import { StrictMode } from 'react'

import { createRoot } from 'react-dom/client'

import './i18n'

import { App } from './App'

const root = createRoot(document.getElementById('root'))

root.render(
    <StrictMode>
        <App />
    </StrictMode>
)
