import React from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { matchPath, NavLink, useLocation } from 'react-router-dom'

import { StoreProject, StoreTestSet } from '@/store'
import { pageUrls } from '@/consts'

import styles from './NavMenu.module.scss'

export const NavMenu = observer(() => {
    const { pathname } = useLocation()
    const { t } = useTranslation(['menuMain'])

    return (
        <div className={styles.NavMenu}>
            <NavLink
                className={cn(styles.NavLink, {
                    [styles.IsActive]: matchPath(`${pageUrls.project}/${StoreProject.id}`, pathname),
                })}
                to={`${pageUrls.project}/${StoreProject.id}`}
            >
                {t('menuMain:repository')}
            </NavLink>
            <NavLink
                className={cn(styles.NavLink, {
                    [styles.IsActive]:
                        matchPath(`${pageUrls.project}/${StoreProject.id}${pageUrls.testSets}`, pathname) ||
                        matchPath(`${pageUrls.testSet}/${StoreTestSet.id}`, pathname),
                })}
                to={`${pageUrls.project}/${StoreProject.id}${pageUrls.testSets}`}
            >
                {t('menuMain:testSets')}
            </NavLink>
        </div>
    )
})
