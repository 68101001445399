// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rbYRoXXgV5vAf2zm6uTE{margin-top:16px;margin-bottom:20px;display:flex;align-items:center;gap:16px}.rbYRoXXgV5vAf2zm6uTE .QjU4vpsgZURoZtxxqaDT{display:flex;gap:8px;width:100%}.rbYRoXXgV5vAf2zm6uTE .QjU4vpsgZURoZtxxqaDT .YWZIuJjVDujICc1dgbLC{min-height:46px}.rbYRoXXgV5vAf2zm6uTE .h0GK4GG6XG9TMV1VjZYQ{display:flex;align-items:center;gap:8px;margin-top:24px}", "",{"version":3,"sources":["webpack://./src/components/SettingPageTabs/Team/Search/UserFilters/UserFilters.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,kBAAA,CACA,YAAA,CACA,kBAAA,CACA,QAAA,CAEA,4CACI,YAAA,CACA,OAAA,CACA,UAAA,CAEA,kEACI,eAAA,CAIR,4CACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,eAAA","sourcesContent":[".Filters {\n    margin-top: 16px;\n    margin-bottom: 20px;\n    display: flex;\n    align-items: center;\n    gap: 16px;\n\n    .Form {\n        display: flex;\n        gap: 8px;\n        width: 100%;\n\n        .SearchInput {\n            min-height: 46px;\n        }\n    }\n\n    .FilterButtons {\n        display: flex;\n        align-items: center;\n        gap: 8px;\n        margin-top: 24px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Filters": "rbYRoXXgV5vAf2zm6uTE",
	"Form": "QjU4vpsgZURoZtxxqaDT",
	"SearchInput": "YWZIuJjVDujICc1dgbLC",
	"FilterButtons": "h0GK4GG6XG9TMV1VjZYQ"
};
export default ___CSS_LOADER_EXPORT___;
