export enum EModalType {
    WORKSPACE = 'WORKSPACE',
    PROJECT = 'PROJECT',
    TEST_KIT = 'TEST_KIT',
}

export enum EParentFolderType {
    PROJECT = 'PROJECT',
    TEST_FOLDER = 'TEST_FOLDER',
}

export enum EModalTypeFolder {
    COPY = 'COPY',
    MOVE = 'MOVE',
    RESTORE = 'RESTORE',
}
