import React, { Key, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { Breadcrumb, Button, Layout, Tooltip, Typography } from 'antd'
import { ProjectsTable } from './components/ProjectsTable'
import { CreateModal } from '@/components/CreateModal/CreateModal'
import { DataPlaceholder } from '@/components/DataPlaceholder/DataPlaceholder'
import { Header } from '@/components/Header/Header'
import { ModalOnDeleteRows } from '@/components/ModalOnDeleteRows/ModalOnDeleteRows'

import { ClearOutlined, DeleteOutlined, LeftOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreCreateTestSet, StoreProject, StoreTestCase, StoreWorkspace } from '@/store'
import { apiUrls, pageUrls } from '@/consts'

import { IProject, ISort, IWorkspace, TableFilterType } from '@/types'
import { EModalType, EOrder } from '@/enums'

import styles from './Projects.module.scss'

const { Title, Paragraph, Text } = Typography
const { Content } = Layout

export const Projects = observer(() => {
    const { t } = useTranslation(['page--projects', 'common', 'notification'])
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { workspaceId } = useParams()

    const [workspaceInfo, setWorkspaceInfo] = useState<IWorkspace>()
    const [isProjectListLoading, setProjectListLoading] = useState(true)
    const [showPlaceholder, setShowPlaceholder] = useState(false)

    const initialFilter = StoreProject.latestSearchProps.filter.workspaceId
        ? StoreProject.latestSearchProps.filter
        : {
              projectName: [],
              projectDescription: [],
              workspaceId: workspaceId!,
          }

    const [filter, setFilter] = useState<TableFilterType>(initialFilter)

    const initialSortProject = StoreProject.latestSearchProps.sort || [
        {
            field: 'createdDate',
            direction: EOrder.DESC,
        },
    ]

    const [projects, setProjects] = useState<IProject[]>([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [elementsCount, setElementsCount] = useState(0)
    const [sort, setSort] = useState<ISort[]>(initialSortProject)
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
    const [showTableMenu, setShowTableMenu] = useState(false)
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)

    useEffect(() => {
        StoreTestCase.executionTestCase && StoreTestCase.resetTestCaseData()
        StoreCreateTestSet.isCreatingTestSet && StoreCreateTestSet.resetTestSet()
    }, [])

    useEffect(() => {
        if (workspaceId) {
            StoreWorkspace.getWorkspace(workspaceId).then((workspace: IWorkspace) => {
                setWorkspaceInfo(workspace)
                StoreWorkspace.setWorkspaceId(workspaceId)
            })
        }
    }, [workspaceId])

    useEffect(() => {
        loadData()
    }, [pageSize, page, filter, sort])

    useEffect(() => {
        if (!projects.length && !isProjectListLoading && JSON.stringify(filter) === JSON.stringify(initialFilter)) {
            setShowPlaceholder(true)
        }
    }, [projects])

    const isFilterChanged = useMemo(() => {
        return (
            JSON.stringify(filter) !== JSON.stringify(initialFilter) ||
            JSON.stringify(sort) !== JSON.stringify(initialSortProject)
        )
    }, [filter, sort])

    const loadData = async (props?: any) => {
        const defaultProps = {
            page,
            pageSize,
            filter: { ...filter, workspaceId: workspaceId || StoreWorkspace.id, isDeleted: false },
            sort,
        }
        const data = await StoreProject.getProjects(props ?? defaultProps)
        setProjects((prev) => data.result)
        setElementsCount((prev) => data.elementsCount)
        setProjectListLoading(false)
    }

    const createProject = () => {
        setIsModalCreateOpen(true)
    }

    const deleteProjects = () => {
        ModalOnDeleteRows({
            items: projects,
            setItems: setProjects,
            elementsCount: elementsCount,
            pageSize: pageSize,
            loadData: loadData,
            specificApiUrl: apiUrls.project,
            title: t('page--projects:deleteGuard.titleAll'),
            content: t('page--projects:deleteGuard.content'),
            okText: t('common:buttons.ok'),
            cancelText: t('common:buttons.cancel'),
            setShowTableMenu: setShowTableMenu,
            selectedRowKeys: selectedRowKeys,
            setSelectedRowKeys: setSelectedRowKeys,
        })
    }

    const goToSettings = () => {
        navigate(`${pageUrls.workspaceSettings}/${workspaceId}`, { state: { previousPath: pathname } })
    }

    const breadcrumbItems = [
        {
            key: 'project',
            title: (
                <Link to={pageUrls.workspaces}>
                    <Text type="secondary">{t('page--projects:allWorkspaces')}</Text>
                </Link>
            ),
        },
    ]

    const onClearFilters = () => {
        setFilter(initialFilter)
        setSort(initialSortProject)
    }

    const showTable = !isProjectListLoading && !showPlaceholder && projects.length !== 0

    return (
        <div className={styles.Projects}>
            <Header />
            <Content className="content">
                <Breadcrumb items={breadcrumbItems} />
                <div className={styles.ProjectsHeader}>
                    <div className={styles.ProjectsHeaderLeft}>
                        <div className={styles.Title}>
                            <Link to={pageUrls.workspaces} className="backLink">
                                <LeftOutlined />
                            </Link>

                            <div className={styles.TitleWithDesc}>
                                <Title level={3}>{workspaceInfo?.workspaceName}</Title>
                                <Paragraph
                                    className={styles.ProjectsHeaderDescription}
                                    ellipsis={{
                                        expandable: true,
                                        symbol: t('common:buttons.readmore'),
                                    }}
                                >
                                    {workspaceInfo?.workspaceDescription}
                                </Paragraph>
                            </div>
                        </div>
                    </div>
                    <div className={styles.ProjectsHeaderRight}>
                        {showTable && (
                            <>
                                {showTableMenu && (
                                    <Button
                                        type="default"
                                        icon={<DeleteOutlined />}
                                        onClick={deleteProjects}
                                        disabled={SpinnerStore.show}
                                    >
                                        {t('common:buttons.delete')}
                                    </Button>
                                )}
                                {isFilterChanged && (
                                    <Tooltip title={t('common:buttons.clearFilters')}>
                                        <Button
                                            type="default"
                                            icon={<ClearOutlined />}
                                            onClick={onClearFilters}
                                            className={styles.FilterButton}
                                        />
                                    </Tooltip>
                                )}
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={createProject}
                                    disabled={SpinnerStore.show}
                                >
                                    {t('page--projects:createProject')}
                                </Button>
                            </>
                        )}

                        <Tooltip overlayStyle={{ position: 'fixed' }} title={t('page--projects:workspaceSettings')}>
                            <Button icon={<SettingOutlined />} onClick={goToSettings} disabled={SpinnerStore.show} />
                        </Tooltip>
                    </div>
                </div>

                {showTable && (
                    <ProjectsTable
                        projects={projects}
                        setProjects={setProjects}
                        loadData={loadData}
                        pageNumber={page}
                        elementsCount={elementsCount}
                        pageSize={pageSize}
                        setPageNumber={setPage}
                        setPageSize={setPageSize}
                        sort={sort}
                        setSort={setSort}
                        filter={filter}
                        setFilter={setFilter}
                        setShowTableMenu={setShowTableMenu}
                        setSelectedRowKeys={setSelectedRowKeys}
                        selectedRowKeys={selectedRowKeys}
                    />
                )}
                {showPlaceholder && (
                    <DataPlaceholder
                        title={t('page--projects:createProject')}
                        description={t('page--projects:createProjectDescription', {
                            workspaceName: workspaceInfo?.workspaceName,
                        })}
                        onCreate={createProject}
                    />
                )}
            </Content>

            {workspaceInfo?.id && isModalCreateOpen && (
                <CreateModal
                    isModalCreateOpen={isModalCreateOpen}
                    setIsModalCreateOpen={setIsModalCreateOpen}
                    modalType={EModalType.PROJECT}
                    workspaceId={workspaceInfo.id}
                />
            )}
        </div>
    )
})
