import React, { FC, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'

import { StoreWorkspace } from '@/store'
import { pageUrls } from '@/consts'

import styles from './ProjectListDropdown.module.scss'

export const ProjectListDropdown: FC = observer(() => {
    const navigate = useNavigate()

    const [items, setItems] = useState<MenuProps['items']>([])

    useEffect(() => {
        if (StoreWorkspace.projectList.length > 1) {
            const newItems = StoreWorkspace.projectList.map((project) => {
                return {
                    key: project.id,
                    label: project.projectName,
                    onClick: () => navigate(`${pageUrls.project}/${project.id}`),
                }
            })
            setItems(newItems)
        }
    }, [StoreWorkspace.projectList])

    return (
        <Dropdown menu={{ items }} trigger={['click']} overlayClassName={styles.Dropdown}>
            <CaretDownOutlined className={styles.NavIcon} />
        </Dropdown>
    )
})
