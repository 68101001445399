import React, { ReactElement, Suspense, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'

import {
    AuthPage,
    CreateTestSet,
    ExecutionCasePage,
    Projects,
    ProjectSettings,
    TestCasePage,
    TestCases,
    TestFolders,
    TestSetPage,
    TestSets,
    User,
    Workspaces,
    WorkspaceSettings,
} from './pages'

import { ConfigProvider } from 'antd'
import Spinner from '@/components/Spinner/Spinner'

import { PreferencesStore, StoreApp, StoreAuthController } from '@/store'
import { pageUrls } from '@/consts'

import './styles/index.scss'

import { ActivateEmailPage } from '@/pages/ActivateEmailPage/ActivateEmailPage'

export const App = observer(() => {
    const [isActionsLoaded, setIsActionsLoaded] = useState(false)

    useEffect(() => {
        ;(async () => {
            await StoreApp.getAppData()

            setIsActionsLoaded(true)
        })()
    }, [])

    const ProtectedRoute = ({ children }: { children: ReactElement<any, any> }) => {
        if (!StoreAuthController.isSignedIn) {
            return <Navigate to={pageUrls.signIn} />
        } else {
            return children
        }
    }

    const router = useMemo(
        () =>
            createBrowserRouter([
                {
                    path: pageUrls.signIn,
                    element: !StoreAuthController.isSignedIn ? <AuthPage page="signIn" /> : <Workspaces />,
                },
                {
                    path: pageUrls.signUp,
                    element: <AuthPage page="signUp" />,
                },
                {
                    path: `${pageUrls.activateEmail}/:requestId/:userId`,
                    element: <ActivateEmailPage />,
                },
                {
                    path: `${pageUrls.passwordRecovery}/:requestId/:userId`,
                    element: <AuthPage page="passwordRecovery" />,
                },
                {
                    path: pageUrls.workspaces,
                    element: (
                        <ProtectedRoute>
                            <Workspaces />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${pageUrls.workspace}/:workspaceId`,
                    element: (
                        <ProtectedRoute>
                            <Projects />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${pageUrls.project}/:projectId`,
                    element: (
                        <ProtectedRoute>
                            <TestFolders />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${pageUrls.projectSettings}/:projectId`,
                    element: (
                        <ProtectedRoute>
                            <ProjectSettings />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${pageUrls.project}/:projectId${pageUrls.testFolder}/:folderId`,
                    element: (
                        <ProtectedRoute>
                            <TestCases />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${pageUrls.project}/:projectId${pageUrls.createTestSet}`,
                    element: (
                        <ProtectedRoute>
                            <CreateTestSet />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${pageUrls.project}/:projectId${pageUrls.testFolder}/:folderId${pageUrls.testCase}/:testCaseId`,
                    element: (
                        <ProtectedRoute>
                            <TestCasePage />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${pageUrls.project}/:projectId${pageUrls.testFolder}/:folderId${pageUrls.testCase}/new`,
                    element: (
                        <ProtectedRoute>
                            <TestCasePage />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${pageUrls.project}/:projectId${pageUrls.testSets}`,
                    element: (
                        <ProtectedRoute>
                            <TestSets />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${pageUrls.testSet}/:testSetId`,
                    element: (
                        <ProtectedRoute>
                            <TestSetPage />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${pageUrls.testSet}/:testSetId${pageUrls.testCase}/:testCaseId`,
                    element: (
                        <ProtectedRoute>
                            <ExecutionCasePage />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `${pageUrls.workspaceSettings}/:workspaceId`,
                    element: (
                        <ProtectedRoute>
                            <WorkspaceSettings />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `/user`,
                    element: (
                        <ProtectedRoute>
                            <User />
                        </ProtectedRoute>
                    ),
                },
            ]),
        [StoreAuthController.isSignedIn]
    )

    return (
        <Suspense fallback={<Spinner show />}>
            <ConfigProvider
                locale={PreferencesStore.antdLocale}
                theme={{
                    hashed: false,
                    token: {
                        colorPrimary: '#3F51B5',
                    },
                    components: {
                        Button: {
                            defaultGhostColor: 'var(--color-text-primary)',
                        },
                    },
                }}
            >
                {isActionsLoaded && <RouterProvider router={router} />}
                {!isActionsLoaded && <Spinner show />}
            </ConfigProvider>
        </Suspense>
    )
})
