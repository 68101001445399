import React, { ChangeEvent, Dispatch, Key, MouseEvent, SetStateAction, useEffect, useMemo, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { searchInTree, useDebounce } from '@/utils'

import { Button, Empty, Input, notification, Segmented, Spin, Tooltip } from 'antd'
import { createFoldersTree } from './CreateFoldersTree'
import { FolderDropdownOptions } from './FolderDropdownOptions'
import { FoldersTree } from './FoldersTree'
import { SelectSearchBy } from './SelectSearchBy'
import { getAllKeys } from './utils/folderUtils'

import { FolderAddOutlined, NodeCollapseOutlined, NodeExpandOutlined } from '@ant-design/icons'

import { StoreCreateTestSet, StoreProject, StoreTestCase, StoreTestFolder, StoreTestSet } from '@/store'
import { folderTabs, maxNestingLevel, pageUrls } from '@/consts'

import { DataNodeWithName, dropdownPositionType, ITestFolder, searchByTypes } from '@/types'
import { EModalTypeFolder } from '@/enums'
import type { SegmentedValue } from 'antd/es/segmented'
import type { DirectoryTreeProps } from 'antd/es/tree'

import styles from './FoldersTab.module.scss'

type Props = {
    testFolders: ITestFolder[]
    setTestFolders: Dispatch<SetStateAction<ITestFolder[]>>
    selectedFolderId: string
    setSelectedFolderId: Dispatch<SetStateAction<string>>
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
    onCreateFolder?: () => void
    selectFolderOnly?: boolean
    selectAndCreateModal?: boolean
    setIsModalCreateFolderOpen?: Dispatch<SetStateAction<boolean>>
    setIsModalRenameOpen?: Dispatch<SetStateAction<boolean>>
    setIsModalSelectOpen?: Dispatch<SetStateAction<boolean>>
    setSelectedForActionFolder?: Dispatch<SetStateAction<ITestFolder | undefined>>
    setModalTypeFolder?: Dispatch<SetStateAction<EModalTypeFolder | undefined>>
    setSearchedName?: Dispatch<SetStateAction<string>>
    withoutSearchParams?: boolean
    selectedForActionFolder?: ITestFolder
    modalTypeFolder?: EModalTypeFolder | undefined
    showInModal?: boolean
    selectFolderModal?: boolean
}

export const FoldersTab = observer((props: Props) => {
    const {
        testFolders,
        setTestFolders,
        selectedFolderId,
        setSelectedFolderId,
        setIsModalCreateFolderOpen,
        onCreateFolder,
        selectFolderOnly,
        selectAndCreateModal,
        setModalTypeFolder,
        modalTypeFolder,
        setIsModalRenameOpen,
        setIsModalSelectOpen,
        setSelectedForActionFolder,
        selectedForActionFolder,
        pageType,
        setSearchedName,
        withoutSearchParams,
        showInModal,
        selectFolderModal,
    } = props
    const { t } = useTranslation(['page--testFolders', 'common', 'notification'])
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const [searchParams, setSearchParams] = useSearchParams()
    const [searchedFolders, setSearchedFolders] = useState<DataNodeWithName[]>([])
    const [searchDebounced, searchValue, setSearchValue] = useDebounce(200, '')
    const [searchBy, setSearchBy] = useState<searchByTypes>('folder')
    const [treeData, setTreeData] = useState<DataNodeWithName[]>([])
    const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
    const [toggleExpand, setToggleExpand] = useState(false)
    const [isCalculatingTreeData, setCalculatingTreeData] = useState(false)

    const onExpand: DirectoryTreeProps['onExpand'] = (keys, info) => {
        setExpandedKeys(keys)
        StoreTestFolder.setExpandedKeys(keys)
    }

    const totalNestedItems = useMemo(() => {
        if (pageType === pageUrls.testFolder) {
            if (StoreTestFolder.currentFolderTab == folderTabs.testCases) {
                return testFolders
                    .filter((folder) => folder.nestingLevel === 1)
                    .reduce((sum, folder) => (sum += folder?.nestedTestCasesCount || 0), 0)
            }
            if (StoreTestFolder.currentFolderTab == folderTabs.archive) {
                return testFolders.find((folder) => !folder.isDeleted)?.nestedTestCasesCount
            }
        } else {
            return testFolders
                .filter((folder) => folder.nestingLevel === 1)
                .reduce((sum, folder) => (sum += folder?.nestedTestSetsCount || 0), 0)
        }
    }, [testFolders, pageType])

    const hasAnyNestedFolder = useMemo(
        () => testFolders.some((folder) => folder.nestingLevel > 1),
        [testFolders, pageType]
    )

    const [selectedRecord, setSelectedRecord] = useState<ITestFolder | undefined>()
    const [dropdownPosition, setDropdownPosition] = useState<dropdownPositionType>({
        x: 0,
        y: 0,
    })

    const setFolderName = (testFolderId: string) => {
        const folderName = testFolders.find((folder) => folder.id === testFolderId)?.name
        folderName && StoreTestFolder.setSelectedFolderName(folderName)
    }

    useEffect(() => {
        if (selectedFolderId) {
            const folderName = testFolders.find((folder) => folder.id === selectedFolderId)?.name
            folderName && StoreTestFolder.setSelectedFolderName(folderName)
        }
    }, [testFolders, selectedFolderId])

    useEffect(() => {
        if (!withoutSearchParams) {
            const testFolderId = searchParams.get('testFolder')

            if (testFolderId) {
                setSelectedFolderId(testFolderId)
                setFolderName(testFolderId)
            }
        }
    }, [searchParams])
    const onChangeTab = (value: SegmentedValue) => {
        setCalculatingTreeData(true)
        StoreTestFolder.setCurrentFolderTab(value as keyof typeof folderTabs)
        if (StoreProject.id) {
            switch (value) {
                case folderTabs.archive:
                    StoreTestFolder.getTestFolders(StoreProject.id, true).then((testFolders) => {
                        setTestFolders(testFolders)
                    })
                    break
                case folderTabs.testCases:
                    StoreTestFolder.setCurrentFolderTab(folderTabs.testCases)
                    StoreTestFolder.getTestFolders(StoreProject.id).then((testFolders) => {
                        setTestFolders(testFolders)
                    })
                    break
            }
            showAllItems()
        }
    }

    const onCreateSubFolder = (
        folderId: string,
        nestingLevel: number,
        e?: MouseEvent,
        setSelectedRecord?: Dispatch<SetStateAction<ITestFolder | undefined>>
    ) => {
        e?.stopPropagation()

        if (nestingLevel >= maxNestingLevel) {
            notification.error({
                message: t('notification:error.maxNestinglevelReached'),
                placement: 'bottomRight',
            })
            return
        }
        setSelectedFolderId(folderId)
        setSelectedRecord && setSelectedRecord(undefined)
        setIsModalCreateFolderOpen && setIsModalCreateFolderOpen(true)
    }

    useEffect(() => {
        const currentFolderTab = StoreTestFolder.currentFolderTab
        currentFolderTab == folderTabs.testCases && StoreTestFolder.setTestFolders(testFolders)
        const data = createFoldersTree({
            testFolders,
            setTestFolders,
            setSelectedFolderId,
            setSelectedForActionFolder,
            setIsModalCreateFolderOpen,
            setIsModalRenameOpen,
            setIsModalSelectOpen,
            setModalTypeFolder,
            selectFolderOnly,
            pageType,
            currentFolderTab,
            onCreateSubFolder,
            selectedForActionFolder,
            modalTypeFolder,
        })
        setTreeData(data)
    }, [
        testFolders,
        StoreTestFolder.showSelectedFolderId,
        modalTypeFolder,
        selectedForActionFolder?.id,
        StoreTestFolder.toggleUpdateFoldersTree,
    ])

    useEffect(() => {
        setCalculatingTreeData(true)
        if (pageType === pageUrls.testFolder) {
            StoreTestFolder.currentFolderTab !== folderTabs.archive
                ? StoreTestFolder.setFoldersTree(treeData)
                : StoreTestFolder.setArchiveFolders(treeData)
        }

        if (pageType === pageUrls.testSets) {
            StoreTestFolder.currentFolderTab !== folderTabs.archive
                ? StoreTestSet.setTestSetFoldersTree(treeData)
                : StoreTestSet.setArchiveTestSetFolders(treeData)
        }
        setCalculatingTreeData(false)
    }, [treeData])

    useEffect(() => {
        resetSelectedSearch()
    }, [testFolders, StoreTestCase.toggleResetSearchTestCase])

    useEffect(() => {
        searchBy === 'testCase' && resetSelectedSearch()
    }, [searchParams])

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSearchValue(value)
    }

    const segmentedOptions = [
        {
            label: (
                <Tooltip title={t('common:inDevelopment')}>{t(`page--testFolders:${folderTabs.commonSteps}`)}</Tooltip>
            ),
            value: folderTabs.commonSteps,
            disabled: true,
        },
        { label: t(`page--testFolders:${folderTabs.testCases}`), value: folderTabs.testCases },
        { label: t(`page--testFolders:${folderTabs.archive}`), value: folderTabs.archive },
    ]

    const showAllItems = () => {
        resetSelectedFolder()
        resetSelectedSearch()

        const isTestSetPage = !!matchPath(`${pageUrls.testSet}/${StoreTestSet.id}`, pathname)
        isTestSetPage && !isFoldersInModal && navigate(`${pageUrls.project}/${StoreProject.id}${pageUrls.testSets}`)
    }

    const showAllItemsButton =
        (!!testFolders.length && !selectFolderOnly) ||
        StoreCreateTestSet.isCreatingTestSet ||
        (StoreTestFolder.currentFolderTab === folderTabs.archive && !selectFolderOnly)

    const resetSelectedSearch = () => {
        setSearchedFolders([])
        setSearchValue('')
    }

    const resetSelectedFolder = () => {
        setSelectedFolderId('')

        if (!withoutSearchParams) {
            setSearchParams()
        }

        StoreTestFolder.resetTestFolder()
    }

    const onCreateFirstLevelFolder = () => {
        StoreTestFolder.setCreatingNewFolder(true)
        onCreateFolder && onCreateFolder()
    }

    const showFolderDropdownOptions =
        !selectFolderOnly &&
        selectedRecord &&
        dropdownPosition &&
        setSelectedForActionFolder &&
        setIsModalRenameOpen &&
        setModalTypeFolder &&
        !selectFolderOnly &&
        setIsModalSelectOpen

    const noFolders =
        !testFolders.length ||
        (!!testFolders.length && !searchedFolders.length && searchBy == 'folder' && searchDebounced)

    const updateFoldersTree = (value: string, type: string) => {
        const updatedFolders = searchInTree(treeData, value)
        setSearchedFolders(updatedFolders)

        const updatedFoldersCopy = [...updatedFolders]

        let newExpandedKeys = [] as Key[]
        updatedFoldersCopy.forEach((folder) => {
            newExpandedKeys = newExpandedKeys.concat(getAllKeys(folder))
        })

        if (searchDebounced && searchBy === 'folder') {
            setExpandedKeys(newExpandedKeys)
            StoreTestFolder.setExpandedKeys(newExpandedKeys)
        }
    }

    useEffect(() => {
        if (searchBy === 'folder') {
            updateFoldersTree(searchValue, 'folder')
            setSearchedName && setSearchedName('')
        } else {
            updateFoldersTree('', searchDebounced)
            setSearchedName && setSearchedName(searchDebounced)
        }
    }, [searchValue, searchBy, searchDebounced])

    const currentTreeData = useMemo(
        () => (searchDebounced ? (searchedFolders as DataNodeWithName[]) : treeData),
        [searchDebounced, treeData, StoreTestFolder.expandedKeys]
    )

    useEffect(() => {
        if (!expandedKeys.length && StoreTestFolder.expandedKeys.length) {
            setExpandedKeys(StoreTestFolder.expandedKeys)
        }
    }, [])

    const handleToggleExpand = () => {
        if (toggleExpand) {
            setExpandedKeys([])
            StoreTestFolder.setExpandedKeys([])
        } else {
            let newExpandedKeys = [] as Key[]
            treeData.forEach((folder) => {
                newExpandedKeys = newExpandedKeys.concat(getAllKeys(folder))
            })
            setExpandedKeys(newExpandedKeys)
            StoreTestFolder.setExpandedKeys(newExpandedKeys)
        }
        setToggleExpand(!toggleExpand)
    }

    const isFoldersInModal = modalTypeFolder || selectFolderModal || selectAndCreateModal || showInModal

    const searchPlaceholder =
        pageType !== pageUrls.testSets ? t('common:buttons.search') : t('page--testFolders:searchFolderByName')

    return (
        <div
            className={cn(styles.FoldersTab, {
                [styles.SelectFolderOnly]: selectFolderOnly,
                [styles.SelectAndCreateModal]: selectAndCreateModal,
                [styles.SelectFolderModal]: selectFolderModal,
                [styles.FoldersTabInModal]: isFoldersInModal,
            })}
        >
            <div className={styles.FoldersNav}>
                <div className={styles.SearchBar}>
                    <Input
                        allowClear
                        value={searchValue}
                        onChange={onSearch}
                        placeholder={searchPlaceholder}
                        className={styles.SearchTestCase}
                        addonAfter={
                            !selectFolderOnly &&
                            pageType !== pageUrls.testSets && (
                                <SelectSearchBy pageType={pageType} setSearchBy={setSearchBy} searchBy={searchBy} />
                            )
                        }
                    />
                    {!selectFolderOnly && StoreTestFolder.currentFolderTab !== folderTabs.archive && (
                        <Tooltip title={t('page--testFolders:createFirstLevelFolder')}>
                            <Button icon={<FolderAddOutlined />} onClick={onCreateFirstLevelFolder} />
                        </Tooltip>
                    )}
                </div>
                {!selectFolderOnly && !selectAndCreateModal && pageType !== pageUrls.testSets && (
                    <Segmented
                        options={segmentedOptions}
                        value={StoreTestFolder.currentFolderTab}
                        onChange={onChangeTab}
                        className={styles.Segmented}
                    />
                )}
                <div className={styles.ShowAll}>
                    {hasAnyNestedFolder && (
                        <>
                            {toggleExpand ? (
                                <Button
                                    title={t('page--testFolders:collapseAll')}
                                    icon={<NodeCollapseOutlined />}
                                    onClick={handleToggleExpand}
                                    size="small"
                                    className={styles.ToggleExpand}
                                    type="link"
                                />
                            ) : (
                                <Button
                                    title={t('page--testFolders:expandAll')}
                                    icon={<NodeExpandOutlined />}
                                    onClick={handleToggleExpand}
                                    size="small"
                                    className={styles.ToggleExpand}
                                    type="link"
                                />
                            )}
                        </>
                    )}
                    {showAllItemsButton && (
                        <Button type="link" className={styles.ShowAllTestCases} onClick={showAllItems}>
                            {pageType === pageUrls.testFolder
                                ? t('page--testFolders:showAllTestCases')
                                : t('page--testFolders:showAllTestSets')}{' '}
                            ({totalNestedItems})
                        </Button>
                    )}
                </div>
            </div>
            {isCalculatingTreeData ? (
                <div className={styles.Spinner}>
                    <Spin />
                </div>
            ) : noFolders ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                <>
                    <FoldersTree
                        treeData={currentTreeData}
                        testFolders={testFolders}
                        onExpand={onExpand}
                        expandedKeys={expandedKeys}
                        selectedKeys={[selectedFolderId]}
                        setSelectedRecord={setSelectedRecord}
                        setDropdownPosition={setDropdownPosition}
                        setFolderName={setFolderName}
                        setSearchedName={setSearchedName}
                        selectFolderOnly={selectFolderOnly}
                        pageType={pageType}
                        setSelectedFolderId={setSelectedFolderId}
                        setSearchParams={setSearchParams}
                        showInModal={showInModal}
                    />
                    {showFolderDropdownOptions && (
                        <FolderDropdownOptions
                            folder={selectedRecord}
                            folders={testFolders}
                            setTestFolders={setTestFolders}
                            setSelectedForActionFolder={setSelectedForActionFolder}
                            setSelectedFolderId={setSelectedFolderId}
                            setIsModalRenameOpen={setIsModalRenameOpen}
                            setIsModalSelectOpen={setIsModalSelectOpen}
                            setModalTypeFolder={setModalTypeFolder}
                            pageType={pageType}
                            onCreateSubFolder={onCreateSubFolder}
                            setSelectedRecord={setSelectedRecord}
                            dropdownPosition={dropdownPosition}
                        />
                    )}
                </>
            )}
        </div>
    )
})
