// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".p2nO7NGLxWx9s7muVIsy .ant-card-head-title{font-size:14px;color:var(--color-primary-dark)}.p2nO7NGLxWx9s7muVIsy .ant-card-extra{width:12px !important;color:var(--color-primary-dark)}.p2nO7NGLxWx9s7muVIsy .tbPaDxhGR5nDKuVI6rO4{display:flex;flex-direction:column}.p2nO7NGLxWx9s7muVIsy .tbPaDxhGR5nDKuVI6rO4 .OKMAFzvcIkFr5TbvSWDw{display:flex;gap:10px}.p2nO7NGLxWx9s7muVIsy .tbPaDxhGR5nDKuVI6rO4 .OKMAFzvcIkFr5TbvSWDw ._RElfg4VpWJeZLAjW69Q svg{color:var(--color-primary-dark);width:10px}.p2nO7NGLxWx9s7muVIsy .tbPaDxhGR5nDKuVI6rO4 .OKMAFzvcIkFr5TbvSWDw .qZ4d8VzXPUG_nLkq3vhc{color:var(--color-text-secondary);font-size:12px}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Sider/GeneralStepCard.module.scss"],"names":[],"mappings":"AAEQ,2CACI,cAAA,CACA,+BAAA,CAEJ,sCACI,qBAAA,CACA,+BAAA,CAIR,4CACI,YAAA,CACA,qBAAA,CAEA,kEACI,YAAA,CACA,QAAA,CAEA,4FACI,+BAAA,CACA,UAAA,CAGJ,wFACI,iCAAA,CACA,cAAA","sourcesContent":[".GeneralStepCard {\n    :global {\n        .ant-card-head-title {\n            font-size: 14px;\n            color: var(--color-primary-dark);\n        }\n        .ant-card-extra {\n            width: 12px !important;\n            color: var(--color-primary-dark);\n        }\n    }\n\n    .CardContent {\n        display: flex;\n        flex-direction: column;\n\n        .CardContentItem {\n            display: flex;\n            gap: 10px;\n\n            .CardContentIcon svg {\n                color: var(--color-primary-dark);\n                width: 10px;\n            }\n\n            .CardContentLabel {\n                color: var(--color-text-secondary);\n                font-size: 12px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GeneralStepCard": "p2nO7NGLxWx9s7muVIsy",
	"CardContent": "tbPaDxhGR5nDKuVI6rO4",
	"CardContentItem": "OKMAFzvcIkFr5TbvSWDw",
	"CardContentIcon": "_RElfg4VpWJeZLAjW69Q",
	"CardContentLabel": "qZ4d8VzXPUG_nLkq3vhc"
};
export default ___CSS_LOADER_EXPORT___;
