import React from 'react'

import cn from 'classnames'

import { Form, FormItemProps } from 'antd'

import styles from './FormItem.module.scss'

export const FormItem = ({ label, className, ...props }: FormItemProps<any>) => (
    <div className={styles.FormItem}>
        <label
            className={cn(styles.Label, className, {
                [styles.LableRequired]: props.rules?.find((rule: any) => rule.required === true),
            })}
        >
            {label}
        </label>

        <Form.Item {...props} />
    </div>
)
