import { Key } from 'react'

import { makeAutoObservable, runInAction } from 'mobx'

import { MsExecutorApi, MsProjectApi } from '@/api'

import { showMessage } from '@/utils/notification'

import { PreferencesStore, SpinnerStore, StoreWorkspace } from '@/store'
import { apiUrls } from '@/consts'

import {
    ICreateJiraIssue,
    IJiraCredentials,
    IJiraCustomFields,
    IJiraEpic,
    IJiraIntegrationInfo,
    IJiraSprint,
    IJiraUser,
    IProject,
    ISort,
} from '@/types'
import { EOrder } from '@/enums'
import type { UploadFile } from 'antd/es/upload/interface'

export const StoreProject = makeAutoObservable({
    id: '',
    projectName: '',
    jiraIntegrationAvailability: false,
    latestSearchProps: {
        filter: { workspaceId: StoreWorkspace.id, projectName: [], projectDescription: [] },
        sort: [],
        pageSize: 10,
    } as {
        page?: number
        filter: Record<string, string[] | string>
        sort: ISort[]
        pageSize?: number
    },
    integrationInfo: null as unknown as IJiraIntegrationInfo,

    setProjectId(value: string) {
        this.id = value
    },

    setJiraIntegrationAvailability(value: boolean) {
        this.jiraIntegrationAvailability = value
    },

    setIntegrationInfo(value: IJiraIntegrationInfo) {
        this.integrationInfo = value
    },

    async getProjects(props?: {
        page?: number
        filter?: Record<string, string | string[] | boolean>
        sort?: { field: string; direction: EOrder }[]
        pageSize?: number
    }) {
        SpinnerStore.setShow(true)
        const projectList = await MsProjectApi.filter({
            ...props,
            specificApiUrl: apiUrls.project,
        })
        SpinnerStore.setShow(false)
        return projectList
    },

    async createProject(project: Partial<IProject>) {
        SpinnerStore.setShow(true)
        const id = await MsProjectApi.saveItem(project, apiUrls.project)
        SpinnerStore.setShow(false)
        PreferencesStore.t('notification:success.projectCreated', { name: project.projectName }).then((message) => {
            showMessage('success', message)
        })

        return id
    },

    async updateProject(project: Partial<IProject>) {
        SpinnerStore.setShow(true)

        await MsProjectApi.updateItem(project, apiUrls.project)

        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.projectUpdated', { name: project.projectName }).then((message) => {
            showMessage('success', message)
        })
    },

    async getProject(id: string) {
        SpinnerStore.setShow(true)
        const projectInfo: IProject = await MsProjectApi.getItem(id, apiUrls.project)

        runInAction(() => {
            this.id = projectInfo.id || ''
            this.projectName = projectInfo.projectName
        })

        SpinnerStore.setShow(false)
        return projectInfo
    },

    async deleteProject(projectId: string, projectName: string) {
        SpinnerStore.setShow(true)
        await MsProjectApi.deleteItem(projectId, apiUrls.project)
        SpinnerStore.setShow(false)
        PreferencesStore.t('notification:success.projectDeleted', { name: projectName }).then((message) => {
            showMessage('success', message)
        })
    },

    async deleteProjects(projects: Key[]) {
        SpinnerStore.setShow(true)
        Promise.all(
            projects.map(async (projectId) => {
                await MsProjectApi.deleteItem(projectId, apiUrls.project)
            })
        )
        SpinnerStore.setShow(false)
        PreferencesStore.t('notification:success.allProjectsDeleted', {}).then((message) => {
            showMessage('success', message)
        })
    },

    async getIntegrationInfo(projectId: string) {
        try {
            SpinnerStore.setShow(true)
            const integrationInfo: IJiraIntegrationInfo = (await MsExecutorApi.getProjectIntegrationJiraInfo(projectId))
                .data
            this.integrationInfo = integrationInfo
            return integrationInfo
        } catch {
            return
        } finally {
            SpinnerStore.setShow(false)
        }
    },

    async connectJira(data: IJiraCredentials) {
        try {
            SpinnerStore.setShow(true)
            await MsExecutorApi.createJiraIntegration(data)
        } finally {
            SpinnerStore.setShow(false)
        }
    },

    async updateJiraIntegration(data: IJiraCredentials) {
        try {
            SpinnerStore.setShow(true)
            await MsExecutorApi.updateJiraIntegration(data)
        } finally {
            SpinnerStore.setShow(false)
        }
    },

    async deleteJiraIntegration(projectId: string) {
        try {
            SpinnerStore.setShow(true)
            await MsExecutorApi.deleteJiraIntegration(projectId)
        } finally {
            SpinnerStore.setShow(false)
        }
    },

    async checkJira(data: IJiraCredentials) {
        try {
            SpinnerStore.setShow(true)
            await MsExecutorApi.checkJiraIntegration(data)
        } finally {
            SpinnerStore.setShow(false)
        }
    },

    async createJiraIssue(issue: ICreateJiraIssue, fileList: UploadFile[]) {
        try {
            SpinnerStore.setShow(true)
            const res = (await MsExecutorApi.createJiraIssue(issue)).data
            fileList.length && (await MsExecutorApi.attachFilesToJiraIssue(res.id, issue.projectId, fileList))
            return res
        } finally {
            SpinnerStore.setShow(false)
        }
    },

    async getScrumBoards(projectId: string) {
        try {
            SpinnerStore.setShow(true)
            return await MsExecutorApi.getScrumBoards(projectId)
        } finally {
            SpinnerStore.setShow(false)
        }
    },

    async getJiraUsers(props: { projectId?: string; name?: string }) {
        SpinnerStore.setShow(true)

        const req = {
            filter: {
                projectId: props.projectId || '',
                name: props.name || '',
            },
            sort: [],
            apiUrl: apiUrls.jiraUsers,
        }
        const sprints: Promise<IJiraUser[]> = await MsExecutorApi.getJiraDictData(req)

        SpinnerStore.setShow(false)
        return sprints
    },

    async getJiraSprints(props: { projectId?: string; name?: string }) {
        SpinnerStore.setShow(true)

        const req = {
            filter: {
                projectId: props.projectId || '',
                name: props.name || '',
            },
            sort: [],
            apiUrl: apiUrls.jiraSprint,
        }
        const sprints: Promise<IJiraSprint[]> = await MsExecutorApi.getJiraDictData(req)

        SpinnerStore.setShow(false)
        return sprints
    },

    async getJiraEpics(props: { projectId?: string; name?: string }) {
        SpinnerStore.setShow(true)

        const req = {
            filter: {
                projectId: props.projectId || '',
                name: props.name || '',
            },
            sort: [],
            apiUrl: apiUrls.jiraEpic,
        }
        const epics: Promise<IJiraEpic[]> = await MsExecutorApi.getJiraDictData(req)
        SpinnerStore.setShow(false)
        return epics
    },

    async getJiraCustomFields(projectId: string): Promise<IJiraCustomFields[]> {
        try {
            SpinnerStore.setShow(true)
            return (await MsExecutorApi.getJiraCustomFields(projectId)).data
        } finally {
            SpinnerStore.setShow(false)
        }
    },
})
