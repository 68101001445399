import React, { Dispatch, Key, SetStateAction, useEffect, useMemo, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Button, Layout, Tooltip, Typography } from 'antd'
import { TableMenu } from './components/TableMenu/TableMenu'
import { TestCaseTable } from './components/TestCaseTable'
import { ModalAddSelectedTestCasesToTestSet } from '@/components/ModalAddSelectedTestCasesToTestSet/ModalAddSelectedTestCasesToTestSet'
import { CreateNewFolderModal } from '@/pages/TestFolders/components/CreateNewFolderModal'
import { MoveTestCaseToFolderModal } from '@/pages/TestFolders/components/Modals/MoveTestCaseToFolderModal'
import { SelectAndCreateModal } from '@/pages/TestFolders/components/Modals/SelectAndCreateModal'

import { ClearOutlined, PlusOutlined } from '@ant-design/icons'

import {
    SpinnerStore,
    StoreCreateTestSet,
    StoreProject,
    StoreTestCase,
    StoreTestCaseList,
    StoreTestFolder,
    StoreTestSet,
    StoreWorkspace,
} from '@/store'
import { folderTabs, pageUrls, showAllPages } from '@/consts'

import { IProject, ISort, ITestCase, ITestFolder, TableFilterType } from '@/types'
import { EOrder, EParentFolderType } from '@/enums'

import styles from './TestCases.module.scss'

const { Title } = Typography
const { Content } = Layout

type Props = {
    testFolders?: ITestFolder[]
    projectId?: string | undefined
    searchedName?: string
    createTestCase?: () => void
    isModalCreateTestCaseOpen?: boolean
    setIsModalCreateTestCaseOpen?: Dispatch<SetStateAction<boolean>>
    selectedProject?: IProject
    testFolderId?: string
    showInModal?: boolean
}

export const TestCases = observer((props: Props) => {
    const {
        searchedName,
        showInModal,
        testFolderId,
        createTestCase,
        isModalCreateTestCaseOpen,
        selectedProject,
        testFolders,
        projectId,
    } = props
    const { t } = useTranslation(['page--testFolders', 'page--testCases', 'notification'])
    const navigate = useNavigate()
    const [_searchParams, setSearchParams] = useSearchParams()

    const [testFolderInfo, setTestFolderInfo] = useState<ITestFolder>()
    const [selectedTestCaseIds, setSelectedTestCaseIds] = useState<string[]>([])
    const [isMoveTestCaseToFolderModalOpen, setIsMoveTestCaseToFolderModalOpen] = useState(false)
    const isSearchByAllProject = !testFolderId || searchedName
    const currentProjectId = selectedProject?.id || StoreProject.id

    const initialFilter =
        StoreTestCaseList.latestSearchProps.filter.parentId && !selectedProject?.id && !searchedName
            ? StoreTestCaseList.latestSearchProps.filter
            : {
                  parentId: isSearchByAllProject ? currentProjectId : testFolderId,
                  parent: isSearchByAllProject ? EParentFolderType.PROJECT : EParentFolderType.TEST_FOLDER,
                  name: searchedName ? [searchedName] : [],
                  needToIncludeSubFolders: true,
                  isDeleted:
                      StoreTestFolder.currentFolderTab === folderTabs.archive && !StoreCreateTestSet.isCreatingTestSet,
              }

    const [filter, setFilter] = useState<TableFilterType>(initialFilter)
    const [showTestSetsModal, setShowTestSetsModal] = useState(false)
    const [isLoadingTestCases, setLoadingTestCases] = useState(true)
    const [isTestFolderLoading, setTestFolderLoading] = useState(false)

    useEffect(() => {
        if (testFolderId) {
            setTestFolderLoading(true)
            StoreTestFolder.setTestFolderId(testFolderId)
            StoreTestFolder.getTestFolder(testFolderId).then((testFolder: ITestFolder) => {
                setTestFolderInfo(testFolder)
                !StoreProject.id && StoreProject.setProjectId(testFolder.projectId)
                !StoreWorkspace.id && StoreWorkspace.setWorkspaceId(testFolder.workspaceId)
                setTestFolderLoading(false)
            })
        } else {
            setTestFolderInfo(undefined)
        }
    }, [testFolderId, selectedProject?.id, searchedName])

    const initialSortTestCase =
        StoreTestCaseList.latestSearchProps.sort || StoreTestFolder.currentFolderTab === folderTabs.archive
            ? [
                  {
                      field: 'lastModifiedDate',
                      direction: EOrder.ASC,
                  },
              ]
            : [
                  {
                      field: 'createdDate',
                      direction: EOrder.DESC,
                  },
              ]

    const [testCases, setTestCases] = useState<ITestCase[]>([])

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(showInModal ? showAllPages : 10)
    const [elementsCount, setElementsCount] = useState(0)

    const [sort, setSort] = useState<ISort[]>(initialSortTestCase)
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>(StoreCreateTestSet.selectedRowKeys)

    const [showTableMenu, setShowTableMenu] = useState(false)
    const [isSelectAndCreateModalOpen, setIsSelectAndCreateModalOpen] = useState(false)
    const [isModalCreateFolderOpen, setIsModalCreateFolderOpen] = useState(false)
    const [testSetFolders, setTestSetFolders] = useState<ITestFolder[]>([])
    const [selectedFolderId, setSelectedFolderId] = useState('')

    const isFilterChanged = useMemo(() => {
        return (
            JSON.stringify(filter) !== JSON.stringify(initialFilter) ||
            JSON.stringify(sort) !== JSON.stringify(initialSortTestCase)
        )
    }, [filter, sort])
    const isFilterEmpty = useMemo(() => {
        if (!filter) {
            return true
        }

        return Object.keys(filter).length === 0 || (Object.keys(filter).length === 1 && 'testFolderId' in filter)
    }, [filter])

    const loadData = async (props?: any) => {
        setLoadingTestCases(true)
        const filterName = filter?.name ? filter.name : []
        const filterNames =
            searchedName && !filterName.includes(searchedName) ? [searchedName, ...filterName] : filterName

        const defaultProps = {
            page,
            pageSize,
            filter: {
                ...filter,
                parentId: isSearchByAllProject ? currentProjectId : StoreTestFolder.id,
                parent: isSearchByAllProject ? EParentFolderType.PROJECT : EParentFolderType.TEST_FOLDER,
                name: filterNames,
                needToIncludeSubFolders: true,
                isDeleted:
                    StoreTestFolder.currentFolderTab === folderTabs.archive && !StoreCreateTestSet.isCreatingTestSet,
            },
            sort,
        }

        //если при обновлении тест-кейсов текущая выбранная папка удалена, то обновить фильтр на все папки TESTADO-679
        if (!defaultProps.filter.parentId && defaultProps.filter.parent === EParentFolderType.TEST_FOLDER) {
            defaultProps.filter.parentId = currentProjectId
            defaultProps.filter.parent = EParentFolderType.PROJECT
        }

        const data = await StoreTestCaseList.getTestCases(props ?? defaultProps)
        setTestCases((prev) => data.result)
        setElementsCount((prev) => data.elementsCount)

        setLoadingTestCases(false)
    }

    useEffect(() => {
        if (!isModalCreateTestCaseOpen) {
            loadData()
        }
    }, [
        pageSize,
        page,
        sort,
        testFolderId,
        testFolderInfo,
        filter,
        searchedName,
        isModalCreateTestCaseOpen,
        StoreProject.id,
        StoreTestFolder.currentFolderTab,
        StoreTestCaseList.toggleUpdateTestCases,
    ])

    useEffect(() => {
        onClearFilters()
    }, [testFolderId, selectedProject?.id, StoreTestFolder.currentFolderTab])

    useEffect(() => {
        if (projectId && !StoreCreateTestSet.isCreatingTestSet) {
            resetSelectedRowKeys()
        }
    }, [projectId, StoreTestFolder.currentFolderTab])

    const getTestSetFolders = async () => {
        await StoreTestSet.getTestSetFolders(currentProjectId).then((folders: ITestFolder[]) => {
            setTestSetFolders(folders)
        })
    }

    const onCreateFolder = () => {
        setIsModalCreateFolderOpen(true)
    }

    const createNewTestSet = () => {
        setIsSelectAndCreateModalOpen(false)
        navigate(`${pageUrls.project}/${StoreProject.id}${pageUrls.createTestSet}?folderId=${selectedFolderId}`)
    }

    const onSelectAndCreateModalOpen = async () => {
        if (!testSetFolders.length) {
            await getTestSetFolders()
        }

        setIsSelectAndCreateModalOpen(true)
    }

    const redirectToFolder = (folderId: string) => {
        const folderName = testFolders?.find((folder) => folder.id === folderId)?.name

        if (folderName) {
            StoreTestFolder.setSelectedFolderName(folderName)
        }

        setSearchParams(`?testFolder=${folderId}`)
        setSelectedFolderId(folderId)
    }

    const handleCloseMoveTestCaseModal = () => {
        resetSelectedRowKeys()
        setIsMoveTestCaseToFolderModalOpen(false)
    }

    const resetSelectedRowKeys = () => {
        if (
            StoreCreateTestSet.selectedRowKeys.length ||
            StoreCreateTestSet.selectedTestCases.length ||
            selectedRowKeys.length
        ) {
            StoreCreateTestSet.setSelectedRowKeys([])
            StoreCreateTestSet.setSelectedTestCases([])
            setSelectedRowKeys([])
        }
    }

    const handleMoveTestCase = async (folderId: string) => {
        StoreCreateTestSet.setSelectedRowKeys([])
        StoreCreateTestSet.setSelectedTestCases([])
        setSelectedRowKeys([])

        if (StoreTestFolder.currentFolderTab === folderTabs.archive) {
            const withLink = (id: string) => {
                selectedTestCaseIds.length &&
                    navigate(
                        `${pageUrls.project}/${StoreProject.id}${pageUrls.testFolder}/${folderId}${pageUrls.testCase}/${id}`
                    )
            }
            await StoreTestCase.unArchiveTestCases(selectedTestCaseIds, folderId, withLink)
            StoreTestFolder.setCurrentFolderTab('testCases')
            StoreTestFolder.updateFoldersTree()
        } else {
            await StoreTestCase.moveTestCases(selectedTestCaseIds, folderId)
            StoreTestFolder.updateFoldersTree()
        }
        redirectToFolder(folderId)
        setSelectedFolderId('')

        handleCloseMoveTestCaseModal()
    }

    const showMoveTestCaseModal =
        !showInModal && isMoveTestCaseToFolderModalOpen && projectId && testFolders && selectedTestCaseIds.length

    const onClearFilters = () => {
        setFilter(initialFilter)
        setSort(initialSortTestCase)
    }

    const tableCondition = isLoadingTestCases || isTestFolderLoading

    return (
        <div className={cn(styles.TestCaseList)}>
            <Content className={styles.ContentWrapper}>
                <div className={styles.TestCaseHeader}>
                    <div className={styles.TestCaseHeaderLeft}>
                        {!StoreCreateTestSet.showSelectedTestCases && (
                            <div className={styles.Title}>
                                <div>
                                    <Title level={4}>
                                        {searchedName
                                            ? t('page--testFolders:searchResults', { name: searchedName })
                                            : StoreTestFolder.selectedFolderName || t('page--testCases:allTestCases')}
                                    </Title>
                                </div>
                            </div>
                        )}
                    </div>

                    {!showInModal && (
                        <div className={styles.TestCaseHeaderRight}>
                            {showTableMenu ? (
                                <TableMenu
                                    testCases={
                                        StoreCreateTestSet.showSelectedTestCases
                                            ? StoreCreateTestSet.selectedTestCases
                                            : testCases
                                    }
                                    setTestCases={setTestCases}
                                    loadData={loadData}
                                    elementsCount={elementsCount}
                                    pageSize={pageSize}
                                    setShowTableMenu={setShowTableMenu}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    selectedRowKeys={selectedRowKeys}
                                    setShowTestSetsModal={setShowTestSetsModal}
                                    onSelectAndCreateModalOpen={onSelectAndCreateModalOpen}
                                    setIsMoveTestCaseToFolderModalOpen={setIsMoveTestCaseToFolderModalOpen}
                                    setSelectedTestCaseIds={setSelectedTestCaseIds}
                                />
                            ) : null}
                            {isFilterChanged && !isFilterEmpty && (
                                <Tooltip title={t('common:buttons.clearFilters')}>
                                    <Button
                                        type="default"
                                        icon={<ClearOutlined />}
                                        onClick={onClearFilters}
                                        className={styles.FilterButton}
                                    />
                                </Tooltip>
                            )}
                            {StoreTestFolder.currentFolderTab !== folderTabs.archive && (
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={createTestCase}
                                    disabled={SpinnerStore.show}
                                >
                                    {t('page--testCases:createTestCase')}
                                </Button>
                            )}
                        </div>
                    )}
                </div>

                <TestCaseTable
                    testCases={
                        StoreCreateTestSet.showSelectedTestCases ? StoreCreateTestSet.selectedTestCases : testCases
                    }
                    setTestCases={setTestCases}
                    setIsMoveTestCaseToFolderModalOpen={setIsMoveTestCaseToFolderModalOpen}
                    loadData={loadData}
                    pageNumber={page}
                    elementsCount={elementsCount}
                    pageSize={pageSize}
                    setPageNumber={setPage}
                    setPageSize={setPageSize}
                    sort={sort}
                    setSort={setSort}
                    filter={filter}
                    setFilter={setFilter}
                    setShowTableMenu={setShowTableMenu}
                    setSelectedRowKeys={setSelectedRowKeys}
                    selectedRowKeys={
                        StoreCreateTestSet.isCreatingTestSet ? StoreCreateTestSet.selectedRowKeys : selectedRowKeys
                    }
                    showInModal={showInModal}
                    selectedProject={selectedProject}
                    searchedName={searchedName}
                    setSelectedTestCaseIds={setSelectedTestCaseIds}
                    setShowTestSetsModal={setShowTestSetsModal}
                    onSelectAndCreateModalOpen={onSelectAndCreateModalOpen}
                    tableCondition={tableCondition}
                />

                {!!StoreCreateTestSet.selectedRowKeys.length && showTestSetsModal && (
                    <ModalAddSelectedTestCasesToTestSet
                        selectedTestCaseIds={StoreCreateTestSet.selectedRowKeys}
                        showTestSetsModal={showTestSetsModal}
                        setShowTestSetsModal={setShowTestSetsModal}
                        resetSelectedRowKeys={resetSelectedRowKeys}
                    />
                )}
                {isSelectAndCreateModalOpen && (
                    <SelectAndCreateModal
                        testFolders={testSetFolders}
                        setTestFolders={setTestSetFolders}
                        selectedFolderId={selectedFolderId}
                        isSelectAndCreateModalOpen={isSelectAndCreateModalOpen}
                        setIsSelectAndCreateModalOpen={setIsSelectAndCreateModalOpen}
                        onCreateFolder={onCreateFolder}
                        onCreateItem={createNewTestSet}
                        setSelectedFolderId={setSelectedFolderId}
                        pageType={pageUrls.testSets}
                    />
                )}
                {currentProjectId && isModalCreateFolderOpen && (
                    <CreateNewFolderModal
                        projectId={currentProjectId}
                        isModalCreateFolderOpen={isModalCreateFolderOpen}
                        setIsModalCreateFolderOpen={setIsModalCreateFolderOpen}
                        testFolders={testSetFolders}
                        setTestFolders={setTestSetFolders}
                        selectedFolderId={selectedFolderId}
                        setSelectedFolderId={setSelectedFolderId}
                        pageType={pageUrls.testSets}
                    />
                )}

                {showMoveTestCaseModal && (
                    <MoveTestCaseToFolderModal
                        initialTestFolders={
                            StoreTestFolder.currentFolderTab === folderTabs.archive
                                ? StoreTestFolder.testFolders
                                : testFolders
                        }
                        initialSelectedFolderId={selectedFolderId}
                        pageType={pageUrls.testFolder}
                        onConfirm={handleMoveTestCase}
                        onClose={handleCloseMoveTestCaseModal}
                    />
                )}
            </Content>
        </div>
    )
})
