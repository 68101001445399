import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation } from 'react-router-dom'

import { hasRoleOrAdmin } from '@/utils'

import { Typography } from 'antd'
import { TestCaseLocker } from '@/components/TestCaseLocker/TestCaseLocker'
import AutoTestCaseIcon from '../TestCaseTypeButtons/AutoTestCaseIcon/AutoTestCaseIcon'
import ManualTestCaseIcon from '../TestCaseTypeButtons/ManualTestCaseIcon/ManualTestCaseIcon'

import { SpinnerStore, StoreTestCase, StoreWorkspace } from '@/store'
import { maxDescriptionLength, maxTitleLength, pageUrls } from '@/consts'

import { EDefaultUserRoles } from '@/enums'

import styles from './TestCaseTitle.module.scss'

const { Title, Paragraph } = Typography

type Props = {
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
    mergeSteps?: () => void
}

export const TestCaseTitle = observer((props: Props) => {
    const { mergeSteps, setTestCaseSavedStatus } = props
    const { t } = useTranslation(['testCase', 'notification'])
    const { pathname } = useLocation()

    const [prevTestCaseName, setPrevTestCaseName] = useState(StoreTestCase.name)
    const [prevTestCaseDescription, setPrevTestCaseDescription] = useState(StoreTestCase.name)

    const [descriptionText, setDescriptionText] = useState(StoreTestCase.description || '')
    const descriptionPlaceholder = t('testCase:addDescription')

    const isNewTC = useMemo(
        () =>
            !!matchPath(
                {
                    path: `/${pageUrls.project}/:id${pageUrls.testFolder}/:id${pageUrls.testCase}/new`,
                },
                pathname
            ),
        [pathname]
    )

    useEffect(() => {
        if (isNewTC) {
            StoreTestCase.setTestCaseName(t('testCase:newTestCaseTitle'))
        }
    }, [t])

    useEffect(() => {
        StoreTestCase.name && prevTestCaseName !== StoreTestCase.name && setPrevTestCaseName(StoreTestCase.name)
    }, [StoreTestCase.name, prevTestCaseName])

    useEffect(() => {
        StoreTestCase.description &&
            prevTestCaseDescription !== StoreTestCase.description &&
            setPrevTestCaseDescription(StoreTestCase.description)
    }, [StoreTestCase.description, prevTestCaseDescription])

    const handleChangeName = (value: string) => {
        StoreTestCase.setTestCaseName(value)
        !value && StoreTestCase.setTestCaseName(prevTestCaseName)
        if (prevTestCaseName !== value) {
            StoreTestCase.setIsSaved(false)
        }
    }

    const handleChangeDescription = (value: string) => {
        StoreTestCase.setTestCaseDescription(value)
        if (prevTestCaseDescription !== value) {
            StoreTestCase.setIsSaved(false)
        }
    }

    const hidePlaceholder = () => {
        setDescriptionText(StoreTestCase.description)
    }

    const isAutomated = !!StoreTestCase.testCaseInfo.isAutomated
    const TestCaseIcon = isAutomated ? AutoTestCaseIcon : ManualTestCaseIcon

    const hasUnlockTestCasePermission: boolean = useMemo(() => {
        if (StoreWorkspace.id && StoreTestCase.projectId) {
            return hasRoleOrAdmin(
                [EDefaultUserRoles.WORKSPACE_ADMIN, EDefaultUserRoles.TEST_LEAD],
                StoreTestCase.projectId,
                StoreWorkspace.id
            )
        } else return false
    }, [StoreTestCase.projectId, StoreWorkspace.id])

    const isLocked = StoreTestCase.blocked && !StoreTestCase.executionTestCase

    return (
        <div className={styles.TestCaseTitle}>
            <div className={styles.TestCaseTitleIconAndTitle}>
                <div className={styles.Statuses}>
                    {isLocked && (
                        <TestCaseLocker
                            blockInfo={StoreTestCase.blockInfo}
                            setTestCaseSavedStatus={setTestCaseSavedStatus}
                            hasUnlockTestCasePermission={hasUnlockTestCasePermission}
                            page={pageUrls.testCase}
                            mergeSteps={mergeSteps}
                        />
                    )}
                </div>
                <div className={styles.Icon}>
                    <TestCaseIcon />
                </div>
                {StoreTestCase.orderNum ? (
                    <div>
                        <Title level={3} className={styles.OrderNum}>
                            #{StoreTestCase.orderNum}
                        </Title>
                    </div>
                ) : null}

                <Title
                    level={3}
                    editable={
                        StoreTestCase.readOnly || SpinnerStore.show
                            ? false
                            : {
                                  onChange: (value) => handleChangeName(value),
                                  triggerType: ['text'],
                                  maxLength: maxTitleLength,
                              }
                    }
                    className={styles.Title}
                >
                    {StoreTestCase.name}
                </Title>
            </div>

            <div className={styles.TestCaseHeaderDescription}>
                <Paragraph
                    editable={
                        StoreTestCase.readOnly || SpinnerStore.show
                            ? false
                            : {
                                  onChange: (value) => handleChangeDescription(value),
                                  triggerType: ['text'],
                                  text: descriptionText,
                                  onStart: hidePlaceholder,
                                  maxLength: maxDescriptionLength,
                              }
                    }
                >
                    {StoreTestCase.description || (!StoreTestCase.readOnly && descriptionPlaceholder)}
                </Paragraph>
            </div>
        </div>
    )
})
