// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gFuAC5KGwfOYlq1o4VTY{display:flex;gap:10px}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Content/StepsPanel/StepsPanelExtra.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,QAAA","sourcesContent":[".StepsMenu {\n    display: flex;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StepsMenu": "gFuAC5KGwfOYlq1o4VTY"
};
export default ___CSS_LOADER_EXPORT___;
