import React, { ChangeEvent, useEffect, useState } from 'react'

import { FormInstance } from 'antd/lib/form/Form'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { DatePicker, Form, Input, Select, Typography } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'
import { UserCard } from '@/components/UserCard/UserCard'
import { CreateTestSetButtons } from '../CreateTestSetButtons/CreateTestSetButtons'

import { SpinnerStore, StoreCreateTestSet, StoreProject } from '@/store'
import { dateFormat, maxDescriptionLength, maxTitleLength } from '@/consts'

import { IGetUserIn, RangeValue } from '@/types'

import styles from './Parameters.module.scss'

const { RangePicker } = DatePicker
const { TextArea } = Input
const { Option } = Select

type Props = {
    form: FormInstance
    projectUsers: IGetUserIn[]
}

export const Parameters = observer((props: Props) => {
    const { form, projectUsers } = props
    const { t } = useTranslation(['page--createTestSet', 'validation', 'common'])
    const [submittableForm, setSubmittableForm] = useState(false)

    const requiredRule = [
        {
            required: true,
            message: t('validation:required'),
        },
    ]

    const values = Form.useWatch([], form)

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittableForm(true)
            },
            () => {
                setSubmittableForm(false)
            }
        )
    }, [values])

    const handleTestSetNameChange = (e: ChangeEvent<HTMLInputElement>) => {
        StoreCreateTestSet.setTestSetName(e.target.value)
    }

    const handleTestSetDescriptionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        StoreCreateTestSet.setTestSetDescription(e.target.value)
    }

    const handleTestSoftwareChange = (e: ChangeEvent<HTMLInputElement>) => {
        StoreCreateTestSet.setTestSoftwareChange(e.target.value)
    }

    const handlenSoftwareVersionChange = (e: ChangeEvent<HTMLInputElement>) => {
        StoreCreateTestSet.setSoftwareVersion(e.target.value)
    }

    const handleExecutionPeriod = (value: RangeValue) => {
        StoreCreateTestSet.setExecutionPeriod(value)
    }

    const onUserChange = (value: string) => {
        StoreCreateTestSet.setSelectedUser(value)
    }

    return (
        <Form
            form={form}
            layout="vertical"
            className={styles.Form}
            key="createTestSet"
            initialValues={{
                project: StoreProject.id,
                name: StoreCreateTestSet.name,
                description: StoreCreateTestSet.description,
                assigned: StoreCreateTestSet.selectedUser,
                executionPeriod: StoreCreateTestSet.executionPeriod,
                testSoftware: StoreCreateTestSet.testSoftware,
                versionSoftware: StoreCreateTestSet.versionSoftware,
            }}
        >
            <FormItem name="name" label={t('page--createTestSet:name')} rules={requiredRule}>
                <Input
                    showCount
                    maxLength={maxTitleLength}
                    className={styles.NameInput}
                    onChange={handleTestSetNameChange}
                    size="large"
                />
            </FormItem>

            <FormItem name="assigned" label={t('page--createTestSet:assigned')}>
                <Select
                    value={StoreCreateTestSet.selectedUser}
                    disabled={SpinnerStore.show}
                    onChange={onUserChange}
                    size="middle"
                    showSearch
                    className={cn('singleUserSelect', {
                        notSelected: !StoreCreateTestSet.selectedUser,
                    })}
                    optionLabelProp="label"
                    optionFilterProp="name"
                    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                >
                    {projectUsers.map((user) => (
                        <Option
                            key={user.userId}
                            value={user.userId}
                            name={user.username}
                            label={<UserCard userName={`${user.firstname} ${user.lastname}`} size={24} />}
                        >
                            {
                                <UserCard
                                    userName={`${user.firstname} ${user.lastname}`}
                                    position={user.position}
                                    size={40}
                                    userNameStyle={{ fontSize: '16px' }}
                                    positionStyle={{ fontSize: '13px' }}
                                />
                            }
                        </Option>
                    ))}
                </Select>
            </FormItem>
            <div className={styles.Description}>
                <FormItem
                    name="description"
                    label={t('page--createTestSet:description')}
                    rules={[{ max: maxDescriptionLength, message: t('validation:textLimitNotification') }]}
                >
                    <TextArea
                        className={styles.DescriptionArea}
                        autoSize={false}
                        maxLength={maxDescriptionLength + 1} //обрезать лишнее, кроме 1 буквы, чтобы отобразить ошибку
                        onChange={handleTestSetDescriptionChange}
                    />
                </FormItem>
            </div>

            <FormItem name="executionPeriod" label={t('page--createTestSet:executionPeriod')}>
                <RangePicker
                    style={{ width: '100%' }}
                    size="middle"
                    value={StoreCreateTestSet.executionPeriod}
                    onChange={handleExecutionPeriod}
                    placeholder={[t('common:startDate'), t('common:endDate')]}
                    format={dateFormat}
                />
            </FormItem>

            <FormItem name="testSoftware" label={t('page--createTestSet:testSoftware')}>
                <Input className={styles.SoftwareInput} maxLength={250} onChange={handleTestSoftwareChange} />
            </FormItem>
            <FormItem name="versionSoftware" label={t('page--createTestSet:versionSoftware')}>
                <Input maxLength={250} onChange={handlenSoftwareVersionChange} />
            </FormItem>
            <div className={styles.CreateTestSetButtons}>
                <CreateTestSetButtons submittableForm={submittableForm} />
            </div>
        </Form>
    )
})
