import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, DatePicker, Form, Input, Select, Tooltip } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'
import { Status } from '@/components/Status/Status'
import { ToggleFilters } from '@/components/ToggleFilters/ToggleFilters'
import { UserCard } from '@/components/UserCard/UserCard'

import { ClearOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreProject } from '@/store'
import { dateFormat, maxTitleLength } from '@/consts'

import { IProject, ISort, ITestSet, IUserCommonInfo, RangeValue, TableFilterType } from '@/types'
import { EExecutionTestSetStatus, EOrder } from '@/enums'

import styles from './TestSetsFilter.module.scss'

const { RangePicker } = DatePicker
const { Option } = Select

type Props = {
    testSets: ITestSet[]
    setTestSets: Dispatch<SetStateAction<ITestSet[]>>
    loadData: () => void
    filter: TableFilterType | null
    setFilter: Dispatch<SetStateAction<TableFilterType>>
    setSort: Dispatch<SetStateAction<ISort[]>>
    initialSort: ISort[]
    isUserPage?: boolean
}

export const TestSetsFilter = observer((props: Props) => {
    const { t } = useTranslation(['page--testSets', 'common'])
    const { testSets, setTestSets, filter, setFilter, setSort, initialSort, isUserPage, loadData } = props
    const [form] = Form.useForm()

    //TODO: надо бы заменить это на onValuesChange формы, но придется всеравно делать доп форматирование для подготовке отправки на бек
    const [projects, setProjects] = useState<IProject[]>([])
    const [selectedProjects, setSelectedProjects] = useState<string[]>([])
    const [selectedOrderNum, setSelectedOrderNum] = useState<number | null>(null)
    const [selectedName, setSelectedName] = useState('')
    // const [selectedDescription, setSelectedDescription] = useState('')
    const [selectedStatuses, setSelectedStatuses] = useState<EExecutionTestSetStatus[]>([])
    const [selectedAuthors, setSelectedAuthors] = useState<string[]>([])
    const [selectedTesters, setSelectedTesters] = useState<string[]>([])
    const [authors, setAuthors] = useState<IUserCommonInfo[]>([])
    const [testers, setTesters] = useState<IUserCommonInfo[]>([])
    const [selectedDate, setSelectedDate] = useState<RangeValue>([null, null])
    const [selectedRunTimeDate, setSelectedRunTimeDate] = useState<RangeValue>([null, null])
    const [isFormLoaded, setFormLoaded] = useState(false)

    const [showAdditionalFilters, setShowAdditionalFilters] = useState(false)

    const [sortField, setSortField] = useState('')
    const [sortDirection, setSortDirection] = useState<EOrder | undefined>(undefined)

    const onClearFilters = () => {
        setFilter([])
        setSort(initialSort)

        form.resetFields()

        setProjects([])
        setSelectedProjects([])
        setSelectedStatuses([])
        setSelectedAuthors([])
        setSelectedTesters([])
        setSelectedDate([null, null])
        setSelectedRunTimeDate([null, null])
        setSortField('')
        setSortDirection(undefined)
        setSelectedOrderNum(null)
        setSelectedName('')
        // setSelectedDescription('')
    }

    const onChangeSortDirection = () => {
        sortDirection === EOrder.DESC ? setSortDirection(EOrder.ASC) : setSortDirection(EOrder.DESC)
    }

    const onChangeSortField = (value: string) => {
        setSortField(value)
    }

    useEffect(() => {
        const authors = [
            ...new Map(testSets.map((testSet) => [testSet.createdBy?.userId, testSet.createdBy])).values(),
        ] as IUserCommonInfo[]

        setAuthors(authors)

        const testers = [
            ...new Map(
                testSets.map((testSet) => [
                    testSet.testerId,
                    {
                        userId: testSet.testerId,
                        firstname: testSet.testerFirstname || t('page--testSets:notAssigned'),
                        lastname: testSet.testerLastname || '',
                        position: testSet.testerPosition,
                        username: testSet.testerId, //TODO: на беке отсутствует (555)
                    },
                ])
            ).values(),
        ] as IUserCommonInfo[]

        setTesters(testers)
    }, [testSets])

    useEffect(() => {
        if (sortDirection && sortField) {
            setSort([{ field: sortField, direction: sortDirection }])
        }
    }, [sortDirection, sortField])

    const getAllProjects = async () => {
        if (!selectedProjects.length) {
            const props = {
                filter: { workspaceId: '', projectName: [], projectDescription: [], isDeleted: false },
            }
            setProjects((await StoreProject.getProjects(props)).result)
        }
    }

    const statusOptions = useMemo(
        () =>
            Object.entries(EExecutionTestSetStatus).map(([key, value]) => ({
                value: key,
                label: <Status status={value} />,
            })),
        []
    )

    useEffect(() => {
        setFormLoaded(true)
    }, [])

    const sortOptions = useMemo(
        () =>
            Object.keys(form.getFieldsValue())
                .map((key) => {
                    return {
                        value: key,
                        label: t(`page--testSets:${key}`),
                    }
                })
                // TODO: на беке задача https://j.bellintegrator.com/browse/TESTADO-635
                // .filter((option) => option.value !== 'sortField' && !(option.value === 'projectId' && !isUserPage)),
                .filter((option) => option.value !== 'sortField' && !(option.value === 'projectId')),

        [isFormLoaded, isUserPage]
    )

    const handleProjectFilter = (value: string[]) => {
        setSelectedProjects([...value])
        const updatedFilter = { ...filter, projectId: [...value] }
        updatedFilter && setFilter(updatedFilter)
    }

    const handleTestersChange = (selectedOptions: string[]) => {
        if (selectedOptions.length) {
            setSelectedTesters(selectedOptions)
            const updatedTestSets = testSets.filter(
                (testSet) => (testSet.testerId && selectedOptions.includes(testSet.testerId)) || !testSet.testerId
            )
            setTestSets(updatedTestSets)
        } else {
            loadData()
        }
    }

    const handleAuthorsChange = (selectedOptions: string[]) => {
        if (selectedOptions.length) {
            setSelectedAuthors(selectedOptions)
            const updatedTestSets = testSets.filter(
                (testSet) => testSet.createdBy?.userId && selectedOptions.includes(testSet.createdBy.userId)
            )
            setTestSets(updatedTestSets)
        } else {
            loadData()
        }
    }

    const handleOrderNumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const reg = /^\d+$/
        if (reg.test(value) || value === '') {
            setSelectedOrderNum(+value)
            const updatedFilter = { ...filter, orderNum: value }
            updatedFilter && setFilter(updatedFilter)
        } else {
            form.setFieldValue('orderNum', selectedOrderNum)
        }
    }

    const handleNameFilter = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        setSelectedName(value)
        const updatedFilter = { ...filter, name: [value] }
        updatedFilter && setFilter(updatedFilter)
    }

    // TODO: на беке задача https://j.bellintegrator.com/browse/TESTADO-635
    // const handleDescriptionFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const value = e.target.value
    //     setSelectedDescription(value)
    //     const updatedFilter = { ...filter, description: value }
    //     updatedFilter && setFilter(updatedFilter)
    // }

    const handleStatusFilter = (value: EExecutionTestSetStatus[]) => {
        setSelectedStatuses([...value])
        const updatedFilter = { ...filter, statuses: [...value] }
        updatedFilter && setFilter(updatedFilter)
    }

    const handleFilterDate = (value: RangeValue) => {
        setSelectedDate(value)
        const createdStartDate = (value && (value[0]?.startOf('day').unix() as unknown as number)) || null
        const createdEndDate = (value && (value[1]?.endOf('day').unix() as unknown as number)) || null

        const updatedFilter = { ...filter, createdStartDate: createdStartDate, createdEndDate: createdEndDate }
        setFilter(updatedFilter)
    }

    const handleFilterRuntime = (value: RangeValue) => {
        setSelectedRunTimeDate(value)
        const beginningPeriodStartDate = (value && (value[0]?.startOf('day').unix() as unknown as number)) || null
        const beginningPeriodEndDate = (value && (value[1]?.endOf('day').unix() as unknown as number)) || null

        const updatedFilter = {
            ...filter,
            beginningPeriodStartDate: beginningPeriodStartDate,
            beginningPeriodEndDate: beginningPeriodEndDate,
        }
        setFilter(updatedFilter)
    }

    useEffect(() => {
        if (sortDirection && sortField) {
            setSort([{ field: sortField, direction: sortDirection }])
        }
    }, [sortDirection, sortField])

    return (
        <div className={styles.Filters}>
            <Form form={form} layout="horizontal" className={styles.Form} key="testSetFilter">
                <div className={styles.CommonFilters}>
                    <FormItem name="orderNum" label={t('page--testSets:orderNum')}>
                        <Input
                            value={String(selectedOrderNum)}
                            onChange={handleOrderNumChange}
                            allowClear
                            className={styles.Input}
                            maxLength={7}
                            placeholder={t('page--testSets:orderNumPlaceholder')}
                        />
                    </FormItem>

                    <FormItem name="name" label={t('page--testSets:name')}>
                        <Input
                            value={selectedName}
                            onChange={handleNameFilter}
                            allowClear
                            className={styles.Input}
                            maxLength={maxTitleLength}
                            placeholder={t('page--testSets:namePlaceholder')}
                        />
                    </FormItem>
                    {!isUserPage && (
                        <FormItem name="testerId" label={t('page--testSets:testerId')}>
                            <Select
                                mode="multiple"
                                className={styles.TesterFilter}
                                value={selectedTesters}
                                onChange={handleTestersChange}
                                showSearch
                                optionLabelProp="label"
                                optionFilterProp="name"
                                dropdownStyle={{ minWidth: 250 }}
                                filterOption={(input, option) =>
                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {testers.map((user) => (
                                    <Option
                                        key={user.userId}
                                        value={user.userId}
                                        name={user.username}
                                        label={<UserCard userName={`${user.firstname} ${user.lastname}`} size={24} />}
                                    >
                                        {
                                            <UserCard
                                                userName={`${user.firstname} ${user.lastname}`}
                                                position={user.position}
                                                size={32}
                                            />
                                        }
                                    </Option>
                                ))}
                            </Select>
                        </FormItem>
                    )}
                    <FormItem name="executionTestSetStatus" label={t('page--testSets:status')}>
                        <Select
                            mode="multiple"
                            value={selectedStatuses}
                            options={statusOptions}
                            onChange={handleStatusFilter}
                        />
                    </FormItem>

                    <div className={styles.FilterButtons}>
                        <div className={styles.FilterButton}>
                            <ToggleFilters
                                showFilters={showAdditionalFilters}
                                setShowFilters={setShowAdditionalFilters}
                                customTooltipTitle={t('page--testSets:additionalFilters')}
                                buttonStyled
                                buttonSize={48}
                            />
                        </div>
                    </div>

                    <FormItem name="sortField" label={t('page--testSets:sort')}>
                        <Select
                            value={sortField}
                            onChange={onChangeSortField}
                            options={sortOptions}
                            className={styles.SortFilter}
                        />
                    </FormItem>

                    <div className={styles.FilterButtons}>
                        <Tooltip title={t(`common:sort.${sortDirection || EOrder.ASC}`)}>
                            <Button
                                type="default"
                                icon={
                                    sortDirection === EOrder.DESC ? (
                                        <SortDescendingOutlined />
                                    ) : (
                                        <SortAscendingOutlined />
                                    )
                                }
                                onClick={onChangeSortDirection}
                                className={styles.FilterButton}
                            />
                        </Tooltip>
                        <Tooltip title={t('common:buttons.clear')}>
                            <Button
                                type="default"
                                icon={<ClearOutlined />}
                                onClick={onClearFilters}
                                className={styles.FilterButton}
                            />
                        </Tooltip>
                    </div>
                </div>

                <div className={cn(styles.AdditionalFilters, { hidden: !showAdditionalFilters })}>
                    {isUserPage && (
                        <FormItem name="projectId" label={t('page--testSets:project')}>
                            <Select
                                mode="multiple"
                                className={styles.ProjectsFilter}
                                value={selectedProjects}
                                onFocus={getAllProjects}
                                loading={SpinnerStore.show}
                                onChange={handleProjectFilter}
                                showSearch
                                optionFilterProp="name"
                                filterOption={(input, option) =>
                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {!!projects.length &&
                                    projects.map((project) => (
                                        <Option key={project.id} value={project.id} name={project.projectName}>
                                            {project.projectName}
                                        </Option>
                                    ))}
                            </Select>
                        </FormItem>
                    )}
                    <FormItem name="createdBy" label={t('page--testSets:createdBy')}>
                        <Select
                            mode="multiple"
                            className={styles.AuthorFilter}
                            value={selectedAuthors}
                            onChange={handleAuthorsChange}
                            showSearch
                            optionLabelProp="label"
                            optionFilterProp="name"
                            dropdownStyle={{ minWidth: 250 }}
                            filterOption={(input, option) =>
                                (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                        >
                            {authors?.map((user) => (
                                <Option
                                    key={user.userId}
                                    value={user.userId}
                                    name={user.username}
                                    label={<UserCard userName={`${user.firstname} ${user.lastname}`} size={24} />}
                                >
                                    {
                                        <UserCard
                                            userName={`${user.firstname} ${user.lastname}`}
                                            position={user.position}
                                            size={32}
                                        />
                                    }
                                </Option>
                            ))}
                        </Select>
                    </FormItem>

                    <FormItem name="beginningPeriod" label={t('page--testSets:beginningPeriod')}>
                        <RangePicker
                            value={selectedRunTimeDate}
                            onChange={handleFilterRuntime}
                            className={styles.DateFilter}
                            placeholder={[t('common:startDate'), t('common:endDate')]}
                            format={dateFormat}
                        />
                    </FormItem>

                    <FormItem name="createdDate" label={t('page--testSets:createdDate')}>
                        <RangePicker
                            value={selectedDate}
                            onChange={handleFilterDate}
                            className={styles.DateFilter}
                            placeholder={[t('common:startDate'), t('common:endDate')]}
                            format={dateFormat}
                        />
                    </FormItem>
                    {/* TODO: на беке задача: https://j.bellintegrator.com/browse/TESTADO-635 */}
                    {/* <FormItem name="description" label={t('page--testSets:description')}>
                        <Input
                            value={selectedDescription}
                            onChange={handleDescriptionFilter}
                            allowClear
                            className={styles.Input}
                        />
                    </FormItem> */}
                </div>
            </Form>
        </div>
    )
})
