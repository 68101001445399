import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { TestCases } from '@/pages/TestCases/TestCases'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { parseNormativeTime, toHoursAndMinutes } from '@/utils'

import { Checkbox, Typography } from 'antd'
import { ResizableFoldersTree } from '@/pages/TestFolders/components/FoldersTree/ResizableFoldersTree'
import { TestFolderHeader } from '../TestFolderHeader/TestFolderHeader'

import {
    StoreAuthController,
    StoreCreateTestSet,
    StoreProject,
    StoreTestCase,
    StoreTestFolder,
    StoreWorkspace,
} from '@/store'
import { pageUrls } from '@/consts'

import { IGetUserIn, IProject, ITestFolder } from '@/types'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'

import styles from './SelectTestCases.module.scss'

const { Title } = Typography

type Props = {
    projectId: string
    setProjectInfo?: Dispatch<SetStateAction<IProject | undefined>>
    setProjectUsers?: Dispatch<SetStateAction<IGetUserIn[]>>
    showInModal?: boolean
}

export const SelectTestCases = observer((props: Props) => {
    const { projectId, setProjectInfo, setProjectUsers, showInModal } = props
    const { t } = useTranslation(['page--createTestSet', 'common'])

    const [testFolders, setTestFolders] = useState<ITestFolder[]>([])
    const [selectedFolderId, setSelectedFolderId] = useState('')
    const [projectList, setProjectList] = useState<IProject[]>([])
    const [selectedProject, setSelectedProject] = useState<IProject>({} as IProject)
    const [totalTime, setTotalTime] = useState(0)

    useEffect(() => {
        if (projectId) {
            StoreProject.getProject(projectId).then((project: IProject) => {
                setProjectInfo && setProjectInfo(project)
                StoreProject.setProjectId(projectId)
                !StoreWorkspace.id && StoreWorkspace.setWorkspaceId(project.workspaceId)
                setSelectedProject(project)
            })
            StoreCreateTestSet.setCreatingTestSet(true)
            StoreTestCase.setReadOnly(true)
        }
    }, [projectId])

    useEffect(() => {
        if (selectedProject.id) {
            StoreTestFolder.getTestFolders(selectedProject.id).then((folders: ITestFolder[]) => {
                setTestFolders(folders)
            })

            setProjectUsers &&
                StoreAuthController.getUsersInProject([selectedProject.id]).then((users) => {
                    setProjectUsers(users.projectUsers)
                })

            StoreCreateTestSet.isCreatingTestSet && StoreCreateTestSet.setSelectedProjectId(selectedProject.id)
        }
    }, [selectedProject])

    const handleShowSelectedTestCase = (e: CheckboxChangeEvent) => {
        StoreCreateTestSet.setShowSelectedTestCases(e.target.checked)
    }

    useEffect(() => {
        const totalMinutes = StoreCreateTestSet.selectedTestCases
            .map((testCase) => parseNormativeTime(testCase.normativeTime))
            .map((time) => time.hours * 60 + time.minutes)

        setTotalTime(totalMinutes.reduce((total, current) => total + current, 0))
    }, [StoreCreateTestSet.selectedRowKeys])

    useEffect(() => {
        setSelectedFolderId('')
    }, [selectedProject])

    return (
        <div className={styles.SelectTestCases}>
            {selectedProject && setSelectedProject && (
                <TestFolderHeader
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    setProjectList={setProjectList}
                    projectList={projectList}
                />
            )}
            {selectedProject && (
                <div className={styles.FoldersAndTestCasesList}>
                    <ResizableFoldersTree
                        testFolders={testFolders}
                        setTestFolders={setTestFolders}
                        selectedFolderId={selectedFolderId}
                        setSelectedFolderId={setSelectedFolderId}
                        selectFolderOnly
                        pageType={pageUrls.testFolder}
                        showInModal={showInModal}
                    />
                    <div className={styles.RightSide}>
                        {!!testFolders.length && (
                            <>
                                <div className={styles.ListTitle}>
                                    <Title level={5} className={styles.Title}>
                                        {t('page--createTestSet:showSelectedTestCases')}
                                    </Title>
                                    <Checkbox
                                        checked={StoreCreateTestSet.showSelectedTestCases}
                                        onChange={handleShowSelectedTestCase}
                                    />
                                    <span>
                                        {t('page--createTestSet:selected')} ({StoreCreateTestSet.selectedRowKeys.length}
                                        )
                                    </span>
                                    <span className={styles.Disc}>●</span>
                                    <span className={styles.TotalTime}>
                                        {t('page--createTestSet:totalTime')}{' '}
                                        {totalTime
                                            ? toHoursAndMinutes(
                                                  totalTime,
                                                  t('common:timeFormat.h'),
                                                  t('common:timeFormat.m')
                                              )
                                            : 0}
                                    </span>
                                </div>
                                <TestCases
                                    testFolders={testFolders}
                                    projectId={projectId}
                                    testFolderId={selectedFolderId}
                                    selectedProject={selectedProject}
                                    showInModal
                                />
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
})
