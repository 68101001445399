import React, { Key, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { parseNormativeTime } from '@/utils'

import { SideBarTitle } from './SideBarTitle/SideBarTitle'
import { TestSetsTable } from './TestSetsTable/TestSetsTable'

import { StoreTestSet } from '@/store'
import { showAllPages } from '@/consts'

import { ISort, ITestSet } from '@/types'
import { EOrder } from '@/enums'

type Props = {
    testSetInfo: ITestSet
    showInModal?: boolean
}
export const TestSetsSideBar = observer((props: Props) => {
    const { testSetInfo, showInModal } = props
    const { t } = useTranslation(['notification'])

    const initialSort = [
        {
            field: 'orderNum',
            direction: EOrder.DESC,
        },
    ]

    const initialFilter = {
        projectId: [testSetInfo.projectId],
    }

    const [testSets, setTestSets] = useState<ITestSet[]>([])
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(showInModal ? showAllPages : 10)
    const [elementsCount, setElementsCount] = useState(0)
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

    const loadData = async (props?: any) => {
        if (testSetInfo.projectId) {
            const defaultProps = {
                page,
                pageSize,
                filter: { ...initialFilter, projectId: [testSetInfo.projectId], isDeleted: false },
                sort: initialSort,
            }

            const data = await StoreTestSet.getTestSets(props ?? defaultProps)

            data.result.map((testSet: ITestSet) => {
                if (testSet.executionCases?.length) {
                    const testCaseStatuses = [
                        ...new Set(testSet.executionCases.map((testCase) => testCase.executionCaseStatus)),
                    ]
                    testSet.progress = testCaseStatuses.reduce((acc: Record<string, number>, status) => {
                        return (
                            (acc[status] =
                                testSet.executionCases?.filter((testCase) => testCase.executionCaseStatus === status)
                                    .length || 0),
                            acc
                        )
                    }, {})

                    testSet.dueTime = (testSet.normativeTime && parseNormativeTime(testSet.normativeTime)) || {
                        hours: 0,
                        minutes: 0,
                    }
                }
            })

            setTestSets((prev) => data.result)
            setElementsCount((prev) => data.elementsCount)
        }
    }

    useEffect(() => {
        loadData()
    }, [pageSize, page, testSetInfo.projectId])

    useEffect(() => {
        const updatedTestSets = testSets.map((testSet) =>
            testSet.id === testSetInfo.id
                ? { ...testSet, executionTestSetStatus: StoreTestSet.executionTestSetStatus }
                : testSet
        )

        setTestSets(updatedTestSets as ITestSet[])
    }, [StoreTestSet.executionTestSetStatus])

    useEffect(() => {
        const updatedTestSets = testSets.map((testSet) =>
            testSet.id === testSetInfo.id ? { ...testSet, name: StoreTestSet.name } : testSet
        )

        setTestSets(updatedTestSets as ITestSet[])
    }, [StoreTestSet.name])

    return (
        <div>
            {testSetInfo.projectId && (
                <SideBarTitle
                    folderId={testSetInfo.testSetFolderId}
                    folderName={testSetInfo.testSetFolderName}
                    projectId={testSetInfo.projectId}
                />
            )}
            <TestSetsTable
                testSets={testSets}
                pageNumber={page}
                elementsCount={elementsCount}
                pageSize={pageSize}
                setPageNumber={setPage}
                setPageSize={setPageSize}
                setSelectedRowKeys={setSelectedRowKeys}
                selectedRowKeys={selectedRowKeys}
                showInModal={showInModal}
            />
        </div>
    )
})
