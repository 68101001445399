import React, { FC, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { MsAuthApi } from '@/api'

import { showMessage } from '@/utils'
import useTranslateFormMessages from '@/utils/hooks/useTranslateFormMessages'

import { Button, Form, Input, Typography } from 'antd'
import { DarkSwitcher } from '@/components/DarkSwitcher'
import { FormItem } from '@/components/FormItem/FormItem'
import { LanguageSwitcher } from '@/components/LanguageSwitcher/LanguageSwitcher'
import styles from '@/components/SignInUp/SignInUp.module.scss'

import { SpinnerStore, StoreAuthController } from '@/store'
import { pageUrls } from '@/consts'

const { Title } = Typography
const { Password } = Input

export const PasswordRecoveryPage: FC = observer(() => {
    const [counter, setCounter] = useState(0)

    const { userId, requestId } = useParams()

    const navigate = useNavigate()

    const [form] = Form.useForm()

    const { t } = useTranslation(['validation', 'page--auth', 'common', 'notification'])

    const requiredRule = [
        {
            required: true,
            message: t('validation:required'),
        },
    ]

    useTranslateFormMessages(form)

    const onFinishChangePassword = (params: { confirm: string }) => {
        if (userId && requestId) {
            MsAuthApi.changePassword({ userId, requestId, newPassword: params.confirm })
                .then(() => {
                    showMessage('success', t('notification:success.passwordChanged'))
                    navigate(pageUrls.signIn)
                })
                .catch((resp) => {
                    showMessage('error', resp.message)
                })
        }
    }

    useEffect(() => {
        const timeout = setTimeout(() => setCounter(counter - 1), 1000)
        counter > 0 && timeout
        return () => clearTimeout(timeout)
    }, [counter])

    return (
        <div className={styles.SignUp}>
            <Title level={3}>{t('page--auth:passwordRecoveryTitle')}</Title>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinishChangePassword}
                initialValues={StoreAuthController.signUpParams}
            >
                <FormItem
                    name="password"
                    label={t('page--auth:newPassword')}
                    rules={requiredRule}
                    className={'hideRequiredMark'}
                    hasFeedback
                >
                    <Password autoComplete="on" />
                </FormItem>
                <FormItem
                    name="confirm"
                    label={t('page--auth:confirmPassword')}
                    dependencies={['password']}
                    hasFeedback
                    className={'hideRequiredMark'}
                    rules={[
                        requiredRule[0],
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error(t('validation:passwordMatch')))
                            },
                        }),
                    ]}
                >
                    <Password />
                </FormItem>
                <div className={styles.FormFooter}>
                    <FormItem className={styles.Submit}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            size="large"
                            disabled={SpinnerStore.show || !(userId && requestId)}
                        >
                            {t('common:buttons.send')}
                        </Button>
                    </FormItem>
                    <div>
                        {t('page--auth:rememberedPassword')} <Link to={pageUrls.signIn}>{t('page--auth:signIn')}</Link>
                    </div>
                    <div className={styles.SiteOptions}>
                        <DarkSwitcher />
                        <LanguageSwitcher />
                    </div>
                </div>
            </Form>
        </div>
    )
})
