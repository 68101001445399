import { Key } from 'react'

import { DataNodeWithName } from '@/types'
import type { DataNode } from 'antd/es/tree'

export const getAllKeys = (obj: DataNode) => {
    let values = [] as Key[]

    for (const key in obj) {
        if (key === 'children' || key === 'key') {
            if (typeof obj[key] === 'object') {
                if (Array.isArray(obj[key])) {
                    obj[key].forEach((item: DataNode) => {
                        values = values.concat(getAllKeys(item))
                    })
                } else {
                    values = values.concat(getAllKeys(obj[key]))
                }
            } else {
                values.push(obj[key])
            }
        }
    }

    return values
}

//TODO: фильтрация дерева папок до дерева которое имеет текущую папку
export const getFolderRoot = (obj: DataNode, folderId: string) => {
    const folders = [] as string[]
    const folderIds = [] as string[]

    for (const key in obj) {
        if (key === 'children' || key === 'name') {
            if (typeof obj[key] === 'object') {
                if (Array.isArray(obj[key]) && obj.key !== folderId) {
                    obj[key].forEach((item: DataNode) => {
                        folders.push(`${getFolderRoot(item, folderId).folders}`)
                        folderIds.push(`${getFolderRoot(item, folderId).folderIds}`)
                    })
                } else {
                    folders.push(`${getFolderRoot(obj[key], folderId).folders}`)
                    folderIds.push(`${getFolderRoot(obj[key], folderId).folderIds}`)
                }
            } else {
                folders.push(`/${obj[key]}`)
                folderIds.push(`/${obj.key}`)
            }
        }
    }

    return { folders: folders, folderIds: folderIds }
}

export const searchFolderById = (nodes: DataNodeWithName[], folderId: string) => {
    let result
    nodes.some((folder) => {
        let children
        if (folder.key === folderId) {
            return (result = folder.name)
        }
        if (folder.children && (children = searchFolderById(folder.children, folderId))) {
            return (result = Object.assign({}, folder))
        }
    })

    return result && [result]
}
