import type { RcFile } from 'antd/es/upload/interface'

export const IO = {
    download(file: Blob | RcFile, filename: string) {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(file)
        link.download = filename
        link.target = '_blank'
        link.setAttribute('type', 'hidden')
        link.setAttribute('download', filename)

        document.body.appendChild(link)
        link.click()
        link.remove()
    },
}
