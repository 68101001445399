import React, { Dispatch, SetStateAction, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Select, Spin } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'

import { SpinnerStore, StoreProject, StoreWorkspace } from '@/store'

import { IProject } from '@/types'

import styles from './SelectProject.module.scss'

const { Option } = Select

type Props = {
    selectedProject: string
    setSelectedProject: Dispatch<SetStateAction<string>>
    projects: IProject[]
    setProjects: Dispatch<SetStateAction<IProject[]>>
}

export const SelectProject = observer((props: Props) => {
    const { selectedProject, setSelectedProject, projects, setProjects } = props
    const { t } = useTranslation(['page--testFolders'])

    const [loading, setLoading] = useState(false)

    const getAllProjects = async () => {
        setLoading(true)
        if (!projects.length) {
            const props = {
                filter: { workspaceId: StoreWorkspace.id, projectName: [], projectDescription: [], isDeleted: false },
            }

            setProjects((await StoreProject.getProjects(props)).result)
            setLoading(false)
        }
    }

    const onSelectProject = (value: string) => {
        setSelectedProject(value)
    }

    return (
        <FormItem name="projectId" label={t('page--testFolders:project')}>
            <Select
                value={selectedProject}
                defaultValue={StoreProject.projectName}
                onFocus={getAllProjects}
                loading={SpinnerStore.show}
                onChange={onSelectProject}
                showSearch
                optionFilterProp="name"
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
            >
                {loading && !projects.length && (
                    <Option value={null}>
                        <div className={styles.Loader}>
                            <Spin />
                        </div>
                    </Option>
                )}
                {projects.length &&
                    projects.map((project) => (
                        <Option key={project.id} value={project.id} name={project.projectName}>
                            {project.projectName}
                        </Option>
                    ))}
            </Select>
        </FormItem>
    )
})
