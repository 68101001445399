import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { Tooltip, Typography } from 'antd'
import { Status } from '@/components/Status/Status'

import { pageUrls } from '@/consts'

import { IExecutionCase, StatusType } from '@/types'

const { Text } = Typography

interface Props {
    testCase: IExecutionCase
    value: string | number | StatusType
    isStatusCell?: boolean
}

export const TableCell = observer((props: Props) => {
    const { testCase, value, isStatusCell } = props

    const [tooltipVisible, setTooltipVisible] = useState(false)
    const [hasOverflow, setHasOverflow] = useState(false)

    const ref = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        const compareSize = () => {
            if (ref.current) {
                const compare = ref.current.scrollWidth > ref.current.clientWidth
                setHasOverflow(compare)
            }
        }
        compareSize()

        window.addEventListener('resize', compareSize)

        return () => window.removeEventListener('resize', compareSize)
    }, [])

    const [isHovered, setIsHovered] = useState(false)

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
        setTooltipVisible(false)
    }

    useEffect(() => {
        if (hasOverflow && isHovered) {
            setTooltipVisible(true)
        } else {
            setTooltipVisible(false)
        }
    }, [hasOverflow, isHovered])

    return (
        <Link
            draggable="false"
            to={`${pageUrls.testSet}/${testCase.executionTestSetId}${pageUrls.testCase}/${testCase.id}`}
        >
            {isStatusCell ? (
                <Status status={value as StatusType} />
            ) : (
                <Tooltip
                    title={value}
                    open={tooltipVisible}
                    onOpenChange={(visible) => setTooltipVisible(visible && hasOverflow)}
                >
                    <Text ref={ref} ellipsis onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        {value}
                    </Text>
                </Tooltip>
            )}
        </Link>
    )
})
