import React, { Dispatch, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Select, Typography } from 'antd'
import { UserCard } from '@/components/UserCard/UserCard'

import { SpinnerStore, StoreTestSet } from '@/store'

import { IGetUserIn, ITestSet } from '@/types'

import styles from './Details.module.scss'

const { Option } = Select
const { Text } = Typography

type Props = {
    testSetInfo: ITestSet
    setTestSetInfo: Dispatch<SetStateAction<ITestSet | undefined>>
    userList: IGetUserIn[]
}

export const SelectTester = observer((props: Props) => {
    const { testSetInfo, setTestSetInfo, userList } = props
    const { t } = useTranslation(['page--testSets'])

    const onTesterIdChange = async (value: string) => {
        if (value && testSetInfo?.id && value !== testSetInfo.testerId) {
            const newExecutionTestSetStatus = (await StoreTestSet.changeTestSetAssigned(testSetInfo.id, value))
                .executionTestSetStatus
            const updatedInfo = {
                ...testSetInfo,
                testerId: value,
                executionTestSetStatus: newExecutionTestSetStatus,
            } as ITestSet
            setTestSetInfo(updatedInfo)
        }
    }

    return (
        <div className={styles.Info}>
            <Text className={styles.Label} type="secondary">
                {t('page--testSets:testerId')}
            </Text>

            <Select
                value={testSetInfo?.testerId || '—'}
                disabled={SpinnerStore.show}
                onChange={onTesterIdChange}
                showSearch
                optionLabelProp="label"
                optionFilterProp="name"
                bordered={false}
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
            >
                {userList.map((user) => (
                    <Option
                        key={user.userId}
                        value={user.userId}
                        name={user.username}
                        label={
                            <UserCard
                                userName={`${user.firstname} ${user.lastname}`}
                                position={user.position}
                                size={24}
                                bordered
                            />
                        }
                    >
                        {
                            <Text ellipsis={{ tooltip: `${user.firstname} ${user.lastname}` }}>
                                <UserCard
                                    userName={`${user.firstname} ${user.lastname}`}
                                    position={user.position}
                                    size={32}
                                    userNameStyle={{ fontSize: '14px', maxWidth: '180px' }}
                                    positionStyle={{ fontSize: '12px' }}
                                />
                            </Text>
                        }
                    </Option>
                ))}
            </Select>
        </div>
    )
})
