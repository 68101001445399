import React, { Dispatch, Key, SetStateAction } from 'react'

import { observer } from 'mobx-react'

import { AddNewStepButtons } from '../AddNewStepButtons/AddNewStepButtons'
import { Steps } from './Steps'

import { StoreTestCase } from '@/store'

import { IStep } from '@/types'
import { EStepType } from '@/enums'

import styles from './StepsTable.module.scss'

type Props = {
    steps: IStep[]
    setSteps: Dispatch<SetStateAction<IStep[]>>
    setShowMenu: Dispatch<SetStateAction<boolean>>
    selectedRowKeys: Key[]
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    stepType: EStepType
    isExecutionTestCase: boolean
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
}

export const StepsTable = observer((props: Props) => {
    const {
        steps,
        setSteps,
        setShowMenu,
        selectedRowKeys,
        setSelectedRowKeys,
        stepType,
        isExecutionTestCase,
        setTestCaseSavedStatus,
    } = props

    return (
        <div className={styles.StepsTable}>
            <Steps
                steps={steps}
                setSteps={setSteps}
                setShowMenu={setShowMenu}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                stepType={stepType}
                isExecutionTestCase={isExecutionTestCase}
                setTestCaseSavedStatus={setTestCaseSavedStatus}
            />

            {!StoreTestCase.readOnly && (
                <AddNewStepButtons
                    steps={steps}
                    setSteps={setSteps}
                    stepType={stepType}
                    setTestCaseSavedStatus={setTestCaseSavedStatus}
                />
            )}
        </div>
    )
})
