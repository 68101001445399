import React, { ClipboardEvent, Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'

import { Input } from 'antd/lib'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, notification, Upload } from 'antd'
import type { InputRef } from 'antd'
import Spinner from '@/components/Spinner/Spinner'
import { handlePasteAndDropFiles } from '@/components/TestCaseComponent/UploadTestCaseAttachments/handlePasteAndDropFiles'
import { checkIfFileNameUnique } from './checkIfFileNameUnique'
import { DraggerUpload } from './DraggerUpload'
import { TestCaseAttachment } from './TestCaseAttachment'

import { PaperClipOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreTestCase } from '@/store'
import { attachmentMaxSizeMb, unitsOfInformation } from '@/consts'

import { EAttachment, EEntityType, EStepType } from '@/enums'
import type { RcFile, UploadProps } from 'antd/es/upload/interface'

import styles from './UploadTestCaseAttachments.module.scss'

type Props = {
    existingObjectId: string
    entityType: EEntityType
    attachmentType: EAttachment
    isNewStep?: boolean
    stepType?: EStepType
    setHasFiles?: Dispatch<SetStateAction<boolean>>
    isExecutionTestCase?: boolean
    originExistingObjectId?: string | null
}

const ENTITY_WITH_BUTTON_UPLOAD = [EEntityType.STEP, EEntityType.EXECUTION_STEP]
const ENTITY_WITH_DRAGGED_UPLOAD = [
    EEntityType.EXECUTION_CASE,
    EEntityType.TEST_CASE,
    EEntityType.PROJECT,
    EEntityType.WORKSPACE,
]

export const UploadTestCaseAttachments = observer((props: Props) => {
    const {
        existingObjectId,
        attachmentType,
        entityType,
        setHasFiles,
        stepType,
        isNewStep,
        isExecutionTestCase,
        originExistingObjectId,
    } = props

    const { t } = useTranslation(['common'])
    const [isMouseOnDragger, setMouseOnDragger] = useState(false)
    const screenshotInputRef = useRef<InputRef>(null)
    const isUploadWithButton = ENTITY_WITH_BUTTON_UPLOAD.includes(entityType)
    const isDraggedUpload = ENTITY_WITH_DRAGGED_UPLOAD.includes(entityType)

    const uploadingAttachments = useMemo(
        () =>
            StoreTestCase.filesToUpload?.filter(
                (file) => file.existingObjectId === existingObjectId && file.attachmentType === attachmentType
            ),
        [existingObjectId, attachmentType, StoreTestCase.filesToUpload]
    )

    const uploadedAttachments = useMemo(() => {
        const matchIds = [existingObjectId, originExistingObjectId]
        return StoreTestCase.attachments.filter(
            (attachment) =>
                matchIds.includes(attachment.existingObjectId) &&
                (attachment.attachmentType === attachmentType ||
                    (isExecutionTestCase && attachment.attachmentType === EAttachment.TEST_CASE))
        )
    }, [attachmentType, existingObjectId, isExecutionTestCase, originExistingObjectId, StoreTestCase.attachments])

    const fileList = useMemo(
        () => [...uploadingAttachments, ...uploadedAttachments],
        [uploadingAttachments, uploadedAttachments]
    )

    useEffect(() => {
        if (setHasFiles) {
            setHasFiles(!!fileList.length)
        }
    }, [fileList])

    const handleChange: UploadProps['onChange'] = ({ file }) => {
        const isFileSizeAllowed = (file: RcFile) => file.size / unitsOfInformation.BytesInMb <= attachmentMaxSizeMb

        if (isFileSizeAllowed(file as RcFile)) {
            const isFileNameUnique = checkIfFileNameUnique(file, existingObjectId, attachmentType)

            if (isFileNameUnique) {
                const fileWithTypes = {
                    originFileObj: file as RcFile,
                    uid: file.uid,
                    lastModified: file.lastModified,
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    existingObjectId: existingObjectId,
                    attachmentType: attachmentType,
                    entityType: entityType,
                    stepType: stepType,
                    isNewStep: isNewStep,
                }

                StoreTestCase.setFilesToUpload([...StoreTestCase.filesToUpload, fileWithTypes])
                StoreTestCase.setIsSaved(false)
            } else {
                notification.error({
                    message: `${t('validation:duplicateFileName')}: "${file.name}"`,
                    placement: 'bottomRight',
                })
            }
        }
    }

    const renderAttachments = useMemo(
        () =>
            fileList.map((file) => {
                const key = 'id' in file ? file.id : file.uid

                return <TestCaseAttachment key={key} file={file} />
            }),
        [fileList]
    )

    const onPasteScreenshotToTestCase = (event: ClipboardEvent<HTMLInputElement>) => {
        if (isMouseOnDragger) {
            handlePasteAndDropFiles({
                event: event,
                needUpload: true,
                existingObjectId: existingObjectId,
                attachmentType: attachmentType,
                stepType: stepType,
                isExecutionTestCase: isExecutionTestCase || false,
                isNewStep: isNewStep || false,
                filesToUpload: StoreTestCase.filesToUpload,
                screenshotTranslation: t('common:screenshot'),
                duplicateFileNameMessage: t('validation:duplicateFileName'),
                attachmentMaxSizeMessage: t('common:buttons.attachmentMaxSize', {
                    attachmentMaxSize: attachmentMaxSizeMb,
                }),
            })
        }
    }

    const handleMouseEnter = () => {
        screenshotInputRef.current?.focus()
        setMouseOnDragger(true)
    }

    const handleMouseLeave = () => {
        setMouseOnDragger(false)
    }

    const beforeUpload = (file: RcFile) => {
        const isFileSizeAllowed = (file: RcFile) => file.size / unitsOfInformation.BytesInMb <= attachmentMaxSizeMb
        if (!isFileSizeAllowed(file)) {
            notification.error({
                message: t('common:buttons.attachmentMaxSize', {
                    attachmentMaxSize: attachmentMaxSizeMb,
                }),
                placement: 'bottomRight',
            })
        }

        return false
    }

    return (
        <>
            {isUploadWithButton && (
                <>
                    <div className={styles.Dragger}>
                        <Upload
                            name="testCaseAttachments"
                            multiple
                            disabled={(StoreTestCase.readOnly && !StoreTestCase.executionTestCase) || SpinnerStore.show}
                            customRequest={() => null}
                            onChange={handleChange}
                            fileList={[]}
                            beforeUpload={beforeUpload}
                        >
                            <Button className={styles.UploadButton} type="text" icon={<PaperClipOutlined />} />
                        </Upload>
                    </div>
                    <div className={styles.AttachmentsList}>{renderAttachments}</div>
                </>
            )}

            {isDraggedUpload &&
                (StoreTestCase.uploadingAttachment ? (
                    <Spinner show={StoreTestCase.uploadingAttachment} />
                ) : (
                    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <DraggerUpload
                            fileList={fileList}
                            attachmentType={attachmentType}
                            handleChange={handleChange}
                        />

                        {/* TODO:  надо с навешиванием событий На ctrl+v при наведении, но не вышло (не происходила фокусировка, надо было одновременно зажимать мышку и жать ctrl+v, onpaste не вызывалось ), пробовал через window - тоже не вышло, в итоге придумал следующее решение - скрытый инпут с onPaste*/}
                        <Input
                            style={{ width: 0, height: 0 }}
                            onPaste={onPasteScreenshotToTestCase}
                            bordered={false}
                            ref={screenshotInputRef}
                        />
                    </div>
                ))}
        </>
    )
})
