import { LocalStorage } from '@/utils'

import { defaultTestCaseColumnOrder } from '@/consts'

export const getTestCaseColumnOrder = () => {
    const testCaseColumnsOrder = LocalStorage.get('testCaseColumnsOrder')?.split(',')
    if (!testCaseColumnsOrder) {
        return defaultTestCaseColumnOrder
    } else {
        return testCaseColumnsOrder
    }
}
