import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { MsAttachmentApi } from '@/api'

import { Button, Form, Input, Typography } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'
import { ModalSave } from '@/components/ModalSave/ModalSave'
import { UploadLogo } from '@/components/Upload/UploadLogo'

import { SaveFilled, UndoOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreWorkspace } from '@/store'
import { pageUrls, settingTabs } from '@/consts'

import { IWorkspace } from '@/types'
import type { UploadFile } from 'antd/es/upload/interface'

import styles from './MainSettings.module.scss'

const { Title } = Typography
const { TextArea } = Input

type Props = {
    selectedTab: string
    currentTab: string
    isLeavingTab: boolean
    setLeavingTab: Dispatch<SetStateAction<boolean>>
    workspaceInfo: IWorkspace
    getWorkspace: () => void
    leaveTab: () => void
    changesSaved: boolean
    setChangesSaved: Dispatch<SetStateAction<boolean>>
    goGoBack: () => void
    isGoingBack: boolean
    setGoingBack: Dispatch<SetStateAction<boolean>>
}

export const MainSettings = observer((props: Props) => {
    const {
        isLeavingTab,
        leaveTab,
        currentTab,
        setLeavingTab,
        selectedTab,
        workspaceInfo,
        getWorkspace,
        changesSaved,
        setChangesSaved,
        goGoBack,
        setGoingBack,
        isGoingBack,
    } = props

    const { t } = useTranslation(['page--workspaceSettings', 'common', 'notification'])
    const { workspaceId } = useParams()
    const [form] = Form.useForm()
    const [isSaved, setSaved] = useState(true)
    const [isModalSaveOpen, setModalSaveOpen] = useState(false)

    const reg = /[А-Яа-яЁё\w]/
    const returnInitialWorkSpaceName = () => {
        const { workspaceName } = form.getFieldsValue()
        !reg.test(workspaceName) && form.setFieldValue('workspaceName', workspaceInfo?.workspaceName)
    }

    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [currentLogo, setCurrentLogo] = useState<UploadFile>()

    useEffect(() => {
        if (workspaceInfo?.logo) {
            const formattedLogo = {
                uid: workspaceInfo.brandbookId,
                name: `${workspaceInfo.logo.fileName}.${workspaceInfo.logo.extension}`,
                thumbUrl: workspaceInfo?.logo?.link,
            } as UploadFile

            setCurrentLogo(formattedLogo)
            setFileList([formattedLogo])
        } else {
            setCurrentLogo(undefined)
        }
    }, [workspaceInfo?.brandbookId])

    const handleUpdateLogo = async () => {
        try {
            if (fileList.length) {
                if (!(currentLogo && fileList[0]?.name === currentLogo?.name)) {
                    const { id } = (await MsAttachmentApi.save(fileList)).data[0]
                    return id
                } else {
                    return workspaceInfo.brandbookId
                }
            } else {
                return null
            }
        } catch (err) {
            console.log('err: ', err)
        }
    }

    useEffect(() => {
        if (isLeavingTab && currentTab === settingTabs.mainSettings) {
            if (isSaved) {
                returnInitialWorkSpaceName()
                leaveTab()
            } else {
                setModalSaveOpen(true)
            }
        }
    }, [isLeavingTab])

    useEffect(() => {
        if (isGoingBack) {
            if (changesSaved) {
                goGoBack()
            } else {
                setModalSaveOpen(true)
            }
        }
    }, [isGoingBack])

    const onSave = async () => {
        SpinnerStore.setShow(true)
        const brandbookId = await handleUpdateLogo()

        const { workspaceName, workspaceDescription } = form.getFieldsValue()

        const updatedWorkspaceData = {
            id: workspaceId,
            orderNum: workspaceInfo?.orderNum,
            workspaceName: reg.test(workspaceName) ? workspaceName : workspaceInfo?.workspaceName,
            workspaceDescription: workspaceDescription,
            brandbookId: brandbookId,
            archiveTime: workspaceInfo.archiveTime,
            executionEntitiesArchiveTime: workspaceInfo.executionEntitiesArchiveTime,
        }

        await StoreWorkspace.updateWorkspace(updatedWorkspaceData)
        getWorkspace()
        setSaved(true)
        setChangesSaved(true)
        setModalSaveOpen(false)
        SpinnerStore.setShow(false)
    }

    const onUndo = () => {
        form.resetFields()
        currentLogo ? setFileList([currentLogo]) : setFileList([])
        setModalSaveOpen(false)
        setSaved(true)
        setChangesSaved(true)
    }

    useEffect(() => {
        currentLogo?.uid && isSaved && setSaved(fileList[0]?.name === currentLogo?.name)
    }, [fileList])

    const onFormChange = () => {
        if (workspaceInfo) {
            const { workspaceName, workspaceDescription } = form.getFieldsValue()
            if (
                (workspaceInfo.workspaceName !== workspaceName && reg.test(workspaceName)) ||
                workspaceInfo?.workspaceDescription !== workspaceDescription
            ) {
                setSaved(false)
                setChangesSaved(false)
            } else {
                setSaved(true)
                setChangesSaved(true)
            }
        }
    }

    const handleDontSave = () => {
        onUndo()
        isLeavingTab ? leaveTab() : goGoBack()
    }

    const handleSave = async () => {
        await onSave()
        isLeavingTab ? leaveTab() : goGoBack()
    }

    const handleCancel = () => {
        setModalSaveOpen(false)
        setLeavingTab(false)
        setGoingBack(false)
    }

    return (
        <div className={styles.MainSettings}>
            <div className={styles.TitleRow}>
                <Title level={4} className={styles.SettingTitle}>
                    {t('page--workspaceSettings:mainSettings.title')}
                </Title>
                {!isSaved && (
                    <div className={styles.ActionMenu}>
                        <Button type="primary" icon={<SaveFilled />} onClick={onSave} disabled={SpinnerStore.show}>
                            {t('common:buttons.save')}
                        </Button>
                        <Button type="default" icon={<UndoOutlined />} onClick={onUndo} disabled={SpinnerStore.show}>
                            {t('common:buttons.undo')}
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.WorkspaceInfo}>
                {workspaceInfo && (
                    <Form
                        form={form}
                        layout="vertical"
                        className={styles.Form}
                        key="workspaceSettings"
                        initialValues={{
                            workspaceName: workspaceInfo.workspaceName,
                            workspaceDescription: workspaceInfo?.workspaceDescription,
                        }}
                        onChange={onFormChange}
                    >
                        <FormItem name="workspaceName" label={t('page--workspaceSettings:mainSettings.workspaceName')}>
                            <Input showCount maxLength={100} />
                        </FormItem>
                        <FormItem
                            name="workspaceDescription"
                            label={t('page--workspaceSettings:mainSettings.workspaceDescription')}
                        >
                            <TextArea showCount maxLength={250} autoSize />
                        </FormItem>
                        <UploadLogo
                            fileList={fileList}
                            setFileList={setFileList}
                            setSaved={setSaved}
                            currentLogo={currentLogo}
                            setChangesSaved={setChangesSaved}
                        />
                    </Form>
                )}
                {/* TODO */}
                {/* {t('page--workspaceSettings:mainSettings.storageTimeForTestCases')}
                        {t('page--workspaceSettings:mainSettings.storageTimeForAttachments')}
                        {t('page--workspaceSettings:mainSettings.storageTimeForTestSets')}
                        {t('page--workspaceSettings:mainSettings.enableEmailNotifications')}
                        {t('page--workspaceSettings:mainSettings.sendTestCaseCompleteNotification')}
                        {t('page--workspaceSettings:mainSettings.selectUserData')}
                        {t('page--workspaceSettings:mainSettings.email')}
                        {t('page--workspaceSettings:mainSettings.fullName')}
                        {t('page--workspaceSettings:mainSettings.position')}
                        {t('page--workspaceSettings:mainSettings.messenger')}
                        {t('page--workspaceSettings:mainSettings.phone')} */}
            </div>
            {isModalSaveOpen && (
                <ModalSave
                    isModalSaveOpen={
                        (selectedTab !== settingTabs.mainSettings && !isSaved && isModalSaveOpen) || !changesSaved
                    }
                    handleDontSave={handleDontSave}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    pageType={pageUrls.workspaceSettings}
                />
            )}
        </div>
    )
})
