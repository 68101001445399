import React from 'react'

import { observer } from 'mobx-react'

import { Typography } from 'antd'

import { SpinnerStore } from '@/store'

import styles from './EditTestSetField.module.scss'

const { Text, Paragraph } = Typography

type Props = {
    fieldName: string
    label: string
    value: string | undefined
    onChangeValue: (value: string, fieldName: string) => void
}

export const EditTestSetField = observer((props: Props) => {
    const { value, label, fieldName, onChangeValue } = props

    return (
        <div className={styles.Info}>
            <Text className={styles.Label} type="secondary">
                {label}
            </Text>
            <Paragraph
                className={styles.EditTestSetField}
                editable={
                    SpinnerStore.show
                        ? false
                        : {
                              onChange: (value) => onChangeValue(value, fieldName),
                              triggerType: ['text', 'icon'],
                          }
                }
            >
                {value || '—'}
            </Paragraph>
        </div>
    )
})
