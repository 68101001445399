import React, { Dispatch, Key, SetStateAction } from 'react'

import dayjs from 'dayjs'
import { TOptions } from 'i18next'
import _ from 'lodash'

import { Button, DatePicker, Input, Space } from 'antd'

import { CalendarOutlined, SearchOutlined } from '@ant-design/icons'

import { SpinnerStore } from '@/store'
import { dateFormat } from '@/consts'

import { ILocalAction, ILocalStep, StepType, TableFilterType } from '@/types'
import { ISort } from '@/types/sort'
import { EOrder } from '@/enums'
import { FilterConfirmProps, FilterValue, SorterResult } from 'antd/es/table/interface'

const { RangePicker } = DatePicker

export const getColumnSearchProps = (
    t: (key: string, options?: TOptions | string) => string,
    props: {
        column: string
        text: string
        clearFilters: () => void
        defaultValue: string[] | null
        setDefaultValue: Dispatch<SetStateAction<string[]>>
        setDropdownVisible: Dispatch<SetStateAction<boolean>>
        dropdownVisible: boolean
        isLocalValue?: boolean
        setFilter?: Dispatch<SetStateAction<any>>
    }
) => ({
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
    }: {
        setSelectedKeys: (selectedKeys: Key[]) => void
        selectedKeys: Key[]
        confirm: (param?: FilterConfirmProps) => void
    }) => {
        const handleInput = () => {
            props.defaultValue && props.setDropdownVisible(false)
            confirm({ closeDropdown: false })
        }

        const handleClearFilters = () => {
            setSelectedKeys([])
            props.setDefaultValue([])
            props.clearFilters()
            confirm({ closeDropdown: false })
            props.setFilter && props.setFilter({})
        }

        return (
            <div className="tableFilterSearch">
                <Input
                    id={`${props.column}-input-search`}
                    placeholder={props.text.toLocaleLowerCase()}
                    value={
                        selectedKeys.join(' ') && props.isLocalValue
                            ? selectedKeys.join(' ')
                            : props?.defaultValue?.join(' ')
                    }
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? e.target.value.split(' ') : [])
                        props.setDefaultValue(e.target.value ? e.target.value.split(' ') : [])
                    }}
                    onPressEnter={handleInput}
                    className="tableFilterSearch__searchInput"
                />
                <Space className="tableFilterSearch__buttons">
                    <Button
                        type="primary"
                        onClick={handleInput}
                        icon={<SearchOutlined />}
                        size="small"
                        disabled={SpinnerStore.show}
                    >
                        {t('common:buttons.search')}
                    </Button>
                    <Button
                        onClick={handleClearFilters}
                        disabled={
                            // !(selectedKeys && selectedKeys?.length > 0) ||
                            !(props.defaultValue && props.defaultValue?.length > 0)
                        }
                        size="small"
                    >
                        {t('common:buttons.clear')}
                    </Button>
                </Space>
            </div>
        )
    },
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    filterDropdownOpen: props.dropdownVisible,
    onFilterDropdownOpenChange: (visible: boolean) => {
        props.setDropdownVisible(visible)
        if (visible) {
            setTimeout(() => document.getElementById(`${props.column}-input-search`)?.focus(), 100)
        }
    },
})

export const getColumnDateSearchProps = (
    t: (key: string, options?: TOptions | string) => string,
    props: {
        column: string
        text: string
        isRangeDate?: boolean
        withTime?: boolean
        filter: any | Record<string, string[] | string | boolean>
        setFilter: Dispatch<SetStateAction<TableFilterType>>
        loadData: () => void
    }
) => ({
    filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
    }: {
        setSelectedKeys: (selectedKeys: Key[]) => void
        selectedKeys: Key[]
        confirm: (param?: FilterConfirmProps) => void
        clearFilters: () => void
    }) => {
        const resetDateFilter = () => {
            clearFilters()
            confirm({ closeDropdown: true })

            delete props.filter.createdStartDate
            delete props.filter.createdEndDate

            delete props.filter.lastModifiedStartDate
            delete props.filter.lastModifiedEndDate

            props.setFilter(props.filter)
            props.loadData()
        }

        return (
            <div style={{ padding: 8, display: 'flex', flexDirection: 'column' }}>
                <RangePicker
                    id={`${props.column}-input-search`}
                    style={{ marginBottom: 8 }}
                    allowClear={false}
                    value={
                        props.column === 'createdDate'
                            ? !!(props.filter.createdStartDate || props.filter.createdEndDate)
                                ? [
                                      dayjs(props.filter.createdStartDate * 1000),
                                      dayjs(props.filter.createdEndDate * 1000),
                                  ]
                                : undefined
                            : !!(props.filter.lastModifiedStartDate || props.filter.lastModifiedEndDate)
                              ? [
                                    dayjs(props.filter.lastModifiedStartDate * 1000),
                                    dayjs(props.filter.lastModifiedEndDate * 1000),
                                ]
                              : undefined
                    }
                    onChange={(dates) => {
                        if (Array.isArray(dates)) {
                            const startDate =
                                (Array.isArray(dates) && dates[0]?.startOf('day').unix()) ||
                                dayjs().startOf('day').unix()
                            const endDate =
                                (Array.isArray(dates) && dates[1]?.endOf('day').unix()) || dayjs().endOf('day').unix()
                            setSelectedKeys([startDate, endDate])
                        }
                    }}
                    placeholder={[t('common:startDate'), t('common:endDate')]}
                    format={dateFormat}
                />

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                        type="text"
                        disabled={!(selectedKeys && !selectedKeys?.length)}
                        onClick={resetDateFilter}
                        size="small"
                    >
                        {t('common:buttons.reset')}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => confirm({ closeDropdown: true })}
                        size="small"
                        style={{ width: 90 }}
                    >
                        {t('common:buttons.ok')}
                    </Button>
                </div>
            </div>
        )
    },
    filterIcon: (filtered: boolean) => <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownOpenChange: (visible: boolean) => {
        if (visible) {
            setTimeout(() => document.getElementById(`${props.column}-input-search`)?.focus(), 100)
        }
    },
})

export function createSorter(
    sort: {
        field: string
        direction: EOrder
    }[]
) {
    return (column: string) => {
        const sortIndex = sort.findIndex(({ field }) => field === column)
        const sortData = sort.find(({ field }) => field === column)
        return {
            dataIndex: column,
            sorter: {
                multiple: sortIndex,
            },
            sortOrder: !sortData?.direction
                ? null
                : ((sortData?.direction === EOrder.DESC ? 'descend' : 'ascend') as 'descend' | 'ascend'),
        }
    }
}

export function createSort<RecordType>(
    sortedNew: SorterResult<RecordType> | SorterResult<RecordType>[],
    sorterOld: SorterResult<RecordType>[]
): ISort[] {
    let sorter: any[] = []
    let sorterNew: any[]
    sorterNew = Array.isArray(sortedNew) ? sortedNew : [sortedNew]
    sorterNew = sorterNew.filter((s) => !!s.order)

    if (sorterNew.length === sorterOld.length) {
        // fields change direction
        sorter = sorterOld.map((s) => {
            const newField = sorterNew.find((el) => el.field === s.field)
            let newDirection = EOrder.ASC
            if (newField?.direction && newField?.direction !== EOrder.ASC) {
                newDirection = EOrder.DESC
            } else {
                newDirection = newField?.order === 'ascend' ? EOrder.ASC : EOrder.DESC
            }
            return {
                field: `${s.field}`,
                direction: newDirection,
            }
        })
    }
    if (sorterNew.length < sorterOld.length) {
        // field removed
        const newFields = sorterNew.map(({ field }) => field)
        sorter = sorterOld.filter(({ field }) => newFields.includes(field))
    }
    if (sorterNew.length > sorterOld.length) {
        // new field in the end
        const sortDataNew = sorterNew.splice(-1)[0]
        sorter = sorterOld.filter(({ field }) => field !== sortDataNew.field)
        sorter.unshift(sortDataNew)
    }

    return sorter.map((s) => ({
        field: `${s.field}`,
        direction: s.order === 'ascend' || s.direction === EOrder.ASC ? EOrder.ASC : EOrder.DESC,
    }))
}

export function createFilter(
    filters: Record<string, FilterValue | null | undefined>,
    interceptor?: (filters: TableFilterType) => TableFilterType
) {
    const result = Object.fromEntries(
        Object.entries(filters)
            .map(([filter, terms]) => [filter, terms ?? []])
            .filter(([, terms]) => terms.length > 0)
    ) as Record<string, string[]>
    return interceptor?.(result) ?? result
}

export const createDateIntervalFilters = (result: TableFilterType, filter: TableFilterType) => {
    if (result?.createdDate) {
        result.createdStartDate = result.createdDate[0 as keyof TableFilterType]
        result.createdEndDate = result.createdDate[1 as keyof TableFilterType]

        delete result.createdDate
    }

    if (
        (filter?.createdStartDate || filter?.createdEndDate) &&
        result &&
        result?.createdStartDate === null &&
        result?.createdEndDate === null
    ) {
        result.createdStartDate = filter?.createdStartDates as string
        result.createdEndDate = filter?.createdEndDate as string
    }

    if (result?.lastModifiedDate) {
        result.lastModifiedStartDate = result.lastModifiedDate[0 as keyof TableFilterType]
        result.lastModifiedEndDate = result.lastModifiedDate[1 as keyof TableFilterType]

        delete result.lastModifiedDate
    }

    if (
        (filter?.lastModifiedStartDate || filter?.lastModifiedEndDate) &&
        result &&
        result?.lastModifiedStartDate === null &&
        result?.lastModifiedEndDate === null
    ) {
        result.lastModifiedStartDate = filter.lastModifiedStartDate as string
        result.lastModifiedEndDate = filter.lastModifiedEndDate as string
    }

    return result
}

export const resetNumeration = (data: any[]) => {
    const dataCopy = _.cloneDeep(data)

    const updatedData = dataCopy.map((item: any, index: number) => {
        item.orderNum = index + 1
        return item
    })

    return updatedData
}

const localStepFieldsForCopy = [
    'expectedResult',
    'required',
    'orderNum',
    'stepType',
    'comment',
    'manual',
    'testData',
    'description',
    'actions',
]
const localStepActionFieldsForCopy = ['orderNum', 'description']

export const extractStepDataForCopy = (step: StepType): ILocalStep => {
    const copyStep = _.pick(step, localStepFieldsForCopy) as ILocalStep
    const formattedActions = step.actions?.map((action) => _.pick(action, localStepActionFieldsForCopy) as ILocalAction)

    return { ...copyStep, actions: formattedActions }
}
