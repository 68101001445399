// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mK3YLL8S9iJrfLdIz88I{display:flex;gap:8px}", "",{"version":3,"sources":["webpack://./src/pages/TestCases/components/TableMenu/TableMenu.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,OAAA","sourcesContent":[".TableMenu {\n    display: flex;\n    gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableMenu": "mK3YLL8S9iJrfLdIz88I"
};
export default ___CSS_LOADER_EXPORT___;
