import React, { Dispatch, SetStateAction, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Checkbox, Dropdown, notification, Tooltip } from 'antd'

import { EditOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreAuthController } from '@/store'
import { pageUrls } from '@/consts'

import { IGetUserIn, IRolesIn, ISearchRoles, settingsPageType } from '@/types'
import { EDefaultUserRoles } from '@/enums'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import type { CheckboxOptionType, CheckboxValueType } from 'antd/es/checkbox/Group'

import styles from './DropDownRoles.module.scss'

const CheckboxGroup = Checkbox.Group

type Props = {
    user: IGetUserIn
    users: IGetUserIn[]
    setUsers: Dispatch<SetStateAction<IGetUserIn[]>>
    roles: ISearchRoles[]
    initialCheckedRoles: string[]
    rolesList: CheckboxOptionType[]
    onlyAdmin: boolean
    pageType: settingsPageType
}

export const DropDownRoles = observer((props: Props) => {
    const { user, setUsers, users, roles, initialCheckedRoles, rolesList, onlyAdmin, pageType } = props
    const { t } = useTranslation(['page--workspaceSettings', 'common', 'validation'])
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>(initialCheckedRoles)
    const [indeterminate, setIndeterminate] = useState(true)
    const [checkAll, setCheckAll] = useState(false)

    const updateRolesOnBackend = async (list: string[]) => {
        const assignedRolesIds = list.map((roleName) => roles.find((role) => role.name === roleName)?.id) as string[]
        const notAssignedRolesIds = roles.filter((role) => !assignedRolesIds.includes(role.id)).map((role) => role.id)
        await StoreAuthController.addRoles(user.userId, assignedRolesIds)
        await StoreAuthController.deleteRoles(user.userId, notAssignedRolesIds)
    }

    const onChangeRole = (list: string[]) => {
        const usersCopy = [...users]
        const userInfo = usersCopy.find((workspaceUser) => workspaceUser.userId === user.userId)

        if (pageType === pageUrls.workspaceSettings) {
            if (userInfo?.userRolesInWorkspace) {
                userInfo.userRolesInWorkspace = list.map((role) => {
                    if (
                        userInfo.userRolesInWorkspace &&
                        userInfo.userRolesInWorkspace.some((userRole) => userRole.roleName === role)
                    ) {
                        return userInfo.userRolesInWorkspace.find((userRole) => userRole.roleName === role) as IRolesIn
                    } else {
                        return { roleName: role, roleDate: 0 }
                    }
                })

                updateRolesOnBackend(list)
                setUsers(usersCopy)
            }
        } else {
            if (userInfo?.userRolesInProject) {
                userInfo.userRolesInProject = list.map((role) => {
                    if (
                        userInfo.userRolesInProject &&
                        userInfo.userRolesInProject.some((userRole) => userRole.roleName === role)
                    ) {
                        return userInfo.userRolesInProject.find((userRole) => userRole.roleName === role) as IRolesIn
                    } else {
                        return { roleName: role, roleDate: 0 }
                    }
                })

                updateRolesOnBackend(list)
                setUsers(usersCopy)
            }
        }
    }

    const onChange = (list: CheckboxValueType[]) => {
        if (
            !list.includes(EDefaultUserRoles.WORKSPACE_ADMIN) &&
            onlyAdmin &&
            checkedList.includes(EDefaultUserRoles.WORKSPACE_ADMIN)
        ) {
            list.push(EDefaultUserRoles.WORKSPACE_ADMIN)
        }

        if (list.length) {
            setCheckedList(list)
            setIndeterminate(!!list.length && list.length < rolesList.length)
            setCheckAll(list.length === rolesList.length)
            onChangeRole(list as string[])
        } else {
            notification.error({
                message: t('validation:selectRoleNotification'),
                placement: 'bottomRight',
            })
        }
    }

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        const updatedCheckList = e.target.checked ? rolesList.map((role) => role.value) : []

        if (!updatedCheckList.length) {
            if (pageType === pageUrls.workspaceSettings) {
                if (!onlyAdmin) {
                    updatedCheckList.push(EDefaultUserRoles.WORKSPACE_USER)
                } else {
                    updatedCheckList.push(EDefaultUserRoles.WORKSPACE_ADMIN)
                }
            }
            if (pageType === pageUrls.projectSettings) {
                updatedCheckList.push(EDefaultUserRoles.PROJECT_USER)
            }
        }

        setCheckedList(updatedCheckList)
        setIndeterminate(false)
        setCheckAll(e.target.checked)
        onChangeRole(updatedCheckList as string[])
    }

    return (
        <Dropdown
            disabled={SpinnerStore.show}
            placement="bottomRight"
            trigger={['click']}
            dropdownRender={(menu) => (
                <div className={styles.DropdownCheckbox}>
                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={onCheckAllChange}
                        checked={checkAll}
                        className={styles.Checkbox}
                    >
                        {t('common:buttons.selectAll')}
                    </Checkbox>
                    <CheckboxGroup
                        options={rolesList}
                        value={checkedList}
                        onChange={onChange}
                        className={styles.DropdownCheckboxGroup}
                    />
                </div>
            )}
        >
            <Tooltip title={t('page--workspaceSettings:team.changeRoles')}>
                <EditOutlined />
            </Tooltip>
        </Dropdown>
    )
})
