import React, { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { InputNumber, Typography } from 'antd'

import { StoreTestCase } from '@/store'

import styles from './TestCaseNormativeTime.module.scss'

const { Text } = Typography

export const TestCaseNormativeTime = observer(() => {
    const { t } = useTranslation(['testCase', 'common'])

    const onNormativeTimeChange = () => {
        StoreTestCase.setIsSaved(false)
    }

    const onHoursChange = (value: number | null) => {
        StoreTestCase.setNormativeHours(value)
        onNormativeTimeChange()
    }

    const onMinutesChange = (value: number | null) => {
        StoreTestCase.setNormativeMinutes(value)
        onNormativeTimeChange()
    }

    return (
        <div className={styles.TestCaseNormativeTime}>
            <Text className={styles.SelectLabel} type="secondary">
                {t('testCase:normativeTime')}
            </Text>
            <div className={styles.InputNumbers}>
                <InputNumber
                    name="hours"
                    min={0}
                    max={99}
                    prefix={t('common:fullTimeFormat.h')}
                    value={StoreTestCase.normativeHours}
                    onChange={onHoursChange}
                    bordered={false}
                    className="underlinedInput"
                    disabled={StoreTestCase.readOnly}
                />

                <InputNumber
                    name="minutes"
                    min={0}
                    prefix={t('common:fullTimeFormat.m')}
                    max={59}
                    value={StoreTestCase.normativeMinutes}
                    onChange={onMinutesChange}
                    bordered={false}
                    className="underlinedInput"
                    disabled={StoreTestCase.readOnly}
                />
            </div>
        </div>
    )
})
