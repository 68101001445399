import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Popconfirm, Tooltip } from 'antd'

import { CloseOutlined } from '@ant-design/icons'

import { StoreTestCase } from '@/store'

import { IMixedAttachments } from '@/types'

import styles from './Attachment.module.scss'

type Props = {
    file: IMixedAttachments
    handleDelete: () => void
}

export const DeleteAttachmentButton = observer((props: Props) => {
    const { file, handleDelete } = props
    const { t } = useTranslation(['common'])

    const showConfirmDelete = !!!('bindId' in file && file.bindId && !StoreTestCase.executionTestCase)

    return (
        <div className={styles.DeleteButton} onClick={(e) => e.stopPropagation()}>
            {showConfirmDelete ? (
                <Popconfirm title={t('common:buttons.deleteGuard')} onConfirm={handleDelete}>
                    <Tooltip title={t('common:buttons.delete')}>
                        <CloseOutlined />
                    </Tooltip>
                </Popconfirm>
            ) : (
                <Tooltip title={t('common:buttons.delete')}>
                    <CloseOutlined onClick={handleDelete} />
                </Tooltip>
            )}
        </div>
    )
})
