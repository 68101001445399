import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Modal, notification } from 'antd'
import { FoldersTab } from '../../FoldersTree/FoldersTab'
import { SelectProject } from './SelectProject/SelectProject'

import { StoreTestCaseList, StoreTestFolder, StoreTestSet } from '@/store'
import { maxNestingLevel, pageUrls } from '@/consts'

import { IProject, ITestFolder } from '@/types'
import { EModalTypeFolder, EParentFolderType } from '@/enums'

type Props = {
    projectId: string
    isModalSelectOpen: boolean
    setIsModalSelectOpen: Dispatch<SetStateAction<boolean>>
    testFolders: ITestFolder[]
    setTestFolders: Dispatch<SetStateAction<ITestFolder[]>>
    selectedFolderId: string
    setSelectedFolderId: Dispatch<SetStateAction<string>>
    setSelectedInModalTestFolder: Dispatch<SetStateAction<string>>
    selectedInModalTestFolder: string
    selectedForActionFolder: ITestFolder
    setModalTypeFolder: Dispatch<SetStateAction<EModalTypeFolder | undefined>>
    modalTypeFolder: EModalTypeFolder | undefined
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
    isTestSetFolder?: boolean
}

export const SelectFolderModal = observer((props: Props) => {
    const {
        projectId,
        isModalSelectOpen,
        setIsModalSelectOpen,
        testFolders,
        setTestFolders,
        selectedForActionFolder,
        modalTypeFolder,
        setModalTypeFolder,
        setSelectedInModalTestFolder,
        selectedInModalTestFolder,
        isTestSetFolder,
        pageType,
    } = props

    const { t } = useTranslation(['page--testFolders', 'notification'])

    const [selectedProject, setSelectedProject] = useState<string>(projectId)
    const [projects, setProjects] = useState<IProject[]>([])
    const [selectedTestFolders, setSelectedTestFolders] = useState<ITestFolder[]>([])

    const handleCancel = () => {
        setIsModalSelectOpen(false)
    }

    const updateFoldersInModal = async () => {
        if (modalTypeFolder) {
            if (modalTypeFolder !== EModalTypeFolder.RESTORE) {
                setSelectedTestFolders(testFolders)
            } else {
                const testCaseFolders = await StoreTestFolder.getTestFolders(projectId)
                setSelectedTestFolders(testCaseFolders)
            }
        }
    }

    useEffect(() => {
        updateFoldersInModal()
    }, [testFolders, isModalSelectOpen])

    useEffect(() => {
        if (selectedProject) {
            if (pageType === pageUrls.testFolder) {
                StoreTestFolder.getTestFolders(selectedProject).then((folders: ITestFolder[]) => {
                    setSelectedTestFolders(folders)
                })
            }
            if (pageType === pageUrls.testSets) {
                StoreTestSet.getTestSetFolders(selectedProject).then((folders: ITestFolder[]) => {
                    setSelectedTestFolders(folders)
                })
            }
        }
    }, [selectedProject])

    const reset = async () => {
        StoreTestFolder.updateFoldersTree()
        if (pageType === pageUrls.testFolder) {
            StoreTestCaseList.updateTestCases()
        } else if (pageType === pageUrls.testSets) {
            StoreTestSet.updateTestSetList()
        }

        setIsModalSelectOpen(false)
        setModalTypeFolder(undefined)
    }

    const onCopyFolder = async () => {
        const props = {
            parent: selectedInModalTestFolder ? EParentFolderType.TEST_FOLDER : EParentFolderType.PROJECT,
            parentId: selectedInModalTestFolder || selectedProject,
            folderId: selectedForActionFolder.id,
            folderName: selectedForActionFolder.name,
        }

        if (pageType === pageUrls.testFolder) {
            try {
                await StoreTestFolder.copyTestFolder(props)
                reset()
            } catch {
                return
            }
        }
        if (pageType === pageUrls.testSets) {
            try {
                await StoreTestSet.copyTestSetFolder(props)
                reset()
            } catch {
                return
            }
        }
    }

    const handleOk = async () => {
        switch (modalTypeFolder) {
            case EModalTypeFolder.COPY:
                await onCopyFolder()
                break
            case EModalTypeFolder.MOVE:
                await onMoveFolder()
                break
            case EModalTypeFolder.RESTORE:
                await onRestoreFolder()
                break
        }
    }

    const onRestoreFolder = async () => {
        const props = {
            parent: selectedInModalTestFolder ? EParentFolderType.TEST_FOLDER : EParentFolderType.PROJECT,
            parentId: selectedInModalTestFolder || selectedProject,
            folderId: selectedForActionFolder.id,
            folderName: selectedForActionFolder.name,
        }

        const onMessageClick = async (id: string) => {
            StoreTestFolder.setCurrentFolderTab('testCases')
            const folders = await StoreTestFolder.getTestFolders(projectId)
            setTestFolders(folders)
        }

        await StoreTestFolder.unarchiveTestFolder(props, onMessageClick)
        await updateFoldersInModal()
        reset()
    }

    const parentHasMore1Children = () => {
        return (
            testFolders.filter((testFolder) => testFolder.parentFolderId === selectedForActionFolder?.parentFolderId)
                .length > 1
        )
    }

    const onMoveFolder = async () => {
        //нельзя переместить папку саму в себя (по умолчанию выбрана текущая папка)
        const notAllowedMoveCase1 = selectedForActionFolder.id === selectedInModalTestFolder

        //если выбрана папка 2 уровня, а в 1м разветвлений нет, тогда выбор скрыт, а значит переместить в проект
        const notAllowedMoveCase2 =
            selectedForActionFolder?.parentFolderId &&
            selectedForActionFolder.nestingLevel === 2 &&
            !parentHasMore1Children()

        //если перемещается в текущую папку
        const notAllowedMoveCase3 = selectedInModalTestFolder === selectedForActionFolder?.parentFolderId

        const shouldMoveToProject = notAllowedMoveCase1 || notAllowedMoveCase2

        const props = {
            parent:
                selectedInModalTestFolder && !shouldMoveToProject
                    ? EParentFolderType.TEST_FOLDER
                    : EParentFolderType.PROJECT,
            parentId: selectedInModalTestFolder && !shouldMoveToProject ? selectedInModalTestFolder : selectedProject,
            folderId: selectedForActionFolder.id,
            folderName: selectedForActionFolder.name,
        }

        if (!notAllowedMoveCase3) {
            if (props.parent === EParentFolderType.TEST_FOLDER) {
                const testFoldersCopy = [...testFolders]

                const movedFolder = testFoldersCopy.find((folder) => folder.id === selectedForActionFolder.id)
                let movedToFolder = testFoldersCopy.find((folder) => folder.id === selectedInModalTestFolder)

                //если undefined, значит выбран другой проект, потому надо запросить инфу о папке
                if (movedToFolder === undefined) {
                    if (pageType === pageUrls.testFolder) {
                        movedToFolder = await StoreTestFolder.getTestFolder(selectedInModalTestFolder)
                    }
                }

                if (movedFolder && movedToFolder) {
                    if (movedToFolder.nestingLevel < maxNestingLevel) {
                        movedFolder.parentFolderId = movedToFolder.id
                        movedFolder.nestingLevel = movedToFolder.nestingLevel + 1
                        movedToFolder.nestedTestCasesCount =
                            (movedToFolder.nestedTestCasesCount || 0) + (movedFolder.nestedTestCasesCount || 0)

                        setTestFolders(testFoldersCopy)

                        if (pageType === pageUrls.testFolder) {
                            await StoreTestFolder.moveTestFolder(props)
                        }
                        if (pageType === pageUrls.testSets) {
                            await StoreTestSet.moveTestSetFolder(props)
                        }

                        reset()
                    } else {
                        notification.error({
                            message: t('notification:error.maxNestinglevelReached'),
                            placement: 'bottomRight',
                        })
                    }
                }
            } else {
                if (pageType === pageUrls.testFolder) {
                    await StoreTestFolder.moveTestFolder(props)
                }
                if (pageType === pageUrls.testSets) {
                    await StoreTestSet.moveTestSetFolder(props)
                }
                reset()
            }
        } else {
            notification.warning({
                message: t('notification:warning.cantMoveToParent'),
                placement: 'bottomRight',
            })
        }
    }

    const getModalTitle = (modalTypeFolder: EModalTypeFolder) => {
        let title = ''
        switch (modalTypeFolder) {
            case EModalTypeFolder.COPY:
                title = t('page--testFolders:copyFolder')
                break
            case EModalTypeFolder.MOVE:
                title = t('page--testFolders:moveFolder')
                break
            case EModalTypeFolder.RESTORE:
                title = t('page--testFolders:folderToPlace')
                break
        }
        return title
    }

    const modalTitle = (modalTypeFolder && getModalTitle(modalTypeFolder)) || ''

    return (
        <Modal
            title={modalTitle}
            open={isModalSelectOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okButtonProps={{ disabled: !selectedProject }}
            centered
            destroyOnClose={true} //для сброса поиска и дерева в модалке при повторном открытии
        >
            {!isTestSetFolder && modalTypeFolder !== EModalTypeFolder.RESTORE && (
                <SelectProject
                    selectedProject={selectedProject}
                    setSelectedProject={setSelectedProject}
                    projects={projects}
                    setProjects={setProjects}
                />
            )}
            <FoldersTab
                testFolders={selectedTestFolders}
                setTestFolders={setSelectedTestFolders}
                selectedFolderId={selectedInModalTestFolder}
                setSelectedFolderId={setSelectedInModalTestFolder}
                selectFolderOnly
                selectedForActionFolder={selectedForActionFolder}
                modalTypeFolder={modalTypeFolder}
                pageType={pageType}
                selectFolderModal
            />
        </Modal>
    )
})
