import React, { Dispatch, Key, SetStateAction, useEffect, useState } from 'react'

import { CreateTestSetButton } from '@/pages/CreateTestSet/CreateTestSetButton'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { parseNormativeTime, useDebounce } from '@/utils'

import { Layout, Typography } from 'antd'
import { TestSetsTable } from './components/TestSetsTable/TestSetsTable'
import { ModalAddToTestSet } from '@/components/ModalAddToTestSet/ModalAddToTestSet'
import Spinner from '@/components/Spinner/Spinner'
import { TestSetsFilter } from '@/components/TestSetsFilter/TestSetsFilter'

import { StoreCreateTestSet, StoreProject, StoreTestCase, StoreTestFolder, StoreTestSet, StoreWorkspace } from '@/store'

import { ISort, ITestSet, TableFilterType } from '@/types'
import { EExecutionCaseStatus, EOrder } from '@/enums'

import styles from './TestSetsMain.module.scss'

const { Content } = Layout
const { Title } = Typography

type Props = {
    isTestSetsLoading: boolean
    setTestSetsLoading: Dispatch<SetStateAction<boolean>>
    onCreateNewTestSet?: () => void
    showInModal?: boolean
    testFolderId?: string
    testerId?: string
}

export const TestSetsMain = observer((props: Props) => {
    const { isTestSetsLoading, setTestSetsLoading, showInModal, testFolderId, onCreateNewTestSet, testerId } = props
    const { t } = useTranslation(['page--testSets'])
    const { workspaceId } = useParams()

    const [selectedTestSet, setSelectedTestSet] = useState<ITestSet>()
    const [showModalAddToTestSet, setShowModalAddToTestSet] = useState(false)
    const [testSets, setTestSets] = useState<ITestSet[]>([])
    const [filterDebounced, filter, setFilter] = useDebounce<TableFilterType | null>(200, null)
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

    const handleShowModalSelectTestCase = (testSet: ITestSet) => {
        setSelectedTestSet(testSet)
        StoreCreateTestSet.setTestSetIdInModal(testSet.id)
        setShowModalAddToTestSet(true)
    }

    useEffect(() => {
        StoreTestCase.executionTestCase && StoreTestCase.resetTestCaseData()
        StoreCreateTestSet.isCreatingTestSet && StoreCreateTestSet.resetTestSet()
    }, [])

    useEffect(() => {
        workspaceId && !StoreWorkspace.id && StoreWorkspace.setWorkspaceId(workspaceId)
    }, [workspaceId])

    const initialSort = StoreTestSet.latestSearchProps.sort || [
        {
            field: 'orderNum',
            direction: EOrder.DESC,
        },
    ]

    const [sort, setSort] = useState<ISort[]>(initialSort)

    const loadData = async (props?: any) => {
        const projectsFilter = filter?.projectId ? filter?.projectId : testerId ? [] : [StoreProject.id]

        const defaultProps = {
            filter: {
                ...filter,
                projectId: projectsFilter,
                testerId: testerId ? [testerId] : [],
                folderId: testFolderId ? testFolderId : '',
                isDeleted: false,
            },
            sort,
        }
        const data = await StoreTestSet.getTestSets(props ?? defaultProps)

        if (data.result.length) {
            data.result.map((testSet: ITestSet) => {
                if (testSet.executionCases?.length) {
                    const testCaseStatuses = [
                        ...new Set(testSet.executionCases.map((testCase) => testCase.executionCaseStatus)),
                    ]
                    testSet.progress = testCaseStatuses.reduce((acc: Record<string, number>, status) => {
                        return (
                            (acc[status] =
                                testSet.executionCases?.filter(
                                    (testCase) =>
                                        testCase.executionCaseStatus === status &&
                                        status !== EExecutionCaseStatus.SKIPPED
                                ).length || 0),
                            acc
                        )
                    }, {})

                    testSet.dueTime = (testSet.normativeTime && parseNormativeTime(testSet.normativeTime)) || {
                        hours: 0,
                        minutes: 0,
                    }
                }
            })

            setTestSets((prev) => data.result)
        } else {
            setTestSets([])
        }
        setTestSetsLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [filterDebounced, sort, testFolderId, StoreProject.id, StoreTestSet.toggleUpdateTestsets])

    useEffect(() => {
        if (StoreCreateTestSet.isCreatingTestSet && showModalAddToTestSet === false) {
            setShowModalAddToTestSet(true)
        }
    }, [StoreCreateTestSet.isCreatingTestSet])

    const title = testerId
        ? t('page--testSets:title')
        : showInModal
          ? t('page--testSets:addingToTestSet')
          : StoreTestFolder.selectedFolderName || t('page--testSets:allTestSets')

    return (
        <>
            {!isTestSetsLoading ? (
                <Content className={cn(styles.TestSets, 'content')}>
                    <div className={styles.TestSetsHeader}>
                        <div className={styles.TestSetsHeaderLeft}>
                            <Title className={styles.Title} level={4}>
                                {title}
                            </Title>
                        </div>

                        {onCreateNewTestSet && (
                            <div className={styles.TestSetsHeaderRight}>
                                {!showInModal && <CreateTestSetButton onCreateNewTestSet={onCreateNewTestSet} />}
                            </div>
                        )}
                    </div>

                    <TestSetsFilter
                        loadData={loadData}
                        testSets={testSets}
                        setTestSets={setTestSets}
                        filter={filterDebounced}
                        setFilter={setFilter}
                        setSort={setSort}
                        initialSort={initialSort}
                        isUserPage={!!testerId}
                    />

                    <TestSetsTable
                        testSets={testSets}
                        setSelectedRowKeys={setSelectedRowKeys}
                        selectedRowKeys={selectedRowKeys}
                        showInModal={showInModal}
                        setTestSets={setTestSets}
                        loadData={loadData}
                        handleShowModalSelectTestCase={handleShowModalSelectTestCase}
                        isUserPage={!!testerId}
                    />

                    {selectedTestSet && showModalAddToTestSet && !showInModal && testSets && setTestSets && (
                        <ModalAddToTestSet
                            testSetInfo={selectedTestSet}
                            testSets={testSets}
                            setTestSets={setTestSets}
                            showModalAddToTestSet={showModalAddToTestSet}
                            setShowModalAddToTestSet={setShowModalAddToTestSet}
                        />
                    )}
                </Content>
            ) : (
                <Spinner show />
            )}
        </>
    )
})
