import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { getTimestampInSeconds, hasLettersOrNumbers } from '@/utils'

import { Button, Modal, notification, Tooltip } from 'antd'

import { PlayCircleOutlined, StopOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreTestCase, StoreTestSet } from '@/store'

import { IStep } from '@/types'
import { EAttachment, EExecutionCaseStatus, EExecutionTestSetStatus } from '@/enums'

import styles from './TestCaseHeader.module.scss'

export const LaunchButtons = observer(() => {
    const { t } = useTranslation(['testCase', 'common'])
    const [isStartButtonDisabled, setStartButtonDisabled] = useState(false)

    const startTestCase = () => {
        StoreTestSet.launchTestCase(StoreTestCase.id)
        StoreTestCase.setExecutionCaseStarted(true)
        StoreTestCase.setExecutionCaseStatus(EExecutionCaseStatus.IN_PROCESS)
        StoreTestCase.setExecutionCaseStartedtime(getTimestampInSeconds())
        StoreTestCase.setSidebarType('testCaseStatus')
    }

    const onLauchTest = () => {
        const showModalChangeTestSetStatus =
            StoreTestCase.testCaseInfo.executionTestSetStatus === EExecutionTestSetStatus.ASSIGNED ||
            StoreTestCase.testCaseInfo.executionTestSetStatus === EExecutionTestSetStatus.POSTPONED

        if (showModalChangeTestSetStatus) {
            Modal.confirm({
                centered: true,
                title: t('testCase:testCaseMenu.changeStatusModal', {
                    name: StoreTestCase.name,
                }),
                onOk: async () => {
                    await StoreTestSet.changeTestSetStatus(
                        StoreTestCase.testCaseInfo.executionTestSetId,
                        EExecutionTestSetStatus.IN_PROGRESS
                    )
                    startTestCase()
                },
                okText: t('common:buttons.ok'),
                cancelText: t('common:buttons.cancel'),
            })
        } else {
            startTestCase()
        }
    }

    const onFinishTest = async () => {
        if (StoreTestCase.executionCaseStatus !== EExecutionCaseStatus.IN_PROCESS) {
            StoreTestCase.setExecutionCaseCompledTime(getTimestampInSeconds())

            await StoreTestSet.setCompletedTimestamp(StoreTestCase.id).then((durationExecutionCase) =>
                StoreTestCase.setDurationExecutionCase(durationExecutionCase)
            )
            await StoreTestCase.saveExecutionTestCase()
            StoreTestCase.setExecutionCaseStarted(false)
        } else {
            notification.error({
                message: t('testCase:testCaseMenu.finishTestDisabledTooltip'),
                placement: 'bottomRight',
            })
        }
    }

    const hasAllFilledRequiredSteps = useMemo(() => {
        if (StoreTestCase.testCaseInfo?.executionSteps) {
            const requiredSteps = StoreTestCase.testCaseInfo?.executionSteps?.filter((step: IStep) => step.required)
            const numberOfRequiredSteps = requiredSteps.length
            const numberOfNotEmptyRequiredSteps = requiredSteps.filter(
                (step: IStep) =>
                    (step.actualResult && hasLettersOrNumbers(step.actualResult)) ||
                    StoreTestCase.filesToUpload.some(
                        (attachment) =>
                            attachment.attachmentType === EAttachment.STEP_ACTUAL_RESULT &&
                            attachment.existingObjectId === step.id
                    ) ||
                    StoreTestCase.attachments.some(
                        (attachment) =>
                            attachment.attachmentType === EAttachment.STEP_ACTUAL_RESULT &&
                            attachment.existingObjectId === step.id
                    )
            ).length

            const hasAllFilledRequiredSteps = numberOfNotEmptyRequiredSteps === numberOfRequiredSteps
            return hasAllFilledRequiredSteps
        }
    }, [StoreTestCase.testCaseInfo?.executionSteps, StoreTestCase.filesToUpload, StoreTestCase.attachments])

    const notDisablingStatuses = [
        EExecutionCaseStatus.BLOCKED,
        EExecutionCaseStatus.FAILED,
        EExecutionCaseStatus.PASSED,
        EExecutionCaseStatus.SKIPPED,
    ]

    const isDisabledStatus = useMemo(
        () => !notDisablingStatuses.includes(StoreTestCase.executionCaseStatus),
        [StoreTestCase.executionCaseStatus]
    )

    const disabledFinishButton = useMemo(
        () =>
            (isDisabledStatus || StoreTestCase.executionCaseStatus == EExecutionCaseStatus.PASSED
                ? !hasAllFilledRequiredSteps
                : false) || SpinnerStore.show,
        [hasAllFilledRequiredSteps, SpinnerStore.show, isDisabledStatus, StoreTestCase.executionCaseStatus]
    )

    useEffect(() => {
        setStartButtonDisabled(StoreTestCase.testCaseInfo.executionTestSetStatus === EExecutionTestSetStatus.PLANNING)
    }, [StoreTestCase.testCaseInfo.executionTestSetStatus])

    const startTooltipTitle = isStartButtonDisabled ? t('testCase:testCaseMenu.notAssignedTooltip') : ''

    const finishTooltipTitle = disabledFinishButton
        ? isDisabledStatus
            ? t('testCase:testCaseMenu.finishTestDisabledTooltip')
            : t('testCase:testCaseMenu.finishTestDisabledTooltipRequired')
        : ''

    const runButtonLabel = StoreTestCase.completeWithError
        ? t('testCase:testCaseMenu.repeatTest')
        : t('testCase:testCaseMenu.runTest')

    return (
        <div className={styles.LaunchButtons}>
            {StoreTestCase.executionCaseStarted ? (
                <Tooltip title={finishTooltipTitle} placement="top">
                    <Button
                        type="primary"
                        icon={<StopOutlined />}
                        onClick={onFinishTest}
                        disabled={disabledFinishButton}
                    >
                        {t('testCase:testCaseMenu.finishTest')}
                    </Button>
                </Tooltip>
            ) : (
                StoreTestCase.executionTestCase && (
                    <Tooltip title={startTooltipTitle}>
                        <Button
                            type="primary"
                            icon={<PlayCircleOutlined />}
                            onClick={onLauchTest}
                            disabled={isStartButtonDisabled}
                        >
                            {runButtonLabel}
                        </Button>
                    </Tooltip>
                )
            )}
        </div>
    )
})
