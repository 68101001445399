// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ogfLjJ12RZxI3zpAHrvm span{font-size:12px}.ogfLjJ12RZxI3zpAHrvm svg{height:12px}.ogfLjJ12RZxI3zpAHrvm .XZOwJD5v1UISwJixJLkD{width:20px;height:20px;display:flex;justify-content:center}.ogfLjJ12RZxI3zpAHrvm .XZOwJD5v1UISwJixJLkD span{-webkit-margin-start:0;margin-inline-start:0}.ogfLjJ12RZxI3zpAHrvm .Ndq1g7GTeV_lUuXWMj1q{padding-bottom:2px}", "",{"version":3,"sources":["webpack://./src/components/Status/Status.module.scss"],"names":[],"mappings":"AACI,2BACI,cAAA,CAEJ,0BACI,WAAA,CAGJ,4CACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CAEI,iDACI,sBAAA,CAAA,qBAAA,CAKZ,4CACI,kBAAA","sourcesContent":[".Status {\n    span {\n        font-size: 12px;\n    }\n    svg {\n        height: 12px;\n    }\n\n    .SquareTag {\n        width: 20px;\n        height: 20px;\n        display: flex;\n        justify-content: center;\n        :global {\n            span {\n                margin-inline-start: 0;\n            }\n        }\n    }\n\n    .Description {\n        padding-bottom: 2px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Status": "ogfLjJ12RZxI3zpAHrvm",
	"SquareTag": "XZOwJD5v1UISwJixJLkD",
	"Description": "Ndq1g7GTeV_lUuXWMj1q"
};
export default ___CSS_LOADER_EXPORT___;
