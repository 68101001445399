import React, { Dispatch, Key, MouseEvent, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { setPositionOnContextMenu } from '@/utils'

import { Tree } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'

import { StoreCreateTestSet, StoreProject, StoreTestFolder, StoreTestSet } from '@/store'
import { pageUrls } from '@/consts'

import { DataNodeWithName, dropdownPositionType, ITestFolder } from '@/types'
import type { DirectoryTreeProps, EventDataNode } from 'antd/es/tree'

import styles from './FoldersTab.module.scss'

type Props = {
    treeData: DataNodeWithName[]
    expandedKeys: Key[]
    onExpand: DirectoryTreeProps['onExpand']
    selectedKeys: string[]
    setSelectedRecord: Dispatch<SetStateAction<ITestFolder | undefined>>
    setDropdownPosition: Dispatch<SetStateAction<dropdownPositionType>>
    setFolderName: (testFolderId: string) => void
    setSearchParams: any
    testFolders: ITestFolder[]
    setSelectedFolderId: Dispatch<SetStateAction<string>>
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
    selectFolderOnly?: boolean
    setSearchedName?: Dispatch<SetStateAction<string>>
    showInModal?: boolean
}

export const FoldersTree = observer((props: Props) => {
    const {
        treeData,
        selectedKeys,
        setSelectedRecord,
        setDropdownPosition,
        setFolderName,
        onExpand,
        expandedKeys,
        testFolders,
        setSelectedFolderId,
        selectFolderOnly,
        pageType,
        setSearchedName,
        setSearchParams,
        showInModal,
    } = props

    const navigate = useNavigate()
    const { pathname } = useLocation()

    const handleSelectFolder: DirectoryTreeProps['onSelect'] = (key) => {
        !selectFolderOnly && key[0] && setSearchParams(`?testFolder=${key[0]}`)
        StoreTestFolder.showSelectedFolderId && StoreTestFolder.setShowSelectedFolderId('')
        setSearchedName && setSearchedName('')

        if (selectFolderOnly) {
            setSelectedFolderId(key[0] as string)
        }

        if (StoreCreateTestSet.isCreatingTestSet) {
            setFolderName(key[0] as string)
        }

        if (pageType === pageUrls.testSets) {
            StoreTestSet.setSelectedTestSetFolderId(key[0] as string)
        }

        if (!showInModal) {
            const isTestSetPage = !!matchPath(`${pageUrls.testSet}/${StoreTestSet.id}`, pathname)
            isTestSetPage && navigate(`${pageUrls.project}/${StoreProject.id}${pageUrls.testSets}?testFolder=${key[0]}`)
        }
    }

    const handleRightClick = (event: MouseEvent<any, any>, node: EventDataNode<DataNodeWithName>) => {
        if (selectFolderOnly) {
            return
        }

        const testFolder = testFolders.find((folder) => folder.id === node.key)

        setSelectedRecord(testFolder)
        setPositionOnContextMenu({ e: event, record: testFolder, setSelectedRecord, setDropdownPosition })
    }

    return (
        <Tree
            showLine
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            onSelect={handleSelectFolder}
            selectedKeys={selectedKeys}
            switcherIcon={() => <CaretDownOutlined className={styles.SwitcherIcon} />}
            treeData={treeData}
            className={styles.Tree}
            onRightClick={({ event, node }) => handleRightClick(event, node)}
        />
    )
})
