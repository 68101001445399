// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sLElmLvvUKou_ZQ5nlJk{display:flex;gap:10px}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Content/StepsPanel/MenuForSteps/MenuForSteps.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,QAAA","sourcesContent":[".MenuForSteps {\n    display: flex;\n    gap: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MenuForSteps": "sLElmLvvUKou_ZQ5nlJk"
};
export default ___CSS_LOADER_EXPORT___;
