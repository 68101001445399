import React, { useEffect, useState } from 'react'

import i18n from 'i18next'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'

import { Select } from 'antd'
import { LanguageDropdown } from './LanguageDropdown'

import { GlobalOutlined } from '@ant-design/icons'

import { PreferencesStore } from '@/store/preferences-store'

import styles from './LanguageSwitcher.module.scss'

type Props = {
    isHeader?: boolean
}

export const LanguageSwitcher = observer((props: Props) => {
    const { isHeader } = props

    const changeLanguage = (value: string) => {
        i18n.changeLanguage(value)
        runInAction(() => (PreferencesStore.locale = value))
    }

    if (isHeader) {
        return (
            <LanguageDropdown>
                <GlobalOutlined className={styles.HeaderLanguageSwitcher} />
            </LanguageDropdown>
        )
    } else {
        return (
            <div className={styles.LanguageSwitcher}>
                <GlobalOutlined className={styles.Globe} />
                <Select
                    className={styles.Select}
                    defaultValue={i18n.language}
                    bordered={false}
                    onChange={changeLanguage}
                    options={PreferencesStore.languages.map((lang) => ({ value: lang.locale, label: lang.name }))}
                />
            </div>
        )
    }
})
