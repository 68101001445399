// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sL9CnzPdQRyDQ25yd98a{display:flex;align-items:center}.sL9CnzPdQRyDQ25yd98a .XIys6Pn0b0UsyMTtrGgQ svg{width:10px}", "",{"version":3,"sources":["webpack://./src/components/ExportButton/ExportButton.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CAEA,gDACI,UAAA","sourcesContent":[".ExportButton {\n    display: flex;\n    align-items: center;\n\n    .DownSmallIcon svg {\n        width: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExportButton": "sL9CnzPdQRyDQ25yd98a",
	"DownSmallIcon": "XIys6Pn0b0UsyMTtrGgQ"
};
export default ___CSS_LOADER_EXPORT___;
