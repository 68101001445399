import React from 'react'

import { useTranslation } from 'react-i18next'

import { Button, Tooltip } from 'antd'
import { AutoIcon } from '@/components/Icons/AutoTestCase/AutoIcon'

import styles from './AutoTestCaseIcon.module.scss'

const AutoTestCaseIcon = () => {
    const { t } = useTranslation(['page--testCases'])

    return (
        <div>
            <Tooltip title={t('page--testCases:type.automated')}>
                <Button type="default" className={styles.AutoTestCaseIcon}>
                    <AutoIcon />
                </Button>
            </Tooltip>
        </div>
    )
}

export default AutoTestCaseIcon
