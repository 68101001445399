import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { List, Typography } from 'antd'
import { UserCard } from '@/components/UserCard/UserCard'
import { ActionButton } from './ActionButton/ActionButton'

import { pageUrls } from '@/consts'

import { IGetUserIn, IUserWidthFilter } from '@/types'

import styles from './GlobalSearchResults.module.scss'

const { Text } = Typography
const { Item } = List

type Props = {
    searchResults: IUserWidthFilter[]
    excludeUser: (user: IGetUserIn | IUserWidthFilter) => void
    addUser: (user: IGetUserIn | IUserWidthFilter) => void
    searchValue: string
    canManageUsers: boolean
    checkIfUserLastAdmin: (user: IGetUserIn | IUserWidthFilter) => boolean
    firstNameSearchValue?: string
    lastNameSearchValue?: string
}

export const GlobalSearchResults = observer((props: Props) => {
    const {
        searchResults,
        excludeUser,
        addUser,
        searchValue,
        canManageUsers,
        checkIfUserLastAdmin,
        firstNameSearchValue,
        lastNameSearchValue,
    } = props
    const { t } = useTranslation(['page--workspaceSettings'])

    return (
        <div className={styles.GlobalSearchResults}>
            <Text type="secondary">{t('page--workspaceSettings:team.globalSearchUser')}</Text>
            <List
                className={styles.List}
                itemLayout="horizontal"
                dataSource={searchResults}
                renderItem={(user) => (
                    <Item
                        key={user.id}
                        className={styles.Item}
                        actions={[
                            canManageUsers && (
                                <ActionButton
                                    key={user.id}
                                    user={user}
                                    excludeUser={excludeUser}
                                    addUser={addUser}
                                    checkIfUserLastAdmin={checkIfUserLastAdmin}
                                    pageType={pageUrls.workspaceSettings}
                                    searchArea={'global'}
                                />
                            ),
                        ]}
                    >
                        <UserCard
                            userName={`${user.firstname} ${user.lastname}`}
                            position={user.position}
                            avatar={null}
                            size={32}
                            searchValue={searchValue}
                            lastNameSearchValue={lastNameSearchValue}
                            firstNameSearchValue={firstNameSearchValue}
                            userFirstName={user.firstname}
                            userLastName={user.lastname}
                            isGlobalSearchResults
                        />
                    </Item>
                )}
            />
        </div>
    )
})
