import React, { Dispatch, Key, SetStateAction, useMemo } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { Table, TablePaginationConfig, Typography } from 'antd'
import { Status } from '@/components/Status/Status'

import { SpinnerStore } from '@/store'
import { pageUrls } from '@/consts'

import { ITestSet } from '@/types'
import { ColumnsType } from 'antd/es/table/interface'

import styles from './TestSetsTable.module.scss'

const { Text } = Typography

interface ITestSetList {
    testSets: ITestSet[]
    pageNumber: number
    elementsCount: number
    pageSize: number
    setPageNumber: Dispatch<SetStateAction<number>>
    setPageSize: Dispatch<SetStateAction<number>>
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    selectedRowKeys: Key[]
    showInModal?: boolean
}

interface ITableColumns {
    (testSets: ITestSet[]): ColumnsType<ITestSet> | any
}

export const TestSetsTable = observer((props: ITestSetList) => {
    const { testSets, pageNumber, elementsCount, pageSize, setPageNumber, setPageSize, showInModal } = props

    const getTableColumns: ITableColumns = (testSets) => {
        if (!testSets) return []
        return [
            {
                key: 'name',
                className: cn('sideBarColumn', 'linkColumn', styles.MainColumn),
                dataIndex: 'name',

                render: (testSetName: string, testSet: ITestSet) => (
                    <Link
                        className={cn(styles.TestSetRowLinkWrapper)}
                        draggable="false"
                        to={`${pageUrls.testSet}/${testSet?.id}`}
                    >
                        <div key={testSet.orderNum} className={styles.TestSetRow}>
                            {testSet.executionTestSetStatus && (
                                <div className={styles.Status}>
                                    <Status status={testSet.executionTestSetStatus} shortened />
                                </div>
                            )}
                            <div className={styles.Title}>
                                <Text className={styles.OrderNum} type="secondary">
                                    ID {testSet.orderNum}
                                </Text>
                                <Text className={styles.TestSetName} ellipsis={{ tooltip: testSet.name }}>
                                    {testSet.name}
                                </Text>
                            </div>
                        </div>
                    </Link>
                ),
            },
        ]
    }

    const columns = useMemo(() => getTableColumns(testSets), [testSets])

    const handleTableChange = (pagination: TablePaginationConfig) => {
        setPageNumber(pagination.current!)
        setPageSize(pagination.pageSize!)
    }

    return (
        <Table
            className={styles.TestSetTable}
            columns={columns}
            onChange={handleTableChange}
            dataSource={testSets}
            rowKey={'id'}
            pagination={{
                showSizeChanger: true,
                pageSize: pageSize,
                defaultCurrent: pageNumber,
                total: elementsCount,
                hideOnSinglePage: true,
            }}
            size="small"
            scroll={showInModal ? { y: '50vh' } : undefined}
            loading={SpinnerStore.show}
        />
    )
})
