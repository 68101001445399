import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button } from 'antd'
import { TooltipWithDisabling } from '@/components/TooltipWithDisabling'

import { RecordIcon } from '@/components/Icons'
import { PlusOutlined } from '@ant-design/icons'

import { SpinnerStore } from '@/store'

import { IStep } from '@/types'
import { EStepType } from '@/enums'

import styles from './AddNewStepButtons.module.scss'

type Props = {
    steps: IStep[]
    setSteps: Dispatch<SetStateAction<IStep[]>>
    stepType: EStepType
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
}

export const AddNewStepButtons = observer((props: Props) => {
    const { steps, setSteps, stepType, setTestCaseSavedStatus } = props
    const { t } = useTranslation(['testCase', 'common'])

    const [count, setCount] = useState(steps.length)
    const [showRecordToolTip, setShowRecordToolTip] = useState(false)
    const [isAnyManualStep, setIsAnyManualStep] = useState(false)

    useEffect(() => {
        setIsAnyManualStep(steps.some((step) => step.manual))
    }, [steps])

    useEffect(() => {
        if (count !== steps.length) {
            setCount(steps.length)
        }
    }, [steps.length])

    const handleAdd = async () => {
        if (stepType === EStepType.DEFAULT_STEP) {
            const newData: IStep = {
                orderNum: count + 1,
                description: ``,
                expectedResult: '',
                testData: ``,
                comment: ``,
                manual: true,
                required: false,
                stepType: stepType,
                actions: [
                    {
                        description: '',
                        orderNum: 1,
                    },
                ],
            }
            setSteps([...steps, newData])
            await setTestCaseSavedStatus(false)
        } else {
            const newData: IStep = {
                orderNum: count + 1,
                description: '',
                expectedResult: '',
                testData: ``,
                comment: ``,
                manual: true,
                required: false,
                stepType: stepType,
            }
            setSteps([...steps, newData])
            await setTestCaseSavedStatus(false)
        }

        setCount(count + 1)
    }

    const handleRecordStep = async () => {
        console.log('recording...')
        await setTestCaseSavedStatus(false)
    }

    return (
        <div className={styles.AddNewStepButtons}>
            <Button onClick={handleAdd} type="default" icon={<PlusOutlined />} disabled={SpinnerStore.show}>
                {t('testCase:addStep')}
            </Button>

            {stepType === EStepType.DEFAULT_STEP && (
                <TooltipWithDisabling
                    disabled={isAnyManualStep}
                    title={`${t('testCase:mixStepsNotification')} + (${t('common:inDevelopment')}))`}
                    isVisible={showRecordToolTip}
                    setIsVisible={setShowRecordToolTip}
                    placement="bottom"
                >
                    <Button
                        onClick={handleRecordStep}
                        type="default"
                        icon={<RecordIcon />}
                        disabled={SpinnerStore.show || isAnyManualStep}
                    >
                        {t('testCase:recordStep')}
                    </Button>
                </TooltipWithDisabling>
            )}
        </div>
    )
})
