import React, { Dispatch, SetStateAction, useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Modal, Typography } from 'antd'

import { MailOutlined } from '@ant-design/icons'

import { StoreAuthController } from '@/store'
import { emailConfirmationTimeOut, pageUrls } from '@/consts'

import styles from '../SignInUp.module.scss'

const { Paragraph } = Typography

type Props = {
    isOpenedEmailConfirmation: boolean
    setIsOpenedEmailConfirmation: Dispatch<SetStateAction<boolean>>
    showCounter: boolean
    setShowCounter: Dispatch<SetStateAction<boolean>>
    counter: number
    setCounter: Dispatch<SetStateAction<number>>
}

export const EmailConfirmationModal = observer((props: Props) => {
    const { t } = useTranslation(['validation', 'page--auth'])
    const {
        isOpenedEmailConfirmation,
        setIsOpenedEmailConfirmation,
        showCounter,
        setShowCounter,
        counter,
        setCounter,
    } = props
    const navigate = useNavigate()

    const onCancel = () => {
        setIsOpenedEmailConfirmation(false)
        navigate(pageUrls.signIn)
    }

    useEffect(() => {
        if (showCounter) {
            const timeout = setTimeout(() => setCounter(counter - 1), 1000)
            if (counter > 0 && timeout) {
                return () => {
                    clearTimeout(timeout)
                }
            } else {
                clearTimeout(timeout)
                setShowCounter(false)
            }
        }
    }, [showCounter, counter])

    const handleOk = () => {
        setCounter(emailConfirmationTimeOut)
        setShowCounter(true)
        StoreAuthController.emailConfirmation()
    }

    return (
        <Modal
            className={styles.SignUpModal}
            open={isOpenedEmailConfirmation}
            centered
            onCancel={onCancel}
            title={
                <>
                    <MailOutlined style={{ marginRight: 16, color: '#3F51B5' }} />
                    {t('page--auth:emailConfirmationTitle')}
                </>
            }
            footer={[
                <Button key="again" type="link" size="small" disabled={counter > 0} onClick={handleOk}>
                    {counter > 0 ? (
                        <span style={{ marginRight: 8 }}>{t('page--auth:tryAgain', { counter })}</span>
                    ) : null}
                    {t('page--auth:sendToEmailAgain')}
                </Button>,
            ]}
        >
            <Paragraph style={{ paddingLeft: 36 }}>{t('page--auth:emailSanded')}</Paragraph>
        </Modal>
    )
})
