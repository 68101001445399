import { notification } from 'antd'

import { ArgsProps } from 'antd/es/notification/interface'

type MessageType = 'success' | 'info' | 'error' | 'warning'
export const showMessage = (
    type: MessageType,
    message: string,
    onClick?: () => void,
    options?: Omit<ArgsProps, 'message'>
) => {
    notification[type]({
        message: message,
        placement: 'bottomRight',
        onClick: onClick,
        ...options,
    })
}
