import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'

import { tohhmmss } from '@/utils'

import styles from './Timer.module.scss'

export const Timer = observer(() => {
    const [seconds, setSeconds] = useState(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((seconds) => seconds + 1)
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    return <div className={styles.Timer}>{tohhmmss(seconds)}</div>
})
