// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d98Vb7PDGC20BwgIUWQQ{display:flex;flex-direction:column;align-items:center;text-align:center;gap:40px;width:570px;position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}", "",{"version":3,"sources":["webpack://./src/components/DataPlaceholder/DataPlaceholder.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,iBAAA,CACA,QAAA,CACA,WAAA,CACA,iBAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA","sourcesContent":[".DataPlaceholder {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    text-align: center;\n    gap: 40px;\n    width: 570px;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DataPlaceholder": "d98Vb7PDGC20BwgIUWQQ"
};
export default ___CSS_LOADER_EXPORT___;
