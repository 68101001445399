// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ZmE_l8zlmuJg3DMbzMJO{display:flex;align-items:center;justify-content:flex-end;gap:8px}.ZmE_l8zlmuJg3DMbzMJO .c0l6RmE3T6bCLykoHS6e{color:#fff;background:var(--color-primary-dark)}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Header/TestCaseMenu/TestCaseMenu.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,wBAAA,CACA,OAAA,CAEA,4CACI,UAAA,CACA,oCAAA","sourcesContent":[".TopRight {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n    gap: 8px;\n\n    .SaveButtonActive {\n        color: white;\n        background: var(--color-primary-dark);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TopRight": "ZmE_l8zlmuJg3DMbzMJO",
	"SaveButtonActive": "c0l6RmE3T6bCLykoHS6e"
};
export default ___CSS_LOADER_EXPORT___;
