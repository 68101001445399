import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Button, Modal, Select, Typography } from 'antd'
import { IntegrationCard } from './IntegrationCard/IntegrationCard'
import { JiraForm } from './JiraForm/JiraForm'

import { JiraLogo } from '@/components/Icons'
import { PlusOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreProject, StoreTestCase } from '@/store'

import type { IJiraIntegrationInfo } from '@/types'

import styles from './Integrations.module.scss'

const { Title, Text } = Typography

export const Integrations = observer(() => {
    const [isShowCreateModal, setIsShowCreateModal] = useState(false)
    const [modalView, setModalView] = useState<'list' | 'jira'>('list')
    const [isEditing, setIsEditing] = useState(false)

    const { projectId } = useParams()

    const { t } = useTranslation(['page--projectSettings', 'common'])

    useEffect(() => {
        if (projectId) {
            StoreTestCase.getJiraIntegrationAvailability(projectId).then((availability) => {
                if (availability) {
                    StoreProject.getIntegrationInfo(projectId)
                }
            })
        }
        return () => {
            StoreProject.integrationInfo = null as unknown as IJiraIntegrationInfo
        }
    }, [projectId])

    const onCancelJiraForm = () => {
        setIsShowCreateModal(false)
        setModalView('list')
        isEditing && setIsEditing(false)
    }

    const onCancelNewConnectionModal = () => {
        setIsShowCreateModal(false)
        setModalView('list')
        isEditing && setIsEditing(false)
    }

    const onOpenModal = () => {
        setIsShowCreateModal(true)
        setModalView('jira')
    }

    const onCloseJiraModal = () => {
        setIsShowCreateModal(false)
        isEditing && setIsEditing(false)
        setModalView('list')
        SpinnerStore.setShow(false)
    }

    const handleDelete = async () => {
        if (projectId) {
            await StoreProject.deleteJiraIntegration(projectId)
            StoreProject.setIntegrationInfo(null as unknown as IJiraIntegrationInfo)
            StoreProject.setJiraIntegrationAvailability(false)
        }
    }

    const onDelete = () => {
        Modal.confirm({
            centered: true,
            title: t('common:buttons.deleteGuard'),
            onOk: handleDelete,
            okText: t('common:buttons.ok'),
            cancelText: t('common:buttons.cancel'),
        })
    }

    const handleSelect = () => {
        setModalView('jira')
    }

    const JiraLabel = (
        <span className={styles.Label}>
            <JiraLogo />
            {t('page--projectSettings:integrations.jira.title')}
        </span>
    )

    return (
        <div className={styles.IntegrationPage}>
            <Title level={4} className={styles.Title}>
                {t('page--projectSettings:integrations.title')}
            </Title>

            <div className={styles.Integrations}>
                {StoreProject.integrationInfo ? (
                    <IntegrationCard
                        name={StoreProject.integrationInfo.projectKey}
                        url={StoreProject.integrationInfo.jiraLink}
                        version={StoreProject.integrationInfo.jiraVersion}
                        scrumBoardId={StoreProject.integrationInfo.scrumBoardId}
                        loading={SpinnerStore.show}
                        openModal={onOpenModal}
                        setIsEditing={setIsEditing}
                        onDelete={onDelete}
                    />
                ) : (
                    <div className={styles.NoIntegrations}>
                        <Text className={styles.IntegrationsTitleNotFound}>
                            {t('page--projectSettings:integrations.integrationNotFound')}
                        </Text>
                        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsShowCreateModal(true)}>
                            {t('common:buttons.create')}
                        </Button>
                    </div>
                )}
                <Modal
                    title={
                        isEditing
                            ? t('page--projectSettings:integrations.connectionSettings')
                            : t('page--projectSettings:integrations.newIntegration')
                    }
                    onOk={handleSelect}
                    onCancel={isEditing ? onCancelJiraForm : onCancelNewConnectionModal}
                    open={isShowCreateModal}
                    centered
                    footer={
                        modalView !== 'jira'
                            ? [
                                  <Button key="cancel" onClick={onCancelNewConnectionModal}>
                                      {t('common:buttons.cancel')}
                                  </Button>,
                                  <Button
                                      key="continue"
                                      type="primary"
                                      onClick={handleSelect}
                                      disabled={SpinnerStore.show}
                                  >
                                      {t('common:buttons.continue')}
                                  </Button>,
                              ]
                            : []
                    }
                >
                    {modalView === 'jira' ? (
                        <JiraForm closeModal={onCloseJiraModal} onCancel={onCancelJiraForm} isEditing={isEditing} />
                    ) : (
                        <div className={styles.IntegrationsServiceSelection}>
                            <Text>{t('page--projectSettings:integrations.selectIntegrationService')}</Text>
                            <Select
                                defaultValue="jira"
                                options={[{ value: 'jira', label: JiraLabel }]}
                                onChange={handleSelect}
                                className={styles.Select}
                            />
                        </div>
                    )}
                </Modal>
            </div>
        </div>
    )
})
