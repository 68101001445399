// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".T19xy72RlPpmTQJtpNny{background:var(--color-other-divider)}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Header/TestCaseDropdownMenu/TestCaseDropdownMenu.module.scss"],"names":[],"mappings":"AAAA,sBACI,qCAAA","sourcesContent":[".Divider {\n    background: var(--color-other-divider);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Divider": "T19xy72RlPpmTQJtpNny"
};
export default ___CSS_LOADER_EXPORT___;
