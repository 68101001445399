import React from 'react'

import { DiscordIcon, TelegramIcon, ViberIcon, VKIcon } from '@/components/Icons'
import { MailOutlined, PhoneOutlined, SkypeFilled, WhatsAppOutlined } from '@ant-design/icons'

import { IUserContacts } from '@/types'

import styles from './ContactIcons.module.scss'

const contactIcons = {
    phone: <PhoneOutlined className={styles.ContactIcon} />,
    email: <MailOutlined className={styles.ContactIcon} />,
    SKYPE: <SkypeFilled className={styles.ContactIcon} />,
    WHATSAPP: <WhatsAppOutlined className={styles.ContactIcon} />,
    TELEGRAM: <TelegramIcon className={styles.ContactIcon} />,
    VK: <VKIcon className={styles.ContactIcon} />,
    DISCORD: <DiscordIcon className={styles.ContactIcon} />,
    VIBER: <ViberIcon className={styles.ContactIcon} />,
}

export const getContactIcon = (iconName: IUserContacts) => contactIcons[iconName]
