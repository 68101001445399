// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".s5VrSpCtLa_BcVd135nH{display:flex;align-items:center;justify-content:center}", "",{"version":3,"sources":["webpack://./src/pages/TestFolders/components/Modals/SelectFolderModal/SelectProject/SelectProject.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA","sourcesContent":[".Loader {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Loader": "s5VrSpCtLa_BcVd135nH"
};
export default ___CSS_LOADER_EXPORT___;
