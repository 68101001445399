import React, { Dispatch, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Modal } from 'antd'
import { FoldersTab } from '../FoldersTree/FoldersTab'

import { StoreTestFolder } from '@/store'
import { pageUrls } from '@/consts'

import { ITestFolder } from '@/types'

type Props = {
    isSelectAndCreateModalOpen: boolean
    setIsSelectAndCreateModalOpen: Dispatch<SetStateAction<boolean>>
    testFolders: ITestFolder[]
    setTestFolders: Dispatch<SetStateAction<ITestFolder[]>>
    selectedFolderId: string
    setSelectedFolderId: Dispatch<SetStateAction<string>>
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
    onCreateFolder: () => void
    onCreateItem: () => void
}

export const SelectAndCreateModal = observer((props: Props) => {
    const {
        isSelectAndCreateModalOpen,
        setIsSelectAndCreateModalOpen,
        testFolders,
        setTestFolders,
        selectedFolderId,
        setSelectedFolderId,
        onCreateFolder,
        onCreateItem,
        pageType,
    } = props

    const { t } = useTranslation(['page--testFolders'])

    const selectFolder = () => {
        setIsSelectAndCreateModalOpen(false)
        onCreateItem()
    }

    const handleCancel = () => {
        setIsSelectAndCreateModalOpen(false)
    }

    return (
        <Modal
            title={t('page--testFolders:selectFolder')}
            open={isSelectAndCreateModalOpen}
            onOk={selectFolder}
            onCancel={handleCancel}
            okButtonProps={{ disabled: !selectedFolderId }}
            centered
        >
            <FoldersTab
                testFolders={testFolders}
                setTestFolders={setTestFolders}
                selectedFolderId={selectedFolderId}
                setSelectedFolderId={setSelectedFolderId}
                onCreateFolder={onCreateFolder}
                selectFolderOnly={!!testFolders.length}
                selectAndCreateModal
                pageType={pageType}
                selectFolderModal
            />
        </Modal>
    )
})
