export enum EAuthority {
    USERS_MANAGEMENT = 'USERS_MANAGEMENT',
    CREATE_WORKSPACE = 'CREATE_WORKSPACE',
    SHOW_ALL_WORKSPACES = 'SHOW_ALL_WORKSPACES',
    MANAGE_ANY_WORKSPACE = 'MANAGE_ANY_WORKSPACE',
}

export enum EmailEventType {
    PASSWORD_CHANGE_EVENT = 'PASSWORD_CHANGE_EVENT',
    EMAIL_CONFIRMATION_EVENT = 'EMAIL_CONFIRMATION_EVENT',
}
