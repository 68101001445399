// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Hs48oifopSocQTKGHEta{top:20px;width:auto !important;height:85vh}.Hs48oifopSocQTKGHEta .ant-modal-content .ant-modal-body{height:80vh !important}", "",{"version":3,"sources":["webpack://./src/components/ModalAddToTestSet/ModalAddToTestSet.module.scss"],"names":[],"mappings":"AAAA,sBACI,QAAA,CACA,qBAAA,CACA,WAAA,CAGI,yDACI,sBAAA","sourcesContent":[".ModalAddToTestSet {\n    top: 20px;\n    width: auto !important;\n    height: 85vh;\n\n    :global {\n        .ant-modal-content .ant-modal-body {\n            height: 80vh !important;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ModalAddToTestSet": "Hs48oifopSocQTKGHEta"
};
export default ___CSS_LOADER_EXPORT___;
