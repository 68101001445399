import React, { Dispatch, ReactElement, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { copyLink, useOnOutsideClick } from '@/utils'

import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import { ModalOnDeleteRow } from '@/components/ModalOnDeleteRow/ModalOnDeleteRow'

import { DeleteOutlined, LinkOutlined, SettingOutlined } from '@ant-design/icons'

import { apiUrls, pageUrls } from '@/consts'

import { dropdownPositionType, IProject } from '@/types'

interface Props {
    project: IProject
    projects: IProject[]
    setProjects: Dispatch<SetStateAction<IProject[]>>
    loadData: () => void
    elementsCount: number
    pageSize: number
    setSelectedRecord?: Dispatch<SetStateAction<IProject | undefined>>
    dropdownPosition?: dropdownPositionType
    button?: ReactElement
}

export const ProjectsOptions = observer((props: Props) => {
    const {
        project,
        projects,
        loadData,
        elementsCount,
        pageSize,
        setProjects,
        dropdownPosition,
        setSelectedRecord,
        button,
    } = props
    const { t } = useTranslation(['page--projects', 'common'])
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const closeContextDropdown = () => {
        setSelectedRecord && setSelectedRecord(undefined)
    }

    const onOpenChange = () => {
        button && closeContextDropdown()
    }

    const isContextMenu = project && dropdownPosition
    isContextMenu && useOnOutsideClick(closeContextDropdown)

    const onCopyLink = () => {
        const href = `${window.location.origin}${pageUrls.project}/${project.id}`
        copyLink(href)
    }

    const onDelete = (project: IProject) => {
        project.id &&
            ModalOnDeleteRow({
                id: project.id,
                name: project.projectName,
                items: projects,
                setItems: setProjects as any,
                elementsCount: elementsCount,
                pageSize: pageSize,
                loadData: loadData,
                specificApiUrl: apiUrls.project,
                title: t('page--projects:deleteGuard.title', {
                    name: project.projectName,
                }),
                content: t('page--projects:deleteGuard.content'),
                okText: t('common:buttons.ok'),
                cancelText: t('common:buttons.cancel'),
            })
    }

    const items: MenuProps['items'] = [
        {
            key: 'deleteItem',
            label: t('common:buttons.delete'),
            icon: <DeleteOutlined />,
            onClick: () => onDelete(project),
        },
        {
            key: 'parametrs',
            label: t('common:buttons.parameters'),
            icon: <SettingOutlined />,
            onClick: () => navigate(`${pageUrls.projectSettings}/${project.id}`, { state: { previousPath: pathname } }),
        },

        {
            key: 'copyLink',
            label: t('page--projects:copyLink'),
            icon: <LinkOutlined />,
            onClick: onCopyLink,
        },
    ]

    return (
        <Dropdown
            onOpenChange={onOpenChange}
            menu={{ items }}
            open={!!button ? undefined : !!project}
            overlayStyle={isContextMenu ? { top: dropdownPosition?.y, left: dropdownPosition?.x } : undefined}
            placement="bottomLeft"
        >
            {button ? button : <></>}
        </Dropdown>
    )
})
