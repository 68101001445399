import React, { FC } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Typography } from 'antd'

import styles from './CustomFields.module.scss'

const { Title } = Typography

export const CustomFields: FC = observer(() => {
    const { t } = useTranslation(['page--workspaceSettings'])

    return (
        <div className={styles.CustomFields}>
            <Title level={4} className={styles.SettingTitle}>
                {t('page--workspaceSettings:customFields.title')}
            </Title>
        </div>
    )
})
