import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Radio } from 'antd'
import type { RadioChangeEvent } from 'antd'

import { BugFilled, CheckCircleOutlined, MinusCircleOutlined, WarningOutlined } from '@ant-design/icons'

import { StoreTestCase } from '@/store'

import { EExecutionCaseStatus } from '@/enums'

import styles from './TestCaseStatusButtons.module.scss'

const { Button, Group } = Radio

export const TestCaseStatusButtons = observer(() => {
    const { t } = useTranslation(['statuses'])

    const onSelectStatus = (e: RadioChangeEvent) => {
        if (StoreTestCase.isSaved && e.target.value !== StoreTestCase.executionCaseStatus) {
            StoreTestCase.setIsSaved(false)
        }
        StoreTestCase.setExecutionCaseStatus(e.target.value)
    }

    return (
        <Group
            value={StoreTestCase.executionCaseStatus}
            disabled={!StoreTestCase.executionCaseStarted}
            onChange={onSelectStatus}
            optionType="button"
            className={styles.TestCaseStatusButtons}
        >
            <Button value={EExecutionCaseStatus.PASSED} className={styles.RadioButton}>
                <CheckCircleOutlined className={styles.IconSuccess} />
                <span>{t(`statuses:${EExecutionCaseStatus.PASSED}`)}</span>
            </Button>
            <Button value={EExecutionCaseStatus.BLOCKED}>
                <MinusCircleOutlined className={styles.IconBlocked} />
                <span>{t(`statuses:${EExecutionCaseStatus.BLOCKED}`)}</span>
            </Button>
            <Button value={EExecutionCaseStatus.SKIPPED} className={styles.RadioButton}>
                <WarningOutlined className={styles.IconSkipped} />
                <span>{t(`statuses:${EExecutionCaseStatus.SKIPPED}`)}</span>
            </Button>
            <Button value={EExecutionCaseStatus.FAILED} className={styles.RadioButton}>
                <BugFilled className={styles.IconFailed} />
                <span>{t(`statuses:${EExecutionCaseStatus.FAILED}`)}</span>
            </Button>
        </Group>
    )
})
