// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".O0egdaOeSNOG0CvaN1GX svg{font-size:16px !important}.Cq8Oxu9diEM4Sdj6i404 .poy6_xDx2vIw40oCxi7E{display:flex;flex-direction:column;gap:8px}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Content/StepIssues/StepIssues.module.scss"],"names":[],"mappings":"AACI,0BACI,yBAAA,CAKJ,4CACI,YAAA,CACA,qBAAA,CACA,OAAA","sourcesContent":[".JiraBugIcon {\n    svg {\n        font-size: 16px !important;\n    }\n}\n\n.StepIssues {\n    .JiraTasks {\n        display: flex;\n        flex-direction: column;\n        gap: 8px;\n    }\n}\n\n//TODO - панель форматирования отключена, так как в джире своя\n// :global {\n//     .ql-toolbar {\n//         height: 40px;\n//         min-width: max-content;\n//     }\n\n//     .ant-form-item-control-input-content > div {\n//         width: 100%;\n//     }\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"JiraBugIcon": "O0egdaOeSNOG0CvaN1GX",
	"StepIssues": "Cq8Oxu9diEM4Sdj6i404",
	"JiraTasks": "poy6_xDx2vIw40oCxi7E"
};
export default ___CSS_LOADER_EXPORT___;
