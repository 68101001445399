import React, { Dispatch, Key, SetStateAction, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { extractStepDataForCopy, resetNumeration } from '@/utils/table'

import { Button, Checkbox, Tooltip } from 'antd'

import { EmptyShield, RecordIcon, ShieldIcon } from '@/components/Icons'
import { CopyOutlined, DeleteOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreCreateTestSet, StoreTestCase } from '@/store'

import { IStep, StepType } from '@/types'
import { EStepType } from '@/enums'

import styles from './StepMenu.module.scss'

type Props = {
    step: IStep
    steps: IStep[]
    setSteps: Dispatch<SetStateAction<IStep[]>>
    selectedRowKeys: Key[]
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    isExecutionTestCase: boolean
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
}

export const StepMenu = observer((props: Props) => {
    const { step, setSteps, steps, selectedRowKeys, setSelectedRowKeys, isExecutionTestCase, setTestCaseSavedStatus } =
        props
    const { t } = useTranslation(['testCase', 'common'])
    const checked = useMemo(() => selectedRowKeys.includes(step.orderNum), [selectedRowKeys, step])

    const toggleRequired = async (orderNum: number) => {
        const index = steps.findIndex((step) => step.orderNum === orderNum)
        const updatedSteps = [...steps]

        if (index !== -1) {
            updatedSteps[index].required = !updatedSteps[index].required
        }

        setSteps(updatedSteps)
        StoreTestCase.setRequiredStepChanged(true)
        await setTestCaseSavedStatus(false)
    }

    const handleDelete = async (orderNum: number) => {
        const updatedSteps = steps.filter((item) => item.orderNum !== orderNum)
        const updatedStepsWithUpdatedNumeration = resetNumeration(updatedSteps)

        StoreTestCase.handleMoveAttachmentsOnDelete([orderNum], steps, updatedSteps, updatedStepsWithUpdatedNumeration)
        setSteps(updatedStepsWithUpdatedNumeration)
        await setTestCaseSavedStatus(false)
    }

    const onStepCopy = async (orderNum: number) => {
        const rowCopy = steps.find((item) => item.orderNum === orderNum)

        if (rowCopy) {
            const stepDataForCopy = extractStepDataForCopy(rowCopy)
            const stepsCopy = [...steps] as StepType[]

            stepsCopy.splice(stepDataForCopy.orderNum!, 0, stepDataForCopy)
            setSteps(resetNumeration(stepsCopy))
            await setTestCaseSavedStatus(false)
        }
    }

    const handleRecordStep = async (_orderNum: number) => {
        console.log('recording...')
        await setTestCaseSavedStatus(false)
    }

    const onSelectChange = () => {
        const selectedRowKeysCopy = [...selectedRowKeys]

        if (checked) {
            const index = selectedRowKeysCopy.indexOf(step.orderNum)
            if (index > -1) {
                selectedRowKeysCopy.splice(index, 1)
                setSelectedRowKeys(selectedRowKeysCopy)
            }
        } else {
            setSelectedRowKeys([...selectedRowKeysCopy, step.orderNum])
        }
    }

    const disabled = StoreTestCase.readOnly

    return (
        <div className={styles.StepMenu}>
            {/* TODO: 17/04/23 будет нескоро*/}
            {/* <Tooltip placement="bottom" title={t('testCase:autoStep')}>
                <Switch size="small" />
            </Tooltip> */}

            <Tooltip
                placement="bottom"
                title={step.required ? t('testCase:requiredStep') : t('testCase:notRequiredStep')}
            >
                <Button
                    ghost
                    icon={step.required ? <ShieldIcon /> : <EmptyShield />}
                    onClick={() => toggleRequired(step.orderNum)}
                    disabled={disabled}
                    className={step.required ? styles.RequiredStepButton : styles.NotRequiredStepButton}
                />
            </Tooltip>
            {step.stepType === EStepType.DEFAULT_STEP && step.manual && (
                <Tooltip
                    placement="bottom"
                    title={
                        step.description
                            ? `${t('testCase:overrideStep')} ${t('common:inDevelopment')}`
                            : t('testCase:recordStep')
                    }
                >
                    <Button
                        icon={<RecordIcon />}
                        onClick={() => handleRecordStep(step.orderNum)}
                        disabled={true}
                        className={styles.RecordStepButton}
                    />
                </Tooltip>
            )}

            <Tooltip placement="bottom" title={t('common:buttons.delete')}>
                <Button icon={<DeleteOutlined />} onClick={() => handleDelete(step.orderNum)} disabled={disabled} />
            </Tooltip>
            <Tooltip placement="bottom" title={t('common:buttons.copy')}>
                <Button icon={<CopyOutlined />} onClick={() => onStepCopy(step.orderNum)} disabled={disabled} />
            </Tooltip>

            {!isExecutionTestCase && !StoreCreateTestSet.isCreatingTestSet && (
                <Checkbox checked={checked} onChange={onSelectChange} disabled={disabled} />
            )}
        </div>
    )
})
