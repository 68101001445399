// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".U5SL6iwIvLlQiMpeuRWs{width:100%}.U5SL6iwIvLlQiMpeuRWs .blcA8fKbIkXYYLCSztA9{margin-top:12px}.U5SL6iwIvLlQiMpeuRWs .blcA8fKbIkXYYLCSztA9 .fZaRADVLNGWbR7j11jUh{display:flex;align-items:center;justify-content:space-between;padding-left:12px;padding-right:12px}.U5SL6iwIvLlQiMpeuRWs .blcA8fKbIkXYYLCSztA9 .fZaRADVLNGWbR7j11jUh:hover{background:var(--color-itemBackgroundHover);cursor:pointer;border-radius:4px}", "",{"version":3,"sources":["webpack://./src/components/SettingPageTabs/Team/Search/GlobalSearchResults/GlobalSearchResults.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,4CACI,eAAA,CACA,kEACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,iBAAA,CACA,kBAAA,CAEA,wEACI,2CAAA,CACA,cAAA,CACA,iBAAA","sourcesContent":[".GlobalSearchResults {\n    width: 100%;\n    .List {\n        margin-top: 12px;\n        .Item {\n            display: flex;\n            align-items: center;\n            justify-content: space-between;\n            padding-left: 12px;\n            padding-right: 12px;\n\n            &:hover {\n                background: var(--color-itemBackgroundHover);\n                cursor: pointer;\n                border-radius: 4px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"GlobalSearchResults": "U5SL6iwIvLlQiMpeuRWs",
	"List": "blcA8fKbIkXYYLCSztA9",
	"Item": "fZaRADVLNGWbR7j11jUh"
};
export default ___CSS_LOADER_EXPORT___;
