import React, { Dispatch, Key, SetStateAction } from 'react'

import { observer } from 'mobx-react'

import { Checkbox } from 'antd'
import { MenuForSteps } from './MenuForSteps/MenuForSteps'

import { StoreTestCase } from '@/store'

import { IStep } from '@/types'
import { EStepType } from '@/enums'

import styles from './StepsPanelExtra.module.scss'

type Props = {
    steps: IStep[]
    setSteps: Dispatch<SetStateAction<IStep[]>>
    stepType: EStepType
    showMenu: boolean
    setShowMenu: Dispatch<SetStateAction<boolean>>
    selectedSteps: Key[]
    setSelectedSteps: Dispatch<SetStateAction<Key[]>>
    onSelectAll: (setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>, selectedRowKeys: Key[], steps: IStep[]) => void
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
}

export const StepsPanelExtra = observer((props: Props) => {
    const {
        steps,
        setSteps,
        onSelectAll,
        showMenu,
        setShowMenu,
        selectedSteps,
        setSelectedSteps,
        setTestCaseSavedStatus,
    } = props

    return (
        <div className={styles.StepsMenu} onClick={(e) => e.stopPropagation()}>
            {showMenu && !StoreTestCase.readOnly && (
                <MenuForSteps
                    steps={steps}
                    setSteps={setSteps}
                    setSelectedRowKeys={setSelectedSteps}
                    selectedRowKeys={selectedSteps}
                    setShowMenu={setShowMenu}
                    setTestCaseSavedStatus={setTestCaseSavedStatus}
                />
            )}
            <Checkbox
                checked={!!selectedSteps.length}
                onChange={() => onSelectAll(setSelectedSteps, selectedSteps, steps)}
                className={styles.Checkbox}
                disabled={StoreTestCase.readOnly}
            />
        </div>
    )
})
