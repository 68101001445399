import { observable } from 'mobx'

import { useAuthorizedAPI } from '@/api'

import { IO, sliceArrayIntoChunks } from '@/utils'

import { apiLimitIds, baseApiUrl } from '@/consts'

import type { RcFile, UploadFile } from 'antd/es/upload/interface'

const baseURL = `${baseApiUrl}/ms-attachment/attachment/`

const api = useAuthorizedAPI(baseURL)

export const MsAttachmentApi = observable({
    async save(files: UploadFile[]) {
        const formData = new FormData()
        files.map((file) => formData.append('files', file.originFileObj as RcFile))

        const config = {
            headers: { 'content-type': 'multipart/form-data' },
        }

        const res = await api.post(`attachment`, formData, config)
        return res
    },

    async getAttachment(id: string, fileName: string) {
        const file = await api.get(`attachment/${id}`, {
            responseType: 'blob',
        })

        IO.download(file.data, fileName)
    },

    async getBlobAttachment(id: string) {
        const file = await api.get(`attachment/${id}`, {
            responseType: 'blob',
        })

        return file.data
    },

    async getAttachmentData(id: string) {
        const response = await api.get(`attachment/get-metadata/${id}`)
        return response.data
    },

    async getAttachmentsData(attachmentsIds: string[]) {
        const slicedAttachmentIds = sliceArrayIntoChunks(attachmentsIds, apiLimitIds)

        const attachmentsWithData = await Promise.all(
            slicedAttachmentIds.map(async (idsSlice) => {
                const request = idsSlice.map((id) => `ids=${id}`).join('&')
                const res = await api.get(`attachment/get-set-of-metadata?${request}`)
                return res.data?.attachments
            })
        )

        return attachmentsWithData.flat()
    },
})
