// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".V2BbSr32mpcUhPjo6YGY{position:absolute;top:-37px;right:0;display:flex;align-items:center;gap:8px;background:var(--color-neutral2);border:1px solid var(--color-Neutral5);border-radius:2px;padding-left:8px;padding-right:8px;padding-top:4px;padding-bottom:4px}.V2BbSr32mpcUhPjo6YGY .KVsKzX05PJsqHYdi_A1D,.V2BbSr32mpcUhPjo6YGY .M8iO9_SOEgho17WME5Oe{display:flex;align-items:center;justify-content:center}.V2BbSr32mpcUhPjo6YGY .KVsKzX05PJsqHYdi_A1D svg,.V2BbSr32mpcUhPjo6YGY .M8iO9_SOEgho17WME5Oe svg{font-size:18px}.V2BbSr32mpcUhPjo6YGY .M8iO9_SOEgho17WME5Oe svg{color:#fff;-webkit-filter:grayscale(100%);filter:grayscale(100%)}.V2BbSr32mpcUhPjo6YGY .fYboFs6LoTi42PvLrkOL svg{font-size:12px}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Content/StepMenu/StepMenu.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,SAAA,CACA,OAAA,CAEA,YAAA,CACA,kBAAA,CACA,OAAA,CACA,gCAAA,CACA,sCAAA,CACA,iBAAA,CACA,gBAAA,CACA,iBAAA,CACA,eAAA,CACA,kBAAA,CAEA,wFAEI,YAAA,CACA,kBAAA,CACA,sBAAA,CAGJ,gGAEI,cAAA,CAGJ,gDACI,UAAA,CACA,8BAAA,CAAA,sBAAA,CAGJ,gDACI,cAAA","sourcesContent":[".StepMenu {\n    position: absolute;\n    top: -37px;\n    right: 0;\n\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    background: var(--color-neutral2);\n    border: 1px solid var(--color-Neutral5);\n    border-radius: 2px;\n    padding-left: 8px;\n    padding-right: 8px;\n    padding-top: 4px;\n    padding-bottom: 4px;\n\n    .RequiredStepButton,\n    .NotRequiredStepButton {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n    }\n\n    .RequiredStepButton svg,\n    .NotRequiredStepButton svg {\n        font-size: 18px;\n    }\n\n    .NotRequiredStepButton svg {\n        color: white;\n        filter: grayscale(100%);\n    }\n\n    .RecordStepButton svg {\n        font-size: 12px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StepMenu": "V2BbSr32mpcUhPjo6YGY",
	"RequiredStepButton": "KVsKzX05PJsqHYdi_A1D",
	"NotRequiredStepButton": "M8iO9_SOEgho17WME5Oe",
	"RecordStepButton": "fYboFs6LoTi42PvLrkOL"
};
export default ___CSS_LOADER_EXPORT___;
