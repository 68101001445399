import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { toDateFormatWithoutHours } from '@/utils'

import { Typography } from 'antd'
import { TestSetProgressBar } from '@/components/TestSetProgressBar/TestSetProgressBar'
import { UserCard } from '@/components/UserCard/UserCard'
import { EditTestSetField } from '../EditTestSetField/EditTestSetField'
import { SelectPeriod } from './SelectPeriod'
import { SelectStatus } from './SelectStatus'
import { SelectTester } from './SelectTester'

import { StoreAuthController } from '@/store'

import { IGetUserIn, ITestSet, totalTimeType } from '@/types'

import styles from './Details.module.scss'

const { Text } = Typography

type Props = {
    testSetInfo: ITestSet
    setTestSetInfo: Dispatch<SetStateAction<ITestSet | undefined>>
    onChangeValue: (value: string, label: string) => void
    totalTime: totalTimeType
    showMaxDescriptionLenghtReached: boolean
}

export const Details = observer((props: Props) => {
    const { testSetInfo, setTestSetInfo, onChangeValue, totalTime, showMaxDescriptionLenghtReached } = props
    const { t } = useTranslation(['page--testSets', 'common', 'validation'])
    const [userList, setUserList] = useState<IGetUserIn[]>([])

    useEffect(() => {
        if (!userList.length) {
            StoreAuthController.getUsersInProject([testSetInfo.projectId]).then((users) => {
                setUserList(users.projectUsers)
            })
        }
    }, [])

    return (
        <div className={styles.Details}>
            <div className={styles.DetailsInfo}>
                <div className={styles.Info}>
                    <Text className={styles.Label} type="secondary">
                        {t('page--testSets:dueTime')}
                    </Text>

                    {`${totalTime?.hours}${t('common:timeFormat.h')} ${totalTime?.minutes}${t('common:timeFormat.m')}`}
                </div>

                <div className={styles.Info}>
                    <Text className={styles.Label} type="secondary">
                        {t('page--testSets:createdDate')}
                    </Text>
                    {testSetInfo?.createdDate && toDateFormatWithoutHours(testSetInfo.createdDate)}
                </div>

                <div className={styles.Info}>
                    <Text className={styles.Label} type="secondary">
                        {t('page--testSets:createdBy')}
                    </Text>
                    {testSetInfo?.createdBy?.username ? (
                        <UserCard
                            userName={`${testSetInfo?.createdBy?.firstname} ${testSetInfo?.createdBy?.lastname}`}
                            position={testSetInfo?.createdBy?.position}
                            bordered
                            size={24}
                        />
                    ) : (
                        '—'
                    )}
                </div>

                <SelectStatus testSetInfo={testSetInfo} />

                <SelectPeriod testSetInfo={testSetInfo} setTestSetInfo={setTestSetInfo} />

                <SelectTester testSetInfo={testSetInfo} setTestSetInfo={setTestSetInfo} userList={userList} />

                <EditTestSetField
                    fieldName={'testSoftware'}
                    label={t('page--testSets:testSoftware')}
                    value={testSetInfo?.testSoftware}
                    onChangeValue={onChangeValue}
                />

                <EditTestSetField
                    fieldName={'versionSoftware'}
                    label={t('page--testSets:versionSoftware')}
                    value={testSetInfo?.versionSoftware}
                    onChangeValue={onChangeValue}
                />
            </div>

            <div className={styles.TestSetFieldWithValidation}>
                <EditTestSetField
                    fieldName={'description'}
                    label={t('page--testSets:description')}
                    value={testSetInfo?.description}
                    onChangeValue={onChangeValue}
                />
                {showMaxDescriptionLenghtReached && (
                    <div
                        id="description_help"
                        className="ant-form-item-explain ant-form-item-explain-connected"
                        role="alert"
                    >
                        <div className={cn(styles.ValidationMesage, 'ant-form-item-explain-error')}>
                            {t('validation:textLimitNotification')}
                        </div>
                    </div>
                )}
            </div>
            {testSetInfo && <div>{testSetInfo.progress && <TestSetProgressBar progress={testSetInfo.progress} />}</div>}
        </div>
    )
})
