import React, { Dispatch, SetStateAction } from 'react'

import { useTranslation } from 'react-i18next'

import { Select, Tooltip } from 'antd'

import { FileOutlined, FolderOutlined } from '@ant-design/icons'

import { pageUrls } from '@/consts'

import { searchByTypes } from '@/types'

const { Option } = Select

type Props = {
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
    searchBy: searchByTypes
    setSearchBy: Dispatch<SetStateAction<searchByTypes>>
}

export const SelectSearchBy = (props: Props) => {
    const { pageType, searchBy, setSearchBy } = props
    const { t } = useTranslation(['page--testFolders'])

    const handleChange = (value: searchByTypes) => {
        setSearchBy(value)
    }

    return (
        <Select defaultValue={searchBy} onChange={handleChange}>
            <Option value="folder" label={t('page--testFolders:searchFolderByName')}>
                <Tooltip title={t('page--testFolders:searchFolderByName')} placement="right">
                    <FolderOutlined />
                </Tooltip>
            </Option>
            <Option
                value="testCase"
                label={
                    pageType === pageUrls.testFolder
                        ? t('page--testFolders:searchTestCaseByName')
                        : t('page--testFolders:searchTestSetByName')
                }
            >
                <Tooltip
                    title={
                        pageType === pageUrls.testFolder
                            ? t('page--testFolders:searchTestCaseByName')
                            : t('page--testFolders:searchTestSetByName')
                    }
                    placement="right"
                >
                    <FileOutlined />
                </Tooltip>
            </Option>
        </Select>
    )
}
