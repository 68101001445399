import React, { Dispatch, SetStateAction } from 'react'

import cn from 'classnames'
import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { DatePicker, Typography } from 'antd'

import { EditOutlined } from '@ant-design/icons'

import { StoreTestSet } from '@/store'
import { dateFormat } from '@/consts'

import { ITestSet, IUpdateTestSet, RangeValue } from '@/types'

import styles from './Details.module.scss'

const { RangePicker } = DatePicker
const { Text } = Typography

type Props = {
    testSetInfo: ITestSet
    setTestSetInfo: Dispatch<SetStateAction<ITestSet | undefined>>
}

export const SelectPeriod = observer((props: Props) => {
    const { testSetInfo, setTestSetInfo } = props
    const { t } = useTranslation(['page--testSets'])

    const onExecutionPeriodChange = async (value: RangeValue) => {
        if (
            value &&
            testSetInfo?.id &&
            dayjs(value[0]).unix() !== testSetInfo.beginningPeriod &&
            dayjs(value[1]).unix() !== testSetInfo.endingPeriod
        ) {
            const updatedInfo = {
                ...testSetInfo,
                beginningPeriod: dayjs(value[0]).unix(),
                endingPeriod: dayjs(value[1]).unix(),
            } as ITestSet
            setTestSetInfo(updatedInfo)

            const props = {
                id: updatedInfo.id,
                name: updatedInfo.name,
                description: updatedInfo.description,
                beginningPeriod: updatedInfo.beginningPeriod,
                endingPeriod: updatedInfo.endingPeriod,
                testerId: updatedInfo.testerId,
                testSoftware: updatedInfo.testSoftware,
                versionSoftware: updatedInfo.versionSoftware,
                isDeleted: updatedInfo.isDeleted,
            } as IUpdateTestSet

            await StoreTestSet.updateTestSet(props)
        }
    }

    return (
        <div className={styles.Info}>
            <Text className={styles.Label} type="secondary">
                {t('page--testSets:runtime')}
            </Text>

            <RangePicker
                value={
                    testSetInfo?.beginningPeriod
                        ? [dayjs.unix(testSetInfo?.beginningPeriod), dayjs.unix(testSetInfo?.endingPeriod)]
                        : [null, null]
                }
                onChange={onExecutionPeriodChange}
                format={dateFormat}
                bordered={false}
                allowClear={false}
                separator={'—'}
                className={cn(styles.ExecutionPeriod, { [styles.EmptyPeriod]: !testSetInfo?.beginningPeriod })}
                suffixIcon={<EditOutlined className={styles.EditIcon} />}
            />
        </div>
    )
})
