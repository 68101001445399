// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UVW4yufULQCwVlzQuebE .AmeFN5a4noCaKgEnO2VX{width:256px}.UVW4yufULQCwVlzQuebE .bcQkmqcsDGXH8bWZyScQ{display:flex;justify-content:space-between;align-items:baseline}.UVW4yufULQCwVlzQuebE .bcQkmqcsDGXH8bWZyScQ .ZkMtW8LhA0Zg0pcBieu7{margin-top:14px}.UVW4yufULQCwVlzQuebE .bcQkmqcsDGXH8bWZyScQ .H0oC07IrNu_exkkPfLFA{display:flex;gap:8px}.UVW4yufULQCwVlzQuebE .wKVngLIrcHHO7985yU9Q{max-width:400px}", "",{"version":3,"sources":["webpack://./src/pages/WorkspaceSettings/MainSettings/MainSettings.module.scss"],"names":[],"mappings":"AACI,4CACI,WAAA,CAGJ,4CACI,YAAA,CACA,6BAAA,CACA,oBAAA,CAEA,kEACI,eAAA,CAGJ,kEACI,YAAA,CACA,OAAA,CAIR,4CACI,eAAA","sourcesContent":[".MainSettings {\n    .Menu {\n        width: 256px;\n    }\n\n    .TitleRow {\n        display: flex;\n        justify-content: space-between;\n        align-items: baseline;\n\n        .SettingTitle {\n            margin-top: 14px;\n        }\n\n        .ActionMenu {\n            display: flex;\n            gap: 8px;\n        }\n    }\n\n    .Form {\n        max-width: 400px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"MainSettings": "UVW4yufULQCwVlzQuebE",
	"Menu": "AmeFN5a4noCaKgEnO2VX",
	"TitleRow": "bcQkmqcsDGXH8bWZyScQ",
	"SettingTitle": "ZkMtW8LhA0Zg0pcBieu7",
	"ActionMenu": "H0oC07IrNu_exkkPfLFA",
	"Form": "wKVngLIrcHHO7985yU9Q"
};
export default ___CSS_LOADER_EXPORT___;
