import React, { Dispatch, SetStateAction, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { copyLink } from '@/utils'

import { Button, Tooltip } from 'antd'
import { LaunchButtons } from '../LaunchButtons'
import { TestCaseColumnSettingsDropdownMenu } from '../TestCaseColumnSettingsDropdownMenu/TestCaseColumnSettingsDropdownMenu'
import { TestCaseDropdownMenu } from '../TestCaseDropdownMenu/TestCaseDropdownMenu'

import { FieldTimeOutlined, LinkOutlined, SaveOutlined, UndoOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreProject, StoreTestCase, StoreTestCaseList } from '@/store'
import { pageUrls } from '@/consts'

import { ITestCase } from '@/types'

import styles from './TestCaseMenu.module.scss'

type Props = {
    showLaunchButtons: boolean
    mergeSteps: () => void
    setTestCaseInfo?: Dispatch<SetStateAction<ITestCase | undefined>>
}

export const TestCaseMenu = observer((props: Props) => {
    const { mergeSteps, showLaunchButtons, setTestCaseInfo } = props
    const { t } = useTranslation(['testCase', 'page--testCases', 'common'])
    const navigate = useNavigate()
    const { pathname } = useLocation()

    const isNewTestCase = matchPath(
        {
            path: `/${pageUrls.project}/:id${pageUrls.testFolder}/:id${pageUrls.testCase}/new`,
        },
        pathname
    )

    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false)

    const onSave = async () => {
        mergeSteps()

        if (!isNewTestCase) {
            const initialTestCaseName = StoreTestCase.testCaseInfo.name
            await StoreTestCase.updateTestCase()

            //если было измено имя, то обновить боковое меню навигации по тест-кейсам
            if (initialTestCaseName !== StoreTestCase.name) {
                await StoreTestCaseList.getAllTestCases()
            }
        } else {
            const testCaseId = await StoreTestCase.createTestCase()

            //TODO: не понятный баг с блокировщиком, если сделать чистку всего, то баг пропадает
            if (StoreTestCase.requiredStepChanged) {
                StoreTestCase.resetTestCaseData()
            } else {
                StoreTestCase.setTestCaseId(testCaseId)
            }
            navigate(
                `${pageUrls.project}/${StoreProject.id}${pageUrls.testFolder}/${StoreTestCase.testFolderId}${pageUrls.testCase}/${testCaseId}`
            )

            await StoreTestCaseList.getAllTestCases()
        }

        setTimeout(() => {
            setTooltipVisible(false)
        }, 100)

        StoreTestCase.setIsSaved(true, true)
        StoreTestCase.setRequiredStepChanged(false)
    }

    const onViewHstory = () => {
        console.log('view history')
    }

    const onCopyLink = () => {
        const href = window.location.href
        copyLink(href)
    }

    const onUndo = async () => {
        if (!isNewTestCase) {
            const testCase = await StoreTestCase.getTestCase(StoreTestCase.id)
            const attachments = [...StoreTestCase.attachments]
            await StoreTestCase.toggleBlocked(false)
            StoreTestCase.resetTestCaseData()
            StoreTestCase.setAttachments(attachments)
            setTestCaseInfo && setTestCaseInfo(testCase)
        } else {
            StoreTestCase.resetTestCaseData()
            StoreTestCase.setTestCaseName(t('testCase:newTestCaseTitle'))
        }
    }

    return (
        <div className={styles.TopRight}>
            {showLaunchButtons && <LaunchButtons />}

            {!StoreTestCase.readOnly && (
                <Tooltip
                    placement="bottom"
                    title={
                        StoreTestCase.isSaved ? t('testCase:testCaseMenu.saved') : t('testCase:testCaseMenu.notSaved')
                    }
                    open={tooltipVisible}
                    onOpenChange={(visible) => setTooltipVisible(visible)}
                >
                    <Button
                        className={cn({ [styles.SaveButtonActive]: !StoreTestCase.isSaved })}
                        type="default"
                        icon={<SaveOutlined />}
                        onClick={onSave}
                        disabled={StoreTestCase.isSaved || SpinnerStore.show}
                    />
                </Tooltip>
            )}

            <Tooltip placement="bottom" title={`${t('testCase:testCaseMenu.history')} (${t('common:inDevelopment')})`}>
                <Button type="default" icon={<FieldTimeOutlined />} onClick={onViewHstory} disabled />
            </Tooltip>
            <Tooltip placement="bottom" title={t('testCase:testCaseMenu.copyLink')}>
                <Button type="default" icon={<LinkOutlined />} onClick={onCopyLink} disabled={!!!StoreTestCase.id} />
            </Tooltip>

            {!StoreTestCase.isSaved && !StoreTestCase.readOnly && (
                <Tooltip placement="bottom" title={t('testCase:testCaseMenu.cancelChanges')}>
                    <Button type="default" icon={<UndoOutlined />} onClick={onUndo} disabled={SpinnerStore.show} />
                </Tooltip>
            )}
            <TestCaseColumnSettingsDropdownMenu />
            {!StoreTestCase.readOnly && <TestCaseDropdownMenu mergeSteps={mergeSteps} />}
        </div>
    )
})
