import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import moment from 'moment'
import { useParams } from 'react-router-dom'

import { MsAuthApi } from '@/api'

import { hasWorkspacePermissions, useDebounce } from '@/utils'

import { Empty } from 'antd'
import { Card } from './Card/Card'
import { GlobalSearchResults } from './Search/GlobalSearchResults/GlobalSearchResults'
import { SearchField } from './Search/SearchField/SearchField'
import { UserFilters } from './Search/UserFilters/UserFilters'
import { TeamTitle } from './TeamTitle/TeamTitle'

import { StoreAuthController } from '@/store'
import { pageUrls } from '@/consts'

import { IGetUserIn, ISearchRoles, IUserWidthFilter, searchAreaTypes, settingsPageType } from '@/types'
import { EAccessLevel, EDefaultUserRoles, EPermissions } from '@/enums'
import type { CheckboxOptionType } from 'antd/es/checkbox/Group'

import styles from './Team.module.scss'

type Props = {
    pageType: settingsPageType
    workspaceId: string
}

export const Team = observer((props: Props) => {
    const { pageType, workspaceId } = props
    const { projectId } = useParams()
    const [users, setUsers] = useState<IGetUserIn[]>([])
    const [searchResults, setSearchResults] = useState<IGetUserIn[] | IUserWidthFilter[]>([])
    const [roles, setRoles] = useState<ISearchRoles[]>([])
    const [searchDebounced, searchValue, setSearchValue] = useDebounce(200, '')
    const [searchArea, setSearchArea] = useState<searchAreaTypes>('local')
    const [initialNumberOfusers, setInitialNumberOfusers] = useState<number>()
    const [showFilters, setShowFilters] = useState(false)
    const parentId = pageType === pageUrls.workspaceSettings ? workspaceId : projectId
    const [firstNameSearchValue, setFirstNameSearchValue] = useState('')
    const [lastNameSearchValue, setLastNameSearchValue] = useState('')

    useEffect(() => {
        if (parentId) {
            if (pageType === pageUrls.workspaceSettings) {
                StoreAuthController.getUsersInWorkspace(parentId).then((users) => {
                    setUsers(users.workspaceUsers)
                    setInitialNumberOfusers(users.workspaceUsers.length)
                })
            }

            if (pageType === pageUrls.projectSettings) {
                StoreAuthController.getUsersInProject([parentId]).then((users) => {
                    setUsers(users.projectUsers)
                    setInitialNumberOfusers(users.projectUsers.length)
                })
            }

            const roleFilter = {
                workspaceId: workspaceId || '',
                projectId: projectId || '',
                accessLevel: pageType === pageUrls.workspaceSettings ? EAccessLevel.WORKSPACE : EAccessLevel.PROJECT,
            }

            MsAuthApi.getRoles(roleFilter).then((roles) => {
                setRoles(roles)
            })
        }
    }, [parentId])

    const updateIsUserInCurrentTeam = (userId: string, isInCurrentTeam: boolean, globalProjectUsers?: IGetUserIn[]) => {
        const searchResultsCopy = globalProjectUsers ? [...globalProjectUsers] : [...searchResults]

        const searchRes =
            pageType === pageUrls.workspaceSettings
                ? searchResultsCopy.find((searchUser) =>
                      'userId' in searchUser ? searchUser.userId : searchUser.id == userId
                  )
                : searchResultsCopy.find((searchUser) => 'userId' in searchUser && searchUser.userId == userId)

        if (searchRes) {
            searchRes.isInCurrentTeam = isInCurrentTeam
            setSearchResults(searchResultsCopy as IGetUserIn[] | IUserWidthFilter[])
        }
    }

    const addUser = async (user: IUserWidthFilter | IGetUserIn) => {
        if (parentId) {
            if (pageType === pageUrls.workspaceSettings && 'id' in user) {
                await StoreAuthController.assignToWorkspace([user.id], parentId)

                user.userRolesInWorkspace = [
                    {
                        roleName: EDefaultUserRoles.WORKSPACE_USER,
                        roleDate: moment().unix(),
                    },
                ]
            } else {
                if ('userId' in user) {
                    await StoreAuthController.assignToProject([user.userId], parentId)

                    user.userRolesInProject = [
                        {
                            roleName: EDefaultUserRoles.PROJECT_USER,
                            roleDate: moment().unix(),
                        },
                    ]
                }
            }

            const newUser = {
                ...user,
                userId: 'id' in user ? user.id : user.userId,
                isInCurrentTeam: true,
            }

            const updatedUsers = [...users, newUser] as IGetUserIn[]
            setUsers(updatedUsers)

            updateIsUserInCurrentTeam(newUser.userId, true)
        }
    }

    const excludeUser = async (user: IGetUserIn | IUserWidthFilter) => {
        const userName = `${user.firstname} ${user.lastname}`
        if (parentId) {
            const userId = 'userId' in user ? user.userId : user.id

            if (pageType === pageUrls.workspaceSettings) {
                await StoreAuthController.removeFromWorkspace(userId, userName, parentId)
            } else {
                await StoreAuthController.removeFromProject(userId, userName, parentId)

                if (searchArea === 'local' && pageType === pageUrls.projectSettings) {
                    const updatedSearchResults = searchResults.filter((user: IGetUserIn) => user.userId !== userId)
                    setSearchResults(updatedSearchResults)
                }
            }

            const updatedUsers = users.filter((user) => user.userId !== userId)
            setUsers(updatedUsers)

            if (
                (searchArea === 'global' && pageType === pageUrls.projectSettings) ||
                pageType === pageUrls.workspaceSettings
            ) {
                updateIsUserInCurrentTeam(userId, false)
            }
        }
    }

    const rolesList: CheckboxOptionType[] = useMemo(() => {
        const formattedRoles = roles.map((role) => {
            return {
                key: role.id,
                label: role.name,
                value: role.name,
            }
        })
        return formattedRoles
    }, [roles])

    const canManageUsers: boolean = useMemo(
        () => hasWorkspacePermissions([EPermissions.MANAGE_USERS], workspaceId),
        [workspaceId]
    )

    const canChangeRoles: boolean = useMemo(
        () =>
            pageType === pageUrls.workspaceSettings ? canManageUsers : searchArea === 'global' ? false : canManageUsers,
        [canManageUsers, pageType, searchArea]
    )

    const onlyAdmin = useMemo(
        () =>
            users.reduce(
                (total, current) =>
                    current?.userRolesInWorkspace?.length
                        ? total +
                          +current.userRolesInWorkspace.some(
                              (role) => role.roleName === EDefaultUserRoles.WORKSPACE_ADMIN
                          )
                        : total,
                0
            ) === 1,
        [users]
    )

    const checkIfUserLastAdmin = (user: IGetUserIn | IUserWidthFilter) => {
        if (pageType === pageUrls.workspaceSettings) {
            if ('userRolesInWorkspace' in user) {
                const isAdmin =
                    user?.userRolesInWorkspace?.some((role) => role.roleName === EDefaultUserRoles.WORKSPACE_ADMIN) ||
                    false
                return onlyAdmin && isAdmin
            } else {
                const isAdmin = user?.roles
                    .filter((roles: ISearchRoles) => roles.workspaceId === parentId)
                    .some((role: ISearchRoles) => role.name === EDefaultUserRoles.WORKSPACE_ADMIN)
                return onlyAdmin && isAdmin
            }
        } else {
            return true
        }
    }

    const notGlobalSearch = searchArea === 'local' || pageType === pageUrls.projectSettings
    const isSearching = searchDebounced || showFilters
    const isProjectUserFound = pageType === pageUrls.projectSettings && !!searchResults.length
    const showFoundedUsers = isSearching || isProjectUserFound
    const usersArr = showFoundedUsers ? searchResults : users

    return (
        <div className={styles.Team}>
            <div className={styles.Header}>
                {parentId && (
                    <TeamTitle
                        parentId={parentId}
                        initialNumberOfusers={initialNumberOfusers}
                        pageType={pageType}
                        canManageUsers={canManageUsers}
                    />
                )}
                <SearchField
                    users={users}
                    setSearchResults={setSearchResults}
                    searchValue={searchValue}
                    searchDebounced={searchDebounced}
                    setSearchValue={setSearchValue}
                    searchArea={searchArea}
                    setSearchArea={setSearchArea}
                    showFilters={showFilters}
                    setShowFilters={setShowFilters}
                    pageType={pageType}
                    workspaceId={workspaceId}
                    canManageUsers={canManageUsers}
                />
            </div>
            <div>
                {showFilters && pageType === pageUrls.workspaceSettings && (
                    <UserFilters
                        rolesList={rolesList}
                        users={users}
                        setSearchResults={setSearchResults}
                        searchArea={searchArea}
                        setFirstNameSearchValue={setFirstNameSearchValue}
                        setLastNameSearchValue={setLastNameSearchValue}
                    />
                )}
            </div>

            <div className={styles.Cards}>
                {notGlobalSearch && showFoundedUsers && !!!searchResults.length && (
                    <Empty className={styles.Empty} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}

                {notGlobalSearch
                    ? usersArr.map((user) => (
                          <Card
                              key={'userId' in user ? user.userId : user.id}
                              user={user as IGetUserIn}
                              roles={roles}
                              users={users}
                              setUsers={setUsers}
                              excludeUser={excludeUser}
                              addUser={addUser}
                              rolesList={rolesList}
                              searchValue={searchDebounced}
                              canManageUsers={canManageUsers}
                              canChangeRoles={canChangeRoles}
                              onlyAdmin={onlyAdmin}
                              checkIfUserLastAdmin={checkIfUserLastAdmin}
                              pageType={pageType}
                              searchArea={searchArea}
                              firstNameSearchValue={firstNameSearchValue}
                              lastNameSearchValue={lastNameSearchValue}
                          />
                      ))
                    : parentId && (
                          <GlobalSearchResults
                              searchResults={searchResults as IUserWidthFilter[]}
                              excludeUser={excludeUser}
                              addUser={addUser}
                              searchValue={searchDebounced}
                              canManageUsers={canManageUsers}
                              checkIfUserLastAdmin={checkIfUserLastAdmin}
                              firstNameSearchValue={firstNameSearchValue}
                              lastNameSearchValue={lastNameSearchValue}
                          />
                      )}
            </div>
        </div>
    )
})
