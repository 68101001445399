import { DateTime } from 'luxon'

export const toDateFormat = (inputDate: number) => {
    return inputDate ? DateTime.fromSeconds(inputDate).toFormat('dd.LL.yyyy T') : '—'
}

export const toDateFormatWithoutHours = (inputDate: number) => {
    return inputDate ? DateTime.fromSeconds(inputDate).toFormat('dd.LL.yyyy') : '—'
}

export const formatToSeconds = (hours: number | null, minutes: number | null) => {
    const seconds = ((hours && hours * 3600) ?? 0) + ((minutes && minutes * 60) ?? 0)
    return seconds
}

export const parseNormativeTime = (normativeTime: number) => {
    const hours = normativeTime >= 3600 ? Math.floor(normativeTime / 3600) : 0
    const minutes = (hours ? (normativeTime - hours * 3600) / 60 : normativeTime / 60) ?? 0
    return { hours, minutes }
}

export const toHoursAndMinutes = (totalMinutes: number, hoursTitle: string, minutesTitle: string) => {
    const hours = Math.floor(totalMinutes / 60)
    const minutes = totalMinutes % 60

    return `${hours > 0 ? ` ${hours}${hoursTitle}` : ''}${minutes > 0 ? ` ${minutes}${minutesTitle}` : ''}`
}

export const getTimestampInSeconds = () => {
    return Math.floor(Date.now() / 1000)
}

const pad = (num: number) => {
    return ('0' + num).slice(-2)
}

export const tohhmmss = (secs: number) => {
    let minutes = Math.floor(secs / 60)
    secs = secs % 60
    const hours = Math.floor(minutes / 60)
    minutes = minutes % 60
    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`
}
