import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button, Form, Input, Typography } from 'antd'
import { DarkSwitcher } from '@/components/DarkSwitcher'
import { FormItem } from '@/components/FormItem/FormItem'
import { LanguageSwitcher } from '@/components/LanguageSwitcher/LanguageSwitcher'
import { EmailConfirmationModal } from './EmailConfirmationModal'

import { SpinnerStore, StoreAuthController } from '@/store'
import { emailConfirmationTimeOut, pageUrls } from '@/consts'

import styles from '../SignInUp.module.scss'

const { Title } = Typography
const { Password } = Input

export const SignUp = observer(() => {
    const [form] = Form.useForm()
    const { t } = useTranslation(['validation', 'page--auth'])

    const [isOpenedEmailConfirmation, setIsOpenedEmailConfirmation] = useState(false)
    const [counter, setCounter] = useState(0)
    const [showCounter, setShowCounter] = useState(false)

    const requiredRule = {
        required: true,
        message: t('validation:required'),
    }

    const latinNumbersPunctuationRule = {
        pattern: /^[?!%^$*(){}=-@:&+|\\/<>#№,._a-zA-Z0-9]+$/,
        message: t('validation:latinNumbersPunctuation'),
    }

    const handleFinish = () => {
        form.validateFields()
            .then((values) => {
                StoreAuthController.signUp(values).then((resp) => {
                    if (resp) {
                        setIsOpenedEmailConfirmation(true)
                        setCounter(emailConfirmationTimeOut)
                        setShowCounter(true)
                    }
                })
            })
            .catch((errorInfo) => {
                return
            })
    }

    return (
        <div className={styles.SignUp}>
            <Title level={3}>{t('page--auth:signUpTitle')}</Title>
            <EmailConfirmationModal
                isOpenedEmailConfirmation={isOpenedEmailConfirmation}
                setIsOpenedEmailConfirmation={setIsOpenedEmailConfirmation}
                showCounter={showCounter}
                setShowCounter={setShowCounter}
                counter={counter}
                setCounter={setCounter}
            />
            <Form
                form={form}
                layout="vertical"
                onFinish={handleFinish}
                initialValues={StoreAuthController.signUpParams}
            >
                <FormItem
                    name="username"
                    label={t('page--auth:username')}
                    rules={[requiredRule, latinNumbersPunctuationRule]}
                    className={'hideRequiredMark'}
                    validateDebounce={1000}
                    hasFeedback
                >
                    <Input />
                </FormItem>
                <FormItem
                    name="email"
                    label={t('page--auth:email')}
                    rules={[
                        requiredRule,
                        {
                            type: 'email',
                            message: t('validation:email'),
                        },
                        latinNumbersPunctuationRule,
                    ]}
                    className={'hideRequiredMark'}
                    validateDebounce={1000}
                    hasFeedback
                >
                    <Input />
                </FormItem>
                <FormItem
                    name="firstname"
                    label={t('page--auth:firstName')}
                    rules={[requiredRule]}
                    className={'hideRequiredMark'}
                >
                    <Input />
                </FormItem>
                <FormItem
                    name="lastname"
                    label={t('page--auth:lastName')}
                    rules={[requiredRule]}
                    className={'hideRequiredMark'}
                >
                    <Input />
                </FormItem>

                <FormItem
                    name="password"
                    label={t('page--auth:password')}
                    rules={[
                        requiredRule,
                        {
                            pattern:
                                /^(?=.*\d)(?=.*[?!%^$*(){}=-@:&+|\\/,._])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{7,}$/,
                            message: t('validation:passwordSymbols'),
                        },
                    ]}
                    className={'hideRequiredMark'}
                    validateDebounce={1000}
                    hasFeedback
                >
                    <Password autoComplete="on" />
                </FormItem>

                <FormItem
                    name="confirm"
                    label={t('page--auth:confirmPassword')}
                    dependencies={['password']}
                    hasFeedback
                    className={'hideRequiredMark'}
                    rules={[
                        requiredRule,
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error(t('validation:passwordMatch')))
                            },
                        }),
                    ]}
                >
                    <Password />
                </FormItem>
                <div className={styles.FormFooter}>
                    <FormItem className={styles.Submit}>
                        <Button type="primary" htmlType="submit" block size="large" disabled={SpinnerStore.show}>
                            {t('page--auth:signUp')}
                        </Button>
                    </FormItem>
                    <div>
                        {t('page--auth:haveAccount')} <Link to={pageUrls.signIn}>{t('page--auth:signIn')}</Link>
                    </div>
                    <div className={styles.SiteOptions}>
                        <DarkSwitcher />
                        <LanguageSwitcher />
                    </div>
                </div>
            </Form>
        </div>
    )
})
