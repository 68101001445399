// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OISinkF61Qj0OKixoaau{display:flex;gap:8px;align-items:center;margin-bottom:8px}.OISinkF61Qj0OKixoaau .cC4NrbVMjKVE6UOsQNw1{flex-direction:column;width:164px;background:var(--color-primary-lightest);padding:4px 8px}.OISinkF61Qj0OKixoaau .cC4NrbVMjKVE6UOsQNw1 .T2FlodViZNlIl3MPISwd{font-size:10px;color:var(--color-text-secondary)}.OISinkF61Qj0OKixoaau .cC4NrbVMjKVE6UOsQNw1 .CBaEJASPUH0HuoJ6Kbpy{font-size:12px;color:var(--color-text-primary)}.iPuo6IDPztLw8kIt_Z_k{flex-direction:row-reverse}.iPuo6IDPztLw8kIt_Z_k .cC4NrbVMjKVE6UOsQNw1{background:var(--color-chat-green)}", "",{"version":3,"sources":["webpack://./src/components/UserMessage/UserMessage.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,OAAA,CACA,kBAAA,CACA,iBAAA,CAEA,4CACI,qBAAA,CACA,WAAA,CACA,wCAAA,CACA,eAAA,CAEA,kEACI,cAAA,CACA,iCAAA,CAEJ,kEACI,cAAA,CACA,+BAAA,CAKZ,sBACI,0BAAA,CAEA,4CACI,kCAAA","sourcesContent":[".UserMessage {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n    margin-bottom: 8px;\n\n    .UserTextInfo {\n        flex-direction: column;\n        width: 164px;\n        background: var(--color-primary-lightest);\n        padding: 4px 8px;\n\n        .UserName {\n            font-size: 10px;\n            color: var(--color-text-secondary);\n        }\n        .Message {\n            font-size: 12px;\n            color: var(--color-text-primary);\n        }\n    }\n}\n\n.OwnMessage {\n    flex-direction: row-reverse;\n\n    .UserTextInfo {\n        background: var(--color-chat-green);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserMessage": "OISinkF61Qj0OKixoaau",
	"UserTextInfo": "cC4NrbVMjKVE6UOsQNw1",
	"UserName": "T2FlodViZNlIl3MPISwd",
	"Message": "CBaEJASPUH0HuoJ6Kbpy",
	"OwnMessage": "iPuo6IDPztLw8kIt_Z_k"
};
export default ___CSS_LOADER_EXPORT___;
