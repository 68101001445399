import React, { useEffect, useMemo, useState } from 'react'

import cn from 'classnames'
import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Divider, Popover, Radio } from 'antd'
import type { RadioChangeEvent } from 'antd'
import { TooltipWithDisabling } from '@/components/TooltipWithDisabling'

import { BugFilled, CheckCircleFilled, MinusCircleFilled, PlusSquareOutlined, WarningFilled } from '@ant-design/icons'

import { StoreTestCase } from '@/store'

import { IExecutionStep, IExecutionStepWithoutResult, IStep } from '@/types'
import { EExecutionCaseStatus, EExecutionStepStatus, EStepType } from '@/enums'

import styles from './TestStepStatusSelection.module.scss'

const { Button, Group } = Radio

type Props = {
    step: IStep
}

export const TestStepStatusSelection = observer((props: Props) => {
    const { step } = props

    const { t } = useTranslation(['testCase'])
    const [isDisabled, disable] = useState(false)
    const [showToolTip, setShowToolTip] = useState(false)
    const [showStatusSelection, setShowStatusSelection] = useState(false)

    const updateExecutionStepStatus = (updatedStep: IExecutionStepWithoutResult) => {
        if (StoreTestCase.testCaseInfo?.executionSteps?.some((step: IExecutionStep) => step.id === updatedStep.id)) {
            const executionStepsCopy = JSON.parse(JSON.stringify(StoreTestCase.testCaseInfo?.executionSteps))
            const updatedSteps = executionStepsCopy?.map((step: IExecutionStep) =>
                step.id === updatedStep.id
                    ? {
                          ...step,
                          executionStepStatus: updatedStep.executionStepStatus,
                      }
                    : step
            )
            StoreTestCase.setExecutionSteps(updatedSteps)

            if (StoreTestCase.isSaved) {
                StoreTestCase.setIsSaved(false)
            }
        }
    }

    const onSelectStatus = (e: RadioChangeEvent) => {
        const newStatus = e.target.value
        const updatedStep = {
            id: step.id,
            executionStepStatus: newStatus,
            executionCaseId: StoreTestCase.id,
            orderNum: step.orderNum,
            completed: DateTime.utc().toISO(),
        }

        updateExecutionStepStatus(updatedStep)

        if (step.required) {
            StoreTestCase.updateTopRequiredStep(step.stepType)
        }

        setShowStatusSelection(false)
    }

    const currentStatus = useMemo(
        () =>
            StoreTestCase.testCaseInfo?.executionSteps?.find(
                (executionStep: IExecutionStep) => executionStep.id === step.id
            )?.executionStepStatus,
        [step.executionStepStatus, StoreTestCase?.testCaseInfo?.executionSteps]
    )

    const hasRequiredPreviousSteps = (step: IStep) => {
        switch (step.stepType) {
            case EStepType.STEP_PRECONDITION:
                return (
                    !!StoreTestCase.topRequiredStep[step.stepType] &&
                    !!(StoreTestCase.topRequiredStep[step.stepType] < step.orderNum)
                )
            case EStepType.DEFAULT_STEP:
                return (
                    !!StoreTestCase.topRequiredStep[EStepType.STEP_PRECONDITION] ||
                    !!(
                        StoreTestCase.topRequiredStep[step.stepType] &&
                        StoreTestCase.topRequiredStep[step.stepType] < step.orderNum
                    )
                )
            case EStepType.STEP_POSTCONDITION:
                return (
                    !!StoreTestCase.topRequiredStep[EStepType.DEFAULT_STEP] ||
                    !!(
                        StoreTestCase.topRequiredStep[step.stepType] &&
                        StoreTestCase.topRequiredStep[step.stepType] < step.orderNum
                    )
                )
        }
    }

    useEffect(() => {
        disable(!StoreTestCase.executionCaseStarted || hasRequiredPreviousSteps(step))
    }, [
        StoreTestCase.executionCaseStarted,
        StoreTestCase.completeWithError,
        StoreTestCase.topRequiredStep.STEP_PRECONDITION,
        StoreTestCase.topRequiredStep.DEFAULT_STEP,
        StoreTestCase.topRequiredStep.STEP_POSTCONDITION,
    ])

    const handleOpenChange = (newOpen: boolean) => {
        StoreTestCase.executionCaseStarted && setShowStatusSelection(newOpen)
    }

    const disablingTooltipTitle = useMemo(() => {
        if (!StoreTestCase.executionCaseStarted && StoreTestCase.executionCaseStatus !== EExecutionCaseStatus.PASSED) {
            if (StoreTestCase.completeWithError) {
                return t('testCase:clickRepeatTest')
            } else {
                return t('testCase:clickRunTest')
            }
        } else {
            if (StoreTestCase.executionCaseCompleted) {
                return ''
            } else {
                return t('testCase:notSelectedStepStatus')
            }
        }
    }, [StoreTestCase.executionCaseStarted, StoreTestCase.executionCaseStatus, StoreTestCase.completeWithError])

    const StatusButtons = () => (
        <Group
            disabled={isDisabled}
            onChange={onSelectStatus}
            optionType="button"
            className={styles.TestStepStatusGroup}
        >
            <Button value={EExecutionStepStatus.PASSED} className={styles.RadioButton}>
                <CheckCircleFilled className={styles.IconPASSED} />
            </Button>
            <Button value={EExecutionStepStatus.BLOCKED} className={styles.RadioButton}>
                <MinusCircleFilled className={styles.IconBLOCKED} />
            </Button>
            <Button value={EExecutionStepStatus.SKIPPED} className={styles.RadioButton}>
                <WarningFilled className={styles.IconSKIPPED} />
            </Button>
            <Button value={EExecutionStepStatus.FAILED} className={styles.RadioButton}>
                <BugFilled className={styles.IconFAILED} />
            </Button>
        </Group>
    )

    return (
        <TooltipWithDisabling
            disabled={isDisabled}
            title={disablingTooltipTitle}
            isVisible={showToolTip}
            setIsVisible={setShowToolTip}
            placement="top"
        >
            <div className={styles.SwtitchStatusButton}>
                <Divider className={styles.OrderNumDivider} />
                <Popover
                    content={
                        (StoreTestCase.executionCaseStarted || StoreTestCase.executionCaseCompleted) && StatusButtons
                    }
                    arrow={false}
                    className={styles.StatusPopover}
                    open={showStatusSelection && !isDisabled}
                    onOpenChange={handleOpenChange}
                >
                    {!currentStatus &&
                        !(
                            StoreTestCase.executionCaseStatus == EExecutionCaseStatus.PASSED &&
                            !StoreTestCase.executionCaseStarted
                        ) && (
                            <PlusSquareOutlined
                                className={cn(styles.AddNewStatusButton, { [styles.Disabled]: isDisabled })}
                            />
                        )}

                    {currentStatus === EExecutionStepStatus.PASSED && (
                        <CheckCircleFilled className={cn(styles.IconPASSED, styles.AddNewStatusButton)} />
                    )}
                    {currentStatus === EExecutionStepStatus.BLOCKED && (
                        <MinusCircleFilled className={cn(styles.IconBLOCKED, styles.AddNewStatusButton)} />
                    )}
                    {currentStatus === EExecutionStepStatus.SKIPPED && (
                        <WarningFilled className={cn(styles.IconSKIPPED, styles.AddNewStatusButton)} />
                    )}
                    {currentStatus === EExecutionStepStatus.FAILED && (
                        <BugFilled className={cn(styles.IconFAILED, styles.AddNewStatusButton)} />
                    )}
                </Popover>
            </div>
        </TooltipWithDisabling>
    )
})
