import React, { ChangeEvent, Dispatch, FocusEvent, SetStateAction, useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'

import { Input, InputRef, Tooltip } from 'antd'
import { getContactIcon } from '../ContactIcons/ContactIcons'

import { removableContactsList } from '@/consts'

import { IAdditionalUserContacts, IUserContacts, IUserWithId } from '@/types'

import styles from './EditableField.module.scss'

type Props = {
    fieldName: string
    label: string
    value: string | undefined
    onChange: (e: ChangeEvent<HTMLInputElement>) => void
    onBlur: (e: FocusEvent<HTMLInputElement>) => void
    setFIOEditing?: Dispatch<SetStateAction<boolean>>
    handleDeleteContact?: (e: MouseEvent) => void
    isNewContact?: boolean
    userInfo?: IUserWithId
}

export const EditableField = observer((props: Props) => {
    const { value, label, fieldName, onChange, onBlur, setFIOEditing, isNewContact, userInfo } = props
    const ref = useRef<InputRef>(null)
    const [focused, setFocus] = useState(false)

    useEffect(() => {
        const autofocus =
            isNewContact &&
            userInfo &&
            (fieldName === 'phone'
                ? !userInfo.phone
                : !userInfo.additionalContacts[fieldName as keyof IAdditionalUserContacts])
        autofocus && ref.current && ref.current.focus()
    }, [])

    const handleFocus = () => {
        setFocus(true)
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        onBlur(e)
        setFocus(false)
        setFIOEditing && setFIOEditing(false)
    }

    const isContactField = fieldName === 'email' || removableContactsList.includes(fieldName)

    const getPrefixIcon = () => {
        if (isContactField) {
            return (
                <Tooltip title={label}>
                    <span className={styles.Icon}> {getContactIcon(fieldName as IUserContacts)}</span>
                </Tooltip>
            )
        }
    }

    return (
        <div className={styles.Info}>
            <div className={styles.Row}>
                <Input
                    ref={ref}
                    value={value}
                    maxLength={100}
                    name={fieldName}
                    placeholder={label}
                    className={styles.EditableField}
                    onChange={onChange}
                    bordered={focused}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    prefix={getPrefixIcon()}
                />
            </div>
        </div>
    )
})
