import React, { Dispatch, Key, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { extractStepDataForCopy, resetNumeration } from '@/utils/table'

import { Button, Modal } from 'antd'

import { CopyOutlined, DeleteOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreTestCase } from '@/store'

import { StepType } from '@/types'

import styles from './MenuForSteps.module.scss'

type Props = {
    steps: StepType[]
    setSteps: Dispatch<SetStateAction<StepType[]>>
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    selectedRowKeys: Key[]
    setShowMenu: Dispatch<SetStateAction<boolean>>
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
}

export const MenuForSteps = observer((props: Props) => {
    const { steps, setSteps, setSelectedRowKeys, selectedRowKeys, setShowMenu, setTestCaseSavedStatus } = props

    const { t } = useTranslation(['common', 'testCase'])

    const deleteSelected = () => {
        Modal.confirm({
            centered: true,
            title: t('testCase:deleteGuard.titleAll'),
            content: t('testCase:deleteGuard.content'),
            onOk: async () => {
                const updatedSteps = steps.filter((step) => !selectedRowKeys.includes(step.orderNum))
                const updatedStepsWithUpdatedNumeration = resetNumeration(updatedSteps)
                StoreTestCase.handleMoveAttachmentsOnDelete(
                    selectedRowKeys,
                    steps,
                    updatedSteps,
                    updatedStepsWithUpdatedNumeration
                )
                setSteps(updatedStepsWithUpdatedNumeration)
                setSelectedRowKeys([])
                await setTestCaseSavedStatus(false)
                setShowMenu(false)
            },
            okText: t('common:buttons.ok'),
            cancelText: t('common:buttons.cancel'),
        })
    }

    const copySelected = async () => {
        const stepsDataForCopy = steps
            .filter((step) => selectedRowKeys.includes(step?.orderNum))
            .map((step) => extractStepDataForCopy(step))
        const updatedSteps = [...steps, ...stepsDataForCopy]

        setSteps(resetNumeration(updatedSteps))
        await setTestCaseSavedStatus(false)
    }

    return (
        <div className={styles.MenuForSteps}>
            <Button
                size="small"
                type="primary"
                icon={<DeleteOutlined />}
                onClick={deleteSelected}
                disabled={SpinnerStore.show}
            >
                {t('common:buttons.delete')}
            </Button>
            <Button
                size="small"
                type="primary"
                icon={<CopyOutlined />}
                onClick={copySelected}
                disabled={SpinnerStore.show}
            >
                {t('common:buttons.copy')}
            </Button>
        </div>
    )
})
