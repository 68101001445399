// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KUQruLLCvwIyjlP2pP1U{display:flex;align-items:baseline;gap:9px}.KUQruLLCvwIyjlP2pP1U h4{margin:0}.KUQruLLCvwIyjlP2pP1U .FSmIE7zXTY0hZBVXz00P svg{font-size:14px;color:var(--color-text-secondary45)}", "",{"version":3,"sources":["webpack://./src/components/SettingPageTabs/Team/TeamTitle/TeamTitle.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,oBAAA,CACA,OAAA,CAEA,yBACI,QAAA,CAEJ,gDACI,cAAA,CACA,mCAAA","sourcesContent":[".TeamTitle {\n    display: flex;\n    align-items: baseline;\n    gap: 9px;\n\n    h4 {\n        margin: 0;\n    }\n    .TooltipIcon svg {\n        font-size: 14px;\n        color: var(--color-text-secondary45);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TeamTitle": "KUQruLLCvwIyjlP2pP1U",
	"TooltipIcon": "FSmIE7zXTY0hZBVXz00P"
};
export default ___CSS_LOADER_EXPORT___;
