import React, { Dispatch, SetStateAction } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { resetNumeration } from '@/utils/table'

import { Tooltip } from 'antd'
import { NumericInput } from '@/components/NumericInput/NumericInput'
import { TestStepStatusSelection } from '@/components/TestStepStatusSelection/TestStepStatusSelection'

import { ShieldIcon } from '@/components/Icons'
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

import { StoreTestCase } from '@/store'

import { IStep } from '@/types'

import styles from './OrderNumColumn.module.scss'

type Props = {
    orderNum: number
    step: IStep
    steps: IStep[]
    setSteps: Dispatch<SetStateAction<IStep[]>>
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
}

export const OrderNumColumn = observer((props: Props) => {
    const { orderNum, step, steps, setSteps, setTestCaseSavedStatus } = props
    const { t } = useTranslation(['testCase'])

    const moveRow = async (orderNum: number, direction: 'up' | 'down' | number) => {
        if (StoreTestCase.readOnly) {
            return
        }

        const from = orderNum - 1

        let to
        switch (direction) {
            case 'up':
                to = from - 1
                break
            case 'down':
                to = from + 1
                break
            default:
                to = direction > steps.length ? steps.length : direction
        }

        const stepsCopy = [...steps]
        to >= 0
            ? stepsCopy.splice(to, 0, ...stepsCopy.splice(from, 1))
            : stepsCopy.splice(to + 1, 0, ...stepsCopy.splice(from, 1))

        StoreTestCase.handleMoveAttachments(from + 1, to + 1, steps.length, step.stepType)

        setSteps(resetNumeration(stepsCopy))
        await setTestCaseSavedStatus(false)
    }

    return (
        <div
            className={cn(styles.OrderNumColumn, {
                [styles.ReadOnly]: StoreTestCase.readOnly,
            })}
        >
            <div className={styles.OrderNumTitle}>
                <NumericInput
                    orderNum={orderNum}
                    maxOrderNum={steps.length}
                    moveRow={moveRow}
                    disabled={StoreTestCase.readOnly}
                />
                {step.required && (
                    <Tooltip title={t('testCase:requiredStep')}>
                        <div className={styles.RequiredStepIcon}>
                            <ShieldIcon />
                        </div>
                    </Tooltip>
                )}
            </div>
            {StoreTestCase.executionTestCase && <TestStepStatusSelection step={step} />}
            {!StoreTestCase.readOnly && (
                <div className={cn(styles.MoveStep)}>
                    {orderNum !== 1 && <CaretUpOutlined onClick={() => moveRow(orderNum, 'up')} />}
                    {orderNum !== steps.length && <CaretDownOutlined onClick={() => moveRow(orderNum, 'down')} />}
                </div>
            )}
        </div>
    )
})
