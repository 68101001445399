import React, { useEffect, useMemo, useRef, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { Tooltip, Typography } from 'antd'
import { Status } from '@/components/Status/Status'
import { TestCaseLocker } from '@/components/TestCaseLocker/TestCaseLocker'
import { getFolderRoot, searchFolderById } from '@/pages/TestFolders/components/FoldersTree/utils/folderUtils'
import IconComponent from './IconComponent'

import { StoreProject, StoreTestFolder } from '@/store'
import { pageUrls } from '@/consts'

import { ITestCase, StatusType, testCaseCellType } from '@/types'

import styles from './TableCell.module.scss'

const { Text } = Typography

interface Props {
    testCase: ITestCase
    value: string | number | boolean | StatusType
    cellType?: testCaseCellType
}

export const TableCell = observer((props: Props) => {
    const { testCase, value, cellType } = props

    const [tooltipVisible, setTooltipVisible] = useState(false)
    const [hasOverflow, setHasOverflow] = useState(false)

    const ref = useRef<HTMLSpanElement>(null)

    useEffect(() => {
        const compareSize = () => {
            if (ref.current) {
                const compare = ref.current.scrollWidth > ref.current.clientWidth
                setHasOverflow(compare)
            }
        }
        compareSize()

        window.addEventListener('resize', compareSize)

        return () => window.removeEventListener('resize', compareSize)
    }, [])

    const [isHovered, setIsHovered] = useState(false)

    const handleMouseEnter = () => {
        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
        setTooltipVisible(false)
    }

    useEffect(() => {
        if (hasOverflow && isHovered) {
            setTooltipVisible(true)
        } else {
            setTooltipVisible(false)
        }
    }, [hasOverflow, isHovered])

    //TODO
    const handleGetFolderPath = (folderId: string, folderName: string) => {
        const testCaseTree = searchFolderById(StoreTestFolder.foldersTree, folderId) || []

        let folderPath = ''
        const { folders, folderIds } = getFolderRoot(testCaseTree[0], folderId)

        const regex = new RegExp(`${folderId}+$`)
        folderIds.forEach((value, index) => {
            const splitedValue = value.split(',/').join('/').replace(/,*$/, '') //TODO: из-за кривого построения дерева иногда на конце лишние запятые
            if (regex.test(splitedValue)) {
                //TODO: folders[0] - корневая папка
                folderPath = `${folders[0]}${folders[index].split(',/').join('/').replace(/,*$/, '')}`
                return
            }
        })

        return folderPath
    }

    const getCell = (cellType: testCaseCellType | undefined) => {
        switch (cellType) {
            case 'common':
                return <Text ellipsis={{ tooltip: value }}>{value}</Text>
            case 'name':
                return (
                    <>
                        {testCase.testCaseBlockInfo && <TestCaseLocker blockInfo={testCase.testCaseBlockInfo} />}
                        <Tooltip
                            title={value}
                            open={tooltipVisible}
                            onOpenChange={(visible) => setTooltipVisible(visible && hasOverflow)}
                        >
                            <Text ref={ref} ellipsis onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                {value}
                            </Text>
                        </Tooltip>
                    </>
                )
            case 'description':
                return (
                    <>
                        <Tooltip
                            title={value}
                            open={tooltipVisible}
                            onOpenChange={(visible) => setTooltipVisible(visible && hasOverflow)}
                        >
                            <Text ref={ref} ellipsis onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                {value}
                            </Text>
                        </Tooltip>
                    </>
                )
            case 'status':
                return <Status status={value as StatusType} />
            case 'folder':
                const folderPath = useMemo(
                    () => handleGetFolderPath(testCase.testFolderId, value),
                    [testCase.testFolderId]
                )
                const handleFolderTooltip = () => {
                    if (folderPath) {
                        setTooltipVisible(true)
                    } else if (hasOverflow) {
                        setTooltipVisible(true)
                    } else {
                        setTooltipVisible(false)
                    }
                }
                return (
                    <>
                        <Tooltip
                            title={folderPath ? folderPath : value}
                            overlayInnerStyle={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            open={tooltipVisible}
                            onOpenChange={handleFolderTooltip}
                        >
                            <Text ref={ref} ellipsis onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                {value}
                            </Text>
                        </Tooltip>
                    </>
                )
            case 'automation':
                return <IconComponent isAutomated={!!testCase.isAutomated} className={styles.TableCellTypeIcon} />

            default:
                return (
                    <Tooltip
                        title={value}
                        open={tooltipVisible}
                        onOpenChange={(visible) => setTooltipVisible(visible && hasOverflow)}
                    >
                        <Text ref={ref} ellipsis onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            {value}
                        </Text>
                    </Tooltip>
                )
        }
    }

    return (
        <Link
            draggable="false"
            className={cn({ selectedRow: StoreTestFolder.showSelectedTestCaseId === testCase.id })}
            to={`${pageUrls.project}/${StoreProject.id}${pageUrls.testFolder}/${testCase.testFolderId}${pageUrls.testCase}/${testCase.id}`}
        >
            {getCell(cellType)}
        </Link>
    )
})
