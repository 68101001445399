// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FP6ThcKN9H71L4L_L64e{color:var(--color-text-secondary45);font-size:14px}.FP6ThcKN9H71L4L_L64e svg{display:none}.FP6ThcKN9H71L4L_L64e:hover svg{display:inline-block;font-size:18px;color:var(--color-text-primary)}.FP6ThcKN9H71L4L_L64e:before{content:\"@\"}.ant-typography-edit-content:before{content:none}", "",{"version":3,"sources":["webpack://./src/pages/User/UserNameField/UserNameField.module.scss"],"names":[],"mappings":"AAAA,sBACI,mCAAA,CACA,cAAA,CAEA,0BACI,YAAA,CAIA,gCACI,oBAAA,CACA,cAAA,CACA,+BAAA,CAIR,6BACI,WAAA,CAMA,oCACI,YAAA","sourcesContent":[".UserNameField {\n    color: var(--color-text-secondary45);\n    font-size: 14px;\n\n    svg {\n        display: none;\n    }\n\n    &:hover {\n        svg {\n            display: inline-block;\n            font-size: 18px;\n            color: var(--color-text-primary);\n        }\n    }\n\n    &:before {\n        content: '@';\n    }\n}\n\n:global {\n    .ant-typography-edit-content {\n        &:before {\n            content: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UserNameField": "FP6ThcKN9H71L4L_L64e"
};
export default ___CSS_LOADER_EXPORT___;
