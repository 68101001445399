import React, { ClipboardEvent, Dispatch, SetStateAction, useRef, useState } from 'react'

import { DateTime } from 'luxon'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, Input, Upload } from 'antd'
import type { InputRef } from 'antd'
import { Attachment } from '@/components/Attachment'

import { UploadOutlined } from '@ant-design/icons'

import { IMixedAttachments } from '@/types'
import type { UploadFile, UploadProps } from 'antd/es/upload/interface'

const { Dragger } = Upload

type Props = {
    fileList: UploadFile[]
    setFileList: Dispatch<SetStateAction<UploadFile[]>>
}

export const JiraUpload = observer((props: Props) => {
    const { fileList, setFileList } = props
    const { t } = useTranslation(['common'])
    const screenshotInputRef = useRef<InputRef>(null)

    const [isMouseOnDragger, setMouseOnDragger] = useState(false)

    const handleChange: UploadProps['onChange'] = ({ fileList }) => {
        const updatedFileList = fileList.filter((file) => file)
        setFileList(updatedFileList)
    }

    const onRemoveFileToUpload = async (file: IMixedAttachments) => {
        if (!('uid' in file)) {
            return
        }

        const updatedAttachments = fileList.filter((attachment) => attachment.uid !== file.uid)
        setFileList(updatedAttachments)
    }

    const renderFileItem = (_originNode: unknown, file: UploadFile) => {
        return <Attachment file={file} onDelete={onRemoveFileToUpload} isUploaded={'id' in file} />
    }

    const handleMouseEnterDragger = () => {
        screenshotInputRef.current?.focus()
        setMouseOnDragger(true)
    }

    const handleMouseLeaveDragger = () => {
        setMouseOnDragger(false)
    }

    const onPasteScreenshotToJira = (event: ClipboardEvent<HTMLInputElement>) => {
        if (isMouseOnDragger) {
            const items = event.clipboardData.items
            for (let i = 0; i < items.length; i++) {
                if (items[i].kind === 'file' && items[i].type.match('^image/')) {
                    const file = items[i].getAsFile() as File
                    const sreenshotName = `${t('common:screenshot')} (${DateTime.now().toFormat(
                        'dd.LL.yyyy HH:mm:ss'
                    )}).png`
                    const screenshot = new File([file], sreenshotName, {
                        type: file.type,
                    })

                    const screenshotFormattedToAntdUpload = {
                        uid: screenshot.lastModified,
                        originFileObj: screenshot,
                        lastModified: screenshot.lastModified,
                        name: screenshot.name,
                        size: screenshot.size,
                        type: screenshot.type,
                    } as unknown as UploadFile<any>

                    const updatedFileList = [...fileList, screenshotFormattedToAntdUpload]
                    setFileList(updatedFileList)
                }
            }
        }
    }

    return (
        <div onMouseEnter={handleMouseEnterDragger} onMouseLeave={handleMouseLeaveDragger} style={{ zIndex: -100 }}>
            <Dragger
                name="jiraAtachments"
                customRequest={() => null}
                multiple
                onChange={handleChange}
                fileList={fileList}
                itemRender={renderFileItem}
            >
                <div>
                    <p className="ant-upload-text">{t('common:buttons.uploadDragText')}</p>
                    <Button className="draggerUploadButton" size="small" type="primary" icon={<UploadOutlined />}>
                        {t('common:buttons.uploadButton')}
                    </Button>
                </div>
            </Dragger>
            <Input
                style={{ width: 0, height: 0 }}
                onPaste={onPasteScreenshotToJira}
                bordered={false}
                ref={screenshotInputRef}
            />
        </div>
    )
})
