import { useEffect } from 'react'

import i18n from 'i18next'

import { FormInstance } from 'antd/es/form'

type InternalNamePath = (string | number)[]

//перевод сообщений об ошибках при переключении языка в формах antd
const useTranslateFormMessages = (form: FormInstance) => {
    useEffect(() => {
        const errorFields = form
            .getFieldsError()
            .reduce((arr: InternalNamePath[], field) => (field.errors.length && arr.push(field.name), arr), [])
        form.validateFields(errorFields)
    }, [i18n.language])
}

export default useTranslateFormMessages
