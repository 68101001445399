import React from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button, Table, Tooltip } from 'antd'
import { ModalSave } from '@/components/ModalSave/ModalSave'
import { TestCaseLocker } from '@/components/TestCaseLocker/TestCaseLocker'

import { MenuUnfoldOutlined, PlusOutlined } from '@ant-design/icons'

import {
    SpinnerStore,
    StoreAuthController,
    StoreBlocker,
    StoreCreateTestSet,
    StoreTestCase,
    StoreTestCaseList,
    StoreTestFolder,
} from '@/store'
import { pageUrls } from '@/consts'

import { IExecutionCase, ITestCase, ITestCaseBlockInfo } from '@/types'
import type { ColumnsType } from 'antd/es/table'

import styles from './TableMenu.module.scss'

type Props = {
    testCases: ITestCase[]
    isModalSaveOpen: boolean
    onLeave: () => void
    handleDontSave: () => void
    handleSave: () => void
    handleCancel: () => void
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
}

export const TableMenu = observer((props: Props) => {
    const { testCases, setTestCaseSavedStatus, isModalSaveOpen, onLeave, handleDontSave, handleSave, handleCancel } =
        props
    const { t } = useTranslation(['testCase'])

    const filterID = testCases.map((testCase) => ({
        value: testCase.orderNum,
        text: testCase.orderNum,
    }))

    const handleLinkClick = async () => {
        const isLockedByCurrentUser =
            StoreTestCase.blockInfo && StoreAuthController.userId === StoreTestCase.blockInfo?.userId
        if (StoreTestCase.isSaved && StoreBlocker.blocker === null && isLockedByCurrentUser && StoreTestCase.blocked) {
            await StoreTestCase.toggleBlocked(false)
        }
    }

    const isLockedByCurrentUser = (blockInfo: ITestCaseBlockInfo | null) => {
        return blockInfo && StoreAuthController.userId === blockInfo?.userId
    }

    const columns: ColumnsType<ITestCase | IExecutionCase> = [
        {
            title: 'ID',
            dataIndex: 'orderNum',
            filters: filterID,
            className: 'linkColumn',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.orderNum - b.orderNum,
            onFilter: (value: string | number | boolean, record: ITestCase | IExecutionCase) =>
                record.orderNum === value,
            filterSearch: true,
            render: (orderNum: number, testCase) => (
                <Tooltip title={'testCaseName' in testCase ? testCase?.testCaseName : testCase?.name} placement="right">
                    <Link
                        onClick={handleLinkClick}
                        draggable="false"
                        to={
                            StoreTestCase.executionTestCase
                                ? `${pageUrls.testSet}/${testCase.executionTestSetId}${pageUrls.testCase}/${testCase.id}`
                                : `${pageUrls.project}/${StoreTestCase.projectId}${pageUrls.testFolder}/${StoreTestCase.testFolderId}${pageUrls.testCase}/${testCase?.id}`
                        }
                        key={orderNum}
                        className={cn({ [styles.CurrentTestCase]: testCase.id === StoreTestCase.id })}
                    >
                        <div className={styles.LinkContent}>
                            {'testCaseBlockInfo' in testCase &&
                                testCase?.testCaseBlockInfo &&
                                !isLockedByCurrentUser(testCase?.testCaseBlockInfo) && (
                                    <div className={styles.TestCaseLocker}>
                                        <TestCaseLocker
                                            blockInfo={testCase?.testCaseBlockInfo}
                                            page={pageUrls.testCase}
                                            navBar
                                            setTestCaseSavedStatus={setTestCaseSavedStatus}
                                        />
                                    </div>
                                )}
                            <span className={styles.OrderNum}>{orderNum}</span>
                        </div>
                    </Link>
                </Tooltip>
            ),
        },
    ]

    const onAddNewTestCase = () => {
        StoreTestCase.setNavigateToNewTestCase(true)
        onLeave()
    }

    const getBackUrl = () => {
        if (!StoreCreateTestSet.isCreatingTestSet) {
            if (StoreTestCase.executionTestCase) {
                return `${pageUrls.testSet}/${StoreTestCase.testSetId}`
            } else {
                if (StoreTestFolder.id) {
                    return `${pageUrls.project}/${StoreTestCase.projectId}?testFolder=${StoreTestCase.testFolderId}`
                } else {
                    return `${pageUrls.project}/${StoreTestCase.projectId}`
                }
            }
        } else {
            if (StoreCreateTestSet.selectedTestFolderId) {
                return `${pageUrls.project}/${StoreTestCase.projectId}${pageUrls.createTestSet}?folderId=${StoreCreateTestSet.selectedTestFolderId}`
            } else {
                return `${pageUrls.testSet}/${StoreCreateTestSet.testSetIdInModal}`
            }
        }
    }

    return (
        <div className={styles.TableMenu}>
            <div className={styles.Buttons}>
                <Tooltip title={t('common:buttons.back')}>
                    <Link draggable="false" to={getBackUrl()}>
                        <Button
                            onClick={handleLinkClick}
                            icon={<MenuUnfoldOutlined />}
                            disabled={SpinnerStore.show}
                            className={cn(styles.BackButton, 'borderless')}
                        />
                    </Link>
                </Tooltip>

                {!StoreTestCase.readOnly && (
                    <Tooltip title={t('testCase:createNewTestCase')}>
                        <Button type="primary" icon={<PlusOutlined />} onClick={onAddNewTestCase} />
                    </Tooltip>
                )}
            </div>

            {testCases.length ? (
                <Table
                    className={styles.TestCasesTable}
                    rowKey={'id'}
                    style={{ width: 100 }}
                    columns={columns}
                    dataSource={testCases}
                    pagination={false}
                    loading={StoreTestCaseList.updatingSideBar}
                    sticky
                />
            ) : null}

            {isModalSaveOpen && (
                <ModalSave
                    isModalSaveOpen={isModalSaveOpen}
                    handleDontSave={handleDontSave}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    pageType={pageUrls.testCase}
                />
            )}
        </div>
    )
})
