import React, { Dispatch, ReactElement, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { copyLink, useOnOutsideClick } from '@/utils'

import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import { ModalOnDeleteRow } from '@/components/ModalOnDeleteRow/ModalOnDeleteRow'

import { DeleteOutlined, LinkOutlined, PlusOutlined } from '@ant-design/icons'

import { SpinnerStore } from '@/store'
import { apiUrls, pageUrls } from '@/consts'

import { dropdownPositionType, ITestSet } from '@/types'

interface Props {
    testSet: ITestSet
    testSets: ITestSet[]
    setTestSets: Dispatch<SetStateAction<ITestSet[]>>
    loadData: () => void
    handleShowModalSelectTestCase?: (testSet: ITestSet) => void
    setSelectedRecord?: Dispatch<SetStateAction<ITestSet | undefined>>
    dropdownPosition?: dropdownPositionType
    button?: ReactElement
    isUserPage?: boolean
}

export const TestSetOptions = observer((props: Props) => {
    const {
        button,
        testSet,
        testSets,
        setTestSets,
        loadData,
        handleShowModalSelectTestCase,
        isUserPage,
        setSelectedRecord,
        dropdownPosition,
    } = props
    const { t } = useTranslation(['page--testSets', 'common'])

    const closeContextDropdown = () => {
        setSelectedRecord && setSelectedRecord(undefined)
    }

    const onOpenChange = () => {
        button && closeContextDropdown()
    }

    const isContextMenu = testSet && dropdownPosition
    isContextMenu && useOnOutsideClick(closeContextDropdown)

    const onCopyLink = (testSet: ITestSet) => {
        const href = `${window.location.origin}${pageUrls.testSet}/${testSet.id}`
        copyLink(href)
    }

    const onDelete = (testSet: ITestSet) => {
        testSet.id &&
            ModalOnDeleteRow({
                id: testSet.id,
                name: testSet.name,
                items: testSets,
                setItems: setTestSets as any,
                loadData: loadData,
                specificApiUrl: apiUrls.testSet,
                title: t('page--testSets:deleteGuard.title', {
                    name: testSet.name,
                }),
                content: t('page--testSets:deleteGuard.content'),
                okText: t('common:buttons.ok'),
                cancelText: t('common:buttons.cancel'),
            })
    }

    const items: MenuProps['items'] = [
        {
            key: 'addTestCase',
            label: t('page--testSets:addTestCases'),
            icon: <PlusOutlined />,
            onClick: () => handleShowModalSelectTestCase && handleShowModalSelectTestCase(testSet),
            disabled: !handleShowModalSelectTestCase || isUserPage,
            className: ((!handleShowModalSelectTestCase || isUserPage) && 'hidden') || '',
        },
        {
            key: 'deleteItem',
            label: t('common:buttons.delete'),
            icon: <DeleteOutlined />,
            onClick: () => onDelete(testSet),
        },
        {
            key: 'copyLink',
            label: t('page--testSets:copyLink'),
            icon: <LinkOutlined />,
            onClick: () => onCopyLink(testSet),
        },
    ]

    return (
        <Dropdown
            onOpenChange={onOpenChange}
            menu={{ items }}
            open={!!button ? undefined : !!testSet}
            overlayStyle={isContextMenu ? { top: dropdownPosition?.y, left: dropdownPosition?.x } : undefined}
            placement="bottomLeft"
            disabled={SpinnerStore.show}
        >
            {button ? button : <></>}
        </Dropdown>
    )
})
