import React, { FC, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Layout, Tabs, Tooltip, Typography } from 'antd'
import type { TabsProps } from 'antd'
import { Header } from '@/components/Header/Header'
import { Documentation } from '@/components/SettingPageTabs/Documentation/Documentation'
import { RoleModel } from '@/components/SettingPageTabs/RoleModel/RoleModel'
import { Team } from '@/components/SettingPageTabs/Team/Team'
import { CustomFields } from './CustomFields/CustomFields'
import { MainSettings } from './MainSettings/MainSettings'

import { LeftOutlined } from '@ant-design/icons'

import { StoreWorkspace } from '@/store'
import { pageUrls, settingTabs } from '@/consts'

import { IWorkspace } from '@/types'

import styles from './WorkspaceSettings.module.scss'

const { Title } = Typography
const { Content } = Layout

export const WorkspaceSettings: FC = observer(() => {
    const { t } = useTranslation(['page--workspaceSettings', 'common', 'documentation', 'roleModel', 'page--user'])
    const { workspaceId } = useParams()
    const location = useLocation()
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate()
    const [workspaceInfo, setWorkspaceInfo] = useState<IWorkspace>()
    const [currentTab, setCurrentTab] = useState(searchParams.get('tab') || settingTabs.mainSettings)
    const [selectedTab, setSelectedTab] = useState(searchParams.get('tab') || settingTabs.mainSettings)
    const [isLeavingTab, setLeavingTab] = useState(false)
    const [changesSaved, setChangesSaved] = useState(true)
    const [isGoingBack, setGoingBack] = useState(false)

    const getWorkspace = () => {
        if (workspaceId) {
            StoreWorkspace.getWorkspace(workspaceId).then((workspace: IWorkspace) => {
                setWorkspaceInfo(workspace)
                StoreWorkspace.setWorkspaceId(workspaceId)
            })
        }
    }

    const backUrl = useMemo(() => location.state?.previousPath, [])
    const goGoBack = () => {
        const url = backUrl ? backUrl : pageUrls.workspaces
        navigate(url)
    }

    useEffect(() => {
        workspaceId && getWorkspace()
    }, [workspaceId])

    const leaveTab = (activeKey?: string) => {
        setCurrentTab(activeKey || selectedTab)
        setSearchParams(`?tab=${activeKey || selectedTab}`)
        setLeavingTab(false)
    }

    const tabs: TabsProps['items'] = [
        {
            label: t('page--workspaceSettings:mainSettings.title'),
            key: 'mainSettings',
            children: (
                <MainSettings
                    isLeavingTab={isLeavingTab}
                    setLeavingTab={setLeavingTab}
                    selectedTab={selectedTab}
                    workspaceInfo={workspaceInfo!}
                    getWorkspace={getWorkspace}
                    leaveTab={leaveTab}
                    currentTab={currentTab}
                    changesSaved={changesSaved}
                    setChangesSaved={setChangesSaved}
                    goGoBack={goGoBack}
                    setGoingBack={setGoingBack}
                    isGoingBack={isGoingBack}
                />
            ),
        },
        {
            label: t('documentation:title'),
            key: settingTabs.documentation,
            children: (
                <Documentation
                    isLeavingTab={isLeavingTab}
                    setLeavingTab={setLeavingTab}
                    selectedTab={selectedTab}
                    currentTab={currentTab}
                    parentInfo={workspaceInfo!}
                    leaveTab={leaveTab}
                    pageType={pageUrls.workspaceSettings}
                    changesSaved={changesSaved}
                    setChangesSaved={setChangesSaved}
                    goGoBack={goGoBack}
                    setGoingBack={setGoingBack}
                    isGoingBack={isGoingBack}
                />
            ),
        },

        {
            label: t('page--workspaceSettings:team.title'),
            key: settingTabs.team,
            children: workspaceId && <Team pageType={pageUrls.workspaceSettings} workspaceId={workspaceId} />,
        },
        {
            label: t('roleModel:title'),
            key: settingTabs.roleModel,
            children: <RoleModel pageType={pageUrls.workspaceSettings} />,
        },
        {
            label: (
                <Tooltip title={t('common:inDevelopment')}> {t('page--workspaceSettings:customFields.title')}</Tooltip>
            ),
            key: settingTabs.customFields,
            children: <CustomFields />,
            disabled: true,
        },
    ]

    const onLeave = (activeKey: string) => {
        if (currentTab !== settingTabs.documentation && currentTab !== settingTabs.mainSettings) {
            leaveTab(activeKey)
        } else {
            setSelectedTab(activeKey)
            setLeavingTab(true)
        }
    }
    const onGoBack = () => {
        if (changesSaved) {
            goGoBack()
        } else {
            setGoingBack(true)
        }
    }

    return (
        <div className={styles.WorkspaceSettings}>
            <Header />

            {workspaceInfo && (
                <Content className="content">
                    <div className={styles.Title}>
                        <Tooltip title={t('common:buttons.back')}>
                            <LeftOutlined onClick={onGoBack} className="backLink" />
                        </Tooltip>
                        <Title level={3}>
                            {t('page--workspaceSettings:workspaceSettings', { name: workspaceInfo?.workspaceName })}
                        </Title>
                    </div>
                    <Tabs
                        activeKey={currentTab}
                        className={styles.Menu}
                        tabPosition={'left'}
                        items={tabs}
                        onChange={onLeave}
                    />
                </Content>
            )}
        </div>
    )
})
