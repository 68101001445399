// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mQS5mILT6QQjOHn0miWt{height:56px}.KMNiSHtVWQpDsCfHatWX{display:flex;align-items:center}.KMNiSHtVWQpDsCfHatWX .ant-select-selection-item{display:flex;align-items:center}.KMNiSHtVWQpDsCfHatWX .ant-select-arrow{align-items:baseline}", "",{"version":3,"sources":["webpack://./src/components/LanguageSwitcher/LanguageSwitcher.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CAGI,iDACI,YAAA,CACA,kBAAA,CAEJ,wCACI,oBAAA","sourcesContent":[".HeaderLanguageSwitcher {\n    height: 56px; //высота шапки\n}\n\n.LanguageSwitcher {\n    display: flex;\n    align-items: center;\n\n    :global {\n        .ant-select-selection-item {\n            display: flex;\n            align-items: center;\n        }\n        .ant-select-arrow {\n            align-items: baseline;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"HeaderLanguageSwitcher": "mQS5mILT6QQjOHn0miWt",
	"LanguageSwitcher": "KMNiSHtVWQpDsCfHatWX"
};
export default ___CSS_LOADER_EXPORT___;
