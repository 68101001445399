// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".oPx1MVPdMdWFB4Ne3QhO svg{font-size:14px;color:var(--color-text-secondary45)}", "",{"version":3,"sources":["webpack://./src/pages/CreateTestSet/TestFolderHeader/ProjectListDropdown/ProjectListDropdown.module.scss"],"names":[],"mappings":"AAAA,0BACI,cAAA,CACA,mCAAA","sourcesContent":[".NavIcon svg {\n    font-size: 14px;\n    color: var(--color-text-secondary45);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavIcon": "oPx1MVPdMdWFB4Ne3QhO"
};
export default ___CSS_LOADER_EXPORT___;
