// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jZlM52KIogFfEzNhQKau .KbXKkdo0SCeVZHz5kvZZ{display:flex;border-bottom:1px solid var(--color-other-divider)}.jZlM52KIogFfEzNhQKau .O1pYg3C_tgZM9az22hJw{padding-top:12px;padding-bottom:12px;padding-left:16px;display:flex;gap:8px}.jZlM52KIogFfEzNhQKau .O1pYg3C_tgZM9az22hJw .xlR2r9keYXuF9bJyIKa2{padding-top:4px}.jZlM52KIogFfEzNhQKau .O1pYg3C_tgZM9az22hJw .EPCPMy4HzLfsk2yvTWeW{display:flex;flex-flow:column;gap:4px;min-width:100px;max-width:150px}.jZlM52KIogFfEzNhQKau .O1pYg3C_tgZM9az22hJw .EPCPMy4HzLfsk2yvTWeW .yXMyunD5hLKAuOsJtCN8{font-size:16px}.jZlM52KIogFfEzNhQKau .O1pYg3C_tgZM9az22hJw .EPCPMy4HzLfsk2yvTWeW .NmEljv5YZPgdC4bWBiLK{font-size:12px}", "",{"version":3,"sources":["webpack://./src/pages/TestSet/TestSet/components/TestSetsSideBar/TestSetsTable/TestSetsTable.module.scss"],"names":[],"mappings":"AACI,4CACI,YAAA,CACA,kDAAA,CAGJ,4CACI,gBAAA,CACA,mBAAA,CACA,iBAAA,CAEA,YAAA,CACA,OAAA,CAEA,kEACI,eAAA,CAGJ,kEACI,YAAA,CACA,gBAAA,CACA,OAAA,CACA,eAAA,CACA,eAAA,CAEA,wFACI,cAAA,CAGJ,wFACI,cAAA","sourcesContent":[".TestSetTable {\n    .TestSetRowLinkWrapper {\n        display: flex;\n        border-bottom: 1px solid var(--color-other-divider);\n    }\n\n    .TestSetRow {\n        padding-top: 12px;\n        padding-bottom: 12px;\n        padding-left: 16px;\n\n        display: flex;\n        gap: 8px;\n\n        .Status {\n            padding-top: 4px;\n        }\n\n        .Title {\n            display: flex;\n            flex-flow: column;\n            gap: 4px;\n            min-width: 100px;\n            max-width: 150px;\n\n            .TestSetName {\n                font-size: 16px;\n            }\n\n            .OrderNum {\n                font-size: 12px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestSetTable": "jZlM52KIogFfEzNhQKau",
	"TestSetRowLinkWrapper": "KbXKkdo0SCeVZHz5kvZZ",
	"TestSetRow": "O1pYg3C_tgZM9az22hJw",
	"Status": "xlR2r9keYXuF9bJyIKa2",
	"Title": "EPCPMy4HzLfsk2yvTWeW",
	"TestSetName": "yXMyunD5hLKAuOsJtCN8",
	"OrderNum": "NmEljv5YZPgdC4bWBiLK"
};
export default ___CSS_LOADER_EXPORT___;
