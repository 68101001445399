import React, { FC, useEffect, useMemo, useState } from 'react'

import { Integrations } from '@/pages/ProjectSettings/Integrations/Integrations'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { Layout, Tabs, Tooltip, Typography } from 'antd'
import type { TabsProps } from 'antd'
import { Header } from '@/components/Header/Header'
import { Documentation } from '@/components/SettingPageTabs/Documentation/Documentation'
import { RoleModel } from '@/components/SettingPageTabs/RoleModel/RoleModel'
import { Team } from '@/components/SettingPageTabs/Team/Team'
import { MainSettings } from './MainSettings/MainSettings'

import { LeftOutlined } from '@ant-design/icons'

import { StoreProject } from '@/store'
import { pageUrls, settingTabs } from '@/consts'

import { IProject } from '@/types'

import styles from './ProjectSettings.module.scss'

const { Title } = Typography
const { Content } = Layout

export const ProjectSettings: FC = observer(() => {
    const { t } = useTranslation(['page--projectSettings', 'common', 'documentation', 'roleModel'])
    const { projectId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [projectInfo, setProjectInfo] = useState<IProject>()
    const [currentTab, setCurrentTab] = useState(searchParams.get('tab') || settingTabs.mainSettings)
    const [selectedTab, setSelectedTab] = useState(searchParams.get('tab') || settingTabs.mainSettings)
    const [isLeavingTab, setLeavingTab] = useState(false)
    const [changesSaved, setChangesSaved] = useState(true)
    const [isGoingBack, setGoingBack] = useState(false)

    const getProject = () => {
        if (projectId) {
            StoreProject.getProject(projectId).then((project: IProject) => {
                setProjectInfo(project)
                StoreProject.setProjectId(projectId)
            })
        }
    }

    const backUrl = useMemo(() => location.state?.previousPath, [])
    const goGoBack = () => {
        const url = backUrl ? backUrl : projectInfo ? `${pageUrls.workspace}/${projectInfo.workspaceId}` : '/'
        navigate(url)
    }

    useEffect(() => {
        projectId && getProject()
    }, [projectId])

    const leaveTab = (activeKey?: string) => {
        setCurrentTab(activeKey || selectedTab)
        setSearchParams(`?tab=${activeKey || selectedTab}`)
        setLeavingTab(false)
    }

    const tabs: TabsProps['items'] = [
        {
            label: t('page--projectSettings:mainSettings.title'),
            key: 'mainSettings',
            children: (
                <MainSettings
                    isLeavingTab={isLeavingTab}
                    setLeavingTab={setLeavingTab}
                    selectedTab={selectedTab}
                    projectInfo={projectInfo!}
                    getProject={getProject}
                    leaveTab={leaveTab}
                    currentTab={currentTab}
                    changesSaved={changesSaved}
                    setChangesSaved={setChangesSaved}
                    goGoBack={goGoBack}
                    setGoingBack={setGoingBack}
                    isGoingBack={isGoingBack}
                />
            ),
        },
        {
            label: t('documentation:title'),
            key: settingTabs.documentation,
            children: (
                <Documentation
                    isLeavingTab={isLeavingTab}
                    setLeavingTab={setLeavingTab}
                    selectedTab={selectedTab}
                    currentTab={currentTab}
                    parentInfo={projectInfo!}
                    leaveTab={leaveTab}
                    pageType={pageUrls.projectSettings}
                    changesSaved={changesSaved}
                    setChangesSaved={setChangesSaved}
                    goGoBack={goGoBack}
                    setGoingBack={setGoingBack}
                    isGoingBack={isGoingBack}
                />
            ),
        },
        {
            label: t('page--projectSettings:team.title'),
            key: settingTabs.team,
            children: projectInfo && <Team pageType={pageUrls.projectSettings} workspaceId={projectInfo.workspaceId} />,
        },

        {
            label: t('page--projectSettings:integrations.title'),
            key: settingTabs.integration,
            children: <Integrations />,
        },

        {
            label: t('roleModel:title'),
            key: settingTabs.roleModel,
            children: <RoleModel pageType={pageUrls.projectSettings} />,
        },
    ]

    const onLeave = (activeKey: string) => {
        if (currentTab !== settingTabs.documentation && currentTab !== settingTabs.mainSettings) {
            leaveTab(activeKey)
        } else {
            setSelectedTab(activeKey)
            setLeavingTab(true)
        }
    }
    const onGoBack = () => {
        if (changesSaved) {
            goGoBack()
        } else {
            setGoingBack(true)
        }
    }

    return (
        <div className={styles.ProjectSettings}>
            <Header />

            {projectInfo && (
                <Content className="content">
                    <div className={styles.Title}>
                        <Tooltip title={t('common:buttons.back')}>
                            <LeftOutlined onClick={onGoBack} className="backLink" />
                        </Tooltip>
                        <Title level={3}>
                            {t('page--projectSettings:projectSettings', { name: projectInfo.projectName })}
                        </Title>
                    </div>
                    <Tabs
                        activeKey={currentTab}
                        className={styles.Menu}
                        tabPosition={'left'}
                        items={tabs}
                        onChange={onLeave}
                    />
                </Content>
            )}
        </div>
    )
})
