// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kRcnUH4DMPVuIIubPttV .FKIB00vSFkPHTkgFW2tN{margin-top:14px}.kRcnUH4DMPVuIIubPttV .VP3lyLUZ3UFiAWguMRE7{display:flex;flex-wrap:wrap;gap:8px;margin-top:14px;margin-bottom:18px}.kRcnUH4DMPVuIIubPttV .VP3lyLUZ3UFiAWguMRE7 .P6RbnClPWqQe7b8sZelv{display:flex;align-items:center;flex-direction:column;gap:40px;margin:0 auto}.yoL8o8JzSDktaGtvTeTl{margin-top:24px;display:flex;flex-direction:column;gap:8px}.yoL8o8JzSDktaGtvTeTl span{font-size:14px}.QYyAHhoN3kS5WzR6CRXM{display:flex;gap:8px;align-items:center}", "",{"version":3,"sources":["webpack://./src/pages/ProjectSettings/Integrations/Integrations.module.scss"],"names":[],"mappings":"AACI,4CACI,eAAA,CAGJ,4CACI,YAAA,CACA,cAAA,CACA,OAAA,CACA,eAAA,CACA,kBAAA,CAEA,kEACI,YAAA,CACA,kBAAA,CACA,qBAAA,CACA,QAAA,CACA,aAAA,CAKZ,sBACI,eAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,2BACI,cAAA,CAIR,sBACI,YAAA,CACA,OAAA,CACA,kBAAA","sourcesContent":[".IntegrationPage {\n    .Title {\n        margin-top: 14px;\n    }\n\n    .Integrations {\n        display: flex;\n        flex-wrap: wrap;\n        gap: 8px;\n        margin-top: 14px;\n        margin-bottom: 18px;\n\n        .NoIntegrations {\n            display: flex;\n            align-items: center;\n            flex-direction: column;\n            gap: 40px;\n            margin: 0 auto;\n        }\n    }\n}\n\n.IntegrationsServiceSelection {\n    margin-top: 24px;\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n\n    span {\n        font-size: 14px;\n    }\n}\n\n.Label {\n    display: flex;\n    gap: 8px;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IntegrationPage": "kRcnUH4DMPVuIIubPttV",
	"Title": "FKIB00vSFkPHTkgFW2tN",
	"Integrations": "VP3lyLUZ3UFiAWguMRE7",
	"NoIntegrations": "P6RbnClPWqQe7b8sZelv",
	"IntegrationsServiceSelection": "yoL8o8JzSDktaGtvTeTl",
	"Label": "QYyAHhoN3kS5WzR6CRXM"
};
export default ___CSS_LOADER_EXPORT___;
