import React, { Dispatch, KeyboardEvent, MouseEvent, ReactElement, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { copyLink, useOnOutsideClick } from '@/utils'

import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'
import { ModalOnDeleteRow } from '@/components/ModalOnDeleteRow/ModalOnDeleteRow'

import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    LinkOutlined,
    PlusOutlined,
    RetweetOutlined,
    SendOutlined,
} from '@ant-design/icons'

import { SpinnerStore, StoreTestFolder, StoreTestSet } from '@/store'
import { apiUrls, folderTabs, pageUrls } from '@/consts'

import { dropdownPositionType, ITestFolder } from '@/types'
import { EModalTypeFolder } from '@/enums'

type Props = {
    folder: ITestFolder
    folders: ITestFolder[]
    setTestFolders: Dispatch<SetStateAction<ITestFolder[]>>
    setSelectedForActionFolder: Dispatch<SetStateAction<ITestFolder | undefined>>
    setSelectedFolderId: Dispatch<SetStateAction<string>>
    setIsModalRenameOpen: Dispatch<SetStateAction<boolean>>
    setIsModalSelectOpen: Dispatch<SetStateAction<boolean>>
    setModalTypeFolder: Dispatch<SetStateAction<EModalTypeFolder | undefined>>
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
    onCreateSubFolder: (
        folderId: string,
        nestingLevel: number,
        e?: any,
        setSelectedRecord?: Dispatch<SetStateAction<ITestFolder | undefined>>
    ) => void
    trigger?: ('contextMenu' | 'click' | 'hover')[] | undefined
    button?: ReactElement
    dropdownPosition?: dropdownPositionType
    setSelectedRecord?: Dispatch<SetStateAction<ITestFolder | undefined>>
}

export const FolderDropdownOptions = observer((props: Props) => {
    const {
        folder,
        button,
        folders,
        setTestFolders,
        setSelectedForActionFolder,
        setIsModalRenameOpen,
        setIsModalSelectOpen,
        setModalTypeFolder,
        pageType,
        trigger,
        onCreateSubFolder,
        dropdownPosition,
        setSelectedRecord,
        setSelectedFolderId,
    } = props

    const { t } = useTranslation(['page--testFolders', 'common'])
    const [searchParams, setSearchParams] = useSearchParams()

    const onOpenChange = () => {
        setSelectedRecord && setSelectedRecord(undefined)
    }

    const isContextMenu = folder && dropdownPosition
    isContextMenu && useOnOutsideClick(onOpenChange)

    const onRenameFolder = (folder: ITestFolder, domEvent: MouseEvent | KeyboardEvent) => {
        domEvent.stopPropagation()
        setSelectedForActionFolder(folder)
        setSelectedRecord && setSelectedRecord(undefined)
        setIsModalRenameOpen(true)
    }

    const onCopyLink = (folder: ITestFolder, domEvent: MouseEvent | KeyboardEvent) => {
        domEvent.stopPropagation()
        const href = `${window.location.origin}${pageUrls.project}/${folder.projectId}?testFolder=${folder.id}`
        copyLink(href)
        setSelectedRecord && setSelectedRecord(undefined)
    }

    const onCopyFolder = (folder: ITestFolder, domEvent: MouseEvent | KeyboardEvent) => {
        domEvent.stopPropagation()
        setModalTypeFolder(EModalTypeFolder.COPY)
        setSelectedForActionFolder(folder)
        setSelectedRecord && setSelectedRecord(undefined)
        setIsModalSelectOpen(true)
    }

    const onMoveFolder = (folder: ITestFolder, domEvent: MouseEvent | KeyboardEvent) => {
        domEvent.stopPropagation()
        setModalTypeFolder(EModalTypeFolder.MOVE)
        setSelectedForActionFolder(folder)
        setSelectedRecord && setSelectedRecord(undefined)
        setIsModalSelectOpen(true)
    }

    const onRestore = (folder: ITestFolder, domEvent: MouseEvent | KeyboardEvent) => {
        domEvent.stopPropagation()
        setModalTypeFolder(EModalTypeFolder.RESTORE)
        setSelectedForActionFolder(folder)
        setSelectedRecord && setSelectedRecord(undefined)
        setIsModalSelectOpen(true)
    }

    const handleResetAfterDelete = (folderId: string) => {
        const currentFolderId =
            pageType === pageUrls.testFolder ? StoreTestFolder.id : StoreTestSet.selectedTestSetFolderId

        if (folderId === currentFolderId) {
            setSearchParams()
            StoreTestFolder.resetTestFolder()
            setSelectedFolderId('')
        }

        if (pageType === pageUrls.testSets) {
            StoreTestSet.setSelectedTestSetFolderId('')
        }
    }

    const onDeleteFolder = (folder: ITestFolder, domEvent: MouseEvent | KeyboardEvent) => {
        domEvent.stopPropagation()

        const hasNestedTestCases = folder.nestedTestCasesCount && folder.nestedTestCasesCount > 0
        const isRemovingToArchive = hasNestedTestCases

        const title = isRemovingToArchive
            ? t('page--testFolders:deleteGuard.titleArchive', {
                  name: folder.name,
              })
            : t('page--testFolders:deleteGuard.titleDelete', {
                  name: folder.name,
              })

        const content = isRemovingToArchive
            ? t('page--testFolders:deleteGuard.contentArchive')
            : t('page--testFolders:deleteGuard.contentDelete')

        folder.id &&
            ModalOnDeleteRow({
                id: folder.id,
                name: folder.name,
                items: folders,
                setItems: setTestFolders as any,
                specificApiUrl: pageType === pageUrls.testFolder ? apiUrls.testFolder : apiUrls.testSetFolder,
                title: title,
                content: content,
                okText: t('common:buttons.ok'),
                cancelText: t('common:buttons.cancel'),
                handleResetAfterDelete: handleResetAfterDelete,
            })

        setSelectedRecord && setSelectedRecord(undefined)
    }

    const items: MenuProps['items'] =
        StoreTestFolder.currentFolderTab !== folderTabs.archive
            ? [
                  {
                      key: 'rename',
                      label: t('common:buttons.rename'),
                      icon: <EditOutlined />,
                      onClick: ({ domEvent }) => onRenameFolder(folder, domEvent),
                  },

                  {
                      key: 'copyLink',
                      label: t('page--testFolders:copyLink'),
                      icon: <LinkOutlined />,
                      onClick: ({ domEvent }) => onCopyLink(folder, domEvent),
                  },

                  {
                      key: 'copy',
                      label: t('common:buttons.copy'),
                      icon: <CopyOutlined />,
                      onClick: ({ domEvent }) => onCopyFolder(folder, domEvent),
                  },

                  {
                      key: 'createFolder',
                      label: t('page--testFolders:createFolder'),
                      icon: <PlusOutlined />,
                      onClick: ({ domEvent }) =>
                          onCreateSubFolder(folder.id, folder.nestingLevel, domEvent, setSelectedRecord),
                  },

                  {
                      key: 'deleteItem',
                      label: t('common:buttons.delete'),
                      icon: <DeleteOutlined />,
                      onClick: ({ domEvent }) => onDeleteFolder(folder, domEvent),
                  },

                  {
                      key: 'move',
                      label: t('page--testFolders:move'),
                      icon: <SendOutlined />,
                      onClick: ({ domEvent }) => onMoveFolder(folder, domEvent),
                  },
              ]
            : [
                  {
                      key: 'Restore',
                      label: t('page--testFolders:restore'),
                      icon: <RetweetOutlined />,
                      onClick: ({ domEvent }) => onRestore(folder, domEvent),
                  },
              ]

    return (
        <Dropdown
            onOpenChange={onOpenChange}
            menu={{ items }}
            trigger={isContextMenu ? ['click'] : ['hover']}
            open={!trigger && !!button ? undefined : !!folder}
            overlayStyle={isContextMenu ? { top: dropdownPosition?.y, left: dropdownPosition?.x } : undefined}
            placement="bottomLeft"
            disabled={SpinnerStore.show}
        >
            {button ? button : <></>}
        </Dropdown>
    )
})
