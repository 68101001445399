// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nR0ctnh6qVFt6eD_txa1{padding:0 !important;text-align:center}", "",{"version":3,"sources":["webpack://./src/components/NumericInput/NumericInput.module.scss"],"names":[],"mappings":"AAAA,sBACI,oBAAA,CACA,iBAAA","sourcesContent":[".NumericInput {\n    padding: 0 !important;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NumericInput": "nR0ctnh6qVFt6eD_txa1"
};
export default ___CSS_LOADER_EXPORT___;
