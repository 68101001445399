import React, { Dispatch, SetStateAction, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, Typography } from 'antd'
import { JiraTaskItem } from '@/components/JiraTaskItem/JiraTaskItem'
import { ModalCreateIssue } from './ModalCreateIssue'

import { JiraBugIcon } from '@/components/Icons'

import { StoreTestCase } from '@/store'

import { IJiraIssue, IStep } from '@/types'
import { EExecutionCaseStatus } from '@/enums'

import styles from './StepIssues.module.scss'

const { Text } = Typography

type Props = {
    step: IStep
    steps: IStep[]
    setSteps: Dispatch<SetStateAction<IStep[]>>
    isExecutionTestCase: boolean
}

export const StepIssues = observer((props: Props) => {
    const { step, isExecutionTestCase, setSteps, steps } = props
    const { t } = useTranslation(['testCase', 'page--auth', 'common', 'validation'])
    const [isShownCreateJiraTaskModal, setIsShownCreateJiraTaskModal] = useState(false)

    return (
        <div className={styles.StepIssues}>
            {!!step?.jiraIssuesWithData?.length && (
                <div className={styles.JiraTasks}>
                    <Text className={styles.IssuesTitle}>{t('testCase:jira.issues')}</Text>
                    <div className={styles.JiraTaskItem}>
                        {step.jiraIssuesWithData.map((issue: IJiraIssue) => (
                            <JiraTaskItem
                                key={issue.id}
                                jiraKey={issue.key}
                                id={issue.id}
                                status={issue.status}
                                statusKey={issue.statusKey}
                                name={issue.name}
                                link={issue.link}
                            />
                        ))}
                    </div>
                </div>
            )}
            {isExecutionTestCase && StoreTestCase.executionCaseStarted && StoreTestCase.jiraIntegrationAvailability && (
                <div className={styles.CreateJiraIssueButton}>
                    <Button
                        onClick={() => setIsShownCreateJiraTaskModal(true)}
                        disabled={
                            StoreTestCase.executionCaseStatus !== EExecutionCaseStatus.NOT_PASSED &&
                            !StoreTestCase.executionCaseStarted
                        }
                        icon={<JiraBugIcon className={styles.JiraBugIcon} />}
                    >
                        {t('testCase:jira.createTitle')}
                    </Button>
                    <ModalCreateIssue
                        step={step}
                        steps={steps}
                        isShownCreateJiraTaskModal={isShownCreateJiraTaskModal}
                        setIsShownCreateJiraTaskModal={setIsShownCreateJiraTaskModal}
                        setSteps={setSteps}
                    />
                </div>
            )}
        </div>
    )
})
