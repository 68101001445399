import React, { Dispatch, SetStateAction } from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { Button, Tooltip } from 'antd'

import { ControlOutlined } from '@ant-design/icons'

import styles from './ToggleFilters.module.scss'

type Props = {
    showFilters: boolean
    setShowFilters: Dispatch<SetStateAction<boolean>>
    customTooltipTitle?: string
    buttonStyled?: boolean
    buttonSize?: number
}

export const ToggleFilters = (props: Props) => {
    const { showFilters, setShowFilters, customTooltipTitle, buttonStyled, buttonSize } = props
    const { t } = useTranslation(['common'])

    const tooleShowFilters = () => {
        setShowFilters(!showFilters)
    }

    const tooltipTitle = customTooltipTitle ? customTooltipTitle : t('common:buttons.advancedSearch')
    return (
        <Tooltip title={tooltipTitle} placement="top">
            {buttonStyled ? (
                <Button
                    type="default"
                    icon={<ControlOutlined className={cn({ [styles.ShowFilters]: showFilters })} />}
                    onClick={tooleShowFilters}
                    style={{ height: buttonSize, width: buttonSize }}
                />
            ) : (
                <ControlOutlined onClick={tooleShowFilters} className={cn({ [styles.ShowFilters]: showFilters })} />
            )}
        </Tooltip>
    )
}
