import { Key } from 'react'

import { observable } from 'mobx'

import { IO, sliceArrayIntoChunks } from '@/utils'

import { useAuthorizedAPI } from './ms-auth-api'

import { apiLimitIds, apiUrls, baseApiUrl, showAllPages } from '@/consts'

import {
    IExportItems,
    IObjectAttachment,
    IObjectAttachments,
    IObjectFilter,
    IProject,
    ISelectTestFolder,
    ITestCase,
    ITestCaseIdsWithFolder,
    ITestFolder,
    IWorkspace,
} from '@/types'
import { EOrder } from '@/enums'

const baseURL = `${baseApiUrl}/ms-project/project`

const api = useAuthorizedAPI(baseURL)

export const MsProjectApi = observable({
    async saveItem(item: IWorkspace | Partial<IProject> | ITestFolder | ITestCase, specificApiUrl: apiUrls) {
        const { id } = (await api.post(specificApiUrl, item)).data
        return id
    },

    async saveAndGetItem(item: IWorkspace | IProject | ITestFolder | ITestCase, specificApiUrl: apiUrls) {
        const resp = (await api.post(specificApiUrl, item)).data
        return resp
    },

    async updateItem(
        item: Partial<IWorkspace | IProject | ITestFolder | ITestCase | IObjectAttachment>,
        specificApiUrl: apiUrls
    ) {
        const { id } = (await api.put(specificApiUrl, item)).data
        return id
    },

    async updateAndGetItem(
        item: Partial<IWorkspace | IProject | ITestFolder | ITestCase | IObjectAttachment>,
        specificApiUrl: apiUrls
    ) {
        const resp = (await api.put(specificApiUrl, item)).data
        return resp
    },

    async copyItem(testCaseId: string, testFolderId: string, specificApiUrl: apiUrls) {
        const { id } = (await api.post(`${specificApiUrl}/${testCaseId}/copy?testFolderId=${testFolderId}`)).data
        return id
    },

    async deleteTestCases(testCases: Key[]) {
        const props = { ids: testCases }
        await api.post(`${apiUrls.testCase}/delete`, props)
    },

    async copyTestCases(props: ITestCaseIdsWithFolder) {
        const testCasesCopy = (await api.post(`${apiUrls.testCase}/copy`, props)).data
        return testCasesCopy
    },

    async moveTestCases(props: ITestCaseIdsWithFolder) {
        await api.post(`${apiUrls.testCase}/move`, props)
    },

    async unarchiveTestCase(props: ITestCaseIdsWithFolder) {
        await api.post(`${apiUrls.testCase}/unarchive`, props)
    },

    async requestBlock(testCaseId: string) {
        try {
            await api.post(`${apiUrls.testCase}/request-block?testCaseId=${testCaseId}`)
        } catch (err) {
            return false
        }
    },

    async disableBlock(testCaseId: string) {
        try {
            await api.post(`${apiUrls.testCase}/disable-block?testCaseId=${testCaseId}`)
        } catch (err) {
            return false
        }
    },

    async getBlockInfo(testCaseId: string) {
        const response = await api.get(`${apiUrls.testCase}/get-block-info/${testCaseId}`)
        return response.data
    },

    async blockHeartbeat(testCaseId: string) {
        api.post(`${apiUrls.testCase}/block-heartbeat?testCaseId=${testCaseId}`)
    },

    async copyTestFolder(props: ISelectTestFolder) {
        const newFolder = (await api.post(`${apiUrls.testFolder}/copy`, props)).data
        return newFolder
    },

    async moveTestFolder(props: ISelectTestFolder) {
        await api.post(`${apiUrls.testFolder}/move`, props)
    },

    async unarchiveTestFolder(props: ISelectTestFolder) {
        await api.post(`${apiUrls.testFolder}/unarchive`, props)
    },

    async exportItems(items: IExportItems, specificApiUrl: apiUrls) {
        const file = await api.post(`${specificApiUrl}/export`, items, {
            responseType: 'blob',
        })

        const fileName = file.headers['content-disposition']
            .split(';')
            .find((desposition: string) => desposition.includes('filename='))
            .replace('filename=', '')
            .trim()
            .replace(/(^"|"$)/g, '')

        IO.download(file.data, fileName)
    },

    async getItem(id: string, specificApiUrl: apiUrls) {
        const response = await api.get(`${specificApiUrl}/${id}`)
        return response.data
    },

    async getProjectItems(id: string, specificApiUrl: apiUrls, isDeleted?: boolean) {
        const response = await api.get(
            isDeleted === undefined
                ? `${specificApiUrl}?projectId=${id}`
                : `${specificApiUrl}?projectId=${id}&isDeleted=${isDeleted}`
        )
        return response.data
    },

    async deleteItem(id: Key | string, specificApiUrl: apiUrls) {
        await api.delete(`${specificApiUrl}/${id}`)
    },

    async deleteMultipleAttachments(filebindIds: string[]) {
        const slicedFilebindIds = sliceArrayIntoChunks(filebindIds, apiLimitIds)
        await Promise.all(
            slicedFilebindIds.map(async (idsSlice) => {
                const request = idsSlice.map((id) => `ids=${id}`).join('&')
                await api.delete(`${apiUrls.objectAttachment}/delete-multiple-attachments?${request}`)
            })
        )
    },

    async getTestCaseFull(id: string, revision: number, specificApiUrl: apiUrls) {
        const response = await api.get(`${specificApiUrl}/full-by-revision/${id}/${revision}`)
        return response.data
    },

    async filter(props: {
        page?: number
        pageSize?: number
        filter?: Record<string, string[] | string | boolean>
        sort?: { field: string; direction: EOrder }[]
        specificApiUrl: apiUrls
    }) {
        const request = {
            pageSize: props.pageSize || showAllPages,
            page: (props.page || 1) - 1,
            filter: props.filter || {},
            sort:
                props.sort && props.sort.length > 0
                    ? props.sort
                    : [
                          {
                              field: props.specificApiUrl === apiUrls.testFolder ? 'name' : 'orderNum',
                              direction: props.specificApiUrl === apiUrls.testFolder ? EOrder.ASC : EOrder.DESC,
                          },
                      ],
        }

        return (await api.post(`${props.specificApiUrl}/filter`, request)).data
    },

    async bindAttachment(attachment: IObjectAttachment) {
        return (await api.post(`${apiUrls.objectAttachment}`, attachment)).data
    },

    async bindAttachments(attachments: IObjectAttachments) {
        return (await api.post(`${apiUrls.objectAttachment}/create-multiple-attachments`, attachments)).data
    },

    async getAttachments(props?: {
        page?: number
        pageSize?: number
        filter?: Partial<IObjectFilter>
        sort?: { field: string; direction: EOrder }[]
    }) {
        const request = {
            pageSize: props?.pageSize || showAllPages,
            page: (props?.page || 1) - 1,
            filter: props?.filter || {},
            sort:
                props?.sort && props?.sort.length > 0 ? props.sort : [{ field: 'createdDate', direction: EOrder.ASC }],
        }

        return (await api.post(`${apiUrls.objectAttachment}/filter`, request)).data.result
    },
})
