import React, { Dispatch, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Image, Space } from 'antd'
import Spinner from '@/components/Spinner/Spinner'

import {
    DeleteOutlined,
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons'

import styles from './Attachment.module.scss'

type Props = {
    fileName: string
    fileSrc: string
    showFile: boolean
    setShowFile: Dispatch<SetStateAction<boolean>>
    isDisabled: boolean
    handleDelete: () => void
    handleDownloadAttachment: () => void
    canDownload: boolean
    isImageAttachment: boolean
}

export const FileVIew = observer((props: Props) => {
    const {
        fileName,
        fileSrc,
        isDisabled,
        handleDelete,
        canDownload,
        handleDownloadAttachment,
        isImageAttachment,
        showFile,
        setShowFile,
    } = props
    const { t } = useTranslation(['common'])

    return (
        <Image
            width={960}
            className={styles.HiddenImage}
            src={fileSrc}
            alt={fileName}
            preview={{
                visible: showFile,
                src: fileSrc,
                onVisibleChange: (value) => {
                    setShowFile(value)
                },
                imageRender: (originalNode) =>
                    fileSrc ? (
                        isImageAttachment ? (
                            originalNode
                        ) : (
                            <video controls width={'960px'}>
                                <source src={fileSrc} />
                            </video>
                        )
                    ) : (
                        <Spinner show fixed />
                    ),
                toolbarRender: (
                    _,
                    { transform: { scale }, actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn } }
                ) =>
                    fileSrc ? (
                        <Space size={12} className="toolbar-wrapper">
                            {canDownload && <DownloadOutlined onClick={handleDownloadAttachment} />}
                            {!isDisabled && (
                                <div className={styles.DeleteButton} onClick={(e) => e.stopPropagation()}>
                                    <DeleteOutlined onClick={handleDelete} />
                                </div>
                            )}
                            {isImageAttachment && (
                                <>
                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                    <RotateRightOutlined onClick={onRotateRight} />
                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                </>
                            )}
                        </Space>
                    ) : null,
            }}
        />
    )
})
