export enum EUserType {
    TECHNICAL = 'TECHNICAL',
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
}

export enum EDefaultUserRoles {
    WORKSPACE_USER = 'WORKSPACE_USER',
    WORKSPACE_ADMIN = 'WORKSPACE_ADMIN',
    PROJECT_ADMIN = 'PROJECT_ADMIN',
    PROJECT_USER = 'PROJECT_USER',
    TEST_LEAD = 'TEST_LEAD',
    TEST_ENGINEER = 'TEST_ENGINEER',
    TEST_DESIGNER = 'TEST_DESIGNER',
}

export enum EUserMessengers {
    SKYPE = 'Skype',
    TELEGRAM = 'Telegram',
    WHATSAPP = 'WhatsApp',
    VK = 'VK',
    DISCORD = 'Discord',
}
