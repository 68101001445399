import React from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Breadcrumb, Typography } from 'antd'

import { pageUrls } from '@/consts'

import { IProject } from '@/types'

const { Text } = Typography

type Props = {
    projectInfo: IProject
}

export const Breadcrumbs = (props: Props) => {
    const { projectInfo } = props
    const { t } = useTranslation(['page--testFolders'])

    const breadcrumbItems = [
        {
            key: 'allWorkspaces',
            title: (
                <Link to={pageUrls.workspaces}>
                    <Text type="secondary">{t('page--testFolders:allWorkspaces')}</Text>
                </Link>
            ),
        },
        {
            key: 'workspace',
            title: (
                <Link to={`${pageUrls.workspace}/${projectInfo?.workspaceId}`}>
                    <Text type="secondary">{projectInfo?.workspaceName}</Text>
                </Link>
            ),
        },
    ]

    return <Breadcrumb items={breadcrumbItems} />
}
