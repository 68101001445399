// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zsqgE0DHgRk8dihmGJJp{width:100%}.zsqgE0DHgRk8dihmGJJp .a_aNH3Tk1dyuNg8SsWqv{margin-bottom:0;font-size:12px;color:var(--color-text-secondary);position:relative;top:10px;z-index:2;margin-left:12px;background:#fff}.zsqgE0DHgRk8dihmGJJp .IRqfAGAWuFItheqqWHzn::after{display:inline-block;-webkit-margin-start:4px;margin-inline-start:4px;color:#ff4d4f;font-family:SimSun,sans-serif;line-height:1;content:\"*\"}.zsqgE0DHgRk8dihmGJJp .ant-form-item{margin-bottom:0}.zsqgE0DHgRk8dihmGJJp .hideRequiredMark::after{display:none}.zsqgE0DHgRk8dihmGJJp .ant-input-textarea{margin-bottom:8px}", "",{"version":3,"sources":["webpack://./src/components/FormItem/FormItem.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CAEA,4CACI,eAAA,CACA,cAAA,CACA,iCAAA,CACA,iBAAA,CACA,QAAA,CACA,SAAA,CACA,gBAAA,CACA,eAAA,CAGJ,mDACI,oBAAA,CACA,wBAAA,CAAA,uBAAA,CACA,aAAA,CACA,6BAAA,CACA,aAAA,CACA,WAAA,CAIA,qCACI,eAAA,CAGJ,+CACI,YAAA,CAGJ,0CACI,iBAAA","sourcesContent":[".FormItem {\n    width: 100%;\n\n    .Label {\n        margin-bottom: 0;\n        font-size: 12px;\n        color: var(--color-text-secondary);\n        position: relative;\n        top: 10px;\n        z-index: 2;\n        margin-left: 12px;\n        background: white;\n    }\n\n    .LableRequired::after {\n        display: inline-block;\n        margin-inline-start: 4px;\n        color: #ff4d4f;\n        font-family: SimSun, sans-serif;\n        line-height: 1;\n        content: '*';\n    }\n\n    :global {\n        .ant-form-item {\n            margin-bottom: 0;\n        }\n\n        .hideRequiredMark::after {\n            display: none;\n        }\n\n        .ant-input-textarea {\n            margin-bottom: 8px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormItem": "zsqgE0DHgRk8dihmGJJp",
	"Label": "a_aNH3Tk1dyuNg8SsWqv",
	"LableRequired": "IRqfAGAWuFItheqqWHzn"
};
export default ___CSS_LOADER_EXPORT___;
