import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Checkbox, Table, Tooltip } from 'antd'

import { SpinnerStore } from '@/store'
import { showAllPages } from '@/consts'

import { IRolesWithPermissions, ITablePermission } from '@/types'
import { EPermissions } from '@/enums'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'

import styles from './RolePermissions.module.scss'

type Props = {
    selectedRole: IRolesWithPermissions
}

export const RolePermissions = observer((props: Props) => {
    const { t } = useTranslation(['roleModel'])
    const { selectedRole } = props

    const onChange = (e: CheckboxChangeEvent) => {
        console.log(`checked = ${e.target.checked}`)
    }

    const formatedRolePermissions = useMemo(
        () =>
            [
                {
                    key: 'workspace',
                    fullAccess: 'hidden',
                    read: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.GET_WORKSPACE
                    ),
                    create: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.CREATE_WORKSPACE
                    ),
                    delete: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.DELETE_WORKSPACE
                    ),
                    descriptions: {
                        readDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.GET_WORKSPACE
                        )?.description,
                        createDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.CREATE_WORKSPACE
                        )?.description,
                        deleteDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.DELETE_WORKSPACE
                        )?.description,
                    },
                },

                {
                    key: 'users',
                    fullAccess: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.MANAGE_USERS
                    ),
                    read: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.GET_USER_INFO
                    ),
                    create: 'hidden',
                    delete: 'hidden',
                    descriptions: {
                        fullAccessDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.MANAGE_USERS
                        )?.description,
                        readDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.GET_USER_INFO
                        )?.description,
                    },
                },

                {
                    key: 'project',
                    fullAccess: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.MANAGE_ALL_WORKSPACE_PROJECTS
                    ),
                    read: selectedRole.permissions.some((permission) => permission?.name === EPermissions.GET_PROJECT),
                    create: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.CREATE_PROJECT
                    ),
                    delete: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.DELETE_PROJECT
                    ),
                    descriptions: {
                        fullAccessDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.MANAGE_ALL_WORKSPACE_PROJECTS
                        )?.description,
                        readDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.GET_PROJECT
                        )?.description,
                        createDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.CREATE_PROJECT
                        )?.description,
                        deleteDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.DELETE_PROJECT
                        )?.description,
                    },
                },

                {
                    key: 'testCase',
                    fullAccess: 'hidden',
                    read: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.GET_TEST_CASE
                    ),
                    create: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.CREATE_TEST_CASE
                    ),
                    delete: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.DELETE_TEST_CASE
                    ),

                    descriptions: {
                        readDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.GET_TEST_CASE
                        )?.description,
                        createDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.CREATE_TEST_CASE
                        )?.description,
                        deleteDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.DELETE_TEST_CASE
                        )?.description,
                    },
                },

                {
                    key: 'testSet',
                    fullAccess: 'hidden',
                    read: selectedRole.permissions.some((permission) => permission?.name === EPermissions.GET_TEST_SET),
                    create: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.CREATE_TEST_SET
                    ),
                    delete: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.DELETE_TEST_SET
                    ),
                    descriptions: {
                        readDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.GET_TEST_SET
                        )?.description,
                        createDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.CREATE_TEST_SET
                        )?.description,
                        deleteDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.DELETE_TEST_SET
                        )?.description,
                    },
                },

                {
                    key: 'launchTestCase',
                    fullAccess: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.EXECUTE_TEST_CASE
                    ),
                    read: 'hidden',
                    create: 'hidden',
                    delete: 'hidden',
                    descriptions: {
                        fullAccessDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.EXECUTE_TEST_CASE
                        )?.description,
                    },
                },

                {
                    key: 'exportTestCase',
                    fullAccess: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.EXPORT_TEST_CASE
                    ),
                    read: 'hidden',
                    create: 'hidden',
                    delete: 'hidden',
                    descriptions: {
                        fullAccessDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.EXPORT_TEST_CASE
                        )?.description,
                    },
                },

                {
                    key: 'attachments',
                    fullAccess: 'hidden',
                    read: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.GET_ATTACHMENTS
                    ),
                    create: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.CREATE_ATTACHMENTS
                    ),
                    delete: selectedRole.permissions.some(
                        (permission) => permission?.name === EPermissions.DELETE_ATTACHMENTS
                    ),
                    descriptions: {
                        readDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.GET_ATTACHMENTS
                        )?.description,
                        createDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.CREATE_ATTACHMENTS
                        )?.description,
                        deleteDescription: selectedRole.permissions.find(
                            (permission) => permission?.name === EPermissions.DELETE_ATTACHMENTS
                        )?.description,
                    },
                },
            ] as ITablePermission[],
        [selectedRole.permissions]
    )

    const getTableColumns = (permissions: ITablePermission[]) => {
        if (!permissions) return []

        return [
            {
                title: t('roleModel:header.permissions'),
                key: 'permissions',
                dataIndex: 'permissions',

                render: (text: string, permission: ITablePermission) => (
                    <span>{t(`roleModel:rows.${permission?.key}`)}</span>
                ),
            },
            {
                title: t('roleModel:header.fullAccess'),
                key: 'fullAccess',
                dataIndex: 'fullAccess',
                render: (text: string, permission: ITablePermission) => (
                    <Tooltip title={permission?.descriptions?.fullAccessDescription}>
                        <Checkbox
                            checked={permission?.fullAccess !== 'hidden' && permission?.fullAccess}
                            onChange={onChange}
                            className={permission?.fullAccess === 'hidden' ? 'hidden' : ''}
                        />
                    </Tooltip>
                ),
            },
            {
                title: t('roleModel:header.read'),
                key: 'read',
                dataIndex: 'read',
                render: (text: string, permission: ITablePermission) => (
                    <Tooltip title={permission?.descriptions?.readDescription}>
                        <Checkbox
                            checked={permission?.read !== 'hidden' && permission?.read}
                            onChange={onChange}
                            className={permission?.read === 'hidden' ? 'hidden' : ''}
                        />
                    </Tooltip>
                ),
            },
            {
                title: t('roleModel:header.create'),
                key: 'create',
                dataIndex: 'create',
                render: (text: string, permission: ITablePermission) => (
                    <Tooltip title={permission?.descriptions?.createDescription}>
                        <Checkbox
                            checked={permission?.create !== 'hidden' && permission?.create}
                            onChange={onChange}
                            className={permission?.create === 'hidden' ? 'hidden' : ''}
                        />
                    </Tooltip>
                ),
            },
            {
                title: t('roleModel:header.delete'),
                key: 'delete',
                dataIndex: 'delete',
                render: (text: string, permission: ITablePermission) => (
                    <Tooltip title={permission?.descriptions?.deleteDescription}>
                        <Checkbox
                            checked={permission?.delete !== 'hidden' && permission?.delete}
                            onChange={onChange}
                            className={permission?.delete === 'hidden' ? 'hidden' : ''}
                        />
                    </Tooltip>
                ),
            },
        ]
    }

    const columns = useMemo(() => getTableColumns(formatedRolePermissions), [t, formatedRolePermissions])

    return (
        <Table
            columns={columns}
            dataSource={formatedRolePermissions}
            rowKey={'key'}
            pagination={{
                showSizeChanger: false,
                pageSize: showAllPages,
                defaultCurrent: 1,
                hideOnSinglePage: true,
            }}
            size="small"
            className={styles.RolePermissions}
            loading={SpinnerStore.show}
        />
    )
})
