import React, { FC, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { mergeStepsToExecutionSteps, parseNormativeTime } from '@/utils'

import { Header } from '@/components/Header/Header'
import { TestCaseComponent } from '@/components/TestCaseComponent/TestCaseComponent'

import { StoreProject, StoreTestCase, StoreTestSet, StoreWorkspace } from '@/store'
import { apiUrls } from '@/consts'

import { IExecutionCase, IJiraIssue, IShowColumns, ITestCase, ITestSet } from '@/types'
import { EExecutionCaseStatus } from '@/enums'

import styles from './ExecutionCase.module.scss'

export const ExecutionCasePage: FC = observer(() => {
    const { testCaseId, testSetId } = useParams()
    const [testCaseInfo, setTestCaseInfo] = useState<IExecutionCase>()
    const [isTestCaseLoading, setTestCaseLoading] = useState(false)

    const getTestCase = async (testCaseId: string) => {
        const testCase: IExecutionCase = await StoreTestSet.getItem(testCaseId, apiUrls.executionCase)
        setTestCaseInfo(testCase)
        StoreTestCase.setTestCaseInfo(testCase)
        StoreTestCase.setTestCaseId(testCaseId)
    }

    useEffect(() => {
        if (testCaseId) {
            setTestCaseLoading(true)
            getTestCase(testCaseId)
        }
    }, [testCaseId])

    const getTestSet = async (testSetId: string) => {
        const testSet: ITestSet = await StoreTestSet.getItem(testSetId, apiUrls.testSet)
        StoreTestCase.setTestCasesInSet(testSet.executionCases)
        !StoreWorkspace.id && StoreWorkspace.setWorkspaceId(testSet.workspaceId)
        !StoreProject.id && StoreProject.setProjectId(testSet.projectId)
    }

    useEffect(() => {
        if (testSetId) {
            getTestSet(testSetId)
        }
    }, [testSetId])

    const getExeTestCaseAttachments = async (exeTestCaseInfo: IExecutionCase, testCaseFull: ITestCase) => {
        const exeStepsId = exeTestCaseInfo.executionSteps?.map((step) => step.id) as string[]
        const stepsId = testCaseFull.steps?.map((step) => step.externalId) as string[]

        await StoreTestCase.getExeTestCaseAttachments(
            [exeTestCaseInfo.id, ...exeStepsId],
            [testCaseFull.id, ...stepsId],
            exeTestCaseInfo.revisionNum
        )
    }

    const getJiraIssues = async (testCaseInfo: IExecutionCase, testCase: ITestCase) => {
        const jiraIds: string[] = []
        testCaseInfo.executionSteps?.forEach((step) => jiraIds.push(...step.jiraIssues))

        if (jiraIds.length) {
            const jiraIssues = await StoreTestCase.getJiraIssues(StoreProject.id, jiraIds)

            if (jiraIssues.length && testCaseInfo.executionSteps) {
                const testCaseInfoCopy = { ...testCaseInfo }

                if (testCaseInfoCopy.executionSteps) {
                    testCaseInfoCopy.executionSteps?.forEach((step, index) => {
                        const updatedJiraIssuesWithData: IJiraIssue[] = []
                        if (step.jiraIssues.length) {
                            step.jiraIssues.forEach((issueId) => {
                                updatedJiraIssuesWithData.push(
                                    jiraIssues.find((fullIssue) => fullIssue.id === issueId)!
                                )
                            })
                            if (testCaseInfoCopy.executionSteps) {
                                testCaseInfoCopy.executionSteps[index].jiraIssuesWithData = updatedJiraIssuesWithData
                            }
                        }
                    })

                    StoreTestCase.setTestCaseInfo(testCaseInfoCopy)
                    const mergedSteps = mergeStepsToExecutionSteps(testCase.steps, testCaseInfoCopy.executionSteps)
                    StoreTestCase.setTestCaseSteps(mergedSteps)
                }
            }
        }
    }

    const getJiraIntegrationAvailability = async () => {
        const availability = await StoreTestCase.getJiraIntegrationAvailability(StoreProject.id)
        StoreTestCase.setJiraIntegrationAvailability(availability)
    }

    const getInfoFromParentTestCase = async (testCaseInfo: IExecutionCase) => {
        const testCase: ITestCase = await StoreTestCase.getTestCaseFull(
            StoreTestCase.testCaseInfo.testCaseId,
            StoreTestCase.testCaseInfo.revisionNum
        )

        await getExeTestCaseAttachments(testCaseInfo, testCase)
        StoreTestCase.setTestCasePriority(testCase.priority)
        StoreTestCase.setTestCaseLinks(testCase.additionalLinks.additionalLinks || [])
        StoreTestCase.setTestCaseStatus(testCase.workStatus)

        const allStepsInfo = mergeStepsToExecutionSteps(testCase.steps, StoreTestCase.testCaseInfo?.executionSteps)

        StoreTestCase.setTestCaseSteps(allStepsInfo)

        const initialColumnsVisibility: IShowColumns = {
            testData: testCase.steps.some((step) => step.testData),
            comment: testCase.steps.some((step) => step.comment),
        }
        StoreTestCase.setShowColumns(initialColumnsVisibility)
        if (StoreProject.id) {
            await getJiraIntegrationAvailability()
            if (StoreTestCase.executionCaseStatus !== EExecutionCaseStatus.NOT_PASSED) {
                await getJiraIssues(testCaseInfo, testCase)
            }
        }
        setTestCaseLoading(false)
    }

    useEffect(() => {
        if (testCaseInfo?.id) {
            StoreTestCase.setTestCaseId(testCaseInfo.id)
            StoreTestCase.setTestCaseName(testCaseInfo.testCaseName)

            StoreTestCase.setTestCaseDescription(testCaseInfo.testCaseDescription)

            StoreTestCase.setTestCaseOrderNum(testCaseInfo.orderNum)

            const { hours, minutes } = parseNormativeTime(testCaseInfo.normativeTime)
            StoreTestCase.setNormativeHours(hours)
            StoreTestCase.setNormativeMinutes(minutes)

            StoreTestCase.setReadOnly(true)
            StoreTestCase.setExecutionTestCase(true)
            StoreTestCase.setTestSetId(StoreTestCase.testCaseInfo.executionTestSetId)
            StoreTestCase.setExecutionCaseCompleted(!!StoreTestCase.testCaseInfo.completed)
            StoreTestCase.setExecutionCaseStatus(testCaseInfo.executionCaseStatus)
            StoreTestCase.setDurationExecutionCase(testCaseInfo?.durationExecutionCase || '')

            testCaseInfo.createdBy && StoreTestCase.setCreatedBy(testCaseInfo.createdBy)

            StoreTestCase.setSidebarType(
                testCaseInfo.executionCaseStatus !== EExecutionCaseStatus.NOT_PASSED ? 'testCaseStatus' : 'settings'
            )

            StoreTestCase.setInitialCompleteWithError(testCaseInfo.executionCaseStatus)
            getInfoFromParentTestCase(testCaseInfo)
        }
    }, [testCaseInfo, StoreProject.id])

    useEffect(() => {
        StoreTestCase.setIsCompleteWithError()
    }, [StoreTestCase.executionCaseStatus])

    return (
        <div className={styles.TestCase}>
            <Header />
            <TestCaseComponent isExecutionTestCase isTestCaseLoading={isTestCaseLoading} />
        </div>
    )
})
