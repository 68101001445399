import { DataNodeWithName } from '@/types'

export const searchInTree = (tree: DataNodeWithName[], name: string): DataNodeWithName[] => {
    return tree.reduce((r, { children = [], ...object }) => {
        if (object.name.toLowerCase().includes(name.toLowerCase())) {
            r.push({ ...object, children })
            return r
        }
        children = searchInTree(children, name)
        if (children.length) {
            r.push(Object.assign(object, { children }))
        }
        return r
    }, [])
}
