import React, { Dispatch, Key, SetStateAction } from 'react'

import { Modal } from 'antd'

import { StoreProject, StoreTestCase, StoreTestFolder, StoreWorkspace } from '@/store'
import { apiUrls } from '@/consts'

import { IProject, ITestCase, ITestFolder, IWorkspace } from '@/types'

interface Props {
    items: any
    setItems: Dispatch<SetStateAction<any>>
    elementsCount: number
    pageSize: number
    loadData: () => void
    specificApiUrl: apiUrls
    content: string
    title: string
    okText: string
    cancelText: string
    setShowTableMenu: Dispatch<SetStateAction<boolean>>
    selectedRowKeys: Key[]
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
}

export const ModalOnDeleteRows = (props: Props) => {
    const {
        items,
        setItems,
        elementsCount,
        pageSize,
        loadData,
        specificApiUrl,
        title,
        content,
        okText,
        cancelText,
        setShowTableMenu,
        selectedRowKeys,
        setSelectedRowKeys,
    } = props

    const handleDelete = async () => {
        switch (specificApiUrl) {
            case apiUrls.workspace:
                await StoreWorkspace.deleteWorkspaces(selectedRowKeys)
                break
            case apiUrls.project:
                await StoreProject.deleteProjects(selectedRowKeys)
                break
            case apiUrls.testFolder:
                await StoreTestFolder.deleteTestFolders(selectedRowKeys)
                break
            case apiUrls.testCase:
                await StoreTestCase.deleteTestCases(selectedRowKeys)
                break
        }

        const updatedItems = items.filter(
            (item: IWorkspace | IProject | ITestFolder | ITestCase) => !selectedRowKeys.includes(item.id as Key)
        )
        setItems(updatedItems)
        setShowTableMenu(false)
        setSelectedRowKeys([])

        //если удалено с не первой страницы и там ничего не осталось, то перезагрузить с переходом на первую
        if (updatedItems.length === 0 && elementsCount - pageSize > 0) {
            loadData()
        }

        if (specificApiUrl === apiUrls.testCase || specificApiUrl === apiUrls.testSet) {
            StoreTestFolder.updateFoldersTree()
        }
    }

    Modal.confirm({
        centered: true,
        title: title,
        content: content,
        onOk: handleDelete,
        okText: okText,
        cancelText: cancelText,
    })
}
