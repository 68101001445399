import { Key } from 'react'

import { makeAutoObservable, runInAction } from 'mobx'

import { MsAttachmentApi, MsExecutorApi, MsProjectApi } from '@/api'

import { formatToSeconds, showMessage } from '@/utils'

import { PreferencesStore, SpinnerStore, StoreBlocker, StoreProject, StoreTestSet } from '@/store'
import {
    apiUrls,
    attachmentBatchMaxSizeMb,
    defaultTestCaseColumnOrder,
    heartBeatInterval,
    unitsOfInformation,
} from '@/consts'

import {
    IAttachment,
    IBindAttachment,
    IExecutionCase,
    IExecutionStep,
    IExportItems,
    IFileToUpload,
    IJiraIssue,
    IObjectAttachment,
    IObjectAttachments,
    IObjectFilter,
    IShowColumns,
    IStep,
    ITestCase,
    ITestCaseBlockInfo,
    IUser,
    IUserCommonInfo,
    SideBarTypes,
    StepType,
} from '@/types'
import { EEntityType, EExecutionCaseStatus, EPriority, EStepType, EWorkStatus } from '@/enums'

export const StoreTestCase = makeAutoObservable({
    id: '',
    name: '',
    description: '',
    projectId: '',
    testFolderId: '',
    testFolderName: '',
    testSetId: '',
    priority: EPriority.MEDIUM,
    status: EWorkStatus.DRAFT,
    normativeTime: 0,
    normativeHours: 0,
    normativeMinutes: 0,
    initialNormativeHours: 0,
    initialNormativeMinutes: 0,
    additionalLinks: [] as string[],
    orderNum: 0,
    users: [],
    isSaved: true,
    creatingInProgress: false,
    navigateToNewTestCase: false,
    steps: [] as IStep[],
    requiredStepChanged: false,
    readOnly: false,
    executionTestCase: false,
    cancelChanges: false,
    executionCaseStarted: false,
    executionCaseStartedTime: 0,
    executionCaseCompletedTime: 0,
    executionCaseStatus: EExecutionCaseStatus.NOT_PASSED,
    createdBy: {} as IUserCommonInfo,
    topRequiredStep: {
        STEP_PRECONDITION: 0,
        DEFAULT_STEP: 0,
        STEP_POSTCONDITION: 0,
    },
    executionCaseCompleted: false,
    testCaseInfo: {} as IExecutionCase | ITestCase | any,
    testCasesInSet: [],
    durationExecutionCase: '',
    sidebarType: 'settings',
    completeWithError: false,
    initialCompleteWithError: false,
    showColumns: {
        testData: false,
        comment: false,
    } as IShowColumns,
    columnsOrder: defaultTestCaseColumnOrder,
    filesToUpload: [] as IFileToUpload[],
    filesToDelete: [] as IAttachment[],
    attachments: [] as IAttachment[],
    uploadingAttachment: false,
    toggleResetSearchTestCase: false,
    jiraIntegrationAvailability: false,
    blocked: false,
    blockInfo: {} as ITestCaseBlockInfo | null,
    isProjectAdmin: false,
    blockHeartbeatTimerId: {} as ReturnType<typeof setInterval>,

    setBlockHeartbeatTimerId(value: ReturnType<typeof setInterval>) {
        this.blockHeartbeatTimerId = value
    },

    setIsProjectAdmin(value: boolean) {
        this.isProjectAdmin = value
    },

    setJiraIntegrationAvailability(value: boolean) {
        this.jiraIntegrationAvailability = value
    },

    setToggleResetSearchTestCase() {
        this.toggleResetSearchTestCase = !this.toggleResetSearchTestCase
    },

    setCreatingInProgress(value: boolean) {
        this.creatingInProgress = value
    },

    async toggleBlocked(value: boolean, testCaseId?: string) {
        const id = testCaseId || this.id
        if (!this.executionTestCase && id) {
            if (value) {
                if (this.blocked !== value) {
                    runInAction(async () => {
                        await this.requestBlock(id)
                        const blockInfo = await this.getBlockInfo(id)
                        this.setBlockInfo(blockInfo)
                    })
                }
            } else {
                if (this.blocked !== value) {
                    runInAction(async () => {
                        await this.disableBlock(id)
                        this.setReadOnly(false)
                        this.setBlockInfo(null)
                    })
                }
            }
        }
    },

    setBlocked(value: boolean) {
        if (!this.executionTestCase) {
            this.blocked = value
        }
    },

    setTestCaseId(value: string) {
        this.id = value
    },

    setBlockInfo(value: ITestCaseBlockInfo | null) {
        if (!this.executionTestCase) {
            this.blockInfo = value
        }
    },

    setTestCaseName(value: string) {
        this.name = value
    },

    setTestCaseDescription(value: string) {
        this.description = value
    },

    setProjectId(value: string) {
        this.projectId = value
    },

    setTestFolderId(value: string) {
        this.testFolderId = value
    },

    setTestFolderName(value: string) {
        this.testFolderName = value
    },

    setTestCasePriority(value: EPriority) {
        this.priority = value
    },

    setTestCaseStatus(value: EWorkStatus) {
        this.status = value
    },

    setNormativeTime(value: number) {
        this.normativeTime = value
    },

    setNormativeHours(value: number | null) {
        this.normativeHours = value || 0
    },

    setNormativeMinutes(value: number | null) {
        this.normativeMinutes = value || 0
    },

    seInitialNormativeHours(value: number | null) {
        this.initialNormativeHours = value || 0
    },

    seInitialNormativeMinutes(value: number | null) {
        this.initialNormativeMinutes = value || 0
    },

    setTestCaseLinks(value: string[]) {
        this.additionalLinks = value
    },

    setTestCaseUsers(value: any) {
        this.users = value
    },

    setTestCaseOrderNum(value: number) {
        this.orderNum = value
    },

    async setIsSaved(value: boolean, dontUpdateLocker?: boolean) {
        if (this.isSaved !== value) {
            this.isSaved = value

            //если были какие-либо изменения, тогда блокировать тест-кейс
            if (dontUpdateLocker === true) {
                return
            }
            if (value === false && this.id && !this.executionTestCase) {
                await this.toggleBlocked(true)
            }
        }
    },

    setRequiredStepChanged(value: boolean) {
        this.requiredStepChanged = value
    },

    setNavigateToNewTestCase(value: boolean) {
        this.navigateToNewTestCase = value
    },

    setReadOnly(value: boolean) {
        this.readOnly = value
    },

    setExecutionTestCase(value: boolean) {
        this.executionTestCase = value
    },

    setCancelChanges(value: boolean) {
        this.cancelChanges = value
    },

    setExecutionCaseStarted(value: boolean) {
        this.executionCaseStarted = value
    },

    setExecutionCaseStartedtime(value: number) {
        this.executionCaseStartedTime = value
    },

    setExecutionCaseCompledTime(value: number) {
        this.executionCaseCompletedTime = value
    },

    setCreatedBy(value: IUserCommonInfo) {
        this.createdBy = value
    },

    setTestSetId(value: string) {
        this.testSetId = value
    },

    updateTopRequiredStep(stepType: EStepType) {
        const topRequiredStep = StoreTestCase.testCaseInfo?.executionSteps.reduce((min: number, step: IStep) => {
            return step.required === true &&
                step?.executionStepStatus === null &&
                step.stepType === stepType &&
                (step.orderNum < min || !min)
                ? step.orderNum
                : min
        }, 0)

        this.topRequiredStep[stepType] = topRequiredStep
    },

    updateTopRequiredSteps() {
        this.updateTopRequiredStep(EStepType.STEP_PRECONDITION)
        this.updateTopRequiredStep(EStepType.DEFAULT_STEP)
        this.updateTopRequiredStep(EStepType.STEP_POSTCONDITION)
    },

    setExecutionCaseCompleted(value: boolean) {
        this.executionCaseCompleted = value
    },

    setTestCaseInfo(value: IExecutionCase | ITestCase) {
        this.testCaseInfo = value
    },

    setDurationExecutionCase(value: string) {
        this.durationExecutionCase = value
    },

    setSidebarType(value: SideBarTypes) {
        this.sidebarType = value
    },

    toggleShowcolumn(columnName: string) {
        const showColumnsCopy = { ...this.showColumns }
        showColumnsCopy[columnName as keyof IShowColumns] = !showColumnsCopy[columnName as keyof IShowColumns]
        this.showColumns = showColumnsCopy
    },

    setShowColumns(columns: IShowColumns) {
        this.showColumns = columns
    },

    setColumnsOrder(newColumnsOrder: string[]) {
        this.columnsOrder = newColumnsOrder
    },

    setAttachments(value: IAttachment[]) {
        this.attachments = value
    },

    setFilesToUpload(value: IFileToUpload[]) {
        this.filesToUpload = value
    },

    setFilesToDelete(value: IAttachment[]) {
        this.filesToDelete = value
    },

    setUploadingAttachment(value: boolean) {
        this.uploadingAttachment = value
    },

    async bindAttachments(files: IBindAttachment[]) {
        const attachments: IObjectAttachments = { objectAttachments: [] }
        files.forEach((file) => {
            const attachment = {
                existingAttachmentId: file.id,
                existingObjectId: file.existingObjectId,
                entityType: file.entityType,
                attachmentType: file.attachmentType,
            } as IObjectAttachment
            attachments.objectAttachments.push(attachment as any)
        })

        if (attachments.objectAttachments.length) {
            const entityType = attachments.objectAttachments[0].entityType
            if (entityType === EEntityType.EXECUTION_STEP || entityType === EEntityType.EXECUTION_CASE) {
                await MsExecutorApi.bindAttachments(attachments)
            } else {
                await MsProjectApi.bindAttachments(attachments)
            }
        }
    },

    async updateAttachmentList(files: IAttachment[]) {
        const entityType = files[0].entityType
        if (entityType === EEntityType.EXECUTION_STEP || entityType === EEntityType.EXECUTION_CASE) {
            const stepsIds = this.testCaseInfo.executionSteps?.map((step: IStep) => step.id) as string[]
            await this.getExeTestCaseAttachments([StoreTestCase.id, ...stepsIds])
        } else {
            const stepsIds = this.testCaseInfo.steps.map((step: IStep) => step.externalId)
            await this.getTestCaseAttachments([StoreTestCase.id, ...stepsIds])
        }
        StoreTestCase.setFilesToUpload([])
    },

    updateNewUploadingFilesStepId(steps: IStep[]) {
        const updatedFilesToUpload = this.filesToUpload.map((file) => {
            if (file.entityType === EEntityType.STEP && file.isNewStep) {
                const regexp = /\d+$/
                const fileOrderNum = +file.existingObjectId.match(regexp)![0]
                const newId = steps.find(
                    (step) => step.stepType === file.stepType && step.orderNum === fileOrderNum
                )?.externalId

                return {
                    ...file,
                    existingObjectId: newId,
                }
            } else {
                return {
                    ...file,
                }
            }
        }) as IFileToUpload[]

        this.setFilesToUpload(updatedFilesToUpload)
    },

    partUploadFiles() {
        const attachmentBatchMaxSizeByte = attachmentBatchMaxSizeMb * unitsOfInformation.BytesInMb
        const uploadingParts: IFileToUpload[][] = []
        let group: IFileToUpload[] = []
        this.filesToUpload.forEach((file) => {
            if (file.size) {
                const groupSum = group?.reduce((sum, gfile) => (sum += gfile?.size || 0), 0)
                if (groupSum + file.size < attachmentBatchMaxSizeByte) {
                    group.push(file)
                } else {
                    uploadingParts.push(group)
                    group = []
                    group.push(file)
                }
            }
        })

        if (group.length) {
            uploadingParts.push(group)
            group = []
        }

        return uploadingParts
    },

    async handleUploadList() {
        const totalUploadingSIzeMb =
            this.filesToUpload.reduce((sum, file) => (sum += file?.size || 0), 0) / unitsOfInformation.BytesInMb
        const shouldCreateParts = totalUploadingSIzeMb >= attachmentBatchMaxSizeMb

        if (shouldCreateParts) {
            const uploadingParts = this.partUploadFiles()
            const resp = await Promise.all(uploadingParts.map((part) => MsAttachmentApi.save(part)))
            const files: IAttachment[] = resp.map((part) => part.data).flat(1)
            return files
        } else {
            const files: IAttachment[] = (await MsAttachmentApi.save(this.filesToUpload)).data
            return files
        }
    },

    async uploadAttachments(testCaseId?: string, steps?: IStep[]) {
        steps?.length && this.updateNewUploadingFilesStepId(steps)

        try {
            const files = await this.handleUploadList()
            //are u sure that files from the backend will be sorted the same way?
            const filesWithTypes: IBindAttachment[] = files.map((file, index: number) => {
                return {
                    ...file,
                    existingObjectId: this.filesToUpload[index].existingObjectId || testCaseId || this.testCaseInfo.id,
                    attachmentType: this.filesToUpload[index].attachmentType,
                    entityType: this.filesToUpload[index].entityType,
                }
            })
            await this.bindAttachments(filesWithTypes)
            await this.updateAttachmentList(filesWithTypes)
        } catch (err) {
            console.log('err: ', err)
        }
    },

    async createTestCase() {
        this.setCreatingInProgress(true)
        StoreTestCase.setNormativeTime(formatToSeconds(this.normativeHours, this.normativeMinutes))
        const testCase = {
            id: StoreTestCase.id,
            testFolderId: StoreTestCase.testFolderId,
            name: StoreTestCase.name,
            description: StoreTestCase.description,
            priority: StoreTestCase.priority,
            steps: StoreTestCase.steps,
            normativeTime: StoreTestCase.normativeTime,
            workStatus: StoreTestCase.status,
            additionalLinks: { additionalLinks: StoreTestCase.additionalLinks },
        }
        SpinnerStore.setShow(true)
        const newTestCase: ITestCase = await MsProjectApi.saveAndGetItem(testCase as ITestCase, apiUrls.testCase)

        SpinnerStore.setShow(false)
        this.setTestCaseInfo(newTestCase)
        this.setTestCaseSteps(newTestCase.steps)
        await this.handleUploadAttachments(newTestCase.id, newTestCase.steps)

        StoreTestCase.seInitialNormativeHours(StoreTestCase.normativeHours)
        StoreTestCase.seInitialNormativeMinutes(StoreTestCase.normativeMinutes)
        this.setCreatingInProgress(false)

        PreferencesStore.t('notification:success.testCaseCreated', { name: testCase.name }).then((message) => {
            showMessage('success', message)
        })

        return newTestCase.id
    },

    async copyTestCase(testCase?: ITestCase) {
        SpinnerStore.setShow(true)

        const targetFolderId = testCase ? testCase.testFolderId : this.testFolderId
        const testEntitiesIds = testCase ? [testCase.id] : [this.id]
        const testCaseName = testCase ? testCase.name : this.name

        const props = {
            targetFolderId: targetFolderId,
            testEntitiesIds: testEntitiesIds,
        }

        const testCasesCopy = await MsProjectApi.copyTestCases(props)
        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.testCaseCopy', { name: testCaseName }).then((message) => {
            showMessage('success', message)
        })

        return testCasesCopy
    },

    async moveTestCases(selectedTestCaseIds: string[], targetFolderId: string) {
        SpinnerStore.setShow(true)

        await MsProjectApi.moveTestCases({
            targetFolderId,
            testEntitiesIds: selectedTestCaseIds,
        })

        const message = await PreferencesStore.t(
            selectedTestCaseIds.length === 1
                ? 'notification:success.testCaseMoved'
                : 'notification:success.testCasesMoved',
            {}
        )

        SpinnerStore.setShow(false)
        showMessage('success', message)
    },

    async unArchiveTestCases(selectedTestCaseIds: string[], targetFolderId: string, navigateTo?: any) {
        SpinnerStore.setShow(true)

        const props = {
            targetFolderId: targetFolderId,
            testEntitiesIds: selectedTestCaseIds,
        }

        await MsProjectApi.unarchiveTestCase(props)
        SpinnerStore.setShow(false)

        const message = await PreferencesStore.t(
            selectedTestCaseIds.length === 1
                ? 'notification:success.testCaseRestored'
                : 'notification:success.testCasesRestored',
            {}
        )
        showMessage('success', message, () => navigateTo(selectedTestCaseIds[0]))
    },

    async blockHeartbeat(testCaseId: string) {
        await MsProjectApi.blockHeartbeat(testCaseId)
    },

    async startHeartBeating(testCaseId: string) {
        await this.blockHeartbeat(testCaseId)
        this.setBlockHeartbeatTimerId(setInterval(() => this.blockHeartbeat(testCaseId), heartBeatInterval))
    },

    async requestBlock(testCaseId: string) {
        await MsProjectApi.requestBlock(testCaseId)
        this.startHeartBeating(testCaseId)
        this.blocked = true
    },

    async disableBlock(testCaseId: string) {
        await MsProjectApi.disableBlock(testCaseId)
        this.blocked = false
        clearInterval(this.blockHeartbeatTimerId)
        this.setBlockHeartbeatTimerId({} as ReturnType<typeof setInterval>)
    },

    async getBlockInfo(testCaseId: string) {
        const blockInfo: ITestCaseBlockInfo | null = await MsProjectApi.getBlockInfo(testCaseId)
        return blockInfo
    },

    async handleUploadAttachments(testCaseId?: string, steps?: IStep[]) {
        if (this.filesToUpload.length && !this.uploadingAttachment) {
            this.setUploadingAttachment(true)
            await this.uploadAttachments(testCaseId, steps)
            this.setUploadingAttachment(false)
        }
    },

    async handleDeleteAttachments() {
        if (StoreTestCase.filesToDelete.length) {
            const filebindIds = StoreTestCase.filesToDelete
                .map((attachment) => attachment.bindId && attachment.bindId)
                .filter((id) => Boolean(id)) as string[]
            await MsProjectApi.deleteMultipleAttachments(filebindIds)
            StoreTestCase.setFilesToDelete([])
        }
    },

    //TODO: несколько громоздкая логика, лучше сделать типа как в handleDeleteUploadAttachments с использованием changedStepIndexMap, однако идея пришла позже + перемещение там как-то не так происходит
    //Перемещение файлов для загрузки в шагах с временным id
    handleMoveAttachments(fromOrderNum: number, toNewOrderNum: number, stepsLength: number, stepType: EStepType) {
        if (StoreTestCase.filesToUpload.length) {
            const filesToUploadCopy = [...StoreTestCase.filesToUpload]

            //файлы которые не двигаются из других типов шагов - добавить их без изменений
            const otherStepTypeFilesToUpload = filesToUploadCopy.filter((file) => file.stepType !== stepType)

            //файлы загружаемые в уже существующие шаги текущего типа с id - добавить их без изменений
            const uploadedFilesInCurrent = filesToUploadCopy.filter(
                (file) => file.stepType === stepType && !file.isNewStep
            )

            //получение индексов перемещенных файлов
            const movedStepsIndex: number[] = []
            const highNum = Math.max(fromOrderNum, Math.min(stepsLength, toNewOrderNum))
            const lowNum = Math.min(fromOrderNum, toNewOrderNum)
            for (let i = lowNum; i <= highNum; i++) {
                movedStepsIndex.push(i)
            }

            //получение индексов неизмененных файлов из текущего типа шага
            const arrayRange = (start: number, stop: number, step: number) =>
                Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step)
            const allStepsIndex = arrayRange(1, stepsLength, 1)
            const notMovedStepsIndex = allStepsIndex.filter((n) => !movedStepsIndex.includes(n))

            //получение списка неперемещенных файлов в несохраненном (с временным id) шаге текущего шага
            const notChangedCurrentStepTypeFiles: IFileToUpload[] = []
            notMovedStepsIndex.forEach((orderNum) => {
                const regexNotChangedFiles = new RegExp(stepType + '_' + '[A-Za-z]+' + '_' + orderNum + '+$')
                const foundedNotChangedFiles = filesToUploadCopy.filter((file) =>
                    regexNotChangedFiles.test(file.existingObjectId)
                )

                if (foundedNotChangedFiles) {
                    notChangedCurrentStepTypeFiles.push(...foundedNotChangedFiles)
                }
            })

            //обновление айди у перемещенных файлов в несохраненном (с временным id) шаге из текущего типа шага
            const updatedCurrentStepTypeFileList: IFileToUpload[] = []
            movedStepsIndex.forEach((stepIndex) => {
                //если текущий индекс является пассивно перемещаемым (на него не кликали)
                if (stepIndex !== fromOrderNum) {
                    const newOrderNum = fromOrderNum < stepIndex ? stepIndex - 1 : stepIndex + 1
                    const regexMoveFrom = new RegExp(stepType + '_' + '[A-Za-z]+' + '_' + stepIndex + '+$')
                    const moveToRowAttachments = filesToUploadCopy.filter((file) =>
                        regexMoveFrom.test(file.existingObjectId)
                    )
                    //если есть файлы для этого индекса, то добавить в список измененых текущих
                    if (moveToRowAttachments) {
                        moveToRowAttachments?.forEach((file) => {
                            const regexp = new RegExp(stepIndex + '+$')
                            const newExistingObjectId = file.existingObjectId.replace(regexp, `${newOrderNum}`)
                            updatedCurrentStepTypeFileList.push({ ...file, existingObjectId: newExistingObjectId })
                        })
                    }
                } else {
                    //если текущий индекс является активным рядом (напрямую указан номер ряда или был клик по стрелкам)
                    const regexMoving = new RegExp(stepType + '_' + '[A-Za-z]+' + '_' + stepIndex + '+$')
                    const movingRowAttachments = [
                        ...filesToUploadCopy.filter((file) => regexMoving.test(file.existingObjectId)),
                    ]

                    //если найден добавить в список измененых текущих
                    if (movingRowAttachments) {
                        const moveToIndex = Math.min(toNewOrderNum, stepsLength) //может быть что в toNewOrderNum указано 5, но по факту там 4 строки
                        movingRowAttachments?.forEach((file) => {
                            const regexp = new RegExp(stepIndex + '+$')
                            const newExistingObjectId = file.existingObjectId.replace(regexp, String(moveToIndex))
                            updatedCurrentStepTypeFileList.push({ ...file, existingObjectId: newExistingObjectId })
                        })
                    }
                }
            })

            //сведение файлов для загрузки: шагов другого типа, не измененных в несохраненном (с временным id) шаге, измененных в несохраненном  (с временным id) шаге, неизмененных в сохраненном  (с постоянным id) шаге
            const updatedFilesToUpload = [
                ...otherStepTypeFilesToUpload,
                ...notChangedCurrentStepTypeFiles,
                ...updatedCurrentStepTypeFileList,
                ...uploadedFilesInCurrent,
            ]
            StoreTestCase.setFilesToUpload(updatedFilesToUpload)
        }
    },

    //удаление вложений для зарузки при удалении шага
    handleDeleteUploadAttachmentsFromStep(deletedRows: Key[], stepType: EStepType) {
        const filesToUploadCopy = [...StoreTestCase.filesToUpload]
        const filesToDelete: IFileToUpload[] = []
        deletedRows.forEach((rowIndex) => {
            const regexFilesToDelete = new RegExp(stepType + '_' + '[A-Za-z]+' + '_' + rowIndex + '+$')
            filesToDelete.push(...filesToUploadCopy.filter((file) => regexFilesToDelete.test(file.existingObjectId)))
        })
        const updatedFilesToUpload = filesToUploadCopy.filter(
            (file) => !filesToDelete.some((deletedFile) => deletedFile.existingObjectId === file.existingObjectId)
        )
        return updatedFilesToUpload
    },

    //Перемещение файлов для загрузки в шагах с временным id в случае удаления
    handleMoveAttachmentsOnDelete(
        deletedRows: Key[],
        steps: StepType[],
        newSteps: StepType[],
        updatedStepsWithUpdatedNumeration: StepType[]
    ) {
        if (StoreTestCase.filesToUpload.length) {
            const stepType = steps[0].stepType
            //сначала убрать удаленные
            const filesToUpload = this.handleDeleteUploadAttachmentsFromStep(deletedRows, stepType)

            //если что-то осталось, то затем перемещение вложений для загрузки
            if (filesToUpload.length) {
                //список шагов с временным id с измененной после удаления (а с ним и изменения нумерацией) id
                const changedStepIndexMap = new Map()
                newSteps.forEach((oldStep, index) => {
                    const isNewStep = !('createdDate' in oldStep && oldStep?.createdDate)
                    if (isNewStep && oldStep.orderNum !== updatedStepsWithUpdatedNumeration[index].orderNum) {
                        changedStepIndexMap.set(oldStep.orderNum, updatedStepsWithUpdatedNumeration[index].orderNum)
                    }
                })

                const regexFilesToMove = /(\w+_)(\d+)+$/
                const updatedFilesToUpload = filesToUpload?.map((file) => {
                    if (file.stepType === stepType) {
                        const fileIndex = file.existingObjectId.match(regexFilesToMove)
                        const isFileMoved = fileIndex && !!changedStepIndexMap.get(+fileIndex[2])

                        //если файл надо переместить, то поменять его индекс
                        if (isFileMoved) {
                            const updatedExistingObjectId = file.existingObjectId.replace(
                                regexFilesToMove,
                                (match, name, idIndex) => `${name}${changedStepIndexMap.get(+idIndex)}`
                            )
                            return { ...file, existingObjectId: updatedExistingObjectId }
                        }
                        //если текущий тип шага, но файл остался на месте, то оставить без изменений
                        else {
                            return file
                        }
                        //если другой тип шага, то оставить без изменений
                    } else {
                        return file
                    }
                })

                StoreTestCase.setFilesToUpload(updatedFilesToUpload)
            }
        }
    },

    async updateTestCase() {
        StoreTestCase.setNormativeTime(formatToSeconds(this.normativeHours, this.normativeMinutes))
        const testCase = {
            id: StoreTestCase.id,
            testFolderId: StoreTestCase.testFolderId,
            name: StoreTestCase.name,
            description: StoreTestCase.description,
            orderNum: StoreTestCase.orderNum,
            priority: StoreTestCase.priority,
            steps: StoreTestCase.steps,
            normativeTime: StoreTestCase.normativeTime,
            workStatus: StoreTestCase.status,
            additionalLinks: { additionalLinks: StoreTestCase.additionalLinks },
        }

        SpinnerStore.setShow(true)

        StoreTestCase.seInitialNormativeHours(StoreTestCase.normativeHours)
        StoreTestCase.seInitialNormativeMinutes(StoreTestCase.normativeMinutes)
        await this.handleDeleteAttachments()

        const updatedTestCase: ITestCase = await MsProjectApi.updateAndGetItem(testCase as ITestCase, apiUrls.testCase)
        this.setTestCaseInfo(updatedTestCase)
        this.setTestCaseSteps(updatedTestCase.steps)

        await this.handleUploadAttachments(updatedTestCase.id, updatedTestCase.steps)

        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.testCaseUpdated', { name: testCase.name }).then((message) => {
            showMessage('success', message)
        })
    },

    async getAttachmentMetaData(attachments: IObjectAttachment[]) {
        const attachmentsIds = attachments.map((attachment) => attachment.existingAttachmentId)
        const attachmentsWithData = await MsAttachmentApi.getAttachmentsData(attachmentsIds)

        const formattedAttachmentsWithData = attachmentsWithData.map((attachmentWithMeta: IAttachment) => {
            const attachmentWithAdditionalData = attachments.find(
                (attachment) => attachment.existingAttachmentId === attachmentWithMeta.id
            )!

            const additionalAttachmentData = {
                bindId: attachmentWithAdditionalData.id,
                existingAttachmentId: attachmentWithAdditionalData.existingAttachmentId,
                existingObjectId: attachmentWithAdditionalData.existingObjectId,
                attachmentType: attachmentWithAdditionalData.attachmentType,
                entityType: attachmentWithAdditionalData.entityType,
            }

            return {
                ...attachmentWithMeta,
                ...additionalAttachmentData,
            }
        })

        return formattedAttachmentsWithData
    },

    async getAttachmentsMetaData(attachments: IObjectAttachment[]) {
        if (attachments.length) {
            return await this.getAttachmentMetaData(attachments)
        } else return []
    },

    async getTestCaseAttachments(ids: string[]) {
        SpinnerStore.setShow(true)

        const attachmentfilter = {
            filter: {
                isDeleted: false,
                existingObjectId: ids,
            } as IObjectFilter,
        }

        const attachments: IObjectAttachment[] = await MsProjectApi.getAttachments(attachmentfilter)
        if (attachments.length) {
            const attachmentsWithMetaData = await this.getAttachmentMetaData(attachments)
            StoreTestCase.setAttachments(attachmentsWithMetaData)
        }

        SpinnerStore.setShow(false)
    },

    async getOriginTestCaseAttachments(ids: string[], revisionNum: number) {
        const attachmentfilter = {
            filter: {
                existingObjectId: ids,
                revisionNum: revisionNum,
            } as IObjectFilter,
        }
        const attachments: IObjectAttachment[] = await MsProjectApi.getAttachments(attachmentfilter)
        return await this.getAttachmentsMetaData(attachments)
    },

    async getExeTestCaseAttachments(ids: string[], originTestCaseIds?: string[], revisionNum?: number) {
        SpinnerStore.setShow(true)

        const originTestCaseAttachmentsWithMetaData: any[] =
            originTestCaseIds && revisionNum
                ? await this.getOriginTestCaseAttachments(originTestCaseIds, revisionNum)
                : []

        const attachmentfilter = {
            filter: {
                existingObjectId: ids,
            } as IObjectFilter,
        }

        const attachments: IObjectAttachment[] = await MsExecutorApi.getExeAttachments(attachmentfilter)
        const exeAttachmentsWithMetaData = await this.getAttachmentsMetaData(attachments)

        const allAttachments = [...exeAttachmentsWithMetaData, ...originTestCaseAttachmentsWithMetaData]
        StoreTestCase.setAttachments(allAttachments)

        SpinnerStore.setShow(false)
    },

    async getTestCase(testCaseId: string) {
        SpinnerStore.setShow(true)
        const testCaseInfo: ITestCase = await MsProjectApi.getItem(testCaseId, apiUrls.testCase)
        const stepsId = testCaseInfo.steps.map((step) => step.externalId)
        await this.getTestCaseAttachments([testCaseId, ...stepsId])
        SpinnerStore.setShow(false)

        return testCaseInfo
    },

    async exportTestCases(testCases: IExportItems) {
        if (testCases.ids) {
            SpinnerStore.setShow(true)
            await MsProjectApi.exportItems(testCases, apiUrls.testCase)
            SpinnerStore.setShow(false)
        }
    },

    async deleteTestCase(id: string, name: string) {
        SpinnerStore.setShow(true)
        await MsProjectApi.deleteItem(id, apiUrls.testCase)
        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.testCaseDeleted', { name: name }).then((message) => {
            showMessage('success', message)
        })
    },

    async deleteTestCases(testCases: Key[]) {
        SpinnerStore.setShow(true)
        await MsProjectApi.deleteTestCases(testCases)
        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.allTestCasesDeleted', {}).then((message) => {
            showMessage('success', message)
        })
    },

    async getTestCaseFull(id: string, revision: number) {
        SpinnerStore.setShow(true)
        const testCaseInfo = await MsProjectApi.getTestCaseFull(id, revision, apiUrls.testCase)
        !StoreProject.id && StoreProject.setProjectId(testCaseInfo.projectId)
        !this.projectId && this.setProjectId(testCaseInfo.projectId)
        SpinnerStore.setShow(false)

        return testCaseInfo
    },

    setTestCaseSteps(value: IStep[]) {
        this.steps = value
    },

    updateTestCaseInfo(value: IExecutionCase | ITestCase) {
        this.testCaseInfo = value
    },

    setExecutionSteps(value: IExecutionStep[]) {
        this.testCaseInfo.executionSteps = value
    },

    setExecutionCaseStatus(value: EExecutionCaseStatus) {
        this.executionCaseStatus = value
    },

    setTestCasesInSet(value: any) {
        this.testCasesInSet = value
    },

    fillExecutionSteps() {
        const updatedSteps = this.testCaseInfo?.executionSteps?.map((step: IExecutionStep) => {
            const exeCaseData: IExecutionStep = this.testCaseInfo.executionSteps?.find(
                (exeStep: IExecutionStep) => step.stepId === exeStep.stepId
            )

            return {
                ...step,
                id: exeCaseData?.id,
                executionCaseId: this.id,
                started: this.executionCaseStartedTime,
                completed: this.executionCaseCompletedTime,
            }
        })
        this.setExecutionSteps(updatedSteps)
    },

    updateExecutionTestCase() {
        const testCaseInfoCopy = JSON.parse(JSON.stringify(this.testCaseInfo)) as IExecutionCase
        testCaseInfoCopy.executionCaseStatus = this.executionCaseStatus
        testCaseInfoCopy.started = this.executionCaseStartedTime
        testCaseInfoCopy.completed = this.executionCaseCompletedTime
        this.updateTestCaseInfo(testCaseInfoCopy)
        return testCaseInfoCopy
    },

    async saveExecutionTestCase() {
        runInAction(async () => {
            this.fillExecutionSteps()
            await StoreTestSet.setExecutionSteps(this.testCaseInfo.executionSteps)
            await StoreTestSet.finishTestCase(this.updateExecutionTestCase())
            await this.handleUploadAttachments()
            this.setExecutionCaseCompleted(true)
            this.setIsSaved(true)
            this.setRequiredStepChanged(false)
        })
    },

    async getJiraIssues(projectId: string, ids: string[]) {
        SpinnerStore.setShow(true)
        const jiraIssues: IJiraIssue[] = (await MsExecutorApi.getJiraIssues(projectId, ids)).data
        SpinnerStore.setShow(false)
        return jiraIssues
    },

    async getJiraIntegrationAvailability(projectId: string): Promise<boolean> {
        SpinnerStore.setShow(true)
        try {
            const availability = (await MsExecutorApi.getJiraIntegrationAvailability(projectId)).data
            StoreProject.setJiraIntegrationAvailability(availability)
            return availability
        } catch (err) {
            return false
        } finally {
            SpinnerStore.setShow(false)
        }
    },

    setInitialCompleteWithError(value: EExecutionCaseStatus) {
        this.initialCompleteWithError = value === EExecutionCaseStatus.BLOCKED || value === EExecutionCaseStatus.FAILED
    },

    setIsCompleteWithError() {
        this.completeWithError =
            this.executionCaseStatus === EExecutionCaseStatus.BLOCKED ||
            this.executionCaseStatus === EExecutionCaseStatus.FAILED
    },

    resetTestCaseData() {
        this.id = ''
        this.name = ''
        this.description = ''
        this.priority = EPriority.MEDIUM
        this.status = EWorkStatus.DRAFT
        this.additionalLinks = []
        this.normativeTime = 0
        this.normativeHours = 0
        this.normativeMinutes = 0
        this.initialNormativeHours = 0
        this.initialNormativeMinutes = 0
        this.orderNum = 0
        this.users = []
        this.steps = []
        this.isSaved = true
        this.requiredStepChanged = false
        this.navigateToNewTestCase = false
        this.readOnly = false
        this.executionTestCase = false
        this.cancelChanges = false
        this.executionCaseStarted = false
        this.executionCaseStatus = EExecutionCaseStatus.NOT_PASSED
        this.createdBy = {} as IUserCommonInfo & IUser
        this.testSetId = ''
        this.executionCaseStartedTime = 0
        this.executionCaseCompletedTime = 0
        this.topRequiredStep = {
            STEP_PRECONDITION: 0,
            DEFAULT_STEP: 0,
            STEP_POSTCONDITION: 0,
        }
        this.executionCaseCompleted = false
        this.testCaseInfo = {} as ITestCase
        this.durationExecutionCase = ''
        this.sidebarType = 'settings'
        this.completeWithError = false
        this.initialCompleteWithError = false
        this.attachments = []
        this.filesToUpload = []
        this.filesToDelete = []
        this.uploadingAttachment = false
        this.creatingInProgress = false
        this.toggleResetSearchTestCase = false
        this.jiraIntegrationAvailability = false
        this.blocked = false
        this.blockInfo = null
        this.isProjectAdmin = false
        StoreBlocker.setBlocker(null)
    },
})
