import React from 'react'

import cn from 'classnames'

import { Avatar } from 'antd'

import styles from './UserAvatar.module.scss'

type Props = {
    userName: string
    avatarId?: string | null | undefined
    size?: number
    manageAvatar?: boolean
}
export const UserAvatar = (props: Props) => {
    const { userName, avatarId, size, manageAvatar } = props
    return avatarId ? (
        <Avatar
            className={cn(styles.AvatarImg, { [styles.ManageAvatar]: manageAvatar })}
            size={size}
            src={`blob:http://localhost:8080/${avatarId}`}
        />
    ) : (
        <Avatar className={cn(styles.AvatarText)} size={size}>
            {userName[0]?.toUpperCase()}
        </Avatar>
    )
}
