import React from 'react'

import { useTranslation } from 'react-i18next'

import { Tooltip } from 'antd' // Импортируем компонент Tooltip из Ant Design

import { InfoCircleOutlined } from '@ant-design/icons'

import { testadoDocumentationUrl } from '@/consts'

const DownloadDocumentation = () => {
    const { t } = useTranslation('common')

    return (
        <Tooltip title={t('common:help')}>
            <a href={testadoDocumentationUrl}>
                <InfoCircleOutlined style={{ color: 'white' }} />
            </a>
        </Tooltip>
    )
}

export default DownloadDocumentation
