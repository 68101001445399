// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yC3QhzJ9kLHQCxIThnfQ .zR4MxJxMhlJ5IlrzVrvX{align-items:baseline;display:flex;padding-left:32px;margin-bottom:24px;gap:16px}.yC3QhzJ9kLHQCxIThnfQ .isNjr1Cbfn_23BopLF4F{padding-left:2px}.yC3QhzJ9kLHQCxIThnfQ .isNjr1Cbfn_23BopLF4F .ant-tabs-nav{min-width:256px}.yC3QhzJ9kLHQCxIThnfQ .isNjr1Cbfn_23BopLF4F .ant-tabs-nav .ant-tabs-tab{margin-top:0;padding-left:32px}.yC3QhzJ9kLHQCxIThnfQ .isNjr1Cbfn_23BopLF4F .ant-tabs-nav .ant-tabs-tab:hover{color:var(--color-primary);background:var(--color-primary1)}.yC3QhzJ9kLHQCxIThnfQ .isNjr1Cbfn_23BopLF4F .ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane{padding-left:40px}", "",{"version":3,"sources":["webpack://./src/pages/WorkspaceSettings/WorkspaceSettings.module.scss"],"names":[],"mappings":"AACI,4CACI,oBAAA,CACA,YAAA,CACA,iBAAA,CACA,kBAAA,CACA,QAAA,CAGJ,4CACI,gBAAA,CAEI,0DACI,eAAA,CAGJ,wEACI,YAAA,CACA,iBAAA,CAGJ,8EACI,0BAAA,CACA,gCAAA,CAGJ,yGACI,iBAAA","sourcesContent":[".WorkspaceSettings {\n    .Title {\n        align-items: baseline;\n        display: flex;\n        padding-left: 32px;\n        margin-bottom: 24px;\n        gap: 16px;\n    }\n\n    .Menu {\n        padding-left: 2px;\n        :global {\n            .ant-tabs-nav {\n                min-width: 256px;\n            }\n\n            .ant-tabs-nav .ant-tabs-tab {\n                margin-top: 0;\n                padding-left: 32px;\n            }\n\n            .ant-tabs-nav .ant-tabs-tab:hover {\n                color: var(--color-primary);\n                background: var(--color-primary1);\n            }\n\n            .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {\n                padding-left: 40px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"WorkspaceSettings": "yC3QhzJ9kLHQCxIThnfQ",
	"Title": "zR4MxJxMhlJ5IlrzVrvX",
	"Menu": "isNjr1Cbfn_23BopLF4F"
};
export default ___CSS_LOADER_EXPORT___;
