import React, { CSSProperties } from 'react'

import cn from 'classnames'
import Highlighter from 'react-highlight-words'

import { Typography } from 'antd'
import { UserAvatar } from '../Avatar/UserAvatar'

import { CrownFilled } from '@ant-design/icons'

import { userNameSizeBigRatio, userNameSizeSmallRatio } from '@/consts'

import styles from './UserCard.module.scss'

const { Paragraph } = Typography

type Props = {
    userName: string
    avatar?: string | null | undefined
    position?: string | null
    userLogin?: string
    size: number
    userNameStyle?: CSSProperties
    positionStyle?: CSSProperties
    userLoginStyle?: CSSProperties
    userTextInfoClassName?: string
    bordered?: boolean
    crowned?: boolean
    searchValue?: string
    firstNameSearchValue?: string
    lastNameSearchValue?: string
    userFirstName?: string | undefined | null
    userLastName?: string | undefined | null
    isGlobalSearchResults?: boolean
}
type SearchType = 'notAdvanced' | 'firstName' | 'lastName' | 'fullName' | 'default'

/*
    TODO remove the numeric prop size and associated calculations, make it a string with a type 'small' | 'middle' | 'large', and use like
    const CARD_SIZE_MAP = [
        { key: 'small', className: styles.SmallUserCard, avatarSize: number },
        ...
    ]
*/
export const UserCard = (props: Props) => {
    const {
        userName,
        avatar,
        position,
        size = 32,
        userTextInfoClassName,
        bordered,
        crowned,
        userLogin,
        userNameStyle,
        positionStyle,
        userLoginStyle,
        searchValue,
        firstNameSearchValue,
        lastNameSearchValue,
        userFirstName,
        userLastName,
        isGlobalSearchResults,
    } = props

    const globalSearchResultsStyle = {
        fontSize: size / userNameSizeBigRatio,
    }

    const globalSearchResultsWithPositionStyle = {
        fontSize: size / userNameSizeSmallRatio,
    }
    const highlightStyle = isGlobalSearchResults
        ? position
            ? globalSearchResultsWithPositionStyle
            : globalSearchResultsStyle
        : undefined

    const searchValueHighlightedElement = (
        <Highlighter highlightClassName="highlight" searchWords={[searchValue]} autoEscape textToHighlight={userName} />
    )

    const firstNameSearchValueHighlightedElement = (
        <>
            <Highlighter
                highlightClassName="highlight"
                searchWords={[firstNameSearchValue]}
                autoEscape
                textToHighlight={userFirstName}
                highlightStyle={highlightStyle}
                unhighlightStyle={highlightStyle}
            />{' '}
            {userLastName}
        </>
    )

    const lastNameSearchValueHighlightedElement = (
        <>
            {userFirstName}{' '}
            <Highlighter
                highlightClassName="highlight"
                searchWords={[lastNameSearchValue]}
                autoEscape
                textToHighlight={userLastName}
                highlightStyle={highlightStyle}
                unhighlightStyle={highlightStyle}
            />
        </>
    )
    const lastNameAndFirstNameSearchValueHighlightedElement = (
        <>
            <Highlighter
                highlightClassName="highlight"
                searchWords={[firstNameSearchValue]}
                autoEscape
                textToHighlight={userFirstName}
                highlightStyle={highlightStyle}
                unhighlightStyle={highlightStyle}
            />{' '}
            <Highlighter
                highlightClassName="highlight"
                searchWords={[lastNameSearchValue]}
                autoEscape
                textToHighlight={userLastName}
                highlightStyle={highlightStyle}
                unhighlightStyle={highlightStyle}
            />
        </>
    )
    const getUserNameToRender = () => {
        const determineSearchType = (): SearchType => {
            if (searchValue) {
                return 'notAdvanced'
            } else if (firstNameSearchValue && lastNameSearchValue) {
                return 'fullName'
            } else if (firstNameSearchValue) {
                return 'firstName'
            } else if (lastNameSearchValue) {
                return 'lastName'
            } else {
                return 'default'
            }
        }

        const searchType = determineSearchType()

        switch (searchType) {
            case 'notAdvanced':
                return searchValueHighlightedElement
            case 'fullName':
                return lastNameAndFirstNameSearchValueHighlightedElement
            case 'firstName':
                return firstNameSearchValueHighlightedElement
            case 'lastName':
                return lastNameSearchValueHighlightedElement
            default:
                return userName
        }
    }

    return (
        <div className={styles.User}>
            <div className={cn(styles.UserCard, { [styles.Bordered]: bordered })}>
                {crowned ? (
                    <div className={styles.CrownedAvatar}>
                        <UserAvatar userName={userName} avatarId={avatar} size={size} />
                        <CrownFilled
                            className={styles.Crown}
                            style={{
                                width: size / 2,
                                height: size / 2,
                                borderRadius: size * 0.3,
                                left: size * 0.6,
                                top: size * 0.6,
                            }}
                        />
                    </div>
                ) : (
                    <UserAvatar userName={userName} avatarId={avatar} size={size} />
                )}

                <div className={cn(styles.UserTextInfo, { [styles.UserTextInfoClassName]: userTextInfoClassName })}>
                    <Paragraph
                        ellipsis={{
                            expandable: false,
                            tooltip: userName,
                        }}
                        className={styles.UserName}
                        style={
                            userNameStyle || {
                                fontSize: size / (position ? userNameSizeSmallRatio : userNameSizeBigRatio),
                            }
                        }
                    >
                        {getUserNameToRender()}
                    </Paragraph>
                    {position && (
                        <Paragraph
                            ellipsis={{
                                expandable: false,
                                tooltip: position,
                            }}
                            className={styles.Position}
                            style={
                                positionStyle || {
                                    fontSize: size / 3,
                                }
                            }
                        >
                            {searchValue ? (
                                <Highlighter
                                    highlightClassName="highlight"
                                    searchWords={[searchValue]}
                                    autoEscape
                                    textToHighlight={position}
                                />
                            ) : (
                                position
                            )}
                        </Paragraph>
                    )}
                    {userLogin && (
                        <Paragraph
                            ellipsis={{
                                expandable: false,
                                tooltip: position,
                            }}
                            className={styles.UserLogin}
                            style={
                                userLoginStyle || {
                                    fontSize: size / 3,
                                }
                            }
                        >
                            {searchValue ? (
                                <Highlighter
                                    highlightClassName="highlight"
                                    searchWords={[searchValue]}
                                    autoEscape
                                    textToHighlight={userLogin}
                                />
                            ) : (
                                userLogin
                            )}
                        </Paragraph>
                    )}
                </div>
            </div>
        </div>
    )
}
