import React, { FC, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { prependHttps } from '@/utils'

import { Select } from 'antd'

import { DownOutlined } from '@ant-design/icons'

import { StoreTestCase } from '@/store'

import styles from './TestCaseLinks.module.scss'

const { Option } = Select

export const TestCaseLinks: FC = observer(() => {
    const { t } = useTranslation(['testCase'])
    const [isOpen, setOpen] = useState(false)

    const onLinkChange = (value: string[]) => {
        StoreTestCase.setTestCaseLinks(value)
        StoreTestCase.setIsSaved(false)
    }

    const onFocus = () => {
        !!StoreTestCase.additionalLinks.length && setOpen(true)
    }

    const onBlur = () => {
        setOpen(false)
    }

    return (
        <div className={styles.TestCaseLinks}>
            <Select
                value={StoreTestCase.additionalLinks}
                allowClear
                mode={'tags'}
                className={cn('underlinedInput', { disabledSelectTags: StoreTestCase.readOnly })}
                bordered={false}
                placeholder={t('testCase:links')}
                onChange={onLinkChange}
                optionLabelProp="label"
                optionFilterProp="name"
                onFocus={onFocus}
                onBlur={onBlur}
                open={isOpen}
                filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                disabled={StoreTestCase.readOnly}
                showSearch={StoreTestCase.readOnly ? false : true}
                suffixIcon={!StoreTestCase.readOnly ? <DownOutlined /> : null}
            >
                {StoreTestCase.additionalLinks?.map((link) => (
                    <Option
                        key={link}
                        value={link}
                        name={link}
                        label={
                            <a href={prependHttps(link)} target="_blank" rel="noreferrer">
                                {link}
                            </a>
                        }
                    >
                        {link}
                    </Option>
                ))}
            </Select>
        </div>
    )
})
