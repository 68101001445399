import React, { ReactElement, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Select, Typography } from 'antd'
import { Status } from '@/components/Status/Status'

import { StoreTestSet } from '@/store'

import { ITestSet } from '@/types'
import { EExecutionTestSetStatus } from '@/enums'

import styles from './Details.module.scss'

const { Text } = Typography

type Props = {
    testSetInfo: ITestSet
}

export const SelectStatus = observer((props: Props) => {
    const { testSetInfo } = props
    const { t } = useTranslation(['page--testSets'])

    const [status, setStatus] = useState<any>({
        value: testSetInfo.executionTestSetStatus!,
        label: <Status status={testSetInfo.executionTestSetStatus!} />,
    })
    const [statusOptions, setStatusOptions] = useState<any>([])

    useEffect(() => {
        switch (status.value) {
            case EExecutionTestSetStatus.PLANNING:
                setStatusOptions([
                    {
                        value: EExecutionTestSetStatus.CLOSED,
                        label: <Status status={EExecutionTestSetStatus.CLOSED} />,
                    },
                ])
                break
            case EExecutionTestSetStatus.ASSIGNED:
                setStatusOptions([
                    {
                        value: EExecutionTestSetStatus.IN_PROGRESS,
                        label: <Status status={EExecutionTestSetStatus.IN_PROGRESS} />,
                    },
                    {
                        value: EExecutionTestSetStatus.POSTPONED,
                        label: <Status status={EExecutionTestSetStatus.POSTPONED} />,
                    },
                    {
                        value: EExecutionTestSetStatus.CLOSED,
                        label: <Status status={EExecutionTestSetStatus.CLOSED} />,
                    },
                    {
                        value: EExecutionTestSetStatus.COMPLETED,
                        label: <Status status={EExecutionTestSetStatus.COMPLETED} />,
                    },
                ])
                break
            case EExecutionTestSetStatus.IN_PROGRESS:
                setStatusOptions([
                    {
                        value: EExecutionTestSetStatus.POSTPONED,
                        label: <Status status={EExecutionTestSetStatus.POSTPONED} />,
                    },
                    {
                        value: EExecutionTestSetStatus.CLOSED,
                        label: <Status status={EExecutionTestSetStatus.CLOSED} />,
                    },
                    {
                        value: EExecutionTestSetStatus.COMPLETED,
                        label: <Status status={EExecutionTestSetStatus.COMPLETED} />,
                    },
                ])
                break
            case EExecutionTestSetStatus.POSTPONED:
                setStatusOptions([
                    {
                        value: EExecutionTestSetStatus.IN_PROGRESS,
                        label: <Status status={EExecutionTestSetStatus.IN_PROGRESS} />,
                    },
                    {
                        value: EExecutionTestSetStatus.CLOSED,
                        label: <Status status={EExecutionTestSetStatus.CLOSED} />,
                    },
                    {
                        value: EExecutionTestSetStatus.COMPLETED,
                        label: <Status status={EExecutionTestSetStatus.COMPLETED} />,
                    },
                ])
                break
            case EExecutionTestSetStatus.CLOSED:
                setStatusOptions([
                    {
                        value: EExecutionTestSetStatus.IN_PROGRESS,
                        label: <Status status={EExecutionTestSetStatus.IN_PROGRESS} />,
                    },
                    {
                        value: EExecutionTestSetStatus.POSTPONED,
                        label: <Status status={EExecutionTestSetStatus.POSTPONED} />,
                    },
                    {
                        value: EExecutionTestSetStatus.COMPLETED,
                        label: <Status status={EExecutionTestSetStatus.COMPLETED} />,
                    },
                ])
                break
            case EExecutionTestSetStatus.COMPLETED:
                setStatusOptions([
                    {
                        value: EExecutionTestSetStatus.IN_PROGRESS,
                        label: <Status status={EExecutionTestSetStatus.IN_PROGRESS} />,
                    },
                    {
                        value: EExecutionTestSetStatus.POSTPONED,
                        label: <Status status={EExecutionTestSetStatus.POSTPONED} />,
                    },
                    {
                        value: EExecutionTestSetStatus.CLOSED,
                        label: <Status status={EExecutionTestSetStatus.CLOSED} />,
                    },
                ])
                break
        }
    }, [status])

    useEffect(() => {
        setStatus({
            value: testSetInfo.executionTestSetStatus!,
            label: <Status status={testSetInfo.executionTestSetStatus!} />,
        })
        StoreTestSet.setExecutionTestSetStatus(testSetInfo.executionTestSetStatus)
    }, [testSetInfo?.projectId])

    useEffect(() => {
        setStatus({
            value: testSetInfo.executionTestSetStatus!,
            label: <Status status={testSetInfo.executionTestSetStatus!} />,
        })
        StoreTestSet.setExecutionTestSetStatus(testSetInfo.executionTestSetStatus)
    }, [testSetInfo.executionTestSetStatus])

    const onSelectStatus = (
        value: EExecutionTestSetStatus,
        label: ReactElement<any, string> | ReactElement<any, string>[]
    ) => {
        setStatus({ value: value, label: label })
        StoreTestSet.setExecutionTestSetStatus(value)
        testSetInfo.id && StoreTestSet.changeTestSetStatus(testSetInfo.id, value)
    }

    return (
        <div className={styles.Info}>
            <Text className={styles.Label} type="secondary">
                {t('page--testSets:status')}
            </Text>
            {testSetInfo?.executionTestSetStatus && status && (
                <Select
                    defaultValue={status}
                    value={{
                        value: status.value,
                        label: <Status status={status.value} />,
                    }}
                    onChange={onSelectStatus}
                    options={statusOptions}
                    bordered={false}
                    suffixIcon={null}
                />
            )}
        </div>
    )
})
