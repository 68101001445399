// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sz0DJvqUCiN7zxBaiZPI span{text-transform:none !important}", "",{"version":3,"sources":["webpack://./src/pages/User/ChangePassword/ChangePassword.module.scss"],"names":[],"mappings":"AACI,2BACI,8BAAA","sourcesContent":[".ChangePasswordButton {\n    span {\n        text-transform: none !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ChangePasswordButton": "sz0DJvqUCiN7zxBaiZPI"
};
export default ___CSS_LOADER_EXPORT___;
