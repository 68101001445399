// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pSdIHKd1cvGGO5jagqBg{background:var(--color-itemBackgroundHover);padding-left:16px;padding-right:16px}.QgZxcaFdAl1lnjwulPow{margin-top:16px;margin-bottom:16px;background:none}.QgZxcaFdAl1lnjwulPow .ant-input-group-addon{background:none;border:0}.QgZxcaFdAl1lnjwulPow:hover{cursor:pointer}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Sider/TestCaseComments.module.scss"],"names":[],"mappings":"AAAA,sBACI,2CAAA,CACA,iBAAA,CACA,kBAAA,CAGJ,sBACI,eAAA,CACA,kBAAA,CACA,eAAA,CAGI,6CACI,eAAA,CACA,QAAA,CAKZ,4BACI,cAAA","sourcesContent":[".TestCaseComments {\n    background: var(--color-itemBackgroundHover);\n    padding-left: 16px;\n    padding-right: 16px;\n}\n\n.SendMessage {\n    margin-top: 16px;\n    margin-bottom: 16px;\n    background: none;\n\n    :global {\n        .ant-input-group-addon {\n            background: none;\n            border: 0;\n        }\n    }\n}\n\n.SendMessage:hover {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestCaseComments": "pSdIHKd1cvGGO5jagqBg",
	"SendMessage": "QgZxcaFdAl1lnjwulPow"
};
export default ___CSS_LOADER_EXPORT___;
