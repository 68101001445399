import React, { MouseEvent } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Button, Card, Tooltip, Typography } from 'antd'

import { ApartmentOutlined, InfoCircleOutlined, SettingOutlined, UserOutlined } from '@ant-design/icons'

import { ThemeStore } from '@/store'
import { pageUrls, themeUrls } from '@/consts'

import { IWorkspace } from '@/types'

import styles from './WorkspaceCard.module.scss'

const { Paragraph, Text } = Typography

type Props = {
    workspace: IWorkspace
}

export const WorkspaceCard = observer((props: Props) => {
    const { t } = useTranslation(['page--workspaces'])
    const { workspace } = props
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const SrcWorkspaceAvatar = `${themeUrls[ThemeStore.theme]}workspace-avatar.svg`

    const onGoToSettings = (e: MouseEvent, workspaceId: string) => {
        e.preventDefault()
        navigate(`${pageUrls.workspaceSettings}/${workspaceId}`, { state: { previousPath: pathname } })
    }

    return (
        <Link
            to={`${pageUrls.workspace}/${workspace.id}`}
            className={styles.WorkspaceLink}
            key={workspace.id}
            draggable="false"
        >
            <Card
                hoverable
                cover={
                    workspace?.logo ? (
                        <img src={workspace.logo.link} alt={`logo ${workspace.workspaceName}`} />
                    ) : (
                        <img src={SrcWorkspaceAvatar} alt="workspaceAvatar" />
                    )
                }
                extra={
                    <Tooltip overlayStyle={{ position: 'fixed' }} title={t('page--workspaces:workspaceSettings')}>
                        <Button
                            icon={<SettingOutlined />}
                            onClick={(e) => onGoToSettings(e, workspace.id)}
                            size="small"
                        />
                    </Tooltip>
                }
                className={styles.WorkspaceCard}
            >
                <div className={styles.WorkspaceInfo}>
                    <div className={styles.WorkspaceDescription}>
                        <Paragraph className={styles.Id}>ID {workspace.orderNum}</Paragraph>
                        <Paragraph className={styles.Title}>
                            <Text ellipsis={{ tooltip: workspace.workspaceName }}>{workspace.workspaceName}</Text>

                            {workspace.workspaceDescription && (
                                <Tooltip title={workspace.workspaceDescription} className={styles.Info}>
                                    <InfoCircleOutlined className="tooltipButton" />
                                </Tooltip>
                            )}
                        </Paragraph>
                    </div>
                    <div className={styles.Counters}>
                        <div className={styles.Counter}>
                            <span>
                                <UserOutlined />
                            </span>
                            <span>{workspace.workspaceUsersNumber}</span>
                        </div>

                        <div className={styles.Counter}>
                            <span>
                                <ApartmentOutlined />
                            </span>
                            <span>{workspace.projectCount}</span>
                        </div>
                    </div>
                </div>
            </Card>
        </Link>
    )
})
