// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rpAxpouVO8VBKrBzVH9z ._aurYDp03mBAX26FyP1M{margin-top:24px;margin-bottom:24px;display:flex;justify-content:space-between;align-items:center}.rpAxpouVO8VBKrBzVH9z ._aurYDp03mBAX26FyP1M .OcucWRMQ_lY8UDuhxSA4{display:inline-block;margin:0}.rpAxpouVO8VBKrBzVH9z .eqrmukq4Wbg5A4DQuLEX{display:flex;flex-wrap:wrap;gap:32px}", "",{"version":3,"sources":["webpack://./src/pages/Workspaces/Workspaces.module.scss"],"names":[],"mappings":"AACI,4CACI,eAAA,CACA,kBAAA,CAOA,YAAA,CACA,6BAAA,CACA,kBAAA,CAPA,kEACI,oBAAA,CACA,QAAA,CAQR,4CACI,YAAA,CACA,cAAA,CACA,QAAA","sourcesContent":[".Workspaces {\n    .Menu {\n        margin-top: 24px;\n        margin-bottom: 24px;\n\n        .Title {\n            display: inline-block;\n            margin: 0;\n        }\n\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n    }\n\n    .WorkspaceCards {\n        display: flex;\n        flex-wrap: wrap;\n        gap: 32px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Workspaces": "rpAxpouVO8VBKrBzVH9z",
	"Menu": "_aurYDp03mBAX26FyP1M",
	"Title": "OcucWRMQ_lY8UDuhxSA4",
	"WorkspaceCards": "eqrmukq4Wbg5A4DQuLEX"
};
export default ___CSS_LOADER_EXPORT___;
