import {
    FileExcelOutlined,
    FileGifOutlined,
    FileImageOutlined,
    FileJpgOutlined,
    FileOutlined,
    FilePdfOutlined,
    FileTextOutlined,
    FileWordOutlined,
    FileZipOutlined,
    PlayCircleOutlined,
} from '@ant-design/icons'

import { IMixedAttachments } from '@/types'

const FILE_EXTENSIONS_MAP = [
    { extensions: ['.xls', '.xlsb', '.xlsm', '.xlsx', '.xml'], icon: FileExcelOutlined },
    { extensions: ['.gif'], icon: FileGifOutlined },
    { extensions: ['.jpeg', '.jpg'], icon: FileJpgOutlined },
    { extensions: ['.jpe', '.bmp', '.dib', '.rle', '.png', '.svg', '.cdr', '.tiff'], icon: FileImageOutlined },
    { extensions: ['.pdf', '.ps,', '.eps,', '.prn', '.psd ', '.ai', '.indd'], icon: FilePdfOutlined },
    { extensions: ['.doc', '.docx'], icon: FileWordOutlined },
    { extensions: ['.txt', '.rtf', '.htm', '.html'], icon: FileTextOutlined },
    {
        extensions: [
            '.7z',
            '.ace',
            '.arj',
            '.cab',
            '.dgc',
            '.dmg',
            '.gca',
            '.j',
            '.jar',
            '.lzh',
            '.lha',
            '.lzx',
            '.rar',
            '.sea',
            '.sit',
            '.zip',
            '.zoo',
            '.a',
            '.ar',
            '.cpio',
            '.shar',
            '.tar',
        ],
        icon: FileZipOutlined,
    },
    {
        extensions: [
            '.webm',
            '.mkv',
            '.flv',
            '.vob',
            '.ogv',
            '.ogg',
            '.drc',
            '.gifv',
            '.mng',
            '.avi',
            '.MTS',
            '.M2TS',
            '.TS',
            '.mov',
            '.qt',
            '.wmv',
            '.yuv',
            '.rm',
            '.rmvb',
            '.viv',
            '.asf',
            '.amv',
            '.mp4',
            '.m4p',
            '.m4v',
            '.mpg',
            '.mp2',
            '.mpeg',
            '.mpe',
            '.mpv',
            '.mpg',
            '.mpeg',
            '.m2v',
            '.m4v',
            '.svi',
            '.3gp',
            '.3g2',
            '.mxf',
            '.roq',
            '.nsv',
            '.flv',
            '.f4v',
            '.f4p',
            '.f4a',
            '.f4b',
        ],
        icon: PlayCircleOutlined,
    },
]

export const getAttachmentIconComponent = (fileName: string) => {
    const regexp = /\.[^/.]+$/
    const fileExtension = fileName.match(regexp)![0].toLocaleLowerCase()

    const extensionConfig = FILE_EXTENSIONS_MAP.find(({ extensions }) => extensions.includes(fileExtension))

    if (!extensionConfig) {
        return FileOutlined
    }

    return extensionConfig.icon
}

export const getFullAttachmentName = (file: IMixedAttachments) => {
    if ('fileName' in file && 'extension' in file) {
        return `${file.fileName}.${file.extension}`
    }

    if ('name' in file) {
        return file.name
    }

    return file.id
}

export const getFileExtention = (file: IMixedAttachments) => {
    if ('extension' in file) {
        return `.${file.extension}`.toLocaleLowerCase()
    } else {
        if ('name' in file) {
            const regexp = /\.[^/.]+$/
            const fileExtension = file.name.match(regexp)![0].toLocaleLowerCase()
            return fileExtension
        }
    }
}

export const getAttachmentType = (file: IMixedAttachments) => {
    const attachmentTypes = new Map([
        ['image', ['.gif', '.jpeg', '.jpg', '.jpe', '.bmp', '.dib', '.rle', '.png', '.svg', '.cdr', '.tiff']],
        [
            'video',
            [
                '.webm',
                '.mkv',
                '.flv',
                '.vob',
                '.ogv',
                '.ogg',
                '.drc',
                '.gifv',
                '.mng',
                '.avi',
                '.mts',
                '.m2ts',
                '.ts',
                '.mov',
                '.qt',
                '.wmv',
                '.yuv',
                '.rm',
                '.rmvb',
                '.viv',
                '.asf',
                '.amv',
                '.mp4',
                '.m4p',
                '.m4v',
                '.mpg',
                '.mp2',
                '.mpeg',
                '.mpe',
                '.mpv',
                '.mpg',
                '.mpeg',
                '.m2v',
                '.m4v',
                '.svi',
                '.3gp',
                '.3g2',
                '.mxf',
                '.roq',
                '.nsv',
                '.flv',
                '.f4v',
                '.f4p',
                '.f4a',
                '.f4b',
            ],
        ],
    ])

    const fileExtension = getFileExtention(file)
    if (fileExtension) {
        for (const [key, value] of attachmentTypes.entries()) {
            if (value.includes(fileExtension)) {
                return key
            }
        }
    }
}
