// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PIX6Ro85DQei87wScEQo{display:flex;gap:12px}.PIX6Ro85DQei87wScEQo .qA3m_kIo5FVW0CraPTPA{display:flex;flex-direction:column;color:var(--color-primary);gap:4px}.PIX6Ro85DQei87wScEQo .qA3m_kIo5FVW0CraPTPA span svg{font-size:14px}.PIX6Ro85DQei87wScEQo .qA3m_kIo5FVW0CraPTPA span svg:hover{cursor:pointer;color:var(--color-primary-light)}.PIX6Ro85DQei87wScEQo ._yu488RnXB1jmQ1k65KN{display:flex;justify-content:space-between;width:100%;gap:8px}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Header/TestCaseColumnSettingsDropdownMenu/TestCaseColumnSettingsDropdownMenu.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,QAAA,CAEA,4CACI,YAAA,CACA,qBAAA,CACA,0BAAA,CACA,OAAA,CAEA,qDACI,cAAA,CAEA,2DACI,cAAA,CACA,gCAAA,CAKZ,4CACI,YAAA,CACA,6BAAA,CACA,UAAA,CACA,OAAA","sourcesContent":[".ColumnMoveDropdown {\n    display: flex;\n    gap: 12px;\n\n    .MoveColumnButtons {\n        display: flex;\n        flex-direction: column;\n        color: var(--color-primary);\n        gap: 4px;\n\n        span svg {\n            font-size: 14px;\n\n            &:hover {\n                cursor: pointer;\n                color: var(--color-primary-light);\n            }\n        }\n    }\n\n    .ToggleVisible {\n        display: flex;\n        justify-content: space-between;\n        width: 100%;\n        gap: 8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ColumnMoveDropdown": "PIX6Ro85DQei87wScEQo",
	"MoveColumnButtons": "qA3m_kIo5FVW0CraPTPA",
	"ToggleVisible": "_yu488RnXB1jmQ1k65KN"
};
export default ___CSS_LOADER_EXPORT___;
