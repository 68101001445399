// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vckr_epq_Uya0JBKXPSS{height:80%;overflow-y:auto}.abNbKjClr6t3Qa86qq6R svg{font-size:14px;color:var(--color-text-secondary45)}", "",{"version":3,"sources":["webpack://./src/pages/TestFolders/components/TestFolderHeader/ProjectListDropdown/ProjectListDropdown.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,eAAA,CAGJ,0BACI,cAAA,CACA,mCAAA","sourcesContent":[".Dropdown {\n    height: 80%;\n    overflow-y: auto;\n}\n\n.NavIcon svg {\n    font-size: 14px;\n    color: var(--color-text-secondary45);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Dropdown": "vckr_epq_Uya0JBKXPSS",
	"NavIcon": "abNbKjClr6t3Qa86qq6R"
};
export default ___CSS_LOADER_EXPORT___;
