import React, { ChangeEvent, useState } from 'react'

import { Input } from 'antd'

import styles from './NumericInput.module.scss'

type Props = {
    orderNum: number
    maxOrderNum: number
    moveRow: (orderNum: number, direction: number) => void
    disabled?: boolean
}

export const NumericInput = (props: Props) => {
    const { orderNum, moveRow, disabled, maxOrderNum } = props
    const [value, setValue] = useState(String(orderNum))
    const [prevValue, setPrevValue] = useState(String(orderNum))

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target
        const reg = /^\d+$/
        if (reg.test(inputValue) || inputValue === '') {
            setValue(inputValue)
        }
    }

    const updateOrderNum = () => {
        if (!value) {
            setValue(prevValue)
        } else {
            const valueTemp = +value.replace(/0*(\d+)/, '$1')
            const newValue = String(valueTemp > maxOrderNum ? maxOrderNum : valueTemp)
            orderNum !== +newValue && moveRow(orderNum, +newValue)
            setValue(String(orderNum))
            setPrevValue(String(orderNum))
        }
    }

    return (
        <Input
            value={value}
            onChange={handleChange}
            onBlur={updateOrderNum}
            maxLength={3}
            onPressEnter={updateOrderNum}
            className={styles.NumericInput}
            bordered={false}
            disabled={disabled}
        />
    )
}
