import React, { ChangeEvent, FC, FocusEvent, MouseEventHandler, useEffect, useMemo, useState } from 'react'

import { TestSetsMain } from '@/pages/TestSets/TestSetsMain/TestSetsMain'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { joinStringsWithSeparator } from '@/utils/string'

import { Button, Dropdown, Layout, Typography } from 'antd'
import type { MenuProps } from 'antd'
import { UserAvatar } from '@/components/Avatar/UserAvatar'
import { Header } from '@/components/Header/Header'
import { ChangePassword } from './ChangePassword/ChangePassword'
import { getContactIcon } from './ContactIcons/ContactIcons'
import { EditableField } from './EditableField/EditableField'
import { UserNameField } from './UserNameField/UserNameField'

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { StoreAuthController } from '@/store'
import { removableContactsList } from '@/consts'

import { fioTypes, IAdditionalUserContacts, IUserContacts, IUserWithId } from '@/types'
import { EUserMessengers } from '@/enums'
import type { UploadFile } from 'antd/es/upload/interface'

import styles from './User.module.scss'

const { Content } = Layout
const { Title } = Typography

interface IInitialFIO {
    firstname: string
    lastname: string
    middlename: string
}

// TODO validation with yup library instead of erasure blocking
export const User: FC = observer(() => {
    const { t } = useTranslation(['page--user'])
    const [userInfo, setUserInfo] = useState<IUserWithId>()
    const [isFullNameEditing, setIsFullNameEditing] = useState(false)
    const [currentContactFields, setCurrentContactFields] = useState<string[]>([])
    const [items, setItems] = useState<MenuProps['items']>([])
    const [avatar, setAvatar] = useState<UploadFile[]>([])
    const [initialFIO, setInitialFio] = useState<IInitialFIO>()
    const [isTestSetsLoading, setTestSetsLoading] = useState(true)

    useEffect(() => {
        ;(async () => {
            if (StoreAuthController.userId) {
                const user = await StoreAuthController.getUser(StoreAuthController.userId)
                setUserInfo(user)
            }
        })()
    }, [])

    useEffect(() => {
        if (userInfo?.firstname && userInfo?.lastname) {
            const initFio = {
                firstname: userInfo?.firstname,
                lastname: userInfo?.lastname,
                middlename: userInfo?.middlename,
            }
            setInitialFio(initFio)
        }
    }, [userInfo?.firstname, userInfo?.lastname, userInfo?.middlename])

    const updateUser = async (user: IUserWithId) => {
        const props = {
            userId: user.id,
            username: user.username,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            avatarId: user.avatarId,
            middlename: user.middlename,
            position: user.position,
            phone: user.phone,
            additionalContacts: user.additionalContacts,
        }

        await StoreAuthController.updateUser(props)
    }

    const handleAddContact: MenuProps['onClick'] = (e) => {
        const updatedCurrentContactFields = [...currentContactFields, e.key]
        setCurrentContactFields(updatedCurrentContactFields)
    }

    const handleDeleteContact: MouseEventHandler<HTMLButtonElement> = (event) => {
        if (!userInfo) {
            return
        }

        const fieldName = event.currentTarget?.name
        const userInfoCopy = { ...userInfo } as IUserWithId

        if (fieldName === 'phone') {
            userInfoCopy.phone = ''
        } else {
            delete userInfoCopy.additionalContacts[fieldName as keyof IAdditionalUserContacts]
        }

        setUserInfo(userInfoCopy)
        updateUser(userInfoCopy)
    }

    const menuProps = {
        items,
        onClick: handleAddContact,
    }

    useEffect(() => {
        const updatedCurrentContactFields = userInfo?.additionalContacts
            ? Object.keys(userInfo?.additionalContacts)
            : []

        //чтобы телефон не опускался вниз, при обновлении TESTADO-563
        if (userInfo?.phone) {
            const phoneIndex = currentContactFields.findIndex((value) => value === 'phone')
            if (phoneIndex > 0) {
                updatedCurrentContactFields.splice(phoneIndex, 0, 'phone')
            } else {
                updatedCurrentContactFields.push('phone')
            }
        }

        setCurrentContactFields(updatedCurrentContactFields)
    }, [userInfo])

    useEffect(() => {
        const dropdownFields = removableContactsList.filter(
            (contactField) => !currentContactFields.includes(contactField)
        )
        const newDropdownItems = dropdownFields.map((fieldName) => {
            return {
                key: fieldName,
                label: t(`page--user:contacts.${fieldName}`),
                icon: getContactIcon(fieldName as IUserContacts),
            }
        })
        setItems(newDropdownItems)
    }, [currentContactFields])

    const getChangedUserInfo = (fieldName: string, value?: string) => {
        const isMessengerField = Object.keys(EUserMessengers).includes(fieldName as EUserMessengers)
        const userInfoCopy = { ...userInfo } as IUserWithId

        const isDeleteAction = !value && removableContactsList.includes(fieldName)
        const isAddAction = !!value
        const isNameField = ['firstname', 'lastname', 'middlename'].includes(fieldName)

        if (isDeleteAction) {
            if (fieldName === 'phone') {
                userInfoCopy.phone = ''
            } else {
                delete userInfoCopy.additionalContacts[fieldName as keyof IAdditionalUserContacts]
            }
        }

        if (isAddAction) {
            if (isMessengerField) {
                userInfoCopy.additionalContacts[fieldName as keyof IAdditionalUserContacts] = value
            } else {
                userInfoCopy[fieldName] = value
            }
        } else {
            if (isNameField && !value) {
                userInfoCopy[fieldName] = value
                return userInfoCopy
            }
        }
        return userInfoCopy
    }

    const handleFillEmptyName = (fieldName: string) => {
        const notAllowedToBeEmptyFields = ['firstname', 'lastname']
        if (notAllowedToBeEmptyFields.includes(fieldName) && initialFIO) {
            const userInfoCopy = { ...userInfo } as IUserWithId

            if (!userInfo?.firstname) {
                userInfoCopy[fieldName as typeof fioTypes] = initialFIO[fieldName as typeof fioTypes]
            }

            if (!userInfo?.lastname) {
                userInfoCopy[fieldName as typeof fioTypes] = initialFIO[fieldName as typeof fioTypes]
            }
            setUserInfo(userInfoCopy)
        }
    }

    const handleFieldBlur = async (e: ChangeEvent<HTMLInputElement>) => {
        const fieldName = e.target.name
        handleFillEmptyName(fieldName)
        userInfo && (await updateUser(userInfo))
    }

    const handleChangeValue = async (e?: ChangeEvent<HTMLInputElement>, fioValue?: string) => {
        if (!userInfo) {
            return
        }

        const fieldName = e ? e.target.name : 'username'
        const value = e ? e.target.value : fioValue
        const changedUserInfo = getChangedUserInfo(fieldName, value)
        setUserInfo(changedUserInfo)
    }

    const handleFullNameClick = () => {
        setIsFullNameEditing(true)
    }

    const fullUserName = useMemo(
        () =>
            userInfo ? joinStringsWithSeparator(' ', userInfo.lastname, userInfo.firstname, userInfo.middlename) : '',
        [userInfo]
    )

    return (
        <div className={styles.PageUser}>
            <Header />
            {userInfo && (
                <Content className={cn('content', styles.Content)}>
                    {!isTestSetsLoading && (
                        <div className={styles.UserProfile}>
                            {/*  TODO: На беке не работает отображение аватарок по ссылке*/}
                            <UserAvatar userName={userInfo.username} size={96} manageAvatar />
                            {!isFullNameEditing && (
                                <>
                                    <Title
                                        level={4}
                                        onClick={handleFullNameClick}
                                        className={styles.FIO}
                                        editable={{
                                            triggerType: ['text', 'icon'],
                                        }}
                                    >
                                        {fullUserName}
                                    </Title>
                                    <UserNameField
                                        fieldName={'username'}
                                        value={userInfo.username}
                                        onChange={handleChangeValue}
                                    />
                                </>
                            )}
                            {isFullNameEditing && (
                                <>
                                    <EditableField
                                        fieldName={'lastname'}
                                        label={t('page--user:lastname')}
                                        value={userInfo.lastname}
                                        onChange={handleChangeValue}
                                        onBlur={handleFieldBlur}
                                        setFIOEditing={setIsFullNameEditing}
                                    />
                                    <EditableField
                                        fieldName={'firstname'}
                                        label={t('page--user:firstname')}
                                        value={userInfo.firstname}
                                        onChange={handleChangeValue}
                                        onBlur={handleFieldBlur}
                                        setFIOEditing={setIsFullNameEditing}
                                    />
                                    <EditableField
                                        fieldName={'middlename'}
                                        label={t('page--user:middlename')}
                                        value={userInfo.middlename}
                                        onChange={handleChangeValue}
                                        onBlur={handleFieldBlur}
                                        setFIOEditing={setIsFullNameEditing}
                                    />
                                </>
                            )}
                            <div className={styles.Contacts}>
                                <EditableField
                                    fieldName={'email'}
                                    label={t('page--user:contacts.email')}
                                    value={userInfo?.email}
                                    onChange={handleChangeValue}
                                    onBlur={handleFieldBlur}
                                />

                                {currentContactFields?.map((field) => (
                                    <div className={styles.AddintionalContactField} key={field}>
                                        <EditableField
                                            fieldName={field}
                                            label={t(`page--user:contacts.${field}`)}
                                            value={
                                                field === 'phone'
                                                    ? userInfo.phone
                                                    : userInfo?.additionalContacts[
                                                          field as keyof IAdditionalUserContacts
                                                      ]
                                            }
                                            onChange={handleChangeValue}
                                            isNewContact={
                                                currentContactFields[currentContactFields.length - 1] === field
                                            }
                                            userInfo={userInfo}
                                            onBlur={handleFieldBlur}
                                        />
                                        <Button
                                            className={cn(styles.DeleteContactButton, 'borderless')}
                                            name={field}
                                            ghost
                                            icon={<DeleteOutlined />}
                                            onClick={handleDeleteContact}
                                        />
                                    </div>
                                ))}

                                {!!items?.length && (
                                    <Dropdown menu={menuProps}>
                                        <Button type="link" icon={<PlusOutlined />} className={styles.AddContactButton}>
                                            {t('page--user:contacts.addContact')}
                                        </Button>
                                    </Dropdown>
                                )}
                            </div>
                            <ChangePassword />
                        </div>
                    )}
                    <div className={styles.TestSets}>
                        {
                            <TestSetsMain
                                testerId={userInfo.id}
                                isTestSetsLoading={isTestSetsLoading}
                                setTestSetsLoading={setTestSetsLoading}
                            />
                        }
                    </div>
                </Content>
            )}
        </div>
    )
})
