import React, { ChangeEvent } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Input, Tooltip, Typography } from 'antd'
import { UploadTestCaseAttachments } from '@/components/TestCaseComponent/UploadTestCaseAttachments/UploadTestCaseAttachments'
import { TestCaseStatusButtons } from './TestCaseStatusButtons'

import { StoreTestCase } from '@/store'

import { EAttachment, EEntityType } from '@/enums'

import styles from './TestCaseStatus.module.scss'

const { Text } = Typography
const { TextArea } = Input

export const TestCaseStatus = observer(() => {
    const { t } = useTranslation(['testCase', 'common'])

    const handleChangeDescription = (e: ChangeEvent<HTMLTextAreaElement>) => {
        console.log(e.target.value)
    }

    return (
        <div className={styles.TestCaseStatus}>
            <TestCaseStatusButtons />

            <Tooltip title={t('common:inDevelopment')}>
                <div className={styles.Comment}>
                    <Text className={styles.SelectLabel} type="secondary">
                        {t('testCase:comment')}
                    </Text>
                    <TextArea showCount maxLength={250} onChange={handleChangeDescription} disabled />
                </div>
            </Tooltip>
            <div className={styles.SideUpload}>
                <UploadTestCaseAttachments
                    existingObjectId={StoreTestCase.id}
                    attachmentType={EAttachment.EXECUTION_CASE}
                    entityType={EEntityType.EXECUTION_CASE}
                    isExecutionTestCase
                    originExistingObjectId={StoreTestCase.testCaseInfo.testCaseId}
                />
            </div>
        </div>
    )
})
