// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NxOOcPZJdennIoeSToR9{display:flex;gap:16px;align-items:center}.NxOOcPZJdennIoeSToR9 .JjpWbAFD7N8YPe7L2ldh{display:flex;align-items:center;justify-content:center;padding:2px 4px;width:32px;height:24px;border-radius:8px;background:var(--color-primary-dark);color:#fff;text-align:center}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Content/StepsPanel/StepsPanelHeader.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,QAAA,CACA,kBAAA,CAEA,4CACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,eAAA,CACA,UAAA,CACA,WAAA,CACA,iBAAA,CACA,oCAAA,CACA,UAAA,CACA,iBAAA","sourcesContent":[".TableTitle {\n    display: flex;\n    gap: 16px;\n    align-items: center;\n\n    .NumOfTestCases {\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        padding: 2px 4px;\n        width: 32px;\n        height: 24px;\n        border-radius: 8px;\n        background: var(--color-primary-dark);\n        color: white;\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TableTitle": "NxOOcPZJdennIoeSToR9",
	"NumOfTestCases": "JjpWbAFD7N8YPe7L2ldh"
};
export default ___CSS_LOADER_EXPORT___;
