import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Modal } from 'antd'
import { FoldersTab } from '../FoldersTree/FoldersTab'

import { pageUrls } from '@/consts'

import { ITestFolder } from '@/types'

type Props = {
    initialTestFolders: ITestFolder[]
    initialSelectedFolderId: string
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
    onConfirm: (selectedId: string) => void
    onClose: () => void
}

export const MoveTestCaseToFolderModal = (props: Props) => {
    const { onClose, initialTestFolders, initialSelectedFolderId, pageType, onConfirm } = props

    const { t } = useTranslation(['page--testFolders'])

    const [selectedFolderId, setSelectedFolderId] = useState(initialSelectedFolderId)
    const [testFolders, setTestFolders] = useState(initialTestFolders)

    useEffect(() => {
        setSelectedFolderId(initialSelectedFolderId)
        setTestFolders(initialTestFolders)
    }, [initialSelectedFolderId, initialTestFolders])

    const handleConfirm = () => {
        onConfirm(selectedFolderId)
    }

    const isDisabledConfirm = !selectedFolderId || selectedFolderId === initialSelectedFolderId

    return (
        <Modal
            open
            title={t('page--testFolders:selectFolder')}
            onOk={handleConfirm}
            onCancel={onClose}
            okButtonProps={{ disabled: isDisabledConfirm }}
            centered
        >
            <FoldersTab
                testFolders={testFolders}
                setTestFolders={setTestFolders}
                selectedFolderId={selectedFolderId}
                setSelectedFolderId={setSelectedFolderId}
                pageType={pageType}
                selectFolderOnly
                selectFolderModal
            />
        </Modal>
    )
}
