import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Button } from 'antd'
import { ModalChangePassword } from './ModalChangePassword'

import { PasswordKeyIcon } from '@/components/Icons'
import { KeyOutlined } from '@ant-design/icons'

import styles from './ChangePassword.module.scss'

export const ChangePassword = () => {
    const { t } = useTranslation(['page--user'])

    const [isModalOpen, setModalOpen] = useState(false)

    const onChangePassword = () => {
        setModalOpen(true)
    }

    return (
        <div>
            <Button
                type="link"
                onClick={onChangePassword}
                className={styles.ChangePasswordButton}
                icon={<PasswordKeyIcon />}
            >
                {t('page--user:password.changePassword')}
            </Button>

            <ModalChangePassword isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
        </div>
    )
}
