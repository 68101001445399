import React from 'react'

import { observer } from 'mobx-react'

import { ThemeStore } from '@/store'
import { themeUrls } from '@/consts'

import styles from './Logo.module.scss'

export const Logo = observer(() => {
    const src = `${themeUrls[ThemeStore.theme]}logo.svg`

    return (
        <div className={styles.Logo}>
            <img src={src} alt="logo" />
        </div>
    )
})
