import { Base64 } from 'js-base64'
import _ from 'lodash'
import { observable } from 'mobx'

import { MsAuthApi, SERVER_ERROR } from '@/api'

import { LocalStorage, showMessage } from '@/utils'

import { PreferencesStore, SpinnerStore, StoreApp } from '@/store'
import { pageUrls } from '@/consts'

import {
    IProjectUsers,
    ISearchUsers,
    ISignUpParams,
    IUpdateCurrentUserPassword,
    IUpdateUser,
    IUserWithId,
    IWorkspaceUsers,
} from '@/types'
import { EAuthority, EmailEventType } from '@/enums'

export const StoreAuthController = observable({
    _blockedUser: false as boolean,
    _isSignInForm: true as boolean,
    _signUpParams: {
        username: '',
        password: '',
        email: '',
        fullName: '',
        firstname: '',
        lastname: '',
        fillLocalRepoByDefaultProjects: false,
    } as ISignUpParams,
    hideError: false as boolean,
    me: {} as Partial<IUserWithId>,

    setHideError(value: boolean) {
        return (this.hideError = value)
    },

    get isBlockedUser() {
        return this._blockedUser
    },

    get isSignInForm() {
        return this._isSignInForm
    },

    get signUpParams() {
        return this._signUpParams
    },

    set isBlockedUser(value: boolean) {
        this._blockedUser = value
    },

    set isSignInForm(value: boolean) {
        this._isSignInForm = value
    },

    setSignUpparams(params: ISignUpParams) {
        this._signUpParams = params
    },

    get isSignedIn() {
        return MsAuthApi.isSignedIn
    },

    get _tokenData() {
        const accessToken = MsAuthApi.accessToken
        if (!new RegExp(`^[^.]+\.[^.]+\.[^.]+$`).test(accessToken || '')) {
            return
        }
        const tokenBody = accessToken ? accessToken.split('.')[1]! : ''
        const decodedToken = Base64.decode(tokenBody)
        return JSON.parse(decodedToken) as {
            USER_ID: string
            user_name: string
            AVATAR_ID: string
            authorities: EAuthority[]
            roleCodes: string[]
            WORKSPACES_PERMISSIONS: Record<string, any[]>
            PROJECTS_PERMISSIONS: Record<string, any[]>
            WORKSPACES_ROLES: Record<string, any[]>
            PROJECTS_ROLES: Record<string, any[]>
            ADMIN_ROLES: string[]
        }
    },

    get roleCodes() {
        return this._tokenData.roleCodes
    },

    get workspacePermissions() {
        return this._tokenData.WORKSPACES_PERMISSIONS
    },

    get projectPermissions() {
        return this._tokenData.PROJECTS_PERMISSIONS
    },

    get workspaceRoles() {
        return this._tokenData.WORKSPACES_ROLES
    },

    get projectRoles() {
        return this._tokenData.PROJECTS_ROLES
    },

    get hasAdminRole() {
        return this._tokenData?.ADMIN_ROLES?.includes('ADMIN')
    },

    get userId() {
        return this._tokenData.USER_ID
    },

    get keepRefreshTokenInLocalStorage() {
        return MsAuthApi.keepRefreshTokenInLocalStorage
    },

    set keepRefreshTokenInLocalStorage(value: boolean) {
        const localStorageRememberValue = value ? 'yes' : 'no'
        LocalStorage.set('rememberMe', localStorageRememberValue)
        MsAuthApi.keepRefreshTokenInLocalStorage = value
    },

    async getAuthorizationData() {
        try {
            await this.getMe()
        } catch (error) {
            console.error(error)
        }
    },

    async emailConfirmation(email?: string, type: EmailEventType = EmailEventType.EMAIL_CONFIRMATION_EVENT) {
        SpinnerStore.show = true
        try {
            MsAuthApi.emailConfirmation(email ?? this._signUpParams.email, type)
        } finally {
            SpinnerStore.show = false
        }
    },

    async signUp(params: ISignUpParams) {
        SpinnerStore.show = true
        try {
            this.setSignUpparams(params)
            await MsAuthApi.signUp(params)
            // await this.signIn(params)
            // PreferencesStore.t(`notification:success.signedUp`, {}).then((message) => {
            //     showMessage('success', message)
            // })

            return true
        } catch (e: any) {
            if (e.message !== SERVER_ERROR) {
                throw e
            }
        } finally {
            SpinnerStore.show = false
        }
    },

    async signIn(params: Pick<ISignUpParams, 'username' | 'password'>) {
        SpinnerStore.show = true
        try {
            await MsAuthApi.signIn(params)
            await StoreApp.getAppData()

            return true
        } catch (e: any) {
            if (e.message !== SERVER_ERROR) {
                throw e
            }
        } finally {
            SpinnerStore.show = false
        }
    },

    signOut() {
        if (location.pathname === pageUrls.signIn) {
            MsAuthApi.signOut()
            SpinnerStore.show = false
        }
    },

    async searchUsers(userInfo: string) {
        SpinnerStore.setShow(true)

        const props = {
            username: [],
            firstname: [userInfo],
            middlename: [userInfo],
            lastname: [userInfo],
            cn: [],
            email: [],
            position: [userInfo],
            phone: [],
        }

        const res = await MsAuthApi.searchUsers(props)
        SpinnerStore.setShow(false)
        return res
    },

    async searchFilteredUsers(props: ISearchUsers) {
        SpinnerStore.setShow(true)
        const res = await MsAuthApi.searchUsers(props)
        SpinnerStore.setShow(false)
        return res
    },

    async getUser(userId: string) {
        SpinnerStore.setShow(true)

        const userWithId = await MsAuthApi.getUser(userId)

        SpinnerStore.setShow(false)

        return userWithId
    },

    async getMe() {
        this.me = await MsAuthApi.getUser(this.userId)
    },

    async updateUser(props: IUpdateUser) {
        SpinnerStore.setShow(true)
        const { userId, ...updatedUserWithoutId } = await MsAuthApi.updateUser(props)

        _.merge(this.me, updatedUserWithoutId)
        SpinnerStore.setShow(false)
    },

    async changeCurrentUserPassword(props: IUpdateCurrentUserPassword) {
        SpinnerStore.setShow(true)
        try {
            await MsAuthApi.changeCurrentUserPassword(props)
            PreferencesStore.t('notification:success.passwordUpdated', {}).then((message) => {
                showMessage('success', message)
            })
            return null
        } catch (err) {
            return err
        } finally {
            SpinnerStore.show = false
        }
    },

    async getUsersInWorkspace(workspaceId: string) {
        SpinnerStore.setShow(true)
        const res: IWorkspaceUsers = await MsAuthApi.getUsersInWorkspace(workspaceId)
        SpinnerStore.setShow(false)

        return res
    },

    async getUsersInProject(projectIds: string[]) {
        SpinnerStore.setShow(true)
        const res: IProjectUsers = await MsAuthApi.getUsersInProject(projectIds)
        SpinnerStore.setShow(false)
        return res
    },

    async removeFromWorkspace(userId: string, userName: string, workspaceId: string) {
        SpinnerStore.setShow(true)
        await MsAuthApi.removeFromWorkspace(userId, workspaceId)
        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.userExcludedFromWorkspace', { name: userName }).then((message) => {
            showMessage('success', message)
        })
    },

    async removeFromProject(userId: string, userName: string, projectId: string) {
        SpinnerStore.setShow(true)
        await MsAuthApi.removeFromProject(userId, projectId)
        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.userExcludedFromProject', { name: userName }).then((message) => {
            showMessage('success', message)
        })
    },

    async assignToWorkspace(userIds: string[], workspaceId: string) {
        SpinnerStore.setShow(true)
        await MsAuthApi.assignToWorkspace(userIds, workspaceId)
        SpinnerStore.setShow(false)
    },

    async assignToProject(userIds: string[], projectId: string) {
        SpinnerStore.setShow(true)
        StoreAuthController.setHideError(true)
        return await new Promise((resolve) => {
            //на беке создание проекта занимает время, сразу добавить юзера нельзя (281)
            const timerId = setInterval(async () => {
                MsAuthApi.assignToProject(userIds, projectId)
                    .then((resp) => {
                        if (resp) {
                            StoreAuthController.setHideError(false)
                            SpinnerStore.setShow(false)
                            clearInterval(timerId)
                            resolve(true)
                        }
                    })
                    .catch((err) => {
                        StoreAuthController.setHideError(true)
                    })
            }, 750)
        })
    },

    async addRoles(userId: string, roleIds: string[]) {
        SpinnerStore.setShow(true)
        await MsAuthApi.addRoles(userId, roleIds)
        SpinnerStore.setShow(false)
    },

    async deleteRoles(userId: string, roleIds: string[]) {
        SpinnerStore.setShow(true)
        await MsAuthApi.deleteRoles(userId, roleIds)
        SpinnerStore.setShow(false)
    },
})
