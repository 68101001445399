import React, { useEffect, useState } from 'react'

import cn from 'classnames'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { getTestCaseColumnOrder, LocalStorage, mapOrder, resetNumeration } from '@/utils'

import { Button, Dropdown } from 'antd'

import {
    CaretDownOutlined,
    CaretUpOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    InsertRowAboveOutlined,
} from '@ant-design/icons'

import { SpinnerStore, StoreTestCase } from '@/store'

import { IShowColumns } from '@/types'

import styles from './TestCaseColumnSettingsDropdownMenu.module.scss'

export const TestCaseColumnSettingsDropdownMenu = observer(() => {
    const { t } = useTranslation(['testCase'])

    interface ITestCaseColumnMenu {
        key: string
        label: string
        orderNum: number
        icon?: JSX.Element
    }

    const columnOrderPreference = getTestCaseColumnOrder()

    const defaultColumnMenuItems: ITestCaseColumnMenu[] = [
        {
            key: 'actions',
            label: t('testCase:actions'),
            orderNum: 1,
        },
        {
            key: 'expectedResult',
            label: t('testCase:expectedResult'),
            orderNum: 2,
        },
        {
            key: 'testData',
            label: t('testCase:testData'),
            icon: StoreTestCase.showColumns.testData ? <EyeOutlined /> : <EyeInvisibleOutlined />,
            orderNum: 3,
        },
        {
            key: 'comment',
            label: t('testCase:comment'),
            icon: StoreTestCase.showColumns.comment ? <EyeOutlined /> : <EyeInvisibleOutlined />,
            orderNum: 4,
        },
    ]

    useEffect(() => {
        const menuCopy = [...defaultColumnMenuItems]
        const sortedMenu = resetNumeration(mapOrder(menuCopy, columnOrderPreference, 'key'))
        setcolumnMenuItems(sortedMenu)
        StoreTestCase.setColumnsOrder(columnOrderPreference)
    }, [])

    const [columnMenuItems, setcolumnMenuItems] = useState(defaultColumnMenuItems)

    const onChangeColumnVisibility = (e: any) => {
        e.stopPropagation()
        StoreTestCase.toggleShowcolumn(e.currentTarget.id)
    }

    const moveColumn = (orderNum: number, direction: 'up' | 'down') => {
        const from = orderNum - 1

        let to
        switch (direction) {
            case 'up':
                to = from - 1
                break
            case 'down':
                to = from + 1
                break
            default:
                to = direction > columnMenuItems.length ? columnMenuItems.length : direction
        }

        const columnMenuItemsCopy = [...columnMenuItems]
        to >= 0
            ? columnMenuItemsCopy.splice(to, 0, ...columnMenuItemsCopy.splice(from, 1))
            : columnMenuItemsCopy.splice(to + 1, 0, ...columnMenuItemsCopy.splice(from, 1))

        const newColumnOrderMenu: ITestCaseColumnMenu[] = resetNumeration(columnMenuItemsCopy)
        setcolumnMenuItems(newColumnOrderMenu)

        const testCaseColumnsOrder = newColumnOrderMenu.map((item) => _.pick(item, ['key']).key)

        //в пост и пред условиях actions называется description, потому надо переименовать, чтобы учитывалось оба значения при перемещении
        const index = testCaseColumnsOrder.indexOf('actions')
        if (index !== -1) {
            testCaseColumnsOrder.splice(index, 0, 'description')
        }
        //чтобы actualResult и stepMenu не перемещались, всегда в конце были
        testCaseColumnsOrder.push('actualResult', 'stepMenu')
        LocalStorage.set('testCaseColumnsOrder', testCaseColumnsOrder.toString())
        StoreTestCase.setColumnsOrder(testCaseColumnsOrder)
    }

    return (
        <Dropdown
            dropdownRender={() => (
                <ul
                    className="ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical"
                    role="menu"
                    data-menu-list="true"
                >
                    {columnMenuItems?.map((column) => (
                        <li
                            className={cn(styles.ColumnMoveDropdown, 'ant-dropdown-menu-item')}
                            role="menuitem"
                            aria-disabled="false"
                            data-menu-id={column.key}
                            key={column.key}
                        >
                            <div className={styles.MoveColumnButtons}>
                                {column.orderNum !== 1 && (
                                    <CaretUpOutlined onClick={() => moveColumn(column.orderNum, 'up')} />
                                )}
                                {column.orderNum !== columnMenuItems.length && (
                                    <CaretDownOutlined onClick={() => moveColumn(column.orderNum, 'down')} />
                                )}
                            </div>
                            <div
                                id={column.key}
                                key={column.key}
                                onClick={onChangeColumnVisibility}
                                className={styles.ToggleVisible}
                            >
                                <span className="ant-dropdown-menu-title-content">
                                    <span className="ant-dropdown-menu-title-content">{column.label}</span>
                                </span>

                                {column?.icon && (
                                    <div>
                                        {StoreTestCase.showColumns[column.key as keyof IShowColumns] ? (
                                            <EyeOutlined />
                                        ) : (
                                            <EyeInvisibleOutlined />
                                        )}
                                    </div>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        >
            <Button ghost className={'borderless'} icon={<InsertRowAboveOutlined />} disabled={SpinnerStore.show} />
        </Dropdown>
    )
})
