// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nVHkUiSYDKNEDOii5Brx .ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content{-webkit-margin-start:30px;margin-inline-start:30px}", "",{"version":3,"sources":["webpack://./src/components/ModalOnDeleteRow/ModalOnDeleteRow.module.scss"],"names":[],"mappings":"AAEQ,2GACI,yBAAA,CAAA,wBAAA","sourcesContent":[".Modal {\n    :global {\n        .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {\n            margin-inline-start: 30px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Modal": "nVHkUiSYDKNEDOii5Brx"
};
export default ___CSS_LOADER_EXPORT___;
