import React, { ChangeEvent } from 'react'

import { observer } from 'mobx-react'

import { Typography } from 'antd'

import { SpinnerStore } from '@/store'

import styles from './UserNameField.module.scss'

const { Paragraph } = Typography

type Props = {
    fieldName: string
    value: string | undefined
    onChange: (e?: ChangeEvent<HTMLInputElement>, fioValue?: string) => void
}

export const UserNameField = observer((props: Props) => {
    const { value, onChange } = props

    return (
        <Paragraph
            className={styles.UserNameField}
            editable={
                SpinnerStore.show
                    ? false
                    : {
                          onChange: (value) => onChange(undefined, value),
                          triggerType: ['text', 'icon'],
                      }
            }
        >
            {value}
        </Paragraph>
    )
})
