// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Q6qNubDOxeNmyz_hAgV6{display:flex;align-items:center;gap:8px;padding:16px;border-bottom:1px solid var(--color-other-divider)}.Q6qNubDOxeNmyz_hAgV6 svg{font-size:14px;color:var(--color-text-secondary45)}", "",{"version":3,"sources":["webpack://./src/pages/TestSet/TestSet/components/TestSetsSideBar/SideBarTitle/SideBarTitle.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,OAAA,CACA,YAAA,CACA,kDAAA,CAEA,0BACI,cAAA,CACA,mCAAA","sourcesContent":[".SideBarTitle {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    padding: 16px;\n    border-bottom: 1px solid var(--color-other-divider);\n\n    svg {\n        font-size: 14px;\n        color: var(--color-text-secondary45);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SideBarTitle": "Q6qNubDOxeNmyz_hAgV6"
};
export default ___CSS_LOADER_EXPORT___;
