import React, { ReactNode } from 'react'

import { useTranslation } from 'react-i18next'

import { Tag } from 'antd'

import { CircleIcon } from '@/components/Icons'
import {
    BugFilled,
    CheckCircleOutlined,
    CloseCircleOutlined,
    MinusCircleFilled,
    PauseOutlined,
    PlayCircleOutlined,
    UserOutlined,
    WarningFilled,
} from '@ant-design/icons'

import { EExecutionCaseStatus, EExecutionTestSetStatus, EWorkStatus } from '@/enums'

import styles from './Status.module.scss'

interface IStatusStyle {
    color: string
    icon: ReactNode
}

const statusStyles = new Map<EExecutionTestSetStatus | EWorkStatus | EExecutionCaseStatus, IStatusStyle>([
    [
        EExecutionTestSetStatus.PLANNING,
        {
            color: 'default',
            icon: <CircleIcon />,
        },
    ],
    [EExecutionTestSetStatus.ASSIGNED, { color: 'error', icon: <UserOutlined /> }],
    [EExecutionTestSetStatus.IN_PROGRESS, { color: 'processing', icon: <PlayCircleOutlined /> }],
    [EExecutionTestSetStatus.POSTPONED, { color: 'warning', icon: <PauseOutlined /> }],
    [EExecutionTestSetStatus.CLOSED, { color: 'green', icon: <CloseCircleOutlined /> }],
    [EExecutionTestSetStatus.COMPLETED, { color: 'success', icon: <CheckCircleOutlined /> }],

    [EExecutionCaseStatus.BLOCKED, { color: 'error', icon: <MinusCircleFilled /> }],
    [EExecutionCaseStatus.FAILED, { color: 'error', icon: <BugFilled /> }],
    [EExecutionCaseStatus.SKIPPED, { color: 'warning', icon: <WarningFilled /> }],
    [EExecutionCaseStatus.PASSED, { color: 'success', icon: <CheckCircleOutlined /> }],
    [EExecutionCaseStatus.NOT_PASSED, { color: 'default', icon: <CircleIcon /> }],
    [EExecutionCaseStatus.PAUSED, { color: 'warning', icon: <PauseOutlined /> }],
    [EExecutionCaseStatus.IN_PROGRESS, { color: 'processing', icon: <PlayCircleOutlined /> }],
    [EExecutionCaseStatus.IN_PROCESS, { color: 'processing', icon: <PlayCircleOutlined /> }],

    [EWorkStatus.READY, { color: 'success', icon: <CheckCircleOutlined /> }],
    [EWorkStatus.DRAFT, { color: 'processing', icon: <PlayCircleOutlined /> }],
])

type Props = {
    status: EExecutionTestSetStatus | EWorkStatus | EExecutionCaseStatus
    shortened?: boolean
}

export const Status = (props: Props) => {
    const { status, shortened } = props
    const { t } = useTranslation(['statuses'])

    return (
        <span className={styles.Status}>
            {shortened ? (
                <Tag
                    icon={statusStyles.get(status)?.icon}
                    color={statusStyles.get(status)?.color}
                    className={styles.SquareTag}
                />
            ) : (
                <Tag
                    icon={statusStyles.get(status)?.icon}
                    color={statusStyles.get(status)?.color}
                    className={styles.Description}
                >
                    {t(`statuses:${status}`)}
                </Tag>
            )}
        </span>
    )
}
