import { IExecutionStep, IStep } from '@/types'

export const mergeStepsToExecutionSteps = (steps: IStep[], executionSteps: IExecutionStep[]): any => {
    const executionStepsCopy = JSON.parse(JSON.stringify(executionSteps))
    const updatedSteps = executionStepsCopy.map((exeStep: IExecutionStep) => {
        const parentStepData = steps?.find((step: IStep) => step.id === exeStep.stepId)

        return {
            ...exeStep,
            jiraIssuesWithData: exeStep?.jiraIssuesWithData ? exeStep?.jiraIssuesWithData : [],
            required: parentStepData?.required,
            stepType: parentStepData?.stepType,
            externalId: parentStepData?.externalId,
            comment: parentStepData?.comment,
            description: parentStepData?.description,
            expectedResult: parentStepData?.expectedResult,
            testData: parentStepData?.testData,
            actions: parentStepData?.actions,
            orderNum: parentStepData?.orderNum,
            manual: parentStepData?.manual,
        }
    })

    return updatedSteps
}
