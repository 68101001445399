import React, { Dispatch, SetStateAction } from 'react'

import { SelectTestCases } from '@/pages/CreateTestSet/SelectTestCases/SelectTestCases'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { parseNormativeTime } from '@/utils'

import { Modal } from 'antd'

import { StoreCreateTestSet, StoreTestSet } from '@/store'

import { IExecutionCase, ITestSet } from '@/types'
import { EExecutionCaseStatus, EWorkStatus } from '@/enums'

import styles from './ModalAddToTestSet.module.scss'

type Props = {
    testSetInfo: ITestSet | undefined
    showModalAddToTestSet: boolean
    setShowModalAddToTestSet: Dispatch<SetStateAction<boolean>>
    setTestSetInfo?: Dispatch<SetStateAction<ITestSet | undefined>>
    testSets?: ITestSet[]
    setTestSets?: Dispatch<SetStateAction<ITestSet[]>>
    setTestCases?: Dispatch<SetStateAction<IExecutionCase[]>>
}

export const ModalAddToTestSet = observer((props: Props) => {
    const {
        testSetInfo,
        setTestSetInfo,
        showModalAddToTestSet,
        setShowModalAddToTestSet,
        setTestCases,
        setTestSets,
        testSets,
    } = props
    const { t } = useTranslation(['page--testSets', 'common', 'notification'])
    const navigate = useNavigate()

    const handleAdd = (testCasesId: string[]) => {
        if (testSetInfo && testCasesId.length) {
            StoreTestSet.addToTestSet([testSetInfo], testCasesId, navigate).then((updatedTestSets) => {
                const updatedNotPassedCounter = updatedTestSets[0].executionCases.filter(
                    (testCase) => testCase.executionCaseStatus === EExecutionCaseStatus.NOT_PASSED
                ).length
                const updatedDueTime =
                    updatedTestSets[0].normativeTime && parseNormativeTime(updatedTestSets[0].normativeTime)
                if (testSets && setTestSets) {
                    const testSetsCopy = [...testSets]
                    const progressCopy = { ...testSetInfo.progress } as Record<EExecutionCaseStatus, number>
                    progressCopy[EExecutionCaseStatus.NOT_PASSED] = updatedNotPassedCounter
                    const index = testSets.findIndex((set) => set.id === updatedTestSets[0].id)
                    testSetsCopy.splice(index, 1, {
                        ...updatedTestSets[0],
                        progress: progressCopy,
                        dueTime: updatedDueTime || undefined,
                    })
                    setTestSets(testSetsCopy)
                }
                if (setTestSetInfo && setTestCases) {
                    const testSetInfoCopy = { ...testSetInfo }
                    if (testSetInfoCopy.progress) {
                        testSetInfoCopy.progress[EExecutionCaseStatus.NOT_PASSED] = updatedNotPassedCounter
                    }
                    if (updatedDueTime) {
                        testSetInfoCopy.dueTime = updatedDueTime
                    }
                    setTestSetInfo(testSetInfoCopy)
                    setTestCases(updatedTestSets[0].executionCases)
                }
                if (setTestSetInfo) {
                    setTestSetInfo((prevTestSetInfo: any) => {
                        if (prevTestSetInfo) {
                            return {
                                ...prevTestSetInfo,
                                progress: {
                                    ...prevTestSetInfo.progress,
                                    [EExecutionCaseStatus.NOT_PASSED]: updatedNotPassedCounter,
                                },
                            }
                        }
                        return prevTestSetInfo
                    })
                }
                setShowModalAddToTestSet(false)
            })
        }
    }

    const handleOk = () => {
        if (StoreCreateTestSet.selectedRowKeys.length) {
            if (
                StoreCreateTestSet.selectedTestCases.some(
                    (testCase) => !!testCase.testCaseBlockInfo || testCase.workStatus === EWorkStatus.DRAFT
                )
            ) {
                const readyTestCaseIds = StoreCreateTestSet.validateAddingToTestCycle()

                if (readyTestCaseIds.length) {
                    handleAdd(readyTestCaseIds)
                } else {
                    Modal.warning({
                        title: t('notification:warning.testCaseCannotBeAdded', {
                            status: t(`statuses:${EWorkStatus.DRAFT}`),
                        }),
                        centered: true,
                    })
                }
            } else {
                handleAdd(StoreCreateTestSet.selectedRowKeys as string[])
            }
        }
        StoreCreateTestSet.isCreatingTestSet && StoreCreateTestSet.resetTestSet()
    }

    const handleCancel = () => {
        StoreCreateTestSet.isCreatingTestSet && StoreCreateTestSet.resetTestSet()
        setShowModalAddToTestSet(false)
    }

    const okButtonDisabledCondition = StoreCreateTestSet.selectedTestCases.length === 0

    return (
        <Modal
            title={t('page--testSets:addToTestSetTitle', { name: testSetInfo?.name })}
            open={showModalAddToTestSet}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={t('common:buttons.select')}
            className={styles.ModalAddToTestSet}
            bodyStyle={{ display: 'flex', overflowY: 'hidden' }}
            okButtonProps={{ disabled: okButtonDisabledCondition }}
        >
            {testSetInfo?.projectId && <SelectTestCases projectId={testSetInfo?.projectId} showInModal />}
        </Modal>
    )
})
