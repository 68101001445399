import React, { useMemo } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { MsAuthApi } from '@/api'

import { Button, Layout } from 'antd'
import { UserAvatar } from '@/components/Avatar/UserAvatar'
import ButtonMailto from '@/components/ButtonMailto'
import { DarkSwitcher } from '@/components/DarkSwitcher'
import DownloadDocumentation from '@/components/DownloadDocumentation/DownloadDocumentation'
import { LanguageSwitcher } from '@/components/LanguageSwitcher/LanguageSwitcher'
import { Logo } from '@/components/Logo/Logo'

import { LogoutOutlined } from '@ant-design/icons'

import { StoreAuthController, ThemeStore } from '@/store'
import { pageUrls, supportMail } from '@/consts'

import styles from './Header.module.scss'

export const Header = observer(() => {
    const navigate = useNavigate()
    const location = useLocation()
    const { t } = useTranslation(['common'])

    const isDarkTheme = useMemo(() => ThemeStore.theme === 'dark', [ThemeStore.theme])

    const userName = StoreAuthController.me.username || ''

    const onLogout = () => {
        MsAuthApi.signOut()
        navigate('/')
    }

    const handleUserIconClick = () => {
        if (location.pathname === pageUrls.user) {
            navigate(-1)
        } else {
            navigate(pageUrls.user)
        }
    }

    const exitButtonType = !isDarkTheme ? 'primary' : 'text'

    return (
        <Layout.Header className={cn(styles.Header, { [styles.HeaderDark]: isDarkTheme })}>
            <div className={styles.HeaderLeftSide}>
                <Link to={pageUrls.workspaces} className={styles.Logo}>
                    <Logo />
                </Link>
            </div>
            <div className={styles.RightMenu}>
                <DownloadDocumentation />
                <ButtonMailto
                    email={supportMail}
                    subject={
                        StoreAuthController.isSignedIn
                            ? t('common:support-mail-from-user', { userName: userName })
                            : t('common:support-mail')
                    }
                    label={t('common:support')}
                    headerStyle
                />
                <DarkSwitcher />
                <LanguageSwitcher isHeader />

                <div className={styles.Profile} onClick={handleUserIconClick}>
                    <div className={styles.Avatar}>
                        <UserAvatar
                            userName={userName}
                            // TODO avatarId={StoreAuthController.me.avatarId}
                            size={32}
                        />
                    </div>
                    <div className={styles.Name}>{userName}</div>
                </div>
                <Button type={exitButtonType} className={styles.Exit} onClick={onLogout}>
                    <LogoutOutlined />
                </Button>
            </div>
        </Layout.Header>
    )
})
