import React, { ClipboardEvent, DragEventHandler, useEffect, useMemo, useRef, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'

import { ReactQuillFormats, ReactQuillModules, useDebounce } from '@/utils'

import { notification } from 'antd'
import { handlePasteAndDropFiles } from '@/components/TestCaseComponent/UploadTestCaseAttachments/handlePasteAndDropFiles'
import { UploadTestCaseAttachments } from '@/components/TestCaseComponent/UploadTestCaseAttachments/UploadTestCaseAttachments'

import { StoreTestCase } from '@/store'
import { attachmentMaxSizeMb, maxStepLength } from '@/consts'

import { ISelectedStep, IStep, IStepColumnNames } from '@/types'
import { EAttachment, EEntityType } from '@/enums'

import 'react-quill/dist/quill.snow.css'

import styles from './StepEditor.module.scss'

type Props = {
    columnName: string
    defaultValue: string | undefined
    step: IStep
    updateStep: (value: string, column: IStepColumnNames, row: IStep) => Promise<void>
    isExecutionTestCase: boolean
}

export const StepEditor = observer((props: Props) => {
    const { columnName, defaultValue, step, updateStep, isExecutionTestCase } = props
    const { t } = useTranslation(['testCase', 'common', 'validation'])
    const quillRef = useRef<HTMLDivElement | any>(null)
    const [currentValueDebounced, currentValue, setCurrentValue] = useDebounce(200, defaultValue || '')

    const [selectedColumn, setSelectedColumn] = useState<ISelectedStep | null>(null)
    const [isMaxLengthReached, setMaxLengthReached] = useState(false)
    const [hasFiles, setHasFiles] = useState(false)

    const isDisabled = columnName === 'actualResult' ? !StoreTestCase.executionCaseStarted : StoreTestCase.readOnly

    useEffect(() => {
        setCurrentValue(defaultValue || '')
    }, [defaultValue])

    const handleEditorFocus = (columnName: IStepColumnNames, orderNum: number) => {
        const newSelected = {
            name: columnName,
            orderNum: orderNum,
        }
        setSelectedColumn(newSelected)
    }
    const handleChangeStep = (value: string) => {
        const currentValueWithoutHTML = value.replace(/(<([^>]+)>﻿?)/gi, '')
        //редактор автоматически добавляет еще 7 тегов параграфа, потому при открытии сразу появлялась ошибка
        const shouldTrim = value.length > maxStepLength + 7 && !!currentValueWithoutHTML
        const showMaxLengthReachedMessage = value.length + 7 > maxStepLength

        setMaxLengthReached(showMaxLengthReachedMessage)
        if (!currentValueWithoutHTML) {
            setCurrentValue('')
            setMaxLengthReached(false)
            handleSaveStep('')
        } else {
            handleSaveStep(value, shouldTrim)
        }
    }

    const handleFocusCell = () => {
        if (!isDisabled) {
            handleEditorFocus(columnName as IStepColumnNames, step.orderNum)
        }
    }

    const save = (stepValue: string) => {
        updateStep(stepValue, columnName as IStepColumnNames, step)
    }

    useEffect(() => {
        if (currentValueDebounced !== defaultValue) {
            if (
                !(
                    (defaultValue === null && (currentValueDebounced === '' || currentValueDebounced === undefined)) ||
                    (currentValueDebounced === null && (defaultValue === '' || defaultValue === undefined))
                )
            ) {
                save(currentValueDebounced)
            }
        }
    }, [currentValueDebounced])

    const getTrimmedValue = (stepValue: string) => {
        const currentValueWithoutHTML = stepValue.replace(/(<([^>]+)>﻿?)/gi, '')
        const trimmedValue =
            currentValueWithoutHTML.length < maxStepLength
                ? currentValueWithoutHTML
                : currentValueWithoutHTML.substring(0, maxStepLength)

        return trimmedValue
    }

    const handleSaveStep = (stepValue: string, shouldTrim?: boolean) => {
        if (!isDisabled) {
            if (!shouldTrim) {
                setCurrentValue(stepValue)
            } else {
                const trimmedValue = getTrimmedValue(stepValue)
                setCurrentValue(trimmedValue)
            }
        }
    }

    const handleBlur = () => {
        setSelectedColumn(null)
    }

    const getAttachmentType = (columnName: IStepColumnNames) => {
        switch (columnName) {
            case 'actions':
                return EAttachment.STEP_ACTION
            case 'description':
                return EAttachment.STEP_ACTION
            case 'expectedResult':
                return EAttachment.STEP_EXPECTED_RESULT
            case 'comment':
                return EAttachment.STEP_COMMENT
            case 'testData':
                return EAttachment.STEP_TEST_DATA
            case 'actualResult':
                return EAttachment.STEP_ACTUAL_RESULT
        }
    }

    const attachmentType = useMemo(() => getAttachmentType(columnName as IStepColumnNames), [])

    const isNewStep = !isExecutionTestCase && !step.externalId

    const existingObjectId = isExecutionTestCase
        ? step.id
        : step.externalId
          ? step.externalId
          : `${step.stepType}_${columnName}_${step.orderNum}`

    const needUpload = !!(attachmentType && step.stepType)

    const handlePaste = (event: ClipboardEvent<HTMLInputElement> | any) => {
        handlePasteAndDropFiles({
            event: event,
            needUpload: needUpload,
            existingObjectId: existingObjectId,
            attachmentType: attachmentType,
            stepType: step.stepType,
            isExecutionTestCase: isExecutionTestCase,
            isNewStep: isNewStep,
            filesToUpload: StoreTestCase.filesToUpload,
            screenshotTranslation: t('common:screenshot'),
            duplicateFileNameMessage: t('validation:duplicateFileName'),
            attachmentMaxSizeMessage: t('common:buttons.attachmentMaxSize', {
                attachmentMaxSize: attachmentMaxSizeMb,
            }),
        })
    }

    const handleDrop = (event: DragEventHandler<HTMLDivElement> | any) => {
        handlePasteAndDropFiles({
            event: event,
            needUpload: needUpload,
            existingObjectId: existingObjectId,
            attachmentType: attachmentType,
            stepType: step.stepType,
            isExecutionTestCase: isExecutionTestCase,
            isNewStep: isNewStep,
            filesToUpload: StoreTestCase.filesToUpload,
            screenshotTranslation: t('common:screenshot'),
            duplicateFileNameMessage: t('validation:duplicateFileName'),
            attachmentMaxSizeMessage: t('common:buttons.attachmentMaxSize', {
                attachmentMaxSize: attachmentMaxSizeMb,
            }),
        })
    }

    const placeholder = t(`testCase:${columnName}`)

    //чтобы менялся placeholder при смене языка. По умолчанию, просто при изменении placeholder не происходит смена
    useEffect(() => {
        if (quillRef) {
            quillRef.current.getEditor().root.dataset.placeholder = placeholder || ''
        }
    }, [placeholder])

    useEffect(() => {
        if (isMaxLengthReached) {
            notification.error({
                message: t('validation:textLimitNotification'),
                placement: 'bottomRight',
            })
        }
    }, [isMaxLengthReached])

    return (
        <div className={styles.InnerCell} key={`${columnName}-${step.orderNum}`}>
            <div className={styles.QuillWrapper} onFocus={handleFocusCell} onPaste={handlePaste} onDrop={handleDrop}>
                <ReactQuill
                    ref={quillRef}
                    key={columnName + step.orderNum}
                    theme="snow"
                    value={currentValue}
                    modules={ReactQuillModules}
                    formats={ReactQuillFormats}
                    onChange={handleChangeStep}
                    onBlur={handleBlur}
                    onFocus={handleFocusCell}
                    placeholder={placeholder}
                    className={cn(styles.TextArea, {
                        [styles[`ColumnActive_${columnName}`]]:
                            selectedColumn?.name === columnName && selectedColumn?.orderNum === step.orderNum,
                        [styles.ShowAllColumns]:
                            +StoreTestCase.showColumns.testData + +StoreTestCase.showColumns.comment === 2 &&
                            !StoreTestCase.executionTestCase,
                        [styles.AreaWithFiles]: hasFiles,
                    })}
                    readOnly={isDisabled}
                    preserveWhitespace
                />
            </div>
            <div
                className={cn(styles.Upload, {
                    [styles[`ColumnActive_${columnName}`]]:
                        selectedColumn?.name === columnName && selectedColumn?.orderNum === step.orderNum,
                    [styles.HasFiles]: hasFiles,
                })}
            >
                {needUpload && (
                    <UploadTestCaseAttachments
                        existingObjectId={existingObjectId}
                        isExecutionTestCase={isExecutionTestCase}
                        originExistingObjectId={isExecutionTestCase ? step.externalId : null}
                        attachmentType={attachmentType}
                        entityType={isExecutionTestCase ? EEntityType.EXECUTION_STEP : EEntityType.STEP}
                        setHasFiles={setHasFiles}
                        stepType={step.stepType}
                        isNewStep={isNewStep}
                    />
                )}
            </div>
        </div>
    )
})
