// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ELjlaCtX3A7unUnMvgyQ{display:flex;flex-flow:column;font-size:14px;width:100%}.ELjlaCtX3A7unUnMvgyQ .Unogx7q3h1cABLpkUpYu .lXFYganOjK8LgkwfLG9C{padding-right:8px}.ELjlaCtX3A7unUnMvgyQ .Unogx7q3h1cABLpkUpYu svg{font-size:18px;color:var(--color-text-secondary45)}.ELjlaCtX3A7unUnMvgyQ .Z4zlOT_A5ZB3TafRykhF{font-size:12px;margin-bottom:5px}", "",{"version":3,"sources":["webpack://./src/pages/User/EditableField/EditableField.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,gBAAA,CACA,cAAA,CACA,UAAA,CAGI,kEACI,iBAAA,CAEJ,gDACI,cAAA,CACA,mCAAA,CAIR,4CACI,cAAA,CACA,iBAAA","sourcesContent":[".Info {\n    display: flex;\n    flex-flow: column;\n    font-size: 14px;\n    width: 100%;\n\n    .EditableField {\n        .Icon {\n            padding-right: 8px;\n        }\n        svg {\n            font-size: 18px;\n            color: var(--color-text-secondary45);\n        }\n    }\n\n    .Label {\n        font-size: 12px;\n        margin-bottom: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Info": "ELjlaCtX3A7unUnMvgyQ",
	"EditableField": "Unogx7q3h1cABLpkUpYu",
	"Icon": "lXFYganOjK8LgkwfLG9C",
	"Label": "Z4zlOT_A5ZB3TafRykhF"
};
export default ___CSS_LOADER_EXPORT___;
