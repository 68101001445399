import React, { FC, useEffect, useState } from 'react'

import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { parseNormativeTime } from '@/utils'

import { Button, Layout, Typography } from 'antd'
import { Header } from '@/components/Header/Header'
import Spinner from '@/components/Spinner/Spinner'
import { Breadcrumbs } from '@/pages/TestFolders/components/Breadcrumbs'
import { CreateNewFolderModal } from '@/pages/TestFolders/components/CreateNewFolderModal'
import { ResizableFoldersTree } from '@/pages/TestFolders/components/FoldersTree/ResizableFoldersTree'
import { RenameFolderModal } from '@/pages/TestFolders/components/Modals/RenameFolderModal'
import { SelectFolderModal } from '@/pages/TestFolders/components/Modals/SelectFolderModal/SelectFolderModal'
import { TestFolderHeader } from '@/pages/TestFolders/components/TestFolderHeader/TestFolderHeader'
import { TestSet } from './TestSet/TestSet'

import { PlusOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreProject, StoreTestFolder, StoreTestSet, StoreWorkspace } from '@/store'
import { apiUrls, pageUrls } from '@/consts'

import { IExecutionCase, IProject, ITestFolder, ITestSet, totalTimeType } from '@/types'
import { EExecutionCaseStatus, EModalTypeFolder } from '@/enums'

import styles from '@/pages/TestFolders/TestFolders.module.scss'

const { Content } = Layout
const { Text } = Typography

export const TestSetPage: FC = observer(() => {
    const { t } = useTranslation(['page--testFolders'])
    const { testSetId } = useParams()
    const [testSetInfo, setTestSetInfo] = useState<ITestSet | undefined>()
    const [testCases, setTestCases] = useState<IExecutionCase[]>([])
    const [projectInfo, setProjectInfo] = useState<IProject>()
    const [testSetFolders, setTestSetFolders] = useState<ITestFolder[]>([])
    const [selectedFolderId, setSelectedFolderId] = useState('')
    const [selectedForActionFolder, setSelectedForActionFolder] = useState<ITestFolder>()
    const [isModalCreateFolderOpen, setIsModalCreateFolderOpen] = useState(false)
    const [isModalRenameOpen, setIsModalRenameOpen] = useState(false)
    const [isModalSelectOpen, setIsModalSelectOpen] = useState(false)
    const [modalTypeFolder, setModalTypeFolder] = useState<EModalTypeFolder | undefined>()
    const [selectedInModalTestFolder, setSelectedInModalTestFolder] = useState('')
    const [totalTime, setTotalTime] = useState<totalTimeType>({ hours: 0, minutes: 0 })

    const [isTestSetLoading, setTestSetLoading] = useState(true)
    const [isProjectInfoLoading, setProjectInfoLoading] = useState(true)
    const isTableLoading = isTestSetLoading || isProjectInfoLoading

    const getAllProjects = async (workspaceId: string) => {
        if (!StoreWorkspace.projectList.length) {
            const props = {
                filter: { workspaceId: workspaceId, projectName: [], projectDescription: [], isDeleted: false },
            }
            StoreWorkspace.setProjectList((await StoreProject.getProjects(props)).result)
        }
    }

    const getItem = async (testSetId: string) => {
        setTestSetLoading(true)
        const testSet = await StoreTestSet.getItem(testSetId, apiUrls.testSet)
        if (testSet) {
            if (testSet.executionCases?.length) {
                const testCaseStatuses = [
                    ...new Set(testSet.executionCases.map((testCase) => testCase.executionCaseStatus)),
                ]
                testSet.progress = testCaseStatuses.reduce((acc: Record<string, number>, status) => {
                    return (
                        (acc[status] =
                            testSet.executionCases?.filter(
                                (testCase) =>
                                    testCase.executionCaseStatus === status && status !== EExecutionCaseStatus.SKIPPED
                            ).length || 0),
                        acc
                    )
                }, {})

                testSet.dueTime = (testSet.normativeTime && parseNormativeTime(testSet.normativeTime)) || {
                    hours: 0,
                    minutes: 0,
                }
            }

            setTestSetInfo(testSet)
            setTestCases(testSet.executionCases as IExecutionCase[])

            if (testSet?.projectId && !StoreWorkspace.projectList.length) {
                runInAction(async () => {
                    const project = await StoreProject.getProject(testSet.projectId)
                    StoreProject.setProjectId(testSet.projectId)
                    !StoreWorkspace.id && StoreWorkspace.setWorkspaceId(project.workspaceId)
                    getAllProjects(project.workspaceId)
                })
            }
        }
        !StoreWorkspace.id && StoreWorkspace.setWorkspaceId(testSet.workspaceId)
        StoreTestSet.setTestSetId(testSetId)
        setTestSetLoading(false)
    }

    useEffect(() => {
        if (testSetId) {
            getItem(testSetId)
        }
    }, [testSetId])

    const getProjectInfo = async (projectId: string) => {
        setProjectInfoLoading(true)
        const project = await StoreProject.getProject(projectId)
        setProjectInfo(project)
        StoreProject.setProjectId(projectId)
        !StoreWorkspace.id && StoreWorkspace.setWorkspaceId(project.workspaceId)

        const folders = await StoreTestSet.getTestSetFolders(projectId)
        setTestSetFolders(folders)
        StoreTestFolder.setSelectedFolderName('')
        setProjectInfoLoading(false)
    }

    useEffect(() => {
        if (testSetInfo?.projectId) {
            const projectId = testSetInfo.projectId
            getProjectInfo(projectId)
        }
    }, [testSetInfo?.projectId])

    const onCreateFolder = () => {
        setIsModalCreateFolderOpen(true)
    }

    useEffect(() => {
        if (testSetInfo?.dueTime?.hours !== undefined && testSetInfo?.dueTime?.minutes !== undefined) {
            setTotalTime({
                hours: testSetInfo.dueTime.hours,
                minutes: testSetInfo.dueTime.minutes,
            })
        }
    }, [testSetInfo?.dueTime])

    return (
        <div className={styles.TestFolderList}>
            <Header />
            {projectInfo && testSetInfo && testSetFolders ? (
                <Content className="content">
                    <Breadcrumbs projectInfo={projectInfo} />
                    <TestFolderHeader projectInfo={projectInfo} />
                    <div className={styles.Main}>
                        {testSetInfo?.projectId && (
                            <ResizableFoldersTree
                                testFolders={testSetFolders}
                                setTestFolders={setTestSetFolders}
                                selectedFolderId={selectedFolderId}
                                setSelectedFolderId={setSelectedFolderId}
                                setIsModalCreateFolderOpen={setIsModalCreateFolderOpen}
                                onCreateFolder={onCreateFolder}
                                setSelectedForActionFolder={setSelectedForActionFolder}
                                setIsModalRenameOpen={setIsModalRenameOpen}
                                setIsModalSelectOpen={setIsModalSelectOpen}
                                setModalTypeFolder={setModalTypeFolder}
                                pageType={pageUrls.testSets}
                            />
                        )}

                        <div className={styles.RightSide}>
                            {!testSetFolders.length && !isTableLoading ? (
                                <div className={styles.NoFolders}>
                                    <Text type="secondary">{t('page--testFolders:noTestSetsFolders')}</Text>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={onCreateFolder}
                                        disabled={SpinnerStore.show}
                                        className={styles.CreateFolder}
                                    >
                                        {t('page--testFolders:createFolder')}
                                    </Button>
                                </div>
                            ) : (
                                <TestSet
                                    testSetInfo={testSetInfo}
                                    setTestSetInfo={setTestSetInfo}
                                    testCases={testCases}
                                    setTestCases={setTestCases}
                                    setTotalTime={setTotalTime}
                                    totalTime={totalTime}
                                />
                            )}
                        </div>
                    </div>

                    {testSetInfo?.projectId && (
                        <CreateNewFolderModal
                            projectId={testSetInfo.projectId}
                            isModalCreateFolderOpen={isModalCreateFolderOpen}
                            setIsModalCreateFolderOpen={setIsModalCreateFolderOpen}
                            testFolders={testSetFolders}
                            setTestFolders={setTestSetFolders}
                            selectedFolderId={selectedFolderId}
                            setSelectedFolderId={setSelectedFolderId}
                            pageType={pageUrls.testSets}
                        />
                    )}

                    {selectedForActionFolder && isModalRenameOpen && testSetInfo?.projectId && (
                        <RenameFolderModal
                            projectId={testSetInfo.projectId}
                            isModalRenameOpen={isModalRenameOpen}
                            setIsModalRenameOpen={setIsModalRenameOpen}
                            testFolders={testSetFolders}
                            setTestFolders={setTestSetFolders}
                            selectedFolderId={selectedFolderId}
                            setSelectedFolderId={setSelectedFolderId}
                            selectedForActionFolder={selectedForActionFolder}
                            setSelectedForActionFolder={setSelectedForActionFolder}
                            pageType={pageUrls.testSets}
                        />
                    )}
                    {testSetInfo?.projectId && selectedForActionFolder && isModalSelectOpen && (
                        <SelectFolderModal
                            projectId={testSetInfo.projectId}
                            testFolders={testSetFolders}
                            setTestFolders={setTestSetFolders}
                            selectedFolderId={selectedFolderId}
                            isModalSelectOpen={isModalSelectOpen}
                            setIsModalSelectOpen={setIsModalSelectOpen}
                            setSelectedFolderId={setSelectedFolderId}
                            selectedForActionFolder={selectedForActionFolder}
                            modalTypeFolder={modalTypeFolder}
                            setModalTypeFolder={setModalTypeFolder}
                            setSelectedInModalTestFolder={setSelectedInModalTestFolder}
                            selectedInModalTestFolder={selectedInModalTestFolder}
                            pageType={pageUrls.testSets}
                        />
                    )}
                </Content>
            ) : (
                <Spinner show />
            )}
        </div>
    )
})
