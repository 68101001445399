import React, { Dispatch, Key, SetStateAction, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import {
    createDateIntervalFilters,
    createSort,
    createSorter,
    getColumnDateSearchProps,
    getColumnSearchProps,
    setPositionOnContextMenu,
    toDateFormat,
} from '@/utils'

import { Button, Table, TablePaginationConfig } from 'antd'
import { TableCell } from './TableCell'
import { TestCaseOptions } from './TestCaseOptions'

import { TypeIcon } from '@/components/Icons'
import { MoreOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreCreateTestSet, StoreProject, StoreTestFolder } from '@/store'

import { dropdownPositionType, IProject, ISort, ITestCase, TableFilterType } from '@/types'
import { EWorkStatus } from '@/enums'
import { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface'

interface ITableColumns {
    (testCases: ITestCase[], sort: ISort[], filter: TableFilterType): ColumnsType<ITestCase> | any
}

interface ITestCaseList {
    testCases: ITestCase[]
    setTestCases: Dispatch<SetStateAction<ITestCase[]>>
    loadData: () => void
    pageNumber: number
    elementsCount: number
    pageSize: number
    setPageNumber: Dispatch<SetStateAction<number>>
    setPageSize: Dispatch<SetStateAction<number>>
    sort: ISort[]
    setSort: Dispatch<SetStateAction<ISort[]>>
    filter: TableFilterType
    setFilter: Dispatch<SetStateAction<TableFilterType>>
    setShowTableMenu: Dispatch<SetStateAction<boolean>>
    selectedRowKeys: Key[]
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    setIsMoveTestCaseToFolderModalOpen?: Dispatch<SetStateAction<boolean>>
    showInModal?: boolean
    selectedProject?: IProject
    searchedName?: string
    setSelectedTestCaseIds?: Dispatch<SetStateAction<string[]>>
    setShowTestSetsModal: Dispatch<SetStateAction<boolean>>
    onSelectAndCreateModalOpen?: () => void
    tableCondition?: boolean
}

export const TestCaseTable = observer((props: ITestCaseList) => {
    const {
        testCases,
        setTestCases,
        loadData,
        pageNumber,
        elementsCount,
        pageSize,
        setPageNumber,
        setPageSize,
        sort,
        setSort,
        filter,
        setFilter,
        setShowTableMenu,
        selectedRowKeys,
        setSelectedRowKeys,
        searchedName,
        setIsMoveTestCaseToFolderModalOpen,
        setSelectedTestCaseIds,
        setShowTestSetsModal,
        onSelectAndCreateModalOpen,
        tableCondition,
    } = props
    const { t } = useTranslation(['page--testCases', 'testCase'])

    const [filterName, setFilterName] = useState<string[]>((filter?.name as string[]) || [])

    const [filterOrderNum, setFilterOrderNum] = useState<undefined | number[]>((filter?.orderNum as number[]) || [])

    const [filterDescription, setFilterDescription] = useState<string[]>((filter?.description as string[]) || [])

    const [dropdownVisibleName, setDropdownVisibleName] = useState<boolean>(false)
    const [dropdownVisibleDescription, setDropdownVisibleDescription] = useState<boolean>(false)
    const [dropdownVisibleOrderNum, setDropdownVisibleOrderNum] = useState(false)

    const [dropdownPosition, setDropdownPosition] = useState<dropdownPositionType>({
        x: 0,
        y: 0,
    })

    const [selectedRecord, setSelectedRecord] = useState<ITestCase | undefined>()

    const allTestCaseIds: string[] = useMemo(() => testCases.map((testCase) => testCase.id), [testCases])

    const onSelectChange = (newSelectedRowKeys: Key[], newSelectedTestCases: ITestCase[]) => {
        const testKeysToExclude = allTestCaseIds.filter((id) => !newSelectedRowKeys.includes(id))

        setSelectedRowKeys(newSelectedRowKeys)
        setShowTableMenu(!!newSelectedRowKeys.length)

        StoreCreateTestSet.setSelectedRowKeys(newSelectedRowKeys, testKeysToExclude)
        StoreCreateTestSet.setSelectedTestCases(newSelectedTestCases, testKeysToExclude)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const handleReset = (title?: string) => {
        switch (title) {
            case 'name':
                setFilterName([])
                setDropdownVisibleName(false)
                break
            case 'description':
                setFilterDescription([])
                setDropdownVisibleName(false)
                break
            case 'orderNum':
                setFilterOrderNum([])
                setDropdownVisibleOrderNum(false)
                break
        }
    }

    const statusFilter = useMemo(
        () =>
            (Object.keys(EWorkStatus) as (keyof typeof EWorkStatus)[]).map((key, index) => {
                return {
                    value: EWorkStatus[key],
                    text: t(`testCase:statuses:${EWorkStatus[key]}`),
                }
            }),
        []
    )

    const getTableColumns: ITableColumns = (testCases, sort) => {
        const sorter = createSorter(sort)
        if (!testCases) return []

        return [
            {
                title: 'ID',
                key: 'orderNum',
                className: 'linkColumn idColumn',
                ...sorter('orderNum'),
                ...getColumnSearchProps(t, {
                    column: 'id',
                    text: 'ID',
                    defaultValue: filterOrderNum,
                    setDefaultValue: setFilterOrderNum,
                    dropdownVisible: dropdownVisibleOrderNum,
                    setDropdownVisible: setDropdownVisibleOrderNum,
                    clearFilters: () => handleReset('orderNum'),
                    setFilter: setFilter,
                }),
                filteredValue: filter?.orderNum,
                render: (orderNum: number, testCase: ITestCase) => <TableCell testCase={testCase} value={orderNum} />,
            },
            {
                title: t('page--testCases:name'),
                key: 'name',
                className: 'linkColumn nameColumn',
                ...sorter('name'),
                ...getColumnSearchProps(t, {
                    column: 'name',
                    text: t('page--testFolders:name'),
                    defaultValue: filterName,
                    setDefaultValue: setFilterName,
                    dropdownVisible: dropdownVisibleName,
                    setDropdownVisible: setDropdownVisibleName,
                    clearFilters: () => handleReset('name'),
                    setFilter: setFilter,
                }),
                filteredValue: filter?.name,
                render: (name: string, testCase: ITestCase) => (
                    <TableCell testCase={testCase} value={name} cellType="name" />
                ),
            },
            {
                title: (
                    <span>
                        <TypeIcon />
                    </span>
                ),
                key: 'isAutomated',
                className: 'linkColumn typeColumn',
                ...sorter('isAutomated'),
                render: (isAutomated: boolean, testCase: ITestCase) => (
                    <TableCell testCase={testCase} value={isAutomated} cellType="automation" />
                ),
            },

            {
                title: t('page--testCases:description'),
                dataIndex: 'description',
                key: 'description',
                className: 'linkColumn descriptionColumn',
                ...getColumnSearchProps(t, {
                    column: 'description',
                    text: t('page--testCases:description'),
                    defaultValue: filterDescription,
                    setDefaultValue: setFilterDescription,
                    dropdownVisible: dropdownVisibleDescription,
                    setDropdownVisible: setDropdownVisibleDescription,
                    clearFilters: () => handleReset('description'),
                    setFilter: setFilter,
                }),
                filteredValue: filter?.description,
                render: (description: string, testCase: ITestCase) => (
                    <TableCell testCase={testCase} value={description} cellType="description" />
                ),
            },
            {
                title: t('page--testCases:priority'),
                key: 'priority',
                className: 'linkColumn priorityColumn',
                ...sorter('priority'),
                render: (priority: string, testCase: ITestCase) => (
                    <TableCell testCase={testCase} value={t(`testCase:priorities.${priority}`)} />
                ),
            },
            {
                title: t('page--testCases:status'),
                key: 'workStatuses',
                className: 'linkColumn statusColumn',
                render: (workStatus: EWorkStatus, testCase: ITestCase) => (
                    <TableCell testCase={testCase} value={workStatus} cellType="status" />
                ),
                ...sorter('workStatus'),
                filters: statusFilter,
            },
            {
                title: t('page--testCases:testFolderName'),
                key: 'testFolderName',
                className: 'linkColumn folderColumn',
                ...sorter('testFolderName'),
                render: (testFolderName: string, testCase: ITestCase) => (
                    <TableCell testCase={testCase} value={testFolderName} cellType="folder" />
                ),
            },

            {
                title: t('page--testCases:lastModifiedDate'),
                key: 'lastModifiedDate',
                className: 'linkColumn dateColumn',
                ...sorter('lastModifiedDate'),
                ...getColumnDateSearchProps(t, {
                    column: 'lastModifiedDate',
                    text: t('page--testCases:lastModifiedDate').toLocaleLowerCase(),
                    isRangeDate: true,
                    withTime: true,
                    filter,
                    setFilter,
                    loadData,
                }),
                filtered: filter?.lastModifiedStartDate || filter?.lastModifiedEndDate,
                render: (lastModifiedDate: number, testCase: ITestCase) => (
                    <TableCell testCase={testCase} value={toDateFormat(lastModifiedDate)} />
                ),
            },
            {
                title: t('page--testCases:createdDate'),
                key: 'createdDate',
                defaultSortOrder: 'descend',
                className: 'linkColumn dateColumn',
                ...sorter('createdDate'),
                ...getColumnDateSearchProps(t, {
                    column: 'createdDate',
                    text: t('page--testCases:createdDate').toLocaleLowerCase(),
                    isRangeDate: true,
                    withTime: true,
                    filter,
                    setFilter,
                    loadData,
                }),
                filtered: filter?.createdStartDate || filter?.createdEndDate,
                render: (createdDate: number, testCase: ITestCase) => (
                    <TableCell testCase={testCase} value={toDateFormat(createdDate)} />
                ),
            },

            {
                title: '',
                key: 'operation',
                className: 'dropdownMenu',
                render: (id: string, testCase: ITestCase) => (
                    <>
                        {!StoreCreateTestSet.isCreatingTestSet && (
                            <TestCaseOptions
                                testCase={testCase}
                                testCases={testCases}
                                setTestCases={setTestCases}
                                loadData={loadData}
                                elementsCount={elementsCount}
                                pageSize={pageSize}
                                pageNumber={pageNumber}
                                button={<Button type="text" icon={<MoreOutlined />} />}
                                setShowTestSetsModal={setShowTestSetsModal}
                                setSelectedRecord={setSelectedRecord}
                                onSelectAndCreateModalOpen={onSelectAndCreateModalOpen}
                                setIsMoveTestCaseToFolderModalOpen={setIsMoveTestCaseToFolderModalOpen}
                                setSelectedTestCaseIds={setSelectedTestCaseIds}
                                setSelectedRowKeys={setSelectedRowKeys}
                                setShowTableMenu={setShowTableMenu}
                                selectedRowKeys={selectedRowKeys}
                            />
                        )}
                    </>
                ),
            },
        ]
    }
    const createFilter = (
        filters: Record<string, FilterValue | null | string>,
        interceptor?: (filters: TableFilterType) => TableFilterType
    ) => {
        const result = Object.fromEntries(
            Object.entries(filters)
                .map(([filter, terms]) => [filter, terms ?? []])
                .filter(([, terms]) => (Array.isArray(terms) ? terms.length > 0 : terms))
        ) as TableFilterType

        searchedName && result?.name.push(searchedName)

        return interceptor?.(createDateIntervalFilters(result, filter)) ?? createDateIntervalFilters(result, filter)
    }

    const columns = useMemo(
        () => getTableColumns(testCases, sort, filter),
        [
            t,
            testCases,
            sort,
            filter,
            dropdownVisibleName,
            dropdownVisibleDescription,
            dropdownVisibleOrderNum,
            filterName,
            filterDescription,
            filterOrderNum,
            StoreProject.id,
        ]
    )

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null | string>,
        sorter: SorterResult<any> | SorterResult<any>[]
    ) => {
        setPageNumber(pagination.current!)
        setPageSize(pagination.pageSize!)
        filters.testFolderId = StoreTestFolder.id

        setFilter(createFilter(filters))
        setSort(createSort(sorter, sort))
    }

    useEffect(() => {
        setPageNumber(1)
        setPageSize(10)
    }, [StoreTestFolder.currentFolderTab, StoreTestFolder.id])

    return (
        <>
            <Table
                columns={columns}
                onChange={handleTableChange}
                dataSource={tableCondition ? undefined : testCases}
                rowKey={'id'}
                onRow={(record) => {
                    return {
                        onContextMenu: (e) =>
                            setPositionOnContextMenu({ e, record, setSelectedRecord, setDropdownPosition }),
                    }
                }}
                pagination={
                    tableCondition
                        ? false
                        : {
                              showSizeChanger: true,
                              pageSize: pageSize,
                              defaultCurrent: pageNumber,
                              total: elementsCount,
                              hideOnSinglePage: true,
                              current: pageNumber,
                          }
                }
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                size="small"
                loading={SpinnerStore.show || tableCondition}
            />

            {selectedRecord && dropdownPosition && !StoreCreateTestSet.isCreatingTestSet && (
                <TestCaseOptions
                    testCase={selectedRecord}
                    testCases={testCases}
                    setTestCases={setTestCases}
                    loadData={loadData}
                    elementsCount={elementsCount}
                    pageSize={pageSize}
                    pageNumber={pageNumber}
                    dropdownPosition={dropdownPosition}
                    setSelectedRecord={setSelectedRecord}
                    setShowTestSetsModal={setShowTestSetsModal}
                    setIsMoveTestCaseToFolderModalOpen={setIsMoveTestCaseToFolderModalOpen}
                    setSelectedTestCaseIds={setSelectedTestCaseIds}
                    onSelectAndCreateModalOpen={onSelectAndCreateModalOpen}
                    setSelectedRowKeys={setSelectedRowKeys}
                    setShowTableMenu={setShowTableMenu}
                    selectedRowKeys={selectedRowKeys}
                />
            )}
        </>
    )
})
