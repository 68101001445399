// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Za7ZHqdDtXLhBwFTE0AF{background:var(--color-itemBackgroundHover);display:flex;flex-direction:column;gap:24px}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Sider/TestCaseStatus.module.scss"],"names":[],"mappings":"AAAA,sBACI,2CAAA,CAEA,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".TestCaseStatus {\n    background: var(--color-itemBackgroundHover);\n\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestCaseStatus": "Za7ZHqdDtXLhBwFTE0AF"
};
export default ___CSS_LOADER_EXPORT___;
