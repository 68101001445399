import React, { Dispatch, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Form, Input, Modal } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'

import { StoreAuthController } from '@/store'

import { IUpdateCurrentUserPassword } from '@/types'

const { Password } = Input

type Props = {
    isModalOpen: boolean
    setModalOpen: Dispatch<SetStateAction<boolean>>
}

export const ModalChangePassword = observer((props: Props) => {
    const { isModalOpen, setModalOpen } = props
    const { t } = useTranslation(['page--user', 'validation'])
    const [form] = Form.useForm()

    const requiredRule = [
        {
            required: true,
            message: t('validation:required'),
        },
    ]

    const onCancel = () => {
        form.resetFields()
        setModalOpen(false)
    }

    const onChangePassword = async () => {
        const passwordData: IUpdateCurrentUserPassword = form.getFieldsValue()

        const resp = await StoreAuthController.changeCurrentUserPassword(passwordData)
        resp === null && onCancel()
    }

    return (
        <Modal
            title={t('page--user:password.changePassword')}
            open={isModalOpen}
            onOk={onChangePassword}
            okText={t('common:buttons.apply')}
            onCancel={onCancel}
            centered
        >
            <Form form={form} layout="vertical">
                <FormItem
                    name="oldPassword"
                    label={t('page--user:password.currentPassword')}
                    rules={requiredRule}
                    className={'hideRequiredMark'}
                >
                    <Password autoComplete="on" />
                </FormItem>
                <FormItem
                    name="newPassword"
                    label={t('page--user:password.newPassword')}
                    rules={requiredRule}
                    className={'hideRequiredMark'}
                >
                    <Password autoComplete="on" />
                </FormItem>
            </Form>
        </Modal>
    )
})
