import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { observer } from 'mobx-react'

import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'

import { CaretDownOutlined } from '@ant-design/icons'

import { StoreProject, StoreWorkspace } from '@/store'

import { IProject } from '@/types'

import styles from './ProjectListDropdown.module.scss'

type Props = {
    setSelectedProject: Dispatch<SetStateAction<IProject>>
    projectList: IProject[]
    setProjectList: Dispatch<SetStateAction<IProject[]>>
}

export const ProjectListDropdown = observer((props: Props) => {
    const { setSelectedProject, setProjectList, projectList } = props

    const [items, setItems] = useState<MenuProps['items']>([])

    const getAllProjects = async () => {
        if (!projectList.length) {
            const props = {
                filter: { workspaceId: StoreWorkspace.id, projectName: [], projectDescription: [], isDeleted: false },
            }
            setProjectList((await StoreProject.getProjects(props)).result)
        }
    }

    useEffect(() => {
        const newItems = projectList.map((project) => {
            return {
                key: project.id,
                label: project.projectName,
                onClick: () => setSelectedProject(project),
            }
        })
        setItems(newItems)
    }, [projectList])

    return (
        <Dropdown menu={{ items }} trigger={['click']}>
            <CaretDownOutlined className={styles.NavIcon} onClick={getAllProjects} />
        </Dropdown>
    )
})
