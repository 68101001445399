import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, Modal } from 'antd'

import { SpinnerStore, StoreTestCase, StoreWorkspace } from '@/store'
import { pageUrls } from '@/consts'

import { settingsPageType } from '@/types'

type PageType = settingsPageType | typeof pageUrls.testCase

type Props = {
    isModalSaveOpen: boolean
    handleDontSave: () => void
    handleSave: () => void
    handleCancel: () => void
    pageType: PageType
}

export const ModalSave = observer((props: Props) => {
    const { isModalSaveOpen, handleDontSave, handleSave, handleCancel, pageType } = props
    const { t } = useTranslation(['common', 'testCase', 'page--workspaceSettings'])

    return (
        <Modal
            title={
                pageType === pageUrls.testCase
                    ? t('testCase:exitGuard', { name: StoreTestCase.name })
                    : t('page--workspaceSettings:exitGuard', { name: StoreWorkspace.workspaceName })
            }
            open={isModalSaveOpen}
            onOk={handleSave}
            onCancel={handleCancel}
            centered
            maskClosable={false}
            footer={[
                <Button key="cancel" onClick={handleCancel}>
                    {t('common:buttons.cancel')}
                </Button>,
                <Button key="dontSave" type="primary" onClick={handleDontSave} disabled={SpinnerStore.show}>
                    {t('common:buttons.dontSave')}
                </Button>,
                <Button key="save" type="primary" onClick={handleSave} disabled={SpinnerStore.show}>
                    {t('common:buttons.save')}
                </Button>,
            ]}
        ></Modal>
    )
})
