import React from 'react'

import { observer } from 'mobx-react'

import { MsAttachmentApi, MsExecutorApi, MsProjectApi } from '@/api'

import { Attachment } from '@/components/Attachment'

import { SpinnerStore, StoreTestCase } from '@/store'
import { apiUrls } from '@/consts'

import { IFileToUpload, IMixedAttachments } from '@/types'
import { EEntityType } from '@/enums'

type Props = {
    file: IMixedAttachments
    hideDeleteCross?: boolean
}

export const TestCaseAttachment = observer((props: Props) => {
    const { file, hideDeleteCross } = props

    const onRemoveAttachment = async (bindId: string, entityType: EEntityType | undefined) => {
        SpinnerStore.setShow(true)

        if (entityType === EEntityType.EXECUTION_STEP || entityType === EEntityType.EXECUTION_CASE) {
            await MsExecutorApi.deleteItem(bindId, apiUrls.objectAttachment)
        } else {
            await MsProjectApi.deleteItem(bindId, apiUrls.objectAttachment)
        }

        const updatedAttachments = StoreTestCase.attachments.filter((attachment) => attachment.bindId !== bindId)
        StoreTestCase.setAttachments(updatedAttachments)
        SpinnerStore.setShow(false)
    }

    const onRemoveFileToUpload = async (file: IFileToUpload) => {
        const updatedAttachments = StoreTestCase.filesToUpload.filter(
            (fileToUpload) =>
                !(
                    fileToUpload.uid === file.uid &&
                    fileToUpload.existingObjectId === file.existingObjectId &&
                    fileToUpload.attachmentType === file.attachmentType
                )
        )

        StoreTestCase.setFilesToUpload(updatedAttachments)
    }

    const handleDelete = (file: IMixedAttachments) => {
        //обработка загруженных файлов
        if ('bindId' in file && file.bindId && !SpinnerStore.show) {
            if (!StoreTestCase.executionTestCase) {
                //положить в корзину при удалении в тест-кейсе
                StoreTestCase.setFilesToDelete([...StoreTestCase.filesToDelete, file])
                const updatedAttachments = StoreTestCase.attachments.filter(
                    (attachment) => attachment.bindId !== file.bindId
                )
                StoreTestCase.setAttachments(updatedAttachments)
                StoreTestCase.setIsSaved(false)
                return
            } else {
                //удаление сразу без корзины по одному в тест-цикле
                const entityType = 'entityType' in file ? file.entityType : undefined
                return onRemoveAttachment(file.bindId, entityType)
            }
        }

        //обработка загружаемых файлов
        if ('uid' in file && file.uid) {
            return onRemoveFileToUpload(file as IFileToUpload)
        }
    }

    const handleClick = async (file: IMixedAttachments, fileName: string) => {
        if (!('id' in file)) {
            return
        }

        SpinnerStore.setShow(true)
        await MsAttachmentApi.getAttachment(file.id, fileName)
        SpinnerStore.setShow(false)
    }

    return (
        <Attachment
            file={file}
            hideDeleteCross={hideDeleteCross}
            onDelete={handleDelete}
            isUploaded={'id' in file}
            onAttachmentClick={handleClick}
        />
    )
})
