// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._8cKu3eJByaZSJhBxy6b_{display:flex;justify-content:space-between;align-items:baseline;border-bottom:1px solid var(--color-other-divider6)}._8cKu3eJByaZSJhBxy6b_ .ant-typography{margin:0}._8cKu3eJByaZSJhBxy6b_ ._0G0d8XlQnPTwkN1Eh9Tg .eUhRsmEgJLkeqMhGu_pt{align-items:baseline;display:flex;gap:9px;margin-bottom:20px}._8cKu3eJByaZSJhBxy6b_ ._0G0d8XlQnPTwkN1Eh9Tg .eUhRsmEgJLkeqMhGu_pt .MgUVQYp9Mt2cbMp53LC_ svg{font-size:14px;color:var(--color-text-secondary45)}", "",{"version":3,"sources":["webpack://./src/pages/CreateTestSet/TestFolderHeader/TestFolderHeader.module.scss"],"names":[],"mappings":"AAAA,uBACI,YAAA,CACA,6BAAA,CACA,oBAAA,CACA,mDAAA,CAGI,uCACI,QAAA,CAKJ,oEACI,oBAAA,CACA,YAAA,CACA,OAAA,CACA,kBAAA,CAEA,8FACI,cAAA,CACA,mCAAA","sourcesContent":[".TestFolderHeader {\n    display: flex;\n    justify-content: space-between;\n    align-items: baseline;\n    border-bottom: 1px solid var(--color-other-divider6);\n\n    :global {\n        .ant-typography {\n            margin: 0;\n        }\n    }\n\n    .TestFolderHeaderLeft {\n        .Title {\n            align-items: baseline;\n            display: flex;\n            gap: 9px;\n            margin-bottom: 20px;\n\n            .NavIcon svg {\n                font-size: 14px;\n                color: var(--color-text-secondary45);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestFolderHeader": "_8cKu3eJByaZSJhBxy6b_",
	"TestFolderHeaderLeft": "_0G0d8XlQnPTwkN1Eh9Tg",
	"Title": "eUhRsmEgJLkeqMhGu_pt",
	"NavIcon": "MgUVQYp9Mt2cbMp53LC_"
};
export default ___CSS_LOADER_EXPORT___;
