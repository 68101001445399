import React, { Dispatch, MouseEvent, SetStateAction } from 'react'

import { dropdownPositionType, IExecutionCase, IProject, ITestCase, ITestFolder, ITestSet, IWorkspace } from '@/types'

type Props = {
    e: MouseEvent<MouseEvent, any>
    record: IWorkspace | IProject | ITestFolder | ITestCase | ITestSet | IExecutionCase | undefined
    setSelectedRecord: Dispatch<
        SetStateAction<IWorkspace | IProject | ITestFolder | ITestCase | ITestSet | undefined | any>
    >
    setDropdownPosition: Dispatch<SetStateAction<dropdownPositionType>>
}

export const setPositionOnContextMenu = (props: Props) => {
    const { e, record, setSelectedRecord, setDropdownPosition } = props
    e.preventDefault()

    setSelectedRecord(record)

    const position = {
        x: e.clientX,
        y: e.clientY,
    }

    setDropdownPosition(position)
}
