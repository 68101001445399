// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tIcNIAktppfFrRXPuonX{display:flex;align-items:center;justify-content:space-between;padding:12px 32px;height:32px;background:var(--color-primary);color:#fff;position:relative;z-index:2;box-sizing:content-box}.tIcNIAktppfFrRXPuonX .mUnU0uzxdeJ77dNw0xQ6{display:flex;gap:100px;align-items:center}.tIcNIAktppfFrRXPuonX .ZYec1CPPw2hwYPlMzvbh,.tIcNIAktppfFrRXPuonX .f7aLtkn0GsMRR6UsUjdg{display:flex;justify-content:space-between;align-items:center;gap:16px;color:#fff;cursor:pointer}.F1J6lURJpdeC3EkFzUVk{background:var(--color-text-primary)}", "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,iBAAA,CACA,WAAA,CACA,+BAAA,CACA,UAAA,CACA,iBAAA,CACA,SAAA,CACA,sBAAA,CAEA,4CACI,YAAA,CACA,SAAA,CACA,kBAAA,CAGJ,wFAEI,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,QAAA,CACA,UAAA,CACA,cAAA,CAIR,sBACI,oCAAA","sourcesContent":[".Header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 12px 32px;\n    height: 32px;\n    background: var(--color-primary);\n    color: white;\n    position: relative;\n    z-index: 2;\n    box-sizing: content-box;\n\n    .HeaderLeftSide {\n        display: flex;\n        gap: 100px;\n        align-items: center;\n    }\n\n    .RightMenu,\n    .Profile {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        gap: 16px;\n        color: white;\n        cursor: pointer;\n    }\n}\n\n.HeaderDark {\n    background: var(--color-text-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Header": "tIcNIAktppfFrRXPuonX",
	"HeaderLeftSide": "mUnU0uzxdeJ77dNw0xQ6",
	"RightMenu": "ZYec1CPPw2hwYPlMzvbh",
	"Profile": "f7aLtkn0GsMRR6UsUjdg",
	"HeaderDark": "F1J6lURJpdeC3EkFzUVk"
};
export default ___CSS_LOADER_EXPORT___;
