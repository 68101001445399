import React from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Layout, Radio, Tooltip } from 'antd'
import type { RadioChangeEvent } from 'antd'
import { UploadTestCaseAttachments } from '@/components/TestCaseComponent/UploadTestCaseAttachments/UploadTestCaseAttachments'
import { GeneralStepCard } from './GeneralStepCard'
import { TestCaseComments } from './TestCaseComments'
import { TestCaseSettings } from './TestCaseSettings'
import { TestCaseStatus } from './TestCaseStatus'

import {
    CarryOutOutlined,
    CommentOutlined,
    PaperClipOutlined,
    SettingOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons'

import { StoreTestCase } from '@/store'

import { EAttachment, EEntityType } from '@/enums'

import styles from './TestCaseSider.module.scss'

const { Sider } = Layout

export const TestCaseSider = observer(() => {
    const { t } = useTranslation(['common'])
    const sidebarTypeOptions = [
        { label: <SettingOutlined />, value: 'settings' },
        {
            label: (
                <Tooltip title={`Общие шаги (${t('common:inDevelopment')})`}>
                    <UnorderedListOutlined />
                </Tooltip>
            ),
            value: 'list',
            disabled: true,
        },
        { label: <PaperClipOutlined />, value: 'upload' },
        {
            label: (
                <Tooltip title={`Обсуждение тест-кейса (${t('common:inDevelopment')})`}>
                    <CommentOutlined />
                </Tooltip>
            ),
            value: 'comments',
            disabled: true,
        },
    ]

    const executionCaseSidebarTypeOptions = [
        { label: <SettingOutlined />, value: 'settings' },
        { label: <CarryOutOutlined />, value: 'testCaseStatus' },
        {
            label: (
                <Tooltip title={`Обсуждение тест-кейса (${t('common:inDevelopment')})`}>
                    <CommentOutlined />
                </Tooltip>
            ),
            value: 'comments',
            disabled: true,
        },
    ]

    const changeSidebarType = ({ target: { value } }: RadioChangeEvent) => {
        StoreTestCase.setSidebarType(value)
    }

    const messages = [
        {
            id: '1',
            author: 'Roman Iglov',
            message: 'Я переделал тест-кейс!',
            avatar: 'https://robohash.org/FGE.png?set=set2',
            ownMessage: true,
        },
        {
            id: '2',
            author: 'Mihail Gromovoy',
            message: 'А кто тебя об этом просил? Скажи мне...',
            avatar: 'https://robohash.org/5G8.png?set=set2',
            ownMessage: false,
        },
    ]
    return (
        <Sider
            className={cn(styles.Sider, { [styles.ExecutionCaseSider]: StoreTestCase.executionTestCase })}
            theme="light"
        >
            <div className={styles.MenuTabs}>
                <Radio.Group
                    className={styles.MenuButtons}
                    options={StoreTestCase.executionTestCase ? executionCaseSidebarTypeOptions : sidebarTypeOptions}
                    onChange={changeSidebarType}
                    value={StoreTestCase.sidebarType}
                    optionType="button"
                    buttonStyle="solid"
                />
            </div>
            <div className={styles.SideBarMain}>
                {StoreTestCase.sidebarType === 'settings' && <TestCaseSettings />}
                {StoreTestCase.sidebarType === 'testCaseStatus' && <TestCaseStatus />}
                {StoreTestCase.sidebarType === 'list' && <GeneralStepCard />}
                {StoreTestCase.sidebarType === 'upload' && (
                    <div className={styles.SideUpload}>
                        <UploadTestCaseAttachments
                            existingObjectId={StoreTestCase.id}
                            attachmentType={EAttachment.TEST_CASE}
                            entityType={EEntityType.TEST_CASE}
                        />
                    </div>
                )}
                {StoreTestCase.sidebarType === 'comments' && <TestCaseComments messages={messages} />}
            </div>
        </Sider>
    )
})
