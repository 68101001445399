import React from 'react'

import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { Typography } from 'antd'

import { CaretLeftOutlined } from '@ant-design/icons'

import { pageUrls } from '@/consts'

import styles from './SideBarTitle.module.scss'

const { Text } = Typography

type Props = {
    folderId: string
    folderName: string
    projectId: string
}

export const SideBarTitle = observer((props: Props) => {
    const { folderId, folderName, projectId } = props

    return (
        <Link
            className={styles.SideBarTitle}
            draggable="false"
            to={`${pageUrls.project}/${projectId}${pageUrls.testSets}?testFolder=${folderId}`}
        >
            <CaretLeftOutlined />
            <Text className={styles.Title} ellipsis={{ tooltip: folderName }}>
                {folderName}
            </Text>
        </Link>
    )
})
