import React, { ClipboardEvent } from 'react'

import { DateTime } from 'luxon'

import { notification } from 'antd'
import { checkIfFileNameUnique } from './checkIfFileNameUnique'

import { StoreTestCase } from '@/store'
import { attachmentMaxSizeMb, unitsOfInformation } from '@/consts'

import { IFileToUpload } from '@/types'
import { EAttachment, EEntityType, EStepType } from '@/enums'

type Props = {
    event: ClipboardEvent<HTMLInputElement> | any
    needUpload: boolean
    existingObjectId: string
    attachmentType: EAttachment | undefined
    stepType: EStepType | undefined
    isExecutionTestCase: boolean
    isNewStep: boolean
    filesToUpload: IFileToUpload[]
    screenshotTranslation: string
    duplicateFileNameMessage: string
    attachmentMaxSizeMessage: string
}

//вставка из буфера файлов и скриншотов, а так же лроп файлов
export const handlePasteAndDropFiles = (props: Props) => {
    const {
        event,
        needUpload,
        existingObjectId,
        attachmentType,
        stepType,
        isExecutionTestCase,
        isNewStep,
        filesToUpload,
        screenshotTranslation,
        duplicateFileNameMessage,
        attachmentMaxSizeMessage,
    } = props

    if (!needUpload) {
        return
    }

    const items = event?.clipboardData?.items || event?.dataTransfer?.items

    const setFileName = (file: File) => {
        const isScreenShot =
            (event?.clipboardData?.items && file.name === 'изображение.png') || file.name === 'image.png'
        if (!isScreenShot) {
            const isFileNameUnique = checkIfFileNameUnique(file, existingObjectId, attachmentType)

            if (isFileNameUnique) {
                return file.name
            } else {
                notification.error({
                    message: `${duplicateFileNameMessage}: "${file.name}"`,
                    placement: 'bottomRight',
                })
                return null
            }
        } else {
            return `${screenshotTranslation} (${DateTime.now().toFormat('dd.LL.yyyy HH:mm:ss')}).png`
        }
    }

    for (let i = 0; i < items.length; i++) {
        if (items[i].kind === 'file') {
            const file: File = items[i].getAsFile()

            const isFileSizeAllowed = (file: File) => file.size / unitsOfInformation.BytesInMb <= attachmentMaxSizeMb
            if (!isFileSizeAllowed(file)) {
                notification.error({
                    message: attachmentMaxSizeMessage,
                    placement: 'bottomRight',
                })
            } else {
                const fileName = setFileName(file)
                if (fileName) {
                    const fileToUpload = new File([file], fileName, {
                        type: file.type,
                    })

                    const screenshotWithTypes = {
                        originFileObj: fileToUpload,
                        lastModified: fileToUpload.lastModified,
                        name: fileToUpload.name,
                        size: fileToUpload.size,
                        type: fileToUpload.type,
                        uid: fileToUpload.lastModified,
                        existingObjectId: existingObjectId,
                        attachmentType: attachmentType,
                        entityType: isExecutionTestCase ? EEntityType.EXECUTION_STEP : EEntityType.STEP,
                        stepType: stepType,
                        isNewStep: isNewStep,
                    } as unknown as IFileToUpload //TODO: в скриншоте нет uid из-за чего ошибка типа

                    StoreTestCase.setFilesToUpload([...filesToUpload, screenshotWithTypes])
                    StoreTestCase.setIsSaved(false)
                }
            }
        }
    }
}
