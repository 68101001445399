import React, { ReactNode, useMemo } from 'react'

import i18n from 'i18next'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react'

import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'

import { PreferencesStore } from '@/store'

export const LanguageDropdown = observer((props: { children: ReactNode }) => {
    const items: MenuProps['items'] = useMemo(
        () => PreferencesStore.languages.map((lang) => ({ key: lang.locale, label: lang.name })),
        []
    )

    const onClick: MenuProps['onClick'] = ({ key }) => {
        i18n.changeLanguage(key)
        runInAction(() => (PreferencesStore.locale = key))
    }

    return (
        <Dropdown menu={{ items, onClick, selectedKeys: [PreferencesStore.locale] }} placement="bottom">
            {props.children}
        </Dropdown>
    )
})
