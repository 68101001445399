import { Key } from 'react'

import { observable } from 'mobx'

import { useAuthorizedAPI } from '@/api/ms-auth-api'

import { apiUrls, baseApiUrl, showAllPages } from '@/consts'

import {
    ICreateJiraIssue,
    ICreateTestSet,
    IDeleteExecutionCases,
    IExecutionCase,
    IExecutionProcessRequest,
    IExecutionStep,
    IJiraCredentials,
    IObjectAttachments,
    IObjectFilter,
    ISelectTestFolder,
    ITestFolder,
    IUpdateTestSet,
} from '@/types'
import { EExecutionTestSetStatus, EOrder } from '@/enums'
import type { RcFile, UploadFile } from 'antd/es/upload/interface'

const baseURL = `${baseApiUrl}/ms-executor/executor`

const api = useAuthorizedAPI(baseURL)

export const MsExecutorApi = observable({
    async save(testSet: ICreateTestSet) {
        return (await api.post(`${apiUrls.testSet}/prepare-execution`, testSet)).data
    },

    async filter(props: {
        page?: number
        pageSize?: number
        filter: Record<string, string[] | string>
        sort: { field: string; direction: EOrder }[]
    }) {
        const request = {
            pageSize: props.pageSize || showAllPages,
            page: (props.page || 1) - 1,
            filter: props.filter,
            sort: props.sort.length > 0 ? props.sort : [{ field: 'orderNum', direction: EOrder.DESC }],
        }

        return (await api.post(`${apiUrls.testSet}/filter`, request)).data
    },

    async getItem(id: string, specificApiUrl: apiUrls) {
        return await api.get(`${specificApiUrl}/${id}`)
    },

    async getTestSetFolders(id: string, specificApiUrl: apiUrls): Promise<ITestFolder[]> {
        const response = await api.get(`${specificApiUrl}?projectId=${id}`)
        return response.data
    },

    async createTestSetFolder(testSetFolder: ITestFolder, specificApiUrl: apiUrls) {
        const resp = (await api.post(specificApiUrl, testSetFolder)).data
        return resp
    },

    async updateTestSetFolder(item: Partial<ITestFolder>, specificApiUrl: apiUrls) {
        const { id } = (await api.put(specificApiUrl, item)).data
        return id
    },

    async copyTestSetFolder(props: ISelectTestFolder) {
        const resp = (await api.post(`${apiUrls.testSetFolder}/copy`, props)).data
        return resp
    },

    async moveTestSetFolder(props: ISelectTestFolder) {
        await api.post(`${apiUrls.testSetFolder}/move`, props)
    },

    async deleteItem(id: Key | string, specificApiUrl: apiUrls) {
        await api.delete(`${specificApiUrl}/${id}`)
    },

    async changeTestSetStatus(testSetId: string, status: EExecutionTestSetStatus) {
        return await api.post(`${apiUrls.testSet}/change-status?testSetId=${testSetId}&status=${status}`)
    },

    async updateTestSet(props: IUpdateTestSet) {
        return await api.put(`${apiUrls.testSet}/update-additional-fields`, props)
    },

    async changeTestSetAssigned(testSetId: string, userId: string) {
        return await api.post(`${apiUrls.testSet}/assigned-execution?testSetId=${testSetId}&testerId=${userId}`)
    },

    async setExecutionStep(executionSteps: IExecutionStep) {
        return (await api.put(`${apiUrls.executionStep}`, executionSteps)).data
    },

    async launchTestCase(executionCaseId: string) {
        return await api.post(`${apiUrls.executionCase}/launch?executionCaseId=${executionCaseId}`)
    },

    async finishTestCase(executionSteps: IExecutionCase) {
        return (await api.put(`${apiUrls.executionCase}`, executionSteps)).data
    },

    async deleteExecutionTestCases(testSetId: string, casesId: IDeleteExecutionCases) {
        return (await api.post(`${apiUrls.testSet}/remove-test-cases-from-test-set?testSetId=${testSetId}`, casesId))
            .data
    },

    async setCompletedTimestamp(executionCaseId: string) {
        return (await api.post(`${apiUrls.executionCase}/set-completed-timestamp?id=${executionCaseId}`)).data
    },

    async addToTestSet(testSetId: string, testCasesId: IExecutionProcessRequest) {
        return await api.post(`${apiUrls.testSet}/add-test-cases-to-test-set?testSetId=${testSetId}`, testCasesId)
    },

    async getProjectIntegrationJiraInfo(projectId: string) {
        return await api.get(`${apiUrls.jiraIntegration}?projectId=${projectId}`)
    },

    async getJiraIntegrationAvailability(projectId: string) {
        return await api.post(`${apiUrls.jiraIntegration}/integration/check-availability?projectId=${projectId}`)
    },

    async getJiraIssues(projectId: string, ids: string[]) {
        const data = {
            collection: ids,
        }
        return await api.post(`${apiUrls.jiraIssue}/get-issues?projectId=${projectId}`, data)
    },

    async createJiraIssue(issue: ICreateJiraIssue) {
        return await api.post(`${apiUrls.jiraIssue}`, issue)
    },

    async attachFilesToJiraIssue(issueId: string, projectId: string, files: UploadFile[]) {
        const formData = new FormData()
        files.map((file) => formData.append('files', file.originFileObj as RcFile))

        const config = {
            headers: { 'content-type': 'multipart/form-data' },
        }

        const res = await api.post(
            `${apiUrls.jiraIssue}/${issueId}/attach-files?projectId=${projectId}`,
            formData,
            config
        )
        return res
    },

    async createJiraIntegration(data: IJiraCredentials) {
        return await api.post(apiUrls.jiraIntegration, data)
    },

    async updateJiraIntegration(data: IJiraCredentials) {
        return await api.put(apiUrls.jiraIntegration, data)
    },

    async deleteJiraIntegration(projectId: string) {
        return await api.delete(`${apiUrls.jiraIntegration}?projectId=${projectId}`)
    },

    async checkJiraIntegration(data: IJiraCredentials) {
        return await api.post(`${apiUrls.jiraIntegration}/check`, data)
    },

    async getScrumBoards(projectId: string) {
        return await api.get(`${apiUrls.jiraBoard}?projectId=${projectId}`)
    },

    async setScrumBoard(projectId: string, scrumBoardId: string) {
        return await api.post(`${apiUrls.jiraBoard}?projectId=${projectId}&scrumBoardId=${scrumBoardId}`)
    },

    async getJiraCustomFields(projectId: string) {
        return await api.get(`${apiUrls.jiraCustomFields}?projectId=${projectId}`)
    },

    async getJiraDictData(props: {
        apiUrl: apiUrls
        filter: Record<string, string[] | string>
        sort: { field: string; direction: EOrder }[]
        page?: number
        pageSize?: number
    }) {
        const request = {
            pageSize: props.pageSize || showAllPages,
            page: (props.page || 1) - 1,
            filter: props.filter,
            sort: props.sort.length > 0 ? props.sort : [{ field: 'name', direction: EOrder.ASC }],
        }
        const data = (await api.post(`${props.apiUrl}/filter`, request)).data.result
        return data
    },

    async bindAttachments(attachments: IObjectAttachments) {
        return (await api.post(`${apiUrls.objectAttachment}/create-multiple-attachments`, attachments)).data
    },

    async getExeAttachments(props?: {
        page?: number
        pageSize?: number
        filter?: Partial<IObjectFilter>
        sort?: { field: string; direction: EOrder }[]
    }) {
        const request = {
            pageSize: props?.pageSize || showAllPages,
            page: (props?.page || 1) - 1,
            filter: props?.filter || {},
            sort: props?.sort && props?.sort.length > 0 ? props.sort : [],
        }

        return (await api.post(`${apiUrls.objectAttachment}/filter`, request)).data.result
    },
})
