import { Modal } from 'antd'

export type ModalInfoProps = {
    title: string
    modalType: 'success' | 'warning' | 'error'
    content?: string
}

export const ModalInfo = ({ title, modalType, content }: ModalInfoProps) => {
    switch (modalType) {
        case 'warning':
            Modal.warning({
                title: title,
                content: content,
                centered: true,
            })
            break
        case 'success':
            Modal.success({
                title: title,
                content: content,
                centered: true,
            })
            break
        case 'error':
            Modal.error({
                title: title,
                content: content,
                centered: true,
            })
            break
    }
    return true
}
