import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Form, Input, Modal } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'

import { StoreTestFolder, StoreTestSet } from '@/store'
import { pageUrls } from '@/consts'

import { ITestFolder } from '@/types'

type Props = {
    projectId: string
    isModalCreateFolderOpen: boolean
    setIsModalCreateFolderOpen: Dispatch<SetStateAction<boolean>>
    testFolders: ITestFolder[]
    setTestFolders: Dispatch<SetStateAction<ITestFolder[]>>
    selectedFolderId?: string
    setSelectedFolderId?: Dispatch<SetStateAction<string>>
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
}

export const CreateNewFolderModal = observer((props: Props) => {
    const {
        projectId,
        isModalCreateFolderOpen,
        setIsModalCreateFolderOpen,
        testFolders,
        setTestFolders,
        selectedFolderId,
        setSelectedFolderId,
        pageType,
    } = props

    const { t } = useTranslation(['page--testFolders', 'createModal'])
    const [form] = Form.useForm()
    const [disabled, disable] = useState(true)

    const onChangename = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const reg = /[А-Яа-яЁё\w]/
        disable(!reg.test(value))
    }

    const reset = () => {
        form.resetFields()
        setSelectedFolderId && setSelectedFolderId('')
        StoreTestFolder.setCreatingNewFolder(false)
        setIsModalCreateFolderOpen(false)
    }

    const createFolder = async () => {
        const props = {
            ...form.getFieldsValue(),
            projectId: projectId,
            parentFolderId: !StoreTestFolder.isCreatingNewFolder && selectedFolderId ? selectedFolderId : null,
            parentId: !StoreTestFolder.isCreatingNewFolder && selectedFolderId ? selectedFolderId : null,
        } as ITestFolder

        if (pageType === pageUrls.testFolder) {
            const resp = await StoreTestFolder.createTestFolder(props)

            const updatedTestFolders = [
                ...testFolders,
                {
                    ...resp,
                } as ITestFolder,
            ]
            setTestFolders(updatedTestFolders)
        }

        if (pageType === pageUrls.testSets) {
            const resp = await StoreTestSet.createTestSetFolder(props)

            const updatedTestSetFolders = [
                ...testFolders,
                {
                    ...resp,
                } as ITestFolder,
            ]
            setTestFolders(updatedTestSetFolders)
        }
        reset()
    }

    const handleCancel = () => {
        reset()
    }

    return (
        <Modal
            title={t('page--testFolders:enterFolderName')}
            open={isModalCreateFolderOpen}
            onOk={createFolder}
            onCancel={handleCancel}
            okButtonProps={{ disabled: disabled }}
            centered
            zIndex={1000}
        >
            <Form form={form} layout="vertical">
                <FormItem name="name" label={t('createModal:name')}>
                    <Input showCount maxLength={50} onChange={onChangename} />
                </FormItem>
            </Form>
        </Modal>
    )
})
