import { Key } from 'react'

import { makeAutoObservable } from 'mobx'

import { MsProjectApi } from '@/api/ms-project-api'

import { showMessage } from '@/utils/notification'

import { PreferencesStore, SpinnerStore, StoreProject } from '@/store'
import { apiUrls, folderTabs } from '@/consts'

import { DataNodeWithName, ISelectTestFolder, ISort, ITestFolder, TableFilterType } from '@/types'
import { EOrder } from '@/enums'

export const StoreTestFolder = makeAutoObservable({
    id: '',
    testFolderName: '',
    selectedFolderName: '',
    showSelectedTestCaseId: '',
    showSelectedFolderId: '',
    description: '',
    clientId: '',
    testFolders: [] as ITestFolder[],
    foldersTree: [] as DataNodeWithName[],
    archiveFolders: [] as DataNodeWithName[],
    currentFolderTab: folderTabs.testCases as keyof typeof folderTabs,
    isCreatingNewFolder: false,
    toggleUpdateFoldersTree: false,
    expandedKeys: [] as Key[],
    latestSearchProps: {
        filter: { projectId: StoreProject.id, isDeleted: false },
        sort: [],
    } as {
        page?: number
        filter: TableFilterType
        sort: ISort[]
        pageSize?: number
    },

    setExpandedKeys(value: Key[]) {
        this.expandedKeys = value
    },

    setTestFolderId(value: string) {
        this.id = value
    },

    setCreatingNewFolder(value: boolean) {
        this.isCreatingNewFolder = value
    },

    setTestFolders(value: ITestFolder[]) {
        this.testFolders = value
    },

    async getTestFolders(projectId: string, archiveFolder?: boolean) {
        SpinnerStore.setShow(true)
        const testFolders: ITestFolder[] = await MsProjectApi.getProjectItems(
            projectId,
            apiUrls.testFolder,
            archiveFolder
        )

        //после удаления / копирования ТК делается повторный запрос и в нем приходит в разном порядке из-за чего папка плавает
        const sortedFolders = testFolders.sort((a, b) => (a.id < b.id ? -1 : b.id > a.id ? 1 : 0))

        SpinnerStore.setShow(false)

        return sortedFolders
    },

    setSelectedFolderName(value: string) {
        this.selectedFolderName = value
    },

    setCurrentFolderTab(value: keyof typeof folderTabs) {
        this.currentFolderTab = value
    },

    setShowSelectedFolderId(value: string) {
        this.showSelectedFolderId = value
    },

    setFoldersTree(value: DataNodeWithName[]) {
        this.foldersTree = value
    },

    setArchiveFolders(value: DataNodeWithName[]) {
        this.archiveFolders = value
    },

    setShowSelectedTestCaseId(value: string) {
        this.showSelectedTestCaseId = value
    },

    updateFoldersTree() {
        this.toggleUpdateFoldersTree = !this.toggleUpdateFoldersTree
    },

    async filterFolders(props: {
        page?: number
        filter: Record<string, string[] | string>
        sort: { field: string; direction: EOrder }[]
        pageSize?: number
    }) {
        SpinnerStore.setShow(true)
        const testFolderList = await MsProjectApi.filter({
            ...props,
            specificApiUrl: apiUrls.testFolder,
        })
        SpinnerStore.setShow(false)
        return testFolderList
    },

    async createTestFolder(testFolder: ITestFolder) {
        SpinnerStore.setShow(true)
        const resp = await MsProjectApi.saveAndGetItem(testFolder, apiUrls.testFolder)

        PreferencesStore.t('notification:success.testFolderCreated', { name: testFolder.name }).then((message) => {
            showMessage('success', message)
        })

        SpinnerStore.setShow(false)
        return resp
    },

    async copyTestFolder(props: ISelectTestFolder) {
        SpinnerStore.setShow(true)

        const copyTestFolderProps = {
            parent: props.parent,
            parentId: props.parentId,
            folderId: props.folderId,
        }

        const resp = await MsProjectApi.copyTestFolder(copyTestFolderProps)

        PreferencesStore.t('notification:success.testFolderCreated', { name: props.folderName }).then((message) => {
            showMessage('success', message)
        })

        SpinnerStore.setShow(false)
        return resp
    },

    async unarchiveTestFolder(props: ISelectTestFolder, onMessageClick?: any) {
        SpinnerStore.setShow(true)

        const unarchiveTestFolderProps = {
            parent: props.parent,
            parentId: props.parentId,
            folderId: props.folderId,
        }

        const resp = await MsProjectApi.unarchiveTestFolder(unarchiveTestFolderProps)

        PreferencesStore.t('notification:success.testFolderRestored', { name: props.folderName }).then((message) => {
            showMessage('success', message, () => onMessageClick())
        })

        SpinnerStore.setShow(false)
        return resp
    },

    async moveTestFolder(props: ISelectTestFolder) {
        SpinnerStore.setShow(true)

        const moveTestFolderProps = {
            parent: props.parent,
            parentId: props.parentId,
            folderId: props.folderId,
        }

        const resp = await MsProjectApi.moveTestFolder(moveTestFolderProps)

        PreferencesStore.t('notification:success.testFolderMoved', { name: props.folderName }).then((message) => {
            showMessage('success', message)
        })

        SpinnerStore.setShow(false)
        return resp
    },

    async getTestFolder(id: string): Promise<ITestFolder> {
        SpinnerStore.setShow(true)
        const testFolderInfo = await MsProjectApi.getItem(id, apiUrls.testFolder)
        SpinnerStore.setShow(false)

        return testFolderInfo
    },

    async deleteTestFolder(id: string, name: string) {
        SpinnerStore.setShow(true)
        await MsProjectApi.deleteItem(id, apiUrls.testFolder)
        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.testFolderDeleted', { name: name }).then((message) => {
            showMessage('success', message)
        })
    },

    async deleteTestFolders(testFolders: Key[]) {
        SpinnerStore.setShow(true)
        Promise.all(
            testFolders.map(async (id) => {
                await MsProjectApi.deleteItem(id, apiUrls.testFolder)
            })
        )
        SpinnerStore.setShow(false)
        PreferencesStore.t('notification:success.allTestFoldersDeleted', {}).then((message) => {
            showMessage('success', message)
        })
    },

    async updateTestFolder(testFolder: Partial<ITestFolder>) {
        SpinnerStore.setShow(true)

        await MsProjectApi.updateItem(testFolder, apiUrls.testFolder)

        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.testFolderUpdated', { name: testFolder.name }).then((message) => {
            showMessage('success', message)
        })
    },

    resetTestFolder() {
        this.id = ''
        this.testFolderName = ''
        this.selectedFolderName = ''
        this.description = ''
        this.showSelectedFolderId = ''
        this.showSelectedTestCaseId = ''
        this.isCreatingNewFolder = false
        this.toggleUpdateFoldersTree = false
    },
})
