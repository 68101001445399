import React from 'react'

import { observer } from 'mobx-react'

import { Button, Typography } from 'antd'

import { SpinnerStore, ThemeStore } from '@/store'
import { themeUrls } from '@/consts'

import styles from './DataPlaceholder.module.scss'

const { Text } = Typography

type Props = {
    title: string
    description: string
    onCreate: () => void
}
export const DataPlaceholder = observer((props: Props) => {
    const { title, description, onCreate } = props
    const src = `${themeUrls[ThemeStore.theme]}dataPlaceholder.svg`

    return (
        <div className={styles.DataPlaceholder}>
            <img src={src} alt="placeholder" className={styles.DataPlaceholderImage} />
            <Text>{description}</Text>
            <Button type="primary" onClick={onCreate} disabled={SpinnerStore.show}>
                {title}
            </Button>
        </div>
    )
})
