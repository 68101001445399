import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'

import { getTimestampInSeconds } from '@/utils'

import { Divider, Drawer, Layout } from 'antd'
import { NavBlocker } from '@/components/NavBlocker'
import Spinner from '@/components/Spinner/Spinner'
import { AFKModal } from './AFKModal'
import { TestCaseContent } from './Content/TestCaseContent'
import { TestCaseHeader } from './Header/TestCaseHeader'
import { TestCaseSider } from './Sider/TestCaseSider'
import { TableMenu } from './TableMenu/TableMenu'

import { StoreBlocker, StoreCreateTestSet, StoreProject, StoreTestCase, StoreTestCaseList, StoreTestSet } from '@/store'
import { pageUrls } from '@/consts'

import { IExecutionStep, IStep, ITestCase } from '@/types'
import { EExecutionCaseStatus, EStepType } from '@/enums'

import styles from './TestCaseComponent.module.scss'

type Props = {
    isExecutionTestCase: boolean
    setTestCaseInfo?: Dispatch<SetStateAction<ITestCase | undefined>>
    isTestCaseLoading: boolean
}

export const TestCaseComponent = observer((props: Props) => {
    const { isExecutionTestCase, setTestCaseInfo, isTestCaseLoading } = props
    const [isModalSaveOpen, setModalSaveOpen] = useState(false)
    const navigate = useNavigate()
    const { projectId } = useParams()
    const { pathname } = useLocation()
    const { t } = useTranslation(['testCase'])

    const isNewTestCase = useMemo(
        () =>
            matchPath(
                {
                    path: `/${pageUrls.project}/:id${pageUrls.testFolder}/:id${pageUrls.testCase}/new`,
                },
                pathname
            ),
        [pathname]
    )

    const trackUserInactivity = !isNewTestCase && !StoreTestCase.executionTestCase && !StoreTestCase.readOnly

    useEffect(() => {
        if (projectId && !StoreProject.id) {
            StoreProject.setProjectId(projectId)
        }
    }, [projectId])

    const [preConditions, setPreConditions] = useState<IStep[]>([])
    const [defaultSteps, setDefaultSteps] = useState<IStep[]>([])
    const [postConditions, setPostConditions] = useState<IStep[]>([])

    const [hasExeSteps, setHasExeSteps] = useState(false)

    const mergeSteps = () => {
        const allSteps = [...preConditions, ...defaultSteps, ...postConditions]
        StoreTestCase.setTestCaseSteps(allSteps)
    }

    const onCreateNewTestCase = async () => {
        const testCaseId = StoreTestCase.id
        const testFolderId = StoreTestCase.testFolderId
        await StoreTestCase.toggleBlocked(false, testCaseId)
        StoreTestCase.resetTestCaseData()
        StoreTestCase.setTestCaseName(t('testCase:newTestCaseTitle'))
        StoreTestCase.setToggleResetSearchTestCase()
        setModalSaveOpen(false)
        await StoreTestCaseList.getAllTestCases()
        testCaseId &&
            navigate(`${pageUrls.project}/${projectId}${pageUrls.testFolder}/${testFolderId}${pageUrls.testCase}/new`)
    }

    const onLeaveTestCase = async () => {
        setModalSaveOpen(false)
        if (!StoreTestCase.navigateToNewTestCase) {
            if (StoreTestCase.executionTestCase) {
                const url =
                    StoreBlocker?.blocker?.location?.pathname || `${pageUrls.testSet}/${StoreTestCase.testSetId}`

                navigate(url)
            } else {
                const currentTestCaseId = StoreTestCase.id
                const url = StoreBlocker.blocker?.location?.pathname
                    ? `${StoreBlocker.blocker?.location?.pathname}${StoreBlocker.blocker?.location?.search}`
                    : `${pageUrls.project}/${StoreTestCase.projectId}`

                await StoreTestCase.toggleBlocked(false, currentTestCaseId)
                StoreCreateTestSet.isCreatingTestSet && StoreCreateTestSet.selectedTestFolderId
                    ? navigate(
                          `${pageUrls.project}/${StoreProject.id}${pageUrls.createTestSet}?folderId=${StoreCreateTestSet.selectedTestFolderId}`
                      )
                    : StoreCreateTestSet.isCreatingTestSet && StoreCreateTestSet.testSetIdInModal
                      ? navigate(`${pageUrls.testSet}/${StoreCreateTestSet.testSetIdInModal}`)
                      : navigate(url)
            }
            StoreTestCase.resetTestCaseData()
        } else {
            onCreateNewTestCase()
        }
    }

    const handleSave = async () => {
        mergeSteps()

        if (StoreTestCase.executionTestCase) {
            StoreTestCase.setExecutionCaseCompledTime(getTimestampInSeconds())

            await StoreTestSet.setCompletedTimestamp(StoreTestCase.id).then((durationExecutionCase) =>
                StoreTestCase.setDurationExecutionCase(durationExecutionCase)
            )
            await StoreTestCase.saveExecutionTestCase()
            StoreTestCase.setExecutionCaseStarted(false)
            onLeaveTestCase()
        } else {
            if (StoreTestCase.id) {
                !StoreTestCase.blocked && (await StoreTestCase.toggleBlocked(true))
                await StoreTestCase.updateTestCase()
            } else {
                await StoreTestCase.createTestCase().then((id) => {
                    if (!StoreTestCase.navigateToNewTestCase) {
                        StoreTestCase.setTestCaseId(id)
                    }
                })
            }

            if (StoreTestCase.navigateToNewTestCase) {
                onCreateNewTestCase()
            } else {
                StoreTestCase.setIsSaved(true)
                StoreTestCase.setRequiredStepChanged(false)
                onLeaveTestCase()
                setModalSaveOpen(false)
                StoreBlocker.setBlocker(null)
            }
        }
    }

    const handleDontSave = async () => {
        await onLeaveTestCase()
    }

    const handleCancel = () => {
        setModalSaveOpen(false)
        StoreBlocker.setBlocker(null)
    }

    const onLeave = async () => {
        //TODO: надо бы вообще блокировщик поменять С ним постоянно проблемы
        //например, если кликнуть на ссылку без вызова блокировщика страницы, тогда не произойдет разблокировка ТК
        //потому при клике по ссылке сделал, что происходит разблокировка, но в случае появления блокировщика - тут же снова блокируется
        //вариант с navigate вызывает ошибки в работе самого блокировщика страницы, потому там Link onClick
        if (!StoreTestCase.navigateToNewTestCase) {
            await StoreTestCase.toggleBlocked(true)
        }
        const shouldSave = !StoreTestCase.isSaved

        if (shouldSave) {
            setModalSaveOpen(true)
        } else {
            await handleDontSave()
        }
    }

    useEffect(() => {
        if (!isTestCaseLoading) {
            const preConditionsStore = StoreTestCase.steps.filter(
                (step) => step.stepType === EStepType.STEP_PRECONDITION
            )
            const defaultStepsStore = StoreTestCase.steps.filter((step) => step.stepType === EStepType.DEFAULT_STEP)
            const postConditionsStore = StoreTestCase.steps.filter(
                (step) => step.stepType === EStepType.STEP_POSTCONDITION
            )

            setPreConditions(preConditionsStore)
            setDefaultSteps(defaultStepsStore)
            setPostConditions(postConditionsStore)
        }
    }, [StoreTestCase.steps, isTestCaseLoading, StoreTestCase.id])

    const setActualResults = () => {
        const steps = [...preConditions, ...defaultSteps, ...postConditions]

        const exeSteps = StoreTestCase.testCaseInfo?.executionSteps

        const updatedExecutionSteps = exeSteps.map((exeStep: IExecutionStep) => {
            return {
                ...exeStep,
                actualResult: steps.find((step: IStep) => step.stepId === exeStep.stepId)?.actualResult || '',
            }
        })

        StoreTestCase.setExecutionSteps(updatedExecutionSteps)
    }

    useEffect(() => {
        if (StoreTestCase.executionTestCase && !isTestCaseLoading) {
            const hasSteps = !!postConditions.length || !!defaultSteps.length || !!postConditions.length
            if (hasSteps) {
                setActualResults()
                setHasExeSteps(true)
            } else {
                setHasExeSteps(false)
            }
        }
    }, [preConditions, defaultSteps, postConditions, StoreTestCase.executionTestCase])

    const setTestCaseSavedStatus = async (isSaved: boolean) => {
        const dontUpdateLocker = !!isNewTestCase
        await StoreTestCase.setIsSaved(isSaved, dontUpdateLocker)
    }

    return (
        <Drawer
            placement="right"
            open
            width={'100vw'}
            contentWrapperStyle={{ top: 56 }}
            closable={false}
            zIndex={1}
            className={styles.Drawer}
        >
            <Layout className={styles.TestCaseComponent}>
                <TableMenu
                    testCases={
                        StoreTestCase.executionTestCase ? StoreTestCase.testCasesInSet : StoreTestCaseList.allTestCases
                    }
                    isModalSaveOpen={isModalSaveOpen}
                    onLeave={onLeave}
                    handleDontSave={handleDontSave}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    setTestCaseSavedStatus={setTestCaseSavedStatus}
                />
                <TestCaseSider />
                <Layout
                    className={cn(styles.RightSide, {
                        [styles.ExecutionCaseRightSide]: StoreTestCase.executionTestCase,
                    })}
                >
                    <TestCaseHeader
                        isModalSaveOpen={isModalSaveOpen}
                        handleDontSave={handleDontSave}
                        handleSave={handleSave}
                        handleCancel={handleCancel}
                        mergeSteps={mergeSteps}
                        setTestCaseInfo={setTestCaseInfo}
                        setTestCaseSavedStatus={setTestCaseSavedStatus}
                    />
                    <Divider />
                    {isTestCaseLoading || (StoreTestCase.executionTestCase && !hasExeSteps) ? (
                        <Spinner show fixed />
                    ) : (
                        <TestCaseContent
                            preConditions={preConditions}
                            setPreConditions={setPreConditions}
                            defaultSteps={defaultSteps}
                            setDefaultSteps={setDefaultSteps}
                            postConditions={postConditions}
                            setPostConditions={setPostConditions}
                            isExecutionTestCase={isExecutionTestCase}
                            isNewTestCase={!!isNewTestCase}
                            setTestCaseSavedStatus={setTestCaseSavedStatus}
                        />
                    )}
                </Layout>
            </Layout>
            {trackUserInactivity && <AFKModal mergeSteps={mergeSteps} setTestCaseInfo={setTestCaseInfo} />}
            {!StoreBlocker.blocker && (
                <NavBlocker
                    isBlocked={
                        !StoreTestCase.isSaved ||
                        (StoreTestCase.executionCaseStatus === EExecutionCaseStatus.IN_PROCESS &&
                            StoreTestCase.executionCaseStarted &&
                            !isModalSaveOpen)
                    }
                    onLeave={onLeave}
                />
            )}
        </Drawer>
    )
})
