import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { MsAuthApi } from '@/api'

import { Tabs, Tooltip, Typography } from 'antd'
import type { TabsProps } from 'antd'
import { RolePermissions } from './RolePermissions'

import { pageUrls } from '@/consts'

import { IRolesWithPermissions, ISearchPermissions, ISearchRoles } from '@/types'
import { EAccessLevel } from '@/enums'

import styles from './RoleModel.module.scss'

const { Title } = Typography

type Props = {
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
}

export const RoleModel = observer((props: Props) => {
    const { pageType } = props
    const { t, i18n } = useTranslation(['roleModel'])
    const { workspaceId, projectId } = useParams()
    const [roles, setRoles] = useState<ISearchRoles[]>([])
    const [permissions, setPermissions] = useState<ISearchPermissions[]>([])
    const [selectedRole, setSelectedRole] = useState<IRolesWithPermissions>()
    const [currentTab, setCurrentTab] = useState<string>()
    const [tabItems, setTabItems] = useState<TabsProps['items']>()

    const selectRole = (roleId: string) => {
        const role = roles.find((role) => role.id === roleId)
        if (role) {
            const rolePermissions = role.permissionsIds.map(
                (id) => permissions.filter((permission) => permission.id === id)[0]
            )
            const updatedRole = { ...role, permissions: rolePermissions }
            setSelectedRole(updatedRole)
            setCurrentTab(roleId)
        }
    }
    const roleNames = (roleName: string) => {
        switch (roleName) {
            case 'WORKSPACE_ADMIN':
                return t('roleModel:roles.workspaceAdmin')
            case 'WORKSPACE_USER':
                return t('roleModel:roles.workspaceUser')
            case 'PROJECT_ADMIN':
                return t('roleModel:roles.projectAdmin')
            case 'PROJECT_USER':
                return t('roleModel:roles.projectUser')
            case 'TEST_LEAD':
                return t('roleModel:roles.testLead')
            case 'TEST_ENGINEER':
                return t('roleModel:roles.testEngineer')
            case 'TEST_DESIGNER':
                return t('roleModel:roles.testDesigner')
            default:
                return i18n.t(`roleModel:roles.${roleName}`)
        }
    }

    useEffect(() => {
        const roleFilter = {
            workspaceId: workspaceId || '',
            projectId: projectId || '',
            accessLevel: pageType === pageUrls.workspaceSettings ? EAccessLevel.WORKSPACE : EAccessLevel.PROJECT,
        }

        if (workspaceId || projectId) {
            MsAuthApi.getRoles(roleFilter).then((roles) => {
                setRoles(roles)
                setCurrentTab(roles[0].id)
            })

            MsAuthApi.getPermissions().then((permissions) => {
                setPermissions(permissions)
            })
        }
    }, [workspaceId, projectId, pageType, i18n.language])

    useEffect(() => {
        roles.length && selectRole(roles[0].id!)
    }, [roles])
    useEffect(() => {
        const tabs = roles.map((role) => {
            const rolePermissions = role.permissionsIds.map(
                (id) => permissions.filter((permission) => permission.id === id)[0]
            )
            const updatedRole = { ...role, permissions: rolePermissions }

            return {
                key: role.id!,
                label: roleNames(role.name),
                children: <RolePermissions selectedRole={updatedRole} />,
            }
        })

        setTabItems(tabs)
    }, [roles, permissions, i18n.language])

    const onSelectRole = (newRoleId: string) => {
        selectRole(newRoleId)
    }

    return (
        <div className={styles.RoleModel}>
            <Title level={4} className={styles.SettingTitle}>
                {t('roleModel:title')}
            </Title>

            <Tabs
                type="card"
                activeKey={currentTab}
                onChange={onSelectRole}
                items={tabItems}
                className={styles.RolesTabs}
            />
        </div>
    )
})
