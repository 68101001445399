// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KYj0iUwN72SsKznEPX3F,.z9yXHGwBmcdevTkifIJL{width:320px}.KYj0iUwN72SsKznEPX3F h3,.z9yXHGwBmcdevTkifIJL h3{text-align:center}.KYj0iUwN72SsKznEPX3F .ant-btn-link span,.z9yXHGwBmcdevTkifIJL .ant-btn-link span{text-transform:none !important}.KYD41feN9Ch_fZJSsDQi .ant-btn span,.XMnIID_rahooiyDAXkIC .ant-btn span{text-transform:none !important}.AkYcObFcVO_PCZgyeIpu{display:flex;flex-flow:column;align-items:center;gap:18px}.AkYcObFcVO_PCZgyeIpu .ehuPcjuB8tB6DwV8tMJE{display:flex;align-items:center;gap:16px}.JYORwhxfPbrMWBdFnVvP{margin-bottom:0}.NOTJJTJXGLrJ4J3jah49{display:flex;justify-content:space-between}.Q5AytkMJexnBiu1BkTHp{display:block;font-size:12px;margin-top:8px}", "",{"version":3,"sources":["webpack://./src/components/SignInUp/SignInUp.module.scss"],"names":[],"mappings":"AAAA,4CAEI,WAAA,CAEA,kDACI,iBAAA,CAIA,kFACI,8BAAA,CAMA,wEACI,8BAAA,CAMhB,sBACI,YAAA,CACA,gBAAA,CACA,kBAAA,CACA,QAAA,CAEA,4CACI,YAAA,CACA,kBAAA,CACA,QAAA,CAIR,sBACI,eAAA,CAGJ,sBACI,YAAA,CACA,6BAAA,CAGJ,sBACI,aAAA,CACA,cAAA,CACA,cAAA","sourcesContent":[".SignIn,\n.SignUp {\n    width: 320px;\n\n    h3 {\n        text-align: center;\n    }\n\n    :global {\n        .ant-btn-link span {\n            text-transform: none !important;\n        }\n    }\n\n    &Modal {\n        :global {\n            .ant-btn span {\n                text-transform: none !important;\n            }\n        }\n    }\n}\n\n.FormFooter {\n    display: flex;\n    flex-flow: column;\n    align-items: center;\n    gap: 18px;\n\n    .SiteOptions {\n        display: flex;\n        align-items: center;\n        gap: 16px;\n    }\n}\n\n.Submit {\n    margin-bottom: 0;\n}\n\n.RememberAndForgot {\n    display: flex;\n    justify-content: space-between;\n}\n\n.DisabledText {\n    display: block;\n    font-size: 12px;\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SignIn": "KYj0iUwN72SsKznEPX3F",
	"SignUp": "z9yXHGwBmcdevTkifIJL",
	"SignInModal": "KYD41feN9Ch_fZJSsDQi",
	"SignUpModal": "XMnIID_rahooiyDAXkIC",
	"FormFooter": "AkYcObFcVO_PCZgyeIpu",
	"SiteOptions": "ehuPcjuB8tB6DwV8tMJE",
	"Submit": "JYORwhxfPbrMWBdFnVvP",
	"RememberAndForgot": "NOTJJTJXGLrJ4J3jah49",
	"DisabledText": "Q5AytkMJexnBiu1BkTHp"
};
export default ___CSS_LOADER_EXPORT___;
