// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PiQb8bfjMKx15aByh791 .ACtUe2g7DIbgcjYFHcD1{display:flex;justify-content:space-between;gap:32px;align-items:center;margin-top:14px;margin-bottom:18px}.PiQb8bfjMKx15aByh791 .ECwosXawTgHqq4SvceeL{display:flex;padding:0px 0px 16px;gap:16px;flex-wrap:wrap;box-shadow:none}.PiQb8bfjMKx15aByh791 .seHJ9KCaYO706uzSPWF7{margin:0 auto;padding-top:88px}", "",{"version":3,"sources":["webpack://./src/components/SettingPageTabs/Team/Team.module.scss"],"names":[],"mappings":"AACI,4CACI,YAAA,CACA,6BAAA,CACA,QAAA,CACA,kBAAA,CACA,eAAA,CACA,kBAAA,CAGJ,4CACI,YAAA,CACA,oBAAA,CACA,QAAA,CACA,cAAA,CACA,eAAA,CAGJ,4CACI,aAAA,CACA,gBAAA","sourcesContent":[".Team {\n    .Header {\n        display: flex;\n        justify-content: space-between;\n        gap: 32px;\n        align-items: center;\n        margin-top: 14px;\n        margin-bottom: 18px;\n    }\n\n    .Cards {\n        display: flex;\n        padding: 0px 0px 16px;\n        gap: 16px;\n        flex-wrap: wrap;\n        box-shadow: none;\n    }\n\n    .Empty {\n        margin: 0 auto;\n        padding-top: 88px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Team": "PiQb8bfjMKx15aByh791",
	"Header": "ACtUe2g7DIbgcjYFHcD1",
	"Cards": "ECwosXawTgHqq4SvceeL",
	"Empty": "seHJ9KCaYO706uzSPWF7"
};
export default ___CSS_LOADER_EXPORT___;
