import React, { Dispatch, useEffect, useState } from 'react'

import { runInAction } from 'mobx'

export function useDebounce<T>(time: number, initialValue: T): [T, T, Dispatch<T>] {
    const [value, setValue] = useState<T>(initialValue)
    const [debouncedValue, setDebouncedValue] = useState<T>(initialValue)

    useEffect(() => {
        const debounce = setTimeout(() => runInAction(() => setDebouncedValue(value)), time)
        return () => {
            clearTimeout(debounce)
        }
    }, [value, time])

    return [debouncedValue, value, setValue]
}
