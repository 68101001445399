import React, { Dispatch, Key, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, Dropdown, Modal, Tooltip } from 'antd'
import type { MenuProps } from 'antd'
import { ExportButton } from '@/components/ExportButton/ExportButton'
import { ModalOnDeleteRows } from '@/components/ModalOnDeleteRows/ModalOnDeleteRows'

import {
    AppstoreAddOutlined,
    CopyOutlined,
    DeleteOutlined,
    FileAddOutlined,
    PlusOutlined,
    RetweetOutlined,
} from '@ant-design/icons'

import { SpinnerStore, StoreCreateTestSet, StoreTestFolder } from '@/store'
import { apiUrls, folderTabs } from '@/consts'

import { ITestCase } from '@/types'
import { EWorkStatus } from '@/enums'

import styles from './TableMenu.module.scss'

type Props = {
    testCases: ITestCase[]
    setTestCases: Dispatch<SetStateAction<ITestCase[]>>
    loadData: () => void
    elementsCount: number
    pageSize: number
    setShowTableMenu: Dispatch<SetStateAction<boolean>>
    selectedRowKeys: Key[]
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    setShowTestSetsModal: Dispatch<SetStateAction<boolean>>
    onSelectAndCreateModalOpen: () => void
    setIsMoveTestCaseToFolderModalOpen?: Dispatch<SetStateAction<boolean>>
    setSelectedTestCaseIds?: Dispatch<SetStateAction<string[]>>
}

export const TableMenu = observer((props: Props) => {
    const {
        testCases,
        setTestCases,
        loadData,
        elementsCount,
        pageSize,
        setShowTableMenu,
        selectedRowKeys,
        setSelectedRowKeys,
        setShowTestSetsModal,
        onSelectAndCreateModalOpen,
        setIsMoveTestCaseToFolderModalOpen,
        setSelectedTestCaseIds,
    } = props

    const { t } = useTranslation(['page--testCases', 'common', 'notification'])

    const handleDelete = () => {
        if (StoreCreateTestSet.selectedRowKeys.length) {
            if (StoreCreateTestSet.selectedTestCases.some((testCase) => !!testCase.testCaseBlockInfo)) {
                const testCasesId: string[] = []
                Object.values(StoreCreateTestSet.selectedRowKeys)
                    .flat()
                    .forEach((testCaseId) => {
                        StoreCreateTestSet.selectedTestCases.filter((testCase) => testCase.id === testCaseId)[0]
                            .testCaseBlockInfo === null && testCasesId.push(testCaseId as string)
                    })

                Modal.warning({
                    title: t('notification:warning.editingTestCaseCannotBeDeleted'),
                    centered: true,
                    onOk: () => {
                        StoreCreateTestSet.updateSelectedTestCases(testCasesId, setSelectedRowKeys)

                        if (testCasesId.length) {
                            ModalOnDeleteRows({
                                items: testCases,
                                setItems: setTestCases,
                                elementsCount: elementsCount,
                                pageSize: pageSize,
                                loadData: loadData,
                                specificApiUrl: apiUrls.testCase,
                                title: t('page--testCases:deleteGuard.titleAll'),
                                content: t('page--testCases:deleteGuard.contentAll'),
                                okText: t('common:buttons.ok'),
                                cancelText: t('common:buttons.cancel'),
                                setShowTableMenu: setShowTableMenu,
                                selectedRowKeys: testCasesId,
                                setSelectedRowKeys: setSelectedRowKeys,
                            })
                        } else {
                            setShowTableMenu(false)
                        }
                    },
                })
            } else {
                ModalOnDeleteRows({
                    items: testCases,
                    setItems: setTestCases,
                    elementsCount: elementsCount,
                    pageSize: pageSize,
                    loadData: loadData,
                    specificApiUrl: apiUrls.testCase,
                    title: t('page--testCases:deleteGuard.titleAll'),
                    content: t('page--testCases:deleteGuard.contentAll'),
                    okText: t('common:buttons.ok'),
                    cancelText: t('common:buttons.cancel'),
                    setShowTableMenu: setShowTableMenu,
                    selectedRowKeys: selectedRowKeys,
                    setSelectedRowKeys: setSelectedRowKeys,
                })
            }
        }
    }

    const handleCopy = () => {
        console.log('copy')
    }

    const handleRestore = () => {
        setSelectedTestCaseIds && setSelectedTestCaseIds(selectedRowKeys as string[])
        setIsMoveTestCaseToFolderModalOpen && setIsMoveTestCaseToFolderModalOpen(true)
    }

    const resetSelection = () => {
        setShowTableMenu(false)
        setSelectedRowKeys([])
    }

    const onAddToNewTestSet = () => {
        onSelectAndCreateModalOpen()
    }

    const onAddToTestSet = () => {
        if (StoreCreateTestSet.selectedRowKeys.length) {
            if (
                StoreCreateTestSet.selectedTestCases.some(
                    (testCase) => !!testCase.testCaseBlockInfo || testCase.workStatus === EWorkStatus.DRAFT
                )
            ) {
                const readyTestCaseIds = StoreCreateTestSet.validateAddingToTestCycle(setSelectedRowKeys)

                if (readyTestCaseIds.length) {
                    Modal.warning({
                        title: t('notification:warning.testCaseCannotBeAdded', {
                            status: t(`statuses:${EWorkStatus.DRAFT}`),
                        }),
                        onOk: () => {
                            setShowTestSetsModal(true)
                            setShowTableMenu(false)
                        },
                        centered: true,
                    })
                } else {
                    setShowTableMenu(false)
                    Modal.warning({
                        title: t('notification:warning.testCaseCannotBeAdded', {
                            status: t(`statuses:${EWorkStatus.DRAFT}`),
                        }),
                        centered: true,
                    })
                }
            } else {
                setShowTestSetsModal(true)
                setShowTableMenu(false)
            }
        }
    }

    const items: MenuProps['items'] = [
        {
            label: t('page--testCases:addToTestSet'),
            key: 'addToTestSet',
            icon: <AppstoreAddOutlined />,
            onClick: onAddToTestSet,
        },
        {
            label: t('page--testCases:addToNewTestSet'),
            key: 'addToNewTestSet',
            icon: <FileAddOutlined />,
            onClick: onAddToNewTestSet,
        },
    ]

    return (
        <div className={styles.TableMenu}>
            {StoreTestFolder.currentFolderTab !== folderTabs.archive ? (
                <>
                    <Dropdown menu={{ items }} disabled={SpinnerStore.show} placement="bottomRight">
                        <Button type="primary" icon={<PlusOutlined />}>
                            {t('common:buttons.addTo')}
                        </Button>
                    </Dropdown>
                    <ExportButton selectedRowKeys={selectedRowKeys} />
                    <Tooltip title={`${t('common:buttons.copy')} (${t('common:inDevelopment')})`}>
                        <Button type="default" icon={<CopyOutlined />} onClick={handleCopy} disabled />
                    </Tooltip>
                    <Tooltip title={t('common:buttons.delete')}>
                        <Button
                            type="default"
                            icon={<DeleteOutlined />}
                            onClick={handleDelete}
                            disabled={SpinnerStore.show}
                        />
                    </Tooltip>
                </>
            ) : (
                <Tooltip title={t('page--testCases:restore')}>
                    <Button type="default" icon={<RetweetOutlined />} onClick={handleRestore} />
                </Tooltip>
            )}
            <Button type="default" onClick={resetSelection} disabled={SpinnerStore.show}>
                {t('common:buttons.cancel')}
            </Button>
        </div>
    )
})
