import React, { Key } from 'react'

import { makeAutoObservable } from 'mobx'

import { MsExecutorApi } from '@/api'

import { showMessage } from '@/utils/notification'

import { PreferencesStore, SpinnerStore, StoreTestCase } from '@/store'
import { apiUrls, pageUrls, showAllPages } from '@/consts'

import {
    DataNodeWithName,
    ICreateTestSet,
    IExecutionCase,
    IExecutionStep,
    ISelectTestFolder,
    ISort,
    IStep,
    ITestFolder,
    ITestSet,
    IUpdateTestSet,
} from '@/types'
import { EExecutionTestSetStatus, EOrder } from '@/enums'

export const StoreTestSet = makeAutoObservable({
    id: '',
    name: '',
    selectedTestSetFolderId: '',
    executionTestSetStatus: undefined as EExecutionTestSetStatus | undefined,
    testSetList: null,
    toggleUpdateTestsets: false,
    latestSearchProps: {
        filter: {
            projectId: '',
        },
        sort: [],
        pageSize: showAllPages,
    } as {
        page?: number
        filter: Record<string, string[] | string>
        sort: ISort[]
        pageSize?: number
    },
    selectedTestSets: [] as ITestSet[],
    foldersTree: [] as DataNodeWithName[],
    archiveFolders: [] as DataNodeWithName[],

    updateTestSetList() {
        this.toggleUpdateTestsets = !this.toggleUpdateTestsets
    },

    setTestSetFoldersTree(value: DataNodeWithName[]) {
        this.foldersTree = value
    },

    setArchiveTestSetFolders(value: DataNodeWithName[]) {
        this.archiveFolders = value
    },

    async createTestSet(testSet: ICreateTestSet, navigateTo?: any) {
        SpinnerStore.setShow(true)
        const id = await MsExecutorApi.save(testSet)
        PreferencesStore.t('notification:success.testSetCreated', { name: testSet.name }).then((message) => {
            showMessage('success', message, () => navigateTo(id))
        })
        SpinnerStore.setShow(false)

        return id
    },

    async updateTestSet(props: IUpdateTestSet) {
        SpinnerStore.setShow(true)
        await MsExecutorApi.updateTestSet(props)
        SpinnerStore.setShow(false)
    },

    async getTestSets(props: {
        page?: number
        filter: Record<string, string>
        sort: { field: string; direction: EOrder }[]
        pageSize?: number
    }) {
        SpinnerStore.setShow(true)
        const testSetList = await MsExecutorApi.filter({
            ...props,
        })

        this.testSetList = testSetList

        SpinnerStore.setShow(false)

        return testSetList
    },

    setTestSetId(value: string) {
        this.id = value
    },

    setExecutionTestSetStatus(value: EExecutionTestSetStatus | undefined) {
        this.executionTestSetStatus = value
    },

    setSelectedTestSetFolderId(value: string) {
        this.selectedTestSetFolderId = value
    },

    setName(value: string) {
        this.name = value
    },

    setSelectedTestSets(value: ITestSet[]) {
        this.selectedTestSets = value
    },

    resetSelectedTestSets() {
        this.selectedTestSets = []
    },

    async getItem(id: string, specificApiUrl: apiUrls) {
        SpinnerStore.setShow(true)
        const testSetInfo = (await MsExecutorApi.getItem(id, specificApiUrl)).data
        SpinnerStore.setShow(false)
        return testSetInfo
    },

    //TODO на беке не реализовано (24.11.23), потому временно будет через фильтр
    // async getTestSetFolder(id: string): Promise<ITestFolder> {
    //     SpinnerStore.setShow(true)
    //     const testSetFolderInfo = (await MsExecutorApi.getItem(id, apiUrls.testSetFolder)).data
    //     SpinnerStore.setShow(false)
    //     return testSetFolderInfo
    // },

    async getTestSetFolder(projectId: string, folderId: string): Promise<ITestFolder | undefined> {
        SpinnerStore.setShow(true)
        const testSetFolderInfo = (await this.getTestSetFolders(projectId)).find((folder) => folder.id === folderId)
        SpinnerStore.setShow(false)
        return testSetFolderInfo
    },

    async setExecutionSteps(executionSteps: IExecutionStep[]) {
        SpinnerStore.setShow(true)
        executionSteps.map(async (step) => (await MsExecutorApi.setExecutionStep(step)).data)
        SpinnerStore.setShow(false)
    },

    async launchTestCase(id: string) {
        SpinnerStore.setShow(true)
        const response = (await MsExecutorApi.launchTestCase(id)).data

        const updateSteps = (parentSteps: IStep[]) => {
            const stepsDataForCopy = parentSteps.map((step) => {
                //TODO сделать список полей для сохранения и добавить их еще в mergeStepsToExecutionSteps
                return {
                    id: step.id,
                    jiraIssuesWithData: step?.jiraIssuesWithData ? step?.jiraIssuesWithData : [],
                    externalId: step?.externalId,
                    required: step?.required,
                    stepType: step?.stepType,
                    comment: step?.comment,
                    description: step?.description,
                    expectedResult: step?.expectedResult,
                    testData: step?.testData,
                    actions: step?.actions,
                    orderNum: step?.orderNum,
                    manual: step?.manual,
                }
            })

            const updatedSteps = StoreTestCase.testCaseInfo?.executionSteps.map((step: IExecutionStep) => {
                const stepDataToAdd = stepsDataForCopy.find((stepDataCopy) => stepDataCopy.id === step.id)
                return { ...step, ...stepDataToAdd }
            })
            return updatedSteps
        }

        response.executionSteps = updateSteps(StoreTestCase.steps)
        StoreTestCase.setTestCaseInfo(response)
        StoreTestCase.updateTopRequiredSteps()

        SpinnerStore.setShow(false)
        return response
    },

    async finishTestCase(executionCase: IExecutionCase) {
        SpinnerStore.setShow(true)
        const response = await MsExecutorApi.finishTestCase(executionCase)
        SpinnerStore.setShow(false)
        return response
    },

    async deleteExecutionCases(testSetId: string, casesId: string[], name?: string) {
        SpinnerStore.setShow(true)
        await MsExecutorApi.deleteExecutionTestCases(testSetId, { executionTestCaseIdsForDeletion: casesId })
        SpinnerStore.setShow(false)

        name
            ? PreferencesStore.t('notification:success.testCaseDeleted', { name: name }).then((message) => {
                  showMessage('success', message)
              })
            : PreferencesStore.t('notification:success.allTestCasesDeleted', {}).then((message) => {
                  showMessage('success', message)
              })
    },

    async setCompletedTimestamp(executionCaseId: string) {
        SpinnerStore.setShow(true)
        const response = await MsExecutorApi.setCompletedTimestamp(executionCaseId)
        SpinnerStore.setShow(false)
        return response.durationExecutionCase
    },

    async changeTestSetStatus(testSetId: string, status: EExecutionTestSetStatus) {
        SpinnerStore.setShow(true)
        await MsExecutorApi.changeTestSetStatus(testSetId, status)
        SpinnerStore.setShow(false)
    },

    async changeTestSetAssigned(testSetId: string, user: string) {
        SpinnerStore.setShow(true)
        const response = await MsExecutorApi.changeTestSetAssigned(testSetId, user)
        SpinnerStore.setShow(false)
        return response.data
    },

    async addToTestSet(testSets: ITestSet[], testCasesId: string[], navigate: any) {
        if (!testCasesId.length) {
            return []
        }

        SpinnerStore.setShow(true)

        const executionProcessRequest = {
            executionProcessRequestDto: testCasesId.map((testCaseId) => {
                return {
                    testCaseId: testCaseId,
                }
            }),
        }

        const updatedTestSets = await Promise.all(
            testSets.map(async (testSet: ITestSet) => {
                const updatedTestSet: ITestSet = (await MsExecutorApi.addToTestSet(testSet.id, executionProcessRequest))
                    .data
                PreferencesStore.t('notification:success.testSetUpdated', { name: testSet.orderNum }).then(
                    (message) => {
                        showMessage('success', message, () => navigate(`${pageUrls.testSet}/${testSet.id}`))
                    }
                )
                return updatedTestSet
            })
        )
        SpinnerStore.setShow(false)

        return updatedTestSets
    },

    async getTestSetFolders(projectId: string) {
        SpinnerStore.setShow(true)
        const testSetFolders = await MsExecutorApi.getTestSetFolders(projectId, apiUrls.testSetFolder)
        SpinnerStore.setShow(false)

        return testSetFolders
    },

    async createTestSetFolder(testSetFolder: ITestFolder) {
        SpinnerStore.setShow(true)
        const resp = await MsExecutorApi.createTestSetFolder(testSetFolder, apiUrls.testSetFolder)

        PreferencesStore.t('notification:success.testFolderCreated', { name: testSetFolder.name }).then((message) => {
            showMessage('success', message)
        })

        SpinnerStore.setShow(false)
        return resp
    },

    async updateTestSetFolder(testSetFolder: Partial<ITestFolder>) {
        SpinnerStore.setShow(true)

        await MsExecutorApi.updateTestSetFolder(testSetFolder, apiUrls.testSetFolder)

        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.testFolderUpdated', { name: testSetFolder.name }).then((message) => {
            showMessage('success', message)
        })
    },

    async copyTestSetFolder(props: ISelectTestFolder) {
        SpinnerStore.setShow(true)

        const copyTestFolderProps = {
            parent: props.parent,
            parentId: props.parentId,
            folderId: props.folderId,
        }

        const resp = await MsExecutorApi.copyTestSetFolder(copyTestFolderProps)

        PreferencesStore.t('notification:success.testFolderCreated', { name: props.folderName }).then((message) => {
            showMessage('success', message)
        })

        SpinnerStore.setShow(false)
        return resp
    },

    async moveTestSetFolder(props: ISelectTestFolder) {
        SpinnerStore.setShow(true)

        const moveTestSetFolderProps = {
            parent: props.parent,
            parentId: props.parentId,
            folderId: props.folderId,
        }

        const resp = await MsExecutorApi.moveTestSetFolder(moveTestSetFolderProps)

        PreferencesStore.t('notification:success.testFolderMoved', { name: props.folderName }).then((message) => {
            showMessage('success', message)
        })

        SpinnerStore.setShow(false)
        return resp
    },

    async deleteTestSet(id: string, name: string) {
        SpinnerStore.setShow(true)
        await MsExecutorApi.deleteItem(id, apiUrls.testSet)
        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.testSetDeleted', { name: name }).then((message) => {
            showMessage('success', message)
        })
    },

    async deleteTestSetFolder(id: string, name: string) {
        SpinnerStore.setShow(true)
        await MsExecutorApi.deleteItem(id, apiUrls.testSetFolder)
        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.testFolderDeleted', { name: name }).then((message) => {
            showMessage('success', message)
        })
    },

    async deleteTestSetFolders(testSetFolders: Key[]) {
        SpinnerStore.setShow(true)
        Promise.all(
            testSetFolders.map(async (id) => {
                await MsExecutorApi.deleteItem(id, apiUrls.testFolder)
            })
        )
        SpinnerStore.setShow(false)
        PreferencesStore.t('notification:success.allTestFoldersDeleted', {}).then((message) => {
            showMessage('success', message)
        })
    },
})
