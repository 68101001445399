import React, { Dispatch, SetStateAction } from 'react'

import { Modal } from 'antd'

import { StoreProject, StoreTestCase, StoreTestCaseList, StoreTestFolder, StoreTestSet, StoreWorkspace } from '@/store'
import { apiUrls } from '@/consts'

import { IProject, ITestCase, ITestFolder, ITestSet, IWorkspace } from '@/types'

import styles from './ModalOnDeleteRow.module.scss'

type ItemType = IWorkspace | IProject | ITestFolder | ITestCase | ITestSet

interface Props {
    id: string
    name: string
    items: Array<ItemType>
    setItems: Dispatch<SetStateAction<Array<ItemType>>>
    specificApiUrl: apiUrls
    content: string
    title: string
    okText: string
    cancelText: string
    elementsCount?: number
    pageSize?: number
    loadData?: () => void
    handleResetAfterDelete?: (id: string) => void
}

const getFoldersTreeIdsFromNode = (items: Array<ItemType>, folderId: string): Array<string> =>
    items.reduce((treeIds: Array<string>, item) => {
        const isFolderForDelete = item.id === folderId

        if (isFolderForDelete) {
            return [...treeIds, item.id]
        }

        const isNestedFolder = 'parentFolderId' in item && item.parentFolderId === folderId

        if (isNestedFolder) {
            const nestedFoldersIds = getFoldersTreeIdsFromNode(items, item.id)

            return [...treeIds, ...nestedFoldersIds]
        }

        return treeIds
    }, [])

export const ModalOnDeleteRow = (props: Props) => {
    const {
        id,
        name,
        items,
        setItems,
        elementsCount,
        pageSize,
        loadData,
        specificApiUrl,
        title,
        content,
        okText,
        cancelText,
        handleResetAfterDelete,
    } = props

    const handleDelete = async () => {
        switch (specificApiUrl) {
            case apiUrls.workspace:
                await StoreWorkspace.deleteWorkspace(id, name)
                break
            case apiUrls.project:
                await StoreProject.deleteProject(id, name)
                break
            case apiUrls.testFolder:
                await StoreTestFolder.deleteTestFolder(id, name)
                break
            case apiUrls.testCase:
                await StoreTestCase.deleteTestCase(id, name)
                break
            case apiUrls.testSetFolder:
                await StoreTestSet.deleteTestSetFolder(id, name)
                break
            case apiUrls.testSet:
                await StoreTestSet.deleteTestSet(id, name)
                break
        }

        const idsForDelete = getFoldersTreeIdsFromNode(items, id)
        const updatedItems = items.filter((item) => !idsForDelete.includes(item.id))

        setItems(updatedItems)

        //Если, например вторая страница таблицы и с нее все удалено, то вернуться на первую
        if (loadData && elementsCount && pageSize && updatedItems.length === 0 && elementsCount - pageSize > 0) {
            loadData()
        }

        if (specificApiUrl === apiUrls.testCase || specificApiUrl === apiUrls.testSet) {
            StoreTestFolder.updateFoldersTree()
            StoreTestCaseList.updateTestCases()
        }

        if (specificApiUrl === apiUrls.testFolder) {
            StoreTestCaseList.updateTestCases()
        } else if (specificApiUrl === apiUrls.testSetFolder) {
            StoreTestSet.updateTestSetList()
        }

        if (specificApiUrl === apiUrls.testFolder || specificApiUrl === apiUrls.testSetFolder) {
            handleResetAfterDelete && handleResetAfterDelete(id)
        }
    }

    Modal.confirm({
        centered: true,
        title: title,
        content: content,
        onOk: handleDelete,
        okText: okText,
        cancelText: cancelText,
        className: styles.Modal,
    })
}
