import React, { Dispatch, Key, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { TestSets } from '@/pages'

import { Modal } from 'antd'

import { StoreTestSet } from '@/store'

import styles from './ModalAddSelectedTestCasesToTestSet.module.scss'

type Props = {
    selectedTestCaseIds: Key[]
    showTestSetsModal: boolean
    setShowTestSetsModal: Dispatch<SetStateAction<boolean>>
    resetSelectedRowKeys?: () => void
}

export const ModalAddSelectedTestCasesToTestSet = observer((props: Props) => {
    const { selectedTestCaseIds, setShowTestSetsModal, showTestSetsModal, resetSelectedRowKeys } = props
    const { t } = useTranslation(['page--testSets', 'common', 'notification'])
    const navigate = useNavigate()

    const handleAdd = async (selectedTestCaseIds: string[]) => {
        await StoreTestSet.addToTestSet(StoreTestSet.selectedTestSets, selectedTestCaseIds, navigate)
        resetSelectedRowKeys && resetSelectedRowKeys()
        setShowTestSetsModal(false)
    }

    const handleOk = () => {
        if (selectedTestCaseIds.length) {
            handleAdd(selectedTestCaseIds as string[])
        }
    }

    const handleCancel = () => {
        setShowTestSetsModal(false)
    }

    return (
        <Modal
            title={t('page--testSets:addToTestSetsTitle')}
            open={showTestSetsModal}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={t('common:buttons.select')}
            className={styles.ModalAddSelectedTestCasesToTestSet}
        >
            <TestSets showInModal />
        </Modal>
    )
})
