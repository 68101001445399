import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreTestSet } from '@/store'

type Props = {
    onCreateNewTestSet: () => void
}

export const CreateTestSetButton = observer((props: Props) => {
    const { onCreateNewTestSet } = props
    const { t } = useTranslation(['page--testSets'])

    return (
        <Button type="primary" icon={<PlusOutlined />} disabled={SpinnerStore.show} onClick={onCreateNewTestSet}>
            {t('page--testSets:createTestSet')}
        </Button>
    )
})
