// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".flIintNrlg50aPqoK3CS{display:flex;gap:40px}.flIintNrlg50aPqoK3CS .zkLfFRcnug3pF6QKo9op{margin-top:7px;color:var(--color-primary);text-decoration:none;font-size:14px;padding-bottom:7px;line-height:18px}.flIintNrlg50aPqoK3CS .zkLfFRcnug3pF6QKo9op.dr1ZZXM67FDIxEukxnW5{padding-bottom:5px;border-bottom:2px solid var(--color-primary)}", "",{"version":3,"sources":["webpack://./src/components/NavMenu/NavMenu.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,QAAA,CAEA,4CACI,cAAA,CACA,0BAAA,CACA,oBAAA,CACA,cAAA,CACA,kBAAA,CACA,gBAAA,CAGJ,iEACI,kBAAA,CACA,4CAAA","sourcesContent":[".NavMenu {\n    display: flex;\n    gap: 40px;\n\n    .NavLink {\n        margin-top: 7px;\n        color: var(--color-primary);\n        text-decoration: none;\n        font-size: 14px;\n        padding-bottom: 7px;\n        line-height: 18px;\n    }\n\n    .NavLink.IsActive {\n        padding-bottom: 5px;\n        border-bottom: 2px solid var(--color-primary);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"NavMenu": "flIintNrlg50aPqoK3CS",
	"NavLink": "zkLfFRcnug3pF6QKo9op",
	"IsActive": "dr1ZZXM67FDIxEukxnW5"
};
export default ___CSS_LOADER_EXPORT___;
