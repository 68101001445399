import React, { useEffect } from 'react'

import type { unstable_Blocker as Blocker } from 'react-router-dom'
import { unstable_useBlocker as useBlocker } from 'react-router-dom'

import { StoreBlocker } from '@/store'

type Props = {
    isBlocked: boolean
    onLeave: () => void
}

export const NavBlocker = (props: Props) => {
    const { isBlocked, onLeave } = props

    const blocker = useBlocker(isBlocked)

    useEffect(() => {
        if (blocker.state === 'blocked' && !isBlocked) {
            blocker.reset()
        }
    }, [blocker, isBlocked])

    const ConfirmNavigation = ({ blocker }: { blocker: Blocker }) => {
        if (blocker.state === 'blocked') {
            StoreBlocker.setBlocker(blocker)
            onLeave()
        }

        return null
    }

    return <>{blocker ? <ConfirmNavigation blocker={blocker} /> : null}</>
}
