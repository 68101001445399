import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Form, Input, Modal, Typography } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'

import { SpinnerStore, StoreAuthController } from '@/store'
import { pageUrls, resetPasswordTimeOut } from '@/consts'

import { EmailEventType } from '@/enums'
import { Rule } from 'antd/es/form'

import styles from '../SignInUp.module.scss'

const { Text, Paragraph } = Typography

type Props = {
    isOpenForgotModal: boolean
    setIsOpenForgotModal: Dispatch<SetStateAction<boolean>>
}

export const ResetPasswordModal = observer((props: Props) => {
    const { isOpenForgotModal, setIsOpenForgotModal } = props
    const { t } = useTranslation(['validation', 'page--auth', 'common'])
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const [counter, setCounter] = useState(0)
    const [showCounter, setShowCounter] = useState(false)

    useEffect(() => {
        if (showCounter) {
            const timeout = setTimeout(() => setCounter(counter - 1), 1000)
            if (counter > 0 && timeout) {
                return () => {
                    clearTimeout(timeout)
                }
            } else {
                clearTimeout(timeout)
                setShowCounter(false)
            }
        }
    }, [showCounter, counter])

    const formInitialValues = {
        email: StoreAuthController.signUpParams.email,
    }

    const rules: Rule[] = [
        {
            required: true,
            message: t('validation:required'),
        },
        {
            type: 'email',
            message: t('validation:email'),
        },
    ]

    const onCancel = () => {
        setIsOpenForgotModal(false)
        form.resetFields()
        navigate(pageUrls.signIn)
    }

    const handleOk = () => {
        form.validateFields()
            .then((values) => {
                const email = values.email
                StoreAuthController.emailConfirmation(email, EmailEventType.PASSWORD_CHANGE_EVENT)
                setShowCounter(true)
                setCounter(resetPasswordTimeOut)
            })
            .catch((errorInfo) => {
                return
            })
    }

    const disabled = SpinnerStore.show || counter > 0

    return (
        <Modal
            className={styles.SignInModal}
            open={isOpenForgotModal}
            onCancel={onCancel}
            title={t('page--auth:passwordRecovery')}
            okText={t('common:buttons.send')}
            cancelText={t('common:buttons.back')}
            centered
            onOk={handleOk}
            okButtonProps={{
                form: 'form',
                htmlType: 'submit',
                disabled: disabled,
            }}
        >
            <Paragraph>{t('page--auth:enterEmail')}</Paragraph>
            <Form id="form" form={form} initialValues={formInitialValues} layout="vertical">
                <FormItem
                    name="email"
                    label={t('page--auth:email')}
                    validateTrigger={'onblur'}
                    rules={rules}
                    className={'hideRequiredMark'}
                >
                    <Input />
                </FormItem>
            </Form>
            {counter > 0 ? (
                <Text className={styles.DisabledText} disabled>
                    {t('page--auth:tryAgain', { counter })}
                </Text>
            ) : (
                ''
            )}
        </Modal>
    )
})
