import React, { Dispatch, SetStateAction } from 'react'

import { observer } from 'mobx-react'

import { Tooltip, Typography } from 'antd'
import { ProjectListDropdown } from './ProjectListDropdown/ProjectListDropdown'

import { InfoCircleOutlined } from '@ant-design/icons'

import { IProject } from '@/types'

import styles from './TestFolderHeader.module.scss'

const { Title } = Typography

type Props = {
    selectedProject: IProject
    setSelectedProject: Dispatch<SetStateAction<IProject>>
    projectList: IProject[]
    setProjectList: Dispatch<SetStateAction<IProject[]>>
}

export const TestFolderHeader = observer((props: Props) => {
    const { selectedProject, setSelectedProject, setProjectList, projectList } = props

    return (
        <div className={styles.TestFolderHeader}>
            <div className={styles.TestFolderHeaderLeft}>
                <div className={styles.Title}>
                    <Title level={4}>{selectedProject?.projectName}</Title>
                    {selectedProject?.projectDescription && (
                        <Tooltip title={selectedProject?.projectDescription}>
                            <InfoCircleOutlined className={styles.NavIcon} />
                        </Tooltip>
                    )}

                    {!!projectList.length && (
                        <ProjectListDropdown
                            setSelectedProject={setSelectedProject}
                            setProjectList={setProjectList}
                            projectList={projectList}
                        />
                    )}
                </div>
            </div>
        </div>
    )
})
