// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ashA3lDT3sqABoDKYEly .JnvvtsATFDg6vRrkFXbn{margin-top:14px}", "",{"version":3,"sources":["webpack://./src/pages/WorkspaceSettings/CustomFields/CustomFields.module.scss"],"names":[],"mappings":"AACI,4CACI,eAAA","sourcesContent":[".CustomFields {\n    .SettingTitle {\n        margin-top: 14px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CustomFields": "ashA3lDT3sqABoDKYEly",
	"SettingTitle": "JnvvtsATFDg6vRrkFXbn"
};
export default ___CSS_LOADER_EXPORT___;
