// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xsQhEHTRpL_erYcpHEAV{display:flex;flex-flow:column;font-size:14px}.xsQhEHTRpL_erYcpHEAV .W47zMYwTRg17Qst6XTni svg{display:none}.xsQhEHTRpL_erYcpHEAV .W47zMYwTRg17Qst6XTni:hover svg{display:inline-block;font-size:18px;color:var(--color-text-primary)}.xsQhEHTRpL_erYcpHEAV .iZFruAPJMhcCOFPflz9V{font-size:12px;margin-bottom:5px}", "",{"version":3,"sources":["webpack://./src/pages/TestSet/TestSet/components/EditTestSetField/EditTestSetField.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,gBAAA,CACA,cAAA,CAGI,gDACI,YAAA,CAIA,sDACI,oBAAA,CACA,cAAA,CACA,+BAAA,CAKZ,4CACI,cAAA,CACA,iBAAA","sourcesContent":[".Info {\n    display: flex;\n    flex-flow: column;\n    font-size: 14px;\n\n    .EditTestSetField {\n        svg {\n            display: none;\n        }\n\n        &:hover {\n            svg {\n                display: inline-block;\n                font-size: 18px;\n                color: var(--color-text-primary);\n            }\n        }\n    }\n\n    .Label {\n        font-size: 12px;\n        margin-bottom: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Info": "xsQhEHTRpL_erYcpHEAV",
	"EditTestSetField": "W47zMYwTRg17Qst6XTni",
	"Label": "iZFruAPJMhcCOFPflz9V"
};
export default ___CSS_LOADER_EXPORT___;
