export enum EPriority {
    VERY_LOW = 'VERY_LOW',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
    VERY_HIGH = 'VERY_HIGH',
}

export enum EWorkStatus {
    READY = 'READY',
    DRAFT = 'DRAFT',
}

export enum EStepType {
    STEP_PRECONDITION = 'STEP_PRECONDITION',
    DEFAULT_STEP = 'DEFAULT_STEP',
    STEP_POSTCONDITION = 'STEP_POSTCONDITION',
}

export enum EWorkType {
    CLICK = 'CLICK',
}
