import React, { Dispatch, Key, SetStateAction, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
    createDateIntervalFilters,
    createSort,
    createSorter,
    getColumnDateSearchProps,
    getColumnSearchProps,
    setPositionOnContextMenu,
    toDateFormat,
} from '@/utils'

import { Button, Table, TablePaginationConfig, Typography } from 'antd'
import type { TableColumnsType } from 'antd'
import { ProjectsOptions } from './ProjectsOptions'

import { DownOutlined, MoreOutlined, UpOutlined } from '@ant-design/icons'

import { SpinnerStore } from '@/store'
import { pageUrls } from '@/consts'

import { dropdownPositionType, IProject, ISort, IUserCommonInfo, TableFilterType } from '@/types'
import { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface'

const { Text } = Typography

interface IProjectList {
    projects: IProject[]
    setProjects: Dispatch<SetStateAction<IProject[]>>
    loadData: () => void
    pageNumber: number
    elementsCount: number
    pageSize: number
    setPageNumber: Dispatch<SetStateAction<number>>
    setPageSize: Dispatch<SetStateAction<number>>
    sort: ISort[]
    setSort: Dispatch<SetStateAction<ISort[]>>
    filter: TableFilterType
    setFilter: Dispatch<SetStateAction<TableFilterType>>
    setShowTableMenu: Dispatch<SetStateAction<boolean>>
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    selectedRowKeys: Key[]
}

interface ITableColumns {
    (projects: IProject[], sort: ISort[], filter: TableFilterType): ColumnsType<IProject> | any
}

export const ProjectsTable = observer((props: IProjectList) => {
    const {
        projects,
        setProjects,
        loadData,
        pageNumber,
        elementsCount,
        pageSize,
        setPageNumber,
        setPageSize,
        sort,
        setSort,
        filter,
        setFilter,
        setShowTableMenu,
        setSelectedRowKeys,
        selectedRowKeys,
    } = props
    const { t } = useTranslation(['page--projects'])
    const [filterName, setFilterName] = useState<string[]>((filter?.projectName as string[]) || [])
    const [filterDescription, setFilterDescription] = useState<string[]>((filter?.projectDescription as string[]) || [])
    const [filterCustomer, setFilterCustomer] = useState<string[]>((filter?.customer as string[]) || [])
    const [dropdownVisibleName, setDropdownVisibleName] = useState(false)
    const [dropdownVisibleDescription, setDropdownVisibleDescription] = useState(false)
    const [dropdownVisibleCustomer, setDropdownVisibleCustomer] = useState(false)

    const [selectedRecord, setSelectedRecord] = useState<IProject | undefined>()
    const [dropdownPosition, setDropdownPosition] = useState<dropdownPositionType>({
        x: 0,
        y: 0,
    })

    const onSelectChange = (newSelectedRowKeys: Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
        setShowTableMenu(!!newSelectedRowKeys.length)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const expandedRowRender = (record: IProject) => {
        const columns: TableColumnsType<IProject> = [
            { title: t('page--projects:id'), dataIndex: 'orderNum', key: 'orderNum' },
            {
                title: t('page--projects:projectCreator'),
                dataIndex: 'createdBy',
                key: 'createdBy',
                className: 'expandedColumn',
                render: (createdBy: IUserCommonInfo, project: IProject) => createdBy?.username,
            },
            {
                title: t('page--projects:testFolderCount'),
                dataIndex: 'testFolderCount',
                key: 'testFolderCount',
                className: 'expandedColumn',
            },
            {
                title: t('page--projects:testCaseCount'),
                dataIndex: 'testCaseCount',
                key: 'testCaseCount',
                className: 'expandedColumn',
            },
            {
                title: t('page--projects:stepCount'),
                dataIndex: 'stepCount',
                key: 'stepCount',
                className: 'expandedColumn',
            },
            {
                title: t('page--projects:commonStepCount'),
                dataIndex: 'commonStepCount',
                key: 'commonStepCount',
                className: 'expandedColumn',
            },
        ]

        return <Table columns={columns} dataSource={[record]} pagination={false} />
    }

    const handleReset = (title?: string) => {
        switch (title) {
            case 'name' || 'projectName':
                setFilterName([])
                setDropdownVisibleName(false)
                break
            case 'projectDescription':
                setFilterDescription([])
                setDropdownVisibleDescription(false)
                break
            case 'customer':
                setFilterCustomer([])
                setDropdownVisibleCustomer(false)
                break
        }
    }

    const getTableColumns: ITableColumns = (projects, sort, filter) => {
        const sorter = createSorter(sort)
        if (!projects) return []
        return [
            {
                title: t('page--projects:name'),
                key: 'projectName',
                className: 'linkColumn',
                ...sorter('projectName'),
                ...getColumnSearchProps(t, {
                    column: 'projectName',
                    text: t('page--projects:name'),
                    defaultValue: filterName,
                    setDefaultValue: setFilterName,
                    dropdownVisible: dropdownVisibleName,
                    setDropdownVisible: setDropdownVisibleName,
                    clearFilters: () => handleReset('projectName'),
                    setFilter: setFilter,
                }),
                filteredValue: filter?.projectName,
                render: (projectName: string, project: IProject) => (
                    <Link draggable="false" to={`${pageUrls.project}/${project?.id}`}>
                        <Text ellipsis={{ tooltip: projectName }}>{projectName}</Text>
                    </Link>
                ),
            },
            {
                title: t('page--projects:description'),
                dataIndex: 'projectDescription',
                key: 'projectDescription',
                className: 'linkColumn',
                ...getColumnSearchProps(t, {
                    column: 'projectDescription',
                    text: t('page--projects:description'),
                    defaultValue: filterDescription,
                    setDefaultValue: setFilterDescription,
                    dropdownVisible: dropdownVisibleDescription,
                    setDropdownVisible: setDropdownVisibleDescription,
                    clearFilters: () => handleReset('projectDescription'),
                    setFilter: setFilter,
                }),
                filteredValue: filter?.projectDescription,
                render: (description: string, project: IProject) => (
                    <Link draggable="false" to={`${pageUrls.project}/${project?.id}`}>
                        <Text ellipsis={{ tooltip: description }}>{description}</Text>
                    </Link>
                ),
            },

            {
                title: t('page--projects:customer'),
                key: 'customer',
                className: 'linkColumn',
                ...sorter('customer'),
                ...getColumnSearchProps(t, {
                    column: 'customer',
                    text: t('page--projects:customer'),
                    defaultValue: filterCustomer,
                    setDefaultValue: setFilterCustomer,
                    dropdownVisible: dropdownVisibleCustomer,
                    setDropdownVisible: setDropdownVisibleCustomer,
                    clearFilters: () => handleReset('customer'),
                    setFilter: setFilter,
                }),
                filteredValue: filter?.projectName,
                render: (customer: string, project: IProject) => (
                    <Link draggable="false" to={`${pageUrls.project}/${project?.id}`}>
                        <Text ellipsis={{ tooltip: customer }}>{customer}</Text>
                    </Link>
                ),
            },

            {
                title: t('page--projects:lastModifiedDate'),
                key: 'lastModifiedDate',
                className: 'linkColumn',
                ...sorter('lastModifiedDate'),
                ...getColumnDateSearchProps(t, {
                    column: 'lastModifiedDate',
                    text: t('page--projects:lastModifiedDate').toLocaleLowerCase(),
                    isRangeDate: true,
                    withTime: true,
                    filter,
                    setFilter,
                    loadData,
                }),
                filtered: filter?.lastModifiedStartDate || filter?.lastModifiedEndDate,
                render: (lastModifiedDate: number, project: IProject) => (
                    <Link draggable="false" to={`${pageUrls.project}/${project?.id}`}>
                        <span>{toDateFormat(lastModifiedDate)}</span>
                    </Link>
                ),
            },
            {
                title: t('page--projects:createdDate'),
                key: 'createdDate',
                defaultSortOrder: 'descend',
                className: 'linkColumn',
                ...sorter('createdDate'),
                ...getColumnDateSearchProps(t, {
                    column: 'createdDate',
                    text: t('page--projects:createdDate').toLocaleLowerCase(),
                    isRangeDate: true,
                    withTime: true,
                    filter,
                    setFilter,
                    loadData,
                }),
                filtered: filter?.createdStartDate || filter?.createdEndDate,

                render: (createdDate: number, project: IProject) => (
                    <Link draggable="false" to={`${pageUrls.project}/${project?.id}`}>
                        <span>{toDateFormat(createdDate)}</span>
                    </Link>
                ),
            },
            {
                title: '',
                key: 'operation',
                className: 'dropdownMenu',
                render: (id: string, project: IProject) => (
                    <ProjectsOptions
                        project={project}
                        projects={projects}
                        setProjects={setProjects}
                        loadData={loadData}
                        elementsCount={elementsCount}
                        pageSize={pageSize}
                        button={<Button type="text" icon={<MoreOutlined />} />}
                        setSelectedRecord={setSelectedRecord}
                    />
                ),
            },
        ]
    }

    const createFilter = (
        filters: Record<string, FilterValue | null>,
        interceptor?: (filters: TableFilterType) => TableFilterType
    ) => {
        const result = Object.fromEntries(
            Object.entries(filters)
                .map(([filter, terms]) => [filter, terms ?? []])
                .filter(([, terms]) => (Array.isArray(terms) ? terms.length > 0 : terms))
        ) as TableFilterType

        return interceptor?.(createDateIntervalFilters(result, filter)) ?? createDateIntervalFilters(result, filter)
    }

    const columns = useMemo(
        () => getTableColumns(projects, sort, filter),
        [
            t,
            projects,
            sort,
            filter,
            dropdownVisibleName,
            dropdownVisibleDescription,
            dropdownVisibleCustomer,
            filterName,
            filterDescription,
            filterCustomer,
        ]
    )

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[]
    ) => {
        setPageNumber(pagination.current!)
        setPageSize(pagination.pageSize!)
        setFilter(createFilter(filters))
        setSort(createSort(sorter, sort))
    }

    return (
        <>
            <Table
                columns={columns}
                onChange={handleTableChange}
                expandable={{
                    expandedRowRender: expandedRowRender,
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <UpOutlined
                                onClick={(e) => {
                                    onExpand(record, e)
                                }}
                            />
                        ) : (
                            <DownOutlined
                                onClick={(e) => {
                                    onExpand(record, e)
                                }}
                            />
                        ),
                }}
                dataSource={projects}
                rowKey={'id'}
                onRow={(record) => {
                    return {
                        onContextMenu: (e) =>
                            setPositionOnContextMenu({ e, record, setSelectedRecord, setDropdownPosition }),
                    }
                }}
                pagination={{
                    showSizeChanger: true,
                    pageSize: pageSize,
                    defaultCurrent: pageNumber,
                    total: elementsCount,
                    hideOnSinglePage: true,
                }}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                size="small"
                loading={SpinnerStore.show}
            />

            {selectedRecord && dropdownPosition && (
                <ProjectsOptions
                    project={selectedRecord}
                    projects={projects}
                    setProjects={setProjects}
                    loadData={loadData}
                    elementsCount={elementsCount}
                    pageSize={pageSize}
                    dropdownPosition={dropdownPosition}
                    setSelectedRecord={setSelectedRecord}
                />
            )}
        </>
    )
})
