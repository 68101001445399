import React from 'react'

import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, Modal } from 'antd'

import { SpinnerStore, StoreCreateTestSet, StoreProject, StoreTestSet } from '@/store'
import { pageUrls } from '@/consts'

import { ICreateTestSet } from '@/types'
import { EWorkStatus } from '@/enums'

import styles from './CreateTestSetButtons.module.scss'

type Props = {
    submittableForm: boolean
}

export const CreateTestSetButtons = observer((props: Props) => {
    const { submittableForm } = props
    const navigate = useNavigate()

    const { t } = useTranslation(['page--createTestSet', 'common', 'notification'])

    const withLink = (id: string) => {
        navigate(`${pageUrls.testSet}/${id}`)
    }

    const prepareTestSetData = (testCaseIds: string[]) => {
        const testCasesId = testCaseIds.map((id) => ({ testCaseId: id }))

        const testSet: ICreateTestSet = {
            name: StoreCreateTestSet.name,
            description: StoreCreateTestSet.description,
            testSoftware: StoreCreateTestSet.testSoftware,
            versionSoftware: StoreCreateTestSet.versionSoftware,
            beginningPeriod:
                !StoreCreateTestSet?.executionPeriod?.includes(null) && StoreCreateTestSet?.executionPeriod !== null
                    ? dayjs(StoreCreateTestSet?.executionPeriod![0]).unix()
                    : 0,
            endingPeriod:
                !StoreCreateTestSet?.executionPeriod?.includes(null) && StoreCreateTestSet?.executionPeriod !== null
                    ? dayjs(StoreCreateTestSet?.executionPeriod![1]).unix()
                    : 0,

            folderId: StoreCreateTestSet.selectedTestFolderId,
            testerId: StoreCreateTestSet.selectedUser,

            executionProcessRequestsDto: {
                executionProcessRequestDto: testCasesId,
            },
        }
        return testSet
    }

    const handleFinishCreating = () => {
        StoreCreateTestSet.resetTestSet()
        navigate(
            `${pageUrls.project}/${StoreProject.id}${pageUrls.testSets}?testFolder=${StoreTestSet.selectedTestSetFolderId}`
        )
    }

    const createTestSet = async (testSet: ICreateTestSet) => {
        try {
            await StoreTestSet.createTestSet(testSet, withLink)
            handleFinishCreating()
        } catch {
            return
        }
    }

    const onCreateTestSet = async () => {
        if (StoreCreateTestSet.selectedRowKeys.length) {
            if (
                StoreCreateTestSet.selectedTestCases.some(
                    (testCase) => !!testCase.testCaseBlockInfo || testCase.workStatus === EWorkStatus.DRAFT
                )
            ) {
                const readyTestCaseIds = StoreCreateTestSet.validateAddingToTestCycle()
                if (readyTestCaseIds.length) {
                    const readyTestSet = prepareTestSetData(readyTestCaseIds)
                    Modal.warning({
                        title: t('notification:warning.testCaseCannotBeAdded', {
                            status: t(`statuses:${EWorkStatus.DRAFT}`),
                        }),
                        centered: true,
                        onOk: () => createTestSet(readyTestSet),
                    })
                } else {
                    Modal.warning({
                        title: t('page--createTestSet:noTestCasesTooltip'),
                        centered: true,
                    })
                }
            } else {
                const readyTestSet = prepareTestSetData(StoreCreateTestSet.selectedRowKeys as string[])
                createTestSet(readyTestSet)
            }
        } else {
            Modal.warning({
                title: t('page--createTestSet:noTestCasesTooltip'),
                centered: true,
            })
        }
    }

    const onLeaveTestSet = () => {
        StoreCreateTestSet.resetTestSet()
        navigate(-1)
    }

    const disabled = !submittableForm || !StoreCreateTestSet.selectedRowKeys.length || SpinnerStore.show

    return (
        <div className={styles.CreateTestSetButtons}>
            <Button type="default" onClick={onLeaveTestSet}>
                {t('common:buttons.cancel')}
            </Button>
            <Button type="primary" onClick={onCreateTestSet} disabled={disabled}>
                {t('common:buttons.create')}
            </Button>
        </div>
    )
})
