import React, { FC } from 'react'

import { useTranslation } from 'react-i18next'

import { Tooltip } from 'antd'
import { AutoIcon } from '@/components/Icons/AutoTestCase/AutoIcon'
import { ManualIcon } from '@/components/Icons/ManualTestCase/ManualIcon'

interface IconComponentProps {
    isAutomated: boolean
    className: string
}

const IconComponent: FC<IconComponentProps> = ({ isAutomated, className }) => {
    const { t } = useTranslation()

    return isAutomated ? (
        <Tooltip title={t('page--testCases:type.automated')} placement="right">
            <AutoIcon className={className} />
        </Tooltip>
    ) : (
        <Tooltip title={t('page--testCases:type.manual')} placement="right">
            <ManualIcon className={className} />
        </Tooltip>
    )
}

export default IconComponent
