import React, { FC, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, Layout, Tooltip, Typography } from 'antd'
import { CreateModal } from '@/components/CreateModal/CreateModal'
import { DataPlaceholder } from '@/components/DataPlaceholder/DataPlaceholder'
import { Header } from '@/components/Header/Header'
import Spinner from '@/components/Spinner/Spinner'
import { WorkspaceCard } from './WorkspaceCard'

import { PlusOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreCreateTestSet, StoreProject, StoreTestCase, StoreWorkspace } from '@/store'
import { maxNumberOfWorkspaces } from '@/consts'

import { IWorkspace } from '@/types'
import { EModalType } from '@/enums'

import styles from './Workspaces.module.scss'

const { Title } = Typography
const { Content } = Layout

export const Workspaces: FC = observer(() => {
    const { t } = useTranslation(['page--workspaces'])

    const [workspaces, setWorkspaces] = useState<IWorkspace[]>([])
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false)
    const [showPlaceholder, setShowPlaceholder] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isCreateTootlipVisible, setCreateTootlipVisible] = useState(false)

    const createWorkspace = () => {
        setIsModalCreateOpen(true)
    }

    useEffect(() => {
        setLoading(true)
        StoreWorkspace.getWorkspaces().then((resp) => {
            setWorkspaces(resp)
            !resp.length ? setShowPlaceholder(true) : setShowPlaceholder(false)
            setLoading(false)
        })
    }, [])

    useEffect(() => {
        StoreTestCase.executionTestCase && StoreTestCase.resetTestCaseData()
        StoreCreateTestSet.isCreatingTestSet && StoreCreateTestSet.resetTestSet()
        StoreProject.setProjectId('')
        StoreWorkspace.setProjectList([])
    }, [])

    const disabledAddNewWorkspace = SpinnerStore.show || loading || workspaces.length >= maxNumberOfWorkspaces

    return (
        <div className={styles.Workspaces}>
            <Header />

            {!showPlaceholder ? (
                <Content className="content">
                    <div className={styles.Menu}>
                        <Title level={3} className={styles.Title}>
                            {t('page--workspaces:workspaces')}
                        </Title>
                        <Tooltip
                            title={
                                disabledAddNewWorkspace ? t('notification:error.maxAmountOfWorkspacesIs') : undefined
                            }
                        >
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={createWorkspace}
                                disabled={disabledAddNewWorkspace}
                            >
                                {t('page--workspaces:workspace')}
                            </Button>
                        </Tooltip>
                    </div>

                    {SpinnerStore.show || loading ? (
                        <Spinner show={SpinnerStore.show || loading} />
                    ) : (
                        <div className={styles.WorkspaceCards}>
                            {workspaces?.map((workspace) => <WorkspaceCard key={workspace.id} workspace={workspace} />)}
                        </div>
                    )}
                </Content>
            ) : (
                <Content className={styles.Content}>
                    <DataPlaceholder
                        title={t('page--workspaces:createWorkspace')}
                        description={t('page--workspaces:createWorkspaceDescription')}
                        onCreate={createWorkspace}
                    />
                </Content>
            )}
            <CreateModal
                isModalCreateOpen={isModalCreateOpen}
                setIsModalCreateOpen={setIsModalCreateOpen}
                modalType={EModalType.WORKSPACE}
            />
        </div>
    )
})
