import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import dayjs from 'dayjs'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'
import { JiraUpload } from './JiraUpload'

import { StoreProject } from '@/store'
import { jiraDateFormat } from '@/consts'

import { ICreateJiraIssue, IJiraCustomFields, IJiraEpic, IJiraIssue, IJiraSprint, IJiraUser, IStep } from '@/types'
import { EEntityType, EJiraCustomFields } from '@/enums'
import type { UploadFile } from 'antd/es/upload/interface'

import 'react-quill/dist/quill.snow.css'

const { TextArea } = Input

type Props = {
    step: IStep
    steps: IStep[]
    setSteps: Dispatch<SetStateAction<IStep[]>>
    isShownCreateJiraTaskModal: boolean
    setIsShownCreateJiraTaskModal: Dispatch<SetStateAction<boolean>>
}

export const ModalCreateIssue = observer((props: Props) => {
    const { step, steps, setSteps, isShownCreateJiraTaskModal, setIsShownCreateJiraTaskModal } = props
    const { t } = useTranslation(['testCase', 'page--auth', 'common', 'validation'])

    const requiredRule = [
        {
            required: true,
            message: t('validation:required'),
        },
    ]

    const [form] = Form.useForm()
    const [formWithCustomFields] = Form.useForm()
    const [sprints, setSprints] = useState<IJiraSprint[]>([])
    const [epics, setEpics] = useState<IJiraEpic[]>([])
    const [users, setUsers] = useState<IJiraUser[]>([])
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [customFields, setCustomFields] = useState<IJiraCustomFields[]>([])
    const [submittableCommonForm, setSubmittableCommonForm] = useState(false)
    const [submittableCustomForm, setSubmittableCustomForm] = useState(false)

    const handleCancel = () => {
        form.resetFields()
        formWithCustomFields.resetFields()
        setFileList([])
        setIsShownCreateJiraTaskModal(false)
    }

    useEffect(() => {
        if (StoreProject.id) {
            const jiraDictFilterProps = {
                projectId: StoreProject.id,
            }

            !customFields.length &&
                StoreProject.getJiraCustomFields(StoreProject.id).then((CFs) => setCustomFields(CFs))
            !epics.length && StoreProject.getJiraEpics(jiraDictFilterProps).then((epics) => setEpics(epics))
            !sprints.length && StoreProject.getJiraSprints(jiraDictFilterProps).then((sprints) => setSprints(sprints))
            !users.length && StoreProject.getJiraUsers(jiraDictFilterProps).then((users) => setUsers(users))
        }
    }, [StoreProject.id])

    const formInitialValues = {
        taskType: t('testCase:jira.error'),
        summary: '',
        description: '',
        reporter: '',
        assignee: '',
        sprint: '',
        epic: '',
    }

    const values = Form.useWatch([], form)
    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittableCommonForm(true)
            },
            () => {
                setSubmittableCommonForm(false)
            }
        )
    }, [values])

    const customValues = Form.useWatch([], formWithCustomFields)
    useEffect(() => {
        formWithCustomFields.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittableCustomForm(true)
            },
            () => {
                setSubmittableCustomForm(false)
            }
        )
    }, [customValues])

    const updateIssueList = (newIssue: IJiraIssue) => {
        const stepsCopy = [...steps]
        const stepToUpdate = stepsCopy.find((exeStep) => exeStep.id === step.id)
        stepToUpdate && stepToUpdate.jiraIssuesWithData?.push(newIssue)
        setSteps(stepsCopy)
    }

    const onCreateJiraIssue = async () => {
        const fieldsValues = { ...form.getFieldsValue() }
        delete fieldsValues['taskType']
        const customfieldsValues = formWithCustomFields.getFieldsValue()

        const formattedCustomfieldsValues: any[] = []
        Object.entries(customfieldsValues).forEach((key) => {
            if (key[1]) {
                const field = customFields.find((field) => field.name === key[0])
                if (field?.fieldType === EJiraCustomFields.DATETIME) {
                    const el = {
                        fieldId: field?.fieldId,
                        value: key[1] ? dayjs(key[1] as Date).format('YYYY-MM-DDTHH:mm:ss.0ZZ') : '',
                    }
                    formattedCustomfieldsValues.push(el)
                    return
                }
                if (field?.fieldType === EJiraCustomFields.NUMBER) {
                    const el = {
                        fieldId: field?.fieldId,
                        value: +key[1] || 0,
                    }
                    formattedCustomfieldsValues.push(el)
                    return
                }
                if (
                    field?.fieldType === EJiraCustomFields.SINGLE_ATTRIBUTE ||
                    field?.fieldType === EJiraCustomFields.ATTRIBUTE_LIST
                ) {
                    const el = {
                        fieldId: field?.fieldId,
                        values: key[1] ? (Array.isArray(key[1]) ? key[1] : [key[1]]) : [],
                    }
                    formattedCustomfieldsValues.push(el)
                } else {
                    const el = {
                        fieldId: field?.fieldId,
                        value: key[1] || '',
                    }
                    formattedCustomfieldsValues.push(el)
                }
            }
        })
        const issue = {
            projectId: StoreProject.id,
            entityId: step.id,
            jiraIssueOwner: EEntityType.EXECUTION_STEP,
            ...fieldsValues,
            additionalFields: formattedCustomfieldsValues,
        } as ICreateJiraIssue

        const newIssue = await StoreProject.createJiraIssue(issue, fileList)
        updateIssueList(newIssue)
        handleCancel()
    }

    return (
        <Modal
            title={t('testCase:jira.createTitle')}
            open={isShownCreateJiraTaskModal}
            onOk={onCreateJiraIssue}
            onCancel={handleCancel}
            okButtonProps={{ disabled: !(submittableCommonForm && submittableCustomForm) }}
            centered
        >
            <Form form={form} layout="vertical" initialValues={formInitialValues}>
                <FormItem name="taskType" label={t('testCase:jira.taskType')}>
                    <Input disabled />
                </FormItem>
                <FormItem name="summary" label={t('testCase:jira.subject')} rules={requiredRule}>
                    <Input />
                </FormItem>
                <FormItem id="description" name="description" label={t('testCase:jira.description')}>
                    <TextArea />
                    {/* TODO:  TESTADO-187. p3.4 В первой итерации не добавлять меню форматирования. */}
                    {/* 
                    <ReactQuill
                        id="description"
                        key="description"
                        theme="snow"
                        modules={ReactQuillModules}
                        formats={ReactQuillFormats}
                        className={styles.TextArea}
                    />  */}
                </FormItem>

                <FormItem name="reporter" label={t('testCase:jira.reporter')}>
                    <Select
                        allowClear
                        showSearch
                        fieldNames={{ label: 'displayName', value: 'name' }}
                        filterOption={(input, option) =>
                            (option?.displayName ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={users}
                    />
                </FormItem>

                <FormItem name="assignee" label={t('testCase:jira.assignee')}>
                    <Select
                        allowClear
                        showSearch
                        fieldNames={{ label: 'displayName', value: 'name' }}
                        filterOption={(input, option) =>
                            (option?.displayName ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={users}
                    />
                </FormItem>

                <FormItem name="sprint" label={t('testCase:jira.sprint')}>
                    <Select
                        allowClear
                        showSearch
                        fieldNames={{ label: 'name', value: 'id' }}
                        filterOption={(input, option) =>
                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={sprints}
                    />
                </FormItem>
                <FormItem name="epic" label={t('testCase:jira.epicLink')} rules={requiredRule}>
                    <Select
                        allowClear
                        showSearch
                        fieldNames={{ label: 'name', value: 'key' }}
                        filterOption={(input, option) =>
                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={epics}
                    />
                </FormItem>
            </Form>
            <Form form={formWithCustomFields} layout="vertical">
                {customFields.map((field) => (
                    <FormItem
                        key={field.fieldId}
                        name={field.name}
                        label={field.name}
                        rules={field.isRequired || field.fieldId === 'priority' ? requiredRule : undefined}
                    >
                        {field.fieldType === EJiraCustomFields.TEXT && <Input value={field.value} />}
                        {field.fieldType === EJiraCustomFields.NUMBER && <InputNumber value={field.value} />}
                        {field.fieldType === EJiraCustomFields.SINGLE_ATTRIBUTE && (
                            <Select
                                allowClear
                                showSearch
                                fieldNames={{ label: 'name', value: 'valueId' }}
                                filterOption={(input, option) =>
                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={field.values}
                            />
                        )}

                        {field.fieldType === EJiraCustomFields.ATTRIBUTE_LIST && (
                            <Select
                                mode="multiple"
                                allowClear
                                showSearch
                                fieldNames={{ label: 'name', value: 'valueId' }}
                                filterOption={(input, option) =>
                                    (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={field.values}
                            />
                        )}

                        {field.fieldType === EJiraCustomFields.DATETIME && (
                            <DatePicker format={jiraDateFormat} showTime />
                        )}
                    </FormItem>
                ))}

                <FormItem name="files" label={t('testCase:jira.files')}>
                    <JiraUpload fileList={fileList} setFileList={setFileList} />
                </FormItem>
            </Form>
        </Modal>
    )
})
