import React, { Dispatch, MouseEvent, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, Tooltip } from 'antd'
import { FolderDropdownOptions } from './FolderDropdownOptions'

import { MoreOutlined, PlusOutlined, RetweetOutlined } from '@ant-design/icons'

import { StoreTestFolder } from '@/store'
import { folderTabs, pageUrls } from '@/consts'

import { ITestFolder } from '@/types'
import { EModalTypeFolder } from '@/enums'

import styles from '../FoldersTree/FoldersTab.module.scss'

type Props = {
    folder: ITestFolder
    folders: ITestFolder[]
    setTestFolders: Dispatch<SetStateAction<ITestFolder[]>>
    setSelectedForActionFolder: Dispatch<SetStateAction<ITestFolder | undefined>>
    setSelectedFolderId: Dispatch<SetStateAction<string>>
    setIsModalCreateFolderOpen: Dispatch<SetStateAction<boolean>>
    setIsModalRenameOpen: Dispatch<SetStateAction<boolean>>
    setIsModalSelectOpen: Dispatch<SetStateAction<boolean>>
    setModalTypeFolder: Dispatch<SetStateAction<EModalTypeFolder | undefined>>
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
    onCreateSubFolder: (folderId: string, nestingLevel: number, e?: MouseEvent) => void
}

export const FolderOptions = observer((props: Props) => {
    const {
        folder,
        folders,
        setTestFolders,
        setSelectedForActionFolder,
        setIsModalRenameOpen,
        setIsModalSelectOpen,
        setModalTypeFolder,
        pageType,
        onCreateSubFolder,
        setSelectedFolderId,
    } = props

    const { t } = useTranslation(['page--testFolders'])

    const onCreateClick = (e: MouseEvent) => {
        e.stopPropagation()
        onCreateSubFolder(folder.id, folder.nestingLevel, e)
    }

    const onRestore = (e: MouseEvent) => {
        e.stopPropagation()
        setModalTypeFolder(EModalTypeFolder.RESTORE)
        setSelectedForActionFolder(folder)
        setIsModalSelectOpen(true)
    }

    return (
        <div className={styles.Options}>
            {StoreTestFolder.currentFolderTab !== folderTabs.archive ? (
                <>
                    <FolderDropdownOptions
                        folder={folder}
                        button={
                            <Button
                                type="text"
                                size="small"
                                icon={<MoreOutlined />}
                                onClick={(e) => e.stopPropagation()}
                            />
                        }
                        folders={folders}
                        setTestFolders={setTestFolders}
                        setSelectedForActionFolder={setSelectedForActionFolder}
                        setSelectedFolderId={setSelectedFolderId}
                        setIsModalRenameOpen={setIsModalRenameOpen}
                        setIsModalSelectOpen={setIsModalSelectOpen}
                        setModalTypeFolder={setModalTypeFolder}
                        pageType={pageType}
                        onCreateSubFolder={onCreateSubFolder}
                    />
                    <Tooltip title={t('page--testFolders:createFolder')}>
                        <Button type="text" size="small" icon={<PlusOutlined />} onClick={onCreateClick} />
                    </Tooltip>
                </>
            ) : (
                <Tooltip title={t('page--testFolders:restore')}>
                    <Button type="text" size="small" icon={<RetweetOutlined />} onClick={onRestore} />
                </Tooltip>
            )}
        </div>
    )
})
