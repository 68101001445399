import i18n from '@/i18n'
import dayjs from 'dayjs'
import { TOptions } from 'i18next'
import { observable, runInAction } from 'mobx'

import { LocalStorage } from '@/utils/localStorage'

import type { Locale } from 'antd/es/locale'

import 'dayjs/locale/en'
import 'dayjs/locale/ru'

interface Module<T> {
    default: T
}

interface Language {
    name: string
    locale: string
    loadAntdLocale: () => Promise<Module<Locale>>
}

const LOCALE = 'PreferencesStore/locale'

export const PreferencesStore = observable({
    _locale: null as null | string,
    antdLocale: undefined as undefined | Locale,

    get itemsPerPage() {
        return 10
    },

    languages: [
        {
            name: 'English',
            locale: 'en',
            loadAntdLocale: () => import(`antd/es/locale/en_US`),
        },
        {
            name: 'Русский',
            locale: 'ru',
            loadAntdLocale: () => import(`antd/es/locale/ru_RU`),
        },
    ] as Language[],

    get locale() {
        return this._locale || LocalStorage.get(LOCALE) || 'en'
    },

    set locale(value: string) {
        const language = this.languages.find((l) => l.locale === value)
        if (!language) {
            return
        }
        i18n.changeLanguage(value)
        this._locale = value
        LocalStorage.set(LOCALE, this._locale)
        language.loadAntdLocale().then((antdLocaleModule) => {
            runInAction(
                () =>
                    (this.antdLocale =
                        (antdLocaleModule.default as unknown as Module<Locale>).default ?? antdLocaleModule.default) // For some weird reason, it's wrapped twice
            )
        })

        if (dayjs.locale(value) === 'en') {
            dayjs.locale('en')
        } else if (dayjs.locale(value) === 'ru') {
            dayjs.locale('ru')
        }
    },

    init() {
        this.locale = this.locale
    },

    async t(key: string, options: TOptions) {
        const ns = key.split(':')[0]
        await i18n.loadNamespaces(ns)
        return i18n.getFixedT(this.locale)(key, options)
    },
})

PreferencesStore.init()
