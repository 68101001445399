// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ew088Wlz7AP9Du4JRTZh{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:.25rem;width:28px;height:28px;gap:8px;color:var(--color-primary6);border-radius:50%;border:1px solid var(--color-Neutral5)}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Header/TestCaseTypeButtons/AutoTestCaseIcon/AutoTestCaseIcon.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CACA,OAAA,CACA,2BAAA,CACA,iBAAA,CACA,sCAAA","sourcesContent":[".AutoTestCaseIcon {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    padding: 0.25rem;\n    width: 28px;\n    height: 28px;\n    gap: 8px;\n    color: var(--color-primary6);\n    border-radius: 50%;\n    border: 1px solid var(--color-Neutral5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AutoTestCaseIcon": "Ew088Wlz7AP9Du4JRTZh"
};
export default ___CSS_LOADER_EXPORT___;
