// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/theme/Light/home-background-with-logo.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/images/theme/Dark/home-background-with-logo.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RygkJgZmBHTETlLP3C3i .FGW1e_yLFln2DpS2kKzN{display:flex;align-items:center;height:100vh;margin-left:136px}.s5cKIEHp3XAhGc85kFjc{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover}.p89Rpyw1Q75YRaAStgC3{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-size:cover}", "",{"version":3,"sources":["webpack://./src/pages/AuthPage/AuthPage.module.scss"],"names":[],"mappings":"AACI,4CACI,YAAA,CACA,kBAAA,CACA,YAAA,CACA,iBAAA,CAIR,sBACI,wDAAA,CACA,2BAAA,CACA,qBAAA,CAGJ,sBACI,wDAAA,CACA,2BAAA,CACA,qBAAA","sourcesContent":[".AuthPage {\n    .SignInWrapper {\n        display: flex;\n        align-items: center;\n        height: 100vh;\n        margin-left: 136px;\n    }\n}\n\n.LightTheme {\n    background-image: url('/public/images/theme/Light/home-background-with-logo.svg');\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.DarkTheme {\n    background-image: url('/public/images/theme/Dark/home-background-with-logo.svg');\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AuthPage": "RygkJgZmBHTETlLP3C3i",
	"SignInWrapper": "FGW1e_yLFln2DpS2kKzN",
	"LightTheme": "s5cKIEHp3XAhGc85kFjc",
	"DarkTheme": "p89Rpyw1Q75YRaAStgC3"
};
export default ___CSS_LOADER_EXPORT___;
