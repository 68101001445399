import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { showMessage, toDateFormat } from '@/utils'

import { Button, Tooltip } from 'antd'

import { LockOutlined, UnlockOutlined } from '@ant-design/icons'

import { StoreAuthController, StoreTestCase, StoreTestCaseList } from '@/store'
import { pageUrls } from '@/consts'

import { ITestCaseBlockInfo } from '@/types'

type Props = {
    blockInfo: ITestCaseBlockInfo | null
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
    mergeSteps?: () => void
    hasUnlockTestCasePermission?: boolean
    page?: (typeof pageUrls)[keyof typeof pageUrls]
    navBar?: boolean
}

export const TestCaseLocker = observer((props: Props) => {
    const { blockInfo, setTestCaseSavedStatus, hasUnlockTestCasePermission, page, navBar, mergeSteps } = props
    const { t } = useTranslation(['testCase', 'notification'])

    const handleUnlock = async () => {
        if (!StoreTestCase.isSaved && mergeSteps && setTestCaseSavedStatus) {
            mergeSteps()
            await StoreTestCase.updateTestCase()
            await setTestCaseSavedStatus(true)
        } else {
            await StoreTestCase.toggleBlocked(false)
        }

        if (page === pageUrls.testCase) {
            await StoreTestCaseList.getAllTestCases()
        }
        showMessage('success', t('notification:success.testCaseUnlocked'))
    }

    const isLockedByCurrentUser = blockInfo && StoreAuthController.userId === blockInfo?.userId
    const unlockTooltip = StoreTestCase.isSaved ? t('testCase:unlock') : t('testCase:saveUnlock')

    return (
        <>
            {navBar && isLockedByCurrentUser ? null : (
                <Tooltip
                    title={
                        blockInfo && !isLockedByCurrentUser
                            ? t('testCase:lockedBy', {
                                  name: blockInfo?.userName,
                                  date: toDateFormat(blockInfo?.createdDate),
                              })
                            : unlockTooltip
                    }
                >
                    {isLockedByCurrentUser || hasUnlockTestCasePermission ? (
                        <Button shape="circle" size="small" danger icon={<UnlockOutlined />} onClick={handleUnlock} />
                    ) : (
                        <Button shape="circle" size="small" icon={<LockOutlined />} />
                    )}
                </Tooltip>
            )}
        </>
    )
})
