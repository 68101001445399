import React, { FC, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Input, Select, Typography } from 'antd'
import { UserCard } from '@/components/UserCard/UserCard'
import { TestCaseLinks } from './TestCaseLinks'
import { TestCaseNormativeTime } from './TestCaseNormativeTime'

import { DownOutlined } from '@ant-design/icons'

import { StoreTestCase } from '@/store'

import { EPriority, EWorkStatus } from '@/enums'

import styles from './TestCaseSettings.module.scss'

const { Text } = Typography

export const TestCaseSettings: FC = observer(() => {
    const { t } = useTranslation(['testCase', 'statuses'])

    const onPriorityChange = (value: EPriority) => {
        StoreTestCase.setTestCasePriority(value)
        StoreTestCase.setIsSaved(false)
    }

    const onStatusChange = (value: EWorkStatus) => {
        StoreTestCase.setTestCaseStatus(value)
        StoreTestCase.setIsSaved(false)
    }

    const priorityOptions = [
        {
            value: EPriority.VERY_LOW,
            label: t('testCase:priorities.VERY_LOW'),
        },
        {
            value: EPriority.LOW,
            label: t('testCase:priorities.LOW'),
        },
        {
            value: EPriority.MEDIUM,
            label: t('testCase:priorities.MEDIUM'),
        },
        {
            value: EPriority.HIGH,
            label: t('testCase:priorities.HIGH'),
        },
        {
            value: EPriority.VERY_HIGH,
            label: t('testCase:priorities.VERY_HIGH'),
        },
    ]

    const workStatuses = useMemo(
        () =>
            Object.entries(EWorkStatus).map(([key, value]) => ({
                value: key,
                label: t(`statuses:${value}`),
            })),
        [t]
    )

    return (
        <div className={styles.SideSettings}>
            {!StoreTestCase.executionTestCase && (
                <Input
                    className="underlinedInput"
                    bordered={false}
                    placeholder={StoreTestCase.testFolderName}
                    disabled
                />
            )}
            <div className={styles.Option}>
                <Text className={styles.Label} type="secondary">
                    {t('testCase:priority')}
                </Text>
                <Select
                    placeholder={t('testCase:priority')}
                    value={StoreTestCase.priority}
                    className="underlinedInput"
                    bordered={false}
                    onChange={onPriorityChange}
                    options={priorityOptions}
                    disabled={StoreTestCase.readOnly}
                    suffixIcon={!StoreTestCase.readOnly ? <DownOutlined /> : null}
                />
            </div>
            <TestCaseNormativeTime />
            <TestCaseLinks />
            {!StoreTestCase.executionTestCase && (
                <div className={styles.Option}>
                    <Text className={styles.Label} type="secondary">
                        {t('testCase:status')}
                    </Text>
                    <Select
                        placeholder={t('testCase:status')}
                        value={StoreTestCase.status}
                        className="underlinedInput"
                        bordered={false}
                        onChange={onStatusChange}
                        options={workStatuses}
                        disabled={StoreTestCase.readOnly}
                        suffixIcon={!StoreTestCase.readOnly ? <DownOutlined /> : null}
                    />
                </div>
            )}

            {StoreTestCase.createdBy?.username && (
                <div className={styles.User}>
                    <UserCard
                        userName={`${StoreTestCase.createdBy?.firstname} ${StoreTestCase.createdBy?.lastname}`}
                        position={t('testCase:author')}
                        avatar={null}
                        size={40}
                        crowned
                    />
                </div>
            )}
        </div>
    )
})
