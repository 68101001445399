export enum EPermissions {
    ADMIN = 'ADMIN',
    CREATE_WORKSPACE = 'CREATE_WORKSPACE',
    DELETE_WORKSPACE = 'DELETE_WORKSPACE',
    GET_WORKSPACE = 'GET_WORKSPACE',
    MANAGE_ALL_WORKSPACE_PROJECTS = 'MANAGE_ALL_WORKSPACE_PROJECTS',
    MANAGE_USERS = 'MANAGE_USERS',
    GET_USER_INFO = 'GET_USER_INFO',
    CREATE_PROJECT = 'CREATE_PROJECT',
    DELETE_PROJECT = 'DELETE_PROJECT',
    GET_PROJECT = 'GET_PROJECT',
    CREATE_TEST_CASE = 'CREATE_TEST_CASE',
    DELETE_TEST_CASE = 'DELETE_TEST_CASE',
    GET_TEST_CASE = 'GET_TEST_CASE',
    EXPORT_TEST_CASE = 'EXPORT_TEST_CASE',
    EXECUTE_TEST_CASE = 'EXECUTE_TEST_CASE',
    CREATE_TEST_SET = 'CREATE_TEST_SET',
    DELETE_TEST_SET = 'DELETE_TEST_SET',
    GET_TEST_SET = 'GET_TEST_SET',
    CREATE_ATTACHMENTS = 'CREATE_ATTACHMENTS',
    DELETE_ATTACHMENTS = 'DELETE_ATTACHMENTS',
    GET_ATTACHMENTS = 'GET_ATTACHMENTS',
}

export enum EAccessLevel {
    ADMIN = 'ADMIN',
    WORKSPACE = 'WORKSPACE',
    PROJECT = 'PROJECT',
    NONE = 'NONE',
}
