import React, { Dispatch, Key, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { StepsPanel } from './StepsPanel/StepsPanel'

import { IStep } from '@/types'
import { EStepType } from '@/enums'

import styles from './TestCaseContent.module.scss'

type Props = {
    preConditions: IStep[]
    setPreConditions: Dispatch<SetStateAction<IStep[]>>
    defaultSteps: IStep[]
    setDefaultSteps: Dispatch<SetStateAction<IStep[]>>
    postConditions: IStep[]
    setPostConditions: Dispatch<SetStateAction<IStep[]>>
    isExecutionTestCase: boolean
    isNewTestCase: boolean
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
}

export const TestCaseContent = observer((props: Props) => {
    const {
        preConditions,
        setPreConditions,
        defaultSteps,
        setDefaultSteps,
        postConditions,
        setPostConditions,
        isExecutionTestCase,
        isNewTestCase,
        setTestCaseSavedStatus,
    } = props

    const { t } = useTranslation(['testCase'])

    const onSelectAll = (
        setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>,
        selectedRowKeys: Key[],
        steps: IStep[]
    ) => {
        if (selectedRowKeys.length) {
            setSelectedRowKeys([])
        } else {
            const stepsCopy = [...steps]
            const allStepsOrderNums = stepsCopy.map((step) => step.orderNum)
            setSelectedRowKeys(allStepsOrderNums)
        }
    }

    return (
        <div className={styles.TestCaseContent}>
            {(!isExecutionTestCase || (isExecutionTestCase && !!preConditions.length)) && (
                <StepsPanel
                    steps={preConditions}
                    setSteps={setPreConditions}
                    stepType={EStepType.STEP_PRECONDITION}
                    onSelectAll={onSelectAll}
                    tableTitleText={t('testCase:steps.stepPrecondition')}
                    isExecutionTestCase={isExecutionTestCase}
                    isNewTestCase={isNewTestCase}
                    setTestCaseSavedStatus={setTestCaseSavedStatus}
                />
            )}
            {(!isExecutionTestCase || (isExecutionTestCase && !!defaultSteps.length)) && (
                <StepsPanel
                    steps={defaultSteps}
                    setSteps={setDefaultSteps}
                    stepType={EStepType.DEFAULT_STEP}
                    onSelectAll={onSelectAll}
                    tableTitleText={t('testCase:steps.defaultStep')}
                    isExecutionTestCase={isExecutionTestCase}
                    isNewTestCase={isNewTestCase}
                    setTestCaseSavedStatus={setTestCaseSavedStatus}
                />
            )}
            {(!isExecutionTestCase || (isExecutionTestCase && !!postConditions.length)) && (
                <StepsPanel
                    steps={postConditions}
                    setSteps={setPostConditions}
                    stepType={EStepType.STEP_POSTCONDITION}
                    onSelectAll={onSelectAll}
                    tableTitleText={t('testCase:steps.stepPostCondition')}
                    isExecutionTestCase={isExecutionTestCase}
                    isNewTestCase={isNewTestCase}
                    setTestCaseSavedStatus={setTestCaseSavedStatus}
                />
            )}
        </div>
    )
})
