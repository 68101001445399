import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { matchPath, useLocation, useParams } from 'react-router-dom'

import { hasProjectPermissions, parseNormativeTime } from '@/utils'

import { Header } from '@/components/Header/Header'
import Spinner from '@/components/Spinner/Spinner'
import { TestCaseComponent } from '@/components/TestCaseComponent/TestCaseComponent'

import { StoreTestCase, StoreTestCaseList, StoreTestFolder, StoreWorkspace } from '@/store'
import { pageUrls } from '@/consts'

import { IShowColumns, ITestCase, ITestFolder } from '@/types'
import { EAttachment, EPermissions } from '@/enums'

import styles from './TestCase.module.scss'

export const TestCasePage = observer(() => {
    const { testCaseId, projectId, folderId } = useParams()
    const { pathname } = useLocation()

    const [testCaseInfo, setTestCaseInfo] = useState<ITestCase>()
    const [isTestCaseLoading, setTestCaseLoading] = useState(false)

    const isNewTestCase = useMemo(
        () =>
            matchPath(
                {
                    path: `/${pageUrls.project}/:id${pageUrls.testFolder}/:id${pageUrls.testCase}/new`,
                },
                pathname
            ),
        [pathname]
    )

    const getTestCase = async (testCaseId: string) => {
        // StoreTestCase.resetTestCaseData()
        const testCase = await StoreTestCase.getTestCase(testCaseId)
        setTestCaseInfo(testCase)
        StoreTestCase.setTestCaseId(testCaseId)
        StoreTestCase.setTestCaseInfo(testCase)
        !StoreWorkspace.id && StoreWorkspace.setWorkspaceId(testCase.workspaceId)
    }

    useEffect(() => {
        projectId && StoreTestCase.setProjectId(projectId)
        if (testCaseId) {
            setTestCaseLoading(true)
            getTestCase(testCaseId)
        } else {
            isTestCaseLoading && setTestCaseLoading(false)
        }
    }, [testCaseId, projectId])

    const getBlockInfo = async (testCaseInfo: ITestCase) => {
        const blockInfo = await StoreTestCase.getBlockInfo(testCaseInfo.id)
        if (blockInfo) {
            StoreTestCase.setBlockInfo(blockInfo)
            StoreTestCase.setBlocked(true)
            StoreTestCase.setReadOnly(true)
            if (testCaseInfo) {
                const hasProjectPermission = hasProjectPermissions(
                    [EPermissions.DELETE_PROJECT],
                    testCaseInfo.projectId,
                    testCaseInfo.workspaceId
                )
                StoreTestCase.setIsProjectAdmin(hasProjectPermission)
            }
        } else {
            StoreTestCase.setBlockInfo(null)
            StoreTestCase.setBlocked(false)
        }
    }

    useEffect(() => {
        if (testCaseInfo?.id) {
            StoreTestCase.setReadOnly(!!testCaseInfo.isDeleted)
            getBlockInfo(testCaseInfo)

            StoreTestCase.setTestCaseId(testCaseInfo.id)
            StoreTestCase.setTestCaseName(testCaseInfo.name)
            StoreTestCase.setTestCaseDescription(testCaseInfo.description)
            StoreTestCase.setTestCasePriority(testCaseInfo.priority)
            StoreTestCase.setTestCaseOrderNum(testCaseInfo.orderNum)
            StoreTestCase.setTestCaseSteps(testCaseInfo.steps)
            StoreTestCase.setNormativeTime(testCaseInfo.normativeTime)

            const { hours, minutes } = parseNormativeTime(StoreTestCase.normativeTime)
            StoreTestCase.seInitialNormativeHours(hours)
            StoreTestCase.seInitialNormativeMinutes(minutes)
            StoreTestCase.setNormativeHours(hours)
            StoreTestCase.setNormativeMinutes(minutes)

            StoreTestCase.setTestCaseLinks(testCaseInfo.additionalLinks.additionalLinks || [])
            StoreTestCase.setTestCaseStatus(testCaseInfo.workStatus)
            testCaseInfo.createdBy?.userId && StoreTestCase.setCreatedBy(testCaseInfo.createdBy)

            StoreTestCase.setNavigateToNewTestCase(false)

            const initialColumnsVisibility: IShowColumns = {
                testData:
                    testCaseInfo.steps.some((step) => step.testData) ||
                    StoreTestCase.attachments.some(
                        (attachment) => attachment.attachmentType === EAttachment.STEP_TEST_DATA
                    ),
                comment:
                    testCaseInfo.steps.some((step) => step.comment) ||
                    StoreTestCase.attachments.some(
                        (attachment) => attachment.attachmentType === EAttachment.STEP_COMMENT
                    ),
            }

            StoreTestCase.setShowColumns(initialColumnsVisibility)
            setTestCaseLoading(false)
        }
    }, [testCaseInfo])

    const getTestFolder = async (folderId: string) => {
        const testFolder: ITestFolder = await StoreTestFolder.getTestFolder(folderId)
        StoreTestCase.setTestFolderId(testFolder.id)
        StoreTestCase.setTestFolderName(testFolder.name)
    }

    useEffect(() => {
        if (folderId) {
            getTestFolder(folderId)
        }
    }, [folderId])

    const handleUpdateTestCaseNavBar = async () => {
        await StoreTestCaseList.getAllTestCases(folderId)
    }

    //для обновления значка блокировки при каждом переходе обновлять + при создании нового
    useEffect(() => {
        if (folderId && testCaseInfo?.id) {
            handleUpdateTestCaseNavBar()
        } else {
            if (isNewTestCase) {
                handleUpdateTestCaseNavBar()
            }
        }
    }, [folderId, testCaseInfo?.id, isNewTestCase])

    return (
        <div className={styles.TestCase}>
            <Header />
            {!StoreTestCase.creatingInProgress ? (
                <TestCaseComponent
                    isExecutionTestCase={false}
                    setTestCaseInfo={setTestCaseInfo}
                    isTestCaseLoading={isTestCaseLoading}
                />
            ) : (
                <Spinner show />
            )}
        </div>
    )
})
