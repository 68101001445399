// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ccytsTMplNevzQW5QmSg{display:flex;justify-content:flex-end;gap:16px}", "",{"version":3,"sources":["webpack://./src/pages/CreateTestSet/CreateTestSetButtons/CreateTestSetButtons.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,wBAAA,CACA,QAAA","sourcesContent":[".CreateTestSetButtons {\n    display: flex;\n    justify-content: flex-end;\n    gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateTestSetButtons": "ccytsTMplNevzQW5QmSg"
};
export default ___CSS_LOADER_EXPORT___;
