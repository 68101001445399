export const pageUrls = {
    signIn: '/',
    signUp: '/signup',
    workspaces: '/workspaces',
    projects: '/projects',
    project: '/project',
    workspace: '/workspace',
    testFolder: '/folder',
    testSetFolder: '/testSetFolder',
    testCase: '/testCase',
    testSet: '/testSet',
    testSets: '/testSets',
    createTestSet: '/createTestSet',
    workspaceSettings: '/workspaceSettings',
    projectSettings: '/projectSettings',
    passwordRecovery: '/change-password-page',
    activateEmail: '/activate-email-page',
    user: '/user',
} as const

export enum apiUrls {
    workspace = '/workspace',
    project = '/project',
    testFolder = '/test-folder',
    testSetFolder = '/test-set-folder',
    testCase = '/test-case',
    user = '/user',
    role = '/role',
    permission = '/permission',
    testSet = '/execution-test-set',
    executionCase = '/execution-case',
    executionStep = '/execution-step',
    objectAttachment = '/object-attachment',
    jiraDictionary = '/jira/dictionary',
    jiraSprint = `${jiraDictionary}/sprint`,
    jiraUsers = `${jiraDictionary}/user`,
    jiraEpic = `${jiraDictionary}/epic`,
    jiraBoard = `${jiraDictionary}/board`,
    jiraIntegration = 'jira/integration',
    jiraCustomFields = 'jira/field',
    jiraIssue = 'jira/issue',
}

export const apiLimitIds = 50

export const testadoDocumentationUrl = '/external-files/documents/TestadoDocumentation.docx'
export const logoMaxSizeMb = 0.5
export const attachmentMaxSizeMb = 5
export const attachmentBatchMaxSizeMb = 20

export const documentMaxSizeMb = 20

export const unitsOfInformation = {
    BytesInKb: 1024,
    KbInMb: 1024,
    BytesInMb: 1024 * 1024,
} as const

export const exportFileFormats = {
    word: 'DOCX',
    excel: 'XLSX',
    adobeReader: 'PDF',
} as const

export const logoImageTypes = ['image/jpeg', 'image/png', 'image/gif'] as const

export const dateFormat = 'DD.MM.YYYY'
export const jiraDateFormat = 'DD/MM/YY HH:mm'
export const showAllPages = 999999
export const maxStepLength = 7992 //с запасом для +7 символов параграфа
export const maxNestingLevel = 10
export const userNameSizeSmallRatio = 2.2857
export const userNameSizeBigRatio = 1.7143
export const maxNumberOfWorkspaces = 5
export const maxDescriptionLength = 8000
export const maxTitleLength = 255
export const resetPasswordTimeOut = 60
export const emailConfirmationTimeOut = 60
export const initialAFKTimerSecs = 150
export const initialAFKTimeOutMs = 900000

export const userSearchFields = ['username', 'firstname', 'middlename', 'lastname', 'cn', 'position', 'email'] as const

export const errorsTypes = {
    userIsDisabledError: 'error.userIsDisabled',
    invalidTokenError: 'error.invalidToken',
    unauthorized: 'error.unauthorized',
} as const

export const settingTabs = {
    mainSettings: 'mainSettings',
    documentation: 'documentation',
    team: 'team',
    roleModel: 'roleModel',
    integration: 'integration',
    customFields: 'customFields',
} as const

export const folderTabs = {
    commonSteps: 'commonSteps',
    testCases: 'testCases',
    archive: 'archive',
} as const

export const removableContactsList = ['phone', 'SKYPE', 'TELEGRAM', 'WHATSAPP', 'VK', 'DISCORD']

export const heartBeatInterval = 120000

export const supportMail = 'support@bellintegratorinnovations.ru'
export const baseApiUrl = process.env.NODE_ENV === 'development' ? 'https://dev.testado.site' : ''

export const themeUrls = {
    light: '/images/theme/Light/',
    dark: '/images/theme/Dark/',
} as const

export const defaultTestCaseColumnOrder = [
    'description',
    'actions',
    'expectedResult',
    'testData',
    'comment',
    'actualResult',
    'stepMenu',
]
