// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Wtzxn9wF9qqcB6_E0MO1{padding:12px;padding-top:0}.FWy5crsVGYiWrxw2brZ7 .ant-upload-list .ant-upload-list-item-container{padding:2px 0}.REsi_oxRXuAvT_dGE0xg,.REsi_oxRXuAvT_dGE0xg:hover{background-color:rgba(255,255,255,.5) !important}.HlCVc_z69B3f3CuW_kZS .ant-upload-drag{display:none}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/UploadTestCaseAttachments/UploadTestCaseAttachments.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,aAAA,CAMQ,uEACI,aAAA,CAMhB,kDAEI,gDAAA,CAKI,uCACI,YAAA","sourcesContent":[".AttachmentsList {\n    padding: 12px;\n    padding-top: 0;\n}\n\n.Dragger {\n    :global {\n        .ant-upload-list {\n            .ant-upload-list-item-container {\n                padding: 2px 0;\n            }\n        }\n    }\n}\n\n.UploadButton,\n.UploadButton:hover {\n    background-color: rgba(255, 255, 255, 0.5) !important;\n}\n\n.HiddenDragger {\n    :global {\n        .ant-upload-drag {\n            display: none;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AttachmentsList": "Wtzxn9wF9qqcB6_E0MO1",
	"Dragger": "FWy5crsVGYiWrxw2brZ7",
	"UploadButton": "REsi_oxRXuAvT_dGE0xg",
	"HiddenDragger": "HlCVc_z69B3f3CuW_kZS"
};
export default ___CSS_LOADER_EXPORT___;
