import { Key } from 'react'

import { makeAutoObservable, runInAction } from 'mobx'

import { MsAttachmentApi, MsAuthApi, MsProjectApi } from '@/api'

import { showMessage } from '@/utils/notification'

import { PreferencesStore, SpinnerStore } from '@/store'
import { apiUrls } from '@/consts'

import { IAttachment, IObjectAttachment, IProject, IWorkspace } from '@/types'
import { EAttachment, EOrder } from '@/enums'

export const StoreWorkspace = makeAutoObservable({
    id: '',
    workspaceName: '',
    logos: [] as string[],
    workspaceInfo: {} as IWorkspace,
    projectList: [] as IProject[],

    async createWorkspace(workspace: IWorkspace) {
        SpinnerStore.setShow(true)
        const id = await MsProjectApi.saveItem(workspace, apiUrls.workspace)

        PreferencesStore.t('notification:success.workspaceCreated', { name: workspace.workspaceName }).then(
            (message) => {
                showMessage('success', message)
            }
        )

        await MsAuthApi.updateToken(true)

        SpinnerStore.setShow(false)
        return id
    },

    setProjectList(value: IProject[]) {
        runInAction(async () => {
            this.projectList = value
        })
    },

    setWorkspaceId(value: string) {
        runInAction(async () => {
            this.id = value
        })
    },

    setLogos(value: string[]) {
        this.logos = value
    },

    setWorkspaceInfo(value: IWorkspace) {
        this.workspaceInfo = value
    },

    setWorkspaceName(value: string) {
        this.workspaceName = value
    },

    async getWorkspace(id: string) {
        SpinnerStore.setShow(true)
        const workspaceInfo: IWorkspace = await MsProjectApi.getItem(id, apiUrls.workspace)
        const workspaceWithLogo = (await this.getLogos([workspaceInfo], [workspaceInfo.id]))[0]

        this.id = workspaceInfo.id || ''
        this.workspaceName = workspaceInfo.workspaceName
        this.workspaceInfo = workspaceWithLogo

        SpinnerStore.setShow(false)
        return workspaceWithLogo
    },

    async getAttachments(existingObjectId: string[], attachmentType?: EAttachment[]) {
        SpinnerStore.setShow(true)

        const props = {
            filter: {
                existingObjectId: existingObjectId,
                objectObjectAttachmentTypeEnums: attachmentType ? attachmentType : [],
                isDeleted: false,
            },
        }

        const attachments: IObjectAttachment[] = await MsProjectApi.getAttachments({ ...props })
        SpinnerStore.setShow(false)
        return attachments
    },

    async getAttachmentsWithData(id: string[], attachmentType?: EAttachment[]) {
        SpinnerStore.setShow(true)
        const attachments = await this.getAttachments(id, attachmentType)

        const attachmentsWithData = await Promise.all(
            attachments.map(async (attachment) => {
                const attachmentData: IAttachment = await MsAttachmentApi.getAttachmentData(
                    attachment.existingAttachmentId
                )

                return { ...attachment, ...attachmentData, bindId: attachment.id }
            })
        )
        SpinnerStore.setShow(false)

        return attachmentsWithData
    },

    async updateWorkspace(workspace: Partial<IWorkspace>) {
        SpinnerStore.setShow(true)

        await MsProjectApi.updateItem(workspace, apiUrls.workspace)

        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.workspaceUpdated', { name: workspace.workspaceName }).then(
            (message) => {
                showMessage('success', message)
            }
        )
    },

    async deleteWorkspace(workspaceId: string, workspaceName: string) {
        SpinnerStore.setShow(true)
        await MsProjectApi.deleteItem(workspaceId, apiUrls.workspace)
        SpinnerStore.setShow(false)
        PreferencesStore.t('notification:success.workspaceDeleted', { name: workspaceName }).then((message) => {
            showMessage('success', message)
        })
    },

    async deleteWorkspaces(workspaces: Key[]) {
        SpinnerStore.setShow(true)
        Promise.all(
            workspaces.map(async (workspaceId) => {
                await MsProjectApi.deleteItem(workspaceId, apiUrls.workspace)
            })
        )
        SpinnerStore.setShow(false)

        PreferencesStore.t('notification:success.allWorkspacesDeleted', {}).then((message) => {
            showMessage('success', message)
        })
    },

    async getLogos(workspaces: IWorkspace[], workspacesId: string[] = []) {
        const workspacesWithLogo = await Promise.all(
            workspaces.map(async (workspace) => {
                if (workspace.brandbookId) {
                    const logo = await MsAttachmentApi.getAttachmentData(workspace.brandbookId)
                    logo.link = URL.createObjectURL(await MsAttachmentApi.getBlobAttachment(workspace.brandbookId))
                    return { ...workspace, logo }
                } else {
                    return workspace
                }
            })
        )
        return workspacesWithLogo
    },

    async getWorkspaces(props?: {
        page?: number
        filter?: Record<string, string[]>
        sort?: { field: string; direction: EOrder }[]
        pageSize?: number
    }) {
        SpinnerStore.setShow(true)
        const workspaces = await MsProjectApi.filter({
            ...props,
            specificApiUrl: apiUrls.workspace,
        })

        const workspacesWithLogo = this.getLogos(workspaces.result)
        SpinnerStore.setShow(false)
        return workspacesWithLogo
    },
})
