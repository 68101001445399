import React, { useEffect, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Layout, Typography } from 'antd'
import { Header } from '@/components/Header/Header'
import { Breadcrumbs } from '@/pages/TestFolders/components/Breadcrumbs'
import { CreateNewFolderModal } from '@/pages/TestFolders/components/CreateNewFolderModal'
import { ResizableFoldersTree } from '@/pages/TestFolders/components/FoldersTree/ResizableFoldersTree'
import { RenameFolderModal } from '@/pages/TestFolders/components/Modals/RenameFolderModal'
import { SelectAndCreateModal } from '@/pages/TestFolders/components/Modals/SelectAndCreateModal'
import { SelectFolderModal } from '@/pages/TestFolders/components/Modals/SelectFolderModal/SelectFolderModal'
import { TestFolderHeader } from '@/pages/TestFolders/components/TestFolderHeader/TestFolderHeader'
import { TestSetsMain } from './TestSetsMain/TestSetsMain'

import { PlusOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreCreateTestSet, StoreProject, StoreTestFolder, StoreTestSet, StoreWorkspace } from '@/store'
import { pageUrls } from '@/consts'

import { IProject, ITestFolder } from '@/types'
import { EModalTypeFolder } from '@/enums'

import styles from '@/pages/TestFolders/TestFolders.module.scss'

const { Content } = Layout
const { Text } = Typography

type Props = {
    showInModal?: boolean
}

export const TestSets = observer((props: Props) => {
    const { showInModal } = props
    const { t } = useTranslation(['page--testFolders'])
    const { projectId } = useParams()
    const navigate = useNavigate()

    const [projectInfo, setProjectInfo] = useState<IProject>()
    const [testSetFolders, setTestSetFolders] = useState<ITestFolder[]>([])
    const [selectedFolderId, setSelectedFolderId] = useState('')
    const [selectedForActionFolder, setSelectedForActionFolder] = useState<ITestFolder>()
    const [isModalCreateFolderOpen, setIsModalCreateFolderOpen] = useState(false)
    const [isFoldersLoading, setFoldersLoading] = useState(false)
    const [isModalRenameOpen, setIsModalRenameOpen] = useState(false)
    const [isSelectAndCreateModalOpen, setIsSelectAndCreateModalOpen] = useState(false)
    const [isModalSelectOpen, setIsModalSelectOpen] = useState(false)
    const [modalTypeFolder, setModalTypeFolder] = useState<EModalTypeFolder | undefined>()
    const [selectedInModalTestFolder, setSelectedInModalTestFolder] = useState('')
    const [isTestSetsLoading, setTestSetsLoading] = useState(true)

    const getProjectInfo = async (projectId: string) => {
        const project = await StoreProject.getProject(projectId)
        setProjectInfo(project)
        StoreProject.setProjectId(projectId)
        !StoreWorkspace.id && StoreWorkspace.setWorkspaceId(project.workspaceId)
        getAllProjects(project.workspaceId)
    }

    useEffect(() => {
        if (projectId) {
            getProjectInfo(projectId)
        }
    }, [projectId, StoreTestFolder.toggleUpdateFoldersTree])

    const getTestSetFolders = async (projectId: string) => {
        setFoldersLoading(true)
        const folders = await StoreTestSet.getTestSetFolders(projectId)
        setTestSetFolders(folders)
        StoreTestFolder.setSelectedFolderName('')
        setFoldersLoading(false)
    }

    useEffect(() => {
        if (projectId) {
            getTestSetFolders(projectId)
        }
    }, [projectId, StoreTestFolder.toggleUpdateFoldersTree])

    useEffect(() => {
        StoreTestFolder.setCurrentFolderTab('testCases')
    }, [])

    const getAllProjects = async (workspaceId: string) => {
        if (!StoreWorkspace.projectList.length) {
            const props = {
                filter: { workspaceId: workspaceId, projectName: [], projectDescription: [], isDeleted: false },
            }
            StoreWorkspace.setProjectList((await StoreProject.getProjects(props)).result)
        }
    }

    const onCreateFolder = () => {
        StoreTestFolder.setCreatingNewFolder(true)
        setIsModalCreateFolderOpen(true)
    }

    const createNewTestSet = () => {
        StoreCreateTestSet.resetTestSet()
        setIsSelectAndCreateModalOpen(false)
        navigate(`${pageUrls.project}/${StoreProject.id}${pageUrls.createTestSet}?folderId=${selectedFolderId}`)
    }

    const onCreateNewTestSet = () => {
        if (selectedFolderId) {
            createNewTestSet()
        } else {
            setIsSelectAndCreateModalOpen && setIsSelectAndCreateModalOpen(true)
        }
    }

    return (
        <div className={styles.TestFolderList}>
            {!showInModal && <Header />}
            {projectInfo && !!StoreWorkspace.projectList.length && (
                <Content className={cn('content', styles.ContentWrapper)}>
                    {!showInModal && <Breadcrumbs projectInfo={projectInfo} />}
                    <TestFolderHeader projectInfo={projectInfo} selectFolderOnly={showInModal} />
                    <div className={styles.Main}>
                        {projectId && !isTestSetsLoading && (
                            <ResizableFoldersTree
                                testFolders={testSetFolders}
                                setTestFolders={setTestSetFolders}
                                selectedFolderId={selectedFolderId}
                                setSelectedFolderId={setSelectedFolderId}
                                setIsModalCreateFolderOpen={setIsModalCreateFolderOpen}
                                onCreateFolder={onCreateFolder}
                                setSelectedForActionFolder={setSelectedForActionFolder}
                                setIsModalRenameOpen={setIsModalRenameOpen}
                                setIsModalSelectOpen={setIsModalSelectOpen}
                                setModalTypeFolder={setModalTypeFolder}
                                pageType={pageUrls.testSets}
                                selectFolderOnly={showInModal}
                                withoutSearchParams={showInModal}
                            />
                        )}

                        <div className={styles.RightSide} style={{ overflow: showInModal ? 'hidden' : 'auto' }}>
                            {!testSetFolders.length && !isFoldersLoading ? (
                                <div className={styles.NoFolders}>
                                    <Text type="secondary">{t('page--testFolders:noTestSetsFolders')}</Text>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={onCreateFolder}
                                        disabled={SpinnerStore.show}
                                        className={styles.CreateFolder}
                                    >
                                        {t('page--testFolders:createFolder')}
                                    </Button>
                                </div>
                            ) : (
                                <TestSetsMain
                                    testFolderId={selectedFolderId}
                                    onCreateNewTestSet={onCreateNewTestSet}
                                    showInModal={showInModal}
                                    isTestSetsLoading={isTestSetsLoading}
                                    setTestSetsLoading={setTestSetsLoading}
                                />
                            )}
                        </div>
                    </div>

                    {projectId && (
                        <CreateNewFolderModal
                            projectId={projectId}
                            isModalCreateFolderOpen={isModalCreateFolderOpen}
                            setIsModalCreateFolderOpen={setIsModalCreateFolderOpen}
                            testFolders={testSetFolders}
                            setTestFolders={setTestSetFolders}
                            selectedFolderId={selectedFolderId}
                            setSelectedFolderId={setSelectedFolderId}
                            pageType={pageUrls.testSets}
                        />
                    )}
                    {projectId && isSelectAndCreateModalOpen && (
                        <SelectAndCreateModal
                            testFolders={testSetFolders}
                            setTestFolders={setTestSetFolders}
                            selectedFolderId={selectedFolderId}
                            isSelectAndCreateModalOpen={isSelectAndCreateModalOpen}
                            setIsSelectAndCreateModalOpen={setIsSelectAndCreateModalOpen}
                            onCreateFolder={onCreateFolder}
                            onCreateItem={createNewTestSet}
                            setSelectedFolderId={setSelectedFolderId}
                            pageType={pageUrls.testSets}
                        />
                    )}
                    {selectedForActionFolder && isModalRenameOpen && projectId && !showInModal && (
                        <RenameFolderModal
                            projectId={projectId}
                            isModalRenameOpen={isModalRenameOpen}
                            setIsModalRenameOpen={setIsModalRenameOpen}
                            testFolders={testSetFolders}
                            setTestFolders={setTestSetFolders}
                            selectedFolderId={selectedFolderId}
                            setSelectedFolderId={setSelectedFolderId}
                            selectedForActionFolder={selectedForActionFolder}
                            setSelectedForActionFolder={setSelectedForActionFolder}
                            pageType={pageUrls.testSets}
                        />
                    )}
                    {projectId && selectedForActionFolder && !showInModal && (
                        <SelectFolderModal
                            projectId={projectId}
                            testFolders={testSetFolders}
                            setTestFolders={setTestSetFolders}
                            selectedFolderId={selectedFolderId}
                            isModalSelectOpen={isModalSelectOpen}
                            setIsModalSelectOpen={setIsModalSelectOpen}
                            setSelectedFolderId={setSelectedFolderId}
                            selectedForActionFolder={selectedForActionFolder}
                            modalTypeFolder={modalTypeFolder}
                            setModalTypeFolder={setModalTypeFolder}
                            setSelectedInModalTestFolder={setSelectedInModalTestFolder}
                            selectedInModalTestFolder={selectedInModalTestFolder}
                            pageType={pageUrls.testSets}
                            isTestSetFolder={true}
                        />
                    )}
                </Content>
            )}
        </div>
    )
})
