import React from 'react'

import { useTranslation } from 'react-i18next'

import { Divider, Input } from 'antd'
import { UserMessage } from '@/components/UserMessage/UserMessage'

import { SendOutlined } from '@ant-design/icons'

import styles from './TestCaseComments.module.scss'

type Props = {
    id: string
    author: string
    message: string
    avatar: any
    ownMessage: boolean
}

export const TestCaseComments = (props: Props[]) => {
    const { messages } = props

    const { t } = useTranslation(['testCase'])
    return (
        <div className={styles.TestCaseComments}>
            <div className={styles.Messages}>
                {messages?.map((message) => (
                    <UserMessage
                        key={message.id}
                        userName={message.author}
                        avatar={message.avatar}
                        message={message.message}
                        size={24}
                        ownMessage={message.ownMessage}
                    />
                ))}
            </div>
            <Divider />
            <div className={styles.SendMessage}>
                <Input
                    className="underlinedInput"
                    bordered={false}
                    placeholder={t('testCase:inputMessage')}
                    addonAfter={<SendOutlined />}
                />
            </div>
        </div>
    )
}
