import React, { FC, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { MsAuthApi } from '@/api'

import { showMessage } from '@/utils'

import { pageUrls } from '@/consts'

import styles from '@/pages/AuthPage/AuthPage.module.scss'

export const ActivateEmailPage: FC = () => {
    const { t } = useTranslation(['page--auth', 'notification'])

    const { userId, requestId } = useParams()

    const navigate = useNavigate()

    useEffect(() => {
        if (userId && requestId) {
            MsAuthApi.userActivate({ userId, requestId }).then(() => {
                navigate(pageUrls.signIn)
                showMessage('success', t('notification:success.emailActivate'))
            })
        }
    }, [navigate, requestId, t, userId])

    return <div className={styles.ActivateEmailPage}>{t('page--auth:')}</div>
}
