// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".A3tLgljpMyUummabmI_x tr:nth-child(2n){background:var(--color-neutral2)}", "",{"version":3,"sources":["webpack://./src/components/SettingPageTabs/RoleModel/RolePermissions.module.scss"],"names":[],"mappings":"AACI,uCACI,gCAAA","sourcesContent":[".RolePermissions {\n    tr:nth-child(2n) {\n        background: var(--color-neutral2);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RolePermissions": "A3tLgljpMyUummabmI_x"
};
export default ___CSS_LOADER_EXPORT___;
