export enum EAttachment {
    WORKSPACE_BRANDBOOK = 'WORKSPACE_BRANDBOOK',
    PROJECT_BRANDBOOK = 'PROJECT_BRANDBOOK',
    WORKSPACE_DOCUMENT = 'WORKSPACE_DOCUMENT',
    PROJECT_DOCUMENT = 'PROJECT_DOCUMENT',
    STEP_ACTION = 'STEP_ACTION',
    STEP_EXPECTED_RESULT = 'STEP_EXPECTED_RESULT',
    STEP_ACTUAL_RESULT = 'STEP_ACTUAL_RESULT',
    STEP_TEST_DATA = 'STEP_TEST_DATA',
    STEP_COMMENT = 'STEP_COMMENT',
    TEST_CASE = 'TEST_CASE',
    EXECUTION_CASE = 'EXECUTION_CASE',
}

export enum EEntityType {
    WORKSPACE = 'WORKSPACE',
    PROJECT = 'PROJECT',
    STEP = 'STEP',
    TEST_CASE = 'TEST_CASE',
    EXECUTION_CASE = 'EXECUTION_CASE',
    EXECUTION_STEP = 'EXECUTION_STEP',
}
