import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { calcPercent } from '@/utils'

import { Badge, Typography } from 'antd'

import { EExecutionCaseStatus } from '@/enums'

import styles from './TestSetProgressBar.module.scss'

const { Text } = Typography

type Props = {
    progress: Record<EExecutionCaseStatus, number>
}

export const TestSetProgressBar = (props: Props) => {
    const { progress } = props
    const { t } = useTranslation(['page--testSets', 'statuses'])

    const total = useMemo(() => Object.values(progress).reduce((sum, current) => sum + current, 0), [progress])

    const passedShare = useMemo(() => calcPercent(progress.PASSED, total), [progress])
    const skippedShare = useMemo(() => calcPercent(progress.SKIPPED, total), [progress])
    const failedShare = useMemo(() => calcPercent(progress.FAILED, total), [progress])
    const blockedShare = useMemo(() => calcPercent(progress.BLOCKED, total), [progress])
    const inProgressShare = useMemo(() => calcPercent(progress.IN_PROCESS, total), [progress])
    const notPassedShare = useMemo(() => calcPercent(progress.NOT_PASSED, total), [progress])

    return (
        <div className={styles.Progress}>
            <Text className={styles.Label} type="secondary">
                {t('page--testSets:progressTitle')}
            </Text>
            <div className={styles.ProgressBar}>
                <div className={styles.Passed} style={{ width: passedShare }}></div>
                <div className={styles.Failed} style={{ width: failedShare }}></div>
                <div className={styles.Blocked} style={{ width: blockedShare }}></div>
                <div className={styles.InProgress} style={{ width: inProgressShare }}></div>
                <div className={styles.NotPassed} style={{ width: notPassedShare }}></div>
            </div>

            <div className={styles.ProgressInfo}>
                <Badge
                    className={styles.Badge}
                    color="var(--color-passed)"
                    text={
                        <span>
                            <Text>{t(`statuses:${EExecutionCaseStatus.PASSED}`)}</Text>
                            <Text type="secondary">
                                {progress.PASSED} ({passedShare})
                            </Text>
                        </span>
                    }
                />
                <Badge
                    color="var(--color-failed)"
                    className={styles.Badge}
                    text={
                        <span>
                            <Text>{t(`statuses:${EExecutionCaseStatus.FAILED}`)}</Text>
                            <Text type="secondary">
                                {progress.FAILED} ({failedShare})
                            </Text>
                        </span>
                    }
                />
                <Badge
                    color="var(--color-blocked)"
                    className={styles.Badge}
                    text={
                        <span>
                            <Text>{t(`statuses:${EExecutionCaseStatus.BLOCKED}`)}</Text>
                            <Text type="secondary">
                                {progress.BLOCKED} ({blockedShare})
                            </Text>
                        </span>
                    }
                />
                <Badge
                    color="var(--color-inProgress)"
                    className={styles.Badge}
                    text={
                        <span>
                            <Text>{t(`statuses:${EExecutionCaseStatus.IN_PROCESS}`)}</Text>
                            <Text type="secondary">
                                {progress.IN_PROCESS} ({inProgressShare})
                            </Text>
                        </span>
                    }
                />
                <Badge
                    color="var(--color-notPassed)"
                    className={styles.Badge}
                    text={
                        <span>
                            <Text>{t(`statuses:${EExecutionCaseStatus.NOT_PASSED}`)}</Text>
                            <Text type="secondary">
                                {progress.NOT_PASSED} ({notPassedShare})
                            </Text>
                        </span>
                    }
                />
            </div>
        </div>
    )
}
