import React, { Dispatch, Key, ReactElement, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { copyLink, useOnOutsideClick } from '@/utils'

import { Dropdown, Tooltip } from 'antd'
import type { MenuProps } from 'antd'
import { ModalOnDeleteRow } from '@/components/ModalOnDeleteRow/ModalOnDeleteRow'

import {
    AppstoreAddOutlined,
    CopyOutlined,
    DeleteOutlined,
    ExportOutlined,
    FileAddOutlined,
    FileExcelOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    FolderOpenOutlined,
    FolderViewOutlined,
    LinkOutlined,
    PlusOutlined,
    RetweetOutlined,
    SendOutlined,
} from '@ant-design/icons'

import {
    SpinnerStore,
    StoreCreateTestSet,
    StoreProject,
    StoreTestCase,
    StoreTestCaseList,
    StoreTestFolder,
} from '@/store'
import { apiUrls, exportFileFormats, folderTabs, pageUrls } from '@/consts'

import { dropdownPositionType, IExportItems, ITestCase } from '@/types'
import { EWorkStatus } from '@/enums'

interface Props {
    testCase: ITestCase
    testCases: ITestCase[]
    setTestCases: Dispatch<SetStateAction<ITestCase[]>>
    setSelectedTestCaseIds?: Dispatch<SetStateAction<string[]>>
    setIsMoveTestCaseToFolderModalOpen?: Dispatch<SetStateAction<boolean>>
    loadData: () => void
    pageNumber: number
    elementsCount: number
    pageSize: number
    setSelectedRecord?: Dispatch<SetStateAction<ITestCase | undefined>>
    dropdownPosition?: dropdownPositionType
    button?: ReactElement
    setShowTestSetsModal: Dispatch<SetStateAction<boolean>>
    onSelectAndCreateModalOpen?: () => void
    setShowTableMenu: Dispatch<SetStateAction<boolean>>
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    selectedRowKeys: Key[]
}

export const TestCaseOptions = observer((props: Props) => {
    const {
        testCase,
        button,
        testCases,
        setTestCases,
        setSelectedTestCaseIds,
        loadData,
        elementsCount,
        pageSize,
        setIsMoveTestCaseToFolderModalOpen,
        setSelectedRecord,
        dropdownPosition,
        setShowTestSetsModal,
        onSelectAndCreateModalOpen,
        setShowTableMenu,
        setSelectedRowKeys,
        selectedRowKeys,
    } = props
    const { t } = useTranslation(['page--testCases', 'common', 'notification'])
    const [searchParams, setSearchParams] = useSearchParams()

    const closeContextDropdown = () => {
        setSelectedRecord && setSelectedRecord(undefined)
    }

    const onOpenChange = () => {
        button && closeContextDropdown()
    }

    const isContextMenu = testCase && dropdownPosition
    isContextMenu && useOnOutsideClick(closeContextDropdown)

    const openFolder = (testCase: ITestCase) => {
        setSearchParams(`?testFolder=${testCase.testFolderId}`)
        StoreTestFolder.setShowSelectedFolderId('')
        StoreTestFolder.setShowSelectedTestCaseId(testCase.id)
    }

    const showFolder = (folderId: string) => {
        StoreTestFolder.setShowSelectedFolderId(folderId)
    }

    const onCopyLink = () => {
        const href = `${window.location.origin}${pageUrls.project}/${StoreProject.id}${pageUrls.testFolder}/${testCase.testFolderId}${pageUrls.testCase}/${testCase.id}`
        copyLink(href)
    }

    const onRestore = () => {
        setSelectedTestCaseIds && setSelectedTestCaseIds([testCase.id])
        setIsMoveTestCaseToFolderModalOpen && setIsMoveTestCaseToFolderModalOpen(true)
    }

    const onExport: MenuProps['onClick'] = async (e) => {
        const exportTestCases = {
            ids: [testCase.id],
            exportType: e.key,
        } as IExportItems

        await StoreTestCase.exportTestCases(exportTestCases)
    }

    const handleChangeSelectedRowKeys = (testCase: ITestCase) => {
        const updatedRowKeys = selectedRowKeys.filter((rowKey) => rowKey !== testCase.id)
        setSelectedRowKeys(updatedRowKeys)
        setShowTableMenu(!!updatedRowKeys.length)

        StoreCreateTestSet.setSelectedRowKeys(updatedRowKeys)
        StoreCreateTestSet.setSelectedTestCases(StoreCreateTestSet.selectedTestCases, [testCase.id])
    }

    const onDelete = (testCase: ITestCase) => {
        if (testCase.id) {
            ModalOnDeleteRow({
                id: testCase.id,
                name: testCase.name,
                items: testCases,
                setItems: setTestCases as any,
                elementsCount: elementsCount,
                pageSize: pageSize,
                loadData: loadData,
                specificApiUrl: apiUrls.testCase,
                title: t('page--testCases:deleteGuard.title', {
                    name: testCase.name,
                }),
                content: t('page--testCases:deleteGuard.content'),
                okText: t('common:buttons.ok'),
                cancelText: t('common:buttons.cancel'),
            })
            handleChangeSelectedRowKeys(testCase)
        }
    }

    const onCopy = async () => {
        const testCasesCopy = await StoreTestCase.copyTestCase(testCase)
        const updateTestCases = [...testCases, testCasesCopy[0]]
        setTestCases(updateTestCases)
        StoreTestFolder.updateFoldersTree()
        StoreTestCaseList.updateTestCases()
    }

    const onMove = async () => {
        setSelectedTestCaseIds && setSelectedTestCaseIds([testCase.id])
        setIsMoveTestCaseToFolderModalOpen && setIsMoveTestCaseToFolderModalOpen(true)
    }

    const onAddToNewTestSet = () => {
        StoreCreateTestSet.resetSelectedTestCases()
        StoreCreateTestSet.setSelectedRowKeys([testCase.id])
        StoreCreateTestSet.setSelectedTestCases([testCase], [])
        onSelectAndCreateModalOpen && onSelectAndCreateModalOpen()
    }

    const onAddToTestSet = () => {
        StoreCreateTestSet.resetSelectedTestCases()
        StoreCreateTestSet.setSelectedRowKeys([testCase.id])
        setShowTestSetsModal(true)
        setShowTableMenu(false)
    }

    const isLocked = !!testCase.testCaseBlockInfo

    const addToTitle =
        testCase.workStatus === EWorkStatus.DRAFT
            ? t('page--testCases:disabledAddToNewTestSet')
            : isLocked && t('notification:error.testCaseBlocked')

    const items: MenuProps['items'] =
        StoreTestFolder.currentFolderTab !== folderTabs.archive
            ? [
                  {
                      key: 'openFolder',
                      label: t('page--testCases:openFolder'),
                      icon: <FolderOpenOutlined />,
                      onClick: () => openFolder(testCase),
                  },

                  {
                      key: 'showFolder',
                      label: t('page--testCases:showFolder'),
                      icon: <FolderViewOutlined />,
                      onClick: () => showFolder(testCase.testFolderId),
                  },
                  {
                      key: 'deleteItem',
                      label: (
                          <Tooltip
                              title={testCase.testCaseBlockInfo && t('notification:error.testCaseBlocked')}
                              className="ant-dropdown-menu-title-content"
                          >
                              {t('common:buttons.delete')}
                          </Tooltip>
                      ),
                      icon: <DeleteOutlined />,
                      onClick: () => onDelete(testCase),
                      disabled: isLocked,
                  },
                  {
                      key: 'duplicate',
                      label: (
                          <Tooltip
                              title={testCase.testCaseBlockInfo && t('notification:error.testCaseBlocked')}
                              className="ant-dropdown-menu-title-content"
                          >
                              {t('common:buttons.duplicate')}
                          </Tooltip>
                      ),
                      icon: <CopyOutlined />,
                      onClick: onCopy,
                      disabled: isLocked,
                  },

                  {
                      key: 'move',
                      label: (
                          <Tooltip
                              title={testCase.testCaseBlockInfo && t('notification:error.testCaseBlocked')}
                              className="ant-dropdown-menu-title-content"
                          >
                              {t('page--testCases:move')}
                          </Tooltip>
                      ),
                      icon: <SendOutlined />,
                      onClick: onMove,
                      disabled: isLocked,
                  },

                  {
                      key: 'copyLink',
                      label: t('page--testCases:copyLink'),
                      icon: <LinkOutlined />,
                      onClick: onCopyLink,
                  },

                  {
                      key: 'addTo',
                      label: (
                          <Tooltip title={addToTitle} className="ant-dropdown-menu-title-content">
                              {t('common:buttons.addTo')}
                          </Tooltip>
                      ),
                      icon: (
                          <Tooltip
                              title={
                                  testCase.workStatus === EWorkStatus.DRAFT &&
                                  t('page--testCases:disabledAddToNewTestSet')
                              }
                          >
                              <PlusOutlined />
                          </Tooltip>
                      ),
                      disabled: testCase.workStatus === EWorkStatus.DRAFT || isLocked,
                      children: [
                          {
                              label: t('page--testCases:addToTestSet'),
                              key: 'addToTestSet',
                              icon: <AppstoreAddOutlined />,
                              onClick: onAddToTestSet,
                          },
                          {
                              label: t('page--testCases:addToNewTestSet'),
                              key: 'addToNewTestSet',
                              icon: <FileAddOutlined />,
                              onClick: onAddToNewTestSet,
                          },
                      ],
                  },
                  {
                      key: 'export',
                      label: t('common:buttons.export'),
                      icon: <ExportOutlined />,
                      children: [
                          {
                              label: exportFileFormats.word,
                              key: exportFileFormats.word,
                              icon: <FileWordOutlined />,
                              onClick: onExport,
                          },

                          {
                              label: exportFileFormats.excel,
                              key: exportFileFormats.excel,
                              icon: <FileExcelOutlined />,
                              onClick: onExport,
                          },
                          {
                              label: exportFileFormats.adobeReader,
                              key: exportFileFormats.adobeReader,
                              icon: <FilePdfOutlined />,
                              onClick: onExport,
                          },
                      ],
                  },
              ]
            : [
                  {
                      key: 'Restore',
                      label: t('page--testCases:restore'),
                      icon: <RetweetOutlined />,
                      onClick: onRestore,
                  },
              ]

    return (
        <Dropdown
            onOpenChange={onOpenChange}
            menu={{ items }}
            open={!!button ? undefined : !!testCase}
            overlayStyle={isContextMenu ? { top: dropdownPosition?.y, left: dropdownPosition?.x } : undefined}
            placement="bottomLeft"
            disabled={SpinnerStore.show}
        >
            {button ? button : <></>}
        </Dropdown>
    )
})
