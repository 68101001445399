import React, { FC } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Card } from 'antd'

import { ArrowUpOutlined, FlagFilled, FormOutlined, UserOutlined } from '@ant-design/icons'

import { EPriority, EWorkStatus } from '@/enums'

import styles from './GeneralStepCard.module.scss'

export const GeneralStepCard: FC = observer(() => {
    const { t } = useTranslation(['testCase'])

    const generalStep = {
        priority: EPriority.MEDIUM,
        status: EWorkStatus.DRAFT,
        user: 'Dmitriy Gurkin',
    }

    return (
        <Card
            className={styles.GeneralStepCard}
            title="Какой-то общий шаг"
            size="small"
            bordered={false}
            extra={
                <Link to="#">
                    <FormOutlined />
                </Link>
            }
        >
            <div className={styles.CardContent}>
                <div className={styles.CardContentItem}>
                    <ArrowUpOutlined className={styles.CardContentIcon} />
                    <div className={styles.CardContentLabel}>{t(`testCase:priorities.${generalStep.priority}`)}</div>
                </div>
                <div className={styles.CardContentItem}>
                    <FlagFilled className={styles.CardContentIcon} />
                    <div className={styles.CardContentLabel}>{t(`testCase:statuses.${generalStep.status}`)}</div>
                </div>
                <div className={styles.CardContentItem}>
                    <UserOutlined className={styles.CardContentIcon} />
                    <div className={styles.CardContentLabel}>{generalStep.user}</div>
                </div>
            </div>
        </Card>
    )
})
