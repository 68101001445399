import React from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, Popconfirm, Tooltip } from 'antd'

import { UsergroupAddOutlined, UsergroupDeleteOutlined } from '@ant-design/icons'

import { SpinnerStore } from '@/store'
import { pageUrls } from '@/consts'

import { IGetUserIn, IUserWidthFilter, searchAreaTypes, settingsPageType } from '@/types'

import styles from './ActionButton.module.scss'

type Props = {
    user: IUserWidthFilter | IGetUserIn
    excludeUser: (user: IUserWidthFilter | IGetUserIn) => void
    addUser: (user: IUserWidthFilter | IGetUserIn) => void
    checkIfUserLastAdmin: (user: IUserWidthFilter | IGetUserIn) => boolean
    pageType: settingsPageType
    searchArea: searchAreaTypes
}

export const ActionButton = observer((props: Props) => {
    const { user, excludeUser, addUser, checkIfUserLastAdmin, pageType, searchArea } = props
    const { t } = useTranslation(['page--workspaceSettings', 'page--projectSettings'])

    return (
        <>
            {user.isInCurrentTeam || searchArea === 'local' ? (
                ((pageType === pageUrls.workspaceSettings && !checkIfUserLastAdmin(user)) ||
                    pageType === pageUrls.projectSettings) && (
                    <Popconfirm
                        title={t('common:buttons.areYouSure')}
                        onConfirm={() => excludeUser(user)}
                        className={styles.ExcludeUser}
                        disabled
                    >
                        <Tooltip
                            //TODO
                            // title={
                            //     pageType === pageUrls.workspaceSettings
                            //         ? t('page--workspaceSettings:team.excludeUser')
                            //         : t('page--projectSettings:team.excludeUser')
                            // }
                            title={t('common:inDevelopment')}
                            placement="right"
                        >
                            <Button
                                icon={<UsergroupDeleteOutlined />}
                                danger
                                ghost
                                disabled={true || SpinnerStore.show}
                                className={cn(styles.ActionButton, styles.ExcludeUser, 'borderless', {
                                    [styles.TopRightPosition]: !(
                                        pageType === pageUrls.workspaceSettings && searchArea === 'global'
                                    ),
                                })}
                            />
                        </Tooltip>
                    </Popconfirm>
                )
            ) : (
                <Tooltip
                    title={
                        pageType === pageUrls.workspaceSettings
                            ? t('page--workspaceSettings:team.addUser')
                            : t('page--projectSettings:team.addUser')
                    }
                    placement="right"
                >
                    <Button
                        ghost
                        icon={<UsergroupAddOutlined />}
                        onClick={() => addUser(user)}
                        disabled={SpinnerStore.show}
                        className={cn(styles.ActionButton, styles.AddUser, 'borderless', {
                            [styles.TopRightPosition]: !(
                                pageType === pageUrls.workspaceSettings && searchArea === 'global'
                            ),
                        })}
                    />
                </Tooltip>
            )}
        </>
    )
})
