import React, { Key } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, Dropdown } from 'antd'
import type { MenuProps } from 'antd'

import { DownOutlined, ExportOutlined, FileExcelOutlined, FilePdfOutlined, FileWordOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreTestCase } from '@/store'
import { exportFileFormats } from '@/consts'

import { IExportItems } from '@/types'

import styles from './ExportButton.module.scss'

type Props = {
    selectedRowKeys: Key[]
}

export const ExportButton = observer((props: Props) => {
    const { selectedRowKeys } = props

    const { t } = useTranslation(['common'])

    const onExport: MenuProps['onClick'] = async (e) => {
        const exportTestCases = {
            ids: selectedRowKeys,
            exportType: e.key,
        } as IExportItems

        await StoreTestCase.exportTestCases(exportTestCases)
    }

    const items: MenuProps['items'] = [
        {
            label: exportFileFormats.word,
            key: exportFileFormats.word,
            icon: <FileWordOutlined />,
            onClick: onExport,
        },
        {
            label: exportFileFormats.excel,
            key: exportFileFormats.excel,
            icon: <FileExcelOutlined />,
            onClick: onExport,
        },
        {
            label: exportFileFormats.adobeReader,
            key: exportFileFormats.adobeReader,
            icon: <FilePdfOutlined />,
            onClick: onExport,
        },
    ]

    return (
        <Dropdown menu={{ items }} disabled={SpinnerStore.show} placement="bottomRight">
            <Button type="primary" icon={<ExportOutlined />} className={styles.ExportButton}>
                {t('common:buttons.export')}
                <DownOutlined className={styles.DownSmallIcon} />
            </Button>
        </Dropdown>
    )
})
