import React from 'react'

import { observer } from 'mobx-react'

import { Typography } from 'antd'

import { ITestFolder } from '@/types'

import styles from './FoldersTab.module.scss'

const { Text } = Typography

type Props = {
    folder: ITestFolder
}

export const FolderName = observer((props: Props) => {
    const { folder } = props

    return (
        <Text className={styles.Name} ellipsis={{ tooltip: folder.name }}>
            {folder.name}
            {(!!folder.nestedTestCasesCount || !!folder.nestedTestSetsCount) && (
                <Text type="secondary" className={styles.Name}>
                    {' '}
                    ({folder.nestedTestCasesCount || folder.nestedTestSetsCount})
                </Text>
            )}
        </Text>
    )
})
