// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._OC5iT6V42H4OENslRm3{display:flex;flex-direction:column;-moz-column-gap:24px;column-gap:24px;width:280px;border-right:1px solid var(--color-other-divider6);padding-right:24px;margin-right:24px}._OC5iT6V42H4OENslRm3 .BcLJzP9DrFxhCH0CI747,._OC5iT6V42H4OENslRm3 .LgN4ujwUzzagm_Ju5hML{height:60px}._OC5iT6V42H4OENslRm3 .vp50lk3Xfhn0ZpCoVXiO{padding-bottom:16px}._OC5iT6V42H4OENslRm3 .RMVUBGln8b279LOI1Hzv{height:85px}._OC5iT6V42H4OENslRm3 ._LNFZQo77Fo8aEwqHrgi{display:flex;flex-direction:row-reverse;padding-top:32px;padding-bottom:32px}textarea{resize:none}", "",{"version":3,"sources":["webpack://./src/pages/CreateTestSet/Parameters/Parameters.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,oBAAA,CAAA,eAAA,CACA,WAAA,CACA,kDAAA,CACA,kBAAA,CACA,iBAAA,CAEA,wFAEI,WAAA,CAEJ,4CACI,mBAAA,CAEJ,4CACI,WAAA,CAGJ,4CACI,YAAA,CACA,0BAAA,CACA,gBAAA,CACA,mBAAA,CAIR,SACI,WAAA","sourcesContent":[".Form {\n    display: flex;\n    flex-direction: column;\n    column-gap: 24px;\n    width: 280px;\n    border-right: 1px solid var(--color-other-divider6);\n    padding-right: 24px;\n    margin-right: 24px;\n\n    .NameInput,\n    .SoftwareInput {\n        height: 60px;\n    }\n    .Description {\n        padding-bottom: 16px;\n    }\n    .DescriptionArea {\n        height: 85px;\n    }\n\n    .CreateTestSetButtons {\n        display: flex;\n        flex-direction: row-reverse;\n        padding-top: 32px;\n        padding-bottom: 32px;\n    }\n}\n\ntextarea {\n    resize: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": "_OC5iT6V42H4OENslRm3",
	"NameInput": "BcLJzP9DrFxhCH0CI747",
	"SoftwareInput": "LgN4ujwUzzagm_Ju5hML",
	"Description": "vp50lk3Xfhn0ZpCoVXiO",
	"DescriptionArea": "RMVUBGln8b279LOI1Hzv",
	"CreateTestSetButtons": "_LNFZQo77Fo8aEwqHrgi"
};
export default ___CSS_LOADER_EXPORT___;
