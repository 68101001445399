import { makeAutoObservable } from 'mobx'

import { themeType } from '@/types'

export const ThemeStore = makeAutoObservable({
    theme: 'light' as themeType,

    setTheme(value: themeType) {
        this.theme = value
    },
})
