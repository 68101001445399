import { showMessage } from '@/utils'

import { PreferencesStore } from '@/store'

export const copyLink = (href: string) => {
    navigator.clipboard
        .writeText(href)
        .then(() => {
            PreferencesStore.t('notification:success.linkCopied', {}).then((message) => {
                showMessage('success', message)
            })
        })
        .catch((err) => {
            PreferencesStore.t('notification:success.linkNotCopied', { err: err }).then((message) => {
                showMessage('error', message)
            })
        })
}
