import React from 'react'

import { observer } from 'mobx-react'

import styles from './StepsPanelHeader.module.scss'

type Props = {
    tableTitleText: string
    numOfTestCases: number
}

export const StepsPanelHeader = observer((props: Props) => {
    const { tableTitleText, numOfTestCases } = props

    return (
        <div className={styles.TableTitle}>
            <span className={styles.TableTitleText}>{tableTitleText}</span>
            <div className={styles.NumOfTestCases}>{numOfTestCases}</div>
        </div>
    )
})
