import React, { Dispatch, MouseEvent, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, notification, Typography, Upload } from 'antd'
import type { UploadProps } from 'antd'

import { DeleteOutlined, FormOutlined, UploadOutlined } from '@ant-design/icons'

import { SpinnerStore } from '@/store'
import { logoImageTypes, logoMaxSizeMb, unitsOfInformation } from '@/consts'

import type { RcFile, UploadFile } from 'antd/es/upload/interface'

import styles from './UploadLogo.module.scss'

const { Paragraph } = Typography

type Props = {
    setFileList: Dispatch<SetStateAction<UploadFile[]>>
    fileList: UploadFile[]
    currentLogo: UploadFile | undefined
    setSaved: Dispatch<SetStateAction<boolean>>
    setChangesSaved: Dispatch<SetStateAction<boolean>>
}

export const UploadLogo = observer((props: Props) => {
    const { fileList, setFileList, setSaved, currentLogo, setChangesSaved } = props
    const { t } = useTranslation(['common', 'page--workspaceSettings'])

    const isFileSizeAllowed = (file: RcFile) => file.size / unitsOfInformation.BytesInMb <= logoMaxSizeMb
    const isImageTypeAllowed = (file: RcFile) => logoImageTypes.includes(file.type)

    const beforeUpload = (file: RcFile) => {
        if (!isImageTypeAllowed(file)) {
            notification.error({
                message: t('page--workspaceSettings:mainSettings.logoImagesTypes'),
                placement: 'bottomRight',
            })
        }

        if (!isFileSizeAllowed(file)) {
            notification.error({
                message: t('page--workspaceSettings:mainSettings.logoMaxSize', {
                    logoMaxSize: logoMaxSizeMb * unitsOfInformation.KbInMb,
                }),
                placement: 'bottomRight',
            })
        }

        return false
    }

    const handleChange: UploadProps['onChange'] = ({ file: file, fileList: newFileList }) => {
        if (isFileSizeAllowed(file as RcFile) && isImageTypeAllowed(file as RcFile)) {
            if (fileList) {
                setSaved(false)
                setChangesSaved(false)
            }
            setFileList(newFileList)
        } else {
            if (currentLogo && fileList[0].uid !== currentLogo.uid) {
                setSaved(false)
                setChangesSaved(false)
            }
        }
    }

    const handleDelete = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setFileList([])
        setSaved(false)
        setChangesSaved(false)
    }

    return (
        <div className={styles.UploadLogo}>
            <p className={styles.Title}>{t('page--workspaceSettings:mainSettings.workspaceBrandBook')}</p>
            <Upload
                customRequest={() => null}
                listType="picture"
                onChange={handleChange}
                fileList={fileList}
                beforeUpload={beforeUpload}
                maxCount={1}
                className={styles.Upload}
                onPreview={() => null}
                accept="image/jpeg, image/png, image/gif"
            >
                {fileList.length >= 1 ? (
                    <div className={styles.UpdateButtons}>
                        <Button type="default" icon={<FormOutlined />} disabled={SpinnerStore.show}>
                            {t('page--workspaceSettings:mainSettings.updateLogo')}
                        </Button>
                        <Button
                            type="default"
                            danger
                            icon={<DeleteOutlined />}
                            disabled={SpinnerStore.show}
                            onClick={(e) => handleDelete(e)}
                        >
                            {t('common:buttons.delete')}
                        </Button>
                    </div>
                ) : (
                    <Button type="primary" icon={<UploadOutlined />} disabled={SpinnerStore.show}>
                        {t('page--workspaceSettings:mainSettings.uploadLogo')}
                    </Button>
                )}
            </Upload>

            <Paragraph className={styles.UploadInfo}>
                {t('page--workspaceSettings:mainSettings.logoNotification')}
            </Paragraph>
            <Paragraph className={styles.UploadInfo}>
                {t('page--workspaceSettings:mainSettings.logoFormat', {
                    logoMaxSize: logoMaxSizeMb * unitsOfInformation.KbInMb,
                })}
            </Paragraph>
        </div>
    )
})
