import React from 'react'

import cn from 'classnames'

import { UserAvatar } from '@/components/Avatar/UserAvatar'

import styles from './UserMessage.module.scss'

type Props = {
    userName: string
    message: string
    ownMessage: boolean
    avatar?: string | null | undefined
    size?: number
}

export const UserMessage = (props: Props) => {
    const { userName, avatar, size, message, ownMessage } = props
    return (
        <div className={cn(styles.UserMessage, { [styles.OwnMessage]: ownMessage })}>
            <UserAvatar userName={userName} avatarId={avatar} size={size} />
            <div className={styles.UserTextInfo}>
                <div className={styles.UserName}>{userName}</div>
                {message && <div className={styles.Message}>{message}</div>}
            </div>
        </div>
    )
}
