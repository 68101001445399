import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Input } from 'antd'
import { ToggleFilters } from '@/components/ToggleFilters/ToggleFilters'
import { ToggleSearchArea } from '../ToggleSearchArea/ToggleSearchArea'

import { StoreAuthController } from '@/store'
import { pageUrls, userSearchFields } from '@/consts'

import { IGetUserIn, IUserWidthFilter, searchAreaTypes, settingsPageType } from '@/types'

import styles from './SearchField.module.scss'

type Props = {
    users: IGetUserIn[]
    setSearchResults: Dispatch<SetStateAction<IGetUserIn[] | IUserWidthFilter[]>>
    searchValue: string
    searchDebounced: string
    setSearchValue: Dispatch<string>
    searchArea: searchAreaTypes
    setSearchArea: Dispatch<SetStateAction<searchAreaTypes>>
    showFilters: boolean
    setShowFilters: Dispatch<SetStateAction<boolean>>
    pageType: settingsPageType
    workspaceId: string
    canManageUsers: boolean
}

export const SearchField = observer((props: Props) => {
    const {
        users,
        setSearchValue,
        searchValue,
        searchDebounced,
        setSearchResults,
        searchArea,
        setSearchArea,
        showFilters,
        setShowFilters,
        pageType,
        workspaceId,
        canManageUsers,
    } = props
    const { t } = useTranslation(['page--workspaceSettings', 'page--projectSettings'])
    const [workspaceUsers, setWorkspaceUsers] = useState<IGetUserIn[]>([])

    const isUserFound = (user: IGetUserIn, value: string) => {
        return Object.entries(user).some(
            (key) => userSearchFields.includes(key[0]) && key[1] && key[1].toLowerCase().includes(value)
        )
    }

    const globalSearchUsers = async (value: string): Promise<IUserWidthFilter[]> => {
        return (await StoreAuthController.searchUsers(value)).result
    }

    const getUsersInWorkspace = async () => {
        const foundedWSUsers = (await StoreAuthController.getUsersInWorkspace(workspaceId)).workspaceUsers
        foundedWSUsers.map((user) => (user.isInCurrentTeam = isInCurrentTeam(user.userId)))
        setWorkspaceUsers(foundedWSUsers)
        return foundedWSUsers
    }

    const searchWorkSpaceUsers = async (value: string) => {
        return workspaceUsers.filter((user) => isUserFound(user, value))
    }

    const isInCurrentTeam = (userId: string) => {
        return users.some((wsUser) => wsUser.userId === userId)
    }

    const localSearch = (value: string) => {
        const foundedUsers = users.filter((user) => isUserFound(user, value))
        setSearchResults(foundedUsers)
    }

    const globalSearch = async (value: string) => {
        if (value) {
            const foundedUsers =
                pageType === pageUrls.workspaceSettings
                    ? await globalSearchUsers(value)
                    : await searchWorkSpaceUsers(value)
            foundedUsers.map(
                (user) => (user.isInCurrentTeam = isInCurrentTeam('userId' in user ? user.userId : user.id))
            )
            setSearchResults(foundedUsers)
        } else {
            if (pageType === pageUrls.workspaceSettings) {
                setSearchResults([])
            } else {
                const foundedUsers = await getUsersInWorkspace()
                foundedUsers.map((user) => (user.isInCurrentTeam = isInCurrentTeam(user.userId)))
                setSearchResults(foundedUsers)
            }
        }
    }

    const onSearch = async (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value.toLowerCase()
        setSearchValue(value)

        if (searchArea === 'local') {
            localSearch(value)
        }
    }

    useEffect(() => {
        if (searchArea === 'global') {
            globalSearch(searchDebounced)
        }
    }, [searchDebounced, searchArea])

    return (
        <Input
            onChange={onSearch}
            suffix={
                canManageUsers && (
                    <ToggleSearchArea
                        searchArea={searchArea}
                        setSearchArea={setSearchArea}
                        localSearch={localSearch}
                        globalSearch={globalSearch}
                        searchValue={searchValue}
                        pageType={pageType}
                    />
                )
            }
            allowClear
            value={searchValue}
            className={styles.SearchUsers}
            placeholder={
                searchArea === 'local'
                    ? pageType === pageUrls.workspaceSettings
                        ? t('page--workspaceSettings:team.localSearchUser')
                        : t('page--projectSettings:team.localSearchUser')
                    : pageType === pageUrls.workspaceSettings
                      ? t('page--workspaceSettings:team.globalSearchUser')
                      : t('page--projectSettings:team.globalSearchUser')
            }
            addonAfter={
                pageType === pageUrls.workspaceSettings && (
                    <ToggleFilters showFilters={showFilters} setShowFilters={setShowFilters} />
                )
            }
        />
    )
})
