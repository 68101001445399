// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pAzmSjrYXcVV4vUfPD5A{display:flex;flex-flow:column;height:100%}.pAzmSjrYXcVV4vUfPD5A .B2pIzEwOU13EyDDidgKx{display:flex;flex-flow:column;padding-bottom:0}.pAzmSjrYXcVV4vUfPD5A .Zfn9xBMYo4gK6aCdH7cF .h7ETWGovU2KZkgh_88Dp{display:flex;align-items:center;justify-content:space-between}.pAzmSjrYXcVV4vUfPD5A .Zfn9xBMYo4gK6aCdH7cF .h7ETWGovU2KZkgh_88Dp .Q_98AGnc6am5X63wGR0P{margin:0}.pAzmSjrYXcVV4vUfPD5A .q4LX0AT98toc6BJpzgEJ{display:flex;height:100%;margin-top:20px}", "",{"version":3,"sources":["webpack://./src/pages/CreateTestSet/CreateTestSet.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,gBAAA,CACA,WAAA,CAEA,4CACI,YAAA,CACA,gBAAA,CACA,gBAAA,CAIA,kEACI,YAAA,CACA,kBAAA,CACA,6BAAA,CAEA,wFACI,QAAA,CAIZ,4CACI,YAAA,CACA,WAAA,CACA,eAAA","sourcesContent":[".CreateTestSet {\n    display: flex;\n    flex-flow: column;\n    height: 100%;\n\n    .ContentWrapper {\n        display: flex;\n        flex-flow: column;\n        padding-bottom: 0;\n    }\n\n    .TitleWithBreadcrumbs {\n        .TitleRow {\n            display: flex;\n            align-items: center;\n            justify-content: space-between;\n\n            .Title {\n                margin: 0;\n            }\n        }\n    }\n    .ParametersAndTestCases {\n        display: flex;\n        height: 100%;\n        margin-top: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CreateTestSet": "pAzmSjrYXcVV4vUfPD5A",
	"ContentWrapper": "B2pIzEwOU13EyDDidgKx",
	"TitleWithBreadcrumbs": "Zfn9xBMYo4gK6aCdH7cF",
	"TitleRow": "h7ETWGovU2KZkgh_88Dp",
	"Title": "Q_98AGnc6am5X63wGR0P",
	"ParametersAndTestCases": "q4LX0AT98toc6BJpzgEJ"
};
export default ___CSS_LOADER_EXPORT___;
