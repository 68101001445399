import React, { useEffect, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams, useSearchParams } from 'react-router-dom'

import { Form, Layout, Typography } from 'antd'
import { Header } from '@/components/Header/Header'
import { Breadcrumbs } from './Breadcrumbs'
import { Parameters } from './Parameters/Parameters'
import { SelectTestCases } from './SelectTestCases/SelectTestCases'

import { StoreAuthController, StoreCreateTestSet, StoreProject } from '@/store'

import { IGetUserIn, IProject } from '@/types'

import styles from './CreateTestSet.module.scss'

const { Title } = Typography
const { Content } = Layout

export const CreateTestSet = observer(() => {
    const { projectId } = useParams()

    const { t } = useTranslation(['page--createTestSet'])
    const [form] = Form.useForm()
    const [projectInfo, setProjectInfo] = useState<IProject>()
    const [projectUsers, setProjectUsers] = useState<IGetUserIn[]>([])
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (!StoreProject.id && (projectId || StoreCreateTestSet.selectedProjectId)) {
            StoreProject.setProjectId(projectId || StoreCreateTestSet.selectedProjectId)
            form.setFieldValue('project', projectId || StoreCreateTestSet.selectedProjectId)
        }

        if (StoreCreateTestSet.selectedProjectId) {
            StoreAuthController.getUsersInProject([StoreCreateTestSet.selectedProjectId]).then((users) => {
                setProjectUsers(users.projectUsers)
            })
        }
    }, [StoreCreateTestSet.selectedProjectId, searchParams])

    useEffect(() => {
        const folderId = searchParams.get('folderId')
        if (folderId) {
            folderId && StoreCreateTestSet.setSelectedTestFolderId(folderId)
        }
    }, [searchParams])

    return (
        <div className={styles.CreateTestSet}>
            <Header />
            <Content className={cn('content', styles.ContentWrapper)}>
                <div className={styles.TitleWithBreadcrumbs}>
                    <Breadcrumbs projectInfo={projectInfo} />
                    <div className={styles.TitleRow}>
                        <Title level={3} className={styles.Title}>
                            {t('page--createTestSet:createTestSet')}
                        </Title>
                    </div>
                </div>

                <div className={styles.ParametersAndTestCases}>
                    {projectId && <Parameters form={form} projectUsers={projectUsers} />}
                    {projectId && (
                        <SelectTestCases
                            projectId={projectId}
                            setProjectInfo={setProjectInfo}
                            setProjectUsers={setProjectUsers}
                        />
                    )}
                </div>
            </Content>
        </div>
    )
})
