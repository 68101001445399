// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ocZm25SyF4EDBLHGpv2d .KOpDAMiDEy9fTdCgVLP2,.ocZm25SyF4EDBLHGpv2d .E5FmLyr14PL8btqXKH3M div{font-size:14px}.ocZm25SyF4EDBLHGpv2d .rlr75u6H9CkZ3nfzF8_r{margin-left:8px}.ocZm25SyF4EDBLHGpv2d .E5FmLyr14PL8btqXKH3M{margin-top:16px}", "",{"version":3,"sources":["webpack://./src/pages/ProjectSettings/Integrations/JiraForm/JiraForm.module.scss"],"names":[],"mappings":"AACI,4FAEI,cAAA,CAGJ,4CACI,eAAA,CAGJ,4CACI,eAAA","sourcesContent":[".JiraForm {\n    .Text,\n    .Alert div {\n        font-size: 14px;\n    }\n\n    .FinishButton {\n        margin-left: 8px;\n    }\n\n    .Alert {\n        margin-top: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"JiraForm": "ocZm25SyF4EDBLHGpv2d",
	"Text": "KOpDAMiDEy9fTdCgVLP2",
	"Alert": "E5FmLyr14PL8btqXKH3M",
	"FinishButton": "rlr75u6H9CkZ3nfzF8_r"
};
export default ___CSS_LOADER_EXPORT___;
