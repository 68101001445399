import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { RcFile } from 'antd/lib/upload'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, notification, Upload } from 'antd'
import type { UploadFile, UploadProps } from 'antd'

import { UploadOutlined } from '@ant-design/icons'

import { SpinnerStore } from '@/store'
import { documentMaxSizeMb, unitsOfInformation } from '@/consts'

import { IAttachment, IFileToUpload, IMixedAttachments, IProject, IWorkspace } from '@/types'
import { EAttachment, EEntityType } from '@/enums'

type Props = {
    existingObjectId: string
    entityType: EEntityType
    attachmentType: EAttachment
    fileList: IMixedAttachments[]
    setFileList: Dispatch<SetStateAction<IMixedAttachments[]>>
    setSaved: Dispatch<SetStateAction<boolean>>
    isSaved: boolean
    setFilesToUpload: Dispatch<SetStateAction<IFileToUpload[]>>
    setAttachments: Dispatch<SetStateAction<IAttachment[]>>
    attachments: IAttachment[]
    filesToUpload: IFileToUpload[]
    parentInfo: IWorkspace | IProject
    setChangesSaved: Dispatch<SetStateAction<boolean>>
}

export const DocumentAttachments = observer((props: Props) => {
    const {
        existingObjectId,
        attachmentType,
        entityType,
        fileList,
        setSaved,
        setFilesToUpload,
        parentInfo,
        filesToUpload,
        setChangesSaved,
    } = props

    const { t } = useTranslation(['common'])
    const storageCapacity =
        parentInfo.documentationSizeCapacity / unitsOfInformation.BytesInKb / unitsOfInformation.KbInMb

    const [totalMb, setTotalMb] = useState<number>(0)
    const [isEnoughSpace, setIsEnoughSpace] = useState(true)

    useEffect(() => {
        if (!isEnoughSpace) {
            notification.error({
                message: t('documentation:documentMaxSize', {
                    documentMaxSize: documentMaxSizeMb,
                }),
                placement: 'bottomRight',
            })
            setIsEnoughSpace(true)
        }
    }, [isEnoughSpace])

    useEffect(() => {
        const calculatedTotalFileListBytes = fileList.reduce((acc, file) => {
            if ('fileSize' in file) {
                return acc + (file as IAttachment).fileSize
            } else {
                return acc
            }
        }, 0)

        const calculatedTotalFileListMb =
            calculatedTotalFileListBytes / unitsOfInformation.BytesInKb / unitsOfInformation.KbInMb

        const calculatedTotalFilesToUploadBytes = filesToUpload.reduce((acc, file) => {
            if ('size' in file) {
                return acc + (file as { size: number }).size
            } else {
                return acc
            }
        }, 0)

        const calculatedTotalFilesToUploadMb =
            calculatedTotalFilesToUploadBytes / unitsOfInformation.BytesInKb / unitsOfInformation.KbInMb

        const calculatedTotalMb = calculatedTotalFileListMb + calculatedTotalFilesToUploadMb
        setTotalMb(calculatedTotalMb)
    }, [fileList, filesToUpload])

    const isSingleFileSizeAllowed = (file: RcFile): boolean =>
        file.size / unitsOfInformation.BytesInMb <= storageCapacity

    const beforeUpload = (file: RcFile, files: RcFile[]) => {
        const newFileListSize =
            totalMb +
            files.reduce((acc, file) => {
                return acc + (file.size || 0) / unitsOfInformation.BytesInMb
            }, 0)

        const isEnoughSpace = newFileListSize < storageCapacity

        if (!isSingleFileSizeAllowed(file) || !isEnoughSpace) {
            setIsEnoughSpace(false)
            return false
        }
    }

    const handleChange: UploadProps['onChange'] = ({ fileList, file }) => {
        const newFileListSize =
            totalMb +
            fileList.reduce((acc, file) => {
                return acc + (file.size || 0) / unitsOfInformation.BytesInMb
            }, 0)

        const isEnoughSpace = newFileListSize < storageCapacity
        if (isSingleFileSizeAllowed(file as RcFile) && isEnoughSpace) {
            const updatedFilesToUpload = fileList
                .map((file) => {
                    if (!('existingAttachmentId' in file)) {
                        return {
                            ...file,
                            existingObjectId: existingObjectId,
                            attachmentType: attachmentType,
                            entityType: entityType,
                        }
                    }
                })
                .filter((file) => file)

            setFilesToUpload(updatedFilesToUpload as IFileToUpload[])
            setSaved(false)
            setChangesSaved(false)
        }
    }

    return (
        <Upload
            name="documentAttachments"
            customRequest={() => null}
            multiple
            disabled={SpinnerStore.show}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            fileList={fileList as UploadFile[]}
            showUploadList={false}
        >
            <Button type="default" icon={<UploadOutlined />} disabled={SpinnerStore.show}>
                {t('common:buttons.uploadFile')}
            </Button>
        </Upload>
    )
})
