// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e3puaUCZojT2zHmNY5h0 .htKWyU1Ltd8JTuHC6CQm{display:flex;justify-content:space-between;margin-bottom:24px}.e3puaUCZojT2zHmNY5h0 .htKWyU1Ltd8JTuHC6CQm .X5p3UEAR9ywQZ5eefAf5{display:flex;justify-content:space-between;align-items:baseline}.e3puaUCZojT2zHmNY5h0 .htKWyU1Ltd8JTuHC6CQm .X5p3UEAR9ywQZ5eefAf5 .Ihkr1PNa_Q_0iI3tQ8Ub{margin-top:14px}.e3puaUCZojT2zHmNY5h0 .htKWyU1Ltd8JTuHC6CQm .X5p3UEAR9ywQZ5eefAf5 .G5n3surUEdRJ67RwPDQC{display:flex;gap:8px}.e3puaUCZojT2zHmNY5h0 .htKWyU1Ltd8JTuHC6CQm ._65SZisPwjQqI7OzxNTbi{display:flex;gap:8px;justify-content:flex-end}", "",{"version":3,"sources":["webpack://./src/components/SettingPageTabs/Documentation/Documentation.module.scss"],"names":[],"mappings":"AACI,4CACI,YAAA,CACA,6BAAA,CACA,kBAAA,CAEA,kEACI,YAAA,CACA,6BAAA,CACA,oBAAA,CAEA,wFACI,eAAA,CAGJ,wFACI,YAAA,CACA,OAAA,CAIR,mEACI,YAAA,CACA,OAAA,CACA,wBAAA","sourcesContent":[".Documentation {\n    .Header {\n        display: flex;\n        justify-content: space-between;\n        margin-bottom: 24px;\n\n        .TitleRow {\n            display: flex;\n            justify-content: space-between;\n            align-items: baseline;\n\n            .SettingTitle {\n                margin-top: 14px;\n            }\n\n            .ActionMenu {\n                display: flex;\n                gap: 8px;\n            }\n        }\n\n        .TableMenu {\n            display: flex;\n            gap: 8px;\n            justify-content: flex-end;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Documentation": "e3puaUCZojT2zHmNY5h0",
	"Header": "htKWyU1Ltd8JTuHC6CQm",
	"TitleRow": "X5p3UEAR9ywQZ5eefAf5",
	"SettingTitle": "Ihkr1PNa_Q_0iI3tQ8Ub",
	"ActionMenu": "G5n3surUEdRJ67RwPDQC",
	"TableMenu": "_65SZisPwjQqI7OzxNTbi"
};
export default ___CSS_LOADER_EXPORT___;
