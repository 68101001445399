import React, { Dispatch, Key, SetStateAction, useEffect, useMemo } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { mapOrder } from '@/utils'

import { notification, Table } from 'antd'
import { OrderNumColumn } from '../OrderNumColumn/OrderNumColumn'
import { StepEditor } from '../StepEditor/StepEditor'
import { StepIssues } from '../StepIssues/StepIssues'
import { StepMenu } from '../StepMenu/StepMenu'

import { SpinnerStore, StoreCreateTestSet, StoreTestCase } from '@/store'

import { IStep, IStepColumnNames } from '@/types'
import { EStepType } from '@/enums'

import styles from './StepsTable.module.scss'

type Props = {
    steps: IStep[]
    setSteps: Dispatch<SetStateAction<IStep[]>>
    setShowMenu: Dispatch<SetStateAction<boolean>>
    selectedRowKeys: Key[]
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    stepType: EStepType
    isExecutionTestCase: boolean
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
}

export const Steps = observer((props: Props) => {
    const {
        steps,
        setSteps,
        setShowMenu,
        selectedRowKeys,
        setSelectedRowKeys,
        stepType,
        isExecutionTestCase,
        setTestCaseSavedStatus,
    } = props
    const { t } = useTranslation(['testCase'])

    const onShowNotStartedTooltip = () => {
        if (!StoreTestCase.executionCaseStarted) {
            if (StoreTestCase.completeWithError) {
                notification.warning({
                    message: t('testCase:clickRepeatTest'),
                    placement: 'bottomRight',
                })
            } else if (
                StoreTestCase.executionCaseStatus === 'PASSED' ||
                StoreTestCase.executionCaseStatus === 'SKIPPED'
            ) {
                notification.warning({
                    message: t('testCase:editingResultsUnavaliable'),
                    placement: 'bottomRight',
                })
            } else {
                notification.warning({
                    message: t('testCase:clickRunTest'),
                    placement: 'bottomRight',
                })
            }
        }
    }

    const updateStep = async (value: string, column: IStepColumnNames, row: IStep) => {
        const rowCopy = { ...row }

        try {
            if (column === 'actions') {
                if (rowCopy.description !== value) {
                    rowCopy.actions = [{ description: value, orderNum: 1 }]
                    handleSave({ ...rowCopy, description: value })
                }
            } else {
                if (rowCopy[column] !== value) {
                    rowCopy[column] = value
                    handleSave({ ...rowCopy })
                }
            }
        } catch (errInfo) {
            console.log('Save failed:', errInfo)
        }
    }

    const actionColumnConditionSteps = {
        key: 'description',
        title: t('testCase:action'),
        dataIndex: 'description',
        render: (description: string, step: IStep) => (
            <StepEditor
                columnName={'description'}
                defaultValue={description}
                step={step}
                updateStep={updateStep}
                isExecutionTestCase={isExecutionTestCase}
            />
        ),
    }

    const actionColumnDefaultSteps = {
        key: 'actions',
        title: t('testCase:action'),
        dataIndex: 'actions',
        render: (actions: IStep[], step: IStep) =>
            actions.map((action: IStep) => {
                if (step.manual) {
                    return (
                        <StepEditor
                            key={`${actions}-${step.orderNum}`}
                            columnName={'actions'}
                            defaultValue={actions[0].description}
                            step={step}
                            updateStep={updateStep}
                            isExecutionTestCase={isExecutionTestCase}
                        />
                    )
                }

                return action.description
            }),
    }

    const actualResultColumn = {
        key: 'actualResult',
        title: t('testCase:actualResult'),
        dataIndex: 'actualResult',
        render: (actualResult: string, step: IStep) => (
            <span onClick={onShowNotStartedTooltip} className={styles.TableCell}>
                <StepEditor
                    columnName={'actualResult'}
                    defaultValue={actualResult}
                    step={step}
                    updateStep={updateStep}
                    isExecutionTestCase={isExecutionTestCase}
                />
            </span>
        ),
    }

    const optionalTestCaseColumns =
        StoreTestCase.executionTestCase || StoreCreateTestSet.isCreatingTestSet
            ? []
            : [
                  {
                      key: 'stepMenu',
                      title: t('testCase:operations'),
                      dataIndex: 'stepMenu',
                      className: styles.StepMenu,
                      render: (orderNum: number, step: IStep) => (
                          <StepMenu
                              step={step}
                              setSteps={setSteps}
                              steps={steps}
                              setSelectedRowKeys={setSelectedRowKeys}
                              selectedRowKeys={selectedRowKeys}
                              isExecutionTestCase={isExecutionTestCase}
                              setTestCaseSavedStatus={setTestCaseSavedStatus}
                          />
                      ),
                  },
              ]

    const defaultColumns = [
        {
            key: 'step',
            title: t('testCase:step'),
            dataIndex: 'orderNum',
            render: (orderNum: number, step: IStep) => (
                <OrderNumColumn
                    orderNum={orderNum}
                    step={step}
                    steps={steps}
                    setSteps={setSteps}
                    setTestCaseSavedStatus={setTestCaseSavedStatus}
                />
            ),
        },

        stepType === EStepType.DEFAULT_STEP ? actionColumnDefaultSteps : actionColumnConditionSteps,

        {
            key: 'expectedResult',
            title: t('testCase:expectedResult'),
            dataIndex: 'expectedResult',
            render: (expectedResult: string, step: IStep) => (
                <StepEditor
                    columnName={'expectedResult'}
                    defaultValue={expectedResult}
                    step={step}
                    updateStep={updateStep}
                    isExecutionTestCase={isExecutionTestCase}
                />
            ),
        },

        actualResultColumn,

        {
            key: 'testData',
            title: t('testCase:testData'),
            dataIndex: 'testData',
            render: (testData: string, step: IStep) => (
                <StepEditor
                    columnName={'testData'}
                    defaultValue={testData}
                    step={step}
                    updateStep={updateStep}
                    isExecutionTestCase={isExecutionTestCase}
                />
            ),
        },
        {
            key: 'comment',
            title: t('testCase:comment'),
            dataIndex: 'comment',
            render: (comment: string, step: IStep) => (
                <StepEditor
                    columnName={'comment'}
                    defaultValue={comment}
                    step={step}
                    updateStep={updateStep}
                    isExecutionTestCase={isExecutionTestCase}
                />
            ),
        },

        ...optionalTestCaseColumns,
    ]

    const handleSave = async (row: IStep) => {
        const newData = [...steps]
        const index = newData.findIndex((item) => row.orderNum === item.orderNum)
        const item = newData[index]
        newData.splice(index, 1, {
            ...item,
            ...row,
        })
        setSteps(newData)
        await setTestCaseSavedStatus(false)
    }

    const hiddenColumnsDictionary = {
        testData: !StoreTestCase.showColumns.testData,
        comment: !StoreTestCase.showColumns.comment,
        actualResult: !StoreTestCase.executionTestCase,
    }

    const columns = useMemo(
        () =>
            defaultColumns
                .filter(({ key }) => {
                    const isHidden = hiddenColumnsDictionary[key as keyof typeof hiddenColumnsDictionary]

                    return !isHidden
                })
                .map((column) => ({
                    ...column,
                    onCell: () => ({
                        dataIndex: column.dataIndex,
                        className: styles.TableCell,
                    }),
                })),
        [
            defaultColumns,
            hiddenColumnsDictionary.testData,
            hiddenColumnsDictionary.comment,
            hiddenColumnsDictionary.actualResult,
        ]
    )

    const sortedColumns = useMemo(
        () => mapOrder(columns, StoreTestCase.columnsOrder, 'key'),
        [columns, StoreTestCase.columnsOrder]
    )

    useEffect(() => {
        setShowMenu(!!selectedRowKeys.length)
    }, [selectedRowKeys])

    const expandedRowRender = (record: IStep) => {
        return <StepIssues step={record} steps={steps} setSteps={setSteps} isExecutionTestCase={isExecutionTestCase} />
    }

    //+4 - это номер + 2 постоянных колонки + меню шага, если 5-6 колонок, последние 2 выровнять по правому краю
    const alignQlToolbarToRight =
        !isExecutionTestCase && +StoreTestCase.showColumns.testData + +StoreTestCase.showColumns.comment + 4 >= 5

    return (
        <Table
            bordered={false}
            dataSource={steps}
            columns={sortedColumns}
            pagination={false}
            rowKey={'orderNum'}
            rowClassName={styles.Row}
            showHeader={false}
            className={cn(styles.Steps, {
                [styles.ExeSteps]: isExecutionTestCase,
                [styles.AlignQlToolbarToRight]: alignQlToolbarToRight,
            })}
            loading={SpinnerStore.show}
            expandable={{
                showExpandColumn: false,
                expandedRowRender: isExecutionTestCase ? expandedRowRender : undefined,
                defaultExpandAllRows: true,
                expandedRowClassName: (record) =>
                    cn(styles.StepIssue, {
                        [styles.ShowStepIssue]:
                            record.jiraIssuesWithData?.length ||
                            (StoreTestCase.executionCaseStarted && StoreTestCase.jiraIntegrationAvailability),
                    }),
                expandIcon: undefined,
            }}
        />
    )
})
