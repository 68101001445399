import React, { useEffect, useMemo, useState } from 'react'

import { disable as disableDarkMode, enable as enableDarkMode } from 'darkreader'
import { useTranslation } from 'react-i18next'

import { LocalStorage } from '@/utils'

import { Tooltip } from 'antd'

import { DarkThemeIcon, LightThemeIcon } from '@/components/Icons'

import { ThemeStore } from '@/store'

export const DarkSwitcher = () => {
    const { t } = useTranslation(['common'])

    const defaultTheme = useMemo(() => (LocalStorage.get('theme') == 'dark' ? true : false), [])

    const [isDark, setIsDark] = useState(defaultTheme)

    const toggleDark = () => {
        setIsDark(!isDark)
    }

    useEffect(() => {
        if (isDark) {
            LocalStorage.set('theme', 'dark')
            ThemeStore.setTheme('dark')
            enableDarkMode({
                brightness: 100,
                contrast: 90,
                sepia: 10,
            })
        } else {
            LocalStorage.set('theme', 'light')
            ThemeStore.setTheme('light')
            disableDarkMode()
        }
    }, [isDark])

    const themeTooltip = isDark ? t('common:toggleTheme.light') : t('common:toggleTheme.dark')

    return (
        <Tooltip title={themeTooltip}>
            <div onClick={toggleDark}>{isDark ? <LightThemeIcon /> : <DarkThemeIcon />}</div>
        </Tooltip>
    )
}
