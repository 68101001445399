import { observable } from 'mobx'

import { sleep } from '@/utils/async'

export const SpinnerStore = observable({
    _show: 0,

    get show() {
        return Boolean(this._show > 0)
    },

    set show(value: boolean) {
        if (value) {
            this.setShow(value)
        } else {
            sleep(200).then(() => {
                this.setShow(value)
            })
        }
    },

    setShow(value: boolean) {
        this._show = value ? 1 : 0
    },

    hide() {
        this._show = 0
    },
})
