// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IoYoAZNA4REmjARy5zxz .aL3cSdogu1KRx1TWGQQQ{margin-top:14px}.IoYoAZNA4REmjARy5zxz .qnRwU0vkD2XfPVfrKGwu span{font-size:14px}.ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-tabs-card .ant-tabs-nav{width:auto}.ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-tabs-card .ant-tabs-nav .ant-tabs-tab{padding-left:16px}", "",{"version":3,"sources":["webpack://./src/components/SettingPageTabs/RoleModel/RoleModel.module.scss"],"names":[],"mappings":"AACI,4CACI,eAAA,CAGJ,iDACI,cAAA,CAKJ,0FACI,UAAA,CAGJ,wGACI,iBAAA","sourcesContent":[".RoleModel {\n    .SettingTitle {\n        margin-top: 14px;\n    }\n\n    .RolesTabs span {\n        font-size: 14px;\n    }\n}\n\n:global {\n    .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-tabs-card .ant-tabs-nav {\n        width: auto;\n    }\n\n    .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .ant-tabs-card .ant-tabs-nav .ant-tabs-tab {\n        padding-left: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RoleModel": "IoYoAZNA4REmjARy5zxz",
	"SettingTitle": "aL3cSdogu1KRx1TWGQQQ",
	"RolesTabs": "qnRwU0vkD2XfPVfrKGwu"
};
export default ___CSS_LOADER_EXPORT___;
