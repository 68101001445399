import React, { ReactNode } from 'react'

import { Tooltip } from 'antd'

import { TooltipPlacement } from 'antd/es/tooltip'

interface ITooltipWithDisabling {
    children: ReactNode
    disabled: boolean
    title: string
    isVisible: boolean
    setIsVisible: (visible: boolean) => void
    placement?: TooltipPlacement
    trigger?: ('contextMenu' | 'click' | 'hover')[] | undefined
}

export const TooltipWithDisabling = ({
    children,
    disabled,
    title,
    isVisible,
    setIsVisible,
    placement,
    trigger,
}: ITooltipWithDisabling) => {
    return (
        <Tooltip
            title={title}
            placement={placement ?? 'topLeft'}
            onOpenChange={(visible) => {
                disabled && setIsVisible(visible)
            }}
            open={isVisible}
            trigger={trigger}
        >
            {children}
        </Tooltip>
    )
}
