import React, { useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, notification, Upload } from 'antd'
import { TestCaseAttachment } from './TestCaseAttachment'

import { UploadOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreCreateTestSet, StoreTestCase } from '@/store'
import { attachmentMaxSizeMb, unitsOfInformation } from '@/consts'

import { IMixedAttachments } from '@/types'
import { EAttachment } from '@/enums'
import type { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload/interface'

import styles from './UploadTestCaseAttachments.module.scss'

const { Dragger } = Upload

type Props = {
    fileList: IMixedAttachments[]
    attachmentType: EAttachment
    handleChange: (info: UploadChangeParam<UploadFile<any>>) => void
}

export const DraggerUpload = observer((props: Props) => {
    const { fileList, handleChange, attachmentType } = props
    const { t } = useTranslation(['common'])

    const beforeUpload = (file: RcFile) => {
        const isFileSizeAllowed = (file: RcFile) => file.size / unitsOfInformation.BytesInMb <= attachmentMaxSizeMb
        if (!isFileSizeAllowed(file)) {
            notification.error({
                message: t('common:buttons.attachmentMaxSize', {
                    attachmentMaxSize: attachmentMaxSizeMb,
                }),
                placement: 'bottomRight',
            })
        }

        return false
    }

    const [dragOverUpload, setDragOverUpload] = useState(false)

    const onDrop = () => {
        onDragLeave()
    }

    const onDragOver = () => {
        setDragOverUpload(true)
    }

    const onDragLeave = () => {
        setDragOverUpload(false)
    }

    const isDisabled =
        (StoreTestCase.readOnly && StoreTestCase.executionTestCase && attachmentType === EAttachment.TEST_CASE) ||
        SpinnerStore.show ||
        (StoreTestCase.executionTestCase && !StoreTestCase.executionCaseStarted) ||
        StoreCreateTestSet.isCreatingTestSet ||
        StoreTestCase.testCaseInfo.isDeleted

    return (
        <Dragger
            name="testCaseAttachments"
            customRequest={() => null}
            multiple
            beforeUpload={beforeUpload}
            disabled={isDisabled}
            className={cn(styles.Dragger, {
                dragOverUpload: dragOverUpload,
                [styles.TestCaseAttachment]: attachmentType === EAttachment.TEST_CASE,
                [styles.ExecutionTestCaseAttachment]: attachmentType === EAttachment.EXECUTION_CASE,
                [styles.HiddenDragger]: StoreTestCase.executionTestCase && attachmentType === EAttachment.TEST_CASE,
            })}
            onChange={handleChange}
            fileList={fileList as UploadFile[]}
            itemRender={(_originNode, file: IMixedAttachments) => <TestCaseAttachment file={file} />}
        >
            <div
                onDragOver={onDragOver}
                onDragLeave={onDragOver}
                onMouseUp={onDragLeave}
                onMouseLeave={onDragLeave}
                onDrop={onDrop}
            >
                <p className="ant-upload-text">{t('common:buttons.uploadDragText')}</p>

                <Button
                    onClick={onDrop}
                    className="draggerUploadButton"
                    size="small"
                    type="primary"
                    icon={<UploadOutlined />}
                    disabled={isDisabled}
                >
                    {t('common:buttons.uploadButton')}
                </Button>
                <p className="ant-upload-text">
                    {t('common:buttons.attachmentMaxSize', {
                        attachmentMaxSize: attachmentMaxSizeMb,
                    })}
                </p>
            </div>
        </Dragger>
    )
})
