// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tuAO1qOWK4gdqEX3ZMca{display:flex;flex-direction:column;width:370px}.tuAO1qOWK4gdqEX3ZMca .An7PDjsnRumEB_c9DhOQ{display:flex;align-items:center;gap:8px}.tuAO1qOWK4gdqEX3ZMca .An7PDjsnRumEB_c9DhOQ .CspX8XDxfptLIuKn0Lyr{margin:0}.tuAO1qOWK4gdqEX3ZMca .vfad0UQoL5brktnftJ2E{display:flex;gap:8px}.tuAO1qOWK4gdqEX3ZMca .vfad0UQoL5brktnftJ2E span,.tuAO1qOWK4gdqEX3ZMca .vfad0UQoL5brktnftJ2E a{font-size:14px}.tuAO1qOWK4gdqEX3ZMca .vfad0UQoL5brktnftJ2E .d9EcaEGImyz3K6VwwcV1{color:var(--color-menu-item-text-selected)}.tuAO1qOWK4gdqEX3ZMca .uHPApOusDPMAnitqU8tz{display:none}.tuAO1qOWK4gdqEX3ZMca:hover .uHPApOusDPMAnitqU8tz{display:block}", "",{"version":3,"sources":["webpack://./src/pages/ProjectSettings/Integrations/IntegrationCard/IntegrationCard.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,WAAA,CAEA,4CACI,YAAA,CACA,kBAAA,CACA,OAAA,CAEA,kEACI,QAAA,CAIR,4CACI,YAAA,CACA,OAAA,CAEA,+FAEI,cAAA,CAGJ,kEACI,0CAAA,CAIR,4CACI,YAAA,CAIA,kDACI,aAAA","sourcesContent":[".IntegrationCard {\n    display: flex;\n    flex-direction: column;\n    width: 370px;\n\n    .IntegrationCardTitle {\n        display: flex;\n        align-items: center;\n        gap: 8px;\n\n        .Title {\n            margin: 0;\n        }\n    }\n\n    .IntegrationCardText {\n        display: flex;\n        gap: 8px;\n\n        span,\n        a {\n            font-size: 14px;\n        }\n\n        .Link {\n            color: var(--color-menu-item-text-selected);\n        }\n    }\n\n    .Dropdown {\n        display: none;\n    }\n\n    &:hover {\n        .Dropdown {\n            display: block;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"IntegrationCard": "tuAO1qOWK4gdqEX3ZMca",
	"IntegrationCardTitle": "An7PDjsnRumEB_c9DhOQ",
	"Title": "CspX8XDxfptLIuKn0Lyr",
	"IntegrationCardText": "vfad0UQoL5brktnftJ2E",
	"Link": "d9EcaEGImyz3K6VwwcV1",
	"Dropdown": "uHPApOusDPMAnitqU8tz"
};
export default ___CSS_LOADER_EXPORT___;
