import React, { Dispatch, Key, SetStateAction, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { MsAttachmentApi, MsProjectApi } from '@/api'

import { hasProjectPermissions, hasWorkspacePermissions } from '@/utils'

import { Button, Popconfirm, Typography } from 'antd'
import { ModalSave } from '@/components/ModalSave/ModalSave'
import { DocumentAttachments } from './DocumentAttachments/DocumentAttachments'
import { DocumentsTable } from './DocumentAttachments/DocumentsTable'
import { UploadScale } from './UploadScale/UploadScale'

import { DeleteOutlined, SaveFilled } from '@ant-design/icons'

import { SpinnerStore, StoreWorkspace } from '@/store'
import { apiUrls, pageUrls, settingTabs } from '@/consts'

import {
    IAttachment,
    IBindAttachment,
    IFileToUpload,
    IMixedAttachments,
    IObjectAttachment,
    IObjectAttachments,
    IProject,
    IWorkspace,
    settingsPageType,
} from '@/types'
import { EAttachment, EEntityType, EPermissions } from '@/enums'

import styles from './Documentation.module.scss'

const { Title } = Typography

type Props = {
    selectedTab: string
    currentTab: string
    isLeavingTab: boolean
    setLeavingTab: Dispatch<SetStateAction<boolean>>
    parentInfo: IWorkspace | IProject
    leaveTab: () => void
    pageType: settingsPageType
    changesSaved: boolean
    setChangesSaved: Dispatch<SetStateAction<boolean>>
    goGoBack: () => void
    isGoingBack: boolean
    setGoingBack: Dispatch<SetStateAction<boolean>>
}

export const Documentation = observer((props: Props) => {
    const {
        currentTab,
        isLeavingTab,
        setLeavingTab,
        selectedTab,
        parentInfo,
        leaveTab,
        pageType,
        changesSaved,
        setChangesSaved,
        goGoBack,
        setGoingBack,
        isGoingBack,
    } = props
    const { t } = useTranslation(['documentation', 'common'])

    const [isSaved, setSaved] = useState(true)
    const [fileList, setFileList] = useState<IMixedAttachments[]>([])
    const [filesToUpload, setFilesToUpload] = useState<IFileToUpload[]>([])
    const [attachments, setAttachments] = useState<IAttachment[]>([])
    const [uploadingAttachment, setUploadingAttachment] = useState(false)
    const [filesToDelete, setFilesToDelete] = useState<IAttachment[]>([])
    const [isModalSaveOpen, setModalSaveOpen] = useState(false)

    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])

    const attachmentType =
        pageType === pageUrls.workspaceSettings ? EAttachment.WORKSPACE_DOCUMENT : EAttachment.PROJECT_DOCUMENT
    const entityType = pageType === pageUrls.workspaceSettings ? EEntityType.WORKSPACE : EEntityType.PROJECT

    const getAttachmentsWithData = () => {
        parentInfo.id &&
            StoreWorkspace.getAttachmentsWithData([parentInfo.id], [attachmentType]).then((resp) => {
                setFileList(resp)
                setAttachments(resp)
                setFilesToUpload([])
            })
    }

    useEffect(() => {
        getAttachmentsWithData()
    }, [])

    const canUploadAttachments: boolean =
        pageType === pageUrls.workspaceSettings
            ? useMemo(() => hasWorkspacePermissions([EPermissions.CREATE_ATTACHMENTS], parentInfo.id), [parentInfo.id])
            : 'workspaceId' in parentInfo &&
              useMemo(
                  () => hasProjectPermissions([EPermissions.CREATE_ATTACHMENTS], parentInfo.id, parentInfo.workspaceId),
                  [parentInfo.id]
              )

    const canDeleteAttachments: boolean =
        pageType === pageUrls.workspaceSettings
            ? useMemo(() => hasWorkspacePermissions([EPermissions.DELETE_ATTACHMENTS], parentInfo.id), [parentInfo.id])
            : 'workspaceId' in parentInfo &&
              useMemo(
                  () => hasProjectPermissions([EPermissions.DELETE_ATTACHMENTS], parentInfo.id, parentInfo.workspaceId),
                  [parentInfo.id]
              )

    const showDeleteButton = !!selectedRowKeys.length && canDeleteAttachments
    const showSaveButton = !isSaved && (canUploadAttachments || canDeleteAttachments)

    const bindAttachments = async (files: IBindAttachment[]) => {
        const attachments: IObjectAttachments = { objectAttachments: [] }
        files.forEach((file) => {
            const attachment = {
                existingAttachmentId: file.id,
                existingObjectId: file.existingObjectId,
                entityType: file.entityType,
                attachmentType: file.attachmentType,
                description: file.description,
            } as IObjectAttachment
            attachments.objectAttachments.push(attachment as any)
        })

        await MsProjectApi.bindAttachments(attachments)
    }

    const uploadAttachments = async () => {
        try {
            const files: IAttachment[] = (await MsAttachmentApi.save(filesToUpload)).data

            const filesWithTypes: IBindAttachment[] = files.map((file, index: number) => {
                return {
                    ...file,
                    existingObjectId: filesToUpload[index].existingObjectId,
                    attachmentType: filesToUpload[index].attachmentType,
                    entityType: filesToUpload[index].entityType,
                    description: filesToUpload[index].description,
                }
            })
            await bindAttachments(filesWithTypes)
        } catch (err) {
            console.log('err: ', err)
        }
    }

    const deleteAttachments = async () => {
        SpinnerStore.setShow(true)
        const filebindIds = filesToDelete
            .map((attachment) => attachment?.bindId && attachment.bindId)
            .filter((id) => Boolean(id)) as string[]

        await MsProjectApi.deleteMultipleAttachments(filebindIds)

        SpinnerStore.setShow(false)
    }

    const onSave = async () => {
        SpinnerStore.setShow(true)

        if (filesToUpload.length && !uploadingAttachment) {
            setUploadingAttachment(true)
            await uploadAttachments()
            setUploadingAttachment(false)
        }

        if (filesToDelete.length) {
            await deleteAttachments()
            setFilesToDelete([])
        }

        setTimeout(() => getAttachmentsWithData(), 1000)
        setSaved(true)
        setChangesSaved(true)
        setSelectedRowKeys([])
        SpinnerStore.setShow(false)
        setModalSaveOpen(false)
    }

    useEffect(() => {
        const updatedFileList = [...filesToUpload, ...attachments]
        setFileList(updatedFileList)
    }, [filesToUpload, attachments])

    const deleteFiles = () => {
        const updatedAttachments = attachments.filter(
            (attachment) => !selectedRowKeys.includes(attachment.bindId as Key)
        )

        const updatedFilesToUpload = filesToUpload.filter(
            (attachment) => !selectedRowKeys.includes(attachment.uid as Key)
        )

        const updatedToDeleteFiles = [
            ...filesToDelete,
            ...attachments.filter((attachment) => selectedRowKeys.includes(attachment.bindId as Key)),
        ]

        setFilesToDelete(updatedToDeleteFiles)
        setAttachments(updatedAttachments)
        setFilesToUpload(updatedFilesToUpload)
        setSelectedRowKeys([])
        setSaved(false)
        setChangesSaved(false)
    }

    const onUndo = () => {
        setSaved(true)
        setChangesSaved(true)
        getAttachmentsWithData()
        setModalSaveOpen(false)
    }

    const handleDontSave = () => {
        setFilesToDelete([])
        onUndo()
        isLeavingTab ? leaveTab() : goGoBack()
    }

    const handleSave = async () => {
        await onSave()
        isLeavingTab ? leaveTab() : goGoBack()
    }

    const handleCancel = () => {
        setModalSaveOpen(false)
        setLeavingTab(false)
        setGoingBack(false)
    }

    useEffect(() => {
        if (isLeavingTab && currentTab === settingTabs.documentation) {
            if (isSaved) {
                leaveTab()
            } else {
                setModalSaveOpen(true)
            }
        }
    }, [isLeavingTab])
    useEffect(() => {
        if (isGoingBack) {
            if (changesSaved) {
                goGoBack()
            } else {
                setModalSaveOpen(true)
            }
        }
    }, [isGoingBack])
    return (
        <div className={styles.Documentation}>
            <div className={styles.Header}>
                <div className={styles.TitleRow}>
                    <Title level={4} className={styles.SettingTitle}>
                        {t('documentation:title')}
                    </Title>
                </div>

                <div className={styles.TableMenu}>
                    {canUploadAttachments && (
                        <DocumentAttachments
                            existingObjectId={parentInfo!.id}
                            entityType={entityType}
                            attachmentType={attachmentType}
                            isSaved={isSaved}
                            setSaved={setSaved}
                            fileList={fileList}
                            setFileList={setFileList}
                            setFilesToUpload={setFilesToUpload}
                            attachments={attachments}
                            setAttachments={setAttachments}
                            filesToUpload={filesToUpload}
                            parentInfo={parentInfo}
                            setChangesSaved={setChangesSaved}
                        />
                    )}

                    {showSaveButton && (
                        <div className={styles.ActionMenu}>
                            <Button type="primary" icon={<SaveFilled />} onClick={onSave} disabled={SpinnerStore.show}>
                                {t('common:buttons.save')}
                            </Button>
                        </div>
                    )}
                    {showDeleteButton && (
                        <Popconfirm title={t('common:buttons.deleteGuard')} onConfirm={deleteFiles}>
                            <Button type="default" icon={<DeleteOutlined />} danger disabled={SpinnerStore.show}>
                                {t('common:buttons.deleteItemWithCounter', {
                                    name:
                                        selectedRowKeys.length > 1 ? t('documentation:files') : t('documentation:file'),
                                    counter: selectedRowKeys.length,
                                })}
                            </Button>
                        </Popconfirm>
                    )}
                </div>
            </div>
            <UploadScale fileList={fileList} filesToUpload={filesToUpload} parentInfo={parentInfo} />
            <DocumentsTable
                setSaved={setSaved}
                setFileList={setFileList}
                fileList={fileList}
                filesToUpload={filesToUpload}
                setFilesToUpload={setFilesToUpload}
                filesToDelete={filesToDelete}
                setFilesToDelete={setFilesToDelete}
                attachments={attachments}
                setAttachments={setAttachments}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                canUploadAttachments={canUploadAttachments}
                canDeleteAttachments={canDeleteAttachments}
            />

            {isModalSaveOpen && (
                <ModalSave
                    isModalSaveOpen={
                        (selectedTab !== settingTabs.documentation && !isSaved && isModalSaveOpen) || !changesSaved
                    }
                    handleDontSave={handleDontSave}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    pageType={pageType}
                />
            )}
        </div>
    )
})
