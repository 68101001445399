import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Progress } from 'antd'

import { ThemeStore } from '@/store'
import { unitsOfInformation } from '@/consts'

import { IAttachment, IFileToUpload, IMixedAttachments, IProject, IWorkspace } from '@/types'

import styles from './UploadScale.module.scss'

interface Props {
    fileList: IMixedAttachments[]
    filesToUpload: IFileToUpload[]
    parentInfo: IWorkspace | IProject
}

export const UploadScale = observer((props: Props) => {
    const { parentInfo } = props
    const { t } = useTranslation('documentation')

    const storageCapacity =
        parentInfo.documentationSizeCapacity / unitsOfInformation.BytesInKb / unitsOfInformation.KbInMb

    const [totalMegabytes, setTotalMegabytes] = useState<number>(0)
    const [percentUsed, setPercentUsed] = useState<number>(0)

    const isDarkTheme = useMemo(() => ThemeStore.theme === 'dark', [ThemeStore.theme])

    useEffect(() => {
        const totalFileListBytes = props.fileList.reduce((acc, file) => {
            if ('fileSize' in file) {
                return acc + (file as IAttachment).fileSize
            } else {
                return acc
            }
        }, 0)

        const totalFilesToUploadBytes = props.filesToUpload.reduce((acc, file) => {
            if ('size' in file) {
                return acc + (file as { size: number }).size
            } else {
                return acc
            }
        }, 0)

        const totalBytes = totalFileListBytes + totalFilesToUploadBytes
        const newTotalMegabytes = totalBytes / 1024 / 1024
        const newPercentUsed = (newTotalMegabytes / storageCapacity) * 100

        setTotalMegabytes(newTotalMegabytes)
        setPercentUsed(newPercentUsed)
    }, [props.fileList, props.filesToUpload, storageCapacity])

    const status = percentUsed >= 100 ? 'exception' : undefined

    const trailColor = isDarkTheme ? '#D0D0D0' : '#E4E0D8'

    return (
        <div className={styles.UploadScale}>
            <Progress
                percent={percentUsed}
                strokeLinecap="butt"
                format={() => `${totalMegabytes.toFixed(2)} / ${storageCapacity} ${t('documentation:mB')}`}
                status={status}
                trailColor={trailColor}
            />
        </div>
    )
})
