import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { Button, Tooltip, Typography } from 'antd'
import { NavMenu } from '@/components/NavMenu/NavMenu'
import { ProjectListDropdown } from './ProjectListDropdown/ProjectListDropdown'

import { SettingOutlined } from '@ant-design/icons'

import { StoreWorkspace } from '@/store'
import { pageUrls } from '@/consts'

import { IProject } from '@/types'

import styles from './TestFolderHeader.module.scss'

const { Title } = Typography

type Props = {
    projectInfo: IProject
    selectFolderOnly?: boolean
}

export const TestFolderHeader = observer((props: Props) => {
    const { projectInfo, selectFolderOnly } = props
    const { t } = useTranslation(['page--testFolders'])
    const { pathname } = useLocation()

    const [tooltipVisible, setTooltipVisible] = useState(false)

    const handleMouseEnter = () => {
        setTooltipVisible(true)
    }

    const handleMouseLeave = () => {
        setTooltipVisible(false)
    }

    return (
        <div className={styles.TestFolderHeader}>
            <div className={styles.TestFolderHeaderLeft}>
                <div className={styles.Title}>
                    {projectInfo?.projectDescription ? (
                        <Tooltip
                            title={projectInfo?.projectDescription}
                            open={tooltipVisible}
                            onOpenChange={setTooltipVisible}
                        >
                            <Title
                                level={3}
                                className={styles.ProjectName}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                {projectInfo?.projectName}
                            </Title>
                        </Tooltip>
                    ) : (
                        <Title
                            level={3}
                            className={styles.ProjectName}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                        >
                            {projectInfo?.projectName}
                        </Title>
                    )}

                    <div className={styles.TitleSuffix}>
                        <Tooltip title={t('page--testFolders:projectSettings')}>
                            <Link
                                to={`${pageUrls.projectSettings}/${projectInfo.id}`}
                                state={{ previousPath: pathname }}
                            >
                                <Button icon={<SettingOutlined />} className={styles.BackButton} />
                            </Link>
                        </Tooltip>
                        {StoreWorkspace.projectList.length > 1 && <ProjectListDropdown />}
                    </div>
                </div>
                {!selectFolderOnly && <NavMenu />}
            </div>
        </div>
    )
})
