import Quill from 'quill'

import 'quill-paste-smart'

import QuillImageDropAndPaste from 'quill-image-drop-and-paste'

Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)

export const ReactQuillModules = {
    toolbar: [
        ['bold', 'italic'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['clean'],
    ],
    clipboard: {
        matchVisual: false,
        substituteBlockElements: false, //вставка из ворда вставляла лишние параграфы
    },
    //запрет на дроп картинок, чтобы дроп обрабатывался отдельно и для всех файлов, а не только картинок
    imageDropAndPaste: {
        handler: null,
    },
} as const

export const ReactQuillFormats = ['bold', 'italic', 'list', 'bullet', 'indent']
