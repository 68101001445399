import axios from 'axios'
import { observable } from 'mobx'
import qs from 'qs'

import { LocalStorage, Nav, SessionStorage } from '@/utils'

import { notification } from 'antd'
import { ModalInfo } from '@/components/ModalInfo/ModalInfo'

import { PreferencesStore, SpinnerStore, StoreAuthController } from '@/store'
import { apiUrls, baseApiUrl, errorsTypes, pageUrls, showAllPages } from '@/consts'

import {
    IRoleFilter,
    ISearchUsers,
    IServerMessage,
    IServerResponse,
    IUpdateCurrentUserPassword,
    IUpdateUser,
    IUserWithId,
} from '@/types'
import { EmailEventType } from '@/enums'

export const pendingRequests: { [key: string]: boolean } = {}

const baseURL = `${baseApiUrl}/ms-auth/auth`

export function useAuthorizedAPI(baseURL: string) {
    const AuthorizeApi = axios.create({
        baseURL,
        headers: {
            post: { 'Content-Type': 'application/json' },
        },
    })

    const addPendingRequest = (key?: string) => {
        if (!key) return
        pendingRequests[key] = true
    }

    const deletePendingRequest = (key?: string) => {
        if (!key) return
        delete pendingRequests[key]
    }

    const clearPendingRequest = () => {
        for (const prop of Object.getOwnPropertyNames(pendingRequests)) {
            delete pendingRequests[prop]
        }
    }

    AuthorizeApi.interceptors.request.use(
        async (config) => {
            if (config.auth) return config
            await MsAuthApi.updateToken()
            if (MsAuthApi.accessToken && config.headers) {
                config.headers['Authorization'] = `Bearer ${MsAuthApi.accessToken}`
            }
            const key = config.url?.split('?')[0]
            addPendingRequest(key)
            return config
        },
        (error) => {
            console.log('error', error)
            clearPendingRequest()
            return Promise.reject(error)
        }
    )

    AuthorizeApi.interceptors.response.use(
        function (response) {
            response.data = ProcessServerResponse(response.data)
            const key = response.config.url?.split('?')[0]
            deletePendingRequest(key)
            return response
        },
        async (error) => {
            const isInvalidTokenError =
                error.response?.data?.metadata?.messages?.ERROR[0]?.messageMnemoCode === errorsTypes.invalidTokenError
            const config = error?.config

            //обновить токен если истек
            if (isInvalidTokenError && !config?.sent) {
                try {
                    config.sent = true
                    await MsAuthApi.updateToken(true)
                    config.headers = {
                        ...config.headers,
                        authorization: `Bearer ${MsAuthApi.accessToken}`,
                    }
                    return axios(config)
                } catch (error: any) {
                    if (error?.response?.data) {
                        const processedError = ProcessServerResponse(error.response?.data)
                        return Promise.reject(processedError)
                    } else return Promise.reject(error)
                }
            }
            //иначе обычная обработка ошибок
            else {
                clearPendingRequest()
                if (error.message === 'canceledByController' || StoreAuthController.hideError) {
                    return
                }
                if (!StoreAuthController.hideError) {
                    const processedError = ProcessServerResponse(error.response?.data)
                    if (processedError === 'invalidToken') {
                        Nav.push('/')
                    } else {
                        return Promise.reject(processedError)
                    }
                }
            }
        }
    )

    return AuthorizeApi
}

const apiAuthAuthorized = useAuthorizedAPI(baseURL)

const displayMessages = (messages: IServerMessage[], type: 'error' | 'warning' | 'info') => {
    for (const message of messages) {
        PreferencesStore.t(`notification:${message.messageMnemoCode}`, message.parameters).then((text) => {
            notification[type]({
                message: text,
                placement: 'bottomRight',
            })
        })
    }
}

const ProcessServerResponse = (response: IServerResponse) => {
    if (!response?.metadata) {
        return response // Is binary file
    }

    const messages = response.metadata.messages

    const isUserBlocked = messages?.ERROR[0]?.messageMnemoCode === errorsTypes.userIsDisabledError
    const isInvalidToken = messages?.ERROR[0]?.messageMnemoCode === errorsTypes.invalidTokenError

    if (!isUserBlocked && !isInvalidToken) {
        displayMessages(messages.ERROR, 'error')
    } else {
        if (isUserBlocked && !isInvalidToken) {
            StoreAuthController.isBlockedUser = true
            MsAuthApi.signOut()

            if (Nav.location.pathname === pageUrls.signIn) {
                PreferencesStore.t('notification:error.youAreDisabled', {}).then((message) => {
                    ModalInfo({
                        title: message,
                        modalType: 'error',
                    })
                })
            }
        }
    }

    displayMessages(messages.WARNING, 'warning')
    displayMessages(messages.INFO, 'info')

    if (response.httpStatus !== 200) {
        SpinnerStore.hide()
        throw new Error(SERVER_ERROR)
    }

    if (isInvalidToken) {
        return 'invalidToken'
    }

    return response.payload
}

const api = axios.create({
    baseURL: baseURL,
    headers: {
        post: { 'Content-Type': 'application/json' },
    },
})

api.interceptors.response.use(
    function (response) {
        response.data = ProcessServerResponse(response.data)
        return response
    },
    (e) => {
        Promise.reject(ProcessServerResponse(e.response.data))
    }
)

export const SERVER_ERROR = 'SERVER_ERROR'

const ACCESS_TOKEN = 'MsAuthApi/accessToken'
const REFRESH_TOKEN = 'MsAuthApi/refreshToken'
const TOKEN_EXPIRATION = 'MsAuthApi/tokenExpiration'

export const MsAuthApi = observable({
    accessToken: LocalStorage.get(ACCESS_TOKEN) || (SessionStorage.get(ACCESS_TOKEN) as string | null),
    tokenExpiration: +(LocalStorage.get(TOKEN_EXPIRATION) || SessionStorage.get(TOKEN_EXPIRATION) || '0') as number,
    refreshToken: (LocalStorage.get(REFRESH_TOKEN) || SessionStorage.get(REFRESH_TOKEN)) as string | null,
    keepRefreshTokenInLocalStorage: LocalStorage.get('rememberMe') === 'yes',

    get isSignedIn() {
        return this.accessToken !== null
    },

    get expirationToken() {
        return this.tokenExpiration
    },

    async signUp(params: { username: string; email: string; firstname: string; lastname: string; password: string }) {
        await api.post('/user/new', {
            ...params,
        })
        await this.emailConfirmation(params.email, EmailEventType.EMAIL_CONFIRMATION_EVENT)
    },

    async emailConfirmation(email: string, type: EmailEventType) {
        await api.post('/user/send-auth-event-message-to-email', {
            email,
            eventType: type,
        })
    },

    async userActivate(params: { userId: string; requestId: string }) {
        await api.post('/user/activate-user', params)
    },

    async changePassword(params: { userId: string; requestId: string; newPassword: string }) {
        await api.post('/user/change-password-by-event-request', {
            authRequestDto: {
                userId: params.userId,
                requestId: params.requestId,
            },
            newPassword: params.newPassword,
        })
    },

    async signIn(params: { username: string; password: string }) {
        await this.token({
            ...params,
            grant_type: 'password',
        })
    },

    async updateToken(forceUpdate?: boolean) {
        const now = new Date().getTime()
        if (this.tokenExpiration > now && !forceUpdate) {
            return false
        }
        if (!this.refreshToken) {
            this.signOut()
        } else {
            try {
                await this.token({
                    grant_type: 'refresh_token',
                    refresh_token: this.refreshToken,
                })
                return
            } catch (e) {
                this.signOut()
                throw e
            }
        }
    },

    async updateTokenSocket() {
        SpinnerStore.show = true
        try {
            await this.token({
                grant_type: 'refresh_token',
                refresh_token: this.refreshToken ?? '',
            })
            SpinnerStore.show = false
            return
        } catch (e) {
            SpinnerStore.show = false
            throw e
        }
    },

    async token(params: Record<string, string>) {
        const response = await api.post('/oauth/token', qs.stringify(params), {
            auth: {
                username: 'client',
                password: 'client',
            },
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
        this.setToken(response.data)
    },

    setToken(token: { access_token: string; expires_in: number; refresh_token: string }) {
        this.accessToken = token.access_token
        this.refreshToken = token.refresh_token
        this.tokenExpiration = new Date().getTime() + token.expires_in * 1000

        if (this.keepRefreshTokenInLocalStorage) {
            LocalStorage.set(ACCESS_TOKEN, `${this.accessToken}`)
            LocalStorage.set(REFRESH_TOKEN, `${this.refreshToken}`)
            LocalStorage.set(TOKEN_EXPIRATION, `${this.tokenExpiration}`)
            LocalStorage.set(REFRESH_TOKEN, `${this.refreshToken}`)
        } else {
            SessionStorage.set(ACCESS_TOKEN, `${this.accessToken}`)
            SessionStorage.set(REFRESH_TOKEN, `${this.refreshToken}`)
            SessionStorage.set(TOKEN_EXPIRATION, `${this.tokenExpiration}`)
            SessionStorage.set(REFRESH_TOKEN, `${this.refreshToken}`)
        }
    },

    signOut() {
        this.accessToken = null
        this.refreshToken = null
        this.tokenExpiration = 0

        if (this.keepRefreshTokenInLocalStorage) {
            LocalStorage.delete(ACCESS_TOKEN)
            LocalStorage.delete(REFRESH_TOKEN)
            LocalStorage.delete(TOKEN_EXPIRATION)
            LocalStorage.delete(REFRESH_TOKEN)
        } else {
            SessionStorage.delete(ACCESS_TOKEN)
            SessionStorage.delete(REFRESH_TOKEN)
            SessionStorage.delete(TOKEN_EXPIRATION)
            SessionStorage.delete(REFRESH_TOKEN)
        }

        this.keepRefreshTokenInLocalStorage = false
    },

    async getUser(userId: string): Promise<IUserWithId> {
        return (await apiAuthAuthorized.get(`user/${userId}`)).data
    },

    async updateUser(props: IUpdateUser): Promise<IUpdateUser> {
        return (await apiAuthAuthorized.put(`${apiUrls.user}/update-user-info`, props)).data
    },

    async changeCurrentUserPassword(props: IUpdateCurrentUserPassword) {
        return await apiAuthAuthorized.post(`${apiUrls.user}/change-password`, props)
    },

    async accounts_getUsers(username?: string[]) {
        const request = {
            filter: {
                username: username || [],
                firstname: [],
                middlename: [],
                lastname: [],
                cn: [],
            },

            sort: [
                {
                    field: 'username',
                    direction: 'ASC',
                },
            ],
            pageSize: showAllPages,
            page: 0,
        }

        return (await apiAuthAuthorized.post(`${apiUrls.user}/filter`, request)).data
    },

    async searchUsers(userInfo: ISearchUsers) {
        const request = {
            filter: {
                ...userInfo,
            },

            sort: [],
            pageSize: showAllPages,
            page: 0,
        }
        return (await apiAuthAuthorized.post(`${apiUrls.user}/width-filter`, request)).data
    },

    async getUsersInWorkspace(workspaceId: string) {
        const res = (await apiAuthAuthorized.get(`${apiUrls.role}/get-users-in-workspace?workspaceId=${workspaceId}`))
            .data
        return res
    },

    async getUsersInProject(projectIds: string[]) {
        const request = projectIds.map((id) => `projectIds=${id}`).join('&')
        const res = (await apiAuthAuthorized.get(`${apiUrls.role}/get-users-in-projects?${request}`)).data
        return res
    },

    async getRoles(roleFilter: IRoleFilter) {
        const request = {
            filter: roleFilter,
            sort: [
                {
                    field: 'name',
                    direction: 'ASC',
                },
            ],
            pageSize: showAllPages,
            page: 0,
        }

        return (await apiAuthAuthorized.post(`${apiUrls.role}/filter`, request)).data.result
    },

    async addRoles(userId: string, roleIds: string[]) {
        await apiAuthAuthorized.post(`${apiUrls.user}/add-role`, {
            userId: userId,
            roleIds: roleIds,
        })
        return
    },

    async deleteRoles(userId: string, roleIds: string[]) {
        await apiAuthAuthorized.post(`${apiUrls.user}/delete-role`, {
            userId: userId,
            roleIds: roleIds,
        })
        return
    },

    async getPermissions() {
        const request = {
            filter: {},
            sort: [
                {
                    field: 'name',
                    direction: 'ASC',
                },
            ],
            pageSize: showAllPages,
            page: 0,
        }

        return (await apiAuthAuthorized.post(`${apiUrls.permission}/filter`, request)).data.result
    },

    async removeFromWorkspace(userId: string, workspaceId: string) {
        await apiAuthAuthorized.delete(`${apiUrls.user}/${userId}/remove-from-workspace/${workspaceId}`)
        return
    },

    async assignToWorkspace(userIds: string[], projectId: string) {
        await apiAuthAuthorized.post(`${apiUrls.user}/assign-to-workspace`, {
            entityId: projectId,
            userIds: userIds,
        })
        return
    },

    async assignToProject(userIds: string[], projectId: string) {
        const resp = await apiAuthAuthorized.post(`${apiUrls.user}/assign-to-project`, {
            entityId: projectId,
            userIds: userIds,
        })
        return resp
    },

    async removeFromProject(userId: string, projectId: string) {
        await apiAuthAuthorized.delete(`${apiUrls.user}/${userId}/remove-from-project/${projectId}`)
        return
    },
})
