import React, { Dispatch, Key, SetStateAction, useMemo, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { setPositionOnContextMenu, toDateFormatWithoutHours } from '@/utils'

import { Button, Table, Typography } from 'antd'
import { TestSetProgressBar } from '@/components/TestSetProgressBar/TestSetProgressBar'
import { TableCell } from './TableCell'
import { TestSetOptions } from './TestSetOptions'

import { DownOutlined, MoreOutlined, UpOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreTestSet } from '@/store'
import { pageUrls, showAllPages } from '@/consts'

import { dropdownPositionType, ITestSet } from '@/types'
import { ColumnsType, ColumnType } from 'antd/es/table/interface'

import styles from './TestSetsTable.module.scss'

const { Paragraph, Text } = Typography

interface ITestSetList {
    testSets: ITestSet[]
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    selectedRowKeys: Key[]
    setTestSets: Dispatch<SetStateAction<ITestSet[]>>
    loadData: () => void
    handleShowModalSelectTestCase?: (testSet: ITestSet) => void
    showInModal?: boolean
    trigger?: ('contextMenu' | 'click' | 'hover')[] | undefined
    isUserPage?: boolean
}

interface ITableColumns {
    (testSets: ITestSet[]): ColumnsType<ITestSet> | any
}

export const TestSetsTable = observer((props: ITestSetList) => {
    const {
        testSets,
        setSelectedRowKeys,
        selectedRowKeys,
        showInModal,
        setTestSets,
        loadData,
        handleShowModalSelectTestCase,
        isUserPage,
    } = props
    const { t } = useTranslation(['page--testSets', 'common'])

    const [noEllipsisDescriptions, setNoEllipsisDescriptions] = useState<string[]>([])

    const [selectedRecord, setSelectedRecord] = useState<ITestSet | undefined>()
    const [dropdownPosition, setDropdownPosition] = useState<dropdownPositionType>({
        x: 0,
        y: 0,
    })

    const onSelectChange = (newSelectedRowKeys: Key[], newSelectedTestSets: ITestSet[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
        StoreTestSet.setSelectedTestSets(newSelectedTestSets)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const expandedRowRender = (record: ITestSet) => {
        return (
            <div className={styles.DropdownInfo}>
                <div className={styles.Columns}>
                    <div className={styles.Column}>
                        <Text className={styles.Label} type="secondary">
                            {t('page--testSets:testCases')}
                        </Text>
                        {record.executionCases?.length}
                    </div>

                    <div className={styles.Column}>
                        <Text className={styles.Label} type="secondary">
                            {t('page--testSets:dueTime')}
                        </Text>

                        {record.dueTime
                            ? `${
                                  record.dueTime.hours > 0 ? ` ${record.dueTime.hours}${t('common:timeFormat.h')}` : ''
                              }${
                                  record.dueTime.minutes > 0
                                      ? ` ${record.dueTime.minutes}${t('common:timeFormat.m')}`
                                      : ''
                              }`
                            : '—'}
                    </div>
                    <div className={styles.Column}>
                        <Text className={styles.Label} type="secondary">
                            {t('page--testSets:runtime')}
                        </Text>

                        {record.beginningPeriod
                            ? `${toDateFormatWithoutHours(record.beginningPeriod)} — ${toDateFormatWithoutHours(
                                  record.endingPeriod
                              )}`
                            : '—'}
                    </div>
                </div>
                <div>{record.progress && <TestSetProgressBar progress={record.progress} />}</div>
            </div>
        )
    }

    const getTableColumns: ITableColumns = (testSets) => {
        if (!testSets) return []
        return [
            {
                key: 'name',
                className: cn('linkColumn', styles.MainColumn),
                dataIndex: 'name',

                render: (testSetName: string, testSet: ITestSet) => (
                    <Link
                        className={styles.TestSetRowLinkWrapper}
                        draggable="false"
                        to={`${pageUrls.testSet}/${testSet?.id}`}
                    >
                        <div key={testSet.orderNum} className={styles.TestSetRow}>
                            <div className={styles.TestSetHeader}>
                                <div className={styles.Title}>
                                    <Text
                                        ellipsis={
                                            !noEllipsisDescriptions.includes(testSet.id!) && {
                                                tooltip: testSet.name,
                                            }
                                        }
                                        className={styles.TestSetName}
                                    >
                                        {testSet.name}
                                    </Text>

                                    <Text className={styles.OrderNum} type="secondary">
                                        - ID {testSet.orderNum}
                                    </Text>
                                </div>
                            </div>
                            {testSet.description && (
                                <Paragraph
                                    className={styles.Description}
                                    type="secondary"
                                    ellipsis={
                                        !noEllipsisDescriptions.includes(testSet.id!) && {
                                            tooltip: testSet.description,
                                        }
                                    }
                                >
                                    {testSet.description}
                                </Paragraph>
                            )}
                        </div>
                    </Link>
                ),
            },
            {
                key: 'status',
                dataIndex: 'status',
                className: 'linkColumn statusColumn',
                render: (executionTestSetStatus: string, testSet: ITestSet) => (
                    <TableCell testSet={testSet} value={testSet.executionTestSetStatus} isStatusCell />
                ),
            },

            {
                key: 'createdDate',
                dataIndex: 'createdDate',
                className: 'linkColumn dateColumn',
                render: (createdDate: string, testSet: ITestSet) => (
                    <TableCell testSet={testSet} value={toDateFormatWithoutHours(+createdDate)} />
                ),
            },
            {
                dataIndex: 'testerId',
                key: 'testerId',
                className: 'linkColumn',
                render: (testerId: string, testSet: ITestSet) => (
                    <TableCell testSet={testSet} value={testSet?.testerId} isUserCell />
                ),
            },

            {
                className: 'dropdownMenu',
                render: (id: string, testSet: ITestSet) =>
                    !showInModal && (
                        <TestSetOptions
                            testSet={testSet}
                            testSets={testSets}
                            setTestSets={setTestSets}
                            loadData={loadData}
                            handleShowModalSelectTestCase={handleShowModalSelectTestCase}
                            button={<Button type="text" icon={<MoreOutlined />} />}
                            setSelectedRecord={setSelectedRecord}
                        />
                    ),
            },
        ]
    }

    const columns = useMemo(
        () =>
            getTableColumns(testSets)
                .map((column: ColumnType<unknown>) => {
                    if (column.key !== 'tester' || (column.key !== 'tester' && !isUserPage)) {
                        return column
                    }
                })
                .filter((newColumn: ColumnType<unknown>) => newColumn),
        [t, testSets, noEllipsisDescriptions, isUserPage]
    )

    return (
        <>
            <Table
                className={styles.TestSetsTable}
                columns={columns}
                expandable={{
                    expandedRowRender: expandedRowRender,
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <UpOutlined
                                onClick={(e) => {
                                    onExpand(record, e)

                                    record.id &&
                                        setNoEllipsisDescriptions([
                                            ...noEllipsisDescriptions.filter((id) => id !== record.id),
                                        ])
                                }}
                            />
                        ) : (
                            <DownOutlined
                                onClick={(e) => {
                                    onExpand(record, e)

                                    record.id &&
                                        !noEllipsisDescriptions.includes(record.id) &&
                                        setNoEllipsisDescriptions([...noEllipsisDescriptions, record.id])
                                }}
                            />
                        ),
                }}
                dataSource={testSets}
                rowKey={'id'}
                onRow={(record) => {
                    return {
                        onContextMenu: (e) =>
                            !showInModal &&
                            setPositionOnContextMenu({ e, record, setSelectedRecord, setDropdownPosition }),
                    }
                }}
                pagination={{
                    showSizeChanger: false,
                    pageSize: showAllPages,
                    defaultCurrent: 1,
                    hideOnSinglePage: true,
                }}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                size="small"
                scroll={showInModal ? { y: '46vh' } : undefined}
                loading={SpinnerStore.show}
            />
            {selectedRecord && dropdownPosition && !showInModal && (
                <TestSetOptions
                    testSet={selectedRecord}
                    testSets={testSets}
                    setTestSets={setTestSets}
                    loadData={loadData}
                    handleShowModalSelectTestCase={handleShowModalSelectTestCase}
                    dropdownPosition={dropdownPosition}
                    setSelectedRecord={setSelectedRecord}
                    isUserPage={isUserPage}
                />
            )}
        </>
    )
})
