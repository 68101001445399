import { StoreTestCase } from '@/store'

import { EAttachment } from '@/enums'
import type { UploadFile } from 'antd/es/upload/interface'

export const checkIfFileNameUnique = (
    file: File | UploadFile<any>,
    existingObjectId: string,
    attachmentType: EAttachment | undefined
) => {
    return !(
        StoreTestCase.filesToUpload.some(
            (fileToUpload) =>
                fileToUpload.name === file.name &&
                fileToUpload.existingObjectId === existingObjectId &&
                fileToUpload.attachmentType === attachmentType
        ) ||
        StoreTestCase.attachments.some(
            (attachment) =>
                `${attachment.fileName}.${attachment.extension}` === file.name &&
                attachment.existingObjectId === existingObjectId &&
                attachment.attachmentType === attachmentType
        )
    )
}
