import React from 'react'

import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { Typography } from 'antd'
import { Status } from '@/components/Status/Status'
import { UserCard } from '@/components/UserCard/UserCard'

import { pageUrls } from '@/consts'

import { ITestSet, StatusType } from '@/types'

const { Text } = Typography

interface Props {
    testSet: ITestSet
    value: string | number | StatusType
    isStatusCell?: boolean
    isUserCell?: boolean
}

export const TableCell = observer((props: Props) => {
    const { testSet, value, isStatusCell, isUserCell } = props

    return (
        <Link draggable="false" to={`${pageUrls.testSet}/${testSet?.id}`}>
            {!isStatusCell && !isUserCell && <Text ellipsis={{ tooltip: value }}>{value}</Text>}
            {isStatusCell && <Status status={value as StatusType} />}
            {isUserCell && testSet?.testerId ? (
                <UserCard
                    userName={`${testSet?.testerFirstname} ${testSet?.testerLastname}`}
                    position={testSet?.testerPosition}
                    bordered
                    size={24}
                />
            ) : (
                isUserCell && '—'
            )}
        </Link>
    )
})
