import React, { Dispatch, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import Highlighter from 'react-highlight-words'
import { useTranslation } from 'react-i18next'

import { Divider, Tag, Typography } from 'antd'
import { UserCard } from '@/components/UserCard/UserCard'
import { DropDownRoles } from '../DropDownRoles/DropDownRoles'
import { ActionButton } from '../Search/GlobalSearchResults/ActionButton/ActionButton'

import { MailOutlined } from '@ant-design/icons'

import { pageUrls } from '@/consts'

import { IGetUserIn, ISearchRoles, IUserWidthFilter, searchAreaTypes, settingsPageType } from '@/types'
import type { CheckboxOptionType } from 'antd/es/checkbox/Group'

import styles from './Card.module.scss'

const { Text } = Typography

type Props = {
    user: IGetUserIn
    users: IGetUserIn[]
    setUsers: Dispatch<SetStateAction<IGetUserIn[]>>
    roles: ISearchRoles[]
    excludeUser: (user: IGetUserIn | IUserWidthFilter) => void
    addUser: (user: IGetUserIn | IUserWidthFilter) => void
    rolesList: CheckboxOptionType[]
    searchValue: string
    canManageUsers: boolean
    canChangeRoles: boolean
    onlyAdmin: boolean
    checkIfUserLastAdmin: (user: IGetUserIn | IUserWidthFilter) => boolean
    pageType: settingsPageType
    searchArea: searchAreaTypes
    firstNameSearchValue?: string
    lastNameSearchValue?: string
}

export const Card = observer((props: Props) => {
    const {
        user,
        roles,
        excludeUser,
        addUser,
        users,
        setUsers,
        rolesList,
        searchValue,
        canManageUsers,
        canChangeRoles,
        onlyAdmin,
        checkIfUserLastAdmin,
        pageType,
        searchArea,
        firstNameSearchValue,
        lastNameSearchValue,
    } = props
    const { t } = useTranslation(['page--workspaceSettings', 'common'])

    const getInitialCheckedRoles = (user: IGetUserIn) => {
        if (pageType === pageUrls.projectSettings && searchArea === 'local') {
            return (user.userRolesInProject && user.userRolesInProject.map((role) => role.roleName)) || []
        } else {
            return (user.userRolesInWorkspace && user.userRolesInWorkspace.map((role) => role.roleName)) || []
        }
    }

    const isLastAdmin = pageType === pageUrls.workspaceSettings ? checkIfUserLastAdmin(user) : undefined

    const userFirstName = `${user.firstname}`
    const userLastName = `${user.lastname}`

    return (
        <div className={styles.Card} key={user.userId}>
            {canManageUsers && (!isLastAdmin || pageType === pageUrls.projectSettings) && (
                <ActionButton
                    key={user.userId}
                    user={user}
                    excludeUser={excludeUser}
                    addUser={addUser}
                    checkIfUserLastAdmin={checkIfUserLastAdmin}
                    pageType={pageType}
                    searchArea={searchArea}
                />
            )}

            <div className={styles.User}>
                <UserCard
                    userName={`${user.firstname} ${user.lastname}`}
                    position={user.position}
                    userLogin={user.username}
                    avatar={null}
                    searchValue={searchValue}
                    size={48}
                    userNameStyle={{ fontSize: '16px' }}
                    positionStyle={{ fontSize: '14px', color: 'var(--color-text-primary)' }}
                    userLoginStyle={{ fontSize: '14px' }}
                    firstNameSearchValue={firstNameSearchValue}
                    lastNameSearchValue={lastNameSearchValue}
                    userFirstName={userFirstName}
                    userLastName={userLastName}
                />

                <div className={styles.Contacts}>
                    <div className={styles.Contact}>
                        <MailOutlined />
                        {searchValue ? (
                            <a href={`mailto: ${user.email}`}>
                                <Highlighter
                                    highlightClassName="highlight"
                                    searchWords={[searchValue]}
                                    autoEscape
                                    textToHighlight={user.email}
                                />
                            </a>
                        ) : (
                            <a href={`mailto: ${user.email}`}>{user.email}</a>
                        )}
                    </div>
                </div>
            </div>
            <Divider className={styles.Divider} />
            <div className={styles.Roles}>
                <div className={styles.RolesHeader}>
                    <Text type="secondary" className={styles.RolesTitle}>
                        {t('page--workspaceSettings:team.roles')}
                    </Text>

                    {!!roles.length && canChangeRoles && (
                        <DropDownRoles
                            user={user}
                            setUsers={setUsers}
                            users={users}
                            roles={roles}
                            initialCheckedRoles={getInitialCheckedRoles(user)}
                            rolesList={rolesList}
                            onlyAdmin={onlyAdmin}
                            pageType={pageType}
                        />
                    )}
                </div>

                <div className={styles.Tags}>
                    {pageType === pageUrls.projectSettings && searchArea === 'local'
                        ? user?.userRolesInProject && user?.userRolesInProject?.length
                            ? user?.userRolesInProject?.map((role) => <Tag key={role.roleName}>{role.roleName}</Tag>)
                            : '—'
                        : user?.userRolesInWorkspace && user?.userRolesInWorkspace?.length
                          ? user?.userRolesInWorkspace?.map((role) => <Tag key={role.roleName}>{role.roleName}</Tag>)
                          : '—'}
                </div>
            </div>
        </div>
    )
})
