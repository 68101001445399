import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Tooltip, Typography } from 'antd'

import { QuestionCircleOutlined } from '@ant-design/icons'

import { pageUrls } from '@/consts'

import { settingsPageType } from '@/types'

import styles from './TeamTitle.module.scss'

const { Title } = Typography

type Props = {
    parentId: string
    initialNumberOfusers: number | undefined
    pageType: settingsPageType
    canManageUsers: boolean
}

export const TeamTitle = observer((props: Props) => {
    const { parentId, initialNumberOfusers, pageType, canManageUsers } = props
    const { t } = useTranslation(['page--workspaceSettings', 'page--projectSettings'])
    const [showAddUserToolTip, setShowAddUserToolTip] = useState(false)

    const showAddUserTooltipNotification = () => {
        setShowAddUserToolTip(true)

        const timer = setTimeout(() => {
            setShowAddUserToolTip(false)
        }, 3000)
        return () => clearTimeout(timer)
    }

    useEffect(() => {
        if ((parentId && !initialNumberOfusers) || (initialNumberOfusers && initialNumberOfusers <= 1)) {
            showAddUserTooltipNotification()
        }
    }, [parentId, initialNumberOfusers])

    return (
        <div className={styles.TeamTitle}>
            <Title level={4}>
                {pageType === pageUrls.workspaceSettings
                    ? t('page--workspaceSettings:team.title')
                    : t('page--projectSettings:team.title')}
            </Title>
            {canManageUsers && (
                <Tooltip
                    open={showAddUserToolTip}
                    onOpenChange={(visible) => {
                        setShowAddUserToolTip(visible)
                    }}
                    title={
                        pageType === pageUrls.workspaceSettings
                            ? t('page--workspaceSettings:team.addUserToolTip')
                            : t('page--projectSettings:team.addUserToolTip')
                    }
                    placement="right"
                >
                    <QuestionCircleOutlined className={styles.TooltipIcon} />
                </Tooltip>
            )}
        </div>
    )
})
