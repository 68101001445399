import React, { Dispatch, SetStateAction, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Button, Form, Input, Select } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'

import { CloseOutlined, SearchOutlined } from '@ant-design/icons'

import { StoreAuthController } from '@/store'

import { IGetUserIn, ISearchUsers, IUserWidthFilter, searchAreaTypes, TableFilterType } from '@/types'
import type { CheckboxOptionType } from 'antd/es/checkbox/Group'

import styles from './UserFilters.module.scss'

type Props = {
    rolesList: CheckboxOptionType[]
    users: IGetUserIn[]
    setSearchResults: Dispatch<SetStateAction<IGetUserIn[] | IUserWidthFilter[]>>
    searchArea: searchAreaTypes
    setFirstNameSearchValue: Dispatch<SetStateAction<string>>
    setLastNameSearchValue: Dispatch<SetStateAction<string>>
}

export const UserFilters = observer((props: Props) => {
    const { t } = useTranslation(['page--workspaceSettings'])
    const { rolesList, users, setSearchResults, searchArea, setFirstNameSearchValue, setLastNameSearchValue } = props
    const [form] = Form.useForm()
    const [filter, setFilter] = useState<TableFilterType>()

    const globalSearchUsers = async (filter: ISearchUsers): Promise<IUserWidthFilter[]> => {
        return (await StoreAuthController.searchFilteredUsers(filter)).result
    }

    const isInCurrentTeam = (userId: string) => {
        return users.some((wsUser) => wsUser.userId === userId)
    }

    const onSearch = async () => {
        const { firstname, middlename, lastname, position, roles } = form.getFieldsValue()
        setFirstNameSearchValue(firstname)
        setLastNameSearchValue(lastname)
        if (searchArea === 'local') {
            const foundedUsers: IGetUserIn[] = users.filter(
                (user) =>
                    (!firstname || user.firstname?.toLowerCase().includes(firstname.toLowerCase())) &&
                    (!middlename || user.middlename?.toLowerCase().includes(middlename.toLowerCase())) &&
                    (!lastname || user.lastname?.toLowerCase().includes(lastname.toLowerCase())) &&
                    (!position || user.position?.toLowerCase().includes(position.toLowerCase())) &&
                    (!roles?.length ||
                        (user?.userRolesInWorkspace &&
                            user?.userRolesInWorkspace.some((role) => roles?.includes(role?.roleName))))
            )

            setSearchResults(foundedUsers)
        }

        if (searchArea === 'global') {
            if (firstname || middlename || lastname || position) {
                const props = {
                    firstname: [firstname],
                    middlename: [middlename],
                    lastname: [lastname],
                    position: [position],
                    username: [],
                    cn: [],
                    email: [],
                    phone: [],
                }

                const foundedUsers = await globalSearchUsers(props)
                foundedUsers.map((user) => (user.isInCurrentTeam = isInCurrentTeam(user.id)))
                setSearchResults(foundedUsers)
            } else {
                setSearchResults([])
            }
        }
    }

    const onClearFilters = () => {
        setFilter([])
        form.resetFields()
    }

    const onRolesChange = (value: string[]) => {
        const updatedFilter = { ...filter, roles: [...value] }
        setFilter(updatedFilter)
    }

    return (
        <div className={styles.Filters}>
            <Form form={form} layout="horizontal" className={styles.Form} key="advancedSearch">
                <FormItem name="lastname" label={t('page--workspaceSettings:team.lastname')}>
                    <Input maxLength={100} className={styles.SearchInput} />
                </FormItem>

                <FormItem name="firstname" label={t('page--workspaceSettings:team.firstname')}>
                    <Input maxLength={100} className={styles.SearchInput} />
                </FormItem>

                <FormItem name="middlename" label={t('page--workspaceSettings:team.middlename')}>
                    <Input maxLength={100} className={styles.SearchInput} />
                </FormItem>

                <FormItem name="position" label={t('page--workspaceSettings:team.position')}>
                    <Input maxLength={255} className={styles.SearchInput} />
                </FormItem>

                {searchArea !== 'global' && (
                    <FormItem name="roles" label={t('page--workspaceSettings:team.roles')}>
                        <Select
                            mode="multiple"
                            options={rolesList}
                            onChange={onRolesChange}
                            className={styles.SearchInput}
                        />
                    </FormItem>
                )}
            </Form>

            <div className={styles.FilterButtons}>
                <Button
                    type="primary"
                    size="large"
                    icon={<SearchOutlined />}
                    onClick={onSearch}
                    className={styles.FilterButton}
                />

                <Button
                    type="default"
                    size="large"
                    icon={<CloseOutlined />}
                    onClick={onClearFilters}
                    className={styles.FilterButton}
                />
            </div>
        </div>
    )
})
