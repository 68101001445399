import React, { Dispatch, Key, MouseEvent, SetStateAction, useEffect, useMemo, useState } from 'react'

import cn from 'classnames'
import { orderBy } from 'lodash'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Button, Layout, Modal, Tabs, Tooltip, Typography } from 'antd'
import { Details } from './components/Details/Details'
import { TestCasesTable } from './components/TestCasesTable/TestCasesTable'
import { TestSetsSideBar } from './components/TestSetsSideBar/TestSetsSideBar'
import { ModalAddToTestSet } from '@/components/ModalAddToTestSet/ModalAddToTestSet'

import { ClearOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreCreateTestSet, StoreTestSet } from '@/store'
import { maxDescriptionLength } from '@/consts'

import { IExecutionCase, ISort, ITestSet, IUpdateTestSet, TimeCheckerFunction, totalTimeType } from '@/types'
import { EExecutionCaseStatus, EOrder } from '@/enums'

import styles from './TestSet.module.scss'

const { Content, Sider } = Layout
const { Title, Text } = Typography
const { TabPane } = Tabs

type Props = {
    testSetInfo: ITestSet | undefined
    setTestSetInfo: Dispatch<SetStateAction<ITestSet | undefined>>
    testCases: IExecutionCase[]
    setTestCases: Dispatch<SetStateAction<IExecutionCase[]>>
    totalTime: totalTimeType
    setTotalTime: Dispatch<SetStateAction<totalTimeType>>
    testSets?: ITestSet[]
}

export const TestSet = observer((props: Props) => {
    const { testSetInfo, setTestSetInfo, testCases, setTestCases, totalTime, setTotalTime } = props
    const { t } = useTranslation(['page--testSets', 'common'])
    const { testSetId } = useParams()

    const [showModalAddToTestSet, setShowModalAddToTestSet] = useState(false)
    const [showTableMenu, setShowTableMenu] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([])
    const [showMaxDescriptionLengthReached, setMaxDescriptionLengthReached] = useState(false)
    const [isTabClicked, setTabClicked] = useState(false)

    const initialSortTestCase = [
        {
            field: 'orderNum',
            direction: EOrder.DESC,
        },
    ]

    const [sort, setSort] = useState<ISort[]>(initialSortTestCase)

    const isFilterChanged = useMemo(() => {
        return JSON.stringify(sort) !== JSON.stringify(initialSortTestCase)
    }, [sort])

    const timeChecker = (totalTiming: totalTimeType, secondsToSubtract: number) => {
        if (totalTiming && totalTiming.hours !== undefined && totalTiming.minutes !== undefined) {
            const totalSeconds = totalTiming.hours * 3600 + totalTiming.minutes * 60

            const newTotalSeconds = totalSeconds - secondsToSubtract

            if (newTotalSeconds >= 0) {
                const newHours = Math.floor(newTotalSeconds / 3600)
                const newMinutes = Math.floor((newTotalSeconds % 3600) / 60)

                return { hours: newHours, minutes: newMinutes }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const onChangeValue = async (value: string, fieldName: string) => {
        if (
            (value || fieldName !== 'name') &&
            testSetInfo?.id &&
            value !== testSetInfo[fieldName as keyof ITestSet] &&
            value !== '—'
        ) {
            if (fieldName === 'description') {
                const showLimitErrorMessage = value.length > maxDescriptionLength
                setMaxDescriptionLengthReached(showLimitErrorMessage)

                if (showLimitErrorMessage) {
                    setTestSetInfo(testSetInfo)
                    return
                }
            }

            const updatedInfo = { ...testSetInfo, [fieldName]: value } as ITestSet
            setTestSetInfo(updatedInfo)

            if (fieldName === 'name') {
                StoreTestSet.setName(value)
            }

            const props = {
                id: updatedInfo.id,
                name: updatedInfo.name,
                description: updatedInfo.description,
                beginningPeriod: updatedInfo.beginningPeriod,
                endingPeriod: updatedInfo.endingPeriod,
                testerId: updatedInfo.testerId,
                testSoftware: updatedInfo.testSoftware,
                versionSoftware: updatedInfo.versionSoftware,
                isDeleted: updatedInfo.isDeleted,
            } as IUpdateTestSet

            await StoreTestSet.updateTestSet(props)
        }
    }

    useEffect(() => {
        if (StoreCreateTestSet.isCreatingTestSet && showModalAddToTestSet === false) {
            setShowModalAddToTestSet(true)
        }
    }, [StoreCreateTestSet.isCreatingTestSet])

    useEffect(() => {
        const sortDirections: (boolean | 'desc' | 'asc')[] | undefined = []
        const sortFields: string[] = []
        sort.forEach((sorter) => {
            sortDirections.push(sorter.direction.toLocaleLowerCase() as 'desc' | 'asc')
            sortFields.push(sorter.field)
        })

        const sortedTestCases = orderBy(testCases, sortFields, sortDirections)
        setTestCases(sortedTestCases)
    }, [sort])

    const deleteItems = async (e: MouseEvent) => {
        if (testSetId) {
            Modal.confirm({
                title: t('page--testSets:deleteTestCasesWarning', {
                    status: t(`statuses:${EExecutionCaseStatus.NOT_PASSED}`),
                }),
                centered: true,
                cancelText: t('common:buttons.cancel'),
                onOk: async () => {
                    let currentTime = 0
                    const testCasesToDelete = selectedRowKeys
                        .map((id) => {
                            const testCaseToDelete = testCases.find(
                                (testCase) =>
                                    testCase.id === id &&
                                    testCase.executionCaseStatus === EExecutionCaseStatus.NOT_PASSED
                            )
                            if (testCaseToDelete && testCaseToDelete.normativeTime !== undefined) {
                                currentTime += testCaseToDelete.normativeTime
                            }
                            return testCaseToDelete ? id : null
                        })
                        .filter((id) => id)

                    setTotalTime((prevTotalTime: totalTimeType) => {
                        const result = timeChecker(prevTotalTime, currentTime)
                        return result ?? prevTotalTime
                    })

                    const updatedNotPassedCounter = testCases.filter(
                        (testCase) =>
                            testCase.executionCaseStatus === EExecutionCaseStatus.NOT_PASSED &&
                            !testCasesToDelete.includes(testCase.id as Key)
                    ).length

                    if (testCasesToDelete.length) {
                        await StoreTestSet.deleteExecutionCases(testSetId, testCasesToDelete as string[])

                        const updatedItems = testCases.filter(
                            (testCase: IExecutionCase) => !testCasesToDelete.includes(testCase.id as Key)
                        )

                        setTestCases(updatedItems)
                    }

                    if (setTestSetInfo) {
                        await setTestSetInfo((prevTestSetInfo) => {
                            if (prevTestSetInfo) {
                                return {
                                    ...prevTestSetInfo,
                                    progress: {
                                        ...prevTestSetInfo.progress,
                                        [EExecutionCaseStatus.NOT_PASSED]: updatedNotPassedCounter,
                                    },
                                }
                            }
                            return prevTestSetInfo
                        })
                    }
                    setSelectedRowKeys([])

                    setShowTableMenu(false)
                },
            })
        }
    }
    const handleShowModalSelectTestCase = () => {
        StoreCreateTestSet.setTestSetIdInModal(testSetId || '')
        setShowModalAddToTestSet(true)
    }

    const onClearFilters = () => {
        setSort(initialSortTestCase)
    }
    const handleTabClick = () => {
        setTabClicked(true)
        setTimeout(() => {
            setTabClicked(false)
        }, 200)
    }

    return (
        <div className={styles.TestSet}>
            <Layout>
                <div className={styles.Main}>
                    <Sider className={styles.LeftSideBar} theme="light" width={204}>
                        {testSetInfo?.id && <TestSetsSideBar testSetInfo={testSetInfo} />}
                    </Sider>
                    <Content className={cn(styles.Content, 'content')}>
                        <div className={styles.TitleWithMenu}>
                            <Title
                                level={4}
                                className={styles.TestSetTitle}
                                editable={
                                    SpinnerStore.show
                                        ? false
                                        : {
                                              onChange: (value) => onChangeValue(value, 'name'),
                                              triggerType: ['text', 'icon'],
                                          }
                                }
                            >
                                {testSetInfo?.name}
                            </Title>
                        </div>

                        <Tabs
                            defaultActiveKey="testCases"
                            type="card"
                            animated={false}
                            onTabClick={handleTabClick}
                            onChange={handleTabClick}
                        >
                            <TabPane
                                tab={
                                    <div className={styles.TestCasesTitle}>
                                        {t('page--testSets:testCases')}{' '}
                                        <Text className={styles.TestCasesTitle} type="secondary">
                                            ({testCases.length})
                                        </Text>
                                    </div>
                                }
                                key="testCases"
                            >
                                <div className={styles.TableMenu}>
                                    {showTableMenu && (
                                        <Button
                                            type="default"
                                            icon={<DeleteOutlined />}
                                            danger
                                            onClick={deleteItems}
                                            disabled={SpinnerStore.show}
                                        >
                                            {t('common:buttons.deleteItemWithCounter', {
                                                name:
                                                    selectedRowKeys.length > 1
                                                        ? t('page--testSets:testCases')
                                                        : t('page--testSets:testCase'),
                                                counter: selectedRowKeys.length,
                                            })}
                                        </Button>
                                    )}

                                    {isFilterChanged && (
                                        <Tooltip title={t('common:buttons.clearFilters')}>
                                            <Button
                                                type="default"
                                                icon={<ClearOutlined />}
                                                onClick={onClearFilters}
                                                className={styles.FilterButton}
                                            />
                                        </Tooltip>
                                    )}

                                    <Tooltip
                                        title={t('page--testSets:addTestCases')}
                                        mouseLeaveDelay={0}
                                        open={showModalAddToTestSet ? false : undefined}
                                    >
                                        <Button
                                            ghost
                                            className={'borderless'}
                                            icon={<PlusOutlined />}
                                            onClick={handleShowModalSelectTestCase}
                                            disabled={SpinnerStore.show}
                                        />
                                    </Tooltip>
                                </div>

                                <TestCasesTable
                                    testCases={testCases}
                                    setTestCases={setTestCases}
                                    sort={sort}
                                    setSort={setSort}
                                    setShowTableMenu={setShowTableMenu}
                                    selectedRowKeys={selectedRowKeys}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    setTestSetInfo={setTestSetInfo}
                                    timeChecker={timeChecker as TimeCheckerFunction}
                                    setTotalTime={setTotalTime}
                                    isTabClicked={isTabClicked}
                                />
                            </TabPane>
                            <TabPane tab={t('page--testSets:details')} key="details">
                                {testSetInfo && setTestSetInfo && (
                                    <Details
                                        totalTime={totalTime}
                                        testSetInfo={testSetInfo}
                                        setTestSetInfo={setTestSetInfo}
                                        onChangeValue={onChangeValue}
                                        showMaxDescriptionLenghtReached={showMaxDescriptionLengthReached}
                                    />
                                )}
                            </TabPane>
                        </Tabs>
                    </Content>
                </div>
            </Layout>
            {testSetInfo && setTestSetInfo && setTestCases && showModalAddToTestSet && (
                <ModalAddToTestSet
                    testSetInfo={testSetInfo}
                    setTestSetInfo={setTestSetInfo}
                    setTestCases={setTestCases}
                    showModalAddToTestSet={showModalAddToTestSet}
                    setShowModalAddToTestSet={setShowModalAddToTestSet}
                />
            )}
        </div>
    )
})
