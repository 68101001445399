// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KNJKfbAdVOg03nZMQcLl{position:absolute;background-color:#fff;top:0px;left:0px;height:100%;width:100%;overflow:hidden}.hPv9QG2yU6XdyFvarsZe{display:flex;height:100%;justify-content:center;align-items:center}.hPv9QG2yU6XdyFvarsZe .xtopy3919f1UYu0jYIeo{font-size:48px;color:var(--color-primary-light)}.hPv9QG2yU6XdyFvarsZe .xtopy3919f1UYu0jYIeo svg{font-size:48px}.dP6ie3crSwMpSHB9KTLU{position:relative}.EopEpHn07ONrOLugVeJz{position:fixed;background:var(--color-text-secondary45);background:rgba(0,0,0,.1);z-index:1000}", "",{"version":3,"sources":["webpack://./src/components/Spinner/Spinner.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,qBAAA,CACA,OAAA,CACA,QAAA,CACA,WAAA,CACA,UAAA,CACA,eAAA,CAGJ,sBACI,YAAA,CACA,WAAA,CACA,sBAAA,CACA,kBAAA,CAEA,4CACI,cAAA,CACA,gCAAA,CAEA,gDACI,cAAA,CAKZ,sBACI,iBAAA,CAGJ,sBACI,cAAA,CACA,wCAAA,CACA,yBAAA,CAEA,YAAA","sourcesContent":[".Container {\n    position: absolute;\n    background-color: white;\n    top: 0px;\n    left: 0px;\n    height: 100%;\n    width: 100%;\n    overflow: hidden;\n}\n\n.SpinnerContainer {\n    display: flex;\n    height: 100%;\n    justify-content: center;\n    align-items: center;\n\n    .Spinner {\n        font-size: 48px;\n        color: var(--color-primary-light);\n\n        svg {\n            font-size: 48px;\n        }\n    }\n}\n\n.Relative {\n    position: relative;\n}\n\n.Fixed {\n    position: fixed;\n    background: var(--color-text-secondary45);\n    background: rgba(0, 0, 0, 0.1);\n\n    z-index: 1000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": "KNJKfbAdVOg03nZMQcLl",
	"SpinnerContainer": "hPv9QG2yU6XdyFvarsZe",
	"Spinner": "xtopy3919f1UYu0jYIeo",
	"Relative": "dP6ie3crSwMpSHB9KTLU",
	"Fixed": "EopEpHn07ONrOLugVeJz"
};
export default ___CSS_LOADER_EXPORT___;
