import React, { Dispatch, SetStateAction } from 'react'

import { observer } from 'mobx-react'
import { Resizable } from 're-resizable'

import { FoldersTab } from './FoldersTab'

import { pageUrls } from '@/consts'

import { ITestFolder } from '@/types'
import { EModalTypeFolder } from '@/enums'

type Props = {
    testFolders: ITestFolder[]
    setTestFolders: Dispatch<SetStateAction<ITestFolder[]>>
    selectedFolderId: string
    setSelectedFolderId: Dispatch<SetStateAction<string>>
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
    setSearchedName?: Dispatch<SetStateAction<string>>
    setSelectedForActionFolder?: Dispatch<SetStateAction<ITestFolder | undefined>>
    setIsModalCreateFolderOpen?: Dispatch<SetStateAction<boolean>>
    setIsModalRenameOpen?: Dispatch<SetStateAction<boolean>>
    setIsModalSelectOpen?: Dispatch<SetStateAction<boolean>>
    setModalTypeFolder?: Dispatch<SetStateAction<EModalTypeFolder | undefined>>
    onCreateFolder?: () => void
    selectFolderOnly?: boolean
    withoutSearchParams?: boolean
    showInModal?: boolean
}

export const ResizableFoldersTree = observer((props: Props) => {
    const {
        testFolders,
        setTestFolders,
        selectedFolderId,
        setSelectedFolderId,
        setIsModalCreateFolderOpen,
        onCreateFolder,
        setSelectedForActionFolder,
        setIsModalRenameOpen,
        setIsModalSelectOpen,
        setModalTypeFolder,
        selectFolderOnly,
        pageType,
        setSearchedName,
        withoutSearchParams,
        showInModal,
    } = props

    return (
        <Resizable
            defaultSize={{
                width: '300',
                height: 'auto',
            }}
            style={{ borderRight: '1px solid var(--color-other-divider6)' }}
            minWidth={300}
            maxWidth={'50%'}
            enable={{
                top: false,
                right: true,
                bottom: false,
                left: false,
                topRight: false,
                bottomRight: false,
                bottomLeft: false,
                topLeft: false,
            }}
        >
            <FoldersTab
                testFolders={testFolders}
                setTestFolders={setTestFolders}
                selectedFolderId={selectedFolderId}
                setSelectedFolderId={setSelectedFolderId}
                setIsModalCreateFolderOpen={setIsModalCreateFolderOpen}
                onCreateFolder={onCreateFolder}
                setSelectedForActionFolder={setSelectedForActionFolder}
                setIsModalRenameOpen={setIsModalRenameOpen}
                setIsModalSelectOpen={setIsModalSelectOpen}
                setModalTypeFolder={setModalTypeFolder}
                selectFolderOnly={selectFolderOnly}
                pageType={pageType}
                setSearchedName={setSearchedName}
                withoutSearchParams={withoutSearchParams}
                showInModal={showInModal}
            />
        </Resizable>
    )
})
