import React, { FunctionComponent } from 'react'

import cn from 'classnames'

import { LoadingOutlined } from '@ant-design/icons'

import { SpinnerStore } from '@/store'

import style from './Spinner.module.scss'

interface ISpinner {
    show?: boolean
    className?: string
    relative?: boolean
    fixed?: boolean
}

const Spinner: FunctionComponent<ISpinner> = (props) => {
    const { show = SpinnerStore.show, className, relative = false, fixed } = props
    const containerClassName = cn(className, style.Container, { [style.Relative]: relative, [style.Fixed]: fixed })

    return (
        <>
            {show && (
                <div className={containerClassName}>
                    <div className={style.SpinnerContainer}>
                        <LoadingOutlined className={style.Spinner} />
                    </div>
                </div>
            )}
        </>
    )
}

export default Spinner
