import React, { useMemo, useState } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'

import { MsAttachmentApi } from '@/api'

import { getAttachmentIconComponent, getAttachmentType, getFullAttachmentName } from '@/utils'

import { Typography } from 'antd'
import { DeleteAttachmentButton } from './DeleteAttachmentButton'
import { FileVIew } from './FileVIew'

import { StoreTestCase } from '@/store'

import { IMixedAttachments } from '@/types'

import styles from './Attachment.module.scss'

const { Text } = Typography

type Props = {
    file: IMixedAttachments
    onDelete: (file: IMixedAttachments, fileName: string) => void
    isUploaded: boolean
    onAttachmentClick?: (file: IMixedAttachments, fileName: string) => void
    hideDeleteCross?: boolean
}

export const Attachment = observer((props: Props) => {
    const { file, onDelete, onAttachmentClick, isUploaded = false, hideDeleteCross } = props
    const [fileSrc, setFileSrc] = useState('')
    const [showFile, setShowFile] = useState(false)

    const fileName = useMemo(() => getFullAttachmentName(file), [file])
    const AttachmentIcon: any = useMemo(() => getAttachmentIconComponent(fileName), [fileName])
    const fileType = useMemo(() => getAttachmentType(file), [file])

    const isImageAttachment = fileType === 'image'
    const isVideoAttachment = fileType === 'video'
    const isImageOrVideoAttachment = !!fileType

    const handleDelete = () => {
        onDelete(file, fileName)
    }

    const handleDownloadAttachment = async () => {
        onAttachmentClick && onAttachmentClick(file, fileName)
    }

    const handleAttachmentClick = async () => {
        //TODO: сейчас скачать / посмотреть можно только загруженные аттачменты, незагруженные надо доработать
        if ('id' in file && file.id) {
            if (isImageOrVideoAttachment) {
                setShowFile(true)
                const imageLink = URL.createObjectURL(await MsAttachmentApi.getBlobAttachment(file.id))
                setFileSrc(imageLink)
            } else {
                handleDownloadAttachment()
            }
        }
    }

    const isDisabled =
        (StoreTestCase.readOnly && !StoreTestCase.executionTestCase) ||
        (StoreTestCase.executionTestCase && !StoreTestCase.executionCaseStarted)

    const hideDeleteCrossButton = isDisabled || !!hideDeleteCross

    return (
        <>
            <div className={cn(styles.Attachment, { [styles.Uploaded]: isUploaded })} onClick={handleAttachmentClick}>
                <div className={styles.Icon}>
                    <AttachmentIcon />
                </div>

                <Text ellipsis={{ tooltip: fileName }} className={styles.Name}>
                    {fileName}
                </Text>
                {!hideDeleteCrossButton && <DeleteAttachmentButton file={file} handleDelete={handleDelete} />}
            </div>

            {(isImageAttachment || isVideoAttachment) && (
                <FileVIew
                    fileName={fileName}
                    fileSrc={fileSrc}
                    showFile={showFile}
                    setShowFile={setShowFile}
                    isDisabled={isDisabled}
                    handleDelete={handleDelete}
                    handleDownloadAttachment={handleDownloadAttachment}
                    canDownload={!!onAttachmentClick}
                    isImageAttachment={isImageAttachment}
                />
            )}
        </>
    )
})
