import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { hasAccess } from '@/utils'

import { Button, Form, Input, Typography } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'
import { ModalSave } from '@/components/ModalSave/ModalSave'

import { SaveFilled, UndoOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreProject } from '@/store'
import { pageUrls, settingTabs } from '@/consts'

import { IProject } from '@/types'
import { EPermissions } from '@/enums'

import styles from './MainSettings.module.scss'

const { Title } = Typography
const { TextArea } = Input

type Props = {
    selectedTab: string
    currentTab: string
    isLeavingTab: boolean
    setLeavingTab: Dispatch<SetStateAction<boolean>>
    projectInfo: IProject
    getProject: () => void
    leaveTab: () => void
    changesSaved: boolean
    setChangesSaved: Dispatch<SetStateAction<boolean>>
    goGoBack: () => void
    isGoingBack: boolean
    setGoingBack: Dispatch<SetStateAction<boolean>>
}

export const MainSettings = observer((props: Props) => {
    const {
        isLeavingTab,
        leaveTab,
        currentTab,
        setLeavingTab,
        selectedTab,
        projectInfo,
        getProject,
        changesSaved,
        setChangesSaved,
        goGoBack,
        isGoingBack,
        setGoingBack,
    } = props

    const { t } = useTranslation(['page--projectSettings', 'common', 'notification'])
    const [form] = Form.useForm()
    const [isSaved, setSaved] = useState(true)
    const [isModalSaveOpen, setModalSaveOpen] = useState(false)

    const reg = /[А-Яа-яЁё\w]/
    const returnInitialProjectName = () => {
        const { projectName } = form.getFieldsValue()
        !reg.test(projectName) && form.setFieldValue('projectName', projectInfo?.projectName)
    }

    const canUpdate: boolean = useMemo(
        () =>
            hasAccess({
                projectPermissions: [EPermissions.CREATE_PROJECT],
                workspacePermissions: [EPermissions.MANAGE_ALL_WORKSPACE_PROJECTS],
                projectId: projectInfo.id,
                workspaceId: projectInfo.workspaceId,
            }),
        [projectInfo.id]
    )

    useEffect(() => {
        if (isLeavingTab && currentTab === settingTabs.mainSettings) {
            if (isSaved) {
                returnInitialProjectName()
                leaveTab()
            } else {
                setModalSaveOpen(true)
            }
        }
    }, [isLeavingTab])

    useEffect(() => {
        if (isGoingBack) {
            if (changesSaved) {
                goGoBack()
            } else {
                setModalSaveOpen(true)
            }
        }
    }, [isGoingBack])

    const onSave = async () => {
        SpinnerStore.setShow(true)

        const { projectName, projectDescription, customer } = form.getFieldsValue()

        const updatedProjectData = {
            id: projectInfo.id,
            orderNum: projectInfo?.orderNum,
            projectName: reg.test(projectName) ? projectName : projectInfo?.projectName,
            projectDescription: projectDescription,
            customer: customer,
        }

        await StoreProject.updateProject(updatedProjectData)
        getProject()
        setSaved(true)
        setChangesSaved(true)
        setModalSaveOpen(false)
        SpinnerStore.setShow(false)
    }

    const onUndo = () => {
        form.resetFields()
        setModalSaveOpen(false)
        setSaved(true)
        setChangesSaved(true)
    }

    const onFormChange = () => {
        if (projectInfo) {
            const { projectName, projectDescription, customer } = form.getFieldsValue()
            if (
                (projectInfo.projectName !== projectName && reg.test(projectName)) ||
                projectInfo?.projectDescription !== projectDescription ||
                projectInfo?.customer !== customer
            ) {
                setSaved(false)
                setChangesSaved(false)
            } else {
                setSaved(true)
                setChangesSaved(true)
            }
        }
    }

    const handleDontSave = () => {
        onUndo()
        isLeavingTab ? leaveTab() : goGoBack()
    }

    const handleSave = async () => {
        await onSave()
        isLeavingTab ? leaveTab() : goGoBack()
    }

    const handleCancel = () => {
        setModalSaveOpen(false)
        setLeavingTab(false)
        setGoingBack(false)
    }

    return (
        <div className={styles.MainSettings}>
            <div className={styles.TitleRow}>
                <Title level={4} className={styles.SettingTitle}>
                    {t('page--projectSettings:mainSettings.title')}
                </Title>
                {!isSaved && (
                    <div className={styles.ActionMenu}>
                        <Button type="primary" icon={<SaveFilled />} onClick={onSave} disabled={SpinnerStore.show}>
                            {t('common:buttons.save')}
                        </Button>
                        <Button type="default" icon={<UndoOutlined />} onClick={onUndo} disabled={SpinnerStore.show}>
                            {t('common:buttons.undo')}
                        </Button>
                    </div>
                )}
            </div>
            <div className={styles.ProjectInfo}>
                {projectInfo && (
                    <Form
                        form={form}
                        layout="vertical"
                        className={styles.Form}
                        key="projectSettings"
                        initialValues={{
                            projectName: projectInfo.projectName,
                            projectDescription: projectInfo?.projectDescription,
                            customer: projectInfo?.customer,
                        }}
                        onChange={onFormChange}
                        disabled={!canUpdate}
                    >
                        <FormItem name="projectName" label={t('page--projectSettings:mainSettings.name')}>
                            <Input showCount={canUpdate} maxLength={100} />
                        </FormItem>
                        <FormItem name="projectDescription" label={t('page--projectSettings:mainSettings.description')}>
                            <TextArea showCount={canUpdate} maxLength={250} autoSize className={styles.TextArea} />
                        </FormItem>
                        <FormItem name="customer" label={t('page--projectSettings:mainSettings.customer')}>
                            <Input showCount={canUpdate} maxLength={100} />
                        </FormItem>
                    </Form>
                )}
            </div>
            {isModalSaveOpen && (
                <ModalSave
                    isModalSaveOpen={
                        (selectedTab !== settingTabs.mainSettings && !isSaved && isModalSaveOpen) || !changesSaved
                    }
                    handleDontSave={handleDontSave}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    pageType={pageUrls.projectSettings}
                />
            )}
        </div>
    )
})
