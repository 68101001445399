import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Modal, notification } from 'antd'

import { ExclamationCircleFilled } from '@ant-design/icons'

import { StoreProject, StoreTestCase } from '@/store'
import { initialAFKTimeOutMs, initialAFKTimerSecs } from '@/consts'

import { ITestCase } from '@/types'

type Props = {
    mergeSteps: () => void
    setTestCaseInfo?: Dispatch<SetStateAction<ITestCase | undefined>>
}

export const AFKModal = observer((props: Props) => {
    const { mergeSteps, setTestCaseInfo } = props
    const { projectId } = useParams()
    const { t } = useTranslation(['testCase'])
    const [modal, contextHolder] = Modal.useModal()
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 })
    const [mousePrevPos, setMousePrevPos] = useState({ x: 0, y: 0 })
    const [isModalAFKOpened, setModalAFKOpened] = useState(false)

    useEffect(() => {
        if (projectId && !StoreProject.id) {
            StoreProject.setProjectId(projectId)
        }
    }, [projectId])

    const handleMouseMove = (event: any) => {
        setMousePos({ x: event.clientX, y: event.clientY })
    }

    useEffect(() => {
        window.addEventListener('click', handleMouseMove)

        return () => {
            window.removeEventListener('mousemove', handleMouseMove)
        }
    }, [])

    const stopHeartBeats = () => {
        clearInterval(StoreTestCase.blockHeartbeatTimerId)
        StoreTestCase.setBlockHeartbeatTimerId({} as ReturnType<typeof setInterval>)
    }
    const handleResetTestCase = async () => {
        const testCase = await StoreTestCase.getTestCase(StoreTestCase.id)
        const attachments = [...StoreTestCase.attachments]
        await StoreTestCase.toggleBlocked(false)
        StoreTestCase.resetTestCaseData()
        StoreTestCase.setAttachments(attachments)
        setTestCaseInfo && setTestCaseInfo(testCase)
        notification.warning({
            message: t('testCase:AFKModal.unlocked'),
            placement: 'bottomRight',
            duration: 0,
        })
    }

    const showAFKModal = () => {
        setModalAFKOpened(true)
        let afkTimerSecs = initialAFKTimerSecs
        const instance = modal.confirm({
            title: t('testCase:AFKModal.title'),
            icon: <ExclamationCircleFilled />,
            content: t('testCase:AFKModal.description', { timer: afkTimerSecs }),
            centered: true,
            okText: t('testCase:AFKModal.continue'),
            cancelText: t('testCase:AFKModal.save'),
            width: 600,
            onOk: async () => {
                clearInterval(timer)
                await StoreTestCase.startHeartBeating(StoreTestCase.id)
                instance.destroy()
                setModalAFKOpened(false)
            },
            onCancel: async () => {
                if (!StoreTestCase.isSaved) {
                    mergeSteps()
                    await StoreTestCase.updateTestCase()
                    await StoreTestCase.toggleBlocked(false)
                    clearInterval(timer)
                    instance.destroy()
                    setModalAFKOpened(false)
                }
            },
        })

        const timer = setInterval(() => {
            afkTimerSecs -= 1
            if (afkTimerSecs !== 0) {
                instance.update({
                    content: t('testCase:AFKModal.description', { timer: afkTimerSecs }),
                })
            } else {
                clearInterval(timer)
                instance.destroy()
                handleResetTestCase()
                setModalAFKOpened(false)
            }
        }, 1000)
    }

    const handleStopAFKTimeOut = () => {
        const isCursorMoved = mousePos.x !== mousePrevPos.x || mousePos.y !== mousePrevPos.y
        if (isCursorMoved) {
            setMousePrevPos(mousePos)
        } else {
            stopHeartBeats()
            showAFKModal()
        }
    }

    useEffect(() => {
        if (!StoreTestCase.isSaved && !isModalAFKOpened) {
            const timer = setTimeout(() => {
                handleStopAFKTimeOut()
            }, initialAFKTimeOutMs)

            return () => clearTimeout(timer)
        }
    }, [mousePos, mousePrevPos, StoreTestCase.isSaved])

    return <>{contextHolder}</>
})
