export enum EExecutionTestSetStatus {
    PLANNING = 'PLANNING',
    ASSIGNED = 'ASSIGNED',
    IN_PROGRESS = 'IN_PROGRESS',
    POSTPONED = 'POSTPONED',
    CLOSED = 'CLOSED',
    COMPLETED = 'COMPLETED',
}

export enum EExecutionTestSetStatusWithProcess {
    EExecutionTestSetStatus,
    IN_PROCESS = 'IN_PROCESS',
}

export enum EExecutionCaseStatus {
    BLOCKED = 'BLOCKED',
    FAILED = 'FAILED',
    SKIPPED = 'SKIPPED',
    PASSED = 'PASSED',
    NOT_PASSED = 'NOT_PASSED',
    PAUSED = 'PAUSED',
    IN_PROGRESS = 'IN_PROGRESS',
    IN_PROCESS = 'IN_PROCESS',
}

export enum EExecutionStepStatus {
    BLOCKED = 'BLOCKED',
    FAILED = 'FAILED',
    SKIPPED = 'SKIPPED',
    PASSED = 'PASSED',
}

export enum EJiraStatus {
    OPEN = 'Open',
    IN_PROGRESS = 'In progress',
    POSTPONED = 'Postponed',
    IN_TESTING = 'In testing',
    CLOSED = 'Closed',
    REJECTED = 'Rejected',
    RESOLVED = 'Resolved',
    REOPENED = 'Reopened',
    RUS_OPEN = 'Открытый',
    RUS_IN_PROGRESS = 'В работе',
    RUS_POSTPONED = 'Отложенный',
    RUS_IN_TESTING = 'В тестировании',
    RUS_CLOSED = 'Закрыт',
    RUS_REJECTED = 'Отклонен',
    RUS_RESOLVED = 'Решенные',
    RUS_REOPEN = 'Переоткрыт',
}

export enum EJiraStatusKey {
    UNDEFINED = 'undefined',
    NEW = 'new',
    INDETERMINATE = 'indeterminate',
    DONE = 'done',
}
