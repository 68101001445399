import { Dispatch, SetStateAction } from 'react'

import { createBrowserHistory } from 'history'
import { useLocation } from 'react-router-dom'

export const Nav = createBrowserHistory()

export const useQuery = (name: string) => new URLSearchParams(useLocation().search).get(name)

export const useLocationHash = () => useLocation().hash

export const hashReplace = (hash: string, regexp: string[]) => {
    let replaced = hash
    regexp.map((str) => {
        replaced = replaced.replace(str, '')
    })
    replaced = replaced.replace('-', '_')
    return replaced.toUpperCase()
}

export const isHashChanged = (hash: string, prevHash: string, setPrevHash: Dispatch<SetStateAction<string>>) => {
    if (hash !== prevHash) {
        setPrevHash(hash)
        return true
    } else return false
}
