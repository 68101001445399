// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z2m86kOjHqShUu5L78Zg .ant-input-show-count-suffix,.Z2m86kOjHqShUu5L78Zg [class*=\" ant-input\"]::after,.Z2m86kOjHqShUu5L78Zg .ant-input-data-count{font-size:12px}.Z2m86kOjHqShUu5L78Zg .aOBcMRVItChjhO5Mr7PF{display:inline-block;font-size:12px;margin-top:20px}.Z2m86kOjHqShUu5L78Zg .DbnWHkkXZivfRiMChbTp{display:inline-block;margin-top:10px;margin-left:12px;font-size:14px;border-bottom:1px dashed var(--color-primary5)}.fiIGL4U00sBTpZmZQT6y{display:inline-flex;gap:8px}.ant-select-item-option-state{align-self:center}", "",{"version":3,"sources":["webpack://./src/components/CreateModal/CreateModal.module.scss"],"names":[],"mappings":"AAEQ,kJAGI,cAAA,CAGR,4CACI,oBAAA,CACA,cAAA,CACA,eAAA,CAGJ,4CACI,oBAAA,CACA,eAAA,CACA,gBAAA,CACA,cAAA,CACA,8CAAA,CAIR,sBACI,mBAAA,CACA,OAAA,CAIA,8BACI,iBAAA","sourcesContent":[".Form {\n    :global {\n        .ant-input-show-count-suffix,\n        [class*=' ant-input']::after,\n        .ant-input-data-count {\n            font-size: 12px;\n        }\n    }\n    .Label {\n        display: inline-block;\n        font-size: 12px;\n        margin-top: 20px;\n    }\n\n    .TeamLink {\n        display: inline-block;\n        margin-top: 10px;\n        margin-left: 12px;\n        font-size: 14px;\n        border-bottom: 1px dashed var(--color-primary5);\n    }\n}\n\n.FormFooter {\n    display: inline-flex;\n    gap: 8px;\n}\n\n:global {\n    .ant-select-item-option-state {\n        align-self: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Form": "Z2m86kOjHqShUu5L78Zg",
	"Label": "aOBcMRVItChjhO5Mr7PF",
	"TeamLink": "DbnWHkkXZivfRiMChbTp",
	"FormFooter": "fiIGL4U00sBTpZmZQT6y"
};
export default ___CSS_LOADER_EXPORT___;
