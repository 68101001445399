import { Dispatch, Key, SetStateAction } from 'react'

import { makeAutoObservable } from 'mobx'

import { StoreTestCase } from '@/store'

import { ITestCase, ITestFolder, RangeValue } from '@/types'
import { EWorkStatus } from '@/enums'

export const StoreCreateTestSet = makeAutoObservable({
    name: '',
    description: '',
    selectedProjectId: '',
    selectedTestFolderId: '',
    isModaTestCasesOpen: false,
    testFolders: [] as ITestFolder[],
    selectedUser: '',
    testFolderInfo: {} as ITestFolder,
    executionPeriod: [null, null] as RangeValue,
    isCreatingTestSet: false,
    testSetIdInModal: '',
    selectedTestCases: [] as ITestCase[],
    showSelectedTestCases: false,
    selectedRowKeys: [] as Key[],
    versionSoftware: '',
    testSoftware: '',

    setShowSelectedTestCases(value: boolean) {
        this.showSelectedTestCases = value
    },

    setTestSoftwareChange(value: string) {
        this.testSoftware = value
    },

    setSoftwareVersion(value: string) {
        this.versionSoftware = value
    },

    setCreatingTestSet(value: boolean) {
        this.isCreatingTestSet = value
    },

    setTestSetIdInModal(value: string) {
        this.testSetIdInModal = value
    },

    setTestSetName(value: string) {
        this.name = value
    },

    setTestSetDescription(value: string) {
        this.description = value
    },

    setSelectedProjectId(value: string) {
        this.selectedProjectId = value
    },

    setSelectedUser(value: string) {
        this.selectedUser = value
    },

    setExecutionPeriod(value: RangeValue) {
        this.executionPeriod = value
    },

    setTestFolderInfo(value: ITestFolder) {
        this.testFolderInfo = value
    },

    setSelectedTestFolderId(value: string) {
        this.selectedTestFolderId = value
    },

    setTestFolders(value: ITestFolder[]) {
        this.testFolders = value
    },

    setIsModaTestCasesOpen(value: boolean) {
        this.isModaTestCasesOpen = value
    },

    setSelectedRowKeys(selectedRowKeys: Key[], testKeysToExclude?: Key[]) {
        if (testKeysToExclude) {
            const updatedSelectedRowKeys = [...new Set([...this.selectedRowKeys, ...selectedRowKeys])].filter(
                (id) => !testKeysToExclude.includes(id)
            )
            this.selectedRowKeys = updatedSelectedRowKeys
        } else {
            this.selectedRowKeys = selectedRowKeys
        }
    },

    setSelectedTestCases(selectedTestCases: ITestCase[], testKeysToExclude?: Key[]) {
        if (testKeysToExclude) {
            const updatedSelectedTestCases = [...this.selectedTestCases, ...selectedTestCases]
                .filter((testCase) => !testKeysToExclude.includes(testCase.id))
                .filter((testCase, index, self) => index === self.findIndex((i) => i.id === testCase.id))
            this.selectedTestCases = updatedSelectedTestCases
        } else {
            this.selectedTestCases = selectedTestCases
        }
    },

    updateSelectedTestCases(testCasesId: string[], setSelectedRowKeys?: Dispatch<SetStateAction<Key[]>>) {
        setSelectedRowKeys && setSelectedRowKeys(testCasesId)
        const testKeysToExclude = StoreCreateTestSet.selectedRowKeys.filter((id) => !testCasesId.includes(id as string))
        StoreCreateTestSet.setSelectedRowKeys(testCasesId, testKeysToExclude)
        StoreCreateTestSet.setSelectedTestCases(StoreCreateTestSet.selectedTestCases, testKeysToExclude)
    },

    validateAddingToTestCycle(setSelectedRowKeys?: Dispatch<SetStateAction<Key[]>>) {
        const testCasesId: string[] = []
        Object.values(StoreCreateTestSet.selectedRowKeys)
            .flat()
            .forEach((testCaseId) => {
                const filteredTestCases = StoreCreateTestSet.selectedTestCases.filter(
                    (testCase) => testCase.id === testCaseId
                )
                if (
                    filteredTestCases[0].testCaseBlockInfo === null &&
                    filteredTestCases[0].workStatus !== EWorkStatus.DRAFT
                ) {
                    testCasesId.push(testCaseId as string)
                }
            })

        this.updateSelectedTestCases(testCasesId, setSelectedRowKeys)
        return testCasesId
    },

    resetTestSet() {
        this.name = ''
        this.description = ''
        this.selectedProjectId = ''
        this.selectedTestFolderId = ''
        this.isModaTestCasesOpen = false
        this.testFolders = []
        this.selectedUser = ''
        this.testFolderInfo = {} as ITestFolder
        this.executionPeriod = [null, null]
        this.isCreatingTestSet = false
        this.testSetIdInModal = ''
        this.selectedRowKeys = []
        this.selectedTestCases = []
        this.versionSoftware = ''
        this.testSoftware = ''
        StoreTestCase.setReadOnly(false)
    },

    resetSelectedTestCases() {
        this.selectedRowKeys = []
        this.selectedTestCases = []
    },
})
