import React, { Dispatch, MouseEvent, SetStateAction } from 'react'

import { FolderRow } from './FolderRow'

import { folderTabs, pageUrls } from '@/consts'

import { DataNodeWithName, ITestFolder } from '@/types'
import { EModalTypeFolder } from '@/enums'

type Props = {
    testFolders: ITestFolder[]
    setTestFolders: Dispatch<SetStateAction<ITestFolder[]>>
    setSelectedFolderId: Dispatch<SetStateAction<string>>
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
    currentFolderTab: keyof typeof folderTabs
    onCreateSubFolder: (folderId: string, nestingLevel: number, e?: MouseEvent) => void
    selectFolderOnly?: boolean
    setIsModalCreateFolderOpen?: Dispatch<SetStateAction<boolean>>
    setIsModalRenameOpen?: Dispatch<SetStateAction<boolean>>
    setIsModalSelectOpen?: Dispatch<SetStateAction<boolean>>
    setSelectedForActionFolder?: Dispatch<SetStateAction<ITestFolder | undefined>>
    setModalTypeFolder?: Dispatch<SetStateAction<EModalTypeFolder | undefined>>
    selectedForActionFolder?: ITestFolder
    modalTypeFolder?: EModalTypeFolder | undefined
}

export const createFoldersTree = (props: Props): DataNodeWithName[] => {
    const {
        testFolders,
        setTestFolders,
        setSelectedFolderId,
        setIsModalCreateFolderOpen,
        selectFolderOnly,
        setModalTypeFolder,
        setIsModalRenameOpen,
        setIsModalSelectOpen,
        setSelectedForActionFolder,
        pageType,
        onCreateSubFolder,
        currentFolderTab,
        selectedForActionFolder,
        modalTypeFolder,
    } = props

    const hashTable = Object.create(null)
    testFolders.forEach(
        (folder) =>
            (hashTable[folder.id] = {
                name: folder.name,
                title: (
                    <FolderRow
                        folder={folder}
                        folders={testFolders}
                        setTestFolders={setTestFolders}
                        setSelectedFolderId={setSelectedFolderId}
                        setSelectedForActionFolder={setSelectedForActionFolder}
                        setIsModalCreateFolderOpen={setIsModalCreateFolderOpen}
                        setIsModalRenameOpen={setIsModalRenameOpen}
                        setIsModalSelectOpen={setIsModalSelectOpen}
                        setModalTypeFolder={setModalTypeFolder}
                        selectFolderOnly={selectFolderOnly}
                        pageType={pageType}
                        onCreateSubFolder={onCreateSubFolder}
                    />
                ),
                key: folder.id,
                parentFolderId: folder.parentFolderId,
                children: [],
                archive: folder.archive,
            })
    )
    const foldersTree = [] as DataNodeWithName[]

    const parentHasMore1Children = () => {
        return (
            testFolders.filter((testFolder) => testFolder.parentFolderId === selectedForActionFolder?.parentFolderId)
                .length > 1
        )
    }

    testFolders.forEach((folder) => {
        if (pageType === pageUrls.testFolder || pageType === pageUrls.testSets) {
            if (!folder.archive) {
                //для запрета перемещения родительской папки первого уровня в дочерние папки + в родительские 1 уровня с не более 1 веткой

                const hideParentFolder =
                    hashTable[folder.id].key === selectedForActionFolder?.parentFolderId && !parentHasMore1Children()

                const isMovingFirstLevelFolder =
                    modalTypeFolder === EModalTypeFolder.MOVE &&
                    (hashTable[folder.id].key === selectedForActionFolder?.id || hideParentFolder)

                if (folder?.parentFolderId) {
                    //для запрета перемещения родительской папки в дочерние папки
                    const hideFolderInMoveModal =
                        modalTypeFolder === EModalTypeFolder.MOVE &&
                        (hashTable[folder.id].key === selectedForActionFolder?.id ||
                            (hashTable[folder.id].key === selectedForActionFolder?.parentFolderId &&
                                !parentHasMore1Children()))

                    //TODO: 250923 не понял зачем это, возможно уже не актуально: currentFolderTab !== folderTabs.archive ||
                    if (
                        testFolders.some(
                            (testFolder) => testFolder.id === folder?.parentFolderId && !testFolder.archive
                        )
                    ) {
                        if (!hideFolderInMoveModal) {
                            hashTable[folder?.parentFolderId].children.push(hashTable[folder.id])
                        }
                    } else {
                        foldersTree.push(hashTable[folder.id])
                    }
                } else {
                    //папки первого уровня
                    !isMovingFirstLevelFolder && foldersTree.push(hashTable[folder.id])
                }
            }
        }
    })

    return foldersTree
}
