import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { MsExecutorApi } from '@/api'

import { showMessage } from '@/utils'

import { Alert, Button, Form, Input, Select, Typography } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'

import { StoreProject } from '@/store'

import { IJiraBoard } from '@/types'

import styles from './JiraForm.module.scss'

const { Text } = Typography
const { Password } = Input

interface JiraFormProps {
    onCancel: () => void
    closeModal: () => void
    isEditing: boolean
}

export const JiraForm = (props: JiraFormProps) => {
    const { onCancel, closeModal, isEditing } = props
    const { t } = useTranslation(['page--projectSettings', 'common', 'notification', 'validation'])
    const [scrumBoards, setScrumBoards] = useState<IJiraBoard[]>([])
    const [submittable, setSubmittable] = useState(false)
    const [loadingTestButton, setLoadingTestButton] = useState(false)
    const [loadingFinishButton, setLoadingFinishButton] = useState(false)

    const [message, setMessage] = useState<string | null>(null)
    const [messageType, setMessageType] = useState<'success' | 'error'>('success')

    const { projectId } = useParams()

    const [form] = Form.useForm()

    const getScrumBoards = () => {
        projectId &&
            StoreProject.jiraIntegrationAvailability &&
            StoreProject.getScrumBoards(projectId).then((res) => {
                setScrumBoards(res.data)
            })
    }

    const setScrumBoard = (scrumBoardId: string) => {
        if (projectId && scrumBoardId) {
            MsExecutorApi.setScrumBoard(projectId, scrumBoardId)
            StoreProject.integrationInfo.scrumBoardId = scrumBoardId
        }
    }

    useEffect(() => {
        StoreProject.jiraIntegrationAvailability ? getScrumBoards() : setScrumBoards([])
    }, [StoreProject.jiraIntegrationAvailability])

    const requiredRule = [
        {
            required: true,
            message: t('validation:required'),
        },
    ]

    const onCancelJiraForm = () => {
        setMessage(null)
        onCancel()
    }

    const onClickTest = () => {
        setLoadingTestButton(true)
        StoreProject.checkJira({ ...form.getFieldsValue(), projectId, issueTypeFieldId: '10004' })
            .then(() => {
                setMessage(t('page--projectSettings:integrations.messages.successCheck'))
                setMessageType('success')
            })
            .catch(() => {
                setMessage(t('page--projectSettings:integrations.messages.errorCheck'))
                setMessageType('error')
            })
            .finally(() => {
                setLoadingTestButton(false)
            })
    }

    const formInitialValues = {
        projectKey: StoreProject?.integrationInfo?.projectKey || '',
        jiraVersion: StoreProject?.integrationInfo?.jiraVersion || '8.5.0',
        jiraLink: StoreProject?.integrationInfo?.jiraLink || '',
        userName: StoreProject?.integrationInfo?.userName || '',
        password: StoreProject?.integrationInfo?.password || '',
        scrumBoardId: StoreProject?.integrationInfo?.scrumBoardId || null,
    }

    const onFinish = (params: any) => {
        setLoadingFinishButton(true)
        if (isEditing) {
            setScrumBoard(params.scrumBoardId)
            StoreProject.updateJiraIntegration({ ...params, projectId, issueTypeFieldId: '10004' })
                .then(() => {
                    showMessage('success', t('notification:success.jiraUpdatedConnection'))

                    closeModal()
                })
                .finally(() => {
                    setLoadingFinishButton(false)
                })
        } else {
            StoreProject.connectJira({ ...params, projectId, issueTypeFieldId: '10004' })
                .then(() => {
                    showMessage('success', t('notification:success.jiraConnection'))
                    StoreProject.getIntegrationInfo(projectId!)
                    StoreProject.setJiraIntegrationAvailability(true)
                    setMessage(null)
                    closeModal()
                })
                .finally(() => {
                    setLoadingFinishButton(false)
                })
        }
    }

    const values = Form.useWatch([], form)
    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                setSubmittable(true)
            },
            () => {
                setSubmittable(false)
            }
        )
    }, [values])

    const jiraVersions = [{ value: '8.5.0', label: '8.5.0' }]

    return (
        <Form form={form} initialValues={formInitialValues} onFinish={onFinish} className={styles.JiraForm}>
            <FormItem
                name="projectKey"
                label={t('page--projectSettings:integrations.jira.projectName')}
                rules={requiredRule}
            >
                <Input />
            </FormItem>
            <FormItem
                name="jiraVersion"
                label={t('page--projectSettings:integrations.jira.version')}
                rules={requiredRule}
            >
                <Select
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={jiraVersions}
                />
            </FormItem>
            <FormItem name="scrumBoardId" label={t('page--projectSettings:integrations.jira.jiraBoard')}>
                <Select
                    showSearch
                    fieldNames={{ value: 'id', label: 'name' }}
                    filterOption={(input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={scrumBoards}
                    disabled={!isEditing}
                />
            </FormItem>
            {!isEditing && (
                <Text type="secondary" className={styles.Text}>
                    {t('page--projectSettings:integrations.jira.jiraBoardTooltip')}
                </Text>
            )}
            <FormItem name="jiraLink" label={t('page--projectSettings:integrations.jira.url')} rules={requiredRule}>
                <Input />
            </FormItem>
            <FormItem name="userName" label={t('page--projectSettings:integrations.jira.login')} rules={requiredRule}>
                <Input />
            </FormItem>
            <FormItem
                name="password"
                label={t('page--projectSettings:integrations.jira.password')}
                rules={requiredRule}
            >
                <Password />
            </FormItem>
            {message && <Alert message={message} type={messageType} showIcon className={styles.Alert} />}
            <FormItem style={{ marginRight: 'auto', display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={onCancelJiraForm}>{t('common:buttons.cancel')}</Button>
                <Button
                    className={styles.FinishButton}
                    loading={loadingTestButton}
                    disabled={!submittable}
                    onClick={onClickTest}
                >
                    {t('common:buttons.test')}
                </Button>

                <Button
                    className={styles.FinishButton}
                    type="primary"
                    htmlType="submit"
                    disabled={!submittable}
                    loading={loadingFinishButton}
                >
                    {isEditing ? t('common:buttons.update') : t('common:buttons.create')}
                </Button>
            </FormItem>
        </Form>
    )
}
