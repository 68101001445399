import React from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Breadcrumb, Typography } from 'antd'

import { pageUrls } from '@/consts'

import { IProject } from '@/types'

const { Text } = Typography

type Props = {
    projectInfo: IProject | undefined
}

export const Breadcrumbs = (props: Props) => {
    const { projectInfo } = props
    const { t } = useTranslation(['page--createTestSet'])

    const breadcrumbItems = [
        {
            key: 'project',
            title: (
                <Link to={`${pageUrls.project}/${projectInfo?.id}`}>
                    <Text type="secondary">{`${t('page--createTestSet:project')} «${projectInfo?.projectName}»`}</Text>
                </Link>
            ),
        },
    ]

    return projectInfo && <Breadcrumb items={breadcrumbItems} />
}
