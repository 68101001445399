import { StoreAuthController } from '@/store'

import { EDefaultUserRoles, EPermissions } from '@/enums'

export const hasWorkspacePermissions = (permissions: EPermissions[], workspaceId: string) => {
    if (!permissions.length) {
        return
    }

    const hasAdminAccess = StoreAuthController.hasAdminRole
    if (hasAdminAccess) {
        return true
    }

    const hasAccess =
        StoreAuthController?.workspacePermissions[workspaceId].some(
            (permission: EPermissions) => permissions.includes(permission) || permissions.includes(EPermissions.ADMIN)
        ) || StoreAuthController.hasAdminRole

    return hasAccess
}

export const hasProjectPermissions = (permissions: EPermissions[], projectId: string, workspaceId: string) => {
    if (!permissions.length) {
        return
    }

    const hasAdminAccess = StoreAuthController.hasAdminRole

    if (hasAdminAccess) {
        return true
    }

    const isWorkspaceAdmin = hasWorkspacePermissions([EPermissions.ADMIN], workspaceId)
    if (isWorkspaceAdmin) {
        return true
    }

    const hasAccess = StoreAuthController?.projectPermissions[projectId].some((permission: EPermissions) =>
        permissions.includes(permission)
    )

    return hasAccess
}

type hasAccessType = {
    workspaceId: string
    projectPermissions?: EPermissions[]
    workspacePermissions?: EPermissions[]
    projectId?: string
}

export const hasAccess = (props: hasAccessType) => {
    const { projectPermissions, workspacePermissions, projectId, workspaceId } = props

    const hasAdminAccess = StoreAuthController.hasAdminRole

    if (hasAdminAccess) {
        return true
    }

    const hasWorkspaceAccess =
        workspacePermissions && workspaceId && hasWorkspacePermissions(workspacePermissions, workspaceId)

    if (hasWorkspaceAccess) {
        return true
    }

    const hasProjectAccess =
        projectPermissions && projectId && hasProjectPermissions(projectPermissions, projectId, workspaceId)

    if (hasProjectAccess) {
        return true
    }

    return false
}

export const hasRoleOrAdmin = (roles: EDefaultUserRoles[], projectId: string, workspaceId: string) => {
    const hasAdminAccess = StoreAuthController.hasAdminRole

    if (hasAdminAccess) {
        return true
    }

    const hasWorkSpaceRole = StoreAuthController?.workspaceRoles[workspaceId].some((role: EDefaultUserRoles) =>
        roles.includes(role)
    )

    if (hasWorkSpaceRole) {
        return true
    }

    const hasProjectRole = StoreAuthController?.projectRoles[projectId].some((role: EDefaultUserRoles) =>
        roles.includes(role)
    )

    if (hasProjectRole) {
        return true
    }

    return false
}
