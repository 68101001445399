import React, { Dispatch, Key, SetStateAction, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { MsAttachmentApi, MsProjectApi } from '@/api'

import { IO, toDateFormat } from '@/utils'

import { Form, Input, Modal, Popconfirm, Table, Tooltip, Typography } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'
import { TestCaseAttachment } from '@/components/TestCaseComponent/UploadTestCaseAttachments/TestCaseAttachment'

import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons'

import { SpinnerStore } from '@/store'
import { apiUrls, showAllPages } from '@/consts'

import { IAttachment, IFileToUpload, IMixedAttachments, IObjectAttachment } from '@/types'
import { ColumnsType } from 'antd/es/table/interface'

import styles from './DocumentsTable.module.scss'

const { Text } = Typography
const { TextArea } = Input

interface ITableColumns {
    (fileList: IMixedAttachments[]): ColumnsType<IMixedAttachments> | any
}

interface ItestSets {
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    selectedRowKeys: Key[]
    setFileList: Dispatch<SetStateAction<IMixedAttachments[]>>
    fileList: IMixedAttachments[]
    setSaved: Dispatch<SetStateAction<boolean>>
    setFilesToUpload: Dispatch<SetStateAction<IFileToUpload[]>>
    filesToUpload: IFileToUpload[]
    setAttachments: Dispatch<SetStateAction<IAttachment[]>>
    attachments: IAttachment[]
    filesToDelete: IAttachment[]
    setFilesToDelete: Dispatch<SetStateAction<IAttachment[]>>
    canUploadAttachments: boolean
    canDeleteAttachments: boolean
}

export const DocumentsTable = (props: ItestSets) => {
    const {
        fileList,
        setFileList,
        setSaved,
        setFilesToUpload,
        filesToUpload,
        setAttachments,
        attachments,
        setSelectedRowKeys,
        selectedRowKeys,
        setFilesToDelete,
        filesToDelete,
        canUploadAttachments,
        canDeleteAttachments,
    } = props
    const { t } = useTranslation(['documentation', 'common', 'page--workspaceSettings'])

    const [form] = Form.useForm()
    const { confirm } = Modal

    const onSelectChange = (newSelectedRowKeys: React.Key[], newSelectedTestCases: IMixedAttachments[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const handleDownload = (attachment: IMixedAttachments) => {
        'bindId' in attachment
            ? getAttachment(attachment?.id, `${attachment.fileName}.${attachment.extension}`)
            : 'uid' in attachment &&
              attachment?.originFileObj &&
              IO.download(attachment?.originFileObj, attachment?.name)
    }

    const onRemoveFileToUpload = async (id: string) => {
        const updatedAttachments = filesToUpload.filter((attachment) => attachment.uid !== id)
        setFilesToUpload(updatedAttachments)
    }

    const onRemoveAttachment = async (id: string) => {
        const updatedAttachments = attachments.filter((attachment) => attachment.bindId !== id)
        setAttachments(updatedAttachments)

        const updatedToDeleteFiles = [...filesToDelete, ...attachments.filter((attachment) => attachment.bindId === id)]
        setFilesToDelete(updatedToDeleteFiles)
    }

    const handleDelete = (attachment: IMixedAttachments) => {
        const attachmentId = 'bindId' in attachment ? attachment.bindId : 'uid' in attachment ? attachment.uid : ''
        canDeleteAttachments && 'bindId' in attachment
            ? attachmentId && !SpinnerStore.show && onRemoveAttachment(attachmentId)
            : attachmentId && onRemoveFileToUpload(attachmentId)
        setSaved(false)
        const newKeys = selectedRowKeys.filter((key) => key !== attachmentId)
        setSelectedRowKeys(newKeys)
    }

    const getAttachment = async (id: string, fileName: string) => {
        SpinnerStore.setShow(true)
        await MsAttachmentApi.getAttachment(id, fileName)
        SpinnerStore.setShow(false)
    }

    const onChangeDescription = async (attachment: IMixedAttachments) => {
        if (!SpinnerStore.show) {
            const initialDescription = 'description' in attachment ? attachment.description : ''

            form.setFieldValue('description', initialDescription)
            confirm({
                title: t('page--workspaceSettings:mainSettings.description'),
                icon: null,
                className: styles.TextAreaModal,
                content: (
                    <Form form={form}>
                        <FormItem name="description">
                            <TextArea showCount maxLength={250} />
                        </FormItem>
                    </Form>
                ),
                centered: true,
                onOk: async () => {
                    if (
                        'bindId' in attachment &&
                        'existingObjectId' in attachment &&
                        'entityType' in attachment &&
                        'attachmentType' in attachment &&
                        'description' in attachment
                    ) {
                        const req = {
                            id: attachment.bindId,
                            existingAttachmentId: attachment.id,
                            existingObjectId: attachment.existingObjectId,
                            entityType: attachment.entityType,
                            attachmentType: attachment.attachmentType,
                            description: form.getFieldValue('description'),
                        } as IObjectAttachment

                        await MsProjectApi.updateItem(req, apiUrls.objectAttachment)

                        if ('id' in attachment) {
                            const formattedFileList = fileList.map((file) => {
                                const isCurrentFile = 'id' in file && file.id === attachment.id

                                if (isCurrentFile) {
                                    return { ...file, description: form.getFieldValue('description') }
                                }

                                return file
                            })

                            setFileList(formattedFileList)
                        }

                        return
                    }

                    if ('uid' in attachment) {
                        const filesToUploadCopy = [...filesToUpload]
                        filesToUploadCopy.find((file) => file.uid === attachment.uid)!.description =
                            form.getFieldValue('description')
                        setFilesToUpload(filesToUploadCopy)
                    }
                },
            })
        }
    }

    const getTableColumns: ITableColumns = (fileList) => {
        if (!fileList) return []

        return [
            {
                title: t('documentation:name'),
                key: 'fileName',
                dataIndex: 'fileName',
                className: styles.ColumnFileName,
                sorter: (a: any, b: any) => (a?.fileName?.length || 0) - (b?.fileName?.length || 0),
                render: (fileName: string, attachment: IMixedAttachments) => (
                    <TestCaseAttachment
                        key={'id' in attachment ? attachment.id : attachment.uid}
                        file={attachment}
                        hideDeleteCross
                    />
                ),
            },

            {
                title: t('documentation:description'),
                key: 'description',
                dataIndex: 'description',
                className: styles.ColumnDescription,
                render: (description: string, attachment: IMixedAttachments) => (
                    <Text ellipsis={{ tooltip: 'description' in attachment && attachment.description }}>
                        {'description' in attachment && attachment.description}
                    </Text>
                ),
            },

            {
                title: t('documentation:uploadDate'),
                key: 'uploadDate',
                className: styles.ColumnUploadDate,
                sorter: (a: any, b: any) => a.createdDate - b.createdDate,
                render: (uploadDate: number, attachment: IMixedAttachments) => (
                    <span>
                        {'createdDate' in attachment && attachment.createdDate && toDateFormat(attachment.createdDate)}
                    </span>
                ),
            },
            canUploadAttachments
                ? {
                      key: 'edit',
                      render: (id: string, attachment: IMixedAttachments) => (
                          <Tooltip placement="bottom" title={t('documentation:editDescripion')}>
                              <EditOutlined
                                  onClick={() => {
                                      onChangeDescription(attachment)
                                  }}
                              />
                          </Tooltip>
                      ),
                  }
                : { className: 'hidden' },

            {
                key: 'download',
                render: (id: string, attachment: IMixedAttachments) => (
                    <Tooltip placement="bottom" title={t('common:buttons.download')}>
                        <DownloadOutlined onClick={() => handleDownload(attachment)} />
                    </Tooltip>
                ),
            },
            canDeleteAttachments
                ? {
                      key: 'delete',
                      render: (id: string, attachment: IMixedAttachments) => (
                          <Popconfirm
                              title={t('common:buttons.deleteGuard')}
                              onConfirm={() => handleDelete(attachment)}
                          >
                              <Tooltip placement="bottom" title={t('common:buttons.delete')}>
                                  <DeleteOutlined className={styles.DeleteButton} />
                              </Tooltip>
                          </Popconfirm>
                      ),
                  }
                : { className: 'hidden' },
        ]
    }

    const columns = useMemo(() => getTableColumns(fileList), [t, fileList, selectedRowKeys])

    return (
        <Table
            columns={columns}
            dataSource={fileList}
            rowKey={(record) => ('bindId' in record ? record.bindId : 'uid' in record && record.uid) as string}
            pagination={{
                showSizeChanger: false,
                pageSize: showAllPages,
                defaultCurrent: 1,
                hideOnSinglePage: true,
            }}
            rowSelection={
                canDeleteAttachments
                    ? {
                          type: 'checkbox',
                          ...rowSelection,
                      }
                    : undefined
            }
            size="small"
            loading={SpinnerStore.show}
        />
    )
}
