// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mOomYN0Pj_2uL9SCTaFg .RRBOjpqvtf3g_dq4XXkP{display:flex}.mOomYN0Pj_2uL9SCTaFg .ant-modal-confirm-body .ant-modal-confirm-title+.ant-modal-confirm-content{max-width:100%;margin-bottom:8px}.mOomYN0Pj_2uL9SCTaFg label{display:none}.BPULtEheSa7MZ9SXkCPl:hover{color:red}.bmZ8NuYgUWZFsVCr7z1I{width:16%}.AvQl5MSfrSSwb6wQ8Pos{width:53%;padding-left:16px;padding-right:16px}.AQPNOZ4j251iM0shGtll{width:14%}", "",{"version":3,"sources":["webpack://./src/components/SettingPageTabs/Documentation/DocumentAttachments/DocumentsTable.module.scss"],"names":[],"mappings":"AACI,4CACI,YAAA,CAIA,kGACI,cAAA,CACA,iBAAA,CAGJ,4BACI,YAAA,CAKZ,4BACI,SAAA,CAGJ,sBACI,SAAA,CAEJ,sBACI,SAAA,CACA,iBAAA,CACA,kBAAA,CAEJ,sBACI,SAAA","sourcesContent":[".TextAreaModal {\n    .Menu {\n        display: flex;\n    }\n\n    :global {\n        .ant-modal-confirm-body .ant-modal-confirm-title + .ant-modal-confirm-content {\n            max-width: 100%;\n            margin-bottom: 8px;\n        }\n\n        label {\n            display: none;\n        }\n    }\n}\n\n.DeleteButton:hover {\n    color: red;\n}\n\n.ColumnFileName {\n    width: 16%;\n}\n.ColumnDescription {\n    width: 53%;\n    padding-left: 16px;\n    padding-right: 16px;\n}\n.ColumnUploadDate {\n    width: 14%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextAreaModal": "mOomYN0Pj_2uL9SCTaFg",
	"Menu": "RRBOjpqvtf3g_dq4XXkP",
	"DeleteButton": "BPULtEheSa7MZ9SXkCPl",
	"ColumnFileName": "bmZ8NuYgUWZFsVCr7z1I",
	"ColumnDescription": "AvQl5MSfrSSwb6wQ8Pos",
	"ColumnUploadDate": "AQPNOZ4j251iM0shGtll"
};
export default ___CSS_LOADER_EXPORT___;
