import React, { useEffect, useMemo, useState } from 'react'

import i18n from '@/i18n'
import _ from 'lodash'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { Button, Dropdown, Modal, Tooltip } from 'antd'
import type { MenuProps } from 'antd'
import { ModalAddSelectedTestCasesToTestSet } from '@/components/ModalAddSelectedTestCasesToTestSet/ModalAddSelectedTestCasesToTestSet'
import { CreateNewFolderModal } from '@/pages/TestFolders/components/CreateNewFolderModal'
import { MoveTestCaseToFolderModal } from '@/pages/TestFolders/components/Modals/MoveTestCaseToFolderModal'
import { SelectAndCreateModal } from '@/pages/TestFolders/components/Modals/SelectAndCreateModal'

import {
    AppstoreAddOutlined,
    CopyOutlined,
    DeleteOutlined,
    ExportOutlined,
    FileAddOutlined,
    FileExcelOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    PlusOutlined,
    SendOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons'

import {
    SpinnerStore,
    StoreCreateTestSet,
    StoreProject,
    StoreTestCase,
    StoreTestCaseList,
    StoreTestFolder,
    StoreTestSet,
} from '@/store'
import { exportFileFormats, pageUrls } from '@/consts'

import { IExportItems, IProject, ITestFolder } from '@/types'
import { EWorkStatus } from '@/enums'

import styles from './TestCaseDropdownMenu.module.scss'

type Props = {
    mergeSteps: () => void
    selectedProject?: IProject
}

export const TestCaseDropdownMenu = observer((props: Props) => {
    const { mergeSteps, selectedProject } = props

    const { t } = useTranslation(['testCase', 'page--testCases', 'common'])
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [showTestSetsModal, setShowTestSetsModal] = useState(false)
    const [isSelectAndCreateModalOpen, setIsSelectAndCreateModalOpen] = useState(false)
    const [isModalCreateFolderOpen, setIsModalCreateFolderOpen] = useState(false)
    const [testFolders, setTestFolders] = useState<ITestFolder[]>([])
    const [selectedFolderId, setSelectedFolderId] = useState<string>('')

    const [testCaseFolders, setTestCaseFolders] = useState<ITestFolder[]>([])

    const [isMoveTestCaseToFolderModalOpen, setIsMoveTestCaseToFolderModalOpen] = useState(false)

    const isNewTC = useMemo(
        () =>
            !!matchPath(
                {
                    path: `/${pageUrls.project}/:id${pageUrls.testFolder}/:id${pageUrls.testCase}/new`,
                },
                pathname
            ),
        [pathname]
    )

    const disabled = isNewTC
    const currentProjectId = selectedProject?.id || StoreProject.id

    useEffect(() => {
        getTestSetFolders()
    }, [currentProjectId])
    useEffect(() => {
        getTestCaseFolders()
    }, [currentProjectId])

    const getTestSetFolders = async () => {
        if (currentProjectId) {
            const folders = await StoreTestSet.getTestSetFolders(currentProjectId)
            setTestFolders(folders)
        }
    }
    const getTestCaseFolders = async () => {
        if (currentProjectId) {
            const testCaseFolders = await StoreTestFolder.getTestFolders(currentProjectId)
            setTestCaseFolders(testCaseFolders)
        }
    }

    const isLocked = !!StoreTestCase.blockInfo

    const addToTitle = useMemo(() => {
        if (StoreTestCase.status === EWorkStatus.DRAFT) {
            return t('page--testCases:disabledAddToNewTestSet')
        } else if (!StoreTestCase.isSaved) {
            return t('page--testCases:changesNotSaved')
        } else if (isLocked) {
            return t('page--testCases:unlockToAdd')
        }
        return ''
    }, [StoreTestCase.status, StoreTestCase.isSaved, isLocked, i18n.language])

    const moveItle = useMemo(() => {
        if (!StoreTestCase.isSaved) {
            return t('page--testCases:changesNotSaved')
        } else if (isLocked) {
            return t('page--testCases:unlockToMove')
        }
        return ''
    }, [StoreTestCase.isSaved, isLocked, i18n.language])

    const exportTitle = useMemo(() => {
        if (!StoreTestCase.isSaved) {
            return t('page--testCases:changesNotSaved')
        } else if (isLocked) {
            return t('page--testCases:unlockToExport')
        }
        return ''
    }, [StoreTestCase.isSaved, isLocked, i18n.language])

    const duplicateTitle = useMemo(() => {
        if (!StoreTestCase.isSaved) {
            return t('page--testCases:changesNotSaved')
        } else if (isLocked) {
            return t('page--testCases:unlockToDuplicate')
        }
        return ''
    }, [StoreTestCase.isSaved, isLocked, i18n.language])

    const onCopy = async () => {
        mergeSteps()
        await StoreTestCase.toggleBlocked(false)

        const testCasesCopy = await StoreTestCase.copyTestCase()
        StoreTestCase.setTestCaseId(testCasesCopy[0].id)
        StoreTestCase.setIsSaved(true)
        StoreTestCase.setRequiredStepChanged(false)
        await StoreTestCaseList.getAllTestCases()
        navigate(
            `${pageUrls.project}/${StoreTestCase.projectId}${pageUrls.testFolder}/${StoreTestCase.testFolderId}${pageUrls.testCase}/${testCasesCopy[0].id}`
        )
    }

    const handleDelete = async (id: string, name: string) => {
        await StoreTestCase.toggleBlocked(false)
        StoreTestCase.setIsSaved(true)

        await StoreTestCase.deleteTestCase(id, name)

        const backUrl = StoreTestFolder.id
            ? `${pageUrls.project}/${StoreTestCase.projectId}?testFolder=${StoreTestCase.testFolderId}`
            : `${pageUrls.project}/${StoreTestCase.projectId}`

        navigate(backUrl)
    }

    const onAddToTestSet = () => {
        setShowTestSetsModal(true)
    }

    const onAddToNewTestSet = () => {
        StoreCreateTestSet.resetSelectedTestCases()
        const selectedRowKeys = [StoreTestCase.id]
        StoreCreateTestSet.setSelectedRowKeys(selectedRowKeys)
        setIsSelectAndCreateModalOpen(true)
    }

    const createNewTestSet = () => {
        setIsSelectAndCreateModalOpen(false)
        navigate(`${pageUrls.project}/${StoreProject.id}${pageUrls.createTestSet}?folderId=${selectedFolderId}`)
    }

    const onCreateFolder = () => {
        setIsModalCreateFolderOpen(true)
    }
    const closeMoveTestCaseToFolderModal = () => {
        setIsMoveTestCaseToFolderModalOpen(false)
    }

    const handleMoveTestCase = async (folderId: string) => {
        await StoreTestCase.moveTestCases([StoreTestCase.id], folderId)
        StoreTestCase.setTestFolderId(folderId)
        closeMoveTestCaseToFolderModal()
        navigate(
            `${pageUrls.project}/${StoreProject.id}${pageUrls.testFolder}/${folderId}${pageUrls.testCase}/${StoreTestCase.id}`
        )
    }

    const onMove = () => {
        setIsMoveTestCaseToFolderModalOpen && setIsMoveTestCaseToFolderModalOpen(true)
    }

    const onExport: MenuProps['onClick'] = async (e) => {
        const exportTestCases = {
            ids: [StoreTestCase.id],
            exportType: e.key,
        } as IExportItems

        await StoreTestCase.exportTestCases(exportTestCases)
    }

    const onDelete = () => {
        Modal.confirm({
            centered: true,
            title: t('page--testCases:deleteGuard.title', {
                name: StoreTestCase.name,
            }),
            content: t('page--testCases:deleteGuard.content'),
            onOk: () => {
                handleDelete(StoreTestCase.id, StoreTestCase.name)
            },
            okText: t('common:buttons.ok'),
            cancelText: t('common:buttons.cancel'),
        })
    }

    const items: MenuProps['items'] = [
        {
            key: 'deleteItem',
            label: t('common:buttons.delete'),
            icon: <DeleteOutlined />,
            disabled: disabled,
            onClick: onDelete,
        },
        {
            key: 'duplicate',
            label: (
                <Tooltip title={duplicateTitle} className="ant-dropdown-menu-title-content">
                    {t('common:buttons.duplicate')}
                </Tooltip>
            ),
            icon: <CopyOutlined />,
            disabled: disabled || isLocked,
            onClick: onCopy,
        },
        {
            key: 'move',
            label: (
                <Tooltip title={moveItle} className="ant-dropdown-menu-title-content">
                    {t('page--testCases:move')}
                </Tooltip>
            ),
            icon: <SendOutlined />,
            onClick: onMove,
            disabled: isLocked,
        },

        {
            key: 'addTo',
            label: (
                <Tooltip title={addToTitle} className="ant-dropdown-menu-title-content">
                    {t('common:buttons.addTo')}
                </Tooltip>
            ),
            icon: (
                <Tooltip title={addToTitle}>
                    <PlusOutlined />
                </Tooltip>
            ),

            disabled: StoreTestCase.status === EWorkStatus.DRAFT || isLocked,
            children: [
                {
                    label: t('page--testCases:addToTestSet'),
                    key: 'addToTestSet',
                    icon: <AppstoreAddOutlined />,
                    onClick: onAddToTestSet,
                },
                {
                    label: t('page--testCases:addToNewTestSet'),
                    key: 'addToNewTestSet',
                    icon: <FileAddOutlined />,
                    onClick: onAddToNewTestSet,
                },
            ],
        },
        {
            key: 'export',
            label: (
                <Tooltip title={exportTitle} className="ant-dropdown-menu-title-content">
                    {t('common:buttons.export')}
                </Tooltip>
            ),
            icon: <ExportOutlined />,
            disabled: !StoreTestCase.isSaved || isLocked,
            children: [
                {
                    label: exportFileFormats.word,
                    key: exportFileFormats.word,
                    icon: <FileWordOutlined />,
                    onClick: onExport,
                },
                {
                    label: exportFileFormats.excel,
                    key: exportFileFormats.excel,
                    icon: <FileExcelOutlined />,
                    onClick: onExport,
                },
                {
                    label: exportFileFormats.adobeReader,
                    key: exportFileFormats.adobeReader,
                    icon: <FilePdfOutlined />,
                    onClick: onExport,
                },
            ],
        },
    ]

    return (
        <>
            <Dropdown menu={{ items }} placement="bottomLeft" className={styles.CustomDropDownMenu}>
                <Button ghost className={'borderless'} icon={<UnorderedListOutlined />} disabled={SpinnerStore.show} />
            </Dropdown>

            <ModalAddSelectedTestCasesToTestSet
                selectedTestCaseIds={[StoreTestCase.id]}
                showTestSetsModal={showTestSetsModal}
                setShowTestSetsModal={setShowTestSetsModal}
            />

            <SelectAndCreateModal
                isSelectAndCreateModalOpen={isSelectAndCreateModalOpen}
                setIsSelectAndCreateModalOpen={setIsSelectAndCreateModalOpen}
                testFolders={testFolders}
                setTestFolders={setTestFolders}
                selectedFolderId={selectedFolderId}
                setSelectedFolderId={setSelectedFolderId}
                pageType={pageUrls.testSets}
                onCreateItem={createNewTestSet}
                onCreateFolder={onCreateFolder}
            />

            <CreateNewFolderModal
                projectId={currentProjectId}
                isModalCreateFolderOpen={isModalCreateFolderOpen}
                setIsModalCreateFolderOpen={setIsModalCreateFolderOpen}
                testFolders={testFolders}
                setTestFolders={setTestFolders}
                selectedFolderId={selectedFolderId}
                setSelectedFolderId={setSelectedFolderId}
                pageType={pageUrls.testSets}
            />
            {isMoveTestCaseToFolderModalOpen && (
                <MoveTestCaseToFolderModal
                    initialTestFolders={testCaseFolders}
                    initialSelectedFolderId={selectedFolderId}
                    pageType={pageUrls.testFolder}
                    onConfirm={handleMoveTestCase}
                    onClose={closeMoveTestCaseToFolderModal}
                />
            )}
        </>
    )
})
