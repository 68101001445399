import React from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { getEnumKey } from '@/utils'

import { Tag, Typography } from 'antd'

import { EJiraStatus, EJiraStatusKey } from '@/enums'

import styles from './JiraTaskItem.module.scss'

const { Text } = Typography

interface JiraTaskItemProps {
    id: string
    link: string
    name: string
    jiraKey: string
    status: EJiraStatus
    statusKey: EJiraStatusKey
}

export const JiraTaskItem = (props: JiraTaskItemProps) => {
    const { status, link, name, jiraKey, statusKey } = props
    const { t } = useTranslation(['statuses'])

    const statusEnum = getEnumKey(EJiraStatus, status)
    const translatedStatus = statusEnum ? t(`statuses:${statusEnum}`) : status

    return (
        <div className={styles.JiraTaskItem}>
            <a href={link} target="_blank" rel="noreferrer" className={styles.Item}>
                <Tag bordered={false} className={cn(styles.Tag, styles[`${statusKey}`])}>
                    {translatedStatus}
                </Tag>
                <Text className={styles.JiraKey} ellipsis={{ tooltip: jiraKey }}>
                    {jiraKey}
                </Text>
                <Text className={styles.Name} ellipsis={{ tooltip: name }}>
                    {name}
                </Text>
            </a>
        </div>
    )
}
