import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { LocalStorage } from '@/utils'
import useTranslateFormMessages from '@/utils/hooks/useTranslateFormMessages'

import { Button, Checkbox, Form, Input, Typography } from 'antd'
import ButtonMailto from '@/components/ButtonMailto'
import { DarkSwitcher } from '@/components/DarkSwitcher'
import { FormItem } from '@/components/FormItem/FormItem'
import { LanguageSwitcher } from '@/components/LanguageSwitcher/LanguageSwitcher'
import { ResetPasswordModal } from './ResetPasswordModal'

import { SpinnerStore, StoreAuthController } from '@/store'
import { pageUrls, supportMail } from '@/consts'

import { ISignUpParams } from '@/types'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'

import styles from '../SignInUp.module.scss'

const { Title } = Typography
const { Password } = Input

export const SignIn = observer(() => {
    const [authForm] = Form.useForm()
    const navigate = useNavigate()
    const { t } = useTranslation(['validation', 'page--auth', 'common'])
    const [isOpenForgotModal, setIsOpenForgotModal] = useState(false)

    const requiredRule = [
        {
            required: true,
            message: t('validation:required'),
        },
    ]

    useTranslateFormMessages(authForm)

    const onChangeRememberMe = (e: CheckboxChangeEvent) => {
        StoreAuthController.keepRefreshTokenInLocalStorage = e.target.checked
    }

    const userName = StoreAuthController.me.username || ''

    return (
        <div className={styles.SignIn}>
            <Title level={3}>{t('page--auth:signInTitle')}</Title>
            <ResetPasswordModal isOpenForgotModal={isOpenForgotModal} setIsOpenForgotModal={setIsOpenForgotModal} />
            <Form
                form={authForm}
                onFinish={(params: Pick<ISignUpParams, 'username' | 'password'>) => {
                    StoreAuthController.isBlockedUser = false
                    StoreAuthController.signIn(params).then((resp) => resp && navigate(pageUrls.workspaces))
                }}
                layout="vertical"
            >
                <FormItem
                    name="username"
                    label={t('page--auth:username')}
                    rules={requiredRule}
                    className={'hideRequiredMark'}
                >
                    <Input />
                </FormItem>
                <FormItem
                    name="password"
                    label={t('page--auth:password')}
                    rules={requiredRule}
                    className={'hideRequiredMark'}
                >
                    <Password autoComplete="on" />
                </FormItem>
                <FormItem>
                    <div className={styles.RememberAndForgot}>
                        <Checkbox
                            defaultChecked={LocalStorage.get('rememberMe') === 'yes'}
                            onChange={onChangeRememberMe}
                        >
                            <span>{t('page--auth:remember')}</span>
                        </Checkbox>
                        <Button size="small" type="link" onClick={() => setIsOpenForgotModal(true)}>
                            {t('page--auth:forgotPassword')}
                        </Button>
                    </div>
                </FormItem>
                <div className={styles.FormFooter}>
                    <FormItem className={styles.Submit}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            size="large"
                            loading={SpinnerStore.show}
                            disabled={SpinnerStore.show}
                        >
                            {t('page--auth:signIn')}
                        </Button>
                    </FormItem>
                    <div>
                        {t('page--auth:noAccount')} <Link to={pageUrls.signUp}>{t('page--auth:signUp')}</Link>
                    </div>
                    <div className={styles.SiteOptions}>
                        <ButtonMailto
                            email={supportMail}
                            subject={
                                StoreAuthController.isSignedIn
                                    ? t('common:support-mail-from-user', { userName: userName })
                                    : t('common:support-mail')
                            }
                            label={t('common:support')}
                        />
                        <DarkSwitcher />
                        <LanguageSwitcher />
                    </div>
                </div>
            </Form>
        </div>
    )
})
