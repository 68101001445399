import { makeAutoObservable } from 'mobx'

import { MsProjectApi } from '@/api/ms-project-api'

import { SpinnerStore, StoreProject, StoreTestCase, StoreTestFolder } from '@/store'
import { apiUrls, showAllPages } from '@/consts'

import { ISort } from '@/types'
import { EOrder, EParentFolderType } from '@/enums'

export const StoreTestCaseList = makeAutoObservable({
    allTestCases: [],
    toggleUpdateTestCases: false,
    updatingSideBar: false,

    latestSearchProps: {
        filter: {
            parentId: StoreTestFolder.id,
            parent: 'TEST_FOLDER',
        },
        sort: [
            {
                field: 'createdDate',
                direction: EOrder.DESC,
            },
        ],
        pageSize: 10,
    } as {
        page?: number
        filter: Record<string, string[] | string | any>
        sort: ISort[]
        pageSize?: number
    },

    async getTestCases(props: {
        page?: number
        filter: Record<string, string[] | string | any>
        sort: { field: string; direction: EOrder }[]
        pageSize?: number
    }) {
        SpinnerStore.setShow(true)

        const testCaseList = await MsProjectApi.filter({
            ...props,
            specificApiUrl: apiUrls.testCase,
        })

        SpinnerStore.setShow(false)
        return testCaseList
    },

    setAllTestCases(value: []) {
        this.allTestCases = value
    },

    updateTestCases() {
        this.toggleUpdateTestCases = !this.toggleUpdateTestCases
    },

    setUpdatingSideBar(value: boolean) {
        this.updatingSideBar = value
    },

    async getAllTestCases(folderId?: string) {
        const folderID = folderId || StoreTestCase.testFolderId
        this.setUpdatingSideBar(true)

        SpinnerStore.setShow(true)
        const props = {
            pageSize: showAllPages,
            page: 0,
            filter: {
                isDeleted: false,
                parentId: folderID ? folderID : StoreProject.id,
                parent: folderID ? EParentFolderType.TEST_FOLDER : EParentFolderType.PROJECT,
            },
            sort: [{ field: 'createdDate', direction: EOrder.DESC }],
        }

        const testCases = (await StoreTestCaseList.getTestCases(props)).result
        this.setAllTestCases(testCases)

        this.setUpdatingSideBar(false)

        return testCases
    },
})
