// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".o4kxmvOLxMTULqJbX71j{display:flex;flex-direction:column;gap:8px}.o4kxmvOLxMTULqJbX71j .eI9g6C5IDfh4lcO_mZ77{display:flex;gap:8px;margin-top:4px}.o4kxmvOLxMTULqJbX71j .eI9g6C5IDfh4lcO_mZ77 .CjNFgCjHD0x1R4FgACCx{display:flex;margin-top:4px;gap:8px}.o4kxmvOLxMTULqJbX71j .a0TqMVq5M_zEqDcfQcLO{margin-top:4px}.o4kxmvOLxMTULqJbX71j .FJCwU0JvfZvIyqQE9vQm{padding-left:4px;color:var(--color-primary);width:-webkit-max-content;width:-moz-max-content;width:max-content}.o4kxmvOLxMTULqJbX71j ._9zlFujZUAi0Ha5XYtbmg{padding-left:38px;font-size:14px;color:var(--color-text-secondary)}.o4kxmvOLxMTULqJbX71j .eM0yQp3eWGXdq7bddTKB{padding-left:4px;width:100%}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Header/TestCaseTitle/TestCaseTitle.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,OAAA,CAEA,4CACI,YAAA,CACA,OAAA,CACA,cAAA,CAEA,kEACI,YAAA,CACA,cAAA,CACA,OAAA,CAGR,4CACI,cAAA,CAEJ,4CACI,gBAAA,CACA,0BAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CAGJ,6CACI,iBAAA,CACA,cAAA,CACA,iCAAA,CAEJ,4CACI,gBAAA,CACA,UAAA","sourcesContent":[".TestCaseTitle {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n\n    .TestCaseTitleIconAndTitle {\n        display: flex;\n        gap: 8px;\n        margin-top: 4px;\n\n        .Statuses {\n            display: flex;\n            margin-top: 4px;\n            gap: 8px;\n        }\n    }\n    .Icon {\n        margin-top: 4px;\n    }\n    .OrderNum {\n        padding-left: 4px;\n        color: var(--color-primary);\n        width: max-content;\n    }\n\n    .TestCaseHeaderDescription {\n        padding-left: 38px;\n        font-size: 14px;\n        color: var(--color-text-secondary);\n    }\n    .Title {\n        padding-left: 4px;\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestCaseTitle": "o4kxmvOLxMTULqJbX71j",
	"TestCaseTitleIconAndTitle": "eI9g6C5IDfh4lcO_mZ77",
	"Statuses": "CjNFgCjHD0x1R4FgACCx",
	"Icon": "a0TqMVq5M_zEqDcfQcLO",
	"OrderNum": "FJCwU0JvfZvIyqQE9vQm",
	"TestCaseHeaderDescription": "_9zlFujZUAi0Ha5XYtbmg",
	"Title": "eM0yQp3eWGXdq7bddTKB"
};
export default ___CSS_LOADER_EXPORT___;
