// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jABVRuGQSt_dtSQm8Acp .FwJyIROPg4R8WvK3aZ2b{display:flex;align-items:center;flex-wrap:wrap;gap:16px}.jABVRuGQSt_dtSQm8Acp .FwJyIROPg4R8WvK3aZ2b .AeYTr6Vyv8_KmXzWwPhI{flex-grow:1}.jABVRuGQSt_dtSQm8Acp .ant-typography{margin:0}@media screen and (min-width: 768px)and (max-width: 1199px){.WhYOCSdD7jWSdMfgblnW{min-width:500px}}@media screen and (max-width: 767px){.WhYOCSdD7jWSdMfgblnW{min-width:500px}}", "",{"version":3,"sources":["webpack://./src/pages/TestSets/TestSetsMain/TestSetsMain.module.scss"],"names":[],"mappings":"AACI,4CACI,YAAA,CACA,kBAAA,CACA,cAAA,CACA,QAAA,CAEA,kEACI,WAAA,CAKJ,sCACI,QAAA,CAIZ,4DACI,sBACI,eAAA,CAAA,CAGR,qCACI,sBACI,eAAA,CAAA","sourcesContent":[".TestSets {\n    .TestSetsHeader {\n        display: flex;\n        align-items: center;\n        flex-wrap: wrap;\n        gap: 16px;\n\n        .TestSetsHeaderLeft {\n            flex-grow: 1;\n        }\n    }\n\n    :global {\n        .ant-typography {\n            margin: 0;\n        }\n    }\n}\n@media screen and (min-width: 768px) and (max-width: 1199px) {\n    .Title {\n        min-width: 500px;\n    }\n}\n@media screen and (max-width: 767px) {\n    .Title {\n        min-width: 500px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestSets": "jABVRuGQSt_dtSQm8Acp",
	"TestSetsHeader": "FwJyIROPg4R8WvK3aZ2b",
	"TestSetsHeaderLeft": "AeYTr6Vyv8_KmXzWwPhI",
	"Title": "WhYOCSdD7jWSdMfgblnW"
};
export default ___CSS_LOADER_EXPORT___;
