// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".q5Vx2p0LBCqxFGhvBmX_{display:flex;align-items:center;justify-content:center;background:orange}.fCzbW9H19Lfqxtuvjaxs{display:flex;align-items:center;justify-content:center;background:rgba(0,0,0,.23)}._gk3NPmCaliBkD36gxsv:hover{background:linear-gradient(to bottom, var(--color-primary5) 50%, var(--color-action-active) 50%)}", "",{"version":3,"sources":["webpack://./src/components/Avatar/UserAvatar.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,iBAAA,CAGJ,sBACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,0BAAA,CAIA,4BAEI,gGAAA","sourcesContent":[".AvatarText {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: orange;\n}\n\n.AvatarImg {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: rgba(0, 0, 0, 0.23);\n}\n\n.ManageAvatar {\n    &:hover {\n        // opacity: 0.06;\n        background: linear-gradient(to bottom, var(--color-primary5) 50%, var(--color-action-active) 50%);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AvatarText": "q5Vx2p0LBCqxFGhvBmX_",
	"AvatarImg": "fCzbW9H19Lfqxtuvjaxs",
	"ManageAvatar": "_gk3NPmCaliBkD36gxsv"
};
export default ___CSS_LOADER_EXPORT___;
