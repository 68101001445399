// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RXuEScQrq79o8ukYqe_M{background:#fff}.RXuEScQrq79o8ukYqe_M .zAQL8YicooGFlnxFCTkc{position:-webkit-sticky;position:sticky;margin-left:382px;background:#fff}.RXuEScQrq79o8ukYqe_M .ErMBzCt5LZAicLUuRoIw{margin-left:422px}.NDvV0A2NI89DzbLzwqvR .ant-drawer-body{padding-top:0}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/TestCaseComponent.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CAEA,4CACI,uBAAA,CAAA,eAAA,CACA,iBAAA,CACA,eAAA,CAGJ,4CACI,iBAAA,CAMA,uCACI,aAAA","sourcesContent":[".TestCaseComponent {\n    background: white;\n\n    .RightSide {\n        position: sticky;\n        margin-left: 382px;\n        background: white;\n    }\n\n    .ExecutionCaseRightSide {\n        margin-left: 422px;\n    }\n}\n\n.Drawer {\n    :global {\n        .ant-drawer-body {\n            padding-top: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestCaseComponent": "RXuEScQrq79o8ukYqe_M",
	"RightSide": "zAQL8YicooGFlnxFCTkc",
	"ExecutionCaseRightSide": "ErMBzCt5LZAicLUuRoIw",
	"Drawer": "NDvV0A2NI89DzbLzwqvR"
};
export default ___CSS_LOADER_EXPORT___;
