import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Form, Input, Modal } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'

import { StoreTestCaseList, StoreTestFolder, StoreTestSet } from '@/store'
import { pageUrls } from '@/consts'

import { ITestFolder } from '@/types'

type Props = {
    projectId: string
    isModalRenameOpen: boolean
    setIsModalRenameOpen: Dispatch<SetStateAction<boolean>>
    testFolders: ITestFolder[]
    setTestFolders: Dispatch<SetStateAction<ITestFolder[]>>
    selectedForActionFolder: ITestFolder
    setSelectedForActionFolder: Dispatch<SetStateAction<ITestFolder | undefined>>
    selectedFolderId: string
    setSelectedFolderId: Dispatch<SetStateAction<string>>
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
}

export const RenameFolderModal = observer((props: Props) => {
    const {
        isModalRenameOpen,
        setIsModalRenameOpen,
        projectId,
        selectedForActionFolder,
        setSelectedForActionFolder,
        testFolders,
        setTestFolders,
        selectedFolderId,
        pageType,
    } = props

    const { t } = useTranslation(['page--testFolders'])
    const [form] = Form.useForm()
    const [disabled, disable] = useState(true)

    const onChangename = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const reg = /[А-Яа-яЁё\w]/
        disable(!reg.test(value))
    }

    const reset = () => {
        form.resetFields()
        setSelectedForActionFolder(undefined)
        setIsModalRenameOpen(false)
    }

    const renameFolder = async () => {
        const newName = form.getFieldValue('name')

        const props = {
            name: newName,
            projectId: projectId,
            id: selectedForActionFolder.id,
            isDeleted: false,
        }

        if (pageType === pageUrls.testFolder) {
            await StoreTestFolder.updateTestFolder(props)
        }

        if (pageType === pageUrls.testSets) {
            await StoreTestSet.updateTestSetFolder(props)
        }

        const testFolderCopy = [...testFolders]
        const currentFolder = testFolderCopy.find((folder) => folder.id === selectedForActionFolder.id)
        currentFolder && (currentFolder.name = newName)
        selectedForActionFolder.id === selectedFolderId && StoreTestFolder.setSelectedFolderName(newName)
        StoreTestFolder.updateFoldersTree()

        if (!selectedFolderId || selectedForActionFolder.id === selectedFolderId) {
            StoreTestCaseList.updateTestCases()
        }

        setTestFolders(testFolderCopy)
        reset()
    }

    const handleCancel = () => {
        reset()
    }

    return (
        <Modal
            title={t('page--testFolders:renameFolder')}
            open={isModalRenameOpen}
            onOk={renameFolder}
            onCancel={handleCancel}
            okButtonProps={{ disabled: disabled }}
            centered
        >
            <Form form={form} layout="vertical" initialValues={{ name: selectedForActionFolder.name }}>
                <FormItem name="name" label={t('page--testFolders:newFolderName')}>
                    <Input showCount maxLength={50} onChange={onChangename} />
                </FormItem>
            </Form>
        </Modal>
    )
})
