// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TR_pDiFwFDFrWnT81uj7{background:var(--color-neutral2)}.TR_pDiFwFDFrWnT81uj7 .ant-collapse-content>.ant-collapse-content-box{padding:24px 16px}.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon{align-items:end}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Content/StepsPanel/StepsPanel.module.scss"],"names":[],"mappings":"AAAA,sBACI,gCAAA,CAGI,sEACI,iBAAA,CAMR,gFACI,eAAA","sourcesContent":[".StepsPanel {\n    background: var(--color-neutral2);\n\n    :global {\n        .ant-collapse-content > .ant-collapse-content-box {\n            padding: 24px 16px;\n        }\n    }\n}\n\n:global {\n    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {\n        align-items: end;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"StepsPanel": "TR_pDiFwFDFrWnT81uj7"
};
export default ___CSS_LOADER_EXPORT___;
