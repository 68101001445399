import React, { Dispatch, SetStateAction } from 'react'

import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { Tooltip } from 'antd'

import { GlobalOutlined } from '@ant-design/icons'

import { pageUrls } from '@/consts'

import { searchAreaTypes, settingsPageType } from '@/types'

import styles from './ToggleSearchArea.module.scss'

type Props = {
    searchArea: searchAreaTypes
    setSearchArea: Dispatch<SetStateAction<searchAreaTypes>>
    localSearch: (value: string) => void
    globalSearch: (value: string) => void
    searchValue: string
    pageType: settingsPageType
}

export const ToggleSearchArea = (props: Props) => {
    const { searchArea, setSearchArea, localSearch, globalSearch, searchValue, pageType } = props
    const { t } = useTranslation(['page--workspaceSettings', 'page--projectSettings'])

    const toggleSearchArea = () => {
        const updatedSearchArea = searchArea === 'local' ? 'global' : 'local'
        setSearchArea(updatedSearchArea)
        updatedSearchArea === 'local' ? localSearch(searchValue) : globalSearch(searchValue)
    }

    return (
        <Tooltip
            title={
                searchArea === 'global'
                    ? pageType === pageUrls.workspaceSettings
                        ? t('page--workspaceSettings:team.switchToLocalSearchUser')
                        : t('page--projectSettings:team.switchToLocalSearchUser')
                    : pageType === pageUrls.workspaceSettings
                      ? t('page--workspaceSettings:team.switchToGlobalSearchUser')
                      : t('page--projectSettings:team.switchToGlobalSearchUser')
            }
            placement="top"
        >
            <GlobalOutlined
                onClick={toggleSearchArea}
                className={cn({ [styles.GlobalSearchArea]: searchArea === 'global' })}
            />
        </Tooltip>
    )
}
