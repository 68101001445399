import React, { Dispatch, MouseEvent, SetStateAction } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'

import { FolderName } from './FolderName'
import { FolderOptions } from './FolderOptions'

import { StoreTestFolder } from '@/store'
import { pageUrls } from '@/consts'

import { ITestFolder } from '@/types'
import { EModalTypeFolder } from '@/enums'

import styles from './FoldersTab.module.scss'

type Props = {
    folder: ITestFolder
    folders: ITestFolder[]
    setTestFolders: Dispatch<SetStateAction<ITestFolder[]>>
    setSelectedFolderId: Dispatch<SetStateAction<string>>
    pageType: (typeof pageUrls)[keyof typeof pageUrls]
    onCreateSubFolder: (folderId: string, nestingLevel: number, e?: MouseEvent) => void
    selectFolderOnly?: boolean
    setIsModalCreateFolderOpen?: Dispatch<SetStateAction<boolean>>
    setIsModalRenameOpen?: Dispatch<SetStateAction<boolean>>
    setIsModalSelectOpen?: Dispatch<SetStateAction<boolean>>
    setSelectedForActionFolder?: Dispatch<SetStateAction<ITestFolder | undefined>>
    setModalTypeFolder?: Dispatch<SetStateAction<EModalTypeFolder | undefined>>
}

export const FolderRow = observer((props: Props) => {
    const {
        folder,
        folders,
        setTestFolders,
        setSelectedFolderId,
        setIsModalCreateFolderOpen,
        selectFolderOnly,
        setModalTypeFolder,
        setIsModalRenameOpen,
        setIsModalSelectOpen,
        setSelectedForActionFolder,
        pageType,
        onCreateSubFolder,
    } = props

    return (
        <div
            className={cn(styles.Folder, {
                showFolder: StoreTestFolder.showSelectedFolderId === folder.id,
            })}
        >
            <div className={styles.Name}>
                <FolderName folder={folder} />
            </div>

            {!selectFolderOnly &&
                setSelectedForActionFolder &&
                setIsModalCreateFolderOpen &&
                setIsModalRenameOpen &&
                setIsModalSelectOpen &&
                setModalTypeFolder && (
                    <FolderOptions
                        folder={folder}
                        folders={folders}
                        setTestFolders={setTestFolders}
                        setSelectedFolderId={setSelectedFolderId}
                        setSelectedForActionFolder={setSelectedForActionFolder}
                        setIsModalCreateFolderOpen={setIsModalCreateFolderOpen}
                        setIsModalRenameOpen={setIsModalRenameOpen}
                        setIsModalSelectOpen={setIsModalSelectOpen}
                        setModalTypeFolder={setModalTypeFolder}
                        pageType={pageType}
                        onCreateSubFolder={onCreateSubFolder}
                    />
                )}
        </div>
    )
})
