// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KfDLv708MIEYJF3WUXlJ{display:flex;gap:16px;padding-bottom:16px;display:flex;gap:16px;background:#fff}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Content/AddNewStepButtons/AddNewStepButtons.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,QAAA,CACA,mBAAA,CACA,YAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".AddNewStepButtons {\n    display: flex;\n    gap: 16px;\n    padding-bottom: 16px;\n    display: flex;\n    gap: 16px;\n    background: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AddNewStepButtons": "KfDLv708MIEYJF3WUXlJ"
};
export default ___CSS_LOADER_EXPORT___;
