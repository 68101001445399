import React, { FC, useEffect, useState } from 'react'

import { TestCases } from '@/pages/TestCases/TestCases'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Layout, Typography } from 'antd'
import { Breadcrumbs } from './components/Breadcrumbs'
import { CreateNewFolderModal } from './components/CreateNewFolderModal'
import { ResizableFoldersTree } from './components/FoldersTree/ResizableFoldersTree'
import { RenameFolderModal } from './components/Modals/RenameFolderModal'
import { SelectAndCreateModal } from './components/Modals/SelectAndCreateModal'
import { SelectFolderModal } from './components/Modals/SelectFolderModal/SelectFolderModal'
import { TestFolderHeader } from './components/TestFolderHeader/TestFolderHeader'
import { Header } from '@/components/Header/Header'

import { PlusOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreCreateTestSet, StoreProject, StoreTestCase, StoreTestFolder, StoreWorkspace } from '@/store'
import { folderTabs, pageUrls } from '@/consts'

import { IProject, ITestFolder } from '@/types'
import { EModalTypeFolder } from '@/enums'

import styles from './TestFolders.module.scss'

const { Content } = Layout
const { Text } = Typography

export const TestFolders: FC = observer(() => {
    const { t } = useTranslation(['page--testFolders', 'testCase'])
    const navigate = useNavigate()
    const { projectId } = useParams()

    const [projectInfo, setProjectInfo] = useState<IProject>()
    const [testFolders, setTestFolders] = useState<ITestFolder[]>([])
    const [selectedFolderId, setSelectedFolderId] = useState('')
    const [selectedForActionFolder, setSelectedForActionFolder] = useState<ITestFolder>()
    const [isModalCreateFolderOpen, setIsModalCreateFolderOpen] = useState(false)
    const [isModalCreateTestCaseOpen, setIsModalCreateTestCaseOpen] = useState(false)
    const [isModalRenameOpen, setIsModalRenameOpen] = useState(false)
    const [isSelectAndCreateModalOpen, setIsSelectAndCreateModalOpen] = useState(false)
    const [isModalSelectOpen, setIsModalSelectOpen] = useState(false)
    const [modalTypeFolder, setModalTypeFolder] = useState<EModalTypeFolder | undefined>()
    const [selectedInModalTestFolder, setSelectedInModalTestFolder] = useState('')
    const [searchedTestCaseName, setSearchedTestCaseName] = useState('')

    useEffect(() => {
        if (projectId) {
            StoreProject.getProject(projectId).then((project: IProject) => {
                setProjectInfo(project)
                StoreProject.setProjectId(projectId)
                !StoreWorkspace.id && StoreWorkspace.setWorkspaceId(project.workspaceId)
                getAllProjects(project.workspaceId)
            })

            StoreTestFolder.setSelectedFolderName('')
        }
    }, [projectId])

    useEffect(() => {
        if (projectId) {
            switch (StoreTestFolder.currentFolderTab) {
                case folderTabs.archive:
                    StoreTestFolder.getTestFolders(projectId, true).then((testFolders) => {
                        setTestFolders(testFolders)
                    })
                    break
                case folderTabs.testCases:
                    StoreTestFolder.getTestFolders(projectId).then((testFolders) => {
                        setTestFolders(testFolders)
                    })
                    break
            }
        }
    }, [projectId, StoreTestFolder.toggleUpdateFoldersTree])

    const getAllProjects = async (workspaceId: string) => {
        if (!StoreWorkspace.projectList.length) {
            const props = {
                filter: { workspaceId: workspaceId, projectName: [], projectDescription: [], isDeleted: false },
            }
            StoreWorkspace.setProjectList((await StoreProject.getProjects(props)).result)
        }
    }

    useEffect(() => {
        StoreTestCase.executionTestCase && StoreTestCase.resetTestCaseData()
        StoreCreateTestSet.isCreatingTestSet && StoreCreateTestSet.resetTestSet()
    }, [])

    const onCreateFolder = () => {
        setIsModalCreateFolderOpen(true)
    }

    const createTestCase = () => {
        const testFolderInfo = testFolders.find((folder) => folder.id === selectedFolderId)

        if (testFolderInfo && projectId) {
            StoreTestCase.resetTestCaseData()
            StoreTestCase.setTestCaseName(t('testCase:newTestCaseTitle'))
            StoreTestCase.setProjectId(projectId)
            StoreTestCase.setToggleResetSearchTestCase()
            navigate(
                `${pageUrls.project}/${StoreProject.id}${pageUrls.testFolder}/${testFolderInfo.id}${pageUrls.testCase}/new`
            )

            setIsSelectAndCreateModalOpen(false)
        } else {
            setIsSelectAndCreateModalOpen(true)
        }
    }

    return (
        <div className={styles.TestFolderList}>
            <Header />
            {projectInfo && !!StoreWorkspace.projectList.length && (
                <Content className={cn('content', styles.ContentWrapper)}>
                    <Breadcrumbs projectInfo={projectInfo} />
                    <TestFolderHeader projectInfo={projectInfo} />
                    <div className={styles.Main}>
                        {projectId && (
                            <ResizableFoldersTree
                                testFolders={testFolders}
                                setTestFolders={setTestFolders}
                                selectedFolderId={selectedFolderId}
                                setSelectedFolderId={setSelectedFolderId}
                                setIsModalCreateFolderOpen={setIsModalCreateFolderOpen}
                                onCreateFolder={onCreateFolder}
                                setSelectedForActionFolder={setSelectedForActionFolder}
                                setIsModalRenameOpen={setIsModalRenameOpen}
                                setIsModalSelectOpen={setIsModalSelectOpen}
                                setModalTypeFolder={setModalTypeFolder}
                                pageType={pageUrls.testFolder}
                                setSearchedName={setSearchedTestCaseName}
                            />
                        )}
                        <div className={styles.RightSide}>
                            {!testFolders.length && StoreTestFolder.currentFolderTab !== folderTabs.archive ? (
                                <div className={styles.NoFolders}>
                                    <Text type="secondary">{t('page--testFolders:noFolders')}</Text>
                                    <Button
                                        type="primary"
                                        icon={<PlusOutlined />}
                                        onClick={onCreateFolder}
                                        disabled={SpinnerStore.show}
                                        className={styles.CreateFolder}
                                    >
                                        {t('page--testFolders:createFolder')}
                                    </Button>
                                </div>
                            ) : (
                                <TestCases
                                    testFolderId={selectedFolderId}
                                    createTestCase={createTestCase}
                                    isModalCreateTestCaseOpen={isModalCreateTestCaseOpen}
                                    setIsModalCreateTestCaseOpen={setIsModalCreateTestCaseOpen}
                                    searchedName={searchedTestCaseName}
                                    testFolders={testFolders}
                                    projectId={projectId}
                                />
                            )}
                        </div>
                    </div>
                    {projectId && (
                        <CreateNewFolderModal
                            projectId={projectId}
                            isModalCreateFolderOpen={isModalCreateFolderOpen}
                            setIsModalCreateFolderOpen={setIsModalCreateFolderOpen}
                            testFolders={testFolders}
                            setTestFolders={setTestFolders}
                            selectedFolderId={selectedFolderId}
                            setSelectedFolderId={setSelectedFolderId}
                            pageType={pageUrls.testFolder}
                        />
                    )}
                    {projectId && isSelectAndCreateModalOpen && (
                        <SelectAndCreateModal
                            testFolders={testFolders}
                            setTestFolders={setTestFolders}
                            selectedFolderId={selectedFolderId}
                            isSelectAndCreateModalOpen={isSelectAndCreateModalOpen}
                            setIsSelectAndCreateModalOpen={setIsSelectAndCreateModalOpen}
                            onCreateFolder={onCreateFolder}
                            onCreateItem={createTestCase}
                            setSelectedFolderId={setSelectedFolderId}
                            pageType={pageUrls.testFolder}
                        />
                    )}
                    {selectedForActionFolder && isModalRenameOpen && projectId && (
                        <RenameFolderModal
                            projectId={projectId}
                            isModalRenameOpen={isModalRenameOpen}
                            setIsModalRenameOpen={setIsModalRenameOpen}
                            testFolders={testFolders}
                            setTestFolders={setTestFolders}
                            selectedFolderId={selectedFolderId}
                            setSelectedFolderId={setSelectedFolderId}
                            selectedForActionFolder={selectedForActionFolder}
                            setSelectedForActionFolder={setSelectedForActionFolder}
                            pageType={pageUrls.testFolder}
                        />
                    )}
                    {projectId && selectedForActionFolder && (
                        <SelectFolderModal
                            projectId={projectId}
                            testFolders={testFolders}
                            setTestFolders={setTestFolders}
                            selectedFolderId={selectedFolderId}
                            isModalSelectOpen={isModalSelectOpen}
                            setIsModalSelectOpen={setIsModalSelectOpen}
                            setSelectedFolderId={setSelectedFolderId}
                            selectedForActionFolder={selectedForActionFolder}
                            modalTypeFolder={modalTypeFolder}
                            setModalTypeFolder={setModalTypeFolder}
                            setSelectedInModalTestFolder={setSelectedInModalTestFolder}
                            selectedInModalTestFolder={selectedInModalTestFolder}
                            pageType={pageUrls.testFolder}
                        />
                    )}
                </Content>
            )}
        </div>
    )
})
