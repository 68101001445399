import React, { Dispatch, ReactElement, SetStateAction, useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useOnOutsideClick } from '@/utils'

import { Dropdown } from 'antd'
import type { MenuProps } from 'antd'

import { DeleteOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreTestSet } from '@/store'

import { dropdownPositionType, IExecutionCase, ITestSet, TimeCheckerFunction, totalTimeType } from '@/types'
import { EExecutionCaseStatus } from '@/enums'

interface Props {
    testCase: IExecutionCase
    testCases: IExecutionCase[]
    setTestCases: Dispatch<SetStateAction<IExecutionCase[]>>
    setSelectedRecord?: Dispatch<SetStateAction<IExecutionCase | undefined>>
    dropdownPosition?: dropdownPositionType
    button?: ReactElement
    setTestSetInfo: Dispatch<SetStateAction<ITestSet | undefined>>
    timeChecker: TimeCheckerFunction
    setTotalTime: Dispatch<SetStateAction<totalTimeType>>
    isTabClicked: boolean
}

export const TestCaseOptions = observer((props: Props) => {
    const {
        testCase,
        button,
        testCases,
        setTestCases,
        setSelectedRecord,
        dropdownPosition,
        setTestSetInfo,
        timeChecker,
        setTotalTime,
        isTabClicked,
    } = props
    const { t } = useTranslation(['common'])

    const closeContextDropdown = () => {
        setSelectedRecord && setSelectedRecord(undefined)
    }

    const onOpenChange = () => {
        button && closeContextDropdown()
    }

    const isContextMenu = testCase && dropdownPosition
    isContextMenu && useOnOutsideClick(closeContextDropdown)

    useEffect(() => {
        if (isTabClicked) {
            closeContextDropdown()
        }
    }, [isTabClicked])

    const onDeleteTestCases = async (selectedTestCase: IExecutionCase) => {
        let currentTime = 0
        await StoreTestSet.deleteExecutionCases(
            selectedTestCase.executionTestSetId,
            [selectedTestCase.id!],
            selectedTestCase.testCaseName
        )
        if (selectedTestCase.normativeTime !== undefined) {
            currentTime += selectedTestCase.normativeTime
        }

        setTotalTime((prevTotalTime: totalTimeType) => {
            const result = timeChecker(prevTotalTime, currentTime)
            return result ?? prevTotalTime
        })

        const updatedNotPassedCounter =
            testCases.filter((testCase) => testCase.executionCaseStatus === EExecutionCaseStatus.NOT_PASSED).length - 1

        const updatedItems = testCases.filter((testCase: IExecutionCase) => testCase.id !== selectedTestCase.id)
        setTestCases(updatedItems)

        if (setTestSetInfo) {
            await setTestSetInfo((prevTestSetInfo: any) => {
                if (prevTestSetInfo) {
                    return {
                        ...prevTestSetInfo,
                        progress: {
                            ...prevTestSetInfo.progress,
                            [EExecutionCaseStatus.NOT_PASSED]: updatedNotPassedCounter,
                        },
                    }
                }
                return prevTestSetInfo
            })
        }
    }

    const items: MenuProps['items'] = [
        {
            key: 'deleteItem',
            label: t('common:buttons.delete'),
            icon: <DeleteOutlined />,
            onClick: () => onDeleteTestCases(testCase),
        },
    ]

    return (
        <Dropdown
            onOpenChange={onOpenChange}
            menu={{ items }}
            open={!!button ? undefined : !!testCase}
            overlayStyle={isContextMenu ? { top: dropdownPosition?.y, left: dropdownPosition?.x } : undefined}
            placement="bottomLeft"
            disabled={SpinnerStore.show}
        >
            {button ? button : <></>}
        </Dropdown>
    )
})
