import React from 'react'

import { Button, Tooltip } from 'antd'

import { MailOutlined } from '@ant-design/icons'

type Props = {
    email: string
    label: string
    subject?: string
    body?: string
    type?: 'link' | 'text' | 'default' | 'primary' | 'dashed'
    headerStyle?: boolean
}

const ButtonMailto = ({ email, subject = '', body = '', label, type = 'link', headerStyle }: Props) => {
    const mailto = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    return (
        <Tooltip title={label}>
            <Button
                onClick={(e) => {
                    window.open(mailto)
                }}
                style={
                    headerStyle ? { color: 'white', height: 56, marginTop: 2 } : { color: 'var(--color-text-primary)' }
                } //56 высота шапки
                type={type}
                icon={<MailOutlined />}
                className="ButtonMailto"
            />
        </Tooltip>
    )
}

export default ButtonMailto
