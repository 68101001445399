import React, { FC, useMemo } from 'react'

import cn from 'classnames'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { PasswordRecoveryPage } from '@/pages'

import { SignIn } from '@/components/SignInUp/SignIn/SignIn'
import { SignUp } from '@/components/SignInUp/SignUp/SignUp'

import { ThemeStore } from '@/store'

import styles from './AuthPage.module.scss'

interface AuthPageProps {
    page: 'signIn' | 'signUp' | 'passwordRecovery'
}

export const AuthPage: FC<AuthPageProps> = observer((props) => {
    const { page } = props

    const Theme = useMemo(
        () => (ThemeStore.theme === 'dark' ? styles.DarkTheme : styles.LightTheme),
        [ThemeStore.theme]
    )

    //загрузка языковых файлов, чтобы потом не перезагружались страницы при подгрузке на новых страницах
    const { t } = useTranslation([
        'common',
        'createModal',
        'testCase',
        'menuMain',
        'documentation',
        'roleModel',
        'notification',
        'page--auth',
        'page--createTestSet',
        'page--projects',
        'page--projectSettings',
        'page--testCases',
        'page--testFolders',
        'page--testSets',
        'page--workspaces',
        'page--workspaceSettings',
        'statuses',
        'validation',
    ])

    return (
        <div className={cn(styles.AuthPage, Theme)}>
            <div className={styles.SignInWrapper}>
                {page === 'signIn' ? <SignIn /> : page === 'passwordRecovery' ? <PasswordRecoveryPage /> : <SignUp />}
            </div>
        </div>
    )
})
