import React, { useEffect } from 'react'

export const useOnOutsideClick = (action: () => void) => {
    const handleClickOutside = () => {
        action()
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside)
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [])
}
