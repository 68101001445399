import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { DateTime } from 'luxon'
import { initReactI18next } from 'react-i18next'

const languages = ['en', 'ru']

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: languages,
        supportedLngs: languages,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

i18n.services.formatter!.add('DATE_HUGE', (value, lng, options) => {
    return DateTime.fromJSDate(value).setLocale(lng!).toLocaleString(DateTime.DATE_HUGE)
})

export default i18n
