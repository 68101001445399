import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Form, Input, Modal, Select, Typography } from 'antd'
import { FormItem } from '@/components/FormItem/FormItem'
import { UserCard } from '@/components/UserCard/UserCard'

import { SpinnerStore, StoreAuthController, StoreProject, StoreWorkspace } from '@/store'
import { pageUrls, settingTabs } from '@/consts'

import { IGetUserIn } from '@/types'
import { EModalType } from '@/enums'

import styles from './CreateModal.module.scss'

const { Text } = Typography
const { TextArea } = Input
const { Option } = Select

type Props = {
    workspaceId?: string
    isModalCreateOpen: boolean
    setIsModalCreateOpen: Dispatch<SetStateAction<boolean>>
    modalType: EModalType
}

export const CreateModal = observer((props: Props) => {
    const { isModalCreateOpen, setIsModalCreateOpen, modalType, workspaceId } = props

    const { t, i18n } = useTranslation(['createModal', 'common', 'validation'])
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [title, setTitle] = useState('')
    const [nameFieldName, setNameFieldName] = useState('')
    const [descriptionFieldName, setDescriptionFieldName] = useState('')
    const [selectedUsers, setSelectedUsers] = useState<string[]>([])
    const [creatingInProgress, setCreatingInProgress] = useState(false)
    const [workspaceUsers, setWorkspaceUsers] = useState<IGetUserIn[]>([])

    const requiredRule = [
        {
            required: true,
            message: t('validation:required'),
        },
    ]

    const handleOk = async () => {
        setCreatingInProgress(true)
        switch (modalType) {
            case EModalType.WORKSPACE:
                {
                    setIsModalCreateOpen(false)
                    const id = await StoreWorkspace.createWorkspace(form.getFieldsValue())
                    navigate(`${pageUrls.workspace}/${id}`)
                }
                break
            case EModalType.PROJECT:
                {
                    if (workspaceId) {
                        const props = {
                            workspaceId: workspaceId,
                            projectName: form.getFieldValue('projectName'),
                            projectDescription: form.getFieldValue('projectDescription'),
                            customer: form.getFieldValue('customer'),
                        }

                        const projectId = await StoreProject.createProject(props)
                        selectedUsers.length && (await StoreAuthController.assignToProject(selectedUsers, projectId))
                        navigate(`${pageUrls.project}/${projectId}`)
                    }
                }
                break
        }
        setCreatingInProgress(false)
        setIsModalCreateOpen(true)
    }

    const handleCancel = () => {
        form.resetFields()
        setIsModalCreateOpen(false)
    }

    const [disabled, disable] = useState(true)

    const onChangename = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        const reg = /[А-Яа-яЁё\w]/
        disable(!reg.test(value))
    }

    useEffect(() => {
        switch (modalType) {
            case EModalType.WORKSPACE:
                setTitle(i18n.t('createModal:newWorkspace'))
                setNameFieldName('workspaceName')
                setDescriptionFieldName('workspaceDescription')
                break
            case EModalType.PROJECT:
                setTitle(t('createModal:newProject'))
                setNameFieldName('projectName')
                setDescriptionFieldName('projectDescription')
                break
        }
    }, [modalType, i18n.language])

    useEffect(() => {
        if (workspaceId) {
            StoreAuthController.getUsersInWorkspace(workspaceId).then((users) => {
                const usersList = users.workspaceUsers.filter((user) => user.userId !== StoreAuthController.userId)
                setWorkspaceUsers(usersList)
            })
        }
    }, [workspaceId])

    return (
        <>
            <Modal
                title={title}
                open={isModalCreateOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                maskClosable={false}
                className={styles.CreateModal}
                footer={[
                    <Form form={form} key="formFooter" onFinish={handleOk}>
                        <div className={styles.FormFooter}>
                            <FormItem className={styles.FormButton}>
                                <Button key="back" onClick={handleCancel}>
                                    {t('common:buttons.cancel')}
                                </Button>
                            </FormItem>
                            <FormItem shouldUpdate className={styles.FormButton}>
                                {() => (
                                    <Button
                                        key="submit"
                                        htmlType="submit"
                                        type="primary"
                                        disabled={
                                            !form.getFieldValue(nameFieldName) ||
                                            SpinnerStore.show ||
                                            creatingInProgress ||
                                            disabled
                                        }
                                        loading={creatingInProgress || SpinnerStore.show}
                                    >
                                        {t('common:buttons.create')}
                                    </Button>
                                )}
                            </FormItem>
                        </div>
                    </Form>,
                ]}
            >
                <Form form={form} layout="vertical" className={styles.Form} key="mainForm">
                    <FormItem name={nameFieldName} label={t('createModal:name')} rules={requiredRule}>
                        <Input showCount maxLength={100} onChange={onChangename} />
                    </FormItem>
                    <FormItem name={descriptionFieldName} label={t('createModal:description')}>
                        <TextArea showCount maxLength={250} />
                    </FormItem>
                    {modalType === EModalType.PROJECT && (
                        <>
                            <FormItem name="customer" label={t('createModal:customer')}>
                                <Input showCount maxLength={250} />
                            </FormItem>
                            <FormItem name="workspaceTeam" label={t('createModal:team')}>
                                {workspaceUsers.length ? (
                                    <Select
                                        mode="multiple"
                                        value={selectedUsers}
                                        onChange={setSelectedUsers}
                                        showSearch
                                        optionLabelProp="label"
                                        optionFilterProp="name"
                                        filterOption={(input, option) =>
                                            (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                    >
                                        {workspaceUsers.map((user) => (
                                            <Option
                                                key={user.userId}
                                                value={user.userId}
                                                name={user.username}
                                                label={
                                                    <UserCard
                                                        userName={`${user.firstname} ${user.lastname}`}
                                                        size={24}
                                                    />
                                                }
                                            >
                                                {
                                                    <UserCard
                                                        userName={`${user.firstname} ${user.lastname}`}
                                                        position={user.position}
                                                        size={32}
                                                    />
                                                }
                                            </Option>
                                        ))}
                                    </Select>
                                ) : (
                                    modalType === EModalType.PROJECT &&
                                    workspaceId && (
                                        <Link
                                            to={`${pageUrls.workspaceSettings}/${workspaceId}?tab=${settingTabs.team}`}
                                            className={styles.TeamLink}
                                        >
                                            {t('createModal:addUsersNotification')}
                                        </Link>
                                    )
                                )}
                            </FormItem>
                        </>
                    )}

                    {(modalType === EModalType.WORKSPACE || modalType === EModalType.PROJECT) && (
                        <Text className={styles.Label} type="secondary">
                            {modalType === EModalType.WORKSPACE
                                ? t('createModal:additionalWorkspaceSettings')
                                : t('createModal:additionalProjectSettings')}
                        </Text>
                    )}
                </Form>
            </Modal>
        </>
    )
})
