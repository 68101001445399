// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Co8A03rJhqd2ZMFXbDgv{overflow-y:auto;height:73vh}.Co8A03rJhqd2ZMFXbDgv .W25_vsvz7zGd18OzAcQm{background:var(--color-neutral2)}.Co8A03rJhqd2ZMFXbDgv .W25_vsvz7zGd18OzAcQm .ant-collapse-content>.ant-collapse-content-box{padding:24px 16px}.Co8A03rJhqd2ZMFXbDgv .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-expand-icon{align-items:end}", "",{"version":3,"sources":["webpack://./src/components/TestCaseComponent/Content/TestCaseContent.module.scss"],"names":[],"mappings":"AAAA,sBACI,eAAA,CACA,WAAA,CAEA,4CACI,gCAAA,CAGI,4FACI,iBAAA,CAMR,sGACI,eAAA","sourcesContent":[".TestCaseContent {\n    overflow-y: auto;\n    height: 73vh;\n\n    .CollapsedTables {\n        background: var(--color-neutral2);\n\n        :global {\n            .ant-collapse-content > .ant-collapse-content-box {\n                padding: 24px 16px;\n            }\n        }\n    }\n\n    :global {\n        .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-expand-icon {\n            align-items: end;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestCaseContent": "Co8A03rJhqd2ZMFXbDgv",
	"CollapsedTables": "W25_vsvz7zGd18OzAcQm"
};
export default ___CSS_LOADER_EXPORT___;
