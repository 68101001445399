import React, { Dispatch, Key, SetStateAction, useEffect, useState } from 'react'

import { observer } from 'mobx-react'

import { Collapse } from 'antd'
import type { CollapseProps } from 'antd'
import { StepsTable } from '../StepsTable/StepsTable'
import { StepsPanelExtra } from './StepsPanelExtra'
import { StepsPanelHeader } from './StepsPanelHeader'

import { StoreTestCase } from '@/store'

import { IStep } from '@/types'
import { EStepType } from '@/enums'

import styles from './StepsPanel.module.scss'

type Props = {
    steps: IStep[]
    setSteps: Dispatch<SetStateAction<IStep[]>>
    stepType: EStepType
    onSelectAll: (setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>, selectedRowKeys: Key[], steps: IStep[]) => void
    tableTitleText: string
    isExecutionTestCase: boolean
    isNewTestCase: boolean
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
}

export const StepsPanel = observer((props: Props) => {
    const { steps, setSteps, stepType, onSelectAll, tableTitleText, isExecutionTestCase, setTestCaseSavedStatus } =
        props
    const [showMenu, setShowMenu] = useState(false)
    const [selectedSteps, setSelectedSteps] = useState<Key[]>([])

    useEffect(() => {
        if (!steps.length && showMenu) {
            setSelectedSteps([])
            setShowMenu(false)
        }
    }, [steps.length])

    const items: CollapseProps['items'] = [
        {
            key: stepType,
            label: <StepsPanelHeader tableTitleText={tableTitleText} numOfTestCases={steps.length} />,
            children: (
                <StepsTable
                    steps={steps}
                    setSteps={setSteps}
                    setShowMenu={setShowMenu}
                    selectedRowKeys={selectedSteps}
                    setSelectedRowKeys={setSelectedSteps}
                    stepType={stepType}
                    isExecutionTestCase={isExecutionTestCase}
                    setTestCaseSavedStatus={setTestCaseSavedStatus}
                />
            ),
            extra: !!steps.length && !isExecutionTestCase && (
                <StepsPanelExtra
                    steps={steps}
                    setSteps={setSteps}
                    stepType={stepType}
                    selectedSteps={selectedSteps}
                    setSelectedSteps={setSelectedSteps}
                    onSelectAll={onSelectAll}
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    setTestCaseSavedStatus={setTestCaseSavedStatus}
                />
            ),
        },
    ]

    return (
        <Collapse
            defaultActiveKey={isExecutionTestCase || stepType === EStepType.DEFAULT_STEP ? [stepType] : []}
            className={styles.StepsPanel}
            items={items}
        />
    )
})
