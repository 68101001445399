// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".guRSCkJMTdP8IH6gUvLP{width:116px;margin-bottom:24px}", "",{"version":3,"sources":["webpack://./src/components/SettingPageTabs/Documentation/UploadScale/UploadScale.module.scss"],"names":[],"mappings":"AAAA,sBACI,WAAA,CACA,kBAAA","sourcesContent":[".UploadScale {\n    width: 116px;\n    margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"UploadScale": "guRSCkJMTdP8IH6gUvLP"
};
export default ___CSS_LOADER_EXPORT___;
