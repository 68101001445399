import React from 'react'

import { useTranslation } from 'react-i18next'

import { Button, Tooltip } from 'antd'
import { ManualIcon } from '@/components/Icons/ManualTestCase/ManualIcon'

import styles from './ManualTestCaseIcon.module.scss'

const ManualTestCaseIcon = () => {
    const { t } = useTranslation(['page--testCases'])

    return (
        <div>
            <Tooltip title={t('page--testCases:type.manual')}>
                <Button type="default" className={styles.ManualTestCaseIcon}>
                    <ManualIcon />
                </Button>
            </Tooltip>
        </div>
    )
}

export default ManualTestCaseIcon
