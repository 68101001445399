import { makeAutoObservable } from 'mobx'
import type { unstable_Blocker as Blocker } from 'react-router-dom'

export const StoreBlocker = makeAutoObservable({
    blocker: null as Blocker | null,

    setBlocker(value: Blocker | null) {
        this.blocker = value
    },
})
