import React, { Dispatch, SetStateAction, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { Button, Card, Dropdown, MenuProps, Typography } from 'antd'

import { JiraLogo } from '@/components/Icons'
import { DeleteOutlined, SettingOutlined, UnorderedListOutlined } from '@ant-design/icons'

import { IJiraBoard } from '@/types'

import styles from './IntegrationCard.module.scss'

const { Title, Text, Link } = Typography

interface IntegrationCardProps {
    url: string
    name: string
    version: string
    scrumBoardId: string | null
    loading: boolean
    openModal: () => void
    setIsEditing: Dispatch<SetStateAction<boolean>>
    onDelete: () => void
}

export const IntegrationCard = (props: IntegrationCardProps) => {
    const { url, name, version, loading, openModal, scrumBoardId, setIsEditing, onDelete } = props

    const { t } = useTranslation(['page--projectSettings', 'common'])

    const onOpenModal = () => {
        setIsEditing(true)
        openModal()
    }

    const items: MenuProps['items'] = [
        {
            key: 'configure',
            label: t('common:buttons.configure'),
            icon: <SettingOutlined />,
            onClick: onOpenModal,
        },
        {
            key: 'delete',
            label: t('common:buttons.delete'),
            icon: <DeleteOutlined />,
            onClick: onDelete,
        },
    ]

    return (
        <Card
            className={styles.IntegrationCard}
            title={
                <div className={styles.IntegrationCardTitle}>
                    <JiraLogo />
                    <Title className={styles.Title} level={5}>
                        Jira
                    </Title>
                </div>
            }
            loading={loading}
            extra={
                <Dropdown menu={{ items }} className={styles.Dropdown}>
                    <Button icon={<UnorderedListOutlined />} />
                </Dropdown>
            }
        >
            <div className={styles.IntegrationCardText}>
                <Text type="secondary">URL:</Text>
                <Link className={styles.Link} href={url} target="_blank">
                    {url}
                </Link>
            </div>
            <div className={styles.IntegrationCardText}>
                <Text type="secondary">{t('page--projectSettings:integrations.projectName')}:</Text>
                <Text>{name}</Text>
            </div>
            <div className={styles.IntegrationCardText}>
                <Text type="secondary">{t('page--projectSettings:integrations.version')}:</Text>
                <Text>{version}</Text>
            </div>
            <div className={styles.IntegrationCardText}>
                <Text type="secondary">{t('page--projectSettings:integrations.jira.panelAgile')}:</Text>
                <Text onClick={onOpenModal}>{scrumBoardId || '—'} </Text>
            </div>
        </Card>
    )
}
