import React, { Dispatch, Key, SetStateAction, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { createSort, createSorter, setPositionOnContextMenu, toDateFormat } from '@/utils'

import { Button, Table, TablePaginationConfig } from 'antd'
import { TableCell } from './TableCell'
import { TestCaseOptions } from './TestCaseOptions'

import { MoreOutlined } from '@ant-design/icons'

import { SpinnerStore, StoreTestSet } from '@/store'
import { showAllPages } from '@/consts'

import { dropdownPositionType, IExecutionCase, ISort, ITestSet, TimeCheckerFunction, totalTimeType } from '@/types'
import { EExecutionCaseStatus, EWorkStatus } from '@/enums'
import { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface'

interface ITableColumns {
    (testCases: IExecutionCase[], sort: ISort[]): ColumnsType<IExecutionCase> | any
}

interface ItestSets {
    testCases: IExecutionCase[]
    setTestCases: Dispatch<SetStateAction<IExecutionCase[]>>
    sort: ISort[]
    setSort: Dispatch<SetStateAction<ISort[]>>
    setShowTableMenu: Dispatch<SetStateAction<boolean>>
    setSelectedRowKeys: Dispatch<SetStateAction<Key[]>>
    selectedRowKeys: Key[]
    setTestSetInfo: Dispatch<SetStateAction<ITestSet | undefined>>
    timeChecker: TimeCheckerFunction
    setTotalTime: Dispatch<SetStateAction<totalTimeType>>
    isTabClicked: boolean
}

export const TestCasesTable = observer((props: ItestSets) => {
    const {
        testCases,
        setTestCases,
        sort,
        setSort,
        setShowTableMenu,
        setSelectedRowKeys,
        selectedRowKeys,
        setTestSetInfo,
        timeChecker,
        setTotalTime,
        isTabClicked,
    } = props
    const { t } = useTranslation(['page--testSets'])

    const onSelectChange = (newSelectedRowKeys: Key[], newSelectedTestCases: IExecutionCase[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
        setShowTableMenu(!!newSelectedRowKeys.length)
    }

    const [selectedRecord, setSelectedRecord] = useState<IExecutionCase | undefined>()
    const [dropdownPosition, setDropdownPosition] = useState<dropdownPositionType>({
        x: 0,
        y: 0,
    })
    const [pageSize, setPageSize] = useState(10)
    const [pageNumber, setPageNumber] = useState(1)

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    }

    const getTableColumns: ITableColumns = (testCases, sort) => {
        const sorter = createSorter(sort)

        if (!testCases) return []

        return [
            {
                title: 'ID',
                key: 'orderNum',
                className: 'linkColumn idColumn',
                render: (orderNum: number, testCase: IExecutionCase) => (
                    <TableCell testCase={testCase} value={orderNum} />
                ),

                ...sorter('orderNum'),
            },
            {
                title: t('page--testSets:testCaseName'),
                key: 'testCaseName',
                className: 'linkColumn testCaseNameColumn',
                ...sorter('testCaseName'),
                render: (name: string, testCase: IExecutionCase) => <TableCell testCase={testCase} value={name} />,
            },
            {
                title: t('page--testSets:testCaseDescription'),
                dataIndex: 'testCaseDescription',
                key: 'testCaseDescription',
                className: 'linkColumn testCaseDescriptionColumn',
                render: (description: string, testCase: IExecutionCase) => (
                    <TableCell testCase={testCase} value={description} />
                ),
            },

            {
                title: t('page--testSets:started'),
                key: 'started',
                className: 'linkColumn startedColumn',
                ...sorter('started'),
                render: (started: number, testCase: IExecutionCase) => (
                    <TableCell testCase={testCase} value={toDateFormat(started)} />
                ),
            },
            {
                title: t('page--testSets:completed'),
                key: 'completed',
                className: 'linkColumn completedColumn',
                ...sorter('completed'),
                render: (completed: number, testCase: IExecutionCase) => (
                    <TableCell testCase={testCase} value={toDateFormat(completed)} />
                ),
            },
            {
                title: t('page--testSets:executionCaseStatus'),
                key: 'executionCaseStatus',
                className: 'linkColumn statusColumn',
                render: (workStatus: EWorkStatus, testCase: IExecutionCase) => (
                    <TableCell testCase={testCase} value={workStatus} isStatusCell />
                ),
                ...sorter('executionCaseStatus'),
            },

            {
                title: '',
                key: 'operation',
                className: 'dropdownMenu',
                render: (id: string, testCase: IExecutionCase) =>
                    testCase.executionCaseStatus === EExecutionCaseStatus.NOT_PASSED && (
                        <TestCaseOptions
                            testCase={testCase}
                            button={<Button type="text" icon={<MoreOutlined />} />}
                            testCases={testCases}
                            setTestCases={setTestCases}
                            setSelectedRecord={setSelectedRecord}
                            setTestSetInfo={setTestSetInfo}
                            timeChecker={timeChecker}
                            setTotalTime={setTotalTime}
                            isTabClicked={isTabClicked}
                        />
                    ),
            },
        ]
    }

    const columns = useMemo(() => getTableColumns(testCases, sort), [t, testCases, sort])

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<any> | SorterResult<any>[]
    ) => {
        setSort(createSort(sorter, sort))
        setPageSize(pagination.pageSize!)
        setPageNumber(pagination.current!)
    }
    useEffect(() => {
        setPageSize(10)
        setPageNumber(1)
    }, [StoreTestSet.id, isTabClicked])

    return (
        <>
            <Table
                columns={columns}
                onChange={handleTableChange}
                dataSource={testCases}
                rowKey={'id'}
                onRow={(record) => {
                    return {
                        onContextMenu: (e) =>
                            setPositionOnContextMenu({ e, record, setSelectedRecord, setDropdownPosition }),
                    }
                }}
                pagination={{
                    showSizeChanger: true,
                    pageSize: pageSize,
                    hideOnSinglePage: true,
                    current: pageNumber,
                }}
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                }}
                size="small"
                loading={SpinnerStore.show}
            />
            {selectedRecord?.executionCaseStatus === EExecutionCaseStatus.NOT_PASSED &&
                selectedRecord &&
                dropdownPosition && (
                    <TestCaseOptions
                        testCase={selectedRecord}
                        testCases={testCases}
                        setTestCases={setTestCases}
                        setSelectedRecord={setSelectedRecord}
                        dropdownPosition={dropdownPosition}
                        setTestSetInfo={setTestSetInfo}
                        timeChecker={timeChecker}
                        setTotalTime={setTotalTime}
                        isTabClicked={isTabClicked}
                    />
                )}
        </>
    )
})
