import React from 'react'

import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'
import PasswordKeySvg from './PasswordKey.svg'

import Icon from '@ant-design/icons'

export const PasswordKeyIcon = (props: Partial<CustomIconComponentProps>) => (
    <Icon component={PasswordKeySvg} {...props} />
)
