import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { tohhmmss } from '@/utils'

import { Tooltip } from 'antd'
import { ModalSave } from '@/components/ModalSave/ModalSave'
import { Status } from '@/components/Status/Status'
import { Timer } from '@/components/Timer/Timer'
import { TestCaseMenu } from './TestCaseMenu/TestCaseMenu'
import { TestCaseTitle } from './TestCaseTitle/TestCaseTitle'

import { StoreTestCase } from '@/store'
import { pageUrls } from '@/consts'

import { ITestCase } from '@/types'
import { EExecutionCaseStatus } from '@/enums'

import styles from './TestCaseHeader.module.scss'

type Props = {
    isModalSaveOpen: boolean
    handleDontSave: () => void
    handleSave: () => void
    handleCancel: () => void
    mergeSteps: () => void
    setTestCaseSavedStatus: (isSaved: boolean) => Promise<void>
    setTestCaseInfo?: Dispatch<SetStateAction<ITestCase | undefined>>
}

export const TestCaseHeader = observer((props: Props) => {
    const {
        isModalSaveOpen,
        handleDontSave,
        handleSave,
        handleCancel,
        mergeSteps,
        setTestCaseInfo,
        setTestCaseSavedStatus,
    } = props

    const { t } = useTranslation(['testCase'])
    const [showLaunchButtons, setShowLaunchButtons] = useState(false)

    useEffect(() => {
        setShowLaunchButtons(
            StoreTestCase.executionTestCase &&
                (StoreTestCase.completeWithError ||
                    StoreTestCase.executionCaseStarted ||
                    (!StoreTestCase.executionCaseStarted &&
                        StoreTestCase.executionCaseStatus === EExecutionCaseStatus.NOT_PASSED) ||
                    StoreTestCase.executionCaseStatus === EExecutionCaseStatus.IN_PROGRESS ||
                    StoreTestCase.executionCaseStatus === EExecutionCaseStatus.IN_PROCESS)
        )
    }, [
        StoreTestCase.executionTestCase,
        StoreTestCase.executionCaseCompleted,
        StoreTestCase.executionCaseStarted,
        StoreTestCase.testCaseInfo.executionTestSetStatus,
        StoreTestCase.completeWithError,
    ])

    return (
        <div className={styles.TestCaseHeader}>
            <div className={styles.TestCaseHeader}>
                <div className={styles.TestCaseHeaderLeft}>
                    <TestCaseTitle mergeSteps={mergeSteps} setTestCaseSavedStatus={setTestCaseSavedStatus} />
                </div>
                <div className={styles.TestCaseHeaderRight}>
                    <TestCaseMenu
                        showLaunchButtons={showLaunchButtons}
                        mergeSteps={mergeSteps}
                        setTestCaseInfo={setTestCaseInfo}
                    />

                    {StoreTestCase.executionTestCase && (
                        <div className={styles.LaunchStatus}>
                            <div className={styles.Timer}>
                                {StoreTestCase.executionCaseStarted ? (
                                    <Timer />
                                ) : (
                                    StoreTestCase.durationExecutionCase && (
                                        <Tooltip title={t('testCase:durationExecutionCase')}>
                                            <span className={styles.Duration}>
                                                {tohhmmss(+StoreTestCase.durationExecutionCase)}
                                            </span>
                                        </Tooltip>
                                    )
                                )}
                                {StoreTestCase.executionCaseStarted && <span className={styles.Divider}>•</span>}
                            </div>

                            <Status status={StoreTestCase.executionCaseStatus} />
                        </div>
                    )}
                </div>
            </div>
            {isModalSaveOpen && (
                <ModalSave
                    isModalSaveOpen={isModalSaveOpen}
                    handleDontSave={handleDontSave}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    pageType={pageUrls.testCase}
                />
            )}
        </div>
    )
})
